import {
  SiteAggregateStatsQuery,
  DocumentCreateInput,
  SubscriptionPlanType,
} from '@marketmuse/config/types/papi';

export enum MISC_SIMPLE_KEYS {
  briefCancellation = 'briefCancellation',
  briefCancellationData = 'briefCancellation-data',
  calendlyPremium = 'calendlyPremium',
  counts = 'counts',
  documentsCreate = 'documentsCreate',
  initialPath = 'initialPath',
  initiateLogout = 'initiateLogout',
  processingSignup = 'processingSignup',
  projectConfirmDelete = 'projectConfirmDelete',
  projectConfirmDeleteData = 'projectConfirmDelete-data',
  projectDate = 'projectDate',
  projectDateData = 'projectDate-data',
  projectUser = 'projectUser',
  projectUserData = 'projectUser-data',
  subscriptionAddonDocuments = 'subscriptionAddonDocuments',
  subscriptionAddonInventory = 'subscriptionAddonInventory',
  subscriptionAddonTopics = 'subscriptionAddonTopics',
  subscriptionAddonUsers = 'subscriptionAddonUsers',
  subscriptionManagement = 'subscriptionManagement',
  subscriptionManagementData = 'subscriptionManagement-data',
  tableViewRefetchTime = 'tableViewRefetchTime',
  upgradeWarning = 'upgradeWarning',
  userLoaded = 'userLoaded',
  userLookup = 'userLookup',
  xAuthGhost = 'x-auth-ghost',
}

export type SliceMisc = {
  [MISC_SIMPLE_KEYS.briefCancellation]?: boolean;
  [MISC_SIMPLE_KEYS.briefCancellationData]?: Record<string, any>;
  [MISC_SIMPLE_KEYS.calendlyPremium]?: boolean;
  [MISC_SIMPLE_KEYS.counts]?: Omit<
    SiteAggregateStatsQuery['siteAggregateStats'],
    '__typename'
  >;
  [MISC_SIMPLE_KEYS.documentsCreate]?: {
    isVisible: boolean;
    items?: Array<DocumentCreateInput>;
  };
  [MISC_SIMPLE_KEYS.initialPath]?: string;
  [MISC_SIMPLE_KEYS.initiateLogout]?: Date | number;
  [MISC_SIMPLE_KEYS.projectConfirmDelete]?: boolean;
  [MISC_SIMPLE_KEYS.projectConfirmDeleteData]?: Record<string, any>;
  [MISC_SIMPLE_KEYS.projectDate]?: boolean;
  [MISC_SIMPLE_KEYS.projectDateData]?: Record<string, any>;
  [MISC_SIMPLE_KEYS.projectUser]?: boolean;
  [MISC_SIMPLE_KEYS.projectUserData]?: Record<string, any>;
  [MISC_SIMPLE_KEYS.subscriptionAddonDocuments]?: boolean;
  [MISC_SIMPLE_KEYS.subscriptionAddonInventory]?: boolean;
  [MISC_SIMPLE_KEYS.subscriptionAddonTopics]?: boolean;
  [MISC_SIMPLE_KEYS.subscriptionAddonUsers]?: boolean;
  [MISC_SIMPLE_KEYS.subscriptionManagement]?: boolean;
  [MISC_SIMPLE_KEYS.subscriptionManagementData]?: {
    planName: SubscriptionPlanType;
  };
  [MISC_SIMPLE_KEYS.tableViewRefetchTime]?: Date | number;
  [MISC_SIMPLE_KEYS.upgradeWarning]?: boolean;
  [MISC_SIMPLE_KEYS.userLoaded]?: boolean;
  [MISC_SIMPLE_KEYS.userLookup]?: boolean;
  [MISC_SIMPLE_KEYS.xAuthGhost]?: string;
};
