import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NetworkStatus, NETWORK_STATUS } from '@marketmuse/config/types';
import { AppQueryNetworkKeys } from '../../../types';
import {
  optimizeTaskHydrateCheck,
  optimizeTaskSiteChange,
  optimizeTaskFetchPage,
} from '../../actions';
import { initialState } from './network.initialState';

const makeInitialStatus = (status: NetworkStatus, initial: NetworkStatus) => {
  return (
    [NETWORK_STATUS.loading, NETWORK_STATUS.queued] as NetworkStatus[]
  ).includes(status)
    ? initial
    : status;
};

export const networkSlice = createSlice({
  name: 'optimize/network',
  initialState,
  reducers: {
    bulkAdwordsKeywordsSet: (state, action: PayloadAction<NetworkStatus>) => {
      state.bulkAdwordsKeywords = action.payload;
    },
    knowledgeGraphSet: (state, action: PayloadAction<NetworkStatus>) => {
      state.knowledgeGraph = action.payload;
    },
    pageContentsSet: (state, action: PayloadAction<NetworkStatus>) => {
      state.pageContents = action.payload;
    },
    setMany: (
      state,
      action: PayloadAction<{
        keys: Array<AppQueryNetworkKeys>;
        status: NetworkStatus;
      }>,
    ) => {
      const { keys, status } = action.payload;
      keys.forEach(key => (state[key] = status));
    },
  },
  extraReducers(builder) {
    builder
      .addCase(optimizeTaskHydrateCheck, state => {
        const bulkAdwordsKeywords = makeInitialStatus(
          state.bulkAdwordsKeywords,
          initialState.bulkAdwordsKeywords,
        );
        const knowledgeGraph = makeInitialStatus(
          state.knowledgeGraph,
          initialState.knowledgeGraph,
        );
        const pageContents = makeInitialStatus(
          state.pageContents,
          initialState.pageContents,
        );

        return {
          bulkAdwordsKeywords,
          knowledgeGraph,
          pageContents,
        };
      })
      .addCase(optimizeTaskFetchPage, state => {
        state.pageContents = NETWORK_STATUS.loading;
      })
      .addCase(optimizeTaskSiteChange, () => {
        return initialState;
      });
  },
});

export const networkActions = networkSlice.actions;
