const initialMap = {
  zero: '',
  one: '',
  other: 's',
};

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/PluralRules
const pluralRules = new Intl.PluralRules('en');

/**
 *
 * @param {String} string - string to make plural
 * @param {Number} count - how many items to compare
 * @param {Object.<{zero: String, one: String, other: String}>} - key pair map of suffixes
 */
export const pluralize = (
  string: string,
  count: number,
  pluralMap: Record<string, string> = initialMap,
) => {
  const suffix = pluralMap?.[pluralRules.select(count)];
  return `${string}${suffix}`;
};
