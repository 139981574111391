import { useMemo } from 'react';
import { NETWORK_STATUS } from '@marketmuse/config/types';
import { LinkingRecommendationForTerm } from '@marketmuse/config/types/papi';
import {
  IResearchDataContext,
  useResearchData,
  isLoading,
} from '@marketmuse/research-data';
import { ConnectViews, ConnectViewStatus, ConnectViewsData } from './types';

const NO_RESULT_DESCRIPTION = {
  [ConnectViews.internal]: "Sorry! We couldn't find anything here!",
  [ConnectViews.external]: "Sorry! We couldn't find anything here!",
  [ConnectViews.competition]:
    "Sorry! We couldn't find anything here! Try clicking the settings wheel above to add more competitor domains and get more results!",
  [ConnectViews.network]:
    "Sorry! We couldn't find anything here! Try clicking the settings wheel above to add more domains to your network and get more results!",
};

export function useStatus(): ConnectViewStatus {
  const {
    network: { linkingRecommendations: status },
  }: IResearchDataContext = useResearchData();

  return useMemo(() => {
    const linkingRecommendationsLoading = isLoading(status);
    return {
      loading: linkingRecommendationsLoading,
      complete: status === NETWORK_STATUS.complete,
      error: status === NETWORK_STATUS.error,
    };
  }, [status]);
}

export function useRecommendation(currentView: ConnectViews): {
  recommendations: LinkingRecommendationForTerm[];
  noResultDescription?: string;
} {
  const {
    data: { linkingRecommendations: data },
    network: { linkingRecommendations: status },
  }: IResearchDataContext = useResearchData();

  return useMemo(() => {
    if (status === NETWORK_STATUS.complete && data) {
      return {
        recommendations: data[ConnectViewsData[currentView]],
        noResultDescription: NO_RESULT_DESCRIPTION[currentView],
      };
    }

    return {
      recommendations: [],
      noResultDescription: NO_RESULT_DESCRIPTION[currentView],
    };
  }, [currentView, status, data]);
}

export function useRecommendationCount(): Record<ConnectViews, number> {
  const {
    data: { linkingRecommendations: data },
    network: { linkingRecommendations: status },
  }: IResearchDataContext = useResearchData();

  return useMemo(() => {
    if (status === NETWORK_STATUS.complete && data)
      return {
        [ConnectViews.competition]:
          data[ConnectViewsData[ConnectViews.competition]].length,
        [ConnectViews.external]:
          data[ConnectViewsData[ConnectViews.external]].length,
        [ConnectViews.internal]:
          data[ConnectViewsData[ConnectViews.internal]].length,
        [ConnectViews.network]:
          data[ConnectViewsData[ConnectViews.network]].length,
      };
    return {
      competitor: 0,
      external: 0,
      internal: 0,
      network: 0,
    };
  }, [status, data]);
}
