import { ReactComponent as Block1 } from '../../../assets/zerostates/newsroom/block1.svg';
import { ReactComponent as Block2 } from '../../../assets/zerostates/newsroom/block2.svg';
import { ReactComponent as Block3 } from '../../../assets/zerostates/newsroom/block3.svg';

import AppZeroStateRow from './components/AppZeroStateRow';
import AppZeroStateBlock from './components/AppZeroStateBlock';
import AppZeroStateWrapper from './components/AppZeroStateWrapper';

const NewsroomZeroState = () => {
  return (
    <AppZeroStateWrapper started={true} style={{ flexFlow: 'column' }}>
      <AppZeroStateRow left arrow={false}>
        <AppZeroStateBlock
          svg={<Block1 />}
          text="Enter a topic and a page (optional) you want researched within the news landscape."
        />
      </AppZeroStateRow>
      <AppZeroStateRow right style={{ marginTop: -50 }}>
        <AppZeroStateBlock
          svg={<Block2 />}
          text="We’ll cross-reference trending news and thousands of long-standing ranking articles to provide you with a topical and competitive analysis."
        />
      </AppZeroStateRow>
      <AppZeroStateRow left style={{ marginTop: -190 }}>
        <AppZeroStateBlock
          svg={<Block3 />}
          text="Use the findings to create or optimize an article with high-ranking potential in the SERP and news, even after the topic stops trending."
        />
      </AppZeroStateRow>
    </AppZeroStateWrapper>
  );
};

export default NewsroomZeroState;
