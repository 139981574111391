import * as React from 'react';
import { twMerge } from 'tailwind-merge';
import { flexRender } from '@tanstack/react-table';

import { Box } from '../../Box';
import { Text } from '../../Text';
import TableCell from './TableCell';
import TableColumnSort from './TableColumnSort';

const wrapperClassNames = [
  'group',
  'text-left',
  'text-xs',
  'font-medium',
  'text-gray-500',
  'uppercase',
  'tracking-wider',
];

const cellClassNames = [
  'max-w-full',
  'h-full',
  'flex',
  'flex-row',
  'items-center',
  'gap-2',
  'justify-items-center',
  'whitespace-pre-wrap',
];

const TableHeaderCell = ({ cell }) => {
  const cellRender = flexRender(
    cell.column.columnDef.header,
    cell.getContext(),
  );
  return (
    <TableCell
      id={cell.column.id}
      className={twMerge([
        ...wrapperClassNames,
        ...(typeof cell.column.columnDef.header === 'string'
          ? ['font-bold', 'text-xs']
          : []),
        ...(cell.column.columnDef?.meta?.headerCell?.classNames || []),
      ])}
      width={cell.getSize()}
    >
      <Box className={cellClassNames}>
        {typeof cell.column.columnDef.header === 'string' && (
          <>
            <Text data-mms--table-header-text>{cellRender}</Text>
            {cell.column.getCanSort() && (
              <TableColumnSort
                dataId={cell.column.id}
                onClick={cell.column.getToggleSortingHandler()}
                isSorted={cell.column.getIsSorted()}
              />
            )}
          </>
        )}
        {typeof cell.column.columnDef.header !== 'string' && cellRender}
      </Box>
    </TableCell>
  );
};

export default TableHeaderCell;
