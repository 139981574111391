import get from 'lodash/get';

import { GetProjectDocument } from '@marketmuse/data-papi';
import Toast from '../../components/Toast';
import * as types from '../../config/types';
import makeRequest from '../../utils/makeRequest';

export default () =>
  ({ getState }) =>
  next =>
  action => {
    if (action.type === types.GET_PROJECT) {
      const { callback, id } = action;
      makeRequest({
        type: types.GET_PROJECT,
        mutation: GetProjectDocument,
        options: {
          isQuery: true,
        },
        variables: {
          id: id,
        },
        apolloOptions: {
          fetchPolicy: 'no-cache',
        },
        keys: [].concat(action.keys || []),
      }).then(res => {
        const data = get(res, 'project') || {};

        if (res.errors || res.error) {
          Toast.fire(res.errors || res.error || 'Unknown error', 'error');
        }

        if (typeof callback === 'function') callback(data);
        return data;
      });
    }

    return next(action);
  };
