import { Color, VolumeCalculateFn, VolumeCalculateReturnType } from '../types';

enum VolumeTitle {
  Extreme = 'Extreme',
  VeryHigh = 'Very High',
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
  VeryLow = 'Very Low',
  NA = 'N/A',
}

const colors: Record<VolumeTitle, Color> = {
  [VolumeTitle.Extreme]: 'blue',
  [VolumeTitle.VeryHigh]: 'blue',
  [VolumeTitle.High]: 'green',
  [VolumeTitle.Medium]: 'yellow',
  [VolumeTitle.Low]: 'orange',
  [VolumeTitle.VeryLow]: 'red',
  [VolumeTitle.NA]: 'gray',
};

const values: Record<VolumeTitle, number> = {
  [VolumeTitle.NA]: -1,
  [VolumeTitle.VeryLow]: 0,
  [VolumeTitle.Low]: 150,
  [VolumeTitle.Medium]: 500,
  [VolumeTitle.High]: 2500,
  [VolumeTitle.VeryHigh]: 5000,
  [VolumeTitle.Extreme]: 10000,
};

const titles: Record<number, VolumeTitle> = {
  [values[VolumeTitle.Extreme]]: VolumeTitle.Extreme,
  [values[VolumeTitle.VeryHigh]]: VolumeTitle.VeryHigh,
  [values[VolumeTitle.High]]: VolumeTitle.High,
  [values[VolumeTitle.Medium]]: VolumeTitle.Medium,
  [values[VolumeTitle.Low]]: VolumeTitle.Low,
  [values[VolumeTitle.VeryLow]]: VolumeTitle.VeryLow,
};

const calculate: VolumeCalculateFn = (volume): VolumeCalculateReturnType => {
  const text: VolumeTitle = VolumeTitle.NA;
  const color: Color = 'gray';
  const volumeInt = Number.isFinite(volume) ? volume : -1;

  const band = Object.entries(titles).reduce(
    (acc: VolumeCalculateReturnType, [key, value]) => {
      const valueInt = parseInt(key);

      if (volumeInt >= valueInt) {
        const text: VolumeTitle = value;
        const color: Color = colors[value];

        return { text, color };
      } else {
        return acc;
      }
    },
    { text, color } as VolumeCalculateReturnType,
  );

  return band;
};

export default calculate;
