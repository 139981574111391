import React from 'react';

import { twMerge } from 'tailwind-merge';
import { Box } from '../Box';
import { Icon, IconProps, SUPPORTED_ICON } from '../Icon';

interface LoadingSpinnerProps {
  className?: string | Array<string>;
  style?: React.CSSProperties;
  iconProps?: Pick<IconProps, 'boxSize' | 'className'>;
}

export const LoadingSpinner = ({
  className,
  style,
  iconProps,
}: LoadingSpinnerProps) => {
  const iconClassName = twMerge('animate-spin', iconProps?.className);
  return (
    <Box
      className={twMerge(['w-4', 'h-4', 'inline-block', className])}
      style={style}
    >
      <Icon
        name={SUPPORTED_ICON.spinner}
        {...(iconProps || {})}
        className={iconClassName}
      />
    </Box>
  );
};
