import { AsyncThunk } from '@reduxjs/toolkit';
import { get } from 'lodash';
import type {
  AdWordsItem,
  KnowledgeGraphItem,
} from '@marketmuse/config/types/papi';
import { checkSiteViableInventory, uniqueBy } from '@marketmuse/utilities';

import {
  ReduxListener,
  ResearchDataBulkAwInput,
  ResearchDataInput,
  ResearchDataWithInventoryItems,
  ResearchThunkInvItemsInput,
  ResearchThunkInvItemsRes,
  ResearchThunkSeoRes,
  RootState,
  ThunkApiConfig,
} from '../../types';
import { dataSlice } from '../slices';

type SeoFulfilledListener = {
  key: ResearchDataWithInventoryItems;
  path?: string;
  entityVariants?: boolean;
  startListening: ReduxListener;
  thunk: AsyncThunk<
    ResearchThunkSeoRes,
    ResearchDataBulkAwInput | ResearchDataInput,
    ThunkApiConfig
  >;
  getInvItems: AsyncThunk<
    ResearchThunkInvItemsRes,
    ResearchThunkInvItemsInput,
    ThunkApiConfig
  >;
};

const checkInventoryRequirements = getState => {
  const state: RootState = getState();
  const site = state?.filter?.site;
  const versions = state?.filter?.versions;
  const permissions = state?.permissions;

  const hasViableInventory = checkSiteViableInventory({
    invId: site?.invId,
    versions,
    permissions,
  });

  return { siteId: site.id, hasViableInventory };
};

const arrayChunks = (array, chunkSize) =>
  Array(Math.ceil(array.length / chunkSize))
    .fill(void 0)
    .map((_, index) => index * chunkSize)
    .map(begin => array.slice(begin, begin + chunkSize));

export function createSeoFulfilledListener<
  Entity extends AdWordsItem | KnowledgeGraphItem,
>({
  entityVariants,
  getInvItems,
  key,
  path,
  startListening,
  thunk,
}: SeoFulfilledListener) {
  const dataActions = dataSlice.actions;

  startListening({
    actionCreator: getInvItems.fulfilled,
    effect: async ({ payload }, { dispatch }) => {
      const terms = payload.data.map(({ term }) => term);
      await dispatch(
        dataActions.checkInvTopicInSeo({
          key,
          path,
          terms,
        }),
      );
    },
  });

  startListening({
    actionCreator: thunk.fulfilled,
    effect: async ({ payload }, { getState, dispatch }) => {
      const { siteId, hasViableInventory } =
        checkInventoryRequirements(getState);

      if (hasViableInventory) {
        const data: Entity[] = path
          ? get(payload, `data.${path}`)
          : payload.data;

        await dispatch(
          getInvItems({
            siteId: siteId,
            items: data,
          }),
        );
      }
    },
  });

  if (entityVariants) {
    startListening({
      actionCreator: thunk.fulfilled,
      effect: async ({ payload }, { getState, dispatch }) => {
        const { siteId, hasViableInventory } =
          checkInventoryRequirements(getState);

        if (hasViableInventory) {
          const data: Entity[] = path
            ? get(payload, `data.${path}`)
            : payload.data;
          const variants: Pick<AdWordsItem, 'term'>[] = data
            .map(topic => topic.variants)
            .flat();
          const variantsUnique = uniqueBy(variants, 'term');

          const chunks: Array<AdWordsItem[]> = arrayChunks(variantsUnique, 200);
          const tasks = chunks.map(chunk =>
            getInvItems({
              siteId: siteId,
              items: chunk,
            }),
          );

          await Promise.allSettled(
            tasks.map(async (task, index) => {
              const delay = (index + 1) * 2000;
              setTimeout(async () => {
                Promise.resolve(dispatch(task));
              }, delay);
            }),
          ).catch(error => console.error(error));
        }
      },
    });
  }
}
