export const omitWalkDeepFromArray = (
  arr: Record<string, unknown>[],
  keys: string[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  return (arr || []).map(item => {
    if (Array.isArray(item)) {
      return omitWalkDeepFromArray(item, keys);
    } else if (typeof item === 'object') {
      return omitWalkDeepFromDict(item as Record<string, unknown>, keys);
    } else {
      return item;
    }
  });
};

export const omitWalkDeepFromDict = (
  dict: Record<string, unknown>,
  keys: string[],
): Record<string, unknown> => {
  return Object.entries(dict).reduce((acc, [key, value]) => {
    if (keys.includes(key)) {
      return acc;
    } else if (Array.isArray(value)) {
      return { ...acc, [key]: omitWalkDeepFromArray(value, keys) };
    } else if (typeof value === 'object' && value !== null) {
      return {
        ...acc,
        [key]: omitWalkDeepFromDict(value as Record<string, unknown>, keys),
      };
    } else {
      return { ...acc, [key]: value };
    }
  }, {});
};
