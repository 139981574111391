import * as React from 'react';

import { statBandMentions } from '@marketmuse/utilities';

import { Box } from '../Box';
import { Text } from '../Text';

export interface MentionsProps {
  value: number;
  banded?: boolean;
}

export const Mentions = React.memo(
  React.forwardRef<HTMLDivElement, MentionsProps>(
    ({ value = 0, banded = false }, ref) => {
      const { band, mentions } = statBandMentions.calculate(value);
      return (
        <Box
          className={[
            'inline-flex',
            'items-center',
            'justify-center',
            'rounded-md',
            'h-8',
            'w-10',
            'max-w-full',
            'whitespace-nowrap',
            statBandMentions.backgroundColor(mentions),
          ]}
        >
          <Text className={['font-bold', 'text-xs', 'text-white']}>
            {banded ? band : mentions}
          </Text>
        </Box>
      );
    },
  ),
);
