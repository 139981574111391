import { useMemo } from 'react';
import { isNil, isEmpty } from 'lodash';

import { TOKEN_KEYS } from '@marketmuse/config/configs';
import { useStoreSelector } from '@marketmuse/data-state/hooks';

const useGuestTokens = () => {
  const guestTokenSession = sessionStorage.getItem(TOKEN_KEYS.GUEST);
  const guestTokenState = useStoreSelector(state => state.guestAccess?.token);

  const hasGuestAccess = useMemo(() => {
    return (
      [guestTokenState, guestTokenSession].filter(
        t => !isNil(t) && !isEmpty(t) && t !== 'undefined',
      ).length > 0
    );
  }, [guestTokenState, guestTokenSession]);

  return { hasGuestAccess };
};

export default useGuestTokens;
