// https://github.com/marketmuse/reports-api/blob/master/mrbriefs/structs/brief_statuses.py
export const BRIEF_STATUS = {
  CART: 'CART', // create sections
  IN_PROGRESS: 'IN_PROGRESS', // processing
  QA_READY: 'QA_READY', // needs review
  DELIVERY_READY: 'DELIVERY_READY', // qa-ready
  DELIVERED: 'DELIVERED', // brief ready
};

export const BRIEF_STATUS_TITLES = {
  [BRIEF_STATUS.CART]: {
    id: BRIEF_STATUS.CART,
    title: 'Create sections',
  },
  [BRIEF_STATUS.IN_PROGRESS]: {
    id: [BRIEF_STATUS.IN_PROGRESS],
    title: 'Processing',
  },
  [BRIEF_STATUS.QA_READY]: {
    id: [BRIEF_STATUS.QA_READY],
    title: 'Needs Review',
  },
  [BRIEF_STATUS.DELIVERY_READY]: {
    id: [BRIEF_STATUS.DELIVERY_READY],
    title: 'Delivery Ready',
  },
  [BRIEF_STATUS.DELIVERED]: {
    id: [BRIEF_STATUS.DELIVERED],
    title: 'Brief Ready',
  },
};

export const adjustBriefStatus = (briefStatus, briefSelfServe) => {
  if (briefSelfServe && !briefStatus) {
    return null;
  }
  if (briefSelfServe) {
    return briefStatus;
  }
  if (briefStatus === BRIEF_STATUS.DELIVERED) {
    return briefStatus;
  }

  return BRIEF_STATUS.IN_PROGRESS;
};

export const getBriefStatusIcon = status => {
  switch (status) {
    case BRIEF_STATUS.CART: {
      return 'circle-progress-25';
    }
    case BRIEF_STATUS.IN_PROGRESS: {
      return 'circle-progress-50';
    }
    case BRIEF_STATUS.QA_READY: {
      return 'circle-progress-75';
    }
    case BRIEF_STATUS.DELIVERY_READY: {
      return 'circle-progress-75';
    }
    default: {
      return 'circle-progress-done';
    }
  }
};
