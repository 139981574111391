import { isArray, isNil } from 'lodash';
import React, { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { Polymorphic } from '../../Polymorphic';
import { ProgressBarRelativeProps } from './ProgressBarRelativeTypes';

const LABEL_FLIP = 35;

export const ProgressBarRelative = React.forwardRef<
  HTMLDivElement,
  ProgressBarRelativeProps
>(({ as, className, value, ...rest }, ref) => {
  const element = as || 'div';

  const classNameArray: string[] = isArray(className)
    ? (className as string[])
    : [className];

  const val = useMemo(() => (isNil(value) ? '-' : Math.round(value)), [value]);
  const sign = useMemo(() => (!isNil(value) ? val > 0 : false), [value, val]);
  const roundedValue = useMemo(() => (val === '-' ? 0 : Math.abs(val)), [val]);

  return (
    <Polymorphic
      {...rest}
      ref={ref}
      as={element}
      className={twMerge(['w-full h-5'], classNameArray)}
    >
      <div className="relative bg-gray-150 rounded-full h-full overflow-hidden">
        <div
          className={twMerge([
            'relative flex items-center max-w-full h-full transition-all',
            sign ? 'rounded-r-full bg-green-400' : 'rounded-l-full bg-red-400',
          ])}
          style={{
            width: roundedValue === 0 ? 0 : `calc(${roundedValue / 2}%)`,
            left: sign ? '50%' : `calc(50% - ${roundedValue / 2}%)`,
          }}
        >
          <div
            className={twMerge([
              'm-[1px] absolute right-2 text-[10px] font-bold w-max h-full flex items-center content-center ',
              roundedValue < LABEL_FLIP || (roundedValue < LABEL_FLIP && !sign)
                ? 'text-gray-500'
                : 'text-white',
              roundedValue === 0
                ? 'right-[-12px]'
                : roundedValue < LABEL_FLIP && !sign
                ? 'right-auto left-[-6px] transform translate-x-[-100%]'
                : roundedValue < LABEL_FLIP
                ? 'right-[-8px] transform translate-x-full'
                : roundedValue > LABEL_FLIP && !sign
                ? 'left-2'
                : 'right-2',
            ])}
          >
            {val}
          </div>
        </div>
      </div>
    </Polymorphic>
  );
});

export default ProgressBarRelative;
