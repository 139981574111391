import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ReactComponent as DottedArrow } from '../../../../assets/zerostates/dottedArrow.svg';

const GUTTER = 42;
const ARROW_SIZE = 62;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

const Side = styled.div`
  flex-shrink: 0;
  width: calc(50% - ${GUTTER / 2}px);
`;

const Content = styled.div`
  flex-shrink: 0;
  position: relative;
  width: calc(50% - ${GUTTER / 2}px);
`;

const DottedArrowContainer = styled.div`
  position: absolute;
  top: -${ARROW_SIZE}px;
  width: ${ARROW_SIZE}px;
  height: ${ARROW_SIZE}px;
  svg {
    width: ${ARROW_SIZE}px;
    height: ${ARROW_SIZE}px;
  }
  ${p =>
    p.left &&
    `
    right: calc(50% - ${GUTTER / 2}px);
    svg { transform: scaleX(-1); }
  `}
  ${p =>
    p.right &&
    `
    left: calc(50% - ${GUTTER / 2}px);
  `}
`;

const AppZeroStateRow = ({ style, arrow, right, left, children } = {}) => {
  return (
    <Wrapper style={style}>
      {arrow && (
        <DottedArrowContainer left={left} right={right}>
          <DottedArrow />
        </DottedArrowContainer>
      )}

      {right && <Side />}

      <Content>{children}</Content>

      {left && <Side />}
    </Wrapper>
  );
};

AppZeroStateRow.propTypes = {
  style: PropTypes.object,
  arrow: PropTypes.bool,
  left: PropTypes.bool,
  right: PropTypes.bool,
  children: PropTypes.any,
};

AppZeroStateRow.defaultProps = {
  arrow: true,
};

export default AppZeroStateRow;
