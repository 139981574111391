import { RootElement, Range, Element } from '@ckeditor/ckeditor5-engine';
import { ObservableMixin } from '@ckeditor/ckeditor5-utils';
import { HighlightAttributeConfig } from '@marketmuse/config/types/editor/plugins/highlightTerms';
import { MISC } from '@marketmuse/config/configs/editor/plugins/highlightTerms';
import { AhoCorasick } from './ahocorasick';

export default class HighlightTermState extends ObservableMixin() {
  public declare highlightAttributeConfig:
    | Record<string, never>
    | HighlightAttributeConfig;

  public declare keysByPriority: string[];
  public declare ahoCorasick: AhoCorasick | null;
  public declare termSearchDict:
    | Record<string, number>
    | Record<string, Record<string, boolean>>;

  constructor() {
    super();
    this.set('ahoCorasick', null);
    this.set('highlightAttributeConfig', {});
    this.set('termSearchDict', {});
    this.set('keysByPriority', []);
  }

  public setTermSearchDict(termSearchDict) {
    const keys = Object.keys(termSearchDict);

    keys.sort(
      (a, b) => termSearchDict[a].priority - termSearchDict[b].priority,
    );

    this.set('termSearchDict', termSearchDict);
    this.set('keysByPriority', keys);
  }

  clearAll(model) {
    const range: Range = model.createRangeIn(
      model.document.getRoot() as RootElement,
    );

    [...range].forEach(treeWalkerValue => {
      const item = treeWalkerValue.item as Element;
      const type = treeWalkerValue.type;
      if (type !== 'elementStart' || !model.schema.checkChild(item, '$text')) {
        return;
      }

      model.enqueueChange({ isUndoable: false }, writer => {
        const items = Array.from(item.getChildren());
        items.forEach(node => {
          if (!node.hasAttribute(MISC.ATTRIBUTE_KEY)) {
            return;
          }

          writer.removeAttribute(MISC.ATTRIBUTE_KEY, node);
        });
      });
    });
  }
}
