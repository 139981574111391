import React, { useEffect, useRef, useReducer, useState } from 'react';
import { isEmpty, isEqual } from 'lodash';
import { twMerge } from 'tailwind-merge';
import { useDebouncedCallback } from 'use-debounce';

import { PolymorphicComponentProps } from '../../Polymorphic';
import { reducer } from './reducer';
import {
  InputPillListActions,
  InputPillListProps,
  InputPillListState,
} from './types';
import { Pill } from './Pill';

export type InputPillListComponentProps<C extends React.ElementType> =
  PolymorphicComponentProps<C, InputPillListProps>;

type InputPillListComponent = <C extends React.ElementType>(
  props: InputPillListComponentProps<C>,
) => React.ReactElement | null;

export const InputPillList: InputPillListComponent = React.memo(
  <C extends React.ElementType>({
    as,
    id,
    name,
    items,
    inputProps,
    onChange,
    validation,
    renderBefore,
    renderAfter,
    pillIconClassName,
    className,
    inputWrapperClassName,
  }: InputPillListComponentProps<C>) => {
    const Component = as || 'input';
    const {
      className: inputClassName,
      placeholder,
      ...restInputProps
    } = inputProps || {};

    const initialState: InputPillListState = {
      inputValue: '',
      items: items || [],
      isKeyReleased: false,
      inputStatus: { valid: true, reason: '' },
    };

    const [state, dispatch] = useReducer(reducer, initialState);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const ref = useRef(null);

    const onAddPillDebounced = useDebouncedCallback(inputValue => {
      dispatch({
        type: InputPillListActions.ADD_NEW_ITEM,
        payload: {
          inputValue,
          validation,
        },
      });
    }, 1500);

    const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === 'Enter') {
        dispatch({
          type: InputPillListActions.ADD_NEW_ITEM,
          payload: {
            inputValue: state.inputValue,
            validation,
          },
        });
        onAddPillDebounced.cancel();
        event.preventDefault();
      }

      if (event.key === 'Backspace') {
        dispatch({ type: InputPillListActions.ON_BACKSPACE });
      }

      dispatch({
        type: InputPillListActions.ON_KEY_PRESS,
        payload: { isKeyReleased: false },
      });
    };

    const onKeyUp = () => {
      dispatch({
        type: InputPillListActions.ON_KEY_PRESS,
        payload: { isKeyReleased: true },
      });
    };

    const inputValueChangeHandler = (inputChange: string) => {
      if (inputChange[inputChange.length - 1] === ',') {
        dispatch({
          type: InputPillListActions.ADD_NEW_ITEM,
          payload: {
            inputValue: inputChange.slice(0, inputChange.length - 1),
            validation,
          },
        });
      } else {
        dispatch({
          type: InputPillListActions.SET_INPUT_VALUE,
          payload: { inputValue: inputChange, validation },
        });
      }
    };

    const removePill = (index: number) => {
      dispatch({
        type: InputPillListActions.REMOVE_ITEMS,
        payload: { tagRemoveIndex: index },
      });
      onAddPillDebounced.cancel();
    };

    useEffect(() => {
      if (items) {
        dispatch({
          type: InputPillListActions.SET_ITEMS,
          payload: { items: items },
        });
      }
    }, [items]);

    useEffect(() => {
      if (onChange && !isEqual(items, state.items)) {
        onChange(state.items);
      }
    }, [items, state.items, onChange]);

    return (
      <>
        {renderBefore}
        <div
          className={twMerge(
            'w-full',
            'px-2',
            'py-2',
            'border',
            'rounded',
            'border-gray-400',
            'min-h-[40px]',
            isInputFocused ? 'border-blue-400 ring-1 ring-blue-400' : '',
            className,
          )}
          onClick={() => {
            ref.current.focus();
          }}
        >
          <div
            className={twMerge(
              ['flex', 'flex-wrap', 'gap-x-1', 'gap-y-2'],
              inputWrapperClassName,
            )}
          >
            {state.items &&
              state.items?.map((item, index) => {
                return (
                  <Pill
                    key={index}
                    color="blue"
                    shade="light"
                    className={[
                      'py-0',
                      'text-sm',
                      'text-gray-900',
                      'flex',
                      'items-center',
                      'min-h-[22px]',
                      'break-all',
                    ]}
                    iconClassName={pillIconClassName}
                    onRemove={() => removePill(index)}
                  >
                    {item}
                  </Pill>
                );
              })}
            <Component
              name={name}
              data-mms--input-pill-list={id}
              className={twMerge(
                'block',
                'flex-grow',
                'focus:border-none',
                'focus:outline-none',
                'focus:ring-transparent',
                'focus:shadow-none',
                'max-h-[22px]',
                'ring-transparent',
                'shadow-none',
                'sm:text-sm',
                !state.inputStatus.valid
                  ? ['border-red-400', 'p-1', 'rounded', 'border']
                  : ['border-none'],
                inputClassName,
              )}
              value={state.inputValue}
              onFocus={() => setIsInputFocused(true)}
              onBlur={() => setIsInputFocused(false)}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              onChange={event => {
                const inputValue = event.target.value;
                inputValueChangeHandler(inputValue);
                onAddPillDebounced(inputValue);
              }}
              placeholder={state?.items?.length === 0 ? placeholder : ''}
              type="text"
              {...restInputProps}
              ref={ref}
            />
          </div>
          {!state.inputStatus.valid && !isEmpty(state.inputStatus?.reason) && (
            <div className={'p-2 text-red-500 text-[10px]'}>
              {state.inputStatus?.reason}
            </div>
          )}
        </div>
        {renderAfter}
      </>
    );
  },
);
