import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// TODO: MMS-290
const Wrapper = styled.div`
  z-index: 1000;
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;

  ${p => p.defineHeight && `
    height: auto;
    max-height: 1000px;
  `}

  ${p => p.hasPadding && `
    padding: ${p.theme.pagePaddingTB}px ${p.theme.pagePaddingLR}px;
  `}
`;

const PageBody = React.forwardRef((props, ref) => (
  <Wrapper
    id={props.id}
    key={props.id}
    hasPadding={props.hasPadding}
    className={props.className}
    style={props.style}
    ref={ref}
  >
    {props.children}
  </Wrapper>
));

PageBody.propTypes = {
  id: PropTypes.string,
  hasPadding: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default PageBody;
