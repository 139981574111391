import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 1px;
  width: 100%;
  background-color: var(--mms-simple-border, ${p => p.theme.generic.light.border});
`;

const SimpleListRowSeparator = () => (
  <Wrapper />
);

SimpleListRowSeparator.propTypes = {};

export default SimpleListRowSeparator;
