import { isEmpty } from 'lodash';
import { twMerge } from 'tailwind-merge';
import {
  Button,
  Popover,
  PopoverProps,
  POPPER_THEME,
  Text,
} from '@marketmuse/components';

export interface ActionPopperProps {
  dataId: string;
  children: JSX.Element;
  title: string;
  content?: React.ReactNode;
  className?: string;
  popoverProps?: PopoverProps;
  buttonProps?: {
    onClick: (event: MouseEvent) => void;
    text: string;
  };
}

const ActionPopper: React.FC<ActionPopperProps> = ({
  children,
  title,
  content,
  dataId,
  className,
  popoverProps,
  buttonProps,
}) => {
  return (
    <Popover
      showArrow={false}
      popOnHover={false}
      theme={POPPER_THEME.light}
      placement="bottom-start"
      {...popoverProps}
      render={({ close }) => (
        <div
          className={twMerge(
            ['w-80', 'p-6', 'mt-1', 'bg-white', 'shadow-low', 'rounded-lg'],
            className,
          )}
        >
          <Text as="h5" className="text-base font-bold">
            {title}
          </Text>
          {content && (
            <Text as="p" className="mt-2 mb-0">
              {content}
            </Text>
          )}
          {!isEmpty(buttonProps) && (
            <div className="w-full mt-4 flex align-middle justify-end gap-3">
              <Button
                variant="none"
                className="text-sm"
                onClick={() => close()}
              >
                Cancel
              </Button>
              <Button
                {...(!isEmpty(dataId)
                  ? { 'data-mms--research-messaging': dataId }
                  : {})}
                variant="blue400"
                className={'text-sm'}
                type="button"
                onClick={event => {
                  close();
                  buttonProps.onClick(event);
                }}
              >
                {buttonProps.text}
              </Button>
            </div>
          )}
        </div>
      )}
    >
      {children}
    </Popover>
  );
};

export default ActionPopper;
