import dayjs from 'dayjs';
import {
  Box,
  Tooltip,
  POPPER_THEME,
  LoadingBlock,
} from '@marketmuse/components';

import { PAPI } from '@marketmuse/config/types';
import { useStoreSelector } from '@marketmuse/data-state/hooks';
import { pluralize } from '@marketmuse/utilities';

import useHasAccess from '../../../hooks/useHasAccess';
import { useSuiteNavigate } from '../../../hooks/useSuiteNavigate';

const serializeAppRuns = (appRuns: {
  limit?: number;
  current?: number;
  extra?: number;
}) => {
  const { limit = 0, current = 0, extra = 0 } = appRuns || {};
  const total = limit + extra;

  return { consumed: current, total: total };
};

const ConsumptionBlock = () => {
  const navigate = useSuiteNavigate();
  const documentAllotment = useStoreSelector(
    state => state?.user?.org?.documentAllotment,
  );
  const appRuns = useStoreSelector(state => state.misc?.counts?.appRuns);
  const queries = serializeAppRuns(appRuns);

  const showAppRuns = useHasAccess({
    resource: PAPI.PermissionResource.SUBSCRIPTION,
    action: PAPI.PermissionAction.USE_APP_RUNS,
  });

  const showAnalysisPlanning = useHasAccess({
    resource: PAPI.PermissionResource.DOCUMENTS,
    action: PAPI.PermissionAction.CREATE_PLANNING_ANALYSIS,
  });

  const showBriefing = useHasAccess({
    resource: PAPI.PermissionResource.DOCUMENTS,
    action: PAPI.PermissionAction.CREATE_BRIEF,
  });

  const remainingBriefs = Math.max(
    0,
    documentAllotment.briefs.total - documentAllotment.briefs.created,
  );
  const remainingAnalysisPlanning = Math.max(
    0,
    documentAllotment.analysisPlanning.total -
      documentAllotment.analysisPlanning.created,
  );

  if (!showAppRuns && !showAnalysisPlanning && !showBriefing) {
    return null;
  }

  return (
    <Box
      className={[
        'w-full',
        'px-5',
        'py-8',
        'flex',
        'flex-row',
        'gap-2',
        'justify-stretch',
        'bg-gray-800',
      ]}
    >
      <Box
        className={['font-semibold', 'w-full', 'cursor-pointer']}
        onClick={() => {
          navigate('/settings/subscription', {
            omitSiteId: true,
            state: {
              defaultTab: 'other',
            },
          });
        }}
      >
        <table className="w-full">
          <thead>
            <tr>
              <td className="text-right" colSpan={2}>
                <Tooltip
                  placement="right"
                  content={
                    <span className="block w-[200px]">
                      These amounts will reset next{' '}
                      <span className="whitespace-nowrap">
                        {dayjs(documentAllotment?.resetDate?.next)
                          .utc(true)
                          .add(dayjs().utcOffset(), 'minute')
                          .format('MMM D, YYYY h:mma')}
                      </span>
                      .
                    </span>
                  }
                >
                  <span>Remaining this month</span>
                </Tooltip>
              </td>
            </tr>
          </thead>
          <tbody>
            {showAppRuns && (
              <tr>
                <td>Queries</td>
                <td className="text-right">
                  {queries.total ? (
                    queries.total - queries.consumed
                  ) : (
                    <LoadingBlock
                      loadingTheme="dark"
                      className={[
                        'rounded',
                        'h-3',
                        'w-4',
                        'ml-[2px]',
                        'inline-block',
                      ]}
                    />
                  )}
                </td>
              </tr>
            )}
            {(showAnalysisPlanning || showBriefing) && (
              <>
                {showBriefing && (
                  <tr>
                    <td>Briefs:</td>
                    <td className="text-right">
                      <Tooltip
                        content={
                          <>
                            {documentAllotment.briefs.created === 0 &&
                              'You have not created any briefs this month.'}
                            {documentAllotment.briefs.created !== 0 && (
                              <>
                                You have created{' '}
                                <strong>
                                  {documentAllotment.briefs.created}
                                </strong>{' '}
                                {pluralize(
                                  'brief',
                                  documentAllotment.briefs.created,
                                )}{' '}
                                this month, and can create{' '}
                                <stong>{remainingBriefs}</stong> more.
                              </>
                            )}
                          </>
                        }
                        placement="right"
                        theme={POPPER_THEME.dark}
                      >
                        <span>{remainingBriefs}</span>
                      </Tooltip>
                    </td>
                  </tr>
                )}
                {showAnalysisPlanning && (
                  <tr>
                    <td>Planning/Analysis:</td>
                    <td className="text-right">
                      <Tooltip
                        content={
                          <>
                            {documentAllotment.analysisPlanning.created === 0 &&
                              'You have not created any planning or analysis month.'}
                            {documentAllotment.analysisPlanning.created !==
                              0 && (
                              <>
                                You have created{' '}
                                <strong>
                                  {documentAllotment.analysisPlanning.created}
                                </strong>{' '}
                                planning or analysis{' '}
                                {pluralize(
                                  'document',
                                  documentAllotment.analysisPlanning.created,
                                )}{' '}
                                this month, and can create{' '}
                                <strong>{remainingAnalysisPlanning}</strong>{' '}
                                more.
                              </>
                            )}
                          </>
                        }
                        placement="right"
                        theme={POPPER_THEME.dark}
                      >
                        <span>{remainingAnalysisPlanning}</span>
                      </Tooltip>
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </Box>
    </Box>
  );
};

export default ConsumptionBlock;
