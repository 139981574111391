import { fetch, Headers } from 'cross-fetch';
import { htmlToWord } from './htmlToWord';

const driveUploadUrl =
  'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id';

const uploadDocToDrive = async ({
  filename,
  data,
  accessToken,
}: {
  filename?: string;
  data: string;
  accessToken?: string;
}) => {
  const file = await htmlToWord({ data });

  const form = new FormData();
  const dateString = new Date().toLocaleDateString('en-US');
  const documentName = `${dateString}-marketmuse-${filename}`;

  const metadata = {
    name: documentName,
    mimeType: 'application/vnd.google-apps.document',
    parents: ['root'],
  };

  form.append(
    'metadata',
    new Blob([JSON.stringify(metadata)], { type: 'application/json' }),
  );
  form.append('file', file);

  return fetch(driveUploadUrl, {
    method: 'POST',
    headers: new Headers({ Authorization: 'Bearer ' + accessToken }),
    body: form,
  });
};

export default uploadDocToDrive;
