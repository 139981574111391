import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import IconLoader from 'react-feather/dist/icons/loader';

const spin = keyframes`
  to { transform: rotate(360deg); }
`;

const IconLoaderWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  color: ${p => p.theme.colors[p.color || 'grey25']};
  animation: ${spin} 1.6s linear infinite;

  svg {
    width: 14px;
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

const Loader = ({ color, style, className }) => (
  <IconLoaderWrapper
    color={color}
    style={style}
    className={className}
    aria-label="Loading"
  >
    <IconLoader />
  </IconLoaderWrapper>
);

Loader.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
  gif: PropTypes.bool,
  className: PropTypes.string,
};

Loader.defaultProps = {
  style: {},
};

export default Loader;
