import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApolloQueryResult } from '@apollo/client';
import { isEmpty, isUndefined, isNull } from 'lodash';

import { sanitizeTerm } from '@marketmuse/utilities';
import type { InventorySeoTopicsQuery } from '@marketmuse/config/types/papi';
import { InventorySeoTopicsDocument } from '@marketmuse/data-papi';

import {
  ThunkApiConfig,
  ResearchThunkInvItemsInput,
  ResearchThunkInvItemsRes,
  ResearchInventoryTopic,
} from '../../types';

interface InvItemsThunkWrapperAPI {
  typePrefix: string;
}

export function createInventoryItemsThunk<
  T extends { term: string; volume?: number },
>({ typePrefix }: InvItemsThunkWrapperAPI) {
  return createAsyncThunk<
    ResearchThunkInvItemsRes,
    ResearchThunkInvItemsInput,
    ThunkApiConfig
    // eslint-disable-next-line
    // @ts-ignore
  >(typePrefix, async (payload, { extra, rejectWithValue }) => {
    const { items, siteId } = payload;
    if (!items.length) {
      return { data: [] };
    }

    const { terms, hash }: { terms: string[]; hash: Record<string, number> } =
      items.reduce(
        (acc, item: T) => {
          const next = {
            terms: [...acc.terms, item.term],
            hash: { ...acc.hash, [sanitizeTerm(item.term)]: item.volume },
          };
          return next;
        },
        { terms: [], hash: {} },
      );

    const cleanTerms = terms.filter(
      term => !isUndefined(term) && !isNull(term) && !isEmpty(term),
    );

    const response: ApolloQueryResult<InventorySeoTopicsQuery> =
      await extra.apollo.query({
        query: InventorySeoTopicsDocument,
        variables: {
          limit: cleanTerms.length,
          terms: cleanTerms,
          siteId,
        },
      });

    const error = response.errors;
    if (error) {
      return rejectWithValue(error);
    }

    const topics: ResearchInventoryTopic[] =
      response?.data?.inventoryItems.items
        .map(item => item.topic)
        .map(topic => {
          return {
            ...topic,
            volume: hash[sanitizeTerm(topic.term)],
            existsInInventory: true,
          };
        });

    return {
      data: topics,
    };
  });
}
