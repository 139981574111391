import { WordCountCalculateFn } from '../types';

const calculate: WordCountCalculateFn = (current, goal) => {
  const percent = (current / goal) * 100;
  if (percent < 50) return 'red';
  if (percent < 80) return 'yellow';
  return 'green';
};

export default calculate;
