import { isArray } from 'lodash';
import React, { memo } from 'react';
import { Polymorphic } from '../Polymorphic';
import { IconLoader } from './IconLoader';
import { SUPPORTED_ICON } from './types';

export interface IconProps extends React.HtmlHTMLAttributes<HTMLElement> {
  as?: React.ElementType<any>;
  boxSize?: number;
  iconSize?: number;
  name: SUPPORTED_ICON;
  title?: string;
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  boxRole?: string;
}

type IconComponentType = (props: IconProps) => React.ReactElement | null;

const IconComponent: IconComponentType = ({
  as,
  boxSize = 16,
  iconSize = 16,
  name,
  onClick,
  title,
  color,
  className = '',
  style,
  boxRole,
  ...rest
}: IconProps) => {
  const classNameArray: string[] = isArray(className)
    ? (className as string[])
    : [className];

  const element = as || 'span';

  return (
    <Polymorphic
      as={element}
      className={[
        ...classNameArray,
        'inline-flex',
        'items-center',
        'justify-center',
        'flex-shrink-0',
      ]}
      role={boxRole || 'presentation'}
      aria-label={title || name}
      onClick={onClick}
      style={{ ...style, width: `${boxSize}px`, height: `${boxSize}px` }}
    >
      <IconLoader
        name={name}
        title={title}
        size={iconSize}
        className={color}
        {...rest}
      />
    </Polymorphic>
  );
};

export const Icon = memo(IconComponent);
