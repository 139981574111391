import { createAction } from '@reduxjs/toolkit';

import { Country } from '@marketmuse/config/types/papi';

import { AppQueryParams, OptimizeSnapshotInput } from '../types';

export const optimizeTaskStatusCheck = createAction('optimize/task/status');
export const optimizeTaskInitialize = createAction<AppQueryParams>(
  'optimize/task/initialize',
);
export const optimizeTaskFetchPage = createAction<{ url: string }>(
  'optimize/task/fetchPage',
);
export const optimizePageError = createAction<{ url?: string }>(
  'optimize/task/pageError',
);
export const optimizeTaskSiteChange = createAction<{
  country: Country;
  siteId: string;
}>('optimize/task/siteChange');
export const optimizeTaskHydrateCheck = createAction<{
  country: Country;
  siteId: string;
}>('optimize/task/hydrateCheck');
export const optimizeTaskQueryLoad = createAction<OptimizeSnapshotInput>(
  'optimize/task/queryLoad',
);
export const optimizeTaskQueryUpsert = createAction<{
  fetch: boolean;
  params: AppQueryParams;
}>('optimize/task/queryUpsert');
