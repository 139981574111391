import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as types from '../../config/types';
import * as miscActions from '../../actions/miscActions';

import MessagePopup from '../../components/MessagePopup';

class LockedFeatureWarning extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      inquirySent: false,
    };

    this.close = this.close.bind(this);
  }

  close() {
    this.setState({ inquirySent: false });
    this.props.miscActions.toggleDynamic({
      id: 'upgradeWarning',
      status: false,
    });
  }

  render() {
    const title = 'This is a premium feature';
    const desc =
      'Get access to more workflows, briefs, and content. Talk to a strategist today to unlock even more power from MarketMuse.';

    const titleSent = 'Thanks!';
    const descSent =
      'Your Personal Strategist will reach out to you shortly so you can learn about all that MarketMuse Pro has to offer!';

    const isLoading = this.props.loadings[types.INQUIRE_PREMIUM];
    const inquirySent = this.state.inquirySent;

    return (
      <MessagePopup
        title={inquirySent ? titleSent : title}
        description={inquirySent ? descSent : desc}
        cta={
          inquirySent
            ? 'Dismiss'
            : isLoading
            ? 'Loading...'
            : 'Talk to A Strategist'
        }
        ctaProps={{ disabled: isLoading }}
        close={this.close}
        ctaClick={() => {
          if (inquirySent) this.close();
          else {
            this.props.miscActions.inquirePremium({
              planLevel: 'premium',

              callback: () => {
                this.setState({ inquirySent: true });
              },
            });
          }
        }}
      />
    );
  }
}

LockedFeatureWarning.propTypes = {
  miscActions: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => ({
  loadings: state.loadings,
});

const mapDispatchToProps = dispatch => ({
  miscActions: bindActionCreators(miscActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LockedFeatureWarning);
