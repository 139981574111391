import { original } from '@reduxjs/toolkit';
import { uniq } from 'lodash';

export const getLastConnectDomains = params => {
  const competitorDomains = params.competitorDomains
    ? original(params.competitorDomains)
    : [];
  const networkDomains = params.networkDomains
    ? original(params.networkDomains)
    : [];

  return {
    competitorDomains,
    networkDomains,
  };
};

export const mergeConnectDomains = ({
  competitorDomains,
  competitorDomainsLast,
  networkDomains,
  networkDomainsLast,
}) => {
  const nextCompetitorDomains = uniq([
    ...(competitorDomains ? competitorDomains : []),
    ...competitorDomainsLast,
  ]);

  const nextNetworkDomains = uniq([
    ...(networkDomains ? networkDomains : []),
    ...networkDomainsLast,
  ]);

  return {
    competitorDomains: nextCompetitorDomains,
    networkDomains: nextNetworkDomains,
  };
};

export const shouldRemoveConnectDomain =
  (next: Array<string>, last: Array<string>) => string => {
    const shouldRemove = !next.includes(string) && last.includes(string);

    return shouldRemove ? false : string;
  };
