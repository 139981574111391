import React from 'react';

import { twMerge } from 'tailwind-merge';
import { Box } from '../Box';

interface LoadingBlockProps {
  className?: string | Array<string>;
  style?: React.CSSProperties;
  loadingTheme?: 'dark' | 'light';
}

export const LoadingBlock = ({
  className,
  style,
  loadingTheme = 'light',
}: LoadingBlockProps) => {
  return (
    <Box
      className={twMerge([
        'w-full',
        'h-8',
        loadingTheme === 'light' && 'animate-loading-gray-100',
        loadingTheme === 'dark' && 'animate-loading-gray-700',
        className,
      ])}
      style={style}
    />
  );
};
