import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { statBandMentions } from '@marketmuse/utilities';

import Tile from '../Tile';

const Wrapper = styled(Tile)`
  background-color: ${p => p.theme[p.color || 'mmxGrey']};
  * {
    color: white;
  }
  border-radius: 8px;
  height: 32px;
  min-height: 32px;
  margin: 8px;
`;

const DistributionTile = ({ user, value, valueTitle, className }) => {
  const { color, band } = statBandMentions.calculate(value);
  return (
    <Wrapper
      color={color}
      valueTitle={valueTitle || 'Distribution'}
      valueContent={user.displayExactDistribution ? value : band}
    />
  );
};

const mapStateToProps = (state, ownProps) => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  // actions: bindActionCreators(actions, dispatch)
});

DistributionTile.propTypes = {
  user: PropTypes.object,
  value: PropTypes.number,
  valueTitle: PropTypes.string,
  className: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(DistributionTile);
