import { ApolloQueryResult } from '@apollo/client';
import { pick, isEmpty } from 'lodash';
import type { SeoPageProcessorQuery } from '@marketmuse/config/types/papi';
import { SeoPageProcessorDocument } from '@marketmuse/data-papi';
import { isPageUrl } from '@marketmuse/utilities';
import { ThunkApiConfig } from '../../types';
import createNonConcurrentAsyncThunk from '../../utils/createNonConcurrentAsyncThunk';

const getPageContents = createNonConcurrentAsyncThunk<
  { data: string },
  { url: string },
  ThunkApiConfig
>('optimize/getPageContents', async (params, { extra, rejectWithValue }) => {
  const { url } = pick(params, ['url']);
  const { valid, reason } = isPageUrl(url);
  if (!valid) {
    return rejectWithValue(reason);
  }

  const response: ApolloQueryResult<SeoPageProcessorQuery> =
    await extra.apollo.query({
      query: SeoPageProcessorDocument,
      variables: {
        url,
      },
    });

  const data = response?.data?.seoPageProcessor;
  const error = response.errors;

  if (error || isEmpty(data.html) || data.statusCode !== 200) {
    extra.apollo.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'seoPageProcessor',
      args: { url },
    });
    extra.apollo.cache.gc();
    return rejectWithValue(error);
  }
  return {
    data: data.html,
  };
});

export default getPageContents;
