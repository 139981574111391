import { FC, ReactNode } from 'react';

import { PAPI } from '@marketmuse/config/types';
import {
  checkSiteViableInventory,
  validatePermission,
} from '@marketmuse/utilities';
import { useStoreSelector } from '@marketmuse/data-state/hooks';

import { ResearchPermissionsContext } from '../context';
import { IResearchPermissionsContext } from '../types';

const ResearchPermissionsProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const site = useStoreSelector(state => state?.filter?.site);
  const versions = useStoreSelector(state => state?.filter?.versions);
  const permissions = useStoreSelector(state => state.permissions);

  const canDisplaySiteContentData = Boolean(site.displayContentData);

  const canAccessConnect = validatePermission({
    permissions,
    permission: {
      resource: PAPI.PermissionResource.RESEARCH_CONNECT,
      action: PAPI.PermissionAction.ACCESS_DATA,
    },
  });
  const canAccessReflect = validatePermission({
    permissions,
    permission: {
      resource: PAPI.PermissionResource.RESEARCH_REFLECT,
      action: PAPI.PermissionAction.ACCESS_DATA,
    },
  });
  const canAccessHeatmapWebsite = validatePermission({
    permissions,
    permission: {
      resource: PAPI.PermissionResource.RESEARCH_HEATMAP_WEBSITE,
      action: PAPI.PermissionAction.ACCESS_DATA,
    },
  });
  const canAccessHeatmapSerp = validatePermission({
    permissions,
    permission: {
      resource: PAPI.PermissionResource.RESEARCH_HEATMAP,
      action: PAPI.PermissionAction.ACCESS_DATA,
    },
  });

  const canAccessXray = validatePermission({
    permissions,
    permission: {
      resource: PAPI.PermissionResource.RESEARCH_SERP_X_RAY,
      action: PAPI.PermissionAction.ACCESS_DATA,
    },
  });

  const canAddToInventory = validatePermission({
    permissions,
    permission: {
      resource: PAPI.PermissionResource.SITE_INVENTORY,
      action: PAPI.PermissionAction.ADD_TO_INVENTORY,
    },
  });
  const canExportConnect = validatePermission({
    permissions,
    permission: {
      resource: PAPI.PermissionResource.RESEARCH_CONNECT,
      action: PAPI.PermissionAction.EXPORT,
    },
  });
  const canExportHeatmap = validatePermission({
    permissions,
    permission: {
      resource: PAPI.PermissionResource.RESEARCH_HEATMAP,
      action: PAPI.PermissionAction.EXPORT,
    },
  });
  const canExportSerpXray = validatePermission({
    permissions,
    permission: {
      resource: PAPI.PermissionResource.RESEARCH_SERP_X_RAY,
      action: PAPI.PermissionAction.EXPORT,
    },
  });
  const canExportTopicNavigator = validatePermission({
    permissions,
    permission: {
      resource: PAPI.PermissionResource.RESEARCH_TOPIC_NAVIGATOR,
      action: PAPI.PermissionAction.EXPORT,
    },
  });
  const canFilterSerpXray = validatePermission({
    permissions,
    permission: {
      resource: PAPI.PermissionResource.RESEARCH_SERP_X_RAY,
      action: PAPI.PermissionAction.FILTER,
    },
  });
  const canFilterTopicNavigator = validatePermission({
    permissions,
    permission: {
      resource: PAPI.PermissionResource.RESEARCH_TOPIC_NAVIGATOR,
      action: PAPI.PermissionAction.FILTER,
    },
  });
  const shouldGateKeywords = !validatePermission({
    permissions,
    permission: {
      resource: PAPI.PermissionResource.RESEARCH_KEYWORDS,
      action: PAPI.PermissionAction.ACCESS_DATA,
    },
  });
  const shouldGateQuestions = !validatePermission({
    permissions,
    permission: {
      resource: PAPI.PermissionResource.RESEARCH_QUESTIONS,
      action: PAPI.PermissionAction.ACCESS_DATA,
    },
  });
  const canSortHeatmap = validatePermission({
    permissions,
    permission: {
      resource: PAPI.PermissionResource.RESEARCH_HEATMAP,
      action: PAPI.PermissionAction.SORT,
    },
  });

  const shouldLimitDataTopicNavigator = !validatePermission({
    permissions,
    permission: {
      resource: PAPI.PermissionResource.RESEARCH_TOPIC_NAVIGATOR,
      action: PAPI.PermissionAction.ACCESS_FULL_DATA,
    },
  });

  const researchPermissions: IResearchPermissionsContext = {
    canAccessHeatmapWebsite,
    canAddToInventory,
    canDisplaySiteContentData,
    canExportConnect,
    canExportHeatmap,
    canExportSerpXray,
    canExportTopicNavigator,
    canFilterSerpXray,
    canFilterTopicNavigator,
    canSortHeatmap,
    hasViableSiteInventory: checkSiteViableInventory({
      invId: site.invId,
      versions,
      permissions,
    }),
    shouldGateConnect: !canAccessConnect,
    shouldGateKeywords,
    shouldGateQuestions,
    shouldGateReflectTable: !canAccessReflect,
    canAccessHeatmapSerp,
    shouldLimitDataTopicNavigator: shouldLimitDataTopicNavigator,
    canAccessXray: canAccessXray,
  };

  return (
    <ResearchPermissionsContext.Provider value={researchPermissions}>
      {children}
    </ResearchPermissionsContext.Provider>
  );
};

export default ResearchPermissionsProvider;
