import { useEffect, useState } from 'react';
import urlParams from '../utils/urlParams';

const stripQuotes = value =>
  value.replace(/(^"|"$)/g, '').replace(/(^'|'$)/g, '');

export function useParamsToStorage(keys) {
  useEffect(() => {
    keys.forEach(key => {
      const value = urlParams.getRaw(key);
      if (value) {
        if (typeof value === 'string') {
          sessionStorage.setItem(key, stripQuotes(value));
        } else {
          sessionStorage.setItem(key, JSON.stringify(value));
        }
      }
    });
  }, [keys]);
}

export function useParamsFromStorage(keys) {
  const [params, setParams] = useState({});
  useEffect(() => {
    const nextState = keys.reduce((acc, key) => {
      let value =
        sessionStorage.getItem(key) || sessionStorage.getItem(`query.${key}`);

      try {
        value = JSON.parse(value);
      } catch (error) {
        // expected error case if is not stringified json
      }

      return {
        ...acc,
        ...(value ? { [key]: stripQuotes(value) } : {}),
      };
    }, {});

    setParams(nextState);
  }, [keys]);

  return params;
}
