import {
  InventoryType,
  SortDirection,
  InventorySortFields,
  UserFieldsBaseFragment,
  SavedViewSharing,
} from '@marketmuse/config/types/papi';

type FilterKeys = 'field' | 'operand' | 'qualifier' | 'value';
type Filters = Array<Record<FilterKeys, 'string' | null | unknown>>;

type TemporarySavedView = {
  createdAt: Date;
  createdBy: UserFieldsBaseFragment;
  description: string;
  filters: Filters;
  hidden: Record<string, boolean>;
  id: string;
  inventoryType: InventoryType;
  order: string[];
  pinned: Record<string, boolean>;
  sharing: SavedViewSharing;
  sorting: InventorySortFields;
  title: string;
  updatedBy: UserFieldsBaseFragment;
};

type InventoryDataTypes =
  | InventoryType.PAGES
  | InventoryType.PAGE_TOPICS
  | InventoryType.TOPICS;

const defaultsHidden: Record<InventoryDataTypes, TemporarySavedView['hidden']> =
  {
    [InventoryType.PAGES]: {
      url: false,
    },
    [InventoryType.PAGE_TOPICS]: {
      url: false,
      topic: false,
    },
    [InventoryType.TOPICS]: {
      topic: false,
    },
  };

const defaultSorting: Record<
  InventoryDataTypes,
  TemporarySavedView['sorting']
> = {
  [InventoryType.PAGES]: {
    field: 'url',
    direction: SortDirection.ASC,
    table: 'pages',
  },
  [InventoryType.PAGE_TOPICS]: {
    field: 'term',
    direction: SortDirection.ASC,
    table: 'topics',
  },
  [InventoryType.TOPICS]: {
    field: 'term',
    direction: SortDirection.ASC,
    table: 'topics',
  },
};

const defaultOrder: Record<InventoryDataTypes, TemporarySavedView['order']> = {
  [InventoryType.PAGES]: ['url', 'rank'],
  [InventoryType.PAGE_TOPICS]: ['url', 'topic', 'rank'],
  [InventoryType.TOPICS]: ['topic', 'rank'],
};

const defaultPinned: Record<InventoryDataTypes, TemporarySavedView['pinned']> =
  {
    [InventoryType.PAGES]: {
      url: false,
    },
    [InventoryType.PAGE_TOPICS]: {
      url: false,
      topic: false,
    },
    [InventoryType.TOPICS]: {
      topic: false,
    },
  };

/**
 * Create static version of a saved view, with user/sharing setting to indicate non editable
 *
 * @returns A temporary saved view for the given title and url.
 */
export function buildTemporarySavedView({
  filters,
  hidden: _hidden,
  inventoryType,
  order: _order,
  pinned: _pinned,
  sorting: _sorting,
  title,
}: {
  filters: Filters;
  hidden?: Record<string, boolean>;
  inventoryType: InventoryDataTypes;
  order?: string[];
  pinned?: Record<string, boolean>;
  sorting?: InventorySortFields;
  title: string;
}): TemporarySavedView {
  const temporaryUser = {
    id: 'no-reply@marketmuse.com',
    email: 'no-reply@marketmuse.com',
    fullName: 'MarketMuse',
  };

  const hidden = _hidden || defaultsHidden[inventoryType];
  const sorting = _sorting || defaultSorting[inventoryType];
  const pinned = _pinned || defaultPinned[inventoryType];
  const order = _order || defaultOrder[inventoryType];

  return {
    createdAt: new Date(),
    createdBy: temporaryUser,
    description: title,
    filters,
    hidden,
    id: 'unsaved-temporary-view',
    inventoryType,
    order,
    pinned,
    sharing: SavedViewSharing.PUBLIC,
    sorting,
    title: title,
    updatedBy: temporaryUser,
  };
}
