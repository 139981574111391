import React from 'react';

import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(0.96); }
  100% { transform: scale(1); }
`;

const Wrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  color: ${p => p.theme.colors[p.color || 'blue']};
`;

const Svg = styled.svg`
  display: block;
  width: 100%;
  height: 100%;
  animation: ${pulse} 2000ms infinite linear;
  transform-origin: 50% 50%;
  color: currentColor;
`;

const Path = styled.path`
  transform-origin: 50% 50%;
  animation: ${fadeIn} 2000ms infinite ease-in-out;

  &.mms--loader-right {
    animation-duration: 2200ms;
    animation-delay: -1220ms;
  }

  &.mms--loader-bottom {
    animation-duration: 2500ms;
    animation-delay: -246ms;
  }

  &.mms--loader-top {
    animation-duration: 2300ms;
    animation-delay: -1040ms;
  }
`;

const pathProps = {
  fill: 'currentColor',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
};

const LoaderSparkles = ({ ...props }) => (
  <Wrapper {...props}>
    <Svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        {...pathProps}
        className="mms--loader-right"
        d="M21.0107 14.4039L25.9995 12.6841L21.0107 10.9642L19.3478 5.80469L17.6848 10.9642L12.696 12.6841L17.6848 14.4039L19.3478 19.5635L21.0107 14.4039Z"
      />
      <Path
        {...pathProps}
        className="mms--loader-bottom"
        d="M8.42386 21.6278L12.2782 20.2991L8.42386 18.9704L7.13909 14.9841L5.85432 18.9704L2 20.2991L5.85432 21.6278L7.13909 25.614L8.42386 21.6278Z"
      />
      <Path
        {...pathProps}
        className="mms--loader-top"
        d="M8.12227 6.69765L11.0697 5.68156L8.12227 4.66547L7.13979 1.61719L6.15732 4.66547L3.2099 5.68156L6.15732 6.69765L7.13979 9.74594L8.12227 6.69765Z"
      />
    </Svg>
  </Wrapper>
);
export default LoaderSparkles;
