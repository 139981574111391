import React, { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import { statBandRank } from '@marketmuse/utilities';
import { Polymorphic } from '../../Polymorphic';

import { Size } from './types';
import { getFontSize, getHeight, getRounded, getWidth } from './utils';

export type RankProps = {
  value?: number | string;
  size?: Size;
  className?: string | Array<string | string[]>;
  as?: React.ElementType<HTMLElement>;
};

export const Rank = React.forwardRef<HTMLDivElement, RankProps>(
  ({ as, className, value, size = 18, ...rest }, ref) => {
    const element = as || 'div';

    const classNameArray: string[] = Array.isArray(className)
      ? (className as string[])
      : [className];

    const bgColor = useMemo(() => statBandRank.backgroundColor(value), [value]);
    const textColor = useMemo(() => statBandRank.textColor(value), [value]);
    const width = useMemo(() => getWidth(size), [size]);
    const height = useMemo(() => getHeight(size), [size]);
    const fontSize = useMemo(() => getFontSize(size), [size]);
    const rounded = useMemo(() => getRounded(size), [size]);

    return (
      <Polymorphic
        {...rest}
        ref={ref}
        as={element}
        className={twMerge([
          ...[
            'px-1',
            'font-bold',
            'flex',
            'flex-shrink-0',
            'flex-grow-0',
            'items-center',
            'justify-center',
            'truncate',
            'lining-nums',
          ],
          textColor,
          bgColor,
          width,
          height,
          fontSize,
          rounded,
          ...classNameArray,
          'leading-normal',
        ])}
      >
        {value || <span className="text-gray-500">NR</span>}
      </Polymorphic>
    );
  },
);

export default Rank;
