import { parse as parseURL } from 'url';

export const isDomain = (url: string): boolean => {
  const clean = url.trim();
  const regexp =
    /^(((http|https):\/\/)?([a-z0-9|-]+\.)*[a-z0-9|-]+\.[a-z]+(\/)?)$/;
  if (!clean) return false;
  return regexp.test(clean);
};

export const validateDomainWithReason = (
  url: string,
): { valid: boolean; reason: string } => {
  const cleanUrl = url.trim();
  const urlObj = parseURL(cleanUrl);

  const tldRegex = /.*\.\b/;
  const isTldExist = tldRegex.test(cleanUrl);

  if (!isTldExist) {
    return {
      valid: false,
      reason: 'Please enter a valid domain with a valid top-level domain.',
    };
  }

  if (urlObj.protocol !== null) {
    if (!urlObj?.protocol?.includes('http')) {
      return {
        valid: false,
        reason: 'Protocol is invalid. Please use http or https.',
      };
    }
  }
  if (!isDomain(cleanUrl)) {
    return {
      valid: false,
      reason: 'Please enter a valid domain.',
    };
  }
  return {
    valid: true,
    reason: 'Domain is valid',
  };
};
