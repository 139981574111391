import { get, set, omit, trim, isEmpty } from 'lodash';

import { InventoryUpdateDocument } from '@marketmuse/data-papi';
import { RESEARCH_UPDATE_SITE_CONNECT_DOMAINS } from '@marketmuse/data-state/actionTypes';
import Toast from '../../components/Toast';
import { saveOrgData } from '../../actions/authenticateActions';
import { updateActiveInventory } from '../../actions/inventoryActions';
import * as types from '../../config/types';
import makeRequest from '../../utils/makeRequest';

export default () =>
  ({ dispatch, getState }) =>
  next =>
  action => {
    const state = getState();
    if (action.type === types.INVENTORY_UPDATE) {
      const { fields, updateSiteId } = action;
      const lastNetworkDomains = get(state, 'filter.site.networkDomains') || [];
      const lastCompetitorDomains =
        get(state, 'filter.site.competitorDomains') || [];

      if (fields.networkDomains) {
        set(
          fields,
          'networkDomains',
          fields.networkDomains
            .map(url => trim(url))
            .filter(url => !isEmpty(url)),
        );
      }
      if (fields.competitorDomains) {
        set(
          fields,
          'competitorDomains',
          fields.competitorDomains
            .map(url => trim(url))
            .filter(url => !isEmpty(url)),
        );
      }

      makeRequest({
        type: types.INVENTORY_UPDATE,
        mutation: InventoryUpdateDocument,
        variables: {
          // authorization
          siteId: updateSiteId || get(state, 'filter.site.id'),
          siteInput: fields,
        },
        keys: Object.keys(fields || {})
          .concat(action.keys || [])
          .map(key => `inv_update_${key}`)
          .concat(`inv_update_${updateSiteId}`),
      }).then(res => {
        if (res.error) {
          Toast.fire(res.error || 'Unknown error', 'error');
        } else {
          const data = get(res, 'siteUpdate', {});
          const toastMessage = action.customToast
            ? action.customToast
            : `Inventory successfully updated.`;

          if (data?.org?.id) {
            const updatedSite = omit(data, ['org']);
            // update state if updated org is users own org
            if (data.org.id === get(state, 'user.org.id')) {
              const sites = get(state, 'user.org.sites') || [];
              const siteIndex = sites.map(s => s.id).indexOf(updateSiteId);
              let sitesNext = [...sites];
              sitesNext[siteIndex] = {
                ...sitesNext[siteIndex],
                ...fields,
              };
              dispatch(
                saveOrgData({
                  ...data.org,
                  sites: sitesNext,
                }),
              );
            }

            // Update 'site' in filter
            if (updateSiteId === get(state, 'filter.site.id')) {
              const { competitorDomains, networkDomains } = data;
              if (competitorDomains || networkDomains) {
                const payload = {
                  next: {
                    networkDomains: networkDomains || [],
                    competitorDomains: competitorDomains || [],
                  },
                  last: {
                    networkDomains: lastNetworkDomains,
                    competitorDomains: lastCompetitorDomains,
                  },
                };

                dispatch({
                  type: RESEARCH_UPDATE_SITE_CONNECT_DOMAINS,
                  payload,
                });
              }
              dispatch(
                updateActiveInventory({
                  updatedFields: fields,
                  siteId: updateSiteId,
                }),
              );
            }
            Toast.fire(toastMessage);

            if (action.callback)
              action.callback({
                ...data.org,
                sites: [{ ...updatedSite }],
              });
          }
        }
      });
    }

    return next(action);
  };
