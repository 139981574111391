import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../../components/Button';
import Box from '../../../../../components/Box';
import Text from '../../../../../components/Text';

import { valuesShape } from '../filterFactoryTypes';

const FilterSwitch = ({ values = [], onChange }) => (
  <Box
    boxVariant="flex"
    width="100%"
    justifyContent="space-between"
    flex-wrap="wrap"
  >
    {values.map((item, i) => (
      <Button
        key={item.id}
        border
        style={{
          marginBottom: '12px',
          border: `1px solid ${item.active ? '#359ff4' : '#e9e9e9'}`,
        }}
        xLarge
        secondary={item.active}
        tertiary={!item.active}
        onClick={() => onChange(item.id)}
      >
        <Text textVariant="textRegular">{item.title}</Text>
      </Button>
    ))}
  </Box>
);

FilterSwitch.propTypes = {
  onChange: PropTypes.func,
  values: PropTypes.arrayOf(valuesShape),
};

export default memo(FilterSwitch);
