import download from 'downloadjs';

export const createDownload = (data: string, name = 'results') => {
  const dateTime = new Date().toLocaleDateString('en-US');
  const mimeType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  const content = `data:${mimeType};base64,${data}`;

  const filename = [dateTime, name];

  download(content, `${filename.join('-')}.xlsx`, mimeType);
};
