import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import Box from '../../../../../components/Box';

import COUNTRY_CODES from '../../../../../config/countryCodes';
import {
  allowedLanguages,
  LANGUAGES_CODES,
} from '../../../../../config/countryLanguageCombinations';
import FilterDataList from './FilterDataList';
import FilterDropdown from './FilterDropdown';

const CountryLanguage = ({
  countries = [],
  activeCountryCode,
  activeLanguageCode,
  handleChange,
}) => {
  const [isLanguageVisible, setIsLanguageVisible] = useState();
  const getActiveCountry = useCallback(
    (countries, id) =>
      countries.find(({ id: countryId }) => countryId === id) ||
      countries[COUNTRY_CODES.US],
    [],
  );
  const country = useMemo(
    () => getActiveCountry(countries, activeCountryCode),
    [countries, activeCountryCode],
  );
  const language = useMemo(
    () =>
      country.languages?.find(
        ({ id: languageId }) => languageId === activeLanguageCode,
      ) ||
      country.languages?.[0] ||
      country.languages?.[LANGUAGES_CODES.EN],
    [country, activeLanguageCode],
  );
  const onCountryChange = useCallback(
    id => {
      if (id !== country.id) {
        const selectedCountry = getActiveCountry(countries, id);
        const shouldReselectLanguages = !selectedCountry.languages?.find(
          ({ id: languageId }) => languageId === language.id,
        );
        const data = { country: selectedCountry.id };
        if (shouldReselectLanguages) {
          data.language =
            selectedCountry.languages?.[0]?.id || LANGUAGES_CODES.EN;
          setIsLanguageVisible(true);
        }
        handleChange(data);
      }
    },
    [countries, country, language, getActiveCountry, handleChange],
  );
  const languagesList = useMemo(() => {
    const serializedLanguages = allowedLanguages.map(l => ({
      ...l,
      disabled: !country?.languages?.find(({ id }) => id === l?.id),
      tags: { 'data-mms--app-language-option': l.id },
    }));

    return serializedLanguages;
  }, [country, language]);
  const countiesList = useMemo(
    () =>
      countries.map(c => ({
        ...c,
        tags: { 'data-mms--app-country-option': c.id },
      })),
    [countries],
  );
  const onLanguageChange = useCallback(
    id => {
      setIsLanguageVisible(false);
      handleChange({ language: id });
    },
    [country, setIsLanguageVisible, handleChange],
  );
  useEffect(() => {
    const data = {};
    if (!activeCountryCode && country?.id) {
      data.country = country.id;
    }
    if (!activeLanguageCode && language?.id) {
      data.language = language.id;
    }
    if (Object.keys(data).length) {
      handleChange(data);
    }
  }, [country, language, activeCountryCode, activeLanguageCode, handleChange]);

  return (
    <Box boxVariant="flex" flexDirection="column" width="100%">
      <FilterDropdown
        values={languagesList}
        tags={{ 'data-mms--app-language-trigger': 'Language selector trigger' }}
        label="Data language"
        selected={language?.id}
        onChange={onLanguageChange}
        visible={isLanguageVisible}
        mb="16px"
      />
      <FilterDataList
        values={countiesList}
        tags={{ 'data-mms--app-country-trigger': 'Country selector trigger' }}
        label="Search results origin"
        selected={country?.id}
        onChange={onCountryChange}
        noResultsText="No countries found..."
      />
    </Box>
  );
};

CountryLanguage.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activeCountryCode: PropTypes.string,
  activeLanguageCode: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
};

export default CountryLanguage;
