import get from 'lodash/get';
import { validatePermission } from '@marketmuse/utilities';

import { store } from '../config/instances';

export default permission => {
  const state = store.getState();
  const permissions = get(state, 'permissions');

  return validatePermission({
    permissions,
    permission,
  });
};
