import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ColumnFiltersState, SortingState } from '@tanstack/table-core';

import {
  DocumentsTableQualifier,
  DocumentsTableKey,
  DocumentsTableUI,
  SliceTypeDocumentsTables,
} from '../types';
import * as ACTION_TYPES from '../actionTypes';

const table: DocumentsTableUI = {
  filters: [],
  limit: 20,
  sort: [{ id: 'createdAt', desc: true }],
  page: 1,
  qualifier: 'AND',
  query: '',
};

export const initialState: SliceTypeDocumentsTables = {
  organization: { ...table },
  site: { ...table },
};

export const documentsTablesSlice = createSlice({
  name: 'documentsTables',
  initialState,
  reducers: {
    setFilterData: (
      state,
      action: PayloadAction<{
        table: DocumentsTableKey;
        data: {
          filters: ColumnFiltersState;
          qualifier: DocumentsTableQualifier;
        };
      }>,
    ) => {
      state[action.payload.table].filters = action.payload.data.filters;
      state[action.payload.table].qualifier = action.payload.data.qualifier;
      state[action.payload.table].page = 1;
    },
    clearFilterData: (
      state,
      action: PayloadAction<{
        table: DocumentsTableKey;
      }>,
    ) => {
      const table = action.payload.table;
      state[table].filters = initialState[table].filters;
      state[table].qualifier = initialState[table].qualifier;
      state[table].page = 1;
    },
    setLimit: (
      state,
      action: PayloadAction<{ table: DocumentsTableKey; data: number }>,
    ) => {
      state[action.payload.table].limit = action.payload.data;
      state[action.payload.table].page = 1;
    },
    setPage: (
      state,
      action: PayloadAction<{
        table: DocumentsTableKey;
        data: number;
      }>,
    ) => {
      state[action.payload.table].page = action.payload.data;
    },
    setSort: (
      state,
      action: PayloadAction<{
        table: DocumentsTableKey;
        data: SortingState;
      }>,
    ) => {
      state[action.payload.table].sort = action.payload.data;
      state[action.payload.table].page = 1;
    },
    setQuery: (
      state,
      action: PayloadAction<{ table: DocumentsTableKey; data: string }>,
    ) => {
      state[action.payload.table].query = action.payload.data;
      state[action.payload.table].page = 1;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      action => action.type === ACTION_TYPES.SIGN_OUT,
      () => {
        return initialState;
      },
    );
  },
});

export const documentsTablesActions = documentsTablesSlice.actions;
export const documentsTablesReducer = documentsTablesSlice.reducer;
