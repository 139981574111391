import styled from 'styled-components';

const DataListTrigger = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 34px;
  min-width: ${p => (p.minWidth ? p.minWidth : '120px')};
  padding: 0 ${p => p.theme.spacing.small};
  color: ${p => p.theme.colors.grey85};
  background-color: ${p => p.theme.colors.grey10};
  font-size: 12px;
  cursor: pointer;
  ${p => p.theme.mixins.applyPadding}
  ${p => p.theme.mixins.applyMargin}

  ${p =>
    p.disabled &&
    `
    cursor: initial;
    padding: 0 ${p.theme.spacing.xxSmall};
    background-color: ${p.theme.colors.white};
  `}
  border: 2px solid transparent;
  ${p =>
    p.error &&
    `
    border-color: ${p.theme.colors.red};
  `}
`;

export default DataListTrigger;
