import { AppQueryStatus } from '@marketmuse/config/types/papi';
import { AppUiStatus } from '@marketmuse/config/types/applications';
import { ReduxListener } from '../../../types';

import { researchTaskStatusCheck } from '../../actions';
import { appQueryUpdate } from '../../thunks';
import { uiSlice } from '../../slices/ui.slice';

import checkIsUiComplete from '../utils/checkIsUiComplete';

const setup = (startListening: ReduxListener) => {
  const uiActions = uiSlice.actions;

  startListening({
    actionCreator: researchTaskStatusCheck,
    effect: async (action, api) => {
      const state = api.getState();
      const appResearch = state.appResearch;
      const permissions = state.permissions;
      const isUiComplete = checkIsUiComplete({
        network: appResearch.network,
        permissions,
      });

      if (
        appResearch.ui?.appQuery?.status !== AppQueryStatus.COMPLETED &&
        isUiComplete
      ) {
        api.dispatch(
          appQueryUpdate({
            appQueryId: appResearch.ui.appQuery?.id,
            status: AppQueryStatus.COMPLETED,
          }),
        );
      }

      if (isUiComplete) {
        api.dispatch(uiActions.statusUpdate(AppUiStatus.complete));
      }
    },
  });
};

export default setup;
