import { Fragment, useCallback, useState } from 'react';
import { Modal, Button, Icon, SUPPORTED_ICON } from '@marketmuse/components';
import { Settings } from '@marketmuse/research-connect';
import { useResearchActions } from '@marketmuse/research-data';
import { useStoreSelector } from '@marketmuse/data-state/hooks';

const ConnectSettingsLoader = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const { updateConnectDomains } = useResearchActions();
  const { competitorDomains, networkDomains } = useStoreSelector(
    state => state?.appResearch?.ui?.params,
  );

  const handleUpdateDomains = useCallback(
    ({
      competitors,
      network,
    }: {
      competitors: string[];
      network: string[];
    }) => {
      updateConnectDomains({
        competitorDomains: competitors,
        networkDomains: network,
      });
    },
    [updateConnectDomains],
  );

  return (
    <Fragment>
      <Button
        variant="none"
        onClick={() => setModalVisible(true)}
        data-mms--research-settings="connect"
        className="px-2.5"
      >
        <Icon name={SUPPORTED_ICON.settings} color="text-gray-500" />
      </Button>

      <Modal
        isVisible={isModalVisible}
        onClose={() => setModalVisible(false)}
        panelClassName={'min-h-[500px]'}
      >
        {isModalVisible && (
          <Settings
            competitors={competitorDomains}
            network={networkDomains}
            onSubmit={args => {
              setModalVisible(false);
              handleUpdateDomains(args);
            }}
            onCancel={() => setModalVisible(false)}
          />
        )}
      </Modal>
    </Fragment>
  );
};

export default ConnectSettingsLoader;
