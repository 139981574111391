import { isEmpty } from 'lodash';
/**
 * Take a source pathname, look for an slug + site id in the pathname,
 * and return a relative path affixed after the slug/site id.
 *
 * @param {Object} parameters
 * @param {string} parameters.pathname - current path in browser
 * @param {string} parameters.route - target suite location
 * @param {string} parameters.suffix - additional route segments
 *
 * @return {string} Full suite path
 */
export const makeSuitePath = ({
  pathname,
  route,
  suffix,
}: {
  pathname: string;
  route: string;
  suffix?: string;
}) => {
  const pathSegments = pathname.split('/');
  const isGuestPath = pathSegments.indexOf('guest') === 1;
  const isAuthenticatedPath = pathSegments.indexOf('a') === 1;
  const isPrefixedPath = isGuestPath || isAuthenticatedPath;

  // if path is unprefixed, expect slug + site id to follow domain
  let slugIndexInPathname = 1;
  let siteIdIndexInPathname = 2;

  // if path is prefixed, expect slug + site id to follow prefix
  if (isPrefixedPath) {
    slugIndexInPathname = 2;
    siteIdIndexInPathname = 3;
  }

  const slug = pathSegments[slugIndexInPathname];
  let siteId = pathSegments[siteIdIndexInPathname];
  if (siteId === route) {
    siteId = '';
  }

  const segments = [
    isPrefixedPath ? pathSegments[1] : false,
    slug,
    siteId,
    route,
    suffix,
  ]
    .filter(segment => !isEmpty(segment))
    .filter(Boolean)
    .join('/');

  return `/${segments}/`;
};
