import { trim, uniq, isEqual, isEmpty } from 'lodash';
import {
  InputPillListActions,
  InputPillListState,
  InputPillListProps,
} from './types';

type AddNewTag = {
  type: InputPillListActions.ADD_NEW_ITEM;
  payload: {
    inputValue: string;
    validation?: InputPillListProps['validation'];
  };
};

type SetInputValue = {
  type: InputPillListActions.SET_INPUT_VALUE;
  payload: {
    inputValue: string;
    validation?: InputPillListProps['validation'];
  };
};

type SetTags = {
  type: InputPillListActions.SET_ITEMS;
  payload: {
    items: string[];
  };
};

type RemoveTag = {
  type: InputPillListActions.REMOVE_ITEMS;
  payload: {
    tagRemoveIndex: number;
  };
};

type OnBackspace = {
  type: InputPillListActions.ON_BACKSPACE;
};

type OnKeyPress = {
  type: InputPillListActions.ON_KEY_PRESS;
  payload: {
    isKeyReleased: boolean;
  };
};

const defaultValid = { valid: true, reason: '' };

export const reducer = (
  state: InputPillListState,
  action:
    | AddNewTag
    | SetInputValue
    | SetTags
    | RemoveTag
    | OnBackspace
    | OnKeyPress,
) => {
  switch (action.type) {
    case InputPillListActions.ADD_NEW_ITEM: {
      const { validation, inputValue } = action.payload;
      if (isEmpty(inputValue)) {
        return state;
      }

      const values = inputValue
        .split(',')
        .map(value => trim(value))
        .filter(value => !isEmpty(value));

      let inputStatus = defaultValid;
      // Check to see if every value is valid
      const isValid = values.every(value => {
        // If theres many items, and one was invalid, keep that state
        if (!inputStatus.valid) {
          return false;
        }
        // Assume valid if no validation function
        if (!validation) {
          return true;
        } else {
          // Validate substring
          inputStatus = validation(value);
          return inputStatus.valid;
        }
      });

      if (!isValid) {
        return {
          ...state,
          inputStatus,
        };
      }

      const items = uniq([...state.items, ...values]);

      return {
        ...state,
        inputValue: '',
        items,
      };
    }

    case InputPillListActions.SET_INPUT_VALUE: {
      const { validation, inputValue } = action.payload;
      const validByFunction = validation
        ? validation(inputValue)
        : defaultValid;
      const inputStatus = inputValue === '' ? defaultValid : validByFunction;

      return {
        ...state,
        inputValue: inputValue,
        inputStatus: !state.inputStatus.valid ? inputStatus : defaultValid,
      };
    }

    case InputPillListActions.ON_BACKSPACE:
      if (
        !state.inputValue.length &&
        state.items.length &&
        state.isKeyReleased
      ) {
        const itemsCopy = [...state.items];
        const poppedTag = itemsCopy.pop();
        return { ...state, inputValue: poppedTag, items: itemsCopy };
      }
      return state;

    case InputPillListActions.ON_KEY_PRESS:
      return { ...state, isKeyReleased: action.payload.isKeyReleased };

    case InputPillListActions.REMOVE_ITEMS: {
      const prevTags = [...state.items];
      prevTags.splice(action.payload.tagRemoveIndex, 1);
      return { ...state, items: prevTags };
    }
    case InputPillListActions.SET_ITEMS: {
      if (!isEqual(state.items, action.payload.items))
        return { ...state, items: action.payload.items };
      return state;
    }
    default:
      throw new Error();
  }
};
