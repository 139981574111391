import React from 'react';
import styled from 'styled-components';
import textPropTypes from '../../config/propTypes/text';
import Box from '../Box';

const TextElement = styled(Box)`
  ${p => p.theme.mixins.applyTextVariant};
  ${p => p.theme.mixins.applyTextStyles};
  ${p => p.theme.mixins.applyColors};
`;

const Text = ({ textVariant, as = 'span', boxVariant = 'block', ...props }) => (
  <TextElement
    forwardedAs={as}
    textVariant={textVariant}
    boxVariant={boxVariant}
    {...props}
  />
);

Text.propTypes = textPropTypes;

export default Text;
