export const stripWWW = (url: string): string => {
  return (url || '').replace(/www\./gi, '');
};

export const stripHttp = (url: string): string => {
  return (url || '').replace(/(^\w+:|^)\/\//, '');
};

export const stripSlash = (url: string): string => {
  let nextUrl = url;
  if (!url) {
    return url;
  }

  if (nextUrl.substr(-1) === '/') {
    nextUrl = nextUrl.substr(0, nextUrl.length - 1);
  }

  if (nextUrl.indexOf('/') === 0) {
    nextUrl = nextUrl.substr(1, nextUrl.length);
  }

  return nextUrl;
};

export const stripUrl = (url: string): string => {
  return stripSlash(stripWWW(stripHttp(url)));
};
