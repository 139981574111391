import { omit } from 'lodash';
import { FC, useMemo } from 'react';
import { ButtonBarItemProps } from '../types';
import RenderButton from './RenderButton';

const ButtonBarItem: FC<ButtonBarItemProps> = ({
  item,
  activeId,
  dataId,
  defaultProps,
}) => {
  const Wrapper = item.Wrapper;

  const isActive = useMemo(() => item.id === activeId, [activeId, item.id]);

  const buttonVariant = useMemo(() => {
    if (isActive) {
      return 'blue500OnBlue100';
    } else if (item?.disabled) {
      return 'gray500';
    } else {
      return defaultProps?.variant || 'gray600OnTransparent';
    }
  }, [defaultProps?.variant, isActive, item?.disabled]);

  const buttonSize = useMemo(
    () => defaultProps?.size || 'xs',
    [defaultProps?.size],
  );

  const props = useMemo(() => {
    return {
      dataId,
      item: omit(item, ['disabled', 'onClick', 'Wrapper']),
      onClick: item?.onClick,
      defaultProps: {
        variant: buttonVariant,
        size: buttonSize,
      },
    };
  }, [buttonSize, buttonVariant, dataId, item]);

  if (Wrapper) {
    return (
      <Wrapper>
        <RenderButton {...props} />
      </Wrapper>
    );
  }

  return <RenderButton {...props} />;
};

export default ButtonBarItem;
