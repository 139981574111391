import { isEmpty, isEqual } from 'lodash';

import { PermissionToCheck } from '@marketmuse/config/types/permissions';

import { PermissionStatus, Permission } from '@marketmuse/config/types/papi';

const validatePermission = ({
  permissions,
  permission,
}: {
  permissions: Permission[];
  permission: PermissionToCheck;
}) => {
  // If the permissions array is empty, we don't have access
  if (isEmpty(permissions)) {
    return false;
  }

  // If the permission action/resource is empty, we don't have access
  if (!permission.action || !permission.resource) {
    return false;
  }

  // Find the permission in the permissions array
  const foundPermission = permissions.find(({ action, resource, status }) => {
    return isEqual(
      {
        action,
        resource,
        status,
      },
      {
        ...permission,
        status: permission.status || PermissionStatus.ALLOW, // Default to ALLOW
      },
    );
  });

  // If we don't have it, it's denied
  if (!foundPermission) {
    return false;
  }

  // All good, we have access
  return true;
};

export default validatePermission;
