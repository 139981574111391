import { AdWordsItem, KnowledgeGraphItem } from '@marketmuse/config/types/papi';
import {
  ResearchDataWithInventoryItems,
  ReduxListener,
  ResearchNetworkData,
} from '../../../types';
import { researchTaskInventoryItemAdded } from '../../actions';
import {
  getAdwordsKeywords,
  getAdwordsQuestions,
  getSerpQuestions,
  getKnowledgeGraph,
  getInvItemsAdwordsKeywords,
  getInvItemsAdwordsQuestions,
  getInvItemsKnowledgeGraph,
  getInvItemsBulkAdwordsKeywords,
  getInvItemsSerpQuestions,
  getBulkAdwordsKeywords,
} from '../../thunks';
import { dataSlice } from '../../slices';
import { createSeoFulfilledListener } from '../../utils/createSeoFulfilledListener';

const setup = (startListening: ReduxListener) => {
  const dataActions = dataSlice.actions;
  startListening({
    actionCreator: researchTaskInventoryItemAdded,
    effect: async ({ payload }, listenerApi) => {
      const topics = payload.map(({ term, volume }) => ({
        term,
        volume,
        existsInInventory: true,
        topRankingPage: null,
        topRankingPageTitle: null,
        maxRank: null,
        mmPersonalizedDifficulty: null,
        mmPersonalizedAdvantage: null,
      }));

      await listenerApi.dispatch(dataActions.inventoryTopicsUpdate(topics));

      [
        { key: 'adwordsKeywords' },
        { key: 'knowledgeGraph', path: 'knowledgeGraphItems' },
        { key: 'bulkAdwordsKeywords' },
        { key: 'adwordsQuestions' },
        { key: 'serpQuestions' },
      ].forEach(async ({ key, path }) => {
        await listenerApi.dispatch(
          dataActions.checkInvTopicInSeo({
            key: key as ResearchDataWithInventoryItems,
            path,
            terms: payload.map(({ term }) => term),
          }),
        );
      });
    },
  });

  createSeoFulfilledListener<AdWordsItem>({
    getInvItems: getInvItemsAdwordsKeywords,
    key: 'adwordsKeywords',
    startListening,
    thunk: getAdwordsKeywords,
  });

  createSeoFulfilledListener<AdWordsItem>({
    getInvItems: getInvItemsAdwordsQuestions,
    key: 'adwordsQuestions',
    startListening,
    thunk: getAdwordsQuestions,
  });

  createSeoFulfilledListener<KnowledgeGraphItem>({
    getInvItems: getInvItemsKnowledgeGraph,
    key: 'knowledgeGraph',
    path: 'knowledgeGraphItems',
    startListening,
    thunk: getKnowledgeGraph,
  });

  createSeoFulfilledListener<AdWordsItem>({
    getInvItems: getInvItemsBulkAdwordsKeywords,
    key: 'bulkAdwordsKeywords',
    entityVariants: true,
    startListening,
    thunk: getBulkAdwordsKeywords,
  });

  createSeoFulfilledListener<AdWordsItem>({
    getInvItems: getInvItemsSerpQuestions,
    key: 'serpQuestions',
    startListening,
    thunk: getSerpQuestions,
  });
};

export default setup;
