import React, { CSSProperties } from 'react';
import cx from 'classnames';
import { isNil } from 'lodash';

import Url from '../Url';

const Meta = (props: {
  style?: CSSProperties;
  title?: string;
  url?: string;
  desc?: string;
  className?: string;
}) => (
  <div
    style={{ width: '100%', ...(props.style ? props.style : {}) }}
    className={cx('mm-tw', props.className)}
  >
    {!isNil(props.title) && (
      <div
        className={cx(['truncate', 'text-gray-800', 'text-sm', 'text-left'])}
      >
        {props.title}
      </div>
    )}
    {!isNil(props.url) && (
      <Url
        href={props.url}
        className={cx([
          'line-clamp-2',
          'text-blue-400',
          'text-xs',
          'leading-tight',
          'block',
        ])}
      >
        {props.url}
      </Url>
    )}
    {!isNil(props.desc) && (
      <div className={cx(['text-xs', 'text-gray-600', 'leading-5'])}>
        {props.desc}
      </div>
    )}
  </div>
);

export default Meta;
