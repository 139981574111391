import { sanitizeHtml } from '@marketmuse/utilities';
import { Polymorphic } from '../Polymorphic';
import { highlight } from './utils';

export interface HighlightProps {
  as?: 'div' | 'span';
  highlightClassNames?: string | Array<string | string[]>;
  terms: string[];
  text?: string;
  url?: boolean;
}

type HighlightComponent = (props: HighlightProps) => React.ReactElement | null;

export const Highlight: HighlightComponent = ({
  as,
  highlightClassNames,
  terms,
  text,
  url,
}: HighlightProps) => {
  const element = as || 'span';
  return (
    <Polymorphic
      as={element}
      dangerouslySetInnerHTML={sanitizeHtml(
        highlight({
          options: { url },
          str: text || '',
          terms: terms,
          highlightClassNames,
        }),
      )}
    />
  );
};

export default Highlight;
