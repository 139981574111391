import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  border-radius: 4px;
  border: 1px solid ${p => p.theme.colors.grey15};
  background-color: white;
  min-height: 80px;
  padding: ${p => p.theme.spacing.regular};
`;

const CardHeaderWrapper = styled.div`
  ${p => p.theme.type.heading4};
  margin-top: 0;
  align-items: center;
  justify-content: flex-start;
`;

const CardBodyWrapper = styled.div`
  flex-grow: 1;
  padding: 12px 0;
  color: ${p => p.theme.colors.grey85};
`;

const CardFooterWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const ConnectorWrapper = styled.div`
  position: relative;
  height: ${p => p.height || 20}px;

  &:after {
    content: '';
    width: 1px;
    height: ${p => p.height || 20}px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${p => p.theme.mmxGreyE9};
  }
`;

export const CardConnector = ({ height }) => <ConnectorWrapper height={height} />;

// Header
export const CardHeader = props => (
  <CardHeaderWrapper className={props.className} style={props.style}>
    {props.children}
  </CardHeaderWrapper>
);

// Body
export const CardBody = props => (
  <CardBodyWrapper className={props.className} style={props.style}>
    {props.children}
  </CardBodyWrapper>
);

// Footer
export const CardFooter = props => (
  <CardFooterWrapper className={props.className} style={props.style}>
    {props.children}
  </CardFooterWrapper>
);

// Card
const Card = React.forwardRef((props, ref) => (
  <Wrapper {...props} ref={ref}>
    {props.children}
  </Wrapper>
));

Card.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.any,
};

CardHeader.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.any,
};

CardBody.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.any,
};

export default Card;
