import get from 'lodash/get';

import { GetOrgDocument } from '@marketmuse/data-papi';
import makeRequest from '../../utils/makeRequest';
import * as types from '../../config/types';

export default () => () => next => action => {
  if (action.type === types.ADMIN_GET_ORG) {
    makeRequest({
      type: types.ADMIN_GET_ORG,
      mutation: GetOrgDocument,
      variables: {
        id: action.id,
      },
      options: {
        isQuery: true,
      },
      apolloOptions: {
        fetchPolicy: 'no-cache',
      },
    }).then(res => {
      const org = get(res, 'organization') || {};
      if (action.callback) action.callback(org);
    });
  }

  return next(action);
};
