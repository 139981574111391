import styled from 'styled-components';
import PageBody from '../../../../components/Page/PageBody';

const PageBodyStyled = styled(PageBody)`
  padding-top: 0;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  background-color: ${p => p.theme.colors.grey05};
`;

export default PageBodyStyled;
