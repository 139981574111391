import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import Clickable from '../../../components/Clickable';
import IconSvg from '../../../components/IconSvg';
import SimpleMenuUserProfile from '../../../components/SimpleMenuInstances/SimpleMenuUserProfile/SimpleMenuUserProfile';

import * as miscActions from '../../../actions/miscActions';
import * as adminActions from '../../../actions/adminActions';
import * as authenticateActions from '../../../actions/authenticateActions';
import isDeveloperMode, {
  toggleDeveloperMode,
} from '../../../utils/isDeveloperMode';
import { stripUrl } from '../../../utils/misc';
import isAdminMode from '../../../utils/isAdminMode';
import { SidebarPaddingLR } from './shared';

const AvatarCollapseWrapper = styled(Clickable)`
  width: ${p => p.theme.sidebarWidthSmall}px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled(Clickable)`
  background-color: ${p => p.theme.colors.black};
  width: 100%;
  padding: ${p => p.theme.spacing.medium};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  height: 65px;
  color: ${p => p.theme.colors.grey15};

  &:hover {
    color: ${p => p.theme.colors.blueBase};
  }
`;

const AdminModeHeader = styled.div`
  background-color: ${p =>
    p.v2 ? p.theme.sidebarColorAdmin : p.theme.colors.blueLight2};
  height: 28px;
  border-radius: 3px;
  padding: ${p => `0 ${p.theme.sidebarPadding}px`};
  display: flex;
  align-items: center;
  margin-top: 22px;
  margin-bottom: ${p => p.theme.spacing.medium};
`;

const AdminModeHeaderTitle = styled.div`
  color: ${p => (p.v2 ? p.theme.colors.white : p.theme.sidebarColorAdmin)};
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 8pt;
`;
const AdminModeHeaderButton = styled(Clickable)`
  color: ${p => (p.v2 ? p.theme.colors.white : p.theme.sidebarColorAdmin)};
  font-size: 8pt;
`;

const ProfileWrapper = styled.div`
  display: flex;
  width: 80%;
  ${p => p.theme.type.heading5}
  color: inherit;

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-flex: 1;
    flex-grow: 1;
  }
`;

const UserProfile = props => {
  const [expanded, setExpanded] = useState(false);
  const avatar = <IconSvg name="avatar" size="16" box="13" color="white" />;

  if (props.collapsed) {
    return (
      <AvatarCollapseWrapper
        onClick={() => {
          setExpanded(!expanded);
          props.miscActions.sidebarToggle();
        }}
      >
        {avatar}
      </AvatarCollapseWrapper>
    );
  }

  const isAdmin = isAdminMode(props.admin, props.user);
  const isDeveloper = isDeveloperMode(props.admin, props.user, props.persist);
  const { domain, title } = props?.filter?.site || {};
  const inventoryTitle = title || domain;
  return (
    <>
      <SimpleMenuUserProfile
        showSidebarCollapse
        placement="right"
        offset={[40, 8]}
      >
        <Wrapper data-mms--menu="user-profile-menu">
          <ProfileWrapper>
            <div>{stripUrl(inventoryTitle)}</div>
          </ProfileWrapper>
          <IconSvg name="settings" size="16" />
        </Wrapper>
      </SimpleMenuUserProfile>

      {isAdmin && (
        <SidebarPaddingLR>
          <AdminModeHeader v2>
            <AdminModeHeaderTitle v2>
              {isDeveloper ? 'Developer' : 'Admin'} Mode
            </AdminModeHeaderTitle>
            <div style={{ flexGrow: 1 }} />
            <AdminModeHeaderButton
              v2
              onClick={() => {
                toggleDeveloperMode(false);
                props.adminActions.disableAdminMode();
              }}
            >
              Disable
            </AdminModeHeaderButton>
          </AdminModeHeader>
        </SidebarPaddingLR>
      )}
    </>
  );
};

UserProfile.propTypes = {
  collapsed: PropTypes.bool,
  user: PropTypes.object,
  filter: PropTypes.object,
  miscActions: PropTypes.object,
  adminActions: PropTypes.object,
  authenticateActions: PropTypes.object,
  v2: PropTypes.bool,
};

const mapStateToProps = state => ({
  persist: state.persist,
  filter: state.filter,
  user: state.user,
  admin: state.admin,
});

const mapDispatchToProps = dispatch => ({
  miscActions: bindActionCreators(miscActions, dispatch),
  adminActions: bindActionCreators(adminActions, dispatch),
  authenticateActions: bindActionCreators(authenticateActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
