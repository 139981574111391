import * as Sentry from '@sentry/browser';
import { isEmpty } from 'lodash';
import { App } from '@marketmuse/config/types/papi';
import { AppUiStatus } from '@marketmuse/config/types/applications';
import type { AppQuery } from '@marketmuse/config/types/papi';
import * as ACTION_TYPES from '../../../actionTypes';
import {
  optimizeTaskQueryUpsert,
  optimizeTaskFetchPage,
  optimizeTaskQueryLoad,
  optimizeTaskInitialize,
} from '../../actions';
import {
  ReduxListener,
  AppQueryParams,
  OptimizeSnapshotInput,
} from '../../../types';
import sanitizeAppParams from '../../../utils/sanitizeAppParams';
import validateAppParams from '../../../utils/validateAppParams';
import { uiSlice } from '../../slices';
import { appQueryUpsert, appQueryUpdate } from '../../thunks';

const listen = (startListening: ReduxListener) => {
  const uiActions = uiSlice.actions;
  startListening({
    actionCreator: optimizeTaskQueryLoad,
    effect: async (
      { payload }: { payload: OptimizeSnapshotInput },
      { dispatch, cancelActiveListeners },
    ) => {
      const params = sanitizeAppParams<AppQueryParams>(payload.params);
      const isParamsValid = validateAppParams(
        params,
      ) as unknown as AppQueryParams;
      if (isParamsValid) {
        cancelActiveListeners();
        dispatch(uiActions.paramsSet(payload.params));
        dispatch(
          uiActions.appQuerySet({
            ...payload.appQuery,
            term: params.term,
            url: params.url,
          }),
        );

        if (payload.params.url) {
          const url = payload.params.url as string;
          dispatch(optimizeTaskFetchPage({ url }));
        }
        await dispatch(optimizeTaskInitialize(params));
      }
    },
  });

  startListening({
    actionCreator: optimizeTaskQueryUpsert,
    effect: ({ payload }, { dispatch, cancelActiveListeners }) => {
      const isParamsValid = validateAppParams(payload.params);
      if (isParamsValid) {
        dispatch(
          uiActions.paramsAndStatusSet({
            params: payload.params,
            status: AppUiStatus.creating,
          }),
        );

        cancelActiveListeners();

        if (payload.fetch && payload.params.url) {
          const url = payload.params.url as string;
          dispatch(optimizeTaskFetchPage({ url }));
        }

        dispatch(
          appQueryUpsert({
            ...payload.params,
            app: App.OPTIMIZE,
          }),
        );
      } else {
        dispatch(uiActions.paramsSet(payload.params));
        console.log('Params invalid', payload);
      }
    },
  });

  startListening({
    actionCreator: appQueryUpdate.fulfilled,
    effect: async ({ payload }, { dispatch, getState }) => {
      const siteId = getState().filter?.site?.id;
      await dispatch(
        uiActions.appQuerySet({
          id: payload.id,
          status: payload.status,
          term: payload.topic,
          url: payload.url,
          country: payload.country,
          language: payload.language,
        }),
      );
      await dispatch({
        type: ACTION_TYPES.AGGREGATE_STATS_GET,
        payload: siteId,
      });
    },
  });

  startListening({
    actionCreator: appQueryUpsert.rejected,
    effect: (action, { dispatch }) => {
      const { aborted, arg } = action.meta;

      if (!aborted) {
        dispatch(uiActions.statusSet(AppUiStatus.zero));

        if (!isEmpty(action.error)) {
          if (action.error.message !== 'Network request failed') {
            Sentry.captureMessage(action.error.message, {
              extra: arg,
            });
          }
        }
      }
    },
  });

  startListening({
    actionCreator: appQueryUpsert.fulfilled,
    effect: async (
      {
        payload,
      }: {
        payload: {
          data: AppQuery;
          params: AppQueryParams;
        };
      },
      { dispatch },
    ) => {
      dispatch(
        uiActions.appQuerySet({
          id: payload.data.id,
          status: payload.data.status,
          term: payload.data.topic,
          url: payload.data.url,
          country: payload.data.country,
          language: payload.data.language,
        }),
      );

      await dispatch(optimizeTaskInitialize(payload.params));
    },
  });
};

export default listen;
