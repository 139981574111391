import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Clickable from '../Clickable';

export const Tabs = styled.div`
  height: ${p => p.isPageHeaderTab ? p.theme.pageHeaderHeight + 1 : 42}px;
  display: flex;
  justify-content: flex-start;
`;

const Tab = styled(Clickable)`
  flex-shrink: 0;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 11px;
  margin-right: 20px;
  color: ${p => p.theme[p.active ? 'mmxBlue' : 'mmxDarkGrey']};
  border-bottom: 2px solid ${p => (p.active ? p.theme.mmxBlue : 'transparent')};

  ${p => p.active && `
    font-weight: bold;
  `}
  &:hover { cursor: pointer; }
`;

const TabComponent = ({ active, className, style, onClick, children, ...rest }) => (
  <Tab
    style={style}
    className={className}
    onClick={onClick}
    active={active}
    {...rest}
  >
    {children}
  </Tab>
);

TabComponent.propTypes = {
  style: PropTypes.object,
  active: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any,
};

export default TabComponent;
