import { FC } from 'react';
import { Box, Button, Text, WistiaVideo } from '@marketmuse/components';

interface CollateralConnectProps {
  onUpgrade?: (event: MouseEvent) => void;
}

const CollateralConnect: FC<CollateralConnectProps> = ({ onUpgrade }) => {
  return (
    <Box
      className={[
        'flex',
        'flex-row',
        'w-full',
        'px-12',
        'gap-14',
        'overflow-y-auto',
      ]}
    >
      <Box
        className={[
          'w-1/2',
          'flex',
          'flex-row',
          'items-center',
          'justify-center',
          'py-6',
        ]}
      >
        <Box className={['w-full']}>
          <WistiaVideo videoId={'1s2hp6y6tg'} title={'Connect'} />
        </Box>
      </Box>
      <Box
        className={[
          'flex',
          'flex-col',
          'items-center',
          'justify-center',
          'w-1/2',
        ]}
      >
        <Box className={['flex', 'flex-col', 'items-start']}>
          <Text className={['prose', 'text-xl', 'font-bold', 'mb-4']} as="h2">
            The right links give your content clusters power.
          </Text>
          <Text className={['prose', 'text-base', 'mb-4']} as="p">
            Stop wondering what strategic internal and non-competitive external
            links to use.
          </Text>
          <Text className={['prose', 'text-base', 'mb-4']} as="p">
            Get link recommendations and anchor text for any topic.{' '}
            <a
              href="https://marketmuse-1.wistia.com/medias/1s2hp6y6tg"
              target="_blank"
              rel="noreferrer"
              className="no-underline"
            >
              Learn more
            </a>
          </Text>
          <Button variant="blue400" onClick={onUpgrade}>
            Upgrade Now
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CollateralConnect;
