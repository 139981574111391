import { pick, isEmpty } from 'lodash';
import * as Sentry from '@sentry/browser';
import {
  AppQueryNetworkStatus,
  ReduxListener,
  RootState,
} from '../../../types';

import { optimizeTaskStatusCheck } from '../../actions';
import { dataSlice, networkSlice } from '../../slices';
import { getKnowledgeGraph, getBulkAdwordsKeywords } from '../../thunks';

const listeners = (startListening: ReduxListener) => {
  const dataActions = dataSlice.actions;
  const networkActions = networkSlice.actions;

  startListening({
    actionCreator: getKnowledgeGraph.pending,
    effect: async (action, { dispatch }) => {
      await dispatch(
        networkActions.knowledgeGraphSet(AppQueryNetworkStatus.loading),
      );
    },
  });

  startListening({
    actionCreator: getKnowledgeGraph.rejected,
    effect: async (action, { dispatch }) => {
      const { aborted, arg } = action.meta;
      if (!aborted) {
        await Promise.all([
          dispatch(
            networkActions.knowledgeGraphSet(AppQueryNetworkStatus.error),
          ),
          dispatch(
            networkActions.bulkAdwordsKeywordsSet(
              AppQueryNetworkStatus.inactive,
            ),
          ),
        ]);

        if (!isEmpty(action.error)) {
          if (action.error.message !== 'Network request failed') {
            Sentry.captureMessage(action.error.message, {
              extra: arg as unknown as Record<string, unknown>,
            });
          }
        }

        // check status after other steps
        await dispatch(optimizeTaskStatusCheck());
      }
    },
  });

  startListening({
    actionCreator: getKnowledgeGraph.fulfilled,
    effect: async ({ payload }, { dispatch }) => {
      await Promise.all([
        dispatch(dataActions.knowledgeGraphSet(payload.data)),
        dispatch(
          networkActions.knowledgeGraphSet(AppQueryNetworkStatus.complete),
        ),
      ]);

      // check status after other steps
      await dispatch(optimizeTaskStatusCheck());
    },
  });

  startListening({
    actionCreator: getKnowledgeGraph.fulfilled,
    effect: async (action, { getState, dispatch }) => {
      const state: RootState = getState();
      const items = action.payload.data.knowledgeGraphItems || [];

      if (Array.isArray(items) && items.length > 0) {
        await dispatch(
          getBulkAdwordsKeywords({
            ...pick(state.appOptimize.ui.appQuery, ['country', 'language']),
            terms: items.map(({ term }) => term),
          }),
        );
      } else {
        await dispatch(
          networkActions.bulkAdwordsKeywordsSet(AppQueryNetworkStatus.inactive),
        );
      }
    },
  });
};

export default listeners;
