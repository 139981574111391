import React from 'react';
import PageDark from '../Page/PageDark';
import { LoaderSparkles } from '../Loader';
import Box from '../Box';
import Text from '../Text';

const ScreenLoader = ({ children = null, style = null }) => {
  return (
    <PageDark>
      <Box
        style={
          style
            ? style
            : {
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }
        }
      >
        {children
          ? children
          : [
              <LoaderSparkles color="white" key="sparkles" />,
              <Text
                key="loader-text"
                as={'p'}
                color="white"
                fontWeight="bold"
                mb={0}
                style={{
                  position: 'absolute',
                  top: 80,
                  left: '50%',
                  transform: 'translateX(-35px)',
                }}
              >
                <Text>Loading...</Text>
              </Text>,
            ]}
      </Box>
    </PageDark>
  );
};

export default ScreenLoader;
