import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  color: ${p => p.theme.colors.redBase};
  margin-right: 6px;
`;

const RequiredStar = () => <Wrapper>*</Wrapper>;
export default RequiredStar;
