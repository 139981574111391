import { ApolloProvider as Provider } from '@apollo/client';
import { apolloClient } from '../config/apollo/clients';
import { REACT_APP } from '../config/env';

const ApolloProvider = ({ children }) => {
  const client = apolloClient();

  if (
    REACT_APP.GQL_LOG === 'true' &&
    REACT_APP.GQL_INTERCEPT === 'true' &&
    window?.laika
  ) {
    require('../config/apollo/intercept');
  }

  return <Provider client={client}>{children}</Provider>;
};

export default ApolloProvider;
