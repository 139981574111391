import { Country, Language } from '@marketmuse/config/types/papi';
import { AppUiStatus } from '@marketmuse/config/types/applications';
import { SliceOptimizeUi } from '../../../types/appOptimize';

export const initialState: SliceOptimizeUi = {
  appQuery: {},
  params: {
    term: '',
    url: '',
    country: Country.US,
    language: Language.EN,
    siteId: '',
  },
  contents: null,
  status: AppUiStatus.zero,
  validParams: false,
};
