import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Clickable from '../../../../components/Clickable';
import IconSvg from '../../../../components/IconSvg/IconSvg';
import Box from '../../../../components/Box';

const Title = styled.div`
  ${p => p.theme.type.heading4};
  color: ${p => p.theme.colors.black};

  align-items: start;
  display: flex;
`;
const MenuSeparator = styled.div.attrs(p => ({
  role: 'separator',
  id: void 0,
}))`
  height: 1px;
  width: 100%;
  margin: ${p => p.theme.spacing.regular} 0;
  background: var(--mms-simple-border, ${p => p.theme.generic.dark.border});
`;

const SectionComponent = styled.form`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 20px;
  margin-top: -10px;
  ${p => p.withlabel && `margin-top: 10px;`}
`;

const Wrapper = styled.div`
  position: relative;
  margin: 0px;
  &:not(:first-of-type) {
    margin-top: 32px;
  }
`;

const Item = styled(Clickable)`
  display: flex;
  align-items: stretch;
  margin-top: 10px;
  ${p => p.grow && `flex-grow: 1;`}
  ${p => p.noShrink && `flex-shrink: 0;`}
  ${p => p.last && `margin-right: 0;`}
  ${p => p.stretch && `width: 100%;`}
  &:last-of-type {
    margin-right: 0;
  }
  & > * {
    height: 100%;
  }
  outline: none;
  width: 100%;
`;

const Section = ({
  icon,
  label,
  items = [],
  collapse,
  style,
  innerStyle,
  onSubmit,
}) => {
  const [hidden, setHidden] = useState(false);
  const visibleItems = useMemo(
    () => items.filter(({ hide }) => !hide),
    [items],
  );
  const shouldRenderBody = (collapse && !hidden) || !collapse;
  const shouldRenderLabel = collapse || !!label;

  return (
    <Wrapper style={style || {}}>
      {shouldRenderLabel && (
        <Clickable
          disabled={!collapse}
          onClick={() => collapse && setHidden(!hidden)}
        >
          <Box
            boxVariant="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="baseline"
          >
            <Title>
              {icon && (
                <IconSvg
                  name={icon}
                  size={28}
                  box={20}
                  style={{ marginRight: '12px' }}
                />
              )}
              {label}
            </Title>
            {collapse && (
              <IconSvg
                name={`caret-${hidden ? 'up' : 'down'}`}
                size={16}
                box={16}
                style={{ marginRight: '12px' }}
              />
            )}
          </Box>
          <MenuSeparator />
        </Clickable>
      )}
      {shouldRenderBody && (
        <SectionComponent
          style={innerStyle || {}}
          onSubmit={onSubmit}
          withlabel={shouldRenderLabel}
        >
          {visibleItems.map(({ component, ...rest }, i) => (
            <Item key={`section-item-${i}`} {...rest}>
              {component}
            </Item>
          ))}
        </SectionComponent>
      )}
    </Wrapper>
  );
};

Section.propTypes = {
  label: PropTypes.string,
  labelOn: PropTypes.string,
  labelOff: PropTypes.string,
  collapse: PropTypes.bool,
  getCollapseFunction: PropTypes.func,
  hide: PropTypes.bool,
  style: PropTypes.object,
  innerStyle: PropTypes.object,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      hide: PropTypes.bool,
      component: PropTypes.any.isRequired,
      grow: PropTypes.bool,
      noShrink: PropTypes.bool,
    }),
  ),
  form: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default Section;
