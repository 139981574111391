import React from 'react';

import Page from '../Page/Page';
import PageBody from '../Page/PageBody';
import PageHeader from '../Page/PageHeader';

const ScreenLoaderPage = ({ title = '', icon = null }) => {
  return (
    <Page>
      <PageHeader title={title} icon={icon ? icon : <></>} />
      <PageBody hasPadding></PageBody>
    </Page>
  );
};
export default ScreenLoaderPage;
