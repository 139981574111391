import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import get from 'lodash/get';
import IconCheck from 'react-feather/dist/icons/check';
import { PAPI } from '@marketmuse/config/types';

import ArrowButton from '../../../../../components/ArrowButton';
import RunOnApp from '../../../../../components/RunOnApp/RunOnApp';
import Distribution from '../../../../../components/Distribution';
import * as SL from '../../../../../components/SimpleList/themes/app';
import Repeat from '../../../../../components/Repeat';

import {
  NewsroomOutputSection,
  NewsroomOutputSectionHeader,
  NewsroomOutputSectionContents,
  NewsroomOutputSectionHeaderTitle,
  NewsroomOutputSectionHeaderItem,
} from '../../sharedStyles';
import NewsTopicsVariants from './NewsTopicsVariants';

const DistributionStyled = styled(Distribution)`
  min-height: 40px;
`;

const SerpPresence = styled(IconCheck)`
  color: ${p => p.theme.mmxBlue};
  width: 14px;
`;

class NewsTopics extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      variantsOpen: false,
      variantSidebarData: [],
    };
  }

  render() {
    const appData = this.props.appData;
    const terms = get(appData, 'scoresNews.kg.data') || [];
    const allVariants = get(appData, 'scoresNews.all_variants') || {};
    const pages = get(appData, 'scoresNews.pages') || {};
    const you = Object.values(pages)[0] || null;
    const yourDistributions = get(you, 'mentions') || {};
    const overlappingTopics =
      get(appData, 'compareKgsNews.data.overlapping_topics') || [];
    const overlappingTopicsTerms = overlappingTopics.map(t => t.term);

    const queryString = this.props.query
      ? `"${this.props.query}"`
      : 'Same Topic';

    return (
      <NewsroomOutputSection>
        <NewsroomOutputSectionHeader>
          <NewsroomOutputSectionHeaderTitle>
            Related News Topics
          </NewsroomOutputSectionHeaderTitle>
          <NewsroomOutputSectionHeaderItem>
            {!this.props.snapshot && (
              <RunOnApp
                url={null}
                query={this.props.query}
                directToolId={PAPI.PermissionResource.RESEARCH_APPLICATION}
              >
                <ArrowButton
                  title={`Run ${queryString} on Research`}
                  icon={<span className="mm mm-research" />}
                  color="grey"
                />
              </RunOnApp>
            )}
          </NewsroomOutputSectionHeaderItem>
        </NewsroomOutputSectionHeader>
        <NewsroomOutputSectionContents>
          <>
            {/*// TODO: MMS-290*/}
            <SL.Row header sticky style={{ top: 0 }}>
              <SL.Item header grow pl={22}>
                Topic
              </SL.Item>
              <SL.Item header width={110} alignCenter>
                SERP
              </SL.Item>
              <SL.Item header width={110} alignCenter>
                Variants
              </SL.Item>
              {you && (
                <SL.Item header width={110} alignCenter>
                  Your Dist.
                </SL.Item>
              )}
              <SL.Item header width={110} alignCenter>
                Suggested
              </SL.Item>
            </SL.Row>
            {this.props.loading && (
              <Repeat
                id="newsroom-topics"
                component={SL.Row}
                props={{ loading: true }}
              />
            )}
            {!this.props.loading && terms.length === 0 && (
              <SL.Row>
                <SL.Item grow truncate>
                  No data
                </SL.Item>
              </SL.Row>
            )}
            {!this.props.loading &&
              terms.map((t, i) => (
                <SL.Row
                  key={`newsroom-${t.term}-${i}`}
                  onClick={() => this.setState({ selectedTopic: t })}
                >
                  <SL.Item grow pl={22}>
                    {t.term}
                  </SL.Item>
                  <SL.Item width={110} alignCenter>
                    {overlappingTopicsTerms.indexOf(t.term) !== -1 && (
                      <SerpPresence />
                    )}
                  </SL.Item>
                  <SL.Item width={110} alignCenter>
                    {(allVariants[t.term] || []).length}
                  </SL.Item>
                  {you && (
                    <SL.Item width={110} p={0} alignCenter>
                      <DistributionStyled value={yourDistributions[t.term]} />
                    </SL.Item>
                  )}
                  <SL.Item width={110} p={0} alignCenter>
                    <DistributionStyled value={t.recommended_mentions} />
                  </SL.Item>
                </SL.Row>
              ))}
          </>
        </NewsroomOutputSectionContents>
        {this.state.selectedTopic && (
          <NewsTopicsVariants
            appData={appData}
            selectedTopic={this.state.selectedTopic}
            close={() => this.setState({ selectedTopic: null })}
          />
        )}
      </NewsroomOutputSection>
    );
  }
}

NewsTopics.propTypes = {
  snapshot: PropTypes.bool,
  url: PropTypes.string,
  query: PropTypes.string,
  newsroomData: PropTypes.object,
  loading: PropTypes.bool,
};

export default NewsTopics;
