import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTheme } from 'styled-components';
import { get, isNil, isEmpty, lowerCase, camelCase, mapKeys } from 'lodash';
import { PAPI } from '@marketmuse/config/types';

import Page from '../../../components/Page/Page';
import PageHeader from '../../../components/Page/PageHeader';
import PageBody from '../../../components/Page/PageBody';
import { AppAutocomplete } from '../../../components/AppAutocomplete';
import SimpleTextInput from '../../../components/SimpleTextInput';
import IconSvg from '../../../components/IconSvg';
import Button from '../../../components/Button';
import Clickable from '../../../components/Clickable';
import Tooltip from '../../../components/Tooltip/Tooltip';
import SimpleSiteInput from '../../../components/SimpleSiteInput';
import Banner from '../../../components/Banner';
import SnapshotsModal from '../components/Snapshots/SnapshotsModal';
import NewsroomZeroState from '../../EmptyStates/ZeroStates/NewsroomZeroState';
import Export from '../components/Export';
import AdvancedSettings from '../components/AdvancedSettings';

import isUrl from '../../../utils/isUrl';
import runApp from '../../../utils/applications/runApp';
import hydrateApp from '../../../utils/applications/hydrateApp';
import { APPLICATIONS_CONFIG_FLAT } from '../../../config/getApplicationsConfig';
import appExported from '../../../models/tracker/events/apps/AppExported';
import getSnapshotKey from '../utils/getSnapshotKey';
import isStandalone from '../utils/isStandalone';
import {
  extractToolsParams,
  setToolsParams,
  toolsDefaultParams,
} from '../utils/shapeToolsParams';

import * as types from '../../../config/types';
import * as toolsActions from '../../../actions/toolsActions';
import * as requestActions from '../../../actions/requestActions';
import * as miscActions from '../../../actions/miscActions';
import Top20 from './Sections/Top20/Top20';
import NewsTopics from './Sections/NewsTopics/NewsTopics';

class Newsroom extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      ...toolsDefaultParams({
        activeInventory: get(props, 'filter.site'),
      }),
      brand: true,
      people: true,
      hideTopicBanner: false,
      hideNewsBanner: false,
      showResults: !!props.trial,
    };

    this.hydrate = this.hydrate.bind(this);
    this.getParams = this.getParams.bind(this);
    this.setParams = this.setParams.bind(this);
    this.isLoading = this.isLoading.bind(this);
    this.runNewsroomApp = this.runNewsroomApp.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.export = this.export.bind(this);
    this.loadSnapshotData = this.loadSnapshotData.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.isLoading(prevProps) && !this.isLoading()) {
      this.hydrate();
    }
    if (this.props.apps !== prevProps.apps) {
      this.hydrate();
    }
  }

  componentDidMount() {
    if (isStandalone(this.props)) {
      if (
        !isNil(this.props.snapshotData) &&
        !isEmpty(this.props.snapshotData)
      ) {
        this.setState({
          appData: this.props.snapshotData,
          showResults: true,
        });
      } else {
        this.loadSnapshotData();
      }

      return;
    }

    this.hydrate();
  }

  loadSnapshotData(snapshotKey) {
    const useSnapshotKey = snapshotKey || getSnapshotKey(this.props);
    if (!useSnapshotKey) return;
    this.setState({ showResults: true, snapshotDataLoading: true });
    this.props.miscActions.getSnapshotData({
      key: useSnapshotKey,
      callback: appData => {
        const { app_runs: appRuns, ...rest } = appData;
        this.setState({
          ...(get(appData, 'params') || {}),
          query: get(appData, 'params.topic'),
          url: get(appData, 'params.url'),
          appData: {
            appRuns: mapKeys(appRuns, (v, k) => camelCase(k)),
            ...mapKeys(rest, (v, k) => camelCase(k)),
          },
          showResults: !!appData,
          snapshotDataLoading: false,
        });
      },
    });
  }

  hydrate() {
    if (isStandalone(this.props)) return;

    hydrateApp({
      appId: 'newsroom',
      apps: this.props.apps,
      loading: this.isLoading(),
      setAppData: (appData = {}) => {
        if (get(appData, 'params.country')) {
          this.setState({
            country: get(appData, 'params.country'),
          });
        }
        this.setState({ appData });
      },
      setParams: this.setParams,
      setShowResults: status => this.setState({ showResults: status }),
      dataRequirements:
        APPLICATIONS_CONFIG_FLAT[PAPI.PermissionResource.NEWSROOM]
          .dataRequirements,
    });
  }

  isLoading(p) {
    const props = p || this.props;
    return (
      this.state.snapshotDataLoading ||
      props.loadings[`${types.GET_APP_DATA}-newsroom`] ||
      props.loadings[`${types.GET_APP_DATA}-all`]
    );
  }

  getParams() {
    return extractToolsParams(this.state, ['language']);
  }

  setParams(params = {}) {
    this.setState(setToolsParams(params));
  }

  runNewsroomApp(e, args = {}) {
    if (isStandalone(this.props)) return;
    if (e) e.preventDefault();

    this.setState({
      hideNewsBanner: false,
      hideTopicBanner: false,
    });

    runApp(args, {
      appId: 'newsroom',
      dataRequirements:
        APPLICATIONS_CONFIG_FLAT[PAPI.PermissionResource.NEWSROOM]
          .dataRequirements,
      setShowResults: status => this.setState({ showResults: status }),
      saveAppData: this.props.toolsActions.saveAppData,
      getAppData: this.props.toolsActions.getAppData,
      setAppData: appData => this.setState({ appData }),
      getParams: this.getParams,
      setParams: this.setParams,
      apps: this.props.apps,
      checkAppRuns: false,
    });
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  export() {
    this.props.toolsActions.getExcel({
      filename: `${new Date().toLocaleDateString(
        'en-US',
      )}-marketmuse-newsroom-${this.state.query}-export`,
      data: this.state.appData,
      fn: 'newsroom',
      context: {
        query: this.state.query,
        url: this.state.url,
        distributionExact: get(this.props, 'user.displayExactDistribution'),
      },
    });

    appExported.record(
      'newsroom',
      extractToolsParams(this.state, ['language']),
    );
  }

  render() {
    const { appData } = this.state;
    const overlappingTopics =
      get(appData, 'compareKgsNews.data.overlapping_topics') || [];
    const rankingUrlsSerp = Object.values(
      get(appData, 'scores.ranking_pages') || {},
    );
    const rankingUrlsSerpRankDict = rankingUrlsSerp.map(rp => ({
      [rp.url]: rp.rank,
    }));
    const rankingUrlsNews = Object.values(
      get(appData, 'scoresNews.ranking_pages') || {},
    ).map(rpnews => ({
      ...rpnews,
      serp_rank: rankingUrlsSerpRankDict[rpnews.url],
    }));

    const serpPresence = rankingUrlsNews.filter(
      rp => !isNil(rp.serp_rank),
    ).length;

    const hotTopic = overlappingTopics.length >= 5;
    const breakingNews = serpPresence > 3;

    const hasQuery = !!this.state.query && this.state.query !== '';
    const hasUrl = !!this.state.url;
    const urlValid = hasUrl ? isUrl(this.state.url) : true;
    const showExport = this.state.showResults && !this.isLoading();
    const isRunActive = hasQuery && urlValid && !this.isLoading();
    return (
      <Page className="mms--app">
        {/* snapshots */}
        {this.state.snapshotsOpen && (
          <SnapshotsModal
            close={() => this.setState({ snapshotsOpen: false })}
            app={lowerCase(PAPI.PermissionResource.NEWSROOM)}
            loadSnapshotData={this.loadSnapshotData}
          />
        )}

        {this.props.trial && (
          <PageHeader
            icon={<IconSvg name="newsroom" size={16} />}
            title="Newsroom"
            loading={this.isLoading()}
            headerProps={{
              'data-mms--app-newsroom--header': true,
            }}
          />
        )}
        {!this.props.snapshot && (
          <PageHeader
            icon={<IconSvg name="newsroom" size={16} />}
            title="Newsroom"
            loading={this.isLoading()}
            headerProps={{
              'data-mms--app-newsroom--header': true,
            }}
          >
            {[
              // topic input
              {
                style: { flexGrow: 1, flexShrink: 1, maxWidth: 360 },
                children: (
                  <AppAutocomplete
                    key="appAutocomplete"
                    fullWidth
                    offset={-8}
                    padding={10}
                    apps={this.props.apps}
                    filter={this.props.filter}
                    activeCountry={this.state.country}
                    activeLanguage="en"
                    setValue={query => this.setState({ query })}
                    setCountry={country => this.setState({ country })}
                    onSubmit={item =>
                      this.runNewsroomApp(null, { configKey: item.id })
                    }
                    popperProps={{ flex: true, style: { height: '100%' } }}
                    inputComponent={SimpleTextInput}
                    style={{ width: '100%' }}
                    inputProps={{
                      placeholder: 'Enter a topic',
                      value: this.state.query,
                      onChange: e => this.setState({ query: e.target.value }),
                      onEnter: () => {
                        if (isRunActive) this.runNewsroomApp();
                      },
                      style: {
                        marginLeft: 18,
                        width: 'calc(100% - 18px)',
                        flexShrink: 0,
                      },
                    }}
                  />
                ),
              },

              // url input
              {
                style: { flexGrow: 1, flexShrink: 1, maxWidth: 360 },
                children: (
                  <SimpleSiteInput
                    offset={-2}
                    padding={10}
                    setValue={url => this.setState({ url })}
                    style={{ width: '100%' }}
                    inputComponent={SimpleTextInput}
                    inputProps={{
                      placeholder: 'Enter url or search by title / url',
                      value: this.state.url,
                      onChange: e => this.setState({ url: e.target.value }),
                      style: { marginLeft: 12 },
                      'data-mms--app-param-url': true,
                    }}
                  />
                ),
              },

              // run button
              <Button
                key="runApp"
                primary
                onClick={this.runNewsroomApp}
                disabled={!isRunActive}
                style={{
                  marginLeft: 12,
                  marginRight: 12,
                  height: 32,
                  padding: '0 18px',
                }}
                data-mms--app-run-trigger
              >
                Run
              </Button>,

              // gap
              { style: { flexGrow: 1 }, children: <div /> },

              // export button
              !showExport ? null : (
                <Export
                  tooltip="Export"
                  key="exportData"
                  onClick={this.export}
                />
              ),

              // snapshots button
              {
                style: { marginRight: 12, flexShrink: 0 },
                children: (
                  <Tooltip title="Snapshots">
                    <Clickable
                      onClick={() =>
                        this.setState({
                          snapshotsOpen: !this.state.snapshotsOpen,
                        })
                      }
                    >
                      <IconSvg
                        name="snapshots"
                        size={28}
                        color={this.state.snapshotsOpen ? 'blue' : 'grey50'}
                      />
                    </Clickable>
                  </Tooltip>
                ),
              },

              // settings button
              {
                style: { marginRight: 12, flexShrink: 0 },
                children: (
                  <AdvancedSettings
                    setState={s => this.setState(s)}
                    state={this.state}
                    tooltip="Settings"
                    tool={PAPI.PermissionResource.NEWSROOM}
                  />
                ),
              },
            ]}
          </PageHeader>
        )}

        <PageBody>
          {!this.state.showResults && <NewsroomZeroState />}

          {this.state.showResults && (
            <div>
              <div style={{ marginBottom: 50 }}>
                {!this.isLoading() &&
                  !this.state.hideTopicBanner &&
                  hotTopic && (
                    <Banner
                      style={{ marginBottom: 20 }}
                      color="red"
                      title="Hot Topic"
                      desc="This topic has significant overlap in the News Top 20 and the SERP Top 20. This is a great opportunity to create content that ranks in the news."
                      dismiss={() => this.setState({ hideTopicBanner: true })}
                    />
                  )}
                {!this.isLoading() &&
                  !this.state.hideNewsBanner &&
                  breakingNews && (
                    <Banner
                      color="blue"
                      title="Breaking News"
                      desc="This topic exists in the Top Stories and more than twice in the Web Top 20. This is an excellent opportunity to dominate in the news box."
                      dismiss={() => this.setState({ hideNewsBanner: true })}
                    />
                  )}
              </div>

              <NewsTopics
                url={this.state.url}
                query={this.state.query}
                loading={this.isLoading()}
                appData={appData}
                snapshot={isStandalone(this.props)}
              />
              <Top20
                url={this.state.url}
                query={this.state.query}
                loading={this.isLoading()}
                appData={appData}
                snapshot={isStandalone(this.props)}
              />
            </div>
          )}
        </PageBody>
      </Page>
    );
  }
}

Newsroom.propTypes = {
  trial: PropTypes.bool,
  snapshot: PropTypes.bool,
  snapshotData: PropTypes.object,
  apps: PropTypes.object,
  filter: PropTypes.object,
  tools: PropTypes.object,
  loadings: PropTypes.object,
  toolsActions: PropTypes.object,
  requestActions: PropTypes.object,
  settings: PropTypes.object,
  persist: PropTypes.object,
  miscActions: PropTypes.object,
};

const mapStateToProps = state => ({
  apps: state.apps,
  filter: state.filter,
  tools: state.tools,
  loadings: state.loadings,
  settings: state.settings,
  persist: state.persist,
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  toolsActions: bindActionCreators(toolsActions, dispatch),
  requestActions: bindActionCreators(requestActions, dispatch),
  miscActions: bindActionCreators(miscActions, dispatch),
});

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(Newsroom),
);
