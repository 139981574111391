import unset from 'lodash/unset';

export const omitItems = <T extends object, K extends keyof T>(
  itemsObj: T,
  keys: K[],
): Omit<T, K> => {
  const dest = Object.assign({}, itemsObj);
  keys.forEach(id => unset(dest, id));
  return dest;
};
