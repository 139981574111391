import React, { memo, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

const Loader = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="currentColor"
  />
);

export interface IconLoaderProps extends React.HtmlHTMLAttributes<HTMLElement> {
  name: string;
  size?: number;
  title?: string;
  color?: string;
}

type IconLoaderComponentType = (
  props: IconLoaderProps,
) => React.ReactElement | null;

const IconLoaderComponent: IconLoaderComponentType = ({
  name,
  size,
  title,
  className,
  ...rest
}: IconLoaderProps) => {
  const ImportedIconRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    let isMounted = true;
    const loadIcons = async () => {
      try {
        // https://react-svgr.com/docs/options/
        const [named, at12, at16, at28] = await Promise.allSettled([
          import(
            /* webpackMode: "eager", webpackPreload: 1 */ `./assets/${name}.svg`
          ),
          import(
            /* webpackMode: "eager", webpackPreload: 1 */ `./assets/${name}_12.svg`
          ),
          import(
            /* webpackMode: "eager", webpackPreload: 1 */ `./assets/${name}_16.svg`
          ),
          import(
            /* webpackMode: "eager", webpackPreload: 1 */ `./assets/${name}_28.svg`
          ),
        ]).then(values => {
          return values.map(v => (v.status === 'fulfilled' ? v : null));
        });

        const weighted = {
          initial: named || at28 || at16 || at12,
          12: at12 || at16 || at28 || named,
          16: at16 || at28 || named || at12,
          28: at28 || named || at16 || at12,
        };

        if (isMounted) {
          ImportedIconRef.current =
            weighted?.[size]?.value?.default ||
            weighted.initial?.value?.default;
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        // console.error(`Unable to load icon - ${name} - ${size}`, error);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };
    loadIcons();
    return () => {
      isMounted = false;
    };
  }, [name, size, setLoading]);

  if (!loading && ImportedIconRef.current) {
    const { current: ImportedIcon } = ImportedIconRef;

    return (
      <ImportedIcon
        title={title || name}
        {...rest}
        className={twMerge(['w-full h-full text-current', className])}
      />
    );
  }

  return <Loader />;
};

export const IconLoader = memo(IconLoaderComponent);
