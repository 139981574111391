import { ApolloQueryResult } from '@apollo/client';
import { isEmpty, pick } from 'lodash';
import { SeoSerpDataQuery } from '@marketmuse/config/types/papi';
import { SeoSerpDataDocument } from '@marketmuse/data-papi';
import {
  ResearchDataInput,
  ResearchThunkSeoRes,
  ThunkApiConfig,
} from '../../types';
import createNonConcurrentAsyncThunk from '../../utils/createNonConcurrentAsyncThunk';
import sanitizeAppParams from '../../utils/sanitizeAppParams';

const getSerpData = createNonConcurrentAsyncThunk<
  ResearchThunkSeoRes,
  ResearchDataInput,
  ThunkApiConfig
>('runs/getSerpData', async (params, { extra, rejectWithValue }) => {
  const variables = sanitizeAppParams<ResearchDataInput>(params);
  const response: ApolloQueryResult<SeoSerpDataQuery> =
    await extra.apollo.query({
      query: SeoSerpDataDocument,
      variables: pick(variables, ['term', 'url', 'country', 'language']),
    });

  const error = response.errors;
  if (error) {
    return rejectWithValue(error);
  }

  const {
    serpFeaturesPresent = [],
    organicSerpItems = [],
    userProvidedPage = {},
  } = response?.data?.seoSerpData || {};

  const data = {
    serpFeaturesPresent,
    organicSerpItems: organicSerpItems.slice(0, 20),
    userProvidedPage: !isEmpty(userProvidedPage) ? userProvidedPage : void 0,
  };

  return {
    data,
    params,
  };
});

export default getSerpData;
