import React from 'react';
import dayjs from 'dayjs';
import { WrapperNew, TextWrapperNew, TextSection, BarText } from './shared';

export const BarLastSaved = React.forwardRef(
  ({ lastSaved, style, shrunk }, ref) => {
    return (
      <WrapperNew ref={ref} style={style}>
        <TextWrapperNew>
          <TextSection>
            Last Saved:
            <BarText color="black" style={{ marginLeft: 4 }}>
              {dayjs(lastSaved)
                .utc(true)
                .local()
                .tz(dayjs.tz.guess())
                .format('HH:mm:ss')}
            </BarText>
          </TextSection>
        </TextWrapperNew>
      </WrapperNew>
    );
  },
);
