import {
  StatBand,
  DifficultyArgs,
  DifficultyCalculateReturnType,
} from '../types';
import backgroundColor from './backgroundColor';
import calculate from './calculate';

const statBandDifficulty: StatBand<
  DifficultyArgs,
  DifficultyCalculateReturnType
> = {
  calculate,
  backgroundColor,
  textColor: (...args) => {
    console.log('Not implemented', args);
    return '';
  },
};

export default statBandDifficulty;
