import styled from 'styled-components';

// https://www.w3.org/WAI/tutorials/menus/application-menus/
// divide menu items to create groups
const MenuTextSeparator = styled.div.attrs(p => ({
  role: 'separator',
  id: void 0
}))`
  height: 1px;
  width: calc(
    100% - calc(${p => p.theme.spacing.medium} + ${p => p.theme.spacing.medium})
  );
  margin: ${p => p.theme.spacing.small} ${p => p.theme.spacing.medium};
  background: var(--mms-simple-border, ${p => p.theme.generic.dark.border});
`;

export default MenuTextSeparator;
