import styled from 'styled-components';

import { isClickable } from '../utils';
import * as SL from '../';

const List = styled(SL.List)`
  overflow: hidden;

  ${p => p.border && `
    border-radius: 3px;
    border: 1px solid ${p.theme.mmxGreyE9};
  `}
`;

const Item = styled(SL.Item)`
  display: flex;
  align-items: center;
  background-color: inherit;
  ${p => p.theme.type.textRegular};
  height: unset;
  min-height: unset;
  white-space: normal;
  word-break: break-word;

  ${p => p.alignRight && `
    justify-content: flex-end;
  `}
  ${p => p.borderRight && `
    border-right: 1px solid ${p.theme.mmxGreyE9};
    box-shadow: 3px 0px 5px -1px rgba(38,41,45,0.05);
  `}
  ${p => p.header && `
    color: ${p.theme.colors.grey50};
    font-weight: bold;
    height: 100%;
    font-size: 9pt;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
  `}
  .mmx--text-input-wrapper {
    height: 32px;
    padding: 0 10px;
  }

  .mmx--text-input-suffix-items {
    right: 8px;
  }

  padding-right: ${p => p.theme.spacing.regular};
  padding-left: ${p => p.theme.spacing.regular};
  padding-top: ${p => p.theme.spacing.xSmall};
  padding-bottom: ${p => p.theme.spacing.xSmall};

  ${p => p.theme.mixins.applyPadding};
  ${p => p.theme.mixins.applyMargin};
`;

const Row = styled(SL.Row).attrs({ m: 0 })`
  background-color: white;
  border-top: 1px solid ${p => p.theme.mmxGreyE6};
  height: unset;
  min-height: 42px;

  &:last-child {
    border-bottom: 1px solid ${p => p.theme.mmxGreyE6};
  }

  ${p => p.header && `
    border: none;
    z-index: 9;
    height: 40px;
  `}

  ${p => p.header && p.sticky && `
    border-bottom: 1px solid ${p.theme.mmxGreyE6};
    margin-bottom: -1px;
  `}

  /* list style active row */
  ${p => p.active && `
    background-color: ${p.theme.mmxGreyF4};
  `}
  //TODO: MMS-290
  ${p => (isClickable(p) || p.hoverAccent) && `
    &:hover {
      background-color: ${p.theme.mmxGrey};
      box-shadow: ${p.theme.moonShadow} !important;
      z-index: 1000 !important;
    }
  `}


  ${p =>
    p.onDragStart &&
    `
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  `}

`;

const Separator = styled(SL.Separator)``;
const Scroll = styled(SL.Scroll)``;

export { List, Item, Row, Separator, Scroll };
