const checkIsGhosting = ({
  adminId,
  userId,
}: {
  adminId: string | undefined;
  userId: string;
}) => {
  if (!adminId) return false;

  return adminId !== userId;
};

export default checkIsGhosting;
