import ReactDOM from 'react-dom';
import { FC, useMemo, ReactElement } from 'react';
import { PortalProps } from './types';

const Portal: FC<PortalProps> = ({ root = 'mms--portal-root', children }) => {
  const portalRoot = useMemo(() => document.getElementById(root), [root]);

  if (portalRoot) {
    return ReactDOM.createPortal(children, portalRoot);
  } else {
    return children as ReactElement;
  }
};
export default Portal;
