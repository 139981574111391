export const initialState = {
  adwordsKeywords: void 0,
  adwordsQuestions: void 0,
  bulkAdwordsKeywords: void 0,
  inventoryTopics: void 0,
  knowledgeGraph: void 0,
  linkingRecommendations: void 0,
  serpUserIntentData: void 0,
  serpData: void 0,
  serpPages: void 0,
  serpQuestions: void 0,
  websiteHeatmap: void 0,
};
