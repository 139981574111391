import { FC } from 'react';
import { IResearchDataProps } from '../types';
import ResearchActions from './ResearchActions';
import ResearchData from './ResearchData';
import ResearchPermissions from './ResearchPermissions';
import ResearchUiState from './ResearchUiState';
import ResearchViewModel from './ResearchViewModel';

const ResearchProvider: FC<IResearchDataProps> = ({ children }) => {
  return (
    <ResearchPermissions>
      <ResearchUiState>
        <ResearchViewModel>
          <ResearchData>
            <ResearchActions>{children}</ResearchActions>
          </ResearchData>
        </ResearchViewModel>
      </ResearchUiState>
    </ResearchPermissions>
  );
};

export default ResearchProvider;
