import { uniq, unset, isEmpty } from 'lodash';
import { original, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as ACTION_TYPES from '../actionTypes';
import {
  SliceTypeLoadings,
  SliceLoadingItem,
  SliceLoadingItemStop,
} from '../types/loadings';

export const initialState: SliceTypeLoadings = {
  _active: {},
};

export const loadingsSlice = createSlice({
  name: 'loadings',
  initialState,
  reducers: {
    clear: () => {
      return initialState;
    },
    setSimple: (
      state,
      action: PayloadAction<{
        key: string;
        value: boolean;
      }>,
    ) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    start: (state, action: PayloadAction<SliceLoadingItem>) => {
      const { id, type, keys = [] } = action.payload;

      state._active[id] = action.payload;
      const stateKeys = uniq([type, ...keys]);
      for (const key of stateKeys) {
        if (!isEmpty(key)) {
          state[key] = true;
        }
      }
    },
    stop: (state, action: PayloadAction<SliceLoadingItemStop>) => {
      const { id } = action.payload;

      if (state._active[id]) {
        const type = state._active[id].type;
        const keys = original(state._active[id].keys) || [];
        const stateKeys = uniq([type, ...keys]);

        for (const key of stateKeys) {
          if (!isEmpty(key)) {
            state[key] = false;
          }
        }
        unset(state, `_active.${id}`);
      }
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      action => action.type === ACTION_TYPES.SIGN_OUT,
      state => {
        return initialState;
      },
    );
  },
});

export const loadingsActions = loadingsSlice.actions;
export const loadingsReducer = loadingsSlice.reducer;
