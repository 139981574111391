import Toast from '../../components/Toast';

import * as types from '../../config/types';

export default () => () => next => action => {
  if (action.type === types.CREATE_REPORT) {
    Toast.fire('This functionality is no longer possible.', 'error');
  }

  return next(action);
};
