import { Store } from 'redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import { CreateStore } from '../types';
import sentryReduxEnhancer from './utils/sentry';

const store = ({
  apolloClient,
  customMiddleware,
  rootReducer,
  state,
}: CreateStore): Store => {
  const middleware = getDefaultMiddleware({
    immutableCheck: false,
    thunk: { extraArgument: { apollo: apolloClient() } },
    serializableCheck: false,
  }).prepend(customMiddleware ? customMiddleware : []);

  return configureStore({
    reducer: rootReducer,
    preloadedState: state,
    devTools: true,
    enhancers: [sentryReduxEnhancer],
    middleware: middleware,
  });
};

export default store;
