import { isEmpty, get } from 'lodash';
import { GetSavedViewsDocument } from '@marketmuse/data-papi';
import * as types from '../../config/types';
import makeRequest from '../../utils/makeRequest';
import { offsetToPage } from '../../utils/misc';

export default () =>
  ({ getState }) =>
  next =>
  action => {
    const state = getState();

    if (action.type === types.GET_SAVED_VIEWS) {
      const { filters, orderBy, limit, offset, search, keys } = action;
      const siteId = get(state, 'filter.site.id');
      const userId = get(state, 'user.id');
      const page = offsetToPage(offset, limit);
      makeRequest({
        type: types.GET_SAVED_VIEWS,
        mutation: GetSavedViewsDocument,
        options: { isQuery: true },
        variables: {
          siteId: siteId,
          order: orderBy,
          page,
          limit,
          filters: [
            {
              AND: [
                !isEmpty(search) && {
                  OR: [
                    {
                      descriptionContains: search,
                    },
                    {
                      titleContains: search,
                    },
                  ],
                },
                {
                  OR: [
                    {
                      sharing: 'PUBLIC',
                    },
                    {
                      sharing: 'SHARED',
                    },
                    {
                      sharing: 'PRIVATE',
                      createdBy: [{ id: userId }],
                    },
                  ],
                },
                ...(!isEmpty(filters) ? filters : []),
              ].filter(Boolean),
            },
          ],
        },
        apolloOptions: {
          fetchPolicy: 'no-cache',
        },
        keys: [
          types.GET_SAVED_VIEWS,
          keys ? keys.map(k => `${types.GET_SAVED_VIEWS}_${k}`) : [],
        ],
      }).then(res => {
        const savedViews = get(res, 'savedViews');
        if (typeof action.callback === 'function' && savedViews)
          action.callback(savedViews);
      });
    }

    return next(action);
  };
