/* eslint-disable jsx-a11y/aria-role */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import styles from '../../../styles/variables';

import Loader from '../../Loader';
import Button from '../../Button';
import hideTooltip from '../../../utils/hideTooltip';
import { SimpleTextInputLabel } from '../../../components/SimpleTextInput';
import RequiredStar from '../../../components/SimpleTextInput/components/RequiredStar';
import * as SL from '../../SimpleList/themes/dropdown';
import Tooltip from '../Tooltip';
import IconSvg from '../../IconSvg/IconSvg';

const PopperPlaceholder = styled(SimpleTextInputLabel)`
  flex-grow: 1;
  cursor: pointer;
  margin-bottom: 0;
  font-weight: 400;
`;

export const PopperChevron = styled(Button).attrs({
  default: true,
  iconOnly: true,
  icon: <IconSvg name="chevron-right" size="16" />
})`
  height: 24px;
  width: 24px;
  margin-left: 0;
  margin-right: 12px;
  background-color: transparent;
  transition: transform 0.2s ease;

  [aria-expanded="false"] & {
    transform: rotate(90deg);
  }

  [aria-expanded="true"] &,
  .mms--tooltip-active & {
    transform: rotate(-90deg);
  }
`;

export const SelectionWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  color: ${p => p.theme.generic.light.foreground};
  background-color: ${p => p.theme.generic.light.backgroundHover};
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    ${p =>
      p.border &&
      `
      border-color: ${p.theme.generic.light.border};
    `}
  }

  ${p =>
    p.disabled &&
    `
    &:hover { cursor: default !important }
    opacity: 0.6;
  `}

  ${p =>
    p.border &&
    `
    height: 38px;
    border: 1px solid ${styles.mmxGreyE9};
    border-radius: 3px;
  `}
`;

const HeaderRow = styled(SL.Row)`
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-color: ${p => p.theme.colors.blue};

  * {
    background: transparent;
  }

  &,
  &:hover {
    background-color: ${p => p.theme.colors.blue};
  }

  &,
  &:hover,
  *,
  *:hover {
    color: white;
  }
`;

const PopperComp = ({
  tippyUsage = 'dropdown',
  tippyTheme = 'light',
  commonHeight,
  condensed,
  loading,
  disabled,
  label,
  placeholder,
  border,
  style = {},
  handleId,
  children,
  selection,
  selectionStyle = {},
  listStyle = {},
  className,
  maxHeight,
  headComponent,
  headText,
  headCTATitle,
  headCTAClick,
  head,
  html,
  items,
  onShow,
  onHide,
  useSpan,
  position,
  open,
  tags,
  ...rest
}) => {
  return (
    <>
      {!!label && tippyUsage === 'dropdown' && (
        <SimpleTextInputLabel>
          {rest.required && <RequiredStar>*</RequiredStar>}
          {label}
        </SimpleTextInputLabel>
      )}
      <Tooltip
        tippyTheme={tippyTheme}
        tippyUsage={tippyUsage}
        childAs={useSpan ? 'span' : 'div'}
        id={handleId}
        disabled={disabled}
        arrow={false}
        placement={position}
        interactive={true}
        trigger="click"
        onShow={onShow}
        onHide={onHide}
        className={className}
        style={style}
        visible={open}
        {...rest}
        html={
          html || (
            <>
              {(headText || headCTATitle || headCTAClick) && (
                <HeaderRow>
                  <SL.Item grow>{headText || ''}</SL.Item>
                  {headCTATitle && headCTAClick && (
                    <SL.Item>
                      <Button
                        text
                        style={{ padding: 0 }}
                        onClick={e => {
                          e.preventDefault();
                          if (typeof headCTAClick === 'function') {
                            headCTAClick();
                          }
                        }}
                      >
                        {headCTATitle}
                      </Button>
                    </SL.Item>
                  )}
                </HeaderRow>
              )}
              <SL.List
                style={{
                  maxHeight: maxHeight || '280px',
                  minWidth: 120,
                  overflow: 'auto',
                  ...listStyle,
                }}
              >
                {(items || []).map((item, index) => (
                  <SL.Row
                    condensed={condensed}
                    key={item?.id || index}
                    disabled={item.disabled}
                    clickDisabled={item.disabled}
                    tooltipProps={item.tooltipProps}
                    active={item.active}
                    style={item.style}
                    {...item.tags}
                    onClick={e => {
                      if (typeof item.onClick === 'function') item.onClick(e);
                      if (item.hideTooltip) hideTooltip(handleId || '');
                    }}
                  >
                    {!!item.icon && (
                      <SL.Item tabIndex={-1} role="" width={32}>
                        {item.icon}
                      </SL.Item>
                    )}
                    {(!!item.text || !!item.component || !!item.title) && (
                      <SL.Item
                        tabIndex={-1}
                        role=""
                        grow
                        pl={item.icon ? 0 : null}
                        {...(item.listItemProps || {})}
                      >
                        {item.component || item.text || item.title}
                        {!!item.description && item.description}
                      </SL.Item>
                    )}
                    {!!item.container && (
                      <SL.Item tabIndex={-1} role="" grow pl={item.icon ? 0 : null}>
                        {item.component || item.text || item.title}
                      </SL.Item>
                    )}
                  </SL.Row>
                ))}
              </SL.List>
            </>
          )
        }
      >
        {children || (
          <SelectionWrapper
            {...tags}
            disabled={disabled}
            border={border}
            commonHeight={commonHeight}
            style={selectionStyle}
          >
            {!selection && (
              <PopperPlaceholder>
                {rest.required && <RequiredStar>*</RequiredStar>}
                {placeholder || 'Select'}
              </PopperPlaceholder>
            )}
            {!!selection && <PopperPlaceholder>{selection}</PopperPlaceholder>}
            {loading && !disabled && <Loader />}
            {!loading && !disabled && (
              <PopperChevron  />
            )}
          </SelectionWrapper>
        )}
      </Tooltip>
    </>
  );
};

PopperComp.propTypes = {
  handleId: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  offset: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.array,
    PropTypes.string,
    PropTypes.number,
  ]),

  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.any,
  html: PropTypes.any,
  items: PropTypes.array,
  children: PropTypes.any,
  condensed: PropTypes.bool,
  border: PropTypes.bool,
  headComponent: PropTypes.any,
  headText: PropTypes.any,
  head: PropTypes.array,
  selection: PropTypes.any,
  selectionStyle: PropTypes.object,
  listStyle: PropTypes.object,
  onShow: PropTypes.func,
  onHide: PropTypes.func,
  maxHeight: PropTypes.string,
  // dropdown activates trigger background
  // popper: make Popper.js behave as simple popper element
  // dropdown: make Popper.js behave as input-like dropdown
  tippyUsage: PropTypes.oneOf(['dropdown', 'popper']),
  tippyTheme: PropTypes.oneOf(['light', 'dark', 'blue']),
  commonHeight: PropTypes.bool, // dunno
  visible: PropTypes.bool,
};

export default PopperComp;
