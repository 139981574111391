import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RESEARCH_ACTIONS } from '@marketmuse/data-state/appResearch';
import { getCountryEnum } from '@marketmuse/utilities';
import { PAPI } from '@marketmuse/config/types';

import { APPLICATIONS_CONFIG_FLAT } from '../../config/getApplicationsConfig';

import hasAccess from '../../utils/hasAccess';
import appConfigKey from '../../utils/applications/appConfigKey';
import hideTooltip from '../../utils/hideTooltip';
import { withNavigate } from '../../hocs/withNavigate';

import Popper from '../Tooltip/Popper';
import Clickable from '../Clickable';

import * as toolsActions from '../../actions/toolsActions';

class RunOnApp extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
    this.handleKeyEvent = this.handleKeyEvent.bind(this);
    this.runOnApp = this.runOnApp.bind(this);
  }

  handleKeyEvent(e) {
    if (e.key === 'Escape') {
      // close the dropdown
      hideTooltip();

      // remove event listener
      document.removeEventListener('keydown', this.handleKeyEvent);
    }
  }

  runOnApp(id) {
    // close the dropdown
    hideTooltip('run-on-app');

    // default app parameters with topic
    const params = {
      topic: this.props.query,
      brands: false,
      people: false,
      bodyOrArticle: 'article',
    };

    // add url if present
    if (this.props.url) params.url = this.props.url;

    // create the configuration key of the parameters
    const configKey = appConfigKey(params);

    // save the config key as the apps active state
    this.props.toolsActions.saveAppData(id, configKey);

    // start the request (optimize starts its )
    if (id !== PAPI.PermissionResource.OPTIMIZE) {
      this.props.toolsActions.getAppData({ app: id, params: params });
    }

    // navigate to the app
    if (id === PAPI.PermissionResource.RESEARCH_APPLICATION) {
      const { competitorDomains, networkDomains, defaultSerpCountry } =
        this.props.filter.site;
      this.props.researchTaskQueryUpsert({
        term: params.topic,
        url: params.url,
        country: getCountryEnum(defaultSerpCountry),
        language: PAPI.Language.EN,
        siteId: this.props.filter.site.id,
        competitorDomains: competitorDomains || [],
        networkDomains: networkDomains || [],
      });
      this.props.navigate('/apps/research/topic-navigator/topic-model');
    } else {
      this.props.navigate(`/apps/${id}`);
    }
  }

  render() {
    const planItems = Object.keys(APPLICATIONS_CONFIG_FLAT)
      .filter(tool => (this.props.exclude || []).indexOf(tool) === -1)
      .filter(
        tool =>
          APPLICATIONS_CONFIG_FLAT[tool].visible &&
          !APPLICATIONS_CONFIG_FLAT[tool].comingSoon,
      )
      .filter(accessId => hasAccess(accessId))
      .map(tool => ({
        icon: APPLICATIONS_CONFIG_FLAT[tool].icon,
        title: APPLICATIONS_CONFIG_FLAT[tool].title,
        onClick: () => this.runOnApp(APPLICATIONS_CONFIG_FLAT[tool].id),
      }));

    return (
      <Popper
        handleId="run-on-app"
        disabled={!!this.props.directToolId}
        items={planItems}
        onShow={e => {
          // attach event listener to document
          document.addEventListener('keydown', this.handleKeyEvent);
          // handle onShow prop function
          if (typeof this.props.onShow === 'function') {
            this.props.onShow(e);
          }
        }}
        onHide={e => {
          // remove event listener
          document.removeEventListener('keydown', this.handleKeyEvent);
          // handle onHide prop function
          if (typeof this.props.onHide === 'function') {
            this.props.onHide(e);
          }
        }}
        maxHeight="280px"
        trigger={this.props.trigger || 'click'}
        position={this.props.position}
        positionOverflow={!!this.props.positionOverflow}
        style={{ display: 'flex', height: '100%', alignItems: 'center' }}
      >
        {!this.props.directToolId && this.props.children}
        {this.props.directToolId && (
          <Clickable onClick={() => this.runOnApp(this.props.directToolId)}>
            {this.props.children}
          </Clickable>
        )}
      </Popper>
    );
  }
}

RunOnApp.propTypes = {
  children: PropTypes.any,
  directToolId: PropTypes.string,
  query: PropTypes.string,
  url: PropTypes.string,
  toolsActions: PropTypes.object,
  onShow: PropTypes.func,
  onHide: PropTypes.func,
  filter: PropTypes.object,
  trigger: PropTypes.string,
  position: PropTypes.string,
  positionOverflow: PropTypes.bool,
  exclude: PropTypes.array,
};

const mapStateToProps = (state, ownProps) => ({
  filter: state.filter,
});

const mapDispatchToProps = dispatch => ({
  toolsActions: bindActionCreators(toolsActions, dispatch),
  researchTaskQueryUpsert: payload =>
    dispatch(RESEARCH_ACTIONS.researchTaskQueryUpsert(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNavigate(RunOnApp));
