import styled from 'styled-components';

import ProgressBar from '../ProgressBar';

export const BarText = styled.span`
  position: relative;
  color: ${p => (p.color ? p.theme[p.color] : p.theme.colors.grey50)};
  font-weight: 900;
`;

export const Indicator = styled.div.attrs({
  className: 'animated fadeInUp',
})`
  width: 100%;
  text-align: center;
  font-size: 10px;
  height: 12px;
  position: absolute;
  top: -12px;
  left: 0;
  color: ${p => p.theme[p.change > 0 ? 'mmxBlue' : 'mmxRed']};
`;

export const WrapperNew = styled.div`
  border-radius: 4px;
  background-color: ${p => p.theme.colors.grey05};
  display: flex;
  flex-flow: column;
  height: 32px;
  overflow: hidden;
  color: ${p => p.theme.colors.grey50};

  &:not(:hover) {
    ${BarText} {
      color: ${p => p.theme.colors.grey50} !important;
    }
    .mms--pb-bar {
      background-color: ${p => p.theme.mmxGreyE9} !important;
    }
  }
`;

export const ProgressBarStyledNew = styled(ProgressBar)`
  height: 3px;
  border-radius: 0;
`;

export const TextWrapperNew = styled.div`
  padding: 3px 8px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bolder;
`;

export const TextSection = styled.div`
  padding: 0 4px;
  transition: ${p => p.theme.transition};
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
  ${p => p.mask && `overflow: hidden;`}
  ${p =>
    p.shrunk &&
    `
    width: 0;
    padding: 0;
  `}
`;
