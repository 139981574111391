import PropTypes from 'prop-types';

import boxVariants from '../../styles/config/boxVariants';
import textVariants from '../../styles/config/textVariants';
import colors from '../../styles/lib/colors';

export const styledComponentProps = {
  // https://styled-components.com/docs/api#forwardedas-prop
  forwardedAs: PropTypes.any,
  // https://styled-components.com/docs/api#as-polymorphic-prop
  as: PropTypes.any,
};

export const boxVariantTypes = {
  boxVariant: PropTypes.oneOf(Object.keys(boxVariants)),
};

export const textVariantTypes = {
  textVariant: PropTypes.oneOf(Object.keys(textVariants)),
};

export const textStyleTypes = {
  fontWeight: PropTypes.string,
  fontStyle: PropTypes.string,
  fontSize: PropTypes.string,
  lineHeight: PropTypes.string,
  textAlign: PropTypes.string,
  letterSpacing: PropTypes.string,
  wordWrap: PropTypes.string,
};

export const colorTypes = {
  backgroundColor: PropTypes.oneOf(Object.keys(colors)),
  color: PropTypes.oneOf(Object.keys(colors)),
};

export const flexTypes = {
  flex: PropTypes.string,
  flexBasis: PropTypes.string,
  flexDirection: PropTypes.string,
  flexFlow: PropTypes.string,
  flexGrow: PropTypes.string,
  flexShrink: PropTypes.string,
  flexWrap: PropTypes.string,
  order: PropTypes.string,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  alignSelf: PropTypes.string,
  alignContent: PropTypes.string,
  gap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const gridTypes = {
  grid: PropTypes.string,
  gridArea: PropTypes.string,
  gridAutoColumns: PropTypes.string,
  gridAutoFlow: PropTypes.string,
  gridAutoRows: PropTypes.string,
  gridColumn: PropTypes.string,
  gridColumnEnd: PropTypes.string,
  gridColumnStart: PropTypes.string,
  gridRow: PropTypes.string,
  gridRowEnd: PropTypes.string,
  gridRowStart: PropTypes.string,
  gridTemplate: PropTypes.string,
  gridTemplateAreas: PropTypes.string,
  gridTemplateColumns: PropTypes.string,
  gridTemplateRows: PropTypes.string,
  gap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnGap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rowGap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const positionTypes = {
  position: PropTypes.string,
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  right: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const paddingTypes = {
  p: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const marginTypes = {
  m: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ml: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

// give any width specification
export const widthTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

// give any height specification
export const heightTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
