import { BadgeSizes, BadgeShades, BadgeColors } from './types';

type GetBadgePaddings = (size: BadgeSizes, rounded?: boolean) => string[];
export const getBadgePaddings: GetBadgePaddings = (size, rounded) => {
  switch (size) {
    case 'xs':
      return [rounded ? 'px-2' : 'px-2.5', 'py-0.5'];
    case 'sm':
      return [rounded ? 'px-2.5' : 'px-3', 'py-0.5'];
    case 'lg':
      return [rounded ? 'px-3.5' : 'px-4', 'py-0.5'];
    case 'base':
    default:
      return [rounded ? 'px-3.5' : 'px-3.5', 'py-0.5'];
  }
};

type GetBadgeShade = (shade: BadgeShades) => number;
export const getBadgeShade: GetBadgeShade = shade => {
  switch (shade) {
    case 'light':
      return 100;
    case 'dark':
      return 300;
    default:
      return 200;
  }
};

type GetBadgeTextSize = (size: BadgeSizes) => string;
export const getBadgeTextSize: GetBadgeTextSize = size => {
  switch (size) {
    case 'xs':
      return 'text-xs';
    case 'sm':
      return 'text-sm';
    case 'lg':
      return 'text-lg';
    default:
      return 'text-base';
  }
};

type GetBadgeTextColor = (color: BadgeColors) => string;
export const getBadgeTextColor: GetBadgeTextColor = color => {
  switch (color) {
    case 'red':
      return 'text-red-500';
    case 'yellow':
      return 'text-yellow-800';
    case 'green':
      return 'text-green-500';
    case 'blue':
      return 'text-blue-500';
    default:
      return 'text-gray-800';
  }
};

type GetBadgeBgColor = (color: BadgeColors, shade: number) => string;
export const getBadgeBgColor: GetBadgeBgColor = (color, shade) => {
  const colorShade = `${color}-${shade}`;

  switch (colorShade) {
    case 'red-100':
      return 'bg-red-100';
    case 'red-200':
      return 'bg-red-200';
    case 'red-300':
      return 'bg-red-300';
    case 'yellow-100':
      return 'bg-yellow-100';
    case 'yellow-200':
      return 'bg-yellow-200';
    case 'yellow-300':
      return 'bg-yellow-300';
    case 'green-100':
      return 'bg-green-100';
    case 'green-200':
      return 'bg-green-200';
    case 'green-300':
      return 'bg-green-300';
    case 'blue-100':
      return 'bg-blue-100';
    case 'blue-200':
      return 'bg-blue-200';
    case 'blue-300':
      return 'bg-blue-300';
    case 'gray-100':
      return 'bg-gray-100';
    case 'gray-200':
      return 'bg-gray-200';
    default:
      return 'bg-gray-300';
  }
};
