import styled from 'styled-components';
import { ReactComponent as Splash } from '../../../assets/decoration-color-splash.svg';
import { ReactComponent as Globe } from '../../../assets/decoration-globe.svg';

const Wrapper = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  min-height: 100vh;
  width: 100vw;
  background-color: ${p => p.theme.colors.black};
`;

const Content = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  min-height: 100%;
  overflow: auto;
  margin: auto;
`;

const SplashWrapper = styled.div`
  position: absolute;
  z-index: 10;
  top: -600px;
  right: -600px;
  width: 1265px;
  height: 1229px;
`;

const SplashStyled = styled(Splash)`
  display: block;
`;

const GlobeWrapper = styled.div`
  position: absolute;
  z-index: 10;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;
const GlobeStyled = styled(Globe)`
  position: absolute;
  display: block;
  bottom: -2200px;
  left: 50%;
  transform: translateX(-50%);
`;

const PageDark = ({ children }) => {
  return (
    <Wrapper>
      <Content>{children}</Content>
      <SplashWrapper>
        <SplashStyled />
      </SplashWrapper>
      <GlobeWrapper>
        <GlobeStyled />
      </GlobeWrapper>
    </Wrapper>
  );
};

export default PageDark;
