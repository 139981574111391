import React, { FC } from 'react';
import { ITruncableElementProps } from '../types';

export const TruncableElement: FC<ITruncableElementProps> = ({
  prev,
  isNextTruncable,
  isPreviousTruncable,
  truncableClassName,
  truncableText,
}) => {
  return (isPreviousTruncable && prev === true) ||
    (isNextTruncable && !prev) ? (
    <span className={truncableClassName || undefined}>{truncableText}</span>
  ) : null;
};
