import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Portal from '../Portal';
import { LoaderSparkles } from '../Loader'


const Wrapper = styled.div`
  height: ${p => (p.full ? '100%' : p.height || '100%')};
  width: ${p => p.width || 90}%;
  ${p => p.full && `width: 100%;`}
  ${p => p.full && `margin: 0;`}
  backdrop-filter: blur(4px);
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  svg {
    color: ${p => p.theme.colors.white};
    height: ${p => p.svgSize * 2}px;
    width: ${p => p.svgSize * 2 }px;
  }
  flex-direction: column;
`;
const Message = styled.div`
  ${p => p.theme.type.heading3};
  color: ${p => p.theme.colors.white}
`;

const ScreenLoaderDark = ({ style, full, height, svgSize, message }) => (
  <Portal>
    <Wrapper style={style} full={full} svgSize={svgSize} height={height}>
      <LoaderSparkles color="white" />
      <Message>{message}</Message>
    </Wrapper>
  </Portal>
);

ScreenLoaderDark.defaultProps = {
  svgSize: 32,
};

ScreenLoaderDark.propTypes = {
  full: PropTypes.bool,
  style: PropTypes.object,
  height: PropTypes.string,
  svgSize: PropTypes.number,
};

export default ScreenLoaderDark;
