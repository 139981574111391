import { createSlice } from '@reduxjs/toolkit';

import { Permissions } from '../types';
import * as ACTION_TYPES from '../actionTypes';

/**
 * The permissions for the current user.
 */
export const initialState: Permissions = [];

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    setPermissions: (state, action) => {
      return action.payload;
    },
    clearPermissions: () => {
      return [];
    },
  },
  extraReducers: builder => {
    builder
      // Clear the permissions on sign out.
      .addMatcher(
        action => action.type === ACTION_TYPES.SIGN_OUT,
        () => {
          return [];
        },
      );
  },
});

export const permissionsActions = permissionsSlice.actions;
export const permissionsReducer = permissionsSlice.reducer;
