import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const gap = 6;
const gapLarge = 12;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: -${gap}px;
`;

export const Item = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: ${gapLarge}px;
  margin-top: ${gap}px;

  &:after {
    content: '';
    margin-left: ${gapLarge}px;
    width: 1px;
    height: 16px;
    background-color: ${p => p.theme.mmxGreyE6};
  }

  &:last-child {
    &:after {
      display: none;
    }
  }

  ${p => p.noBorderRight && `
    &:after { display: none; }
    border-right: none;
  `}

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
    border-right: none;
  }
`;

const ItemsVertical = ({ style = {}, className, children }) => (
  <Wrapper style={style} className={className}>{children}</Wrapper>
);

ItemsVertical.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.any,
};

export default ItemsVertical;
