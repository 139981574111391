import { ReduxListener } from '../../../types';

import bulkAdwordsKeywords from './bulkAdwordsKeywords';
import knowledgeGraph from './knowledgeGraph';
import pageContents from './pageContents';

const setupListeners = (startListening: ReduxListener) =>
  [bulkAdwordsKeywords, knowledgeGraph, pageContents].forEach(listener =>
    listener(startListening),
  );

export default setupListeners;
