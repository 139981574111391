import * as React from 'react';
import { IdpContextType } from '../../types';
import { IdentityContext } from './context';

const useIdentityProvider = (): IdpContextType => {
  const context = React.useContext(IdentityContext);
  if (!context) {
    throw new Error(
      'This component must be used within the <IdentityProvider />.',
    );
  }
  return context;
};

export default useIdentityProvider;
