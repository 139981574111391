import checkIsGhosting from './checkIsGhosting';
import checkIsSuperAdmin from './checkIsSuperAdmin';

type Args = {
  adminOrgId: string;
  userId: string;
  adminId: string | undefined;
};

function checkIsAdminMode({ adminId, userId, adminOrgId }: Args) {
  const isSuperAdmin = checkIsSuperAdmin({
    userOrgId: adminOrgId,
  });
  const ghosting = isSuperAdmin && checkIsGhosting({ adminId, userId });

  if (ghosting) {
    return false;
  } else {
    return isSuperAdmin;
  }
}

export default checkIsAdminMode;
