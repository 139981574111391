import { css } from 'styled-components';

import {
  flexProps,
  gridProps,
  positioningProps,
  marginProps,
  paddingProps,
  widthProps,
  heightProps,
  textStyleProps,
  colorProps,
} from '../config/styleProps';

import textVariants from '../config/textVariants';
import boxVariants from '../config/boxVariants';
import utils from './utils';
import breakpoints from './breakpoints';
import colors from './colors';

const showSROnly = {
  position: 'absolute',
  width: '1px',
  height: '1px',
  margin: '-1px',
  padding: 0,
  overflow: 'hidden',
  clip: 'rect(0,0,0,0)',
  border: 0,
};

const listReset = {
  paddingLeft: 0,
  marginTop: 0,
  marginBottom: 0,
  listStyle: 'none',
};

const minMediaQuery = Object.keys(breakpoints).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media (min-width: ${breakpoints[label].min}) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});

const maxMinMediaQuery = Object.keys(breakpoints).reduce(
  (accumulator, label) => {
    accumulator[label] = (...args) => css`
      @media (max-width: ${breakpoints[label].min}) {
        ${css(...args)};
      }
    `;
    return accumulator;
  },
  {},
);

const maxMediaQuery = Object.keys(breakpoints).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media (max-width: ${breakpoints[label].max}) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});

const buttonReset = props => css`
  padding: 0;
  margin: 0;
  background: transparent;
  text-align: left;
  outline: none;
  border: 0;

  &:hover {
    cursor: ${props.disabled ? 'default' : 'pointer'};
  }
`;

const applyPositioning = () => css(utils.reduceProps(positioningProps));
const applyFlex = () =>
  css(utils.reduceProps(flexProps, { processPropValue: value => value }));
const applyGrid = () => css(utils.reduceProps(gridProps));

const applyPadding = () => css(utils.reduceProps(paddingProps));
const applyMargin = () => css(utils.reduceProps(marginProps));

const applyWidths = () => css(utils.reduceProps(widthProps));
const applyHeights = () => css(utils.reduceProps(heightProps));
const applyTextStyles = () => css(utils.reduceProps(textStyleProps));
const applyColors = () =>
  css(
    utils.reduceProps(colorProps, {
      processPropValue: value => colors[value] || value,
    }),
  );

const applyBoxVariant = props =>
  props.boxVariant &&
  boxVariants.hasOwnProperty(props.boxVariant) &&
  boxVariants[props.boxVariant];

const applyTextVariant = props =>
  props.textVariant &&
  textVariants.hasOwnProperty(props.textVariant) &&
  textVariants[props.textVariant];

export default {
  minMediaQuery,
  maxMediaQuery,
  maxMinMediaQuery,
  listReset,
  showSROnly,
  applyPositioning,
  applyWidths,
  applyHeights,
  applyBoxVariant,
  applyTextVariant,
  applyTextStyles,
  applyColors,
  applyFlex,
  applyGrid,
  applyPadding,
  applyMargin,
  buttonReset,
};
