import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';
import {
  useStoreDispatch,
  useStoreSelector,
} from '@marketmuse/data-state/hooks';
import { MISC_SIMPLE_KEYS } from '@marketmuse/data-state/types';
import { miscActions } from '@marketmuse/data-state/misc';

const useInitialPath = () => {
  const dispatch = useStoreDispatch();
  const initialPath = useStoreSelector(
    state => state.misc[MISC_SIMPLE_KEYS.initialPath],
  );
  const location = useLocation();

  useEffect(() => {
    const ignoreEntries = ['/', '/guest', '/login', '/logout', '/post-login'];

    if (isEmpty(initialPath) && !ignoreEntries.includes(location.pathname)) {
      dispatch(
        miscActions.setSimple({
          key: MISC_SIMPLE_KEYS.initialPath,
          value: location.pathname,
        }),
      );
    }
  }, [location.pathname, dispatch, initialPath]);

  return initialPath;
};

export default useInitialPath;
