import { intentTitle } from './titles';

const TOPIC_INTENT = {
  KNOW: 'info - know',
  KNOW_SIMPLE: 'info - know simple',
  COMP: 'info - comparison',
  TRANS: 'transactional',
  LOCAL: 'local',
  WEBSITE_QUERY: 'website query',
};

export const topicIntents = {
  [TOPIC_INTENT.KNOW]: { id: TOPIC_INTENT.KNOW, title: intentTitle.INFO_KNOW },
  [TOPIC_INTENT.KNOW_SIMPLE]: {
    id: TOPIC_INTENT.KNOW_SIMPLE,
    title: intentTitle.INFO_KNOW_SIMPLE,
  },
  [TOPIC_INTENT.COMP]: {
    id: TOPIC_INTENT.COMP,
    title: intentTitle.INFO_COMPARISON,
  },
  [TOPIC_INTENT.TRANS]: {
    id: TOPIC_INTENT.TRANS,
    title: intentTitle.TRANSACTIONAL,
  },
  [TOPIC_INTENT.LOCAL]: { id: TOPIC_INTENT.LOCAL, title: intentTitle.LOCAL },
  [TOPIC_INTENT.WEBSITE_QUERY]: {
    id: TOPIC_INTENT.WEBSITE_QUERY,
    title: intentTitle.WEBSITE_QUERY,
  },
};

// PAPI
export const topicIntentsDetailScreen = {
  infoKnow: { id: TOPIC_INTENT.KNOW, title: intentTitle.INFO_KNOW },
  infoKnowSimple: {
    id: TOPIC_INTENT.KNOW_SIMPLE,
    title: intentTitle.INFO_KNOW_SIMPLE,
  },
  infoComparison: {
    id: TOPIC_INTENT.COMP,
    title: intentTitle.INFO_COMPARISON,
  },
  transactional: { id: TOPIC_INTENT.TRANS, title: intentTitle.TRANSACTIONAL },
  local: { id: TOPIC_INTENT.LOCAL, title: intentTitle.LOCAL },
  websiteQuery: {
    id: TOPIC_INTENT.WEBSITE_QUERY,
    title: intentTitle.WEBSITE_QUERY,
  },
};
