import { useMemo } from 'react';

export const useColorClasses = (
  existsInInventory: boolean,
  locked: boolean,
  loading: boolean,
) => {
  return useMemo(() => {
    if (existsInInventory)
      return [
        'border-transparent',
        'bg-green-200',
        'hover:bg-green-200',
        'text-green-500',
      ];
    if (locked) return ['border-transparent', 'bg-gray-200', 'text-gray-600'];
    if (loading) return ['border-transparent', 'animate-loading-gray-100'];
    return [
      'text-gray-600',
      'border-gray-600',
      'hover:border-transparent',
      'hover:bg-gray-200',
    ];
  }, [existsInInventory, loading, locked]);
};
