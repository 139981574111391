import React from 'react';
import styled, { css } from 'styled-components';
import { isNil, get } from 'lodash';
import IconPlus from 'react-feather/dist/icons/plus';

import Clickable from '../../../../components/Clickable';
import IconSvg from '../../../../components/IconSvg';

import { paddingLR } from '../shared';
import { sideItemProps, BadgeButton } from './shared';

const Wrapper = styled(Clickable)`
  padding: 12px 18px 12px 12px;
  height: 40px;
  font-weight: bold;
  ${p =>
    p.locked &&
    `
    opacity: 0.3;
    cursor: default;
  `}
  ${p =>
    p.header &&
    `
    height: 40px;
    color: ${p.theme.colors.grey25};
    margin-bottom: 10px;
  `}
  ${p =>
    p.subheader &&
    `
    padding: 6px 18px 7px 12px;
    height: 28px;
    color: ${p.theme.colors.grey60};
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1px;
    margin-bottom: 10px;
    margin-top: 13px;
    text-transform: uppercase;
  `}
  display: flex;
  align-items: center;

  user-select: none;
  background-color: transparent;

  ${p =>
    !p.header &&
    !p.subheader &&
    !p.locked &&
    `
    &:hover {
      background-color: ${p.theme.colors.black};
      border-radius: ${p.theme.borderRadius};
    }
  `}
  ${p =>
    (p.header || p.subheader) &&
    !p.onClick &&
    `
    cursor: default;
  `}
  ${p =>
    p.paddingLR &&
    `
    padding-right: ${paddingLR}px;
    padding-left: ${paddingLR}px;
  `}
  ${p =>
    p.backgroundColor &&
    `background-color: ${
      p.theme.colors[p.backgroundColor] || p.backgroundColor
    };`}
  ${p => p.color && `color: ${p.theme.colors[p.color] || p.color};`}
  border-radius: ${p => p.theme.borderRadius};
  ${p =>
    p.active &&
    !p.locked &&
    `
    color: ${
      p.activeColor
        ? p.theme.colors[p.activeColor] || p.activeColor
        : p.theme.sidebarColorSecondary
    };
    background-color: ${p.theme.colors.black};
  `}
  ${p =>
    p.shouldFitContent &&
    `
    min-height: 40px;
    height: fit-content;
  `}
  ${p =>
    p.order &&
    `
    order: ${p.order};
  `}
`;

const IconWrapper = styled.div`
  order: ${p => p.order};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  color: ${p => (p.active ? 'currentColor' : p.theme.colors.grey50)};

  svg {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const TitleWrapper = styled.div`
  order: ${p => p.order};
  display: flex;
  align-items: center;
  height: ${p => (p.shouldFitContent ? 'fit-content' : '40px')};
  white-space: nowrap;
`;

const LabelWrapper = styled.div`
  background-color: ${p => {
    const background =
      p.background && (p.theme.colors[p.background] || p.background);
    return p.active ? background || p.theme.colors.black : p.theme.colors.black;
  }};
  color: ${p => (p.active ? p.theme.colors.white : p.theme.colors.grey25)};
  ${p =>
    p.labelLoading &&
    css`
      animation: ${p.theme.keyframes.flashOp} 1.2s linear infinite;
    `}
  border-radius: 3px;
  padding: 4px;
  margin-left: 12px;
  font-style: normal;
  font-weight: ${p => (p.active ? 'bold' : 'normal')};
  height: 16px;
  font-size: 10px;
  line-height: 16px;
  align-items: center;
  display: flex;
  text-align: right;
  order: ${p => p.order};
`;

const BadgeWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
  order: ${p => p.order};

  &:hover {
    color: ${p => p.theme.sidebarColorSecondary};
  }

  ${p =>
    p.hoverProps &&
    css`
      display: none;

      &:hover {
        color: ${p.theme.colors[p?.hoverProps?.color] || 'currentColor'};
      }

      ${Wrapper}:hover & {
        display: block;
      }
    `}
`;

const SidebarItem = props => {
  const trackPrefix = props.dataTrack
    ? props.dataTrack
    : `sidebar--${props.title}`;
  const isDisabled =
    (props.header || props.subheader) && (!props.onClick || props.locked);

  return (
    <Wrapper
      order={props.order}
      aria-label={props.title}
      header={props.header}
      subheader={props.subheader}
      paddingLR={props.paddingLR}
      onClick={props.onClick}
      active={props.active && !props.isFilter ? true : void 0}
      locked={props.locked}
      data-track={`${trackPrefix}--wrapper`}
      disabled={isDisabled}
      color={props.color}
      backgroundColor={props.backgroundColor}
      activeColor={props.activeColor}
      shouldFitContent={props.shouldFitContent}
    >
      {/* icon */}
      {props.icon && !props.collapsedIconOnly && (
        <IconWrapper active={props.active} order={1}>
          {props.icon}
        </IconWrapper>
      )}

      {/* title */}
      <TitleWrapper order={2} shouldFitContent={props.shouldFitContent}>
        {props.title}
      </TitleWrapper>

      {/* gap */}
      <div
        style={{
          flexGrow: 1,
          display: 'flex',
          order: props.labelOrder === 3 ? 4 : 3,
        }}
      />

      {/* label */}
      {!isNil(props.label) && (
        <LabelWrapper
          order={props.labelOrder || 3}
          active={props.active}
          background={props.activeLabelBackground}
        >
          {get(props, 'commonProps.labelLoading') && '-'}
          {!get(props, 'commonProps.labelLoading') && props.label}
        </LabelWrapper>
      )}

      {/* badge */}
      {!isNil(props.badge) && !props.locked && (
        <BadgeWrapper order={5}>{props.badge}</BadgeWrapper>
      )}

      {!isNil(props.badgeAdd) && !props.locked && (
        <BadgeWrapper order={6}>
          <BadgeButton
            data-track={trackPrefix + '--badge'}
            tooltip={props.badgeAdd[0]}
            tooltipPosition="right"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              props.badgeAdd[1]();
            }}
          >
            <IconPlus />
          </BadgeButton>
        </BadgeWrapper>
      )}

      {!isNil(props.buttonRight) && !props.locked && (
        <BadgeWrapper order={6} hoverProps={props?.buttonRight?.hoverProps}>
          <BadgeButton
            data-mms={
              props?.buttonRight?.dataTrack || trackPrefix + '--btn-right'
            }
            tooltip={props?.buttonRight?.title}
            tooltipPosition="right"
            onClick={props?.buttonRight.onClick}
          >
            {props?.buttonRight?.children}
          </BadgeButton>
        </BadgeWrapper>
      )}

      {props.locked && (
        <BadgeWrapper order={7}>
          <IconSvg name="locked" size="16" />
        </BadgeWrapper>
      )}
    </Wrapper>
  );
};

SidebarItem.propTypes = {
  ...sideItemProps,
};

export default SidebarItem;
