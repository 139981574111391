import styles from '../../styles/variables';

class Toast {
  static success({ opts, msg }) {
    const options = opts || this._getOpts(msg);
    try {
      options.class = 'iziToast--success';
      window.iziToast.success(options);
    } catch (e) {
      /* do nothing */
    }
  }

  static warning({ opts, msg }) {
    const options = opts || this._getOpts(msg);
    try {
      options.class = 'iziToast--warning';
      window.iziToast.warning(options);
    } catch (e) {
      /* do nothing */
    }
  }

  static error({ opts, msg }) {
    const options = opts || this._getOpts(msg);
    try {
      options.class = 'iziToast--error';
      window.iziToast.error(options);
    } catch (e) {
      /* do nothing */
    }
  }

  static show({ opts, msg }) {
    const options = opts || this._getOpts(msg);
    try {
      options.class = 'iziToast--default';
      window.iziToast.show(options);
    } catch (e) {
      /* do nothing */
    }
  }

  static fire(msg, type, options = {}) {
    const opts = this._getOpts(msg, options);
    switch (type) {
      case 'success':
        this.success({ opts });
        break;
      case 'warning':
        this.warning({ opts });
        break;
      case 'error':
        this.error({ opts });
        break;
      default:
        this.show({ opts });
        break;
    }
  }

  static _getOpts(msg, options) {
    return {
      message: msg || '',
      position: 'bottomRight',
      transitionIn: 'fadeInDown',
      animateInside: false,
      pauseOnHover: true,
      progressBar: false,
      backgroundColor: styles.colors.white,
      timeout: 6000,
      maxWidth: '500px',
      icon: '',
      theme: 'light',
      zindex: 2147483647,
      ...options,
    };
  }
}

window.Toast = Toast;

export default Toast;
