import { SUPPORTED_ICON } from '../../../Icon';
import { ToastComponentProps } from '../types';

type ToastType = ToastComponentProps['type'];

export const getToastColor = (type: ToastType) => {
  switch (type) {
    case 'success':
      return 'green-400';
    case 'error':
      return 'red-400';
    case 'neutral':
      return 'blue-400';
    case 'warning':
      return 'yellow-400';
    case 'info':
      return 'gray-400';
    default:
      return type;
  }
};

export const getToastIcon = (type: ToastType) => {
  switch (type) {
    case 'error':
      return SUPPORTED_ICON.close;
    case 'neutral':
      return SUPPORTED_ICON.info_letter;
    case 'warning':
      return SUPPORTED_ICON.warning_triangle;
    case 'info':
      return SUPPORTED_ICON.info_letter;
    default:
    case 'success':
      return SUPPORTED_ICON.checkmark;
  }
};
