import { ResearchKGItem, ResearchAdWordsItem } from '../../types';
import { createInventoryItemsThunk } from '../utils/createInventoryItemsThunk';

export const getInvItemsAdwordsKeywords =
  createInventoryItemsThunk<ResearchAdWordsItem>({
    typePrefix: 'runs/getInvItemsAdwordsKeywords',
  });

export const getInvItemsAdwordsQuestions =
  createInventoryItemsThunk<ResearchAdWordsItem>({
    typePrefix: 'runs/getInvItemsAdwordsQuestions',
  });

export const getInvItemsKnowledgeGraph =
  createInventoryItemsThunk<ResearchKGItem>({
    typePrefix: 'runs/getInvItemsKnowledgeGraph',
  });

export const getInvItemsBulkAdwordsKeywords =
  createInventoryItemsThunk<ResearchAdWordsItem>({
    typePrefix: 'runs/getInvItemsBulkAdwordsKeywords',
  });

export const getInvItemsSerpQuestions =
  createInventoryItemsThunk<ResearchAdWordsItem>({
    typePrefix: 'runs/getInvItemsSerpQuestions',
  });
