import get from 'lodash/get';
import { loadingsActions } from '@marketmuse/data-state/loadings';
import * as types from '../config/types';
import { requestSuccess, requestFail } from '../actions/requestActions';
import { parseErrorResponse } from '../utils/misc';

export default () =>
  ({ dispatch, getState }) =>
  next =>
  action => {
    if (action.type === types.REQUEST) {
      // set an id for each request
      const id = `${Date.now()}-${Math.round(Math.random() * 10000)}`;

      // start the request
      dispatch(
        loadingsActions.start({
          id,
          type: action.requestType,
          keys: action.requestKeys || [],
        }),
      );

      // initiate the request. dispatch actions that'll be grabbed by internal request
      // handlers. they will dispatch the global success or fail actions only if
      // the request has not been cancelled
      action
        .mutation({
          ...(get(action, 'data') || {}),
          variables: {
            ...(get(action, 'data.variables') || {}),
          },
        })
        .then(res => {
          dispatch({
            id,
            res,
            type: get(res, 'data', false) ? '_success' : '_fail',
            actionReq: action,
          });
        })
        .catch(e => {
          dispatch({ id, type: '_fail', actionReq: action, res: e });
        });
    }

    // internal success handler
    if (action.type === '_success') {
      const { id, res, actionReq } = action;
      const state = getState();

      // is request still active (ie. not cancelled)
      const requestActive = get(state, `loadings._active['${id}']`);

      // dispatch global success action and exec callback
      if (requestActive) {
        // stop the request
        dispatch(
          loadingsActions.stop({
            id,
          }),
        );

        if (actionReq.callback) actionReq.callback(res.data);
        Promise.resolve().then(() => {
          dispatch(
            requestSuccess(
              id,
              res.data,
              actionReq.requestType,
              actionReq.requestKeys,
              actionReq.options,
            ),
          );
        });
      }
    }

    // internal fail handler
    if (action.type === '_fail') {
      const { id, res, actionReq } = action;
      const state = getState();

      // is request still active (ie. not cancelled)
      const requestActive = get(state, `loadings._active['${id}']`);

      // dispatch global fail action and exec callback
      if (requestActive) {
        // stop the request
        dispatch(
          loadingsActions.stop({
            id,
          }),
        );

        const error = parseErrorResponse(res);

        if (actionReq.callback) actionReq.callback({ error });
        Promise.resolve().then(() => {
          dispatch(
            requestFail(
              id,
              { message: error || 'Unknown error' },
              actionReq.requestType,
              actionReq.requestKeys,
              actionReq.options,
            ),
          );
        });
      }
    }

    return next(action);
  };
