import * as types from '../config/types';

export const recurlyHydrateState = ({
  accountCode,
  accountCodeTest,
  callback = null,
} = {}) => ({
  type: types.RECURLY_HYDRATE_STATE,
  accountCode,
  accountCodeTest,
  callback,
});

export const getRecurlyAccount = ({
  callback,
  accountCode,
  accountCodeTest,
} = {}) => ({
  type: types.RECURLY_GET_ACCOUNT,
  callback,
  accountCode,
  accountCodeTest,
});

export const getRecurlySubscriptions = ({
  callback,
  accountCode,
  accountCodeTest,
} = {}) => ({
  type: types.RECURLY_GET_SUBSCRIPTIONS,
  callback,
  accountCode,
  accountCodeTest,
});

export const getRecurlyTransactions = ({
  callback,
  accountCode,
  accountCodeTest,
} = {}) => ({
  type: types.RECURLY_GET_TRANSACTIONS,
  callback,
  accountCode,
  accountCodeTest,
});

export const upsertBillingInfo = ({
  recurlyToken,
  callback,
  callbackFail,
}) => ({
  type: types.RECURLY_UPSERT_BILLING_INFO,
  recurlyToken,
  callback,
  callbackFail,
});

export const recurlyCreateAccount = ({ test, params, callback }) => ({
  type: types.RECURLY_CREATE_ACCOUNT,
  test,
  params,
  callback,
});

export const validateCoupon = ({ couponCode, callback, callbackFail }) => ({
  type: types.RECURLY_VALIDATE_COUPON,
  couponCode,
  callback,
  callbackFail,
});
