import { trim } from 'lodash';
import { isDomain as checkIsDomain } from '@marketmuse/utilities';

// validation utils
export const validate = (
  field,
  name,
  compareValidationField,
  ...validations
) => {
  return state => {
    return Object.values(validations).reduce((acc, v) => {
      const errorMessage = v(
        state[field],
        state[compareValidationField?.field],
        state,
      );

      if (!errorMessage) {
        return acc;
      }

      if (typeof errorMessage === 'function') {
        return {
          ...acc,
          [field]: errorMessage(name, compareValidationField?.name),
        };
      } else {
        return { ...acc, [field]: errorMessage };
      }
    }, {});
  };
};

export const reduceRule = (state, runners) =>
  runners.reduce((memo, runner) => {
    const validation = runner(state);
    return Object.assign(memo, validation);
  }, {});

export const isEmptyStr = string => string === '';

export const isEmptyObj = obj =>
  Object.entries(obj).length === 0 && obj.constructor === Object;

// error messages
export const isNotRequired = fieldName => `${fieldName} is required`;

export const isNotValid = fieldName => `${fieldName} is not valid`;

export const isNotEmail = fieldName => `${fieldName} is not a valid email`;

export const isNotWorkEmail = () =>
  'Please use a business email, or reach out to support for more information.';

export const isNotURL = fieldName => `${fieldName} is not a valid URL`;

export const isNotMatch = (fieldName, comparisonFieldName) =>
  `${fieldName} is not matched to ${comparisonFieldName}`;

export const isNotStrongPassword = fieldName =>
  `${fieldName} is not strong enough`;

export const isNotRootDomain = fieldName =>
  `${fieldName} is not a valid root domain`;

export const isNotShortPhrase = () =>
  'This demonstration works best for head and medium tail terms, please choose a shorter phrase that’s four words or less';

export const isNotLongEnoughPhrase = () =>
  'This demonstration works best for more realistic search terms';

export const containsDisallowedChar = () =>
  'This demonstration works best without special characters';

export const isNotPhoneNumber = fieldName =>
  `${fieldName} is not a valid phone number`;

// validation rules
export const isName = value => {
  const nameRegex = /[`!@#$%^*()+=[\]{};':"\\|,<>/?~]/;
  if (!trim(value)) return isNotRequired;
  if (nameRegex.test(value)) return isNotValid;
  return null;
};

export const required = value => {
  return trim(value) ? null : isNotRequired;
};

export const isEmail = value => {
  const format =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (value && !format.test(value)) {
    return isNotEmail;
  }
  return null;
};

export const isWorkEmail = value => {
  const invalidEmails = [
    'gmail.',
    'hotmail.',
    'yahoo.',
    'outlook.',
    'icloud.',
    'aol.',
    'inbox.',
    'mail.',
    'yandex.',
    'excite.',
    'protonmail.',
    'byom.',
    'askalmanac.',
  ];

  if (value) {
    const domain = value.split('@')[1] || '';

    if (invalidEmails.some(substring => domain.includes(substring))) {
      return isNotWorkEmail;
    }
    return null;
  }
};

export const isWebsite = value => {
  if (value && !checkIsDomain(value)) {
    return isNotURL;
  }
  return null;
};

export const isDomain = value => {
  if (value && !checkIsDomain(value)) {
    return isNotRootDomain;
  }
  return null;
};

export const limitSpecialChar = value => {
  // eslint-disable-next-line
  const format = /[!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?]+/;
  if (value && format.test(value)) {
    return containsDisallowedChar;
  }
  return null;
};

export const isStringMatch = (string1, string2) => {
  return string1 === string2;
};

export const isMatch = (string1, string2) => {
  if (string1 !== string2) {
    return isNotMatch;
  }
  return null;
};

export const isStrongPassword = value => {
  const specChars = /[!@#$%^&*]/;
  const lowerChar = /[a-z]/;
  const upperChar = /[A-Z]/;
  const numberChar = /[0-9]/;

  const isStrong =
    specChars.test(value) &&
    lowerChar.test(value) &&
    upperChar.test(value) &&
    numberChar.test(value) &&
    value.length >= 8;

  if (!isStrong) {
    return isNotStrongPassword;
  }
  return null;
};
