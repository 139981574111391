import { isEmpty } from 'lodash';
import { ValidateEmailDocument as VALIDATE_EMAIL } from '@marketmuse/data-papi';
import makeRequest from '../../utils/makeRequest';
import * as types from '../../config/types';

export default () => () => next => action => {
  if (action.type === types.VALIDATE_EMAIL) {
    makeRequest({
      type: types.VALIDATE_EMAIL,
      mutation: VALIDATE_EMAIL,
      options: { isQuery: true },
      variables: { email: action.email },
    })
      .then(res => {
        const data = res?.validateEmail ?? {};
        if (!data?.valid) {
          const message = data?.message;
          const next = {
            ...data,
            error: !isEmpty(message)
              ? message
              : 'This email address is not valid.',
          };
          if (typeof action.callback === 'function') action.callback(next);
        } else {
          if (typeof action.callback === 'function') action.callback(data);
        }
      })
      .catch(error => {
        console.error(error);
        if (typeof action.callback === 'function')
          action.callback({ error: "Email can't be validated" });
      });
  }

  return next(action);
};
