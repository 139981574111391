import React, {  forwardRef, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import defaultProps from '../Tippy/defaultProps';
import Tippy from '../Tippy';

const TippyChild = styled.span`
  display: inline;
  align-items: center;
  outline: none;
  max-height: 100%;
`;

const Tooltip = forwardRef(
  (
    {
      tippyTheme = 'dark',
      tippyUsage = 'tooltip',
      childAs = 'span',
      childProps = {},
      style,
      className = '',
      id,

      title,
      html,
      tabIndex = 0,
      children,

      manualOrigin,
      maxWidth,
      // main tippy args
      popperOptions,
      visible,
      interactive,
      interactiveBorder,
      delay,
      duration,
      arrow,
      disabled,
      trigger,
      placement,
      hideOnClick = true,
      showOnCreate,
      offset,
      reference,
      plugins,

      position, // deprecate, use placement
      distance, // deprecate, use offset
      ...rest
    },
    ref
  ) => {
    const safe = {
      ...defaultProps,
      manualOrigin,
      visible,
      reference,
      disabled,
      hideOnClick,
      showOnCreate,
      interactive,
      interactiveBorder,
      delay,
      duration,
      popperOptions,
      plugins,
      arrow: arrow,
      theme: tippyTheme,
      trigger: trigger || defaultProps.trigger,
      offset: offset || distance || defaultProps.offset,
      placement: placement || position || defaultProps.placement,
      maxWidth: maxWidth || defaultProps.maxWidth,
    };

    const tippyChildProps = {
      ...childProps,
      as: childAs,
      id,
      className,
      style,
      tabIndex,
      tippyTheme,
      tippyUsage,
    };

    const content = useMemo(
      () => <div className={`mms--tippy-${tippyUsage}`}>{title || html}</div>,
      [tippyUsage, title, html]
    );

    return (
      <Tippy {...safe} content={content} ref={ref}>
        {children && <TippyChild {...tippyChildProps}>{children}</TippyChild>}
      </Tippy>
    );
  }
);

Tooltip.propTypes = {
  // determine appearance of tippy
  tippyTheme: PropTypes.oneOf(['dark', 'light', 'blue']),
  tippyUsage: PropTypes.oneOf(['tooltip', 'dropdown', 'popper']),
  // determine tippy child -- something renderable
  // function prop child
  childAs: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
  childProps: PropTypes.object,

  // styled components
  style: PropTypes.object,
  className: PropTypes.string,
  maxWidth: PropTypes.string,

  // popper content
  title: PropTypes.string,
  html: PropTypes.any,

  // https://atomiks.github.io/tippyjs/v6/all-props/#popperoptions
  popperOptions: PropTypes.shape({
    strategy: PropTypes.string,
    placement: PropTypes.string,
    modifiers: PropTypes.arrayOf(
      PropTypes.shape({ name: PropTypes.string, options: PropTypes.object })
    ),
  }),
  // https://github.com/atomiks/tippyjs-react#reference-reactrefobject--element
  reference: PropTypes.object,

  visible: PropTypes.bool,
  // allow interaction
  interactive: PropTypes.bool,
  // Determines the size of the invisible border around the tippy that will prevent it from hiding if the cursor left it.
  interactiveBorder: PropTypes.number,
  // Delay in ms once a trigger event is fired before a tippy shows or hides.
  delay: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  // Delay in ms once a trigger event is fired before a tippy shows or hides.
  duration: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  // show arrow
  arrow: PropTypes.bool,
  // prevent opening
  disabled: PropTypes.bool,
  // how to open
  trigger: PropTypes.oneOf([
    'mouseenter focus',
    'mouseenter click',
    'click',
    'focusin',
    'manual',
  ]),
  placement: PropTypes.oneOf([
    'top',
    'top-start',
    'top-end',
    'right',
    'right-start',
    'right-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end',
    'auto',
    'auto-start',
    'auto-end',
  ]),
  hideOnClick: PropTypes.oneOf([true, false, 'toggle']),
  showOnCreate: PropTypes.bool,
  // https://popper.js.org/docs/v2/modifiers/offset/#options
  offset: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.array,
    PropTypes.string,
    PropTypes.number,
  ]),
  plugins: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      defaultValue: PropTypes.bool,
      fn: PropTypes.func,
    })
  ),
};

export default memo(Tooltip);
