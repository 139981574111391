import React, { useRef, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import isDeveloperMode from '../../../utils/isDeveloperMode';

import Button from '../../../components/Button';
import IconSvg from '../../../components/IconSvg';
import Tab, { Tabs } from '../../../components/Tab';
import SlideTransition from '../../../components/SlideTransition/SlideTransition';
import ToolkitTabFeed from './ToolkitTabFeed';
import ToolkitTabResearch from './ToolkitTabResearch';
import ToolkitTabCompete from './ToolkitTabCompete';
import ToolkitTabTopics from './ToolkitTabTopics';
import ToolkitTabSubheadings from './ToolkitTabSubheadings';
import ToolkitTabQuestions from './ToolkitTabQuestions';
import ToolkitTabEnrichments from './ToolkitTabEnrichments';
import ToolkitLoading from './ToolkitLoading';

const padding = 14;

const Wrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 420px;
  height: auto;
  padding: ${padding}px;
  height: 100%;
  overflow: hidden;
  z-index: 500;
`;

const Contents = styled.div`
  box-shadow: ${p => p.theme.moonShadow};
  border-radius: 4px;
  padding-bottom: 0;
  height: 100%;
  overflow: hidden;
`;

const SlideContents = styled.div`
  width: 100%;
  flex-shrink: 0;
  overflow: hidden;
  padding: ${padding}px;
`;

// TODO: MMS-290
const HeadingWrapper = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2000;
  width: 100%;
  padding: ${padding}px;
  padding-bottom: 0;
`;

const TabsStyled = styled(Tabs)`
  height: 32px;
  border: 1px solid ${p => p.theme.colors.grey25};
  border-radius: 4px;
`;

const TabStyled = styled(Tab)`
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  flex-shrink: 0;
  border-right: 1px solid ${p => p.theme.colors.grey25};
  &:last-of-type {
    border: none;
  }
  align-items: center;
  justify-content: center;
  margin: 0;
  border-bottom: none !important;
  ${p => p.active && `color: ${p.theme.colors.black};`}
  ${p => p.theme.ripple(p.theme.colors.grey10)}
`;

const TabContents = styled.div``;

const BackButton = styled(Button)`
  color: ${p => p.theme.colors.grey50};
  font-weight: bold;
  padding: 0;
`;

const Toolkit = ({
  functions,
  loading,
  toolkitType,
  appData,
  mentions,
  notifications,
  initialTab,
  style,
  className,
}) => {
  const decors = {
    decorRelatedTopics: mentions.termsTopicModel,
    decorVariants: mentions.termsVariants,
  };
  const scrollRef = useRef(null);
  const isDeveloper = isDeveloperMode();
  const [tab, setTab] = useState(initialTab || 'research');

  // selected variant from research app
  const [variant, setVariant] = useState(null);
  // selected competitor from compete app
  const [comparingTo, setComparingTo] = useState(null);

  // scroll to the top when tab changes
  useEffect(() => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  }, [tab]);

  const tabs = useMemo(
    () => (
      <TabsStyled>
        {toolkitType === 'optimize' && (
          <>
            <TabStyled
              data-mms--optimize-sidebar-tab="feed"
              active={tab === 'feed'}
              onClick={() => setTab('feed')}
            >
              Feed
            </TabStyled>
            <TabStyled
              data-mms--optimize-sidebar-tab="research"
              active={tab === 'research'}
              onClick={() => setTab('research')}
            >
              Research
            </TabStyled>
            <TabStyled
              data-mms--optimize-sidebar-tab="compete"
              active={tab === 'compete'}
              onClick={() => setTab('compete')}
            >
              Compete
            </TabStyled>
          </>
        )}

        {toolkitType === 'optimizeBrief' && (
          <TabStyled
            data-mms--brief-optimize-sidebar-tab="research"
            active={tab === 'research'}
            onClick={() => setTab('research')}
          >
            Research
          </TabStyled>
        )}

        {toolkitType === 'briefSubheadings' && (
          <>
            <TabStyled
              active={tab === 'subheadings'}
              onClick={() => setTab('subheadings')}
              data-mms--brief-subheadings-sidebar-tab="subheadings"
            >
              Subheadings
            </TabStyled>
            <TabStyled
              data-mms--brief-subheadings-sidebar-tab="questions"
              active={tab === 'questions'}
              onClick={() => setTab('questions')}
            >
              Questions
            </TabStyled>
            <TabStyled
              data-mms--brief-subheadings-sidebar-tab="topics"
              active={tab === 'topics'}
              onClick={() => setTab('topics')}
            >
              Topics
            </TabStyled>
            {isDeveloper && (
              <TabStyled
                data-mms--brief-subheadings-sidebar-tab="enrichments"
                active={tab === 'enrichments'}
                onClick={() => setTab('enrichments')}
              >
                <IconSvg name="settings" size={16} />
              </TabStyled>
            )}
          </>
        )}

        {toolkitType === 'writing' && (
          <>
            <TabStyled
              data-mms--first-draft-sidebar-tab="research"
              data-mms--writing-sidebar-tab="research"
              active={tab === 'research'}
              onClick={() => setTab('research')}
            >
              Research
            </TabStyled>
            <TabStyled
              data-mms--first-draft-sidebar-tab="compete"
              data-mms--writing-sidebar-tab="compete"
              active={tab === 'compete'}
              onClick={() => setTab('compete')}
            >
              Compete
            </TabStyled>
          </>
        )}
      </TabsStyled>
    ),
    [tab, setTab, isDeveloper, appData],
  );

  const mainView = ({ moveForwardTo }) => (
    <>
      {/* heading */}
      {toolkitType !== 'optimizeBrief' && (
        <HeadingWrapper>{tabs}</HeadingWrapper>
      )}

      {/* slide contents */}
      <SlideContents ref={scrollRef}>
        {/* tab contents */}
        <TabContents
          style={toolkitType === 'optimizeBrief' ? { paddingTop: 0 } : {}}
        >
          {toolkitType === 'optimize' && (
            <>
              {loading && <ToolkitLoading />}
              {!loading && tab === 'feed' && (
                <ToolkitTabFeed
                  appData={appData}
                  decors={decors}
                  notifications={notifications}
                />
              )}

              {!loading && tab === 'research' && (
                <ToolkitTabResearch
                  appData={appData}
                  decors={decors}
                  moveForwardTo={moveForwardTo}
                  setVariant={setVariant}
                />
              )}

              {!loading && tab === 'compete' && (
                <ToolkitTabCompete
                  appData={appData}
                  decors={decors}
                  setComparingTo={setComparingTo}
                />
              )}
            </>
          )}

          {toolkitType === 'optimizeBrief' && (
            <>
              {loading && <ToolkitLoading />}
              {!loading && (
                <ToolkitTabResearch
                  preventVariantData
                  appData={appData}
                  decors={decors}
                />
              )}
            </>
          )}

          {toolkitType === 'briefSubheadings' && (
            <>
              {tab === 'subheadings' && (
                <ToolkitTabSubheadings
                  appData={appData}
                  loading={loading.qts}
                  functions={functions}
                />
              )}

              {tab === 'questions' && (
                <ToolkitTabQuestions
                  appData={appData}
                  loading={loading.qts}
                  functions={functions}
                />
              )}

              {tab === 'topics' && (
                <ToolkitTabTopics
                  appData={appData}
                  loading={loading.qts}
                  functions={functions}
                />
              )}

              {tab === 'enrichments' && (
                <ToolkitTabEnrichments
                  appData={appData}
                  loading={loading}
                  functions={functions}
                />
              )}
            </>
          )}

          {toolkitType === 'writing' && (
            <>
              {loading && <ToolkitLoading />}
              {!loading && tab === 'research' && (
                <ToolkitTabResearch
                  appData={appData}
                  decors={decors}
                  moveForwardTo={moveForwardTo}
                  setVariant={setVariant}
                />
              )}

              {!loading && tab === 'compete' && (
                <ToolkitTabCompete
                  appData={appData}
                  decors={decors}
                  moveForwardTo={moveForwardTo}
                  setComparingTo={setComparingTo}
                />
              )}
            </>
          )}
        </TabContents>
      </SlideContents>
    </>
  );

  const slideInView = ({ duration, moveBackTo, moveForwardTo }) => (
    <>
      {/* heading */}
      <HeadingWrapper>
        <BackButton
          text
          onClick={() => {
            moveBackTo('main');
            setTimeout(() => {
              setVariant(null);
              setComparingTo(null);
            }, duration);
          }}
        >
          {variant && '← Research'}
          {comparingTo && '← Compete'}
        </BackButton>
      </HeadingWrapper>

      {/* slide contents */}
      <SlideContents>
        {/* tab contents */}
        <TabContents>
          {/* research tab */}
          {variant && (
            <ToolkitTabResearch
              appData={appData}
              decors={decors}
              variant={variant}
              setVariant={setVariant}
              moveForwardTo={moveForwardTo}
              moveBackTo={moveBackTo}
            />
          )}

          {/* compete tab */}
          {comparingTo && (
            <ToolkitTabCompete
              appData={appData}
              decors={decors}
              comparingTo={comparingTo}
              setComparingTo={setComparingTo}
              moveForwardTo={moveForwardTo}
              moveBackTo={moveBackTo}
            />
          )}
        </TabContents>
      </SlideContents>
    </>
  );

  return (
    <Wrapper style={style} className={className}>
      <Contents>
        <SlideTransition
          views={[
            {
              id: 'main',
              initial: true,
              ref: scrollRef,
              render: mainView,
            },
            {
              id: 'contents',
              render: slideInView,
            },
          ]}
        />
      </Contents>
    </Wrapper>
  );
};

Toolkit.propTypes = {
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  toolkitType: PropTypes.oneOf([
    'writing',
    'briefSubheadings',
    'optimizeBrief',
    'optimize',
  ]),
  generation: PropTypes.object,
  appData: PropTypes.shape({
    // reserved for params sent to getAppData
    params: PropTypes.shape({
      topic: PropTypes.string,
      url: PropTypes.string,
      country: PropTypes.string,
    }),
    // service response formats
    kg: PropTypes.object,
    qts: PropTypes.object,
    scores: PropTypes.object,
    lr: PropTypes.object,
  }),
  decors: PropTypes.object,
  notifications: PropTypes.object,
  initialTab: PropTypes.string,
  functions: PropTypes.object,
  contentScore: PropTypes.number,
  wordCount: PropTypes.number,
  orderItem: PropTypes.func,
  orderingLoading: PropTypes.bool,
  ordered: PropTypes.bool,
};

export default Toolkit;
