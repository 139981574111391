import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isNil from 'lodash/isNil';

import SimpleListOverlay from '../../components/SimpleList/SimpleListOverlay';
import Clickable from '../Clickable';
import LoadingBlock from '../LoadingBlock';
import Tooltip from '../Tooltip/Tooltip/Tooltip';
import Item from './SimpleListItem';
import Separator from './SimpleListRowSeparator';
import { isClickable } from './utils';

const LinkWrapper = styled.a`
  text-decoration: none !important;
  color: inherit !important;
  width: 100%;
`;

const LoadingBlockStyled = styled(LoadingBlock)`
  height: 60%;
`;

const RowWrapper = styled(Clickable).attrs(props => ({
  className: 'mms--sl-row',
  role: props.clickable ? 'button' : null,
}))`
  ${p => p.theme.type.textRegular};
  line-height: 19px;
  position: relative;
  display: flex;
  flex-shrink: 0;
  color: var(--mms-simple-foreground, ${p => p.theme.generic.light.foreground});
  min-height: ${p => (p.condensed ? 32 : 42)}px;

  /* header row */
  ${p =>
    p.header &&
    `
    height: 32px;
    min-height: 32px;
    align-items: flex-end;
  `}

  ${p =>
    p.sticky &&
    `
    position: sticky;
    z-index: 999;
  `}

  /* sizes, paddings and margins */
  ${p => !isNil(p.width) && `width: ${p.theme.apply(p.width)};`}
  ${p => !isNil(p.height) && `height: ${p.theme.apply(p.height)};`}
  ${p => !isNil(p.height) && `min-height: ${p.theme.apply(p.height)};`}
  ${p => p.theme.mixins.applyPadding}
  ${p => p.theme.mixins.applyMargin}

  /* side items hover control */
  ${p =>
    p.sideItemsLeftOnHover &&
    `
    > .mms--sl-hover-trigger-left {
      visibility: hidden;
    }`}

  ${p =>
    p.sideItemsRightOnHover &&
    `
    > .mms--sl-hover-trigger-right {
      visibility: hidden;
    }`}

  color: var(--mms-simple-foreground, ${p => p.theme.generic.light.foreground});
  background-color: var(
    --mms-simple-background,
    ${p => p.theme.generic.light.background}
  );

  ${p =>
    p.active &&
    `
    background-color: var(--mms-simple-background-active, ${p.theme.generic.light.backgroundActive});
  `}

  ${p =>
    p.disabled &&
    `
    background-color: var(--mms-simple-background-disabled, ${p.theme.generic.light.backgroundDisabled});
  `}

  &:hover,
  &:focus {
    ${p =>
      !p.disabled &&
      !p.clickable &&
      `
      cursor: default !important;
      background-color: var(--mms-simple-background, ${p.theme.generic.light.background});
    `}

    ${p =>
      !p.disabled &&
      p.clickable &&
      !p.active &&
      `
      background-color: var(--mms-simple-background-hover, ${p.theme.generic.light.backgroundHover});
    `}

    ${p =>
      !p.disabled &&
      p.clickable &&
      p.active &&
      `
      background-color: var(--mms-simple-background-active, ${p.theme.generic.light.backgroundActive});
    `}

    /* side items hover control */
    ${p =>
      !p.disabled &&
      p.sideItemsLeftOnHover &&
      `
      > .mms--sl-hover-trigger-left {
        visibility: visible;
      }`}

    ${p =>
      !p.disabled &&
      p.sideItemsRightOnHover &&
      `
      > .mms--sl-hover-trigger-right {
        visibility: visible;
      }`}
  }

  > .mms--sl-hover-trigger {
    position: absolute;
    top: 0;
    width: 80px;
    height: 100%;
  }

  > .mms--sl-hover-trigger-left {
    left: -80px;
  }

  > .mms--sl-hover-trigger-right {
    right: -80px;
  }
`;

const SideItems = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  ${p =>
    p.left &&
    `
    justify-content: flex-end;
    margin-right: 10px;
  `}

  ${p =>
    p.right &&
    `
    justify-content: flex-start;
    margin-left: 10px;
  `}
`;

const SideItem = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${p =>
    p.left &&
    `
    margin-left: 6px;

    &:first-child {
      margin-left: 0;
    }
  `}

  ${p =>
    p.right &&
    `
    margin-right: 6px;

    &:last-child {
      margin-right: 0;
    }
  `}

  svg {
    width: 18px;
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

const SimpleListRow = ({ children, ...rest }) => {
  const clickable = isClickable(rest);
  const isActive = rest.active;

  let row = (
    <>
      <RowWrapper
        {...rest}
        onClick={clickable ? rest.onClick : null}
        className={cx(
          rest.className,
          { 'sl-clickable': clickable },
          { 'sl-header': rest.header }
        )}
        clickable={clickable}
        active={isActive}
        sideItemsLeftOnHover={rest.sideItemsLeftOnHover}
        sideItemsRightOnHover={rest.sideItemsRightOnHover}
      >
        {/* left items */}
        {rest.sideItemsLeft && rest.sideItemsLeft.length > 0 && (
          <div className="mms--sl-hover-trigger mms--sl-hover-trigger-left">
            <SideItems left className="mmx-sl-rowi-l">
              {rest.sideItemsLeft.map((item, i) => (
                <SideItem left key={`sl-rowi-l-${i}`}>
                  {item}
                </SideItem>
              ))}
            </SideItems>
          </div>
        )}

        {/* loading */}
        {rest.loading && !rest.header && (
          <Item grow>
            <LoadingBlockStyled />
          </Item>
        )}

        {/* loading */}
        {rest.loading && rest.header && children}

        {/* children */}
        {!rest.loading && children}

        {/* right items */}
        {rest.sideItemsRight && rest.sideItemsRight.length > 0 && (
          <div className="mms--sl-hover-trigger mms--sl-hover-trigger-right">
            <SideItems right className="mmx-sl-rowi-r">
              {rest.sideItemsRight.map((item, i) => (
                <SideItem right key={`sl-rowi-r-${i}`}>
                  {item}
                </SideItem>
              ))}
            </SideItems>
          </div>
        )}

        {/* overlay */}
        {rest.overlayItems && (
          <SimpleListOverlay.Overlay
            p={rest.p}
            pl={rest.pl}
            overlayItemsActive={rest.overlayItemsActive}
            overlayItemsAbsolute={rest.overlayItemsAbsolute}
            overlayItemsHideGradient={rest.overlayItemsHideGradient}
            overlayItemsBackgroundTransparent={rest.overlayItemsBackgroundTransparent}
            hoverAccent={rest.hoverAccent}
            clickable={clickable}
            {...(rest.overlayItemProps || {})}
          >
            {rest.overlayItems}
          </SimpleListOverlay.Overlay>
        )}
      </RowWrapper>

      {rest.separator && <Separator />}
    </>
  );

  // wrap row in an a tag
  if (rest.href) {
    row = (
      <LinkWrapper href={rest.href} target="_blank">
        {row}
      </LinkWrapper>
    );
  }

  // has tooltip
  if (!isNil(rest.tooltipProps)) {
    row = <Tooltip {...rest.tooltipProps}>{row}</Tooltip>;
  }

  return row;
};

SimpleListRow.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.any,
  disabled: PropTypes.bool, // row renders as disabled
  clickDisabled: PropTypes.bool, // disable onClick
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  sideItemsLeft: PropTypes.array, // abs positioned items rendered on the left site
  sideItemsLeftOnHover: PropTypes.bool, // only show left items on hover
  sideItemsRight: PropTypes.array, // abs positioned items rendered on the right site
  sideItemsRightOnHover: PropTypes.bool, // only show left items on hover
  separator: PropTypes.bool, // renders a separator component below the row
  header: PropTypes.bool, // indicates that the row is a header
  href: PropTypes.string, // wraps up the row with an a tag
  condensed: PropTypes.bool, // smaller table, lower height
  border: PropTypes.bool, // render visible borders
  light: PropTypes.bool, // no background or border
  active: PropTypes.bool, // row is active (highlighted)
  tooltipProps: PropTypes.object, // have a tooltip over the row
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  overlayItems: PropTypes.any, // set of items to show on the right on hover
  overlayItemProps: PropTypes.object, // props that will be passed over to the overlay item container
  overlayItemsActive: PropTypes.bool, // force items to keep open disregarding hover
  overlayItemsAbsolute: PropTypes.bool, // overlay items renders over the row
  overlayItemsHideGradient: PropTypes.bool, // hide overlay transition gradient
  overlayItemsBackgroundTransparent: PropTypes.bool, // make overlay bacground transparent
  p: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  m: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ml: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

SimpleListRow.defaultProps = {
  condensed: false,
  active: false,
  header: false,
};

export default SimpleListRow;
