import get from 'lodash/get';

import { RecurlyGetCouponDocument } from '@marketmuse/data-papi';

import * as types from '../../config/types';
import makeRequest from '../../utils/makeRequest';

export default () => () => next => action => {
  if (action.type === types.RECURLY_VALIDATE_COUPON) {
    makeRequest({
      type: types.RECURLY_VALIDATE_COUPON,
      mutation: RecurlyGetCouponDocument,
      variables: {
        couponId: `code-${action.couponCode}`,
      },
      options: {
        isQuery: true,
      },
      apolloOptions: {
        fetchPolicy: 'no-cache',
      },
    }).then(res => {
      if (get(res, 'error')) {
        if (typeof action.callbackFail === 'function') {
          action.callbackFail(res.error);
        }
      } else {
        const couponInfo = get(res, 'recurlyGetCoupon');
        if (typeof action.callback === 'function') {
          action.callback(couponInfo);
        }
      }
    });
  }

  return next(action);
};
