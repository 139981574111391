import { ReduxListener } from '../../../types';

import initialize from './initialize';
import inventoryBySeoTerms from './inventoryBySeoTerms';
import query from './query';
import statusCheck from './statusCheck';

const setupListeners = (startListening: ReduxListener) =>
  [initialize, inventoryBySeoTerms, query, statusCheck].forEach(listener =>
    listener(startListening),
  );

export default setupListeners;
