import get from 'lodash/get';
import isNil from 'lodash/isNil';

export default misc => {
  // check if there is a limit
  const limit = get(misc, 'counts.appRuns.limit');
  if (isNil(limit)) return true;

  const current = get(misc, 'counts.appRuns.current');
  const extra = get(misc, 'counts.appRuns.extra');
  const hasStandard = current < limit;
  const hasExtra = extra > 0;

  return hasStandard || hasExtra;
};
