import React, { memo } from 'react';
import styled from 'styled-components';

import Portal from '../Portal';
import { LoaderSparkles } from '../Loader';

const Wrapper = styled.div`
  position: relative;
  background: ${p => p.theme.colors.white};
  width: ${p => (p.unwrapped ? '100%' : '100vw')};
  height: ${p => (p.unwrapped ? '100%' : '100vh')};
  display: grid;
  place-items: center;
`;

const LoaderStyled = styled(LoaderSparkles)`
  width: 60px;
  height: 60px;
`;

const ScreenLoaderLight = ({
  children,
  colorLoader = 'blue',
  style,
  unwrapped,
}: {
  children?: React.ReactNode;
  colorLoader?: 'blue' | 'white';
  style?: React.CSSProperties;
  unwrapped?: boolean;
}) => {
  const content = (
    <Wrapper unwrapped={unwrapped} style={style}>
      {children || <LoaderStyled color={colorLoader} />}
    </Wrapper>
  );

  const wrapped = <Portal>{content}</Portal>;

  if (unwrapped) {
    return content;
  }

  return wrapped;
};

export default memo(ScreenLoaderLight);
