import React, { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Dialog from '../../components/Dialog';
import Portal from '../../components/Portal';
import Toast from '../../components/Toast';

import { removeProjectOrder } from '../../actions/projectActions';
import { toggleDynamic } from '../../actions/miscActions';
import { useSuiteNavigate } from '../../hooks/useSuiteNavigate';

const BriefCancelModal = () => {
  const navigate = useSuiteNavigate();
  const dispatch = useDispatch();
  const siteId = useSelector(state => state?.filter?.site?.id);
  const guestAccessRestricted = useSelector(state => state.guestAccess?.token);
  const { navigationUrl, onError, projectId } =
    useSelector(state => state.misc?.['briefCancellation-data']) || {};

  const [isDialogLoading, setDialogLoading] = useState(false);

  const close = () =>
    dispatch(
      toggleDynamic({
        id: 'briefCancellation',
        status: false,
      }),
    );

  const onCancelBrief = useCallback(() => {
    setDialogLoading(true);
    dispatch(
      removeProjectOrder({
        projectId,
        siteId,
        callback: isRemoved => {
          if (isRemoved) {
            setDialogLoading(false);
            Toast.fire('Brief removed');

            if (navigationUrl) {
              navigate(navigationUrl);
            }
            close();
          } else {
            if (onError && typeof onError === 'function') {
              onError();
            }
            close();
          }
        },
      }),
    );
  }, [
    guestAccessRestricted,
    navigationUrl,
    siteId,
    onError,
    dispatch,
    projectId,
  ]);

  if (!projectId) {
    Toast.fire("Can't cancel brief");
    close();
  }
  if (guestAccessRestricted) {
    Toast.fire('Please sign in to continue');
    close();
  }

  return (
    <Portal>
      <Dialog
        title="Confirm Cancellation?"
        message={
          <>
            Cancelling this Content Brief order will refund you the Credit spent
            on it and remove this item from your Briefs list. Are you sure you
            would like to cancel this Content Brief order?
          </>
        }
        close={isDialogLoading ? void 0 : () => close()}
        ctas={[
          {
            title: 'Not yet',
            onClick: () => close(),
            variant: 'blackOnTransparent',
          },
          {
            title: 'Yes, confirm',
            onClick: () => onCancelBrief(),
            variant: 'redLight2',
            loading: isDialogLoading,
          },
        ]}
      />
    </Portal>
  );
};

export default memo(BriefCancelModal);
