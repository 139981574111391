export const SETTINGS = {
  NAME: 'HighlightTerm',
  CONFIG_NAME: 'highlightTerms',
} as const;

export const COMMANDS = {
  highlightTerms: 'highlightTerms',
  clearHighlightedTerms: 'clearHighlightedTerms',
  setHighlightTerms: 'setHighlightTerms',
} as const;

export const EVENTS = {
  onHighlight: 'onHighlight',
} as const;

export const MISC = {
  ATTRIBUTE_KEY: 'highlightTerm',
};
