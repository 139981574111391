import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Modal, { ModalHeader, ModalBody, ModalFooter } from '../Modal';
import Button from '../Button';

const ButtonStyled = styled(Button)`
  padding: 8px 16px;

  ${p =>
    p?.variant === 'white' &&
    `
    padding-right: 8px;
    padding-left: 8px;
  `}
`;

const Dialog = ({ close, title, icon, message, ctas, className }) => (
  <Modal
    hideClose={!!title}
    close={close}
    className={className}
    style={{ width: 620 }}
    closeStyle={{
      top: 10,
      right: 10,
      left: 'auto',
      background: 'transparent',
    }}
  >
    {/* title */}
    {!!title && <ModalHeader title={title} icon={icon} close={close} />}

    {/* body */}
    {!!message && <ModalBody>{message}</ModalBody>}

    {ctas.length && ctas.length > 0 && (
      <ModalFooter>
        {ctas.map((cta, i) => {
          const { title, trackingValue, id, onClick, ...ctaRest } = cta;
          const key = id || i;
          return (
            <ButtonStyled
              key={key}
              large
              onClick={onClick}
              {...ctaRest}
              data-mms--modal-cta={trackingValue || title}
            >
              {title}
            </ButtonStyled>
          );
        })}
      </ModalFooter>
    )}
  </Modal>
);

Dialog.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.any,
  message: PropTypes.any,
  ctas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      trackingValue: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  className: PropTypes.string,
  close: PropTypes.func,
};

Dialog.defaultProps = {
  ctas: [],
};

export default Dialog;
