import React from 'react';
import styled, { css } from 'styled-components';
import { get, isNil } from 'lodash';
import IconPlus from 'react-feather/dist/icons/plus';

import Clickable from '../../../../components/Clickable';
import Icon from '../../../../components/Icon';
import Tooltip from '../../../../components/Tooltip/Tooltip/Tooltip';

import { sideItemProps, BadgeButton } from './shared';

const Wrapper = styled(Clickable)`
  position: relative;
  width: ${p => p.theme.sidebarWidthSmall - 2 * p.theme.sidebarPadding}px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  margin: auto;
  font-size: 16pt;
  ${p =>
    p.subheader &&
    `
    color: ${p.theme.colors.grey60};
  `}
  ${p =>
    p.header &&
    `
    color: ${p.theme.colors.grey25};
  `}

  ${p => p.locked && `opacity: 0.3;`}

  &:hover {
    ${p =>
      !p.locked &&
      `
      background-color: ${p.theme.colors.black};
      border-radius: ${p.theme.borderRadius};
    `}
  }
  ${p =>
    (p.header || p.subheader) &&
    !p.onClick &&
    `
    cursor: default;
  `}
  ${p => p.color && `color: ${p.theme.colors[p.color] || p.color};`}
  ${p =>
    !p.locked &&
    p.active &&
    `
    color: ${p.theme.sidebarColorSecondary};
    background-color: ${p.theme.colors.black};
    border-radius: ${p.theme.borderRadius};
  `}
  ${p =>
    p.shouldFitContent &&
    `
    min-height: 40px;
    height: fit-content;
  `}

  ${p =>
    p.adminMode &&
    `
    background-color: ${p.theme.sidebarColorAdmin};
    color: ${p.theme.colors.white};
    font-weight: bold;
  `}
  ${p =>
    p.order &&
    `
    order: ${p.order};
  `}
`;

const LabelWrapper = styled.div`
  position: absolute;
  bottom: 2px;
  right: 2px;
  ${p =>
    p.labelLoading &&
    css`
      animation: ${p.theme.keyframes.flashOp} 1.2s linear infinite;
    `}
  border-radius: 3px;
  padding: 4px;
  margin-left: 12px;
  font-style: normal;
  font-weight: ${p => (p.active ? 'bold' : 'normal')};
  height: 16px;
  font-size: 10px;
  line-height: 16px;
  align-items: center;
  display: flex;
  text-align: right;
  background-color: ${p => {
    const background =
      p.background && (p.theme.colors[p.background] || p.background);
    return p.active
      ? background || p.theme.colors.black
      : p.theme.colors.grey70;
  }};
  color: ${p => (p.active ? p.theme.colors.white : p.theme.colors.grey35)};
  box-shadow: ${p => p.theme.labelBlackShadow};
`;

const BadgeWrapper = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  display: flex;
  align-items: center;
  font-size: 9pt;
`;

const SidebarItemCollapsed = props => {
  const isDisabled =
    (props.header || props.subheader) && (!props.onClick || props.locked);
  const tooltipShortCard = props.adminMode
    ? 'AM'
    : props.title && props.title[0].toUpperCase();
  const tooltip = props.icon ? props.icon : tooltipShortCard;
  return (
    <Wrapper
      order={props.order}
      onClick={props.onClick}
      active={props.active && !props.isFilter}
      locked={props.locked}
      header={props.header}
      subheader={props.subheader}
      disabled={isDisabled}
      color={props.color}
      adminMode={props.adminMode}
    >
      <Tooltip title={props.title} disabled={!props.title} position="right">
        {tooltip}
      </Tooltip>

      {!isNil(props.label) && !props.locked && (
        <LabelWrapper
          active={props.active}
          background={props.activeLabelBackground}
        >
          {get(props, 'commonProps.labelLoading') && '-'}
          {!get(props, 'commonProps.labelLoading') && props.label}
        </LabelWrapper>
      )}

      {!isNil(props.badge) && !props.locked && (
        <BadgeWrapper>{props.badge}</BadgeWrapper>
      )}

      {!isNil(props.badgeAdd) && !props.locked && (
        <BadgeWrapper>
          <BadgeButton
            style={{ minHeight: 0 }}
            tooltip={props.badgeAdd[0]}
            tooltipPosition="right"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              props.badgeAdd[1]();
            }}
          >
            <IconPlus />
          </BadgeButton>
        </BadgeWrapper>
      )}
      {props.locked && (
        <BadgeWrapper>
          <Icon name="locked" size="16" style={{ fontSize: '13pt' }} />
        </BadgeWrapper>
      )}
    </Wrapper>
  );
};

SidebarItemCollapsed.propTypes = {
  ...sideItemProps,
};

export default SidebarItemCollapsed;
