import { isUndefined, isEmpty, isNull } from 'lodash';
import { PAPI } from '@marketmuse/config/types';
import { validatePermission } from '@marketmuse/utilities';
import {
  ReduxListener,
  AppQueryNetworkStatus,
  AppQueryNetworkKeys,
  RootState,
} from '../../../types';
import {
  researchTaskStatusCheck,
  researchTaskWebsiteHeatmapData,
  researchTaskWebsiteHeatmapFilters,
} from '../../actions';
import { dataSlice, networkSlice } from '../../slices';

import { getWebsiteHeatmap } from '../../thunks';

const listeners = (startListening: ReduxListener) => {
  const dataActions = dataSlice.actions;
  const networkActions = networkSlice.actions;

  startListening({
    actionCreator: getWebsiteHeatmap.pending,
    effect: async (action, { dispatch }) => {
      await dispatch(
        networkActions.websiteHeatmapSet(AppQueryNetworkStatus.loading),
      );
    },
  });

  startListening({
    actionCreator: getWebsiteHeatmap.rejected,
    effect: async (action, { dispatch }) => {
      const { aborted } = action.meta;
      if (!aborted) {
        await dispatch(
          networkActions.websiteHeatmapSet(AppQueryNetworkStatus.error),
        );

        // check status after other steps
        await dispatch(researchTaskStatusCheck());
      }
    },
  });

  startListening({
    actionCreator: getWebsiteHeatmap.fulfilled,
    effect: async ({ payload }, { dispatch }) => {
      await Promise.all([
        dispatch(dataActions.websiteHeatmapSet(payload)),
        dispatch(
          networkActions.websiteHeatmapSet(AppQueryNetworkStatus.complete),
        ),
      ]);

      // check status after other steps
      await dispatch(researchTaskStatusCheck());
    },
  });

  startListening({
    actionCreator: researchTaskWebsiteHeatmapFilters,
    effect: async (action, { dispatch, getState }) => {
      const state: RootState = getState();

      if (
        state?.appResearch?.network?.[AppQueryNetworkKeys.knowledgeGraph] !==
        AppQueryNetworkStatus.complete
      ) {
        return;
      }

      const knowledgeGraphItems =
        state.appResearch.data?.knowledgeGraph?.knowledgeGraphItems;

      const payload = {
        ...action.payload,
        knowledgeGraphItems,
      };

      await dispatch(researchTaskWebsiteHeatmapData(payload));
    },
  });

  startListening({
    actionCreator: researchTaskWebsiteHeatmapData,
    effect: async (action, { dispatch, getState }) => {
      const state: RootState = getState();
      const permissions = state.permissions;

      const terms = action.payload.knowledgeGraphItems;
      const includePath = action.payload.include;
      const excludePaths = action.payload.exclude || [];

      const hasAccess = validatePermission({
        permissions,
        permission: {
          resource: PAPI.PermissionResource.RESEARCH_HEATMAP_WEBSITE,
          action: PAPI.PermissionAction.ACCESS_DATA,
        },
      });

      if (!hasAccess) {
        return;
      }

      if (isEmpty(includePath)) {
        return;
      }

      if (
        state?.appResearch?.network?.[AppQueryNetworkKeys.knowledgeGraph] !==
        AppQueryNetworkStatus.complete
      ) {
        return;
      }

      const term = state.appResearch.ui.params.term;
      const kgTerms = terms
        .map(topic => topic.term)
        .filter(term => !isUndefined(term) && !isEmpty(term) && !isNull(term));

      const payload = {
        term,
        includePath,
        excludePaths,
        kgTerms,
      };

      await dispatch(getWebsiteHeatmap(payload));
    },
  });
};

export default listeners;
