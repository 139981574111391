import { isNil } from 'lodash';
import { RankCalculateFn } from '../types';

const calculate: RankCalculateFn = value => {
  const rankValue = typeof value === 'string' ? parseInt(value) : value;

  if (isNaN(rankValue) || isNil(rankValue) || rankValue < 1) {
    return 'gray';
  }
  if (rankValue === 1) {
    return 'blue';
  }
  if (rankValue <= 4) {
    return 'green';
  }
  if (rankValue <= 10) {
    return 'yellow';
  }
  if (rankValue <= 20) {
    return 'orange';
  }
  return 'red';
};

export default calculate;
