import * as React from 'react';

import { IResearchDataContext } from '../types';
import { ResearchDataContext } from '../context';

const useResearchData = (): IResearchDataContext => {
  const context = React.useContext(ResearchDataContext);
  if (!context) {
    throw new Error(
      'This component must be used within the <ResearchProvider />.',
    );
  }
  return context;
};

export default useResearchData;
