import { memo, forwardRef } from 'react';
import cx from 'classnames';
import { SortDirection } from '@tanstack/react-table';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { Box } from '../../Box';

export type IPropsHeaderSort = {
  dataId?: string;
  onClick?: (event: MouseEvent) => void;
  isSorted: SortDirection | boolean;
};

const styles = [
  'cursor-pointer ',
  'select-none',
  'flex-shrink-0',
  'flex',
  'flex-col',
  'justify-center',
  'w-4',
  'h-full',
  'relative',
];

const TableColumnSort = memo(
  forwardRef<HTMLDivElement, IPropsHeaderSort>(
    ({ onClick, isSorted, dataId }, ref) => (
      <Box
        onClick={event => (onClick ? onClick(event) : void 0)}
        ref={ref}
        as={'button'}
        className={styles}
        {...(dataId ? { 'data-mms--table-header-sort': dataId } : {})}
        aria-label="Change sort"
      >
        <Box
          as="span"
          className={[
            'block',
            isSorted !== 'asc' && 'opacity-40',
            isSorted === 'asc' && 'text-blue-400',
          ]}
        >
          <ChevronUpIcon className={cx(['w-4', 'h-4'])} />
        </Box>
        <Box
          as="span"
          className={[
            'block',
            '-mt-2',
            isSorted !== 'desc' && 'opacity-40',
            isSorted === 'desc' && 'text-blue-400',
          ]}
        >
          <ChevronDownIcon className={cx(['w-4', 'h-4'])} />
        </Box>
      </Box>
    ),
  ),
);

export default TableColumnSort;
