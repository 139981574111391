import { ColumnFiltersState, SortingState } from '@tanstack/react-table';
import { createSlice, combineReducers, PayloadAction } from '@reduxjs/toolkit';
import {
  researchTaskSiteChange,
  researchTaskWebsiteHeatmapFilters,
} from '../actions';
import {
  ViewTableSliceState,
  ViewHeatmapFiltersState,
  ViewHeatmapSliceState,
  ResearchViews,
  ViewSerpXrayFiltersState,
} from '../../types';
import { initialState } from './views.initialState';

const createTableSlice = (name: string, initialState: ViewTableSliceState) => {
  return createSlice({
    name: `research/${name}`,
    initialState: initialState,
    reducers: {
      filtersReset: state => {
        state.filters = initialState.filters;
      },
      filtersSet: (state, action: PayloadAction<ColumnFiltersState>) => {
        state.filters = action.payload;
      },
      sortSet: (state, action: PayloadAction<SortingState>) => {
        state.sort = action.payload;
      },
    },
  });
};

export const topicModel = createTableSlice(
  ResearchViews.TOPIC_MODEL,
  initialState.topicModel,
);
export const keywords = createTableSlice(
  ResearchViews.KEYWORDS,
  initialState.keywords,
);
export const questions = createTableSlice(
  ResearchViews.QUESTIONS,
  initialState.questions,
);
export const reflect = createTableSlice(
  ResearchViews.REFLECT,
  initialState.reflect,
);

export const serpXray = createSlice({
  name: `research/${ResearchViews.XRAY}`,
  initialState: initialState.xray,
  reducers: {
    filtersReset: state => {
      state.filters = initialState.xray.filters;
    },
    filtersSet: (state, action: PayloadAction<ViewSerpXrayFiltersState>) => {
      state.filters = action.payload;
    },
  },
});

export const heatmap = createSlice({
  name: `research/${ResearchViews.HEATMAP}`,
  initialState: initialState.heatmap,
  reducers: {
    filtersReset: state => {
      state.filters = initialState.heatmap.filters;
    },
    filtersSet: (state, action: PayloadAction<ViewHeatmapFiltersState>) => {
      state.filters = action.payload;
    },
    sortSet: (state, action: PayloadAction<SortingState>) => {
      state.sort = action.payload;
    },
    modeSet: (state, action: PayloadAction<ViewHeatmapSliceState['mode']>) => {
      state.mode = action.payload;
    },
    modeAndFiltersSet: (
      state,
      action: PayloadAction<{
        mode: ViewHeatmapSliceState['mode'];
        filters: ViewHeatmapFiltersState;
      }>,
    ) => {
      state.mode = action.payload.mode;
      state.filters = action.payload.filters;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(
        researchTaskWebsiteHeatmapFilters,
        (state, action: PayloadAction<ViewHeatmapFiltersState>) => {
          state.filters = action.payload;
        },
      )
      .addCase(researchTaskSiteChange, () => initialState.heatmap);
  },
});

export const viewsActions = {
  heatmap: heatmap.actions,
  keywords: keywords.actions,
  questions: questions.actions,
  reflect: reflect.actions,
  topicModel: topicModel.actions,
  xray: serpXray.actions,
};

export const viewsSlice = combineReducers({
  heatmap: heatmap.reducer,
  keywords: keywords.reducer,
  questions: questions.reducer,
  reflect: reflect.reducer,
  topicModel: topicModel.reducer,
  xray: serpXray.reducer,
});
