import { Country } from './papi';
import { ObjectValues } from './utilities';

export enum CODES_ALL {
  AR = 'ar',
  AU = 'au',
  BE = 'be',
  BG = 'bg',
  BO = 'bo',
  BR = 'br',
  CA = 'ca',
  CH = 'ch',
  CL = 'cl',
  CN = 'cn',
  CO = 'co',
  CR = 'cr',
  DE = 'de',
  DK = 'dk',
  EC = 'ec',
  ES = 'es',
  FI = 'fi',
  FR = 'fr',
  GB = 'gb',
  GR = 'gr',
  GT = 'gt',
  HK = 'hk',
  HU = 'hu',
  IE = 'ie',
  IL = 'il',
  IN = 'in',
  IT = 'it',
  JP = 'jp',
  KR = 'kr',
  MX = 'mx',
  MY = 'my',
  NI = 'ni',
  NL = 'nl',
  NO = 'no',
  NZ = 'nz',
  PE = 'pe',
  PH = 'ph',
  PL = 'pl',
  PY = 'py',
  RO = 'ro',
  RU = 'ru',
  SE = 'se',
  SG = 'sg',
  TR = 'tr',
  US = 'us',
  VE = 've',
  ZA = 'za',
}

export enum CODES_INVENTORY {
  US = 'us',
  GB = 'gb',
  CA = 'ca',
  AU = 'au',
  NZ = 'nz',
}

export type CodesPapi = keyof typeof Country;
export type CodesAll = ObjectValues<typeof CODES_ALL>;
export type CodesInventory = ObjectValues<typeof CODES_INVENTORY>;

export interface CountryInfo {
  id: CodesAll;
  title: string;
  name: string;
  flag: string;
  adjective?: string;
  fallback: CodesInventory;
}
