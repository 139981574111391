import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import get from 'lodash/get';

import { regexCleanup } from '@marketmuse/utilities';
import optimizeCompeteHeadToHeadExpanded from '../../../models/tracker/events/optimize/OptimizeCompeteHeadToHeadExpanded';
import Rank from '../../../components/Rank';
import Meta from '../../../components/Meta';
import ContentScore from '../../../components/ContentScore';
import { SLRow, SLItem } from '../Optimize/shared/List';
import Distribution from '../../../components/Distribution';

const Wrapper = styled.div``;

const Count = styled.span`
  font-weight: 700;
  font-size: 12px;
  color: ${p => p.theme.mmxBlack};
`;

const H2hTitle = styled.div`
  text-transform: uppercase;
  letter-spacing: 1px;
  color: ${p => p.theme.colors.grey50};
  font-size: 11px;
  font-weight: bold;
`;

const H2hContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 12px;
`;

const ToolkitTabCompete = ({
  appData,
  decors,
  comparingTo,
  setComparingTo,
  moveForwardTo,
}) => {
  const focusTopic = useMemo(() => get(appData, 'params.topic'), [appData]);
  const matches = useMemo(
    () => get(decors, 'decorRelatedTopics') || {},
    [decors],
  );
  const competitorMatches = useMemo(
    () => get(comparingTo, 'mentions') || {},
    [comparingTo],
  );
  const contentScoreAverage = useMemo(
    () => get(appData, 'scores.metrics.cs_avg'),
    [appData],
  );
  const contentScoreMax = useMemo(
    () => get(appData, 'scores.metrics.cs_max'),
    [appData],
  );
  const contentScoreTarget = useMemo(
    () => get(appData, 'scores.metrics.cs_target'),
    [appData],
  );
  const rankingPages = useMemo(
    () =>
      Object.values(get(appData, 'scores.ranking_pages') || {}).sort(
        (a, b) => a.rank - b.rank,
      ),
    [appData],
  );

  // for variant slide-in view, notify parent the
  // render is finished so it can slide this in
  useEffect(() => {
    if (comparingTo) {
      setTimeout(() => {
        moveForwardTo('contents');
      }, 100);
    }
  }, []);

  // compete table to show when comparingTo is not selected
  const competeTable = useMemo(
    () => (
      <>
        {/* compete table header */}
        <SLRow header>
          <SLItem header width={50} alignCenter p={0}>
            Rank
          </SLItem>
          <SLItem header grow pl={12} pr={12}>
            Top Ranking Page
          </SLItem>
          <SLItem header width={50} alignCenter p={0}>
            Score
          </SLItem>
          <SLItem header width={50} alignCenter p={0}>
            Count
          </SLItem>
        </SLRow>

        {/* compete table */}
        {rankingPages.map(rp => (
          <SLRow
            key={`optimize-serp-${rp.rank}`}
            onClick={() => {
              setComparingTo(rp);
              optimizeCompeteHeadToHeadExpanded.record({
                comparingToTitle: rp.title,
                comparingToUrl: rp.url,
                comparingToRank: rp.rank,
              });
            }}
          >
            <SLItem width={50} alignCenter p={0}>
              <Rank value={rp.rank} />
            </SLItem>
            <SLItem grow p={8}>
              <Meta title={rp.title} url={rp.url} />
            </SLItem>
            <SLItem width={44} p={0} alignCenter>
              <ContentScore
                value={rp.cs}
                average={contentScoreAverage}
                target={contentScoreTarget}
                best={contentScoreMax}
              />
            </SLItem>
            <SLItem width={44} p={0} alignCenter>
              <Count>{rp.wc || '-'}</Count>
            </SLItem>
          </SLRow>
        ))}
      </>
    ),
    [contentScoreAverage, contentScoreMax, contentScoreTarget, rankingPages],
  );

  const headToHeadTable = useMemo(
    () => (
      <>
        {/* head to head label */}
        <H2hTitle>Head To Head</H2hTitle>
        <H2hContainer>
          <Rank value={get(comparingTo, 'rank')} />
          <Meta
            style={{
              marginLeft: 8,
              width: 'calc(100% - 36px)',
            }}
            title={get(comparingTo, 'title')}
            url={get(comparingTo, 'url')}
          />
        </H2hContainer>

        {/* head to head header */}
        <SLRow header>
          <SLItem header grow pl={0}>
            Related Topic
          </SLItem>
          <SLItem header width={40} pl={0} pr={0} alignCenter>
            You
          </SLItem>
          <SLItem header width={92} pl={0} pr={0} alignCenter>
            Them
          </SLItem>
        </SLRow>

        {/* head to head table */}
        {Object.keys(competitorMatches)
          .filter(term => term !== focusTopic)
          .map(term => (
            <SLRow key={`h2h-mentions-${term}`}>
              <SLItem grow pl={0} style={{ whiteSpace: 'normal' }}>
                {term}
              </SLItem>
              <SLItem width={60} p={0}>
                <Distribution value={get(matches, regexCleanup(term)) || 0} />
              </SLItem>
              <SLItem width={80} p={0}>
                <Distribution
                  value={get(competitorMatches, regexCleanup(term)) || 0}
                />
              </SLItem>
            </SLRow>
          ))}
      </>
    ),
    [matches, competitorMatches, comparingTo],
  );

  return (
    <Wrapper
      data-mms--toolkit-tab={comparingTo ? 'compete-head-to-head' : 'compete'}
    >
      {comparingTo && headToHeadTable}
      {!comparingTo && competeTable}
    </Wrapper>
  );
};

ToolkitTabCompete.propTypes = {
  appData: PropTypes.object,
  decors: PropTypes.object,
  comparingTo: PropTypes.object,
  setComparingTo: PropTypes.func,
  moveForwardTo: PropTypes.func,
  moveBackTo: PropTypes.func,
};

export default ToolkitTabCompete;
