import { isNil } from 'lodash';
import { MentionsCalculateFn, MentionsCalculateReturnType } from '../types';

const calculate: MentionsCalculateFn = mentions => {
  const result: MentionsCalculateReturnType = {
    mentions,
    color: 'red',
    band: '-',
    text: 'N/A',
  };

  if (isNil(mentions)) return result;

  if (mentions < 1) {
    return {
      ...result,
      color: 'red',
      band: '0',
      text: 'Low',
      bandLow: 0,
      bandHigh: 0,
    };
  }

  if (mentions <= 2) {
    return {
      ...result,
      color: 'yellow',
      band: '1-2',
      text: 'Low',
      bandLow: 1,
      bandHigh: 2,
    };
  }

  if (mentions <= 10) {
    return {
      ...result,
      color: 'green',
      band: '3-10',
      text: 'Medium',
      bandLow: 3,
      bandHigh: 10,
    };
  }

  if (mentions > 10) {
    return {
      ...result,
      color: 'blue',
      band: '10+',
      text: 'High',
      bandLow: 11,
    };
  }

  return result;
};

export default calculate;
