import React from 'react';
import { isEmpty, camelCase, pick } from 'lodash';

import { Calendly } from '@marketmuse/components';
import { miscActions } from '@marketmuse/data-state/misc';
import { MISC_SIMPLE_KEYS } from '@marketmuse/data-state/types';
import {
  useStoreSelector,
  useStoreDispatch,
} from '@marketmuse/data-state/hooks';
import { PARAMS_LIST } from '../../config/paramsList';
import { useParamsFromStorage } from '../../hooks/useParamStorage';

const CalendlyModal = () => {
  const params = useParamsFromStorage(PARAMS_LIST);
  const dispatch = useStoreDispatch();
  const userFullName = useStoreSelector(state => state?.user?.fullName);
  const userFirstName = useStoreSelector(state => state?.user?.firstName);
  const userLastName = useStoreSelector(state => state?.user?.lastName);
  const userEmail = useStoreSelector(state => state?.user?.email);

  const orgName = useStoreSelector(state => state?.user?.org?.name);
  const siteDomain = useStoreSelector(state => state?.filter?.site?.domain);

  const utmInfo = Object.entries(
    pick(params, [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_term',
      'utm_content',
    ]),
  ).reduce(
    (acc, [key, value]) => ({
      ...acc,
      ...(!isEmpty(value) ? { [camelCase(key)]: value } : {}),
    }),
    {},
  );

  return (
    <Calendly
      url="https://calendly.com/book-marketmuse-time/marketmuse-premium-walkthrough"
      isVisible={true}
      style={{
        height: 'calc(100vh - 40px)',
      }}
      prefill={{
        name: userFullName,
        firstName: userFirstName,
        lastName: userLastName,
        email: userEmail,
        customAnswers: {
          a1: orgName,
          a2: siteDomain,
        },
      }}
      utm={!isEmpty(utmInfo) ? utmInfo : void 0}
      onClose={() =>
        dispatch(
          miscActions.setSimple({
            key: MISC_SIMPLE_KEYS.calendlyPremium,
            value: false,
          }),
        )
      }
    />
  );
};

export default CalendlyModal;
