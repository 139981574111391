export const blue = {
  blueDark: '#1b8fed',
  blueDark1: '#0881e2',
  blueLight: '#61b9ff',
  blueLight1: '#81c6fe',
  blueLight2: '#e2f2ff',
  blueBase: '#359ff4',
  blue: '#359ff4',
}

export const yellow = {
  yellowDark: '#f3bd1c',
  yellowDark1: '#e3aa01',
  yellowLight: '#ffd968',
  yellowLight1: '#ffe18a',
  yellowLight2: '#fff1c0',
  yellowBase: '#fec92e',
  yellow: '#fec92e',
}

export const green = {
  greenDark: '#21cf7a',
  greenDark1: '#0cb562',
  greenLight: '#50eea1',
  greenLight1: '#85f3bd',
  greenLight2: '#a8f6d0',
  greenBase: '#38da8b',
  green: '#38da8b',
}

export const red = {
  redDark: '#e94b37',
  redDark1: '#de3520',
  redLight: '#f37b6c',
  redLight1: '#ffaa9f',
  redLight2: '#ffe3df',
  redBase: '#ed6a5a',
  red: '#ed6a5a',
}

export const orange = {
  orangeDark: '#ef8035',
  orangeLight: '#ffad75',
  orangeDark1: '#dd6818',
  orangeLight1: '#ffbd91',
  orangeLight2: '#ffcaa6',
  orangeBase: '#fc954f',
  orange: '#fc954f',
}

export const neutral = {
  transparent: 'rgba(255, 255, 255, 0)',
  white: '#ffffff',
  grey05: '#fafafa',
  grey10: '#f4f4f4',
  grey15: '#e9e9e9',
  grey25: '#cfcfcf',
  grey35: '#c4c4c4',
  grey50: '#aeaeae',
  grey60: '#8f8b8f',
  grey70: '#3c3f43',
  grey85: '#26292d',
  black: '#18191b', // Please refer to system--main before adding #000
}

export default {
  ...blue,
  ...yellow,
  ...green,
  ...red,
  ...orange,
  ...neutral
};
