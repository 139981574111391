import { useMemo } from 'react';
import { isNil } from 'lodash';
import { Updater, FilterFn } from '@tanstack/react-table';
import { Input, Checkbox } from '../../../Forms';
import { Box } from '../../../Box';
import { Text } from '../../../Text';
import { formatNumber } from './utils';

const InputFilterFns = {
  IN_NUMBER_RANGE: 'inNumberRange',
  NUMBER_IN_RANGE_NULL_OPTIONAL: 'numberInRangeOptional',
} as const;

interface PropsInput {
  labelForExclude?: string;
  filterFn: string | FilterFn<unknown>;
  filterValue: number[] | bigint[];
  id: string;
  setFilterValue: (updater: Updater<any>) => void;
  title: string;
}

function InputBetween({
  labelForExclude,
  filterFn,
  filterValue,
  id,
  setFilterValue,
  title,
}: PropsInput) {
  const filterFnName: string =
    typeof filterFn === 'function' ? filterFn.name : filterFn;
  const isEmptyOptional =
    filterFnName === InputFilterFns.NUMBER_IN_RANGE_NULL_OPTIONAL;

  const inputMinProps = useMemo(
    () => ({
      value: filterValue?.[0] || '',
      type: 'number',
      lang: 'en',
      onChange: event => {
        setFilterValue((prev = []) => {
          const value = event.target.value;
          const next = [formatNumber(value), prev[1]];
          if (isEmptyOptional) {
            const minEmpty = value === '' || isNil(value);
            const excludeNull = !minEmpty || prev[2] ? true : false;
            next.push(excludeNull);
          }
          return next;
        });
      },
      placeholder: 'Min',
      className: ['w-[100px]', 'flex-initial'],
    }),
    [filterValue, isEmptyOptional, setFilterValue],
  );

  const inputMaxProps = useMemo(
    () => ({
      value: filterValue?.[1] || '',
      type: 'number',
      lang: 'en',
      onChange: event => {
        setFilterValue((prev = []) => {
          const next = [prev[0], formatNumber(event.target.value)];
          if (isEmptyOptional) {
            next.push(prev[2]);
          }
          return next;
        });
      },
      placeholder: 'Max',
      className: ['w-[100px]', 'flex-initial'],
    }),
    [filterValue, isEmptyOptional, setFilterValue],
  );

  const checkboxProps = useMemo(
    () => ({
      value: filterValue?.[2] ? 'checked' : 'unchecked',
      checked: Boolean(filterValue?.[2]),
      onChange: event => {
        setFilterValue((prev = []) => {
          const next = [prev[0], prev[1], prev[2] ? false : true];
          return next;
        });
      },
    }),
    [filterValue, setFilterValue],
  );

  return (
    <Box className={['flex', 'flex-col', 'gap-2']}>
      <Text
        className={[
          'flex',
          'flex-grow',
          'font-bold',
          'text-gray-700',
          'text-base',
        ]}
      >
        {title}
      </Text>
      <Box className={['flex', 'flex-row', 'justify-start', 'gap-5']}>
        <Box
          className={[
            'flex',
            'flex-row',
            'justify-start',
            'gap-3',
            isEmptyOptional ? 'basis-5/12' : 'max-w-[300px]',
          ]}
        >
          <Input
            name={`table-filter--column-${id}-min`}
            label={'from'}
            labelProps={{
              className: ['flex', 'self-center', 'sr-only'],
            }}
            inputProps={inputMinProps}
          />
          <Input
            name={`table-filter--column-${id}-max`}
            label={'to'}
            labelProps={{
              className: ['flex', 'self-center', 'sr-only'],
            }}
            inputProps={inputMaxProps}
          />
        </Box>
        {isEmptyOptional && (
          <Text
            as="label"
            className={[
              'flex',
              'flex-row',
              'justify-center',
              'items-center',
              'gap-2',
              'basis-7/12',
            ]}
          >
            <Checkbox {...checkboxProps} />
            <span className="block">{labelForExclude || 'Exclude empty'}</span>
          </Text>
        )}
      </Box>
    </Box>
  );
}

export default InputBetween;
