import { SUPPORTED_ICON } from '@marketmuse/components';
import { NavigationItem } from './types';

const config: Record<string, NavigationItem> = {
  topicNavigator: {
    id: 'topicNavigator',
    title: 'Topic Navigator',
    path: 'topic-navigator/:view',
    initialView: 'topic-model',
    iconName: SUPPORTED_ICON.research_topic_navigator,
    activeClassName: 'border-green-400 xl:border-green-400',
  },
  serpXray: {
    id: 'serpXray',
    title: 'SERP X-Ray',
    path: 'serp-xray',
    iconName: SUPPORTED_ICON.research_serp_xray,
    activeClassName: 'border-yellow-400 xl:border-yellow-400',
    disabled: true,
  },
  heatmap: {
    id: 'heatmap',
    title: 'Heatmap',
    path: 'heatmap',
    iconName: SUPPORTED_ICON.research_heatmap,
    activeClassName: 'border-red-400 xl:border-red-400',
    disabled: true,
  },
  connect: {
    id: 'connect',
    title: 'Connect',
    path: 'connect/:view',
    initialView: 'internal',
    iconName: SUPPORTED_ICON.research_connect,
    activeClassName: 'border-blue-400 xl:border-blue-400',
  },
};

export default config;
