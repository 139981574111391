const spacing = {
  xxSmall: '4px',
  xSmall: '8px',
  small: '12px',
  regular: '16px',
  medium: '20px',
  large: '32px',
  xLarge: '60px',
  xxLarge: '92px',
  parse: function(name) { return parseInt(this[name], 10)},
};

export default spacing;
