import { ObjectValues } from '../utilities';

export const JWT_VALIDATION_CODES = {
  EMPTY: 'EMPTY',
  ERROR: 'ERROR',
  EXPIRED: 'EXPIRED',
  VALID: 'VALID',
} as const;

export type JwtValidationCodes = ObjectValues<typeof JWT_VALIDATION_CODES>;

export type JwtTokenAnalysis = {
  valid: boolean;
  code: JwtValidationCodes;
};
