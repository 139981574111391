import React from 'react';

import { ErrorBoundary as ErrorBoundaryComponent } from '@marketmuse/components';
import openSupportChat from '../utils/openSupportChat';

const ErrorBoundary = props => (
  <ErrorBoundaryComponent openChat={openSupportChat} {...props} />
);

export default ErrorBoundary;
