import React, { FC } from 'react';
import cx from 'classnames';
import { twMerge } from 'tailwind-merge';
import { INavigationButtonProps } from '../types';
import { Button } from '../../Button';
import { Icon } from '../../Icon';

const baseClassNames = [
  'flex',
  'items-center ',
  'text-blue-500',
  'font-semibold',
];

export const NavigationButton: FC<INavigationButtonProps> = ({
  title,
  iconName,
  disabled,
  onClick,
  className,
  iconPosition,
}) => {
  const buttonClassName =
    typeof className === 'string' ? className : cx(className);
  return (
    <Button
      className={twMerge([
        baseClassNames,
        !disabled ? 'cursor-pointer' : '',
        disabled ? 'text-gray-500' : '',
        buttonClassName,
      ])}
      onClick={onClick}
      disabled={disabled}
    >
      {iconPosition === 'right' && title}
      <Icon name={iconName} title={title} />
      {iconPosition === 'left' && title}
    </Button>
  );
};
