import React from 'react';

import LoadingBlock from '../../../components/LoadingBlock/LoadingBlock';

export default () => (
  <>
    {Array(17)
      .fill('x')
      .map((_, i) => (
        <div
          key={`optimize-sidebar-loading-${i}`}
          style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}
        >
          <LoadingBlock
            style={{ flexGrow: 1, height: 32, borderRadius: 4, marginRight: 0 }}
          />
          <LoadingBlock
            style={{
              flexShrink: 0,
              marginLeft: 12,
              marginRight: 0,
              height: 32,
              width: 52,
              borderRadius: 4,
            }}
          />
          <LoadingBlock
            style={{
              flexShrink: 0,
              marginLeft: 12,
              marginRight: 0,
              height: 32,
              width: 52,
              borderRadius: 4,
            }}
          />
        </div>
      ))}
  </>
);
