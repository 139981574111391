import Pendo from './agents/Pendo';
import Sentry from './agents/Sentry';
import GoogleAnalytics from './agents/GoogleAnalytics';
import Qualified from './agents/Qualified';
import { getDefaults } from './getDefaults';

class Tracker {
  constructor() {
    if (!Tracker._instance) {
      Tracker._instance = this;
    }

    window.dataLayer = window.dataLayer || [];

    this.agents = [
      new Sentry(),
      new Pendo(),
      new GoogleAnalytics(),
      new Qualified(),
    ];
    this.user = null;

    return Tracker._instance;
  }

  // it is ready when all agents are initialized successfully
  init(user) {
    const defaults = getDefaults();
    this.user = user;
    this.agents.forEach(agent => {
      agent.init(user, defaults);
    });
  }

  updateField(field, value) {
    const defaults = getDefaults();
    this.user[field] = value;
    this.agents.forEach(agent => {
      agent.update(this.user, defaults);
    });
  }

  update(user) {
    const defaults = getDefaults();
    this.user = user;
    this.agents.forEach(agent => {
      agent.update(user, defaults);
    });
  }

  page(pageInfo) {
    this.agents.forEach(agent => {
      agent.page(pageInfo);
    });
  }

  initAnonymous() {
    this.agents.forEach(agent => {
      agent.initAnonymous();
    });
  }

  clear() {
    this.agents.forEach(agent => {
      agent.clear();
    });
  }

  track(e) {
    this.agents.forEach(agent => {
      agent.track(e);
    });
  }
}

export default Tracker;
