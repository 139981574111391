import React, { memo } from 'react';
import { LineChart, Line, Tooltip } from 'recharts';

import SeasonalityTooltip from './SeasonalityTooltip';
import { SeasonalityProps } from './types';
import { useHistory } from './useHistory';

export const Seasonality = memo(
  ({ width, height, data, animate }: SeasonalityProps) => {
    const history = useHistory(data);
    return (
      <LineChart data={history} width={width || 160} height={height || 40}>
        <Line
          type="monotone"
          dataKey="volume"
          strokeWidth={2}
          stroke={'#61b9ff'}
          dot={false}
          isAnimationActive={animate ? animate : false}
        />
        <Tooltip content={SeasonalityTooltip} />
      </LineChart>
    );
  },
);
