import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Label from '../components/Label';
import RequiredStar from '../components/RequiredStar';
import BaseWrapper from './base/BaseWrapper';
import BaseInput from './base/BaseInput';
import BaseTextarea from './base/BaseTextarea';


const FloatingLabel = styled(Label)`
  top: 0;
  position: absolute;
  padding: 0 ${p => p.theme.spacing.small};
  margin: 0;
  color: ${p => p.theme.colors.grey50};
  font-size: 12px;
  font-weight: normal;
  line-height: 1.4;
  min-width: auto;
  max-width: 100%;
  border-radius: ${p => p.theme.borderRadius};
  transform: translateY(11px);
  transition: ${p => p.theme.transition};
  pointer-events: none;
`;

const labelStyleActive = p => css`
  top: 0;
  padding: 0 4px;
  margin: 0 8px;
  font-size: 9px;
  transform: translateY(-50%);
  color: ${p => p.theme.colors.grey70};
  background-color: white;
`;

const inputStyleShared = p => css`
  color: ${p => p.theme.colors.grey70};
  font-size: 12px;
  line-height: 1.2;

  &:not(:disabled) {
    &:active ~ ${FloatingLabel}, &:focus ~ ${FloatingLabel} {
      ${labelStyleActive}
    }
  }
`;

export const LightInput = styled(BaseInput)`
  ${inputStyleShared}
  height: ${p => p.theme.input.light.minHeight}px;
  min-height: inherit;
`;

export const LightTextarea = styled(BaseTextarea)`
  ${inputStyleShared}
  min-height: 56px;
  height: inherit;
  max-width: 100%;
  resize: ${p => (p.disabled ? 'none' : 'vertical')};
`;

const Wrapper = styled(BaseWrapper)`
  height: auto;
  min-height: ${p => p.theme.input.light.minHeight}px;
  width: 100%;
  margin-top: 0;
  color: ${p => p.theme.input.light.color};
  background-color: ${p => p.theme.input.light.backgroundColor};
  border: 1px solid ${p => p.theme.colors.grey15};
  border-radius: 3px;

  ${p =>
    (p.placeholder || !p.isEmpty) &&
    `
    ${FloatingLabel} {
      ${labelStyleActive(p)}
    }
  `}

  ${p =>
    p.disabled &&
    `
    color: ${p.theme.input.light.disabled.color};
    background-color: ${p.theme.input.light.disabled.backgroundColor};
  `}

  ${p =>
    p.error &&
    `
    border-color: ${p.theme.mmxRed};
  `}
`;

const LightWrapper = ({ children, ...props }) => {
  return (
    <Wrapper {...props} hasLabel={false}>
      {args => (
        <>
          {children(args)}

          {props.hasLabel && (
            <FloatingLabel>
              {props.required && <RequiredStar />}
              {props.label}
            </FloatingLabel>
          )}
        </>
      )}
    </Wrapper>
  );
};

LightWrapper.propTypes = {
  children: PropTypes.any,
};

export default LightWrapper;
