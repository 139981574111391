import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const MatrixScroll = styled.div`
  overflow: auto;
`;

const GridContainer = styled.div`
  display: flex;
`;

const GridWrapper = styled.div`
  display: flex;
`;

const MatrixGrid = props => (
  <GridWrapper>
    {props.locked &&
      !props.loading && (
        <GridContainer>
          {props.locked()}
        </GridContainer>
      )}

    <MatrixScroll>
      <GridContainer>
        {props.children}
      </GridContainer>
    </MatrixScroll>
  </GridWrapper>
);

MatrixGrid.propTypes = {
  loading: PropTypes.bool,
  locked: PropTypes.any,
  children: PropTypes.any
};

export default MatrixGrid;
