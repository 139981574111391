import { createAction } from '@reduxjs/toolkit';
import {
  ResearchKGItem,
  ResearchDataInput,
  ResearchDataConnectInput,
  ResearchSnapshotInput,
  ViewHeatmapFiltersState,
} from '../types';
import {
  RESEARCH_UPDATE_CONNECT_DOMAINS,
  RESEARCH_UPDATE_SITE_CONNECT_DOMAINS,
} from './appResearch.actionTypes';

export const researchTaskStatusCheck = createAction('research/task/status');
export const researchTaskInitialize = createAction<ResearchDataInput>(
  'research/task/initialize',
);

export const researchTaskSiteChange = createAction<ResearchDataInput>(
  'research/task/siteChange',
);

export const researchSiteConnectDomains =
  createAction<ResearchDataConnectInput>(RESEARCH_UPDATE_SITE_CONNECT_DOMAINS);

export const researchTaskConnectDomains =
  createAction<ResearchDataConnectInput>(RESEARCH_UPDATE_CONNECT_DOMAINS);

export const researchTaskConnectFetch = createAction<ResearchDataConnectInput>(
  'research/task/connectFetch',
);

export const researchTaskQueryLoad = createAction<ResearchSnapshotInput>(
  'research/task/queryLoad',
);

export const researchTaskQueryUpsert = createAction<ResearchDataInput>(
  'research/task/queryUpsert',
);

export const researchTaskInventoryItemAdded = createAction<
  Array<{ term: string; volume?: number }>
>('research/task/inventoryItemAdded');

export const researchTaskWebsiteHeatmapFilters =
  createAction<ViewHeatmapFiltersState>('research/task/websiteHeatmapFilters');

export const researchTaskWebsiteHeatmapData = createAction<{
  knowledgeGraphItems: ResearchKGItem[];
  include: ViewHeatmapFiltersState['include'];
  exclude: ViewHeatmapFiltersState['exclude'];
}>('research/task/websiteHeatmapData');
