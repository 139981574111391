import { isEmpty } from 'lodash';
import { createRegexFromTerms } from '@marketmuse/utilities';

const wrap = ({
  str,
  query,
  highlightClassNames,
  options = {},
}: {
  str: string;
  options: { url?: boolean };
  query: string;
  highlightClassNames?: string | Array<string | string[]>;
}) => {
  const terms = [query];
  if (options.url) {
    const qDash = query.replace(/ /g, '-');
    const qUnderscore = query.replace(/ /g, '_');
    if (qUnderscore !== query) terms.push(qUnderscore);
    if (qDash !== query) terms.push(qDash);
  }

  const r = createRegexFromTerms(terms, { substr: true });
  return str.replace(r, `<span class="${highlightClassNames}">$1</span>`);
};

export const highlight = ({
  str,
  terms = [],
  options = {},
  highlightClassNames,
}: {
  str: string;
  terms: string[];
  options: { url?: boolean };
  highlightClassNames?: string | Array<string | string[]>;
}) => {
  if (isEmpty(terms)) {
    return str;
  }
  let strNew = str;
  terms.forEach(term => {
    strNew = wrap({ str: strNew, query: term, highlightClassNames, options });
  });
  return strNew;
};
