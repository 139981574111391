import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Tooltip from '../Tooltip/Tooltip/Tooltip';

import isUrl from '../../utils/isUrl';

const TooltipContent = styled.span`
  white-space: nowrap;
  display: block;
`;

const Wrapper = styled.a`
  display: inline-flex;
  align-items: center;
  position: relative;
  text-decoration: none;
  color: ${p => p.theme.colors[p.color || 'blueDark']};
  font-size: inherit;
  line-height: 18px;
  .mms--icon-link {
    visibility: hidden;
  }
  ${p =>
    p.valid &&
    `
    &:hover {
      cursor: pointer;
      text-decoration: underline;
      .mms--icon-link {
        visibility: visible;
      }
    }
  `}
  ${p =>
    !p.valid &&
    `
    &:hover {
      cursor: default !important;
    }
  `}
  svg {
    width: 12px;
    margin-left: 4px;
    height: 12px;
    margin-bottom: -2px;
  }
`;

const Url = props => {
  let url = typeof props.href === 'string' ? props.href : '';
  if (url.indexOf('http://') === -1 && url.indexOf('https://') === -1) {
    url = `http://${url}`;
  }
  const isValid = isUrl(url);
  return (
    <Tooltip
      html={<TooltipContent>Open link in New Tab</TooltipContent>}
      placement="bottom"
      disabled={!isValid}
    >
      <Wrapper
        valid={isValid}
        href={isValid ? url : null}
        target="_blank"
        onClick={isValid ? e => e.stopPropagation() : null}
        className={props.className}
        style={props.style}
        color={props.color}
      >
        {props.children}
      </Wrapper>
    </Tooltip>
  );
};

Url.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  href: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default Url;
