export const plansCodes: Record<'paid' | 'trial', string[]> = {
  paid: [
    'mmplus-annual',
    'mmplus',
    'optimize-annual-q32024',
    'optimize-annual',
    'optimize-monthly-q32024',
    'optimize',
    'pro-annual',
    'pro',
    'research-annual-q32024',
    'research-monthly-q32024',
    'standard-149-monthly-q12023',
    'standard-1500-annual-q12023',
    'standard-6000-annual-q42022',
    'standard-7200-monthly-q42022',
    'standard-annual',
    'standard-monthly',
    'standard',
    'strategy-annual-q32024',
    'strategy-monthly-q32024',
  ],
  trial: ['standard-trial-q22023', 'research-trial-q32024'],
};
