import { groupBy } from 'lodash';

import { Failure, GotoFunction, Output, Result, SearchItem } from './types';
import { isAlphaNumeric } from './utils/isAlphaNumeric';

function isWholeWord(content: string, item: Result): boolean {
  const isStartAlphaNumeric = isAlphaNumeric(content.charAt(item.start - 1));
  const isEndAlphaNumeric = isAlphaNumeric(content.charAt(item.end));

  const isMatch =
    (item.start === 0 || !isStartAlphaNumeric) &&
    (item.end === content.length || !isEndAlphaNumeric);

  return isMatch;
}

function serializeBranchItems({
  items,
  charIndex,
  content,
  addToResults,
}: {
  items: SearchItem[];
  charIndex: number;
  content: string;
  addToResults: (result: Result) => void;
}) {
  if (!items.length) {
    return;
  }

  items.forEach(({ term, key }) => {
    const result = {
      term,
      key,
      start: charIndex - term.length + 1,
      end: charIndex + 1,
    };

    if (isWholeWord(content, result)) {
      addToResults(result);
    }
  });
}

// Based on https://github.com/BrunoRB/ahocorasick/blob/master/src/main.js
// https://medium.com/pattern-searching-algorithm/aho-corasick-algorithm-7e5c2e58861c
export class AhoCorasick {
  private _gotoFn: GotoFunction;
  private _output: Output;
  private _failure: Failure;

  constructor(searchItems: SearchItem[]) {
    this._gotoFn = { 0: {} };
    this._output = { 0: [] };
    this._failure = { 0: 0 };

    this._buildOutputTree(searchItems);
    this._buildFailuresTree();
  }

  private _buildTermBranches({
    incrementIndex,
    term,
  }: {
    incrementIndex: () => number;
    term: string;
  }) {
    let current = 0;
    for (let i = 0; i < term.length; i++) {
      const char = term[i];
      const charInGoto = char in (this._gotoFn[current] || {});

      if (this._gotoFn[current] && charInGoto) {
        current = this._gotoFn[current][char];
      } else {
        const searchIndex = incrementIndex();

        this._gotoFn[searchIndex] = {};
        this._output[searchIndex] = [];

        this._gotoFn[current] = this._gotoFn[current] || {};
        this._gotoFn[current][char] = searchIndex;

        current = searchIndex;
      }
    }

    return current;
  }

  private _buildOutputTree(searchItems: SearchItem[]) {
    let searchIndex = 0;
    searchItems.forEach(item => {
      const term = item.term.toLowerCase();
      const current = this._buildTermBranches({
        term,
        incrementIndex: () => {
          searchIndex++;
          return searchIndex;
        },
      });

      this._output[current].push(item);
    });
  }

  private _buildFailuresTree() {
    const positions: number[] = [];
    // this_failure(index) = 0 for all indexes of depth 1
    // - (the ones from which the 0 index can transition to)
    for (const i in this._gotoFn[0]) {
      const index = this._gotoFn[0][i];
      this._failure[index] = 0;
      positions.push(index);
    }

    while (positions.length) {
      const position = positions.shift() as number;

      // for each symbol a such that _gotoFn(position, branch) = index
      for (const branch in this._gotoFn[position]) {
        const next = this._gotoFn[position][branch];
        positions.push(next);

        // set index = this._failure(position)
        let index = this._failure[position];
        while (index > 0 && !(branch in this._gotoFn[index])) {
          index = this._failure[index];
        }

        if (branch in this._gotoFn[index]) {
          const leaf = this._gotoFn[index][branch];
          this._failure[next] = leaf;

          this._output[next] = this._output[next].concat(this._output[leaf]);
        } else {
          this._failure[next] = 0;
        }
      }
    }
  }

  public search(text: string): Record<string, Result[]> {
    let index = 0;
    const results: Result[] = [];
    const content = text.toLowerCase();

    if (content.length <= 2) return {};

    for (let charIndex = 0; charIndex < content.length; charIndex++) {
      const char = content[charIndex];

      // check if branch exists
      while (index > 0 && !(char in this._gotoFn[index])) {
        // otherwise point the index via the failure collection
        index = this._failure[index];
      }

      // If there is no branch we proceed to next index
      if (!(char in this._gotoFn[index])) {
        continue;
      }

      // Find branch in data tree
      index = this._gotoFn[index][char];
      serializeBranchItems({
        items: this._output[index],
        charIndex,
        content,
        addToResults: result => results.push(result),
      });
    }

    const grouped = groupBy(results, 'key');

    return grouped;
  }
}
