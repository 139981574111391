import { get, isNil } from 'lodash';

import { InventoryItemsUpdateDocument } from '@marketmuse/data-papi';
import Toast from '../../components/Toast';

import makeRequest from '../../utils/makeRequest';
import * as types from '../../config/types';

const createReverseAction = i => {
  const next = { ...i };
  if (!isNil(i.hiddenByUser)) next['hiddenByUser'] = !i.hiddenByUser;
  return next;
};

export default () =>
  ({ getState }) =>
  next =>
  action => {
    const state = getState();

    if (action.type === types.INVENTORY_ITEMS_UPDATE) {
      makeRequest({
        type: types.INVENTORY_ITEMS_UPDATE,
        mutation: InventoryItemsUpdateDocument,
        variables: {
          items: {
            siteId: get(state, 'filter.site.id'),
            topics: action.topics,
            pages: action.pages,
            resources: action.resources,
          },
        },
        keys: [`${types.INVENTORY_ITEMS_UPDATE}-${action.updateType}`],
      }).then(res => {
        const result = get(res, 'inventoryItemsUpdate') || [];

        if (res.errors || res.error) {
          Toast.fire('Items cannot be added at this moment', 'error');
        }

        if (result && action.updateType === 'visibility') {
          // revert actions performed
          const pages = (action.pages || []).map(createReverseAction);
          const topics = (action.topics || []).map(createReverseAction);
          const resources = (action.resources || []).map(createReverseAction);

          if (action.callback) action.callback();
          if (!action.noFeedback) {
            Toast.fire('Item visibility updated', null, {
              buttons: [
                [
                  '<button>Undo</button>',
                  (instance, toast) => {
                    Toast.fire('Reverting...', 'info');
                    makeRequest({
                      type: types.INVENTORY_ITEMS_UPDATE,
                      mutation: InventoryItemsUpdateDocument,
                      variables: {
                        items: {
                          siteId: get(state, 'filter.site.id'),
                          topics,
                          pages,
                          resources,
                        },
                      },
                    }).then(res2 => {
                      const resultsUndo = get(res2, 'inventoryItemsUpdate');
                      if (resultsUndo) Toast.fire('Item visibility reverted');
                      else
                        Toast.fire(
                          'Item visibility could not be reverted',
                          'error',
                        );
                      if (action.undoCallback) action.undoCallback(res2);
                    });
                  },
                ],
              ],
            });
          }
        } else if (result) {
          Toast.fire('Item successfully updated');
        }
      });
    }

    return next(action);
  };
