export function formatNumber(value) {
  const numberRegexp = /^[+-]?([0-9]*)*([.,]?[0-9]{0,2})$/;
  if (!value) {
    return void 0;
  }

  let parsedValue = value;
  if (!numberRegexp.test(value)) {
    const [integer, decimal] = value.split(/[.,]/);
    parsedValue = `${integer}.${decimal?.slice(0, 2)}`;
  }

  return parsedValue;
}

export function getMinMax(column, parser) {
  const facetedValues = column.getFacetedMinMaxValues();

  const min = Number(facetedValues?.[0] ?? '');
  const max = Number(facetedValues?.[1] ?? '');

  return [parser(min), parser(max)];
}
