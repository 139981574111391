import React from 'react';
import styled from 'styled-components';

import Box from '../Box';
import Toggle from '../Radio/Toggle';

const BoxStyled = styled(Box)`
  color: ${p => p.theme.colors.grey60};
  font-size: 11px;
`;

const SimpleCheckbox = ({
  targetValue,
  onDebounce,
  name,
  label,
  disabled,
  rightRail,
  note,
}) => {
  return (
    <Box boxVariant="flex" alignContent="center" justifyContent="start" mt={12}>
      <Box boxVariant="flex" width={80} pr={16} pl={16} minWidth={80}>
        <Toggle
          disabled={disabled}
          active={targetValue}
          onClick={() => onDebounce(!targetValue, name)}
        />
      </Box>
      <Box
        boxVariant="flex"
        flexDirection="column"
        pr={16}
        justifyContent="center"
      >
        <Box boxVariant="flex">{label}</Box>
        {note && (
          <BoxStyled boxVariant="flex" mt={4}>
            {note}
          </BoxStyled>
        )}
      </Box>
      {rightRail && (
        <Box boxVariant="flex" alignItems="center">
          {rightRail}
        </Box>
      )}
    </Box>
  );
};

export default SimpleCheckbox;
