import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '../../../../components/Button';

export const sideItemProps = {
  collapsed: PropTypes.bool,
  color: PropTypes.string,
  activeLabelBackground: PropTypes.string,
  paddingLR: PropTypes.bool,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  header: PropTypes.bool,
  subheader: PropTypes.bool,
  isFilter: PropTypes.bool,
  icon: PropTypes.any,
  collapsedIconOnly: PropTypes.bool,
  title: PropTypes.string,
  titleStyle: PropTypes.object,
  label: PropTypes.any,
  badge: PropTypes.any,
  locked: PropTypes.any,
  // renders an add button
  // first arg title, second arg callback
  badgeAdd: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  ),

  buttonRight: PropTypes.shape({
    onClick: PropTypes.any,
    children: PropTypes.any,
    title: PropTypes.string,
    displayMode: PropTypes.oneOf(['onHover', 'always']),
  }),
  shouldFitContent: PropTypes.bool,
  adminMode: PropTypes.bool,
};

export const BadgeButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  color: currentColor;

  svg {
    width: 14px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
