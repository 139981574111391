export function addScriptToDOM(src: string, callback: () => void): void {
  const script = document.createElement('script');
  script.src = src;

  script.onload = () => {
    if (typeof callback === 'function') {
      callback();
    }
  };

  document.head.appendChild(script);
}
