import React from 'react';

import ChangePill from '../ChangePill';

import styles from '../../styles/variables';
import Rank from './Rank';

const SerializedRank = ({ row, minField, maxField }) => {

  const maxRank = row?.[maxField] ? row?.[maxField] : 'NR' ;
  const minRank = row?.[minField] ? row?.[minField] : 'NR' ;

  return (maxRank === minRank || maxRank === 'NR')
    ? <Rank
      value={maxRank}
      style={{ backgroundColor: styles.colors.grey15, color: styles.colors.grey60 }}
    />
    : <ChangePill
      value={maxRank}
      changedValue={minRank}
      dir="range"
    />
};

export default SerializedRank;
