import styled from 'styled-components';

import Page from '../../../../components/Page/Page';

export const PageStyled = styled(Page)`
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow: hidden;
  background: ${p => p.theme.colors.white};
`;
