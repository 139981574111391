import get from 'lodash/get';

import { GetFormsDocument } from '@marketmuse/data-papi';

import makeRequest from '../../utils/makeRequest';
import * as types from '../../config/types';

export default () => () => next => action => {
  if (action.type === types.GET_FORMS) {
    makeRequest({
      type: types.GET_FORMS,
      mutation: GetFormsDocument,
      variables: {
        filters: get(action, 'filters') || [{}],
      },
      options: {
        isQuery: true,
      },
      apolloOptions: {
        fetchPolicy: 'no-cache',
      },
    }).then(res => {
      const forms = get(res, 'forms', []);
      if (typeof action.callback === 'function') action.callback(forms);
    });
  }

  return next(action);
};
