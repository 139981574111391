import styled from 'styled-components';
import Clickable from '../../../components/Clickable';

export const paddingLR = 20;
export const SidebarPaddingLR = styled.div`
  padding-left: ${paddingLR}px;
  padding-right: ${paddingLR}px;
  width: 100%;
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${p => p.theme.colors.grey70};
  margin-top: 13px;
  margin-bottom: 13px;
`;

export const SidebarButton = styled(Clickable)`
  ${p => p.theme.type.textRegular}
  position: relative;
  border-radius: 4px;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  overflow: hidden;
  color: white;
  font-weight: bold;
  &:hover {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
  background-color: ${p =>
    p.isAdmin ? p.theme.sidebarColorAdminInverse : p.theme.sidebarColorInverse};

  ${p =>
    p.emphasis &&
    `
    background-color: white;
    color: ${p.isAdmin ? p.theme.sidebarColorAdmin : p.theme.sidebarColor};
  `}
`;
