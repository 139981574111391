import * as React from 'react';
import { ButtonSizes, BUTTON_SIZES } from '../types';

export const useSizesClasses = (size: ButtonSizes) => {
  return React.useMemo(() => {
    switch (size) {
      case BUTTON_SIZES.lg:
        return ['py-3', 'px-6'];
      case BUTTON_SIZES.sm:
        return ['py-2', 'px-4'];
      case BUTTON_SIZES.xs:
        return ['py-1', 'px-3'];
      case BUTTON_SIZES.md:
        return ['py-2.5', 'px-5'];
      default:
        return [];
    }
  }, [size]);
};
