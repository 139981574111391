import { useMemo } from 'react';
import { MODAL_MODE } from '../types';

export const transitionBase = {
  enter: 'ease-out duration-300',
  leave: 'ease-in duration-200',
  enterFrom: 'opacity-0 scale-95',
  leaveFrom: 'opacity-100 scale-100',
  enterTo: 'opacity-100 scale-100',
  leaveTo: 'opacity-0 scale-95',
};

export const transitionSlideoutRight = {
  ...transitionBase,
  enterFrom: 'opacity-0 translate-x-full',
  leaveFrom: 'opacity-100 translate-x-0',
  enterTo: 'opacity-100 translate-x-0',
  leaveTo: 'opacity-0 translate-x-full',
};

export const transitionSlideoutLeft = {
  ...transitionSlideoutRight,
  enterFrom: 'opacity-0 -translate-x-full',
  leaveTo: 'opacity-0 -translate-x-full',
};

const useModalContentTransition = (mode: MODAL_MODE) => {
  return useMemo(() => {
    if (mode === MODAL_MODE.slideoutRight) {
      return transitionSlideoutRight;
    } else if (mode === MODAL_MODE.slideoutLeft) {
      return transitionSlideoutLeft;
    } else {
      return transitionBase;
    }
  }, [mode]);
};

export default useModalContentTransition;
