import { uniq } from 'lodash';
import * as types from '../config/types';

export const request = (type, mutation, data, callback, options = {}) => ({
  type: types.REQUEST,
  requestType: type,
  requestKeys: uniq(options.keys || []),
  mutation,
  data,
  callback,
  options,
});

export const requestSuccess = (
  id,
  res,
  requestType,
  requestKeys,
  options = {},
) => ({
  id,
  type: types.REQUEST_SUCCESS,
  res,
  requestType,
  requestKeys,
  options,
});

export const requestFail = (
  id,
  err,
  requestType,
  requestKeys,
  options = {},
) => ({
  id,
  type: types.REQUEST_FAIL,
  err,
  requestType,
  requestKeys,
  options,
});
