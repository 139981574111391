import { authLink } from '@marketmuse/data-client';
import { apolloClientPapi } from '@marketmuse/data-papi';
import { REACT_APP } from '../../config/env';
import { gqlLogger } from './links';

export const apolloClient = () => {
  return apolloClientPapi({
    endpoint: REACT_APP.PAPI_GQL,
    links: [
      authLink({
        getSessionManager: function () {
          return window?.Clerk?.session;
        },
      }),
      gqlLogger(),
    ],
  });
};
