import React from 'react';
import { useSelector } from 'react-redux';
import { capitalize } from 'lodash';
import Text from '../../../components/Text';
import Box from '../../../components/Box';
import Tooltip from '../../../components/Tooltip/Tooltip';

import IconAppStatus from './IconAppStatus';

const listProps = {
  boxVariant: 'flex',
  flexRow: true,
  gap: 8,
};

export function AppResearchStatus() {
  const researchStatus = useSelector(state => state?.appResearch?.ui?.status);
  const researchNetworkStatus = useSelector(
    state => state?.appResearch?.network,
  );
  return (
    <Tooltip
      style={{ height: '100%', display: 'flex' }}
      delay={[800, 0]}
      html={
        <Box width={'190px'} p={8}>
          <Box {...listProps}>
            <Text fontWeight="bold">Keywords:</Text>
            <Text>{capitalize(researchNetworkStatus.adwordsKeywords)}</Text>
          </Box>
          <Box {...listProps}>
            <Text fontWeight="bold">Quick Questions:</Text>
            <Text>{capitalize(researchNetworkStatus.adwordsQuestions)}</Text>
          </Box>
          <Box {...listProps}>
            <Text fontWeight="bold">SERP Data:</Text>
            <Text>{capitalize(researchNetworkStatus.serpData)}</Text>
          </Box>
          <Box {...listProps}>
            <Text fontWeight="bold">SERP Pages:</Text>
            <Text>{capitalize(researchNetworkStatus.serpPages)}</Text>
          </Box>
          <Box {...listProps}>
            <Text fontWeight="bold">Topic Model:</Text>
            <Text>{capitalize(researchNetworkStatus.knowledgeGraph)}</Text>
          </Box>
          <Box {...listProps}>
            <Text fontWeight="bold">SERP Questions:</Text>
            <Text>{capitalize(researchNetworkStatus.serpQuestions)}</Text>
          </Box>
          <Box {...listProps}>
            <Text fontWeight="bold">Connect:</Text>
            <Text>
              {capitalize(researchNetworkStatus.linkingRecommendations)}
            </Text>
          </Box>
        </Box>
      }
      disabled={researchStatus === 'zero' || researchStatus === 'complete'}
    >
      <IconAppStatus status={researchStatus} />
    </Tooltip>
  );
}
