import styled from 'styled-components';

import * as SL from '../SimpleList/themes/dropdown';

const DataListItem = styled(SL.Item).attrs({ truncate: false })`
  width: 100%;
  padding: 0 ${p => p.theme.spacing.regular};
  flex-grow: 1;
  overflow: visible;
  line-height: 18px;
  text-align: left;

  ${p =>
    p.disabled &&
    `
    font-style: italic;
    background: ${p.theme.colors.grey05};
    color: ${p.theme.colors.grey70};
  `}
`;

export default DataListItem;
