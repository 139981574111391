// https://github.com/ckeditor/ckeditor5/blob/08270aafcc6e7e4fb4f4a4d2ebbc7451f031d303/packages/ckeditor5-word-count/src/utils.ts
import type { Element, Item, Node } from '@ckeditor/ckeditor5-engine';

/**
 * Returns a plain text representation of an element and its children.
 *
 * @returns Plain text representing the model's data.
 */
export function modelElementToPlainText(item: Item | Element): string {
  if (item.is('$text') || item.is('$textProxy')) {
    return item.data;
  }

  const element = item as Element;
  let text = '';
  let prev: null | Node = null;

  for (const child of element.getChildren()) {
    const childText = modelElementToPlainText(child);

    // If last block was finish, start from new line.
    if (prev && (prev as Node).is('element')) {
      text += '\n';
    }

    text += childText;

    prev = child;
  }

  return text;
}
