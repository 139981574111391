
// function to determine if formatter initialized correctly
const formatAvailable = f => f && typeof f.format === 'function';

// initialize formatter instances
const formatters = !Intl ? {} : {
  default: new Intl.NumberFormat('en-US'),
};

export default (number, options = {}) => {

  // grab formatter
  const formatter = options.type ?
    formatters[options.type] :
    formatters.default;

  // if formatter not available, return
  if (!formatAvailable(formatter)) return number;

  // try to format, return number on failure
  try { return formatter.format(number); }
  catch(e) { return number; }
}
