import React, { useEffect, useMemo, memo } from 'react';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

import useHotkeys from '../../hooks/useHotKeys';
import * as propTypes from '../../config/propTypes/simpleMenu';

import Tooltip from '../Tooltip/Tooltip';
import SimpleMenuList from './components/SimpleMenuList';

const SimpleMenu = ({
  id,
  label,
  origin,
  children,
  disabled,
  reference,
  items = [],
  hotkeys = [],
  trigger = 'click',
  placement,
  offset,
  childAs,
  style,
}) => {
  const handleHotkeys = useHotkeys(hotkeys);

  useEffect(() => {
    const handleKey = event => {
      handleHotkeys({ event });
    };

    document.addEventListener('keydown', handleKey);
    return () => {
      document.removeEventListener('keydown', handleKey);
    };
  }, [hotkeys]);

  const props = useMemo(() => {
    const val = {
      ...(!isEmpty(origin)
        ? {
            manualOrigin: origin,
            inlinePositioning: false,
            placement:
              window?.innerHeight - origin?.y < window?.innerHeight - 50 &&
              items.length * 40 > window?.innerHeight - origin?.y
                ? 'auto-end'
                : 'right-start',
            visible: true,
            offset: [0, 0],
            hideOnClick: false,
          }
        : {
            trigger,
            placement: 'bottom-start',
            hideOnClick: false,
          }),
    };

    return val;
  }, [trigger, origin?.x, origin?.y, items, window?.innerHeight]);

  const html = useMemo(
    () => (
      <SimpleMenuList
        isParent
        items={items}
        role="menubar"
        aria-label={label}
      />
    ),
    [items, label, origin?.y, origin?.x],
  );
  return (
    <Tooltip
      tippyUsage={'dropdown'}
      tippyTheme={'dark'}
      disabled={disabled || isNil(items)}
      interactive
      childAs={childAs || 'div'}
      style={style}
      id={id}
      reference={reference}
      {...props}
      placement={placement || props.placement}
      offset={offset}
      html={html}
    >
      {children}
    </Tooltip>
  );
};

SimpleMenu.propTypes = propTypes.menu;

export default memo(SimpleMenu);
