import React from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import styled from 'styled-components';
import IconX from 'react-feather/dist/icons/x';

import Clickable from '../../components/Clickable';
import MMBriefIcon from '../../assets/marketmuse-brief.svg';

const i1Top = '8%';
const i1Right = '220px';
const i1Rotate = '315deg';

const i1HoverTop = '11%';
const i1HoverRight = '230px';
const i1HoverRotate = '310deg';

const i2Top = '-14%';
const i2Right = '140px';
const i2Rotate = '350deg';

const i2HoverTop = '-21%';
const i2HoverRight = '150px';
const i2HoverRotate = '352deg';

const i3Bottom = '-6%';
const i3Right = '150px';
const i3Rotate = '330deg';

const i3HoverBottom = '-1%';
const i3HoverRight = '150px';
const i3HoverRotate = '350deg';

const i4Top = '18%';
const i4Right = '80px';
const i4Rotate = '340deg';

const i4HoverTop = '19%';
const i4HoverRight = '90px';
const i4HoverRotate = '330deg';

const i5Bottom = '4%';
const i5Right = '10px';
const i5Rotate = '350deg';

const i5HoverBottom = '-1%';
const i5HoverRight = '5px';
const i5HoverRotate = '355deg';

const Wrapper = styled.div`
  position: relative;
  padding: 40px 200px 40px 40px;
  display: flex;
  align-items: center;
  color: white;
  overflow: hidden;
  height: 100%;

  ${p =>
    p.color === 'grey' &&
    `background-color: ${p.theme.mmxLightGrey} !important;`}
  ${p => p.color === 'red' && `background-color: ${p.theme.mmxRed} !important;`}
  ${p =>
    p.color === 'green' && `background-color: ${p.theme.mmxGreen} !important;`}
  ${p =>
    p.color === 'blue' && `background-color: ${p.theme.mmxBlue} !important;`}
  ${p =>
    p.color === 'yellow' &&
    `background-color: ${p.theme.mmxYellow} !important;`}
  ${p =>
    p.color === 'orange' &&
    `background-color: ${p.theme.mmxOrange} !important;`}
  ${p =>
    p.color === 'purple' &&
    `background-color: ${p.theme.mmxPurple} !important;`}
`;

const Title = styled.div`
  font-size: 22pt;
  font-weight: bolder;
  font-family: ${p => p.theme.type.families.heading};
  flex-shrink: 0;
  min-width: 16%;
  padding-right: 20px;
`;

const Desc = styled.div`
  font-size: 11pt;
  margin-left: 50px;
  width: 60%;
  line-height: 20px;
`;

const Close = styled(Clickable)`
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  width: 25px;
  height: 25px;
  z-index: 9999999;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
    color: ${p => p.theme.mmxGrey};
  }
`;

Close.displayName = 'Close';

const Icons = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`;

const Icon = styled.img`
  width: 55px;
  position: absolute;

  ${p =>
    p.i1 &&
    `
    top: ${i1Top};
    right: ${i1Right};
    transform: rotate( ${i1Rotate});
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  `}
  ${Wrapper}:hover & {
    ${p =>
      p.i1 &&
      `
      top: ${i1HoverTop} !important;
      right: ${i1HoverRight} !important;
      transform: rotate(${i1HoverRotate}) !important;
    `}
  }

  ${p =>
    p.i2 &&
    `
    top: ${i2Top};
    right: ${i2Right};
    transform: rotate(${i2Rotate});
    transition: all 0.6s cubic-bezier(0.35, 0.8, 0.25, 1);
  `}
  ${Wrapper}:hover & {
    ${p =>
      p.i2 &&
      `
      top: ${i2HoverTop} !important;
      right: ${i2HoverRight} !important;
      transform: rotate(${i2HoverRotate}) !important;
    `}
  }

  ${p =>
    p.i3 &&
    `
    bottom: ${i3Bottom};
    right: ${i3Right};
    transform: rotate(${i3Rotate});
    transition: all 0.8s cubic-bezier(0.25, 0.8, 0.25, 1);
  `}
  ${Wrapper}:hover & {
    ${p =>
      p.i3 &&
      `
      bottom: ${i3HoverBottom} !important;
      right: ${i3HoverRight} !important;
      transform: rotate(${i3HoverRotate}) !important;
    `}
  }

  ${p =>
    p.i4 &&
    `
    top: ${i4Top};
    right: ${i4Right};
    transform: rotate(${i4Rotate});
    transition: all 1s cubic-bezier(0.45, 0.8, 0.25, 1);
  `}
  ${Wrapper}:hover & {
    ${p =>
      p.i4 &&
      `
      top: ${i4HoverTop} !important;
      right: ${i4HoverRight} !important;
      transform: rotate(${i4HoverRotate}) !important;
    `}
  }

  ${p =>
    p.i5 &&
    `
    bottom: ${i5Bottom};
    right: ${i5Right};
    transform: rotate(${i5Rotate});
    transition: all 0.6s cubic-bezier(0.45, 0.8, 0.25, 1);
  `}
  ${Wrapper}:hover & {
    ${p =>
      p.i5 &&
      `
      bottom: ${i5HoverBottom} !important;
      right: ${i5HoverRight} !important;
      transform: rotate(${i5HoverRotate}) !important;
    `}
  }
`;

class Banner extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  render() {
    return (
      <Wrapper style={this.props.style} color={this.props.color}>
        {!isNil(this.props.contents) && this.props.contents}
        {!isNil(!this.props.title) && <Title>{this.props.title}</Title>}
        {!isNil(this.props.desc) && <Desc>{this.props.desc}</Desc>}
        {this.props.dismiss && (
          <Close onClick={this.props.dismiss} aria-label={'Close'}>
            <IconX />
          </Close>
        )}
        <Icons>
          <Icon src={MMBriefIcon} i1={true} />
          <Icon src={MMBriefIcon} i2={true} />
          <Icon src={MMBriefIcon} i3={true} />
          <Icon src={MMBriefIcon} i4={true} />
          <Icon src={MMBriefIcon} i5={true} />
        </Icons>
        {!isNil(this.props.children) && this.props.children}
      </Wrapper>
    );
  }
}

Banner.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
  dismiss: PropTypes.func,
  title: PropTypes.any,
  contents: PropTypes.any,
  desc: PropTypes.any,
  style: PropTypes.object,
};

export default Banner;
