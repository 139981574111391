import React from 'react';
import styled from 'styled-components';

import Button from '../Button';
import SimpleGrid from '../SimpleGrid';
import SimpleField from './SimpleField';

const Form = styled.form``;

const SimpleFields = ({
  fields,
  style,
  className,
  handleChange,
  handleSubmit,
  renderSubmit,
  state,
  errors,
  maxWidth = '600px',
}) => (
  <Form style={style} className={className} noValidate={true} handleSubmit={handleSubmit}>
    <SimpleGrid.Container gap={'16px'} columns={2} maxWidth={maxWidth}>
      {fields?.map((formField, index) => (
        <SimpleField
          key={`${index}-${formField.field}`}
          index={index}
          field={formField}
          state={state}
          errors={errors}
          handleChange={handleChange}
        />
      ))}
      <SimpleGrid.Item span={2} order={fields?.length ? fields.length + 100 : 100}>
        {renderSubmit || (
          <Button type={'submit'} onClick={handleSubmit}>
            Submit
          </Button>
        )}
      </SimpleGrid.Item>
    </SimpleGrid.Container>
  </Form>
);

export default SimpleFields;
