import { Suspense } from 'react';

import { lazyLoad } from '@marketmuse/utilities';
import { PAPI } from '@marketmuse/config/types';

import ScreenLoader from '../components/ScreenLoader';
import withAccess from '../hocs/withAccess';
import withTailwindCompatibility from '../hocs/withTailwindCompatibility';
import withSiteIdProp from '../hocs/withSiteIdProp';

const DocumentsTable = withAccess(
  withTailwindCompatibility({
    Component: lazyLoad(
      () =>
        import(
          /* webpackChunkName: 'DocumentsTable' */ '@marketmuse/containers/Documents/DocumentsDisplayTable/DocumentsDisplayTable'
        ),
    ),
    style: { height: '100%' },
  }),
  {
    allow: [
      {
        resource: PAPI.PermissionResource.DOCUMENTS,
        action: PAPI.PermissionAction.ACCESS_DATA,
      },
    ],
  },
);
const DocumentSingle = withAccess(
  withTailwindCompatibility({
    Component: lazyLoad(
      () =>
        import(
          /* webpackChunkName: 'DocumentSingle' */ '@marketmuse/containers/Documents/DocumentSingle/DocumentSingle'
        ),
    ),
    style: { height: '100%' },
  }),
  {
    allow: [
      {
        resource: PAPI.PermissionResource.DOCUMENTS,
        action: PAPI.PermissionAction.ACCESS_DATA,
      },
    ],
  },
);

const DocumentTableWithSiteId = withSiteIdProp(DocumentsTable);

export const orgRoutes = [
  {
    path: '/a/:slug/documents',
    index: true,
    element: (
      <Suspense fallback={<ScreenLoader.Light unwrapped={true} />}>
        <DocumentsTable />
      </Suspense>
    ),
  },
  {
    path: '/a/:slug/documents/:documentId',
    index: true,
    element: (
      <Suspense fallback={<ScreenLoader.Light unwrapped={true} />}>
        <DocumentSingle />
      </Suspense>
    ),
  },
];

export const siteRoutes = [
  {
    path: '/a/:slug/:siteId/documents',
    index: true,
    element: (
      <Suspense fallback={<ScreenLoader.Light unwrapped={true} />}>
        <DocumentTableWithSiteId />
      </Suspense>
    ),
  },
  {
    path: '/a/:slug/:siteId/documents/:documentId',
    element: (
      <Suspense fallback={<ScreenLoader.Light unwrapped={true} />}>
        <DocumentSingle />
      </Suspense>
    ),
  },
];
