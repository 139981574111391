import { useCallback } from 'react';
import type { GenerationContextInput } from '@marketmuse/config/types/papi';
import { useExportHeatmapLazyQuery } from '@marketmuse/data-papi';
import { toast } from '@marketmuse/components';
import { createDownload } from '@marketmuse/utilities';
import { ExportHeatmapPayload } from '../types';
import useToaster from './useToaster';

type UseExportHeatmapArgs = GenerationContextInput & {
  canExport: boolean;
};

const useExportHeatmap = ({ canExport, term, url }: UseExportHeatmapArgs) => {
  const { premiumFeatureToast, exportSuccessfulToast } = useToaster();

  const [exportHeatmap, { loading }] = useExportHeatmapLazyQuery({
    fetchPolicy: 'no-cache',
    errorPolicy: 'none',
    notifyOnNetworkStatusChange: true,
    context: {
      omitTypename: true,
    },
    onError: () => {
      toast.error('Sorry, we were unable to export the Heatmap.');
    },
    onCompleted: data => {
      if (data?.exportHeatmap?.data) {
        exportSuccessfulToast();
        createDownload(data.exportHeatmap.data, `heatmap-${term}`);
      }
    },
  });

  const handler = useCallback(
    ({
      knowledgeGraphItems,
      organicSerpItemPageKgData,
      filterContext,
      userProvidedPage,
    }: ExportHeatmapPayload) => {
      if (!canExport) {
        premiumFeatureToast();
        return;
      }

      exportHeatmap({
        variables: {
          generationContext: { term, url },
          knowledgeGraphItems,
          organicSerpItemPageKgData,
          userProvidedPage,
          filterContext,
        },
      });
    },
    [canExport, exportHeatmap, term, url, premiumFeatureToast],
  );

  return { handler, loading };
};

export default useExportHeatmap;
