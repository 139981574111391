import { FC } from 'react';
import { Box, Button, Text } from '@marketmuse/components';
import { ReactComponent as Image } from './assets/upsell-heatmap.svg';

interface CollateralHeatmapProps {
  onUpgrade?: (event: MouseEvent) => void;
}

const CollateralHeatmap: FC<CollateralHeatmapProps> = ({ onUpgrade }) => {
  return (
    <Box className={['flex', 'flex-row']}>
      <Box
        className={[
          'w-1/2',
          'flex',
          'flex-row',
          'items-center',
          'justify-center',
          'py-6',
        ]}
      >
        <Image />
      </Box>
      <Box
        className={[
          'w-1/2',
          'flex',
          'flex-col',
          'items-center',
          'justify-center',
          'text-center',
        ]}
      >
        <Text className={['prose', 'text-xl', 'font-bold', 'mb-2.5']} as="h2">
          Analyze search results
        </Text>
        <Text className={['prose', 'text-base', 'mb-4']} as="p">
          See more search engine results, assess the quality of more content on
          top ranking pages and those pages use of important topics.
        </Text>
        <Button variant="blue400" onClick={onUpgrade}>
          Upgrade My Plan
        </Button>
      </Box>
    </Box>
  );
};

export default CollateralHeatmap;
