import get from 'lodash/get';

import { GuestAccessCreateDocument as GUEST_ACCESS_CREATE } from '@marketmuse/data-papi';
import makeRequest from '../../utils/makeRequest';
import * as types from '../../config/types';

export default () => () => next => action => {
  if (action.type === types.GUEST_ACCESS_CREATE) {
    makeRequest({
      type: types.GUEST_ACCESS_CREATE,
      mutation: GUEST_ACCESS_CREATE,
      variables: {
        siteId: action.siteId,
        purpose: action.purpose,
        tokenOptions: action.tokenOptions,
      },
    }).then(res => {
      const data = get(res, 'guestAccessCreate') || {};
      if (typeof action.callback === 'function') action.callback(data);
      return data;
    });
  }

  return next(action);
};
