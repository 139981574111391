import {
  styledComponentProps,
  boxVariantTypes,
  flexTypes,
  gridTypes,
  positionTypes,
  paddingTypes,
  marginTypes,
  widthTypes,
  heightTypes,
  colorTypes,
} from './styleSystem';

export default {
  ...styledComponentProps,
  ...boxVariantTypes,
  ...flexTypes,
  ...gridTypes,
  ...positionTypes,
  ...paddingTypes,
  ...marginTypes,
  ...widthTypes,
  ...heightTypes,
  ...colorTypes,
};
