import { get, isUndefined, isEmpty, isNull } from 'lodash';
import { stripUrl } from '@marketmuse/utilities';
import type { KnowledgeGraph } from '@marketmuse/config/types/papi';

const serializeKnowledgeGraphResponse = (knowledgeGraph: KnowledgeGraph) => {
  const {
    organicSerpItemPagesKgData = [],
    knowledgeGraphItems = [],
    userProvidedPage = {},
    metrics,
  } = knowledgeGraph;

  const resKnowledgeGraphItems = knowledgeGraphItems.filter(
    ({ term }) => !isUndefined(term) && !isEmpty(term) && !isNull(term),
  );

  const resUserProvidedPage = {
    ...userProvidedPage,
    url: stripUrl(get(userProvidedPage, 'url')),
  };

  const resOrganicSerpItemPagesKgData = organicSerpItemPagesKgData
    .map(item => ({
      ...item,
      url: stripUrl(get(item, 'url')),
    }))
    .sort(({ position: a }, { position: b }) => {
      if (a !== 0 && b === 0) {
        return -1;
      } else if (a === 0 && b === 0) {
        return 1;
      } else {
        return a - b;
      }
    })
    .slice(0, 20);

  const data = {
    userProvidedPage: !isEmpty(userProvidedPage) ? resUserProvidedPage : void 0,
    knowledgeGraphItems: resKnowledgeGraphItems,
    organicSerpItemPagesKgData: resOrganicSerpItemPagesKgData,
    metrics: metrics,
  };

  return data;
};

export default serializeKnowledgeGraphResponse;
