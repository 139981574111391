export const htmlToWord = ({ data }: { data: string }) => {
  const preHtml = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'></head><body>`;
  const postHtml = '</body></html>';
  const doc = preHtml + data + postHtml;

  const blob = new Blob(['\ufeff', doc], {
    type: 'application/msword',
  });

  return blob;
};
