import { RankArgs } from '../types';
import calculate from './calculate';

const backgroundColor = (...args: RankArgs) => {
  const color = calculate(...args);
  if (color === 'gray') return 'text-gray-600';
  return 'text-white';
};

export default backgroundColor;
