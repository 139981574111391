import isNil from 'lodash/isNil';
import { ContentScoreCalculateFn } from '../types';

const calculate: ContentScoreCalculateFn = (
  contentScore,
  average,
  target,
  highest,
) => {
  if (
    isNil(contentScore) ||
    isNil(average) ||
    isNil(highest) ||
    !average ||
    !highest
  ) {
    return 'gray';
  }

  if (contentScore < average) return 'red';
  if (contentScore < target) return 'yellow';
  if (contentScore < highest) return 'green';
  return 'blue';
};

export default calculate;
