import { FC } from 'react';
import cx from 'classnames';
import { Icon, SUPPORTED_ICON } from '../../../Icon';

type ToastIconProps = {
  iconName: SUPPORTED_ICON;
  color: string;
};

const ToastIcon: FC<ToastIconProps> = ({ iconName, color }) => {
  return (
    <div
      className={cx([
        'border-4',
        'border-opacity-50',
        'flex-none',
        'flex',
        'h-[42px]',
        'items-center',
        'justify-center',
        'rounded-full',
        'w-[42px]',
        `border-${color}`,
      ])}
    >
      <div
        className={cx([
          'w-full',
          'h-full',
          'flex',
          'items-center',
          'justify-center',
          'rounded-full',
          `bg-${color}`,
        ])}
      >
        <Icon name={iconName} boxSize={21} color="text-white" />
      </div>
    </div>
  );
};

export default ToastIcon;
