import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { SLRow, SLItem } from '../Optimize/shared/List';

const fill = Array(20).fill({});

const ToolkitTabTopics = ({ appData, loading, functions }) => {
  const items = useMemo(
    () =>
      loading
        ? fill
        : (appData?.qts?.suggestedSubHeadingTerms || [])?.map(i => ({
            ...i,
            title: i.term,
          })),
    [appData?.qts?.suggestedSubHeadingTerms, loading],
  );

  const render = useMemo(
    () => (
      <>
        <SLRow header sticky style={{ top: 0, zIndex: 999999 }}>
          <SLItem header grow>
            Related Topics
          </SLItem>
        </SLRow>
        {items.map((item, i) => (
          <SLRow
            key={`item-${item?.title || i}`}
            data-mms--sidebar-item={`item-${i + 1}`}
          >
            <SLItem
              grow
              pr={0}
              pl={0}
              loading={loading}
              draggable
              onDragStart={functions.onItemDragStart.bind(this, item)}
            >
              {(item.title || '').toLowerCase()}
            </SLItem>
          </SLRow>
        ))}
      </>
    ),
    [items, loading, functions],
  );

  return (
    <div data-mms--brief-subheadings-sidebar-content="topics">{render}</div>
  );
};

ToolkitTabTopics.propTypes = {
  appData: PropTypes.object,
  moveForwardTo: PropTypes.func,
  moveBackTo: PropTypes.func,
};

export default ToolkitTabTopics;
