import { isPageUrl } from '@marketmuse/utilities';
import { optimizeTaskStatusCheck, optimizeTaskFetchPage } from '../../actions';
import { networkSlice, uiSlice } from '../../slices';
import { AppQueryNetworkStatus, ReduxListener } from '../../../types';
import { getPageContents } from '../../thunks';

const listeners = (startListening: ReduxListener) => {
  const networkActions = networkSlice.actions;
  const uiActions = uiSlice.actions;
  startListening({
    actionCreator: optimizeTaskFetchPage,
    effect: async (action, { dispatch }) => {
      const { url } = action.payload;

      const { valid } = isPageUrl(url);

      if (valid) {
        await Promise.all([dispatch(getPageContents({ url }))]);
      }
    },
  });
  startListening({
    actionCreator: getPageContents.pending,
    effect: async (action, { dispatch }) => {
      await dispatch(
        networkActions.pageContentsSet(AppQueryNetworkStatus.loading),
      );
    },
  });

  startListening({
    actionCreator: getPageContents.rejected,
    effect: async (action, { dispatch }) => {
      const { aborted } = action.meta;
      if (!aborted) {
        await Promise.all([
          dispatch(networkActions.pageContentsSet(AppQueryNetworkStatus.error)),
        ]);
      }

      // check status after other steps
      await dispatch(optimizeTaskStatusCheck());
    },
  });

  startListening({
    actionCreator: getPageContents.fulfilled,
    effect: async ({ payload }, { dispatch }) => {
      await Promise.all([
        dispatch(uiActions.contentsSet(payload.data)),
        dispatch(
          networkActions.pageContentsSet(AppQueryNetworkStatus.complete),
        ),
      ]);
    },
  });
};

export default listeners;
