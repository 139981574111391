import { ApolloLink, NextLink, Operation } from '@apollo/client';

import { omitWalkDeepFromDict } from '@marketmuse/utilities';

export const cleanTypenameLink = new ApolloLink(
  (operation: Operation, forward: NextLink) => {
    const shouldOmitTypename = operation.getContext()?.omitTypename;

    if (operation.variables && shouldOmitTypename) {
      // eslint-disable-next-line
      operation.variables = omitWalkDeepFromDict(operation.variables, [
        '__typename',
      ]);
    }

    return forward(operation);
  },
);
