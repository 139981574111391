export const isClickable = props => {
  return (
    !props.disabled &&
    !props.clickDisabled &&
    !props.hide &&
    (
      props.href ||
      typeof props.onClick === 'function'
    )
  );
}
