import { isEmpty } from 'lodash';
import {
  isPageUrl,
  sanitizeTerm,
  sanitizePageUrl,
  validateFocusTerm,
} from '@marketmuse/utilities';
import { AppQueryParams } from '../types';

function validateAppParams<T extends AppQueryParams>(params: T) {
  const term = sanitizeTerm(params?.term || '', params.language);
  const url = sanitizePageUrl(params?.url || '');

  if (!validateFocusTerm(term)) {
    return false;
  }

  if (!isEmpty(url)) {
    return isPageUrl(url).valid;
  }

  return true;
}

export default validateAppParams;
