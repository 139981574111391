import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import LoadingBlock from '../../../components/LoadingBlock';
import SidebarItem from './SidebarItem';

const StyledLoadingBlock = styled(LoadingBlock)`
  margin-top: 12px;
`;

const SidebarStatusFilters = ({ collapsed, labelLoading, filters = [] }) => {
  return (
    <>
      <SidebarItem subheader title="STATUS FILTERS" collapsed={collapsed} />
      {!labelLoading &&
        filters.map(filter => (
          <SidebarItem
            key={filter.title}
            isFilter
            order={filter.order}
            icon={filter.icon}
            title={filter.title}
            label={filter.label}
            activeLabelBackground={filter.activeLabelBG}
            active={filter.isActive}
            collapsed={collapsed}
            onClick={filter.onClick}
          />
        ))}
      {labelLoading && (
        <>
          <StyledLoadingBlock loadingTheme="dark" />
          <StyledLoadingBlock loadingTheme="dark" />
          <StyledLoadingBlock loadingTheme="dark" />
          <StyledLoadingBlock loadingTheme="dark" />
        </>
      )}
    </>
  );
};

SidebarStatusFilters.propTypes = {
  filters: PropTypes.array,
  isLoading: PropTypes.bool,
  collapsed: PropTypes.bool,
  labelLoading: PropTypes.bool,
};

export default SidebarStatusFilters;
