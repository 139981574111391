import { AppQueryNetworkStatus, ReduxListener } from '../../../types';

import { researchTaskStatusCheck } from '../../actions';
import { dataSlice, networkSlice } from '../../slices';
import { getSerpQuestions } from '../../thunks';

const listeners = (startListening: ReduxListener) => {
  const dataActions = dataSlice.actions;
  const networkActions = networkSlice.actions;

  startListening({
    actionCreator: getSerpQuestions.pending,
    effect: async (action, { dispatch }) => {
      await dispatch(
        networkActions.serpQuestionsSet(AppQueryNetworkStatus.loading),
      );
    },
  });
  startListening({
    actionCreator: getSerpQuestions.rejected,
    effect: async (action, { dispatch }) => {
      const { aborted } = action.meta;
      if (!aborted) {
        await Promise.all([
          dispatch(
            networkActions.serpQuestionsSet(AppQueryNetworkStatus.error),
          ),
          dispatch(
            networkActions.inventoryTopicsSerpQuestionsSet(
              AppQueryNetworkStatus.inactive,
            ),
          ),
        ]);

        // check status after other steps
        await dispatch(researchTaskStatusCheck());
      }
    },
  });

  startListening({
    actionCreator: getSerpQuestions.fulfilled,
    effect: async ({ payload }, { dispatch }) => {
      await Promise.all([
        dispatch(dataActions.serpQuestionsSet(payload.data)),
        dispatch(
          networkActions.serpQuestionsSet(AppQueryNetworkStatus.complete),
        ),
      ]);

      // check status after other steps
      await dispatch(researchTaskStatusCheck());
    },
  });
};

export default listeners;
