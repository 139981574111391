import { appResearchSlices } from '@marketmuse/data-state/appResearch';
import { appOptimizeSlices } from '@marketmuse/data-state/appOptimize';

import { serializeToAppData } from '@marketmuse/utilities';

import { setAppsReducer } from '../../actions/toolsActions';
import { setSimple } from '../../actions/miscActions.js';
import * as types from '../../config/types';
import appConfigKey from '../../utils/applications/appConfigKey';

export default () =>
  ({ dispatch, getState }) =>
  next =>
  action => {
    const state = getState();

    // request
    if (action.type === types.SET_APPS_DATA_HYDRATING) {
      const params = action.params;
      const key = appConfigKey(params);
      const siteId = state?.filter?.site?.id || action.siteId;

      try {
        Promise.all([
          // keep as promise chain in case additional data is needed
          fetch(
            `https://mmx-onboarding.s3.amazonaws.com/${params.topic}.json`,
          ).then(response => response.json()),
        ]).then(results => {
          const {
            seoAdwordsKeywords,
            seoAdwordsQuestions,
            seoKnowledgeGraph,
            seoBulkAdwordsKeywords,
            seoPageProcessor,
            seoSerpData,
            seoSerpPageData,
            seoSerpQuestions,
            seoSerpUserIntentData,
          } = results.reduce((acc, curr) => ({ ...acc, ...(curr || {}) }), {});

          const data = serializeToAppData({
            knowledgeGraphItems: seoKnowledgeGraph.knowledgeGraphItems,
            metrics: seoKnowledgeGraph.metrics,
            organicSerpItemPagesKgData:
              seoKnowledgeGraph.organicSerpItemPagesKgData,
            userProvidedPage: seoKnowledgeGraph.userProvidedPage,
            bulkAdwordsKeywords: seoBulkAdwordsKeywords,
          });
          const payload = {
            optimize: key,
            [key]: {
              date: Date.now(),
              params,
              data,
            },
          };

          const researchUiActions = appResearchSlices.uiSlice.actions;
          const researchNetworkActions = appResearchSlices.networkSlice.actions;
          const researchDataActions = appResearchSlices.dataSlice.actions;

          dispatch(researchDataActions.adwordsKeywordsSet(seoAdwordsKeywords));
          dispatch(
            researchDataActions.adwordsQuestionsSet(seoAdwordsQuestions),
          );
          dispatch(researchDataActions.knowledgeGraphSet(seoKnowledgeGraph));
          dispatch(
            researchDataActions.bulkAdwordsKeywordsSet(seoBulkAdwordsKeywords),
          );
          dispatch(researchDataActions.serpDataSet(seoSerpData));
          dispatch(researchDataActions.serpPagesSet(seoSerpPageData));
          dispatch(researchDataActions.serpQuestionsSet(seoSerpQuestions));
          dispatch(
            researchDataActions.serpUserIntentDataSet(seoSerpUserIntentData),
          );

          dispatch(
            researchNetworkActions.setMany({
              keys: [
                'adwordsKeywords',
                'adwordsQuestions',
                'knowledgeGraph',
                'bulkAdwordsKeywords',
                'inventoryTopicsKeywords',
                'inventoryTopicsKG',
                'inventoryTopicsBulkKeywords',
                'inventoryTopicsQuestions',
                'inventoryTopicsSerpQuestions',
                'linkingRecommendations',
                'serpData',
                'serpPages',
                'serpQuestions',
                'serpUserIntentData',
              ],
              status: 'complete',
            }),
          );
          dispatch(
            researchUiActions.appQuerySet({
              id: '',
              status: 'COMPLETED',
              country: 'US',
              language: 'EN',
              term: params.topic,
              url: params.url,
            }),
          );
          dispatch(
            researchUiActions.paramsAndStatusUpdate({
              params: {
                term: params.topic,
                url: params.url,
                country: 'US',
                language: 'EN',
                siteId,
                networkDomains: [],
                competitorDomains: [],
              },
              status: 'complete',
            }),
          );

          const optimizeUiActions = appOptimizeSlices.uiSlice.actions;
          const optimizeNetworkActions = appOptimizeSlices.networkSlice.actions;
          const optimizeDataActions = appOptimizeSlices.dataSlice.actions;

          dispatch(optimizeDataActions.knowledgeGraphSet(seoKnowledgeGraph));
          dispatch(
            optimizeDataActions.bulkAdwordsKeywordsSet(seoBulkAdwordsKeywords),
          );

          dispatch(
            optimizeNetworkActions.setMany({
              keys: ['knowledgeGraph', 'bulkAdwordsKeywords', 'pageContents'],
              status: 'complete',
            }),
          );

          dispatch(
            optimizeUiActions.appQuerySet({
              id: '',
              status: 'COMPLETED',
              country: 'US',
              language: 'EN',
              term: params.topic,
              url: params.url,
            }),
          );
          dispatch(
            optimizeUiActions.paramsAndStatusSet({
              params: {
                term: params.topic,
                url: params.url,
                country: 'US',
                language: 'EN',
                siteId,
                networkDomains: [],
                competitorDomains: [],
              },
              status: 'complete',
            }),
          );

          if (seoPageProcessor?.html) {
            const key = `optimize-app-${siteId}_html`;
            dispatch(
              setSimple({
                key,
                value: seoPageProcessor?.html,
                persist: true,
              }),
            );

            dispatch(
              appOptimizeSlices.uiSlice.actions.contentsSet(
                seoPageProcessor?.html,
              ),
            );
          }

          dispatch(setAppsReducer({ payload }));

          if (action.callback) action.callback(payload);
        });
      } catch (error) {
        console.error(error);
      }
    }

    return next(action);
  };
