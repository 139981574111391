import { get, trim } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { createSelector } from '@reduxjs/toolkit';

import { useStoreSelector } from '@marketmuse/data-state/hooks';
import { RootState } from '@marketmuse/data-state/types';
import { sanitizeOrgSlug } from '@marketmuse/utilities';

import { NavigateArgs } from './types';
import { addRouteParams } from './utils';

const selectSlug = (state: RootState) => get(state, 'user.org.slug');

const selectSanitizedSlug = createSelector([selectSlug], slug =>
  sanitizeOrgSlug(slug || ''),
);

export const useSuiteNavigate = () => {
  const navigate = useNavigate();
  const orgSlug = useStoreSelector(selectSanitizedSlug);
  const siteId = useStoreSelector(state => state.filter?.site?.id);

  return (
    pathUnsafe: string,
    {
      newWindow = false,
      omitSiteId = false,
      omitOrgSlug = false,
      replace,
      root,
      state,
    }: NavigateArgs = {},
  ) => {
    const path = trim(pathUnsafe, '/');
    if ([pathUnsafe, path].includes('undefined')) {
      return;
    }

    const fullPath = root
      ? path
      : addRouteParams({
          omitOrgSlug,
          omitSiteId,
          orgSlug,
          pathSuffix: path,
          siteId,
        });

    if (newWindow) {
      window.open(`${window.location.origin}/${fullPath}`);
    } else {
      navigate(`/${fullPath}`, { replace: Boolean(replace), state });
    }
  };
};
