import React, { memo } from 'react';
import PropTypes from 'prop-types';

import FilterToggle from './filterItems/FilterToggle';
import FilterSwitch from './filterItems/FilterSwitch';
import FilterDropdown from './filterItems/FilterDropdown';
import FilterText from './filterItems/FilterText';

import { FilterWrapper } from './styles';

export const Filter = ({
  type,
  style,
  placeholder,
  label,
  value,
  values,
  onChange,
  tags,
}) => (
  <FilterWrapper style={style}>
    {type === 'toggle' && <FilterToggle values={values} onChange={onChange} />}
    {type === 'switch' && <FilterSwitch values={values} onChange={onChange} />}
    {type === 'dropdown' && (
      <FilterDropdown
        values={values}
        tags={tags}
        placeholder={placeholder}
        label={label}
        onChange={onChange}
      />
    )}
    {type === 'text' && (
      <FilterText value={value} placeholder={placeholder} onChange={onChange} />
    )}
  </FilterWrapper>
);

Filter.propTypes = {
  style: PropTypes.object,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  values: PropTypes.array,
  onChange: PropTypes.func,
};

export default memo(Filter);
