import { ApolloQueryResult } from '@apollo/client';
import type { SeoConnectQuery } from '@marketmuse/config/types/papi';
import { SeoConnectDocument } from '@marketmuse/data-papi';
import {
  ResearchDataInput,
  ResearchThunkSeoRes,
  ThunkApiConfig,
} from '../../types';
import createNonConcurrentAsyncThunk from '../../utils/createNonConcurrentAsyncThunk';

const getLinkingRecommendations = createNonConcurrentAsyncThunk<
  ResearchThunkSeoRes,
  ResearchDataInput,
  ThunkApiConfig
>(
  'runs/getLinkingRecommendations',
  async (params, { extra, rejectWithValue }) => {
    const response: ApolloQueryResult<SeoConnectQuery> =
      await extra.apollo.query({
        query: SeoConnectDocument,
        variables: {
          ...params,
          domain: params.url,
          url: void 0,
        },
      });

    const error = response.errors;
    if (error) {
      return rejectWithValue(error);
    }

    return {
      data: response?.data?.seoConnect,
      path: 'linkingRecommendations',
      params,
    };
  },
);

export default getLinkingRecommendations;
