import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ModalHeaderWrapper } from './ModalHeader';

const ModalBody = styled.div`
  order: 4;
  padding: ${p => p.theme.spacing.large};
  ${p => p.theme.type.textRegular};
  ${p => p.theme.mixins.applyMargin};
  ${p => p.theme.mixins.applyPadding};
  ${p => p.theme.mixins.applyFlex};
  ${p => p.theme.mixins.applyGrid};

  ${ModalHeaderWrapper} ~ & {
    padding-top: 0;
  }
`;


ModalBody.propTypes = {
  children: PropTypes.any,
};

export default ModalBody;
