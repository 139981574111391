export default {
  animation: 'fade',
  appendTo: () => document.getElementById('mms--tippy-root'),
  arrow: false,
  delay: 0,
  duration: [20, 20],
  offset: [0, 2],
  hideOnClick: true,
  inertia: false,
  interactive: false,
  interactiveBorder: 4,
  maxWidth: 'none',
  popperOptions: {
    modifiers: [
      { name: 'flip', options: { rootBoundary: 'viewport' } },
      { name: 'preventOverflow', options: { rootBoundary: 'viewport' } },
    ],
  },
  // https://atomiks.github.io/tippyjs/v6/all-props/#placement
  placement: 'bottom-start',
  theme: 'dark',
  touch: false,
  trigger: 'mouseenter focus',
  zIndex: 9999,
};
