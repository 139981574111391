import * as Sentry from '@sentry/react';
import { cloneDeep, pick, unset } from 'lodash';

const savedViewsInclude = [
  'PAGE_TOPICS',
  'PAGES',
  'TOPICS',
  'PROJECTS',
  'PLANS',
  'BRIEFS',
  'SAVED_WRITING',
]
  .map(view =>
    [
      'current.id',
      'current.filters',
      'current.sorting',
      'current.tableUiConfig',
    ].map(segment => [view, segment].join('.')),
  )
  .flat();

const userInclude = [
  'createdAt',
  'defaultApplicationSerpCountry',
  'displayExactDistribution',
  'email',
  'firstName',
  'id',
  'lastName',
  'onboardingStep',
  'org.id',
  'org.name',
  'org.sfdcAccountId',
  'orgRole',
  'sfdcContactId',
  'suspended',
  'updatedAt',
].map(segment => ['user', segment].join('.'));

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: action => {
    const actionSafe = cloneDeep(action);
    if (
      ['persist/REHYDRATE', 'persist/PERSIST', '_success'].includes(
        actionSafe.type,
      )
    ) {
      return null;
    }
    if (actionSafe.type === 'REQUEST') {
      unset(actionSafe, 'data.query');
      unset(actionSafe, 'mutation');
    }
    unset(actionSafe, 'callback');

    return actionSafe;
  },
  stateTransformer: state => {
    const next = pick(cloneDeep(state), [
      'appOptimize.network',
      'appOptimize.ui',
      'appResearch.ui',
      'appResearch.network',
      'appResearch.views',
      'filter.site',
      'filter.versions',
      'guestAccess',
      'misc.counts',
      'payment.account.code',
      'payment.account.company',
      'payment.account.username',
      ...userInclude,
      ...savedViewsInclude,
    ]);

    return next;
  },
});

export default sentryReduxEnhancer;
