import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  flex-shrink: 0;
  display: inline-block;
  text-transform: uppercase;
  color: white;
  background-color: ${p => p.theme.mmxDarkGrey};
  font-size: 8pt;
  border-radius: 2px;
  padding: 1px 5px;
`;

const Title = ({ children }) => (
  <Wrapper>
    {children}
  </Wrapper>
);

Title.propTypes = {
  children: PropTypes.any,
};

export default Title;
