import { useCallback, useState, useMemo } from 'react';
import * as Rules from '../../utils/inputValidation';

import { phoneField } from './config';
import { getFieldsValidation } from './utils';

const useFields = ({
  initialState = {},
  initialErrors = {},
  fields = [],
  phone = false,
}) => {
  const [valueState, setState] = useState(initialState);
  const [errors, setErrors] = useState(initialErrors);
  // no async errors at the moment of initialization
  const [asyncErrors, setAsyncErrors] = useState({});

  const formFields = useMemo(() => {
    return [...(fields && fields), ...[phone && phoneField]]
      .filter(Boolean)
      .sort((a, b) => a.order - b.order);
  }, [fields, phone]);

  const formValidations = useMemo(() => {
    return getFieldsValidation(formFields);
  }, [formFields]);

  const allErrors = useMemo(() => ({ ...errors, ...asyncErrors }), [errors, asyncErrors]);

  const setValueState = useCallback(
    ({ name, value }) => {
      setState(lastState => {
        const nextState = { ...lastState, [name]: value };
        const nextErrors = Rules.reduceRule(nextState, Object.values(formValidations));
        setErrors(nextErrors);
        return nextState;
      });
    },
    [formValidations]
  );

  const setFormState = useCallback(
    state => {
      const nextErrors = Rules.reduceRule(state, Object.values(formValidations));
      setState(state);

      setErrors(nextErrors);
    },
    [formValidations]
  );

  return [
    formFields,
    formValidations,
    [valueState, setValueState, setFormState],
    [allErrors, setErrors, setAsyncErrors],
  ];
};

export default useFields;
