import { useMemo } from 'react';
import { allowedLanguages } from '../../../config/countryLanguageCombinations';
import hideTooltip from '../../../utils/hideTooltip';

export function useLanguages({ country, language, setCountryLanguage }) {
  return useMemo(() => {
    if (!setCountryLanguage) {
      return [];
    }
    const serializedLanguages = allowedLanguages.map(l => ({
      ...l,
      disabled: !country?.languages?.find(({ id }) => id === l?.id),
    }));

    return serializedLanguages?.map(({ id, title, disabled }) => ({
      id,
      title,
      tags: { 'data-mms--app-language-option': id },
      active: id === language?.id,
      disabled,
      onClick: () => {
        hideTooltip('mms--app-language-dropdown');
        setCountryLanguage({ country: country.id, language: id });
      },
    }));
  }, [country, language, setCountryLanguage]);
}
