import { setContext } from '@apollo/client/link/context';

import { TOKEN_KEYS } from '@marketmuse/config/configs';

interface SessionManager extends Record<string, any> {
  id: string;
  getToken: () => Promise<string>;
  [key: string]: any;
}

function getTokens() {
  let guestToken = '';
  let ghostToken = '';

  if (typeof window !== 'undefined') {
    guestToken = window?.sessionStorage?.getItem(TOKEN_KEYS.GUEST) || '';
    ghostToken = window?.sessionStorage?.getItem(TOKEN_KEYS.GHOST) || '';
  }

  return { guestToken, ghostToken };
}

function getSessionHeaders(
  getSessionManager?: () => SessionManager,
): Promise<{ bearerToken: string | void; sessionId: string | void }> {
  const sessionManager = getSessionManager?.();
  if (!sessionManager) {
    return Promise.resolve({
      bearerToken: void 0,
      sessionId: void 0,
    });
  }
  return sessionManager.getToken().then(bearerToken => {
    const sessionId = sessionManager?.id;

    return {
      bearerToken,
      sessionId,
    };
  });
}

export const authLink = ({
  getSessionManager,
}: {
  getSessionManager?: () => SessionManager;
} = {}) => {
  return setContext((_, { headers = {} }) => {
    const { guestToken, ghostToken } = getTokens();

    return new Promise(resolve => {
      return getSessionHeaders(getSessionManager).then(
        ({ bearerToken, sessionId }) => {
          const authHeaders = {
            ...(guestToken ? { [TOKEN_KEYS.GUEST]: guestToken } : {}),
            ...(ghostToken ? { [TOKEN_KEYS.GHOST]: ghostToken } : {}),
            ...(bearerToken
              ? { [TOKEN_KEYS.BEARER]: `Bearer ${bearerToken}` }
              : {}),
            ...(sessionId ? { [TOKEN_KEYS.SESSION]: btoa(sessionId) } : {}),
          };

          resolve({
            headers: Object.assign({}, headers, authHeaders),
          });
        },
      );
    }).catch(reason => console.error(reason));
  });
};
