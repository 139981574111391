import { ReactNode, forwardRef, Ref } from 'react';
import { twMerge } from 'tailwind-merge';
import { Box } from '../../Box';

export interface IPropsLayout {
  className?: string | Array<string | string[]>;
  children: ReactNode;
}

const TableLayout = forwardRef(
  ({ className, children }: IPropsLayout, ref: Ref<HTMLDivElement>) => {
    return (
      <Box
        className={twMerge([
          'flex',
          'flex-grow',
          'flex-col',
          'h-full',
          'min-w-full',
          'overflow-y-scroll',
          className,
        ])}
        ref={ref}
      >
        {children}
      </Box>
    );
  },
);

export default TableLayout;
