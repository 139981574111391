import { trim, toLower } from 'lodash';
import { Language } from '@marketmuse/config/types/papi';

export const sanitizeTerm = (term: string, language?: Language) => {
  let sanitized = trim(term);
  sanitized = toLower(sanitized);

  const lastCharacter = sanitized.slice(-1);
  if (['!', ',', '?', '.'].includes(lastCharacter)) {
    sanitized = sanitized.substring(0, sanitized.length - 1);
  }

  if (language === Language.EN) {
    sanitized = sanitized.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  return sanitized;
};
