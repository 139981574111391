import { isEmpty } from 'lodash';
import { getCookie } from './getCookie';
class GoogleClient {
  private static instance: GoogleClient;
  private client;
  private accessToken;
  private onTokenClientInit;

  private constructor(clientId) {
    this.accessToken = getCookie('googleAccessToken');

    this.initGSI(clientId);
  }

  public static getInstance(clientId: string): GoogleClient {
    if (!GoogleClient.instance) {
      GoogleClient.instance = new GoogleClient(clientId);
    }
    return GoogleClient.instance;
  }

  private initGSI(clientId) {
    this.client = window?.google?.accounts?.oauth2.initTokenClient({
      client_id: clientId,
      scope: 'https://www.googleapis.com/auth/drive.file',
      callback: tokenResponse => {
        this.accessToken = tokenResponse.access_token;
        document.cookie = `googleAccessToken=${tokenResponse.access_token}; max-age=${tokenResponse.expires_in}; path=/;`;

        if (typeof this.onTokenClientInit === 'function') {
          this.onTokenClientInit();
        }
      },
    });
  }

  public getClient() {
    return this.client;
  }

  public getToken() {
    return this.accessToken;
  }

  public requestAccessToken() {
    this.client.requestAccessToken();
  }

  public isAuthorized() {
    return !isEmpty(this.accessToken);
  }

  public setTokenClientInitCallback(callback) {
    this.onTokenClientInit = callback;
  }
}

export default GoogleClient;
