import { Plugin } from '@ckeditor/ckeditor5-core';

import { EDITOR } from '@marketmuse/config/types/';
import { editor as editorConfig } from '@marketmuse/config/configs';
import { addScriptToDOM } from './utils/addScript';
import GoogleClient from './utils/googleClient';
import uploadDocToDrive from './utils/uploadDocToDrive';

export default class ExportGoogleDoc extends Plugin {
  private _config: EDITOR.ExportConfig;
  private googleClient!: GoogleClient;

  constructor(editor) {
    super(editor);
    this._config = editor.config.get(editorConfig.SETTINGS.CONFIG_NAME) || {};

    if (this._config.googleClientId) {
      addScriptToDOM('https://apis.google.com/js/api.js', () => {
        addScriptToDOM('https://accounts.google.com/gsi/client', () => {
          const instance = GoogleClient.getInstance(
            this._config.googleClientId as string,
          );
          this.googleClient = instance;
          this.googleClient.setTokenClientInitCallback(() =>
            this.uploadGoogleDoc(),
          );
        });
      });
    } else {
      editor.fire(editorConfig.EXPORT_EVENT.updateExportToolbarView, {
        key: 'googleDoc',
        isEnabled: false,
      });
    }
  }

  private async uploadGoogleDoc() {
    this.editor?.fire(editorConfig.EXPORT_EVENT.updateExportToolbarView, {
      key: 'googleDoc',
      isLoading: true,
    });

    const data = this.editor.getData();
    const config = this.editor.config.get('export') as EDITOR.ExportConfig;
    const response = await uploadDocToDrive({
      data,
      accessToken: this.googleClient?.getToken(),
      filename: config.filename,
    });

    if (response.status === 401) {
      this.googleClient.requestAccessToken();
    } else if (response.status === 200) {
      const res = await response.json();

      if (
        res &&
        typeof this._config.feature.googleDoc.onSuccess === 'function'
      ) {
        this._config.feature.googleDoc.onSuccess(res.id);
      }
    } else {
      console.error('Unable to export to google drive');
    }
    this.editor?.fire(editorConfig.EXPORT_EVENT.updateExportToolbarView, {
      key: 'googleDoc',
      isLoading: false,
    });
  }

  public async initiateGoogleDocUpload() {
    if (this.googleClient?.isAuthorized()) {
      this.uploadGoogleDoc();
    } else {
      this.googleClient?.requestAccessToken();
    }
  }
}
