import { combineReducers } from '@reduxjs/toolkit';

import { dataSlice, networkSlice, uiSlice } from './slices';

const appOptimizeReducer = combineReducers({
  data: dataSlice.reducer,
  network: networkSlice.reducer,
  ui: uiSlice.reducer,
});

export default appOptimizeReducer;
