import { ObjectValues } from '../../utilities';

export const EVENTS = {
  onCheckWordCount: 'word-count:onCheckWordCount',
  onChangeWordCount: 'word-count:onChangeWordCount',
} as const;

export type WordCountEvents = ObjectValues<typeof EVENTS>;

export type OnChangeWordCountEvent = {
  name: typeof EVENTS['onChangeWordCount'];
  args: [data: number];
};

export type OnCheckWordCountEvent = {
  name: typeof EVENTS['onCheckWordCount'];
  args: [];
};

export type WordCountConfig = {
  isEnabled: boolean;
};
