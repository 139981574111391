import React, { useMemo } from 'react';
import { RowData, TableOptions } from '@tanstack/react-table';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  InitialTableState,
  TableState,
  useReactTable,
} from '@tanstack/react-table';

import { TableFilters } from './containers/TableFilters';
import TableBody from './containers/TableBody';
import TableHeader from './containers/TableHeader';
import TablePagination from './containers/TablePagination';
import TableLayout from './containers/TableLayout';

import wordsIncludesExcludes from './filters/FilterIncludesExcludes/filter';
import numberInRangeOptional from './filters/FilterNumberBetween/filter';
import stringInList from './filters/FilterList/filter';

import { sortUndefinedLast, sortListLength } from './sorting';
import { TableContext } from './useTableContext';
import useInitialState from './useInitialState';

export interface ITableProps<T extends RowData> {
  columns: Array<any>;
  data: Array<T>;
  hooks?: Partial<TableOptions<T>>;
  children?: React.ReactNode;
  initialState?: InitialTableState;
  state?: Partial<TableState>;
  loading?: boolean;
}

export const Table = <TData extends RowData>({
  columns,
  data,
  hooks,
  children,
  state,
  initialState,
  loading,
}: ITableProps<TData>) => {
  const localInitialState = useInitialState({ columns, initialState });
  const enableSorting = useMemo(() => {
    if (localInitialState.sorting || state?.sorting) {
      return true;
    } else {
      return false;
    }
  }, [localInitialState, state]);

  const context = useReactTable<TData>({
    columns,
    data: data || [],
    state,
    initialState: localInitialState,
    enableSorting,
    getCoreRowModel: getCoreRowModel(),
    ...(hooks || {
      getPaginationRowModel: getPaginationRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getFacetedRowModel: getFacetedRowModel(),
      getFacetedUniqueValues: getFacetedUniqueValues(),
      getFacetedMinMaxValues: getFacetedMinMaxValues(),
    }),
    sortingFns: {
      sortListLength,
      sortUndefinedLast,
    },
    filterFns: {
      stringInList,
      wordsIncludesExcludes,
      numberInRangeOptional,
    },
  });

  return (
    <TableContext.Provider value={{ ...context, loading }}>
      {children || (
        <TableLayout>
          <TableHeader />
          <TableBody<TData> />
        </TableLayout>
      )}
    </TableContext.Provider>
  );
};

Table.Filters = TableFilters;
Table.Body = TableBody;
Table.Header = TableHeader;
Table.Pagination = TablePagination;
Table.Layout = TableLayout;

export default Table;
