import get from 'lodash/get';

import { FormUpdateDocument } from '@marketmuse/data-papi';
import makeRequest from '../../utils/makeRequest';
import * as types from '../../config/types';

export default () =>
  ({ getState }) =>
  next =>
  action => {
    if (action.type === types.UPDATE_FORM) {
      const formInput = {
        title: action.data?.title,
        orderVisible: JSON.stringify(action.data?.orderVisible),
        orderHidden: JSON.stringify(action.data?.orderHidden),
      };
      makeRequest({
        type: types.UPDATE_FORM,
        mutation: FormUpdateDocument,
        variables: {
          formId: action.id,
          formInput: formInput,
        },
        apolloOptions: {
          fetchPolicy: 'no-cache',
        },
      }).then(res => {
        const data = get(res, 'formUpdate', {});
        if (typeof action.callback === 'function') action.callback(data);
      });
    }

    return next(action);
  };
