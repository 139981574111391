import { trim, kebabCase } from 'lodash';

type TitleCaseOptions = {
  // leading/trailing characters to remove
  trimChar?: string | void | undefined;
  // special case words to keep
  brandedTerms?: string[] | undefined;
};

// split text into multiple grams to be able to uppercase first character
function prepare(str: string, trimChar?: string | void) {
  const kebabCaseStr = kebabCase(str.toLowerCase());
  const replaced = kebabCaseStr.replace(/[^a-zA-Z0-9]/g, ' ');
  const prepared = trim(replaced, trimChar || '');
  return prepared;
}

// Check input string against branded terms and return branded term to keep
function titleCaseBranded(gram: string, options: TitleCaseOptions | null) {
  const brandedTerm = options?.brandedTerms?.find(
    term => prepare(term) === gram,
  );
  if (brandedTerm) {
    return brandedTerm;
  } else {
    return gram.charAt(0).toUpperCase() + gram.slice(1).toLowerCase();
  }
}

/**
 * Convert a string in upper snake case to words.
 * eg. "ORDER_MANAGED_BRIEF" -> "Order Managed Brief"

 * @returns {string} The converted string.
 */
export const titleCase = (
  str: string,
  options: TitleCaseOptions | null = null,
) => {
  const prepared = prepare(str, options?.trimChar);

  return prepared
    .split(' ')
    .map(gram => titleCaseBranded(gram, options))
    .join(' ');
};
