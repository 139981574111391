import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TileLarge from '../../components/TileLarge';
import ExternalLink from '../../components/ExternalLink';

const Wrapper = styled.div`
  position: relative;
`;

const TileLargeStyled = styled(TileLarge)`
  flex-grow: 1;
`;

const TileRow = styled.div`
  display: flex;
  border-bottom: 1px solid ${p => p.theme.mmxGreyE6};
`;

const TileWrapper = styled.div`
  flex-shrink: 0;
  & > div { height: 100%; }
`;

const ExternalLinkStyled = styled(ExternalLink)`
  padding: ${p => `8px ${p.theme.slideoutPaddingLR}px`};
`;

const UrlRow = styled(TileRow)`
  align-items: center;
`;

const TileHeader = ({ tiles, header, title, url, className }) => (
  <Wrapper>
    <TileRow className={className}>
      {title && (
        <TileLargeStyled
          title={header}
          content={title}
        />
      )}
      {tiles.filter(i => !!i).map((tile, i) => (
        <TileWrapper key={`h-tile-${i}`}>
          {tile}
        </TileWrapper>
      ))}
    </TileRow>
    {url && (
      <UrlRow>
        <ExternalLinkStyled url="https://blog.marketmuse.com/building-a-better-content-brief-using-tech" />
      </UrlRow>
    )}
  </Wrapper>
);

TileHeader.propTypes = {
  className: PropTypes.string,
  header: PropTypes.any,
  title: PropTypes.any,
  url: PropTypes.string,
  tiles: PropTypes.array,
};

TileHeader.defaultProps = {
  tiles: [],
}

export default TileHeader;
