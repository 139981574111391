import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IconChevRight from 'react-feather/dist/icons/chevron-right';
import IconChevDown from 'react-feather/dist/icons/chevron-down';
import { Collapse } from 'react-collapse';
import isNil from 'lodash/isNil';

import Clickable from '../Clickable';
import Loader from '../../components/Loader/Loader';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  background-color: white;
`;

const TileMeta = styled.div`
  display: flex;
  flex-flow: column;
  flex-grow: 1;
`;

const Title = styled(Clickable)`
  padding: ${p => `${p.theme.slideoutPaddingTB}px ${p.theme.slideoutPaddingLR}px`};
  display: flex;
`;

const TitleText = styled.div`
  flex-grow: 1;
  color: ${p => p.theme.colors.grey85};
  font-size: 9.5pt;
`;

const TitleDesc = styled.div`
  flex-grow: 1;
  color: ${p => p.theme.mmxDarkGrey};
  font-size: 8.5pt;
`;

const TitleChevron = styled.div`
  color: ${p => p.theme.mmxDarkGrey};
  svg {
    width: 14px;
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

export const TileBody = styled.div`
  flex-grow: 1;
  padding: ${p => `${p.theme.slideoutPaddingTB}px ${p.theme.slideoutPaddingLR}px`};
`;

const ValueTitle = styled.div`
  font-size: 8pt;
  margin-bottom: 3px;
  color: ${p => p.theme.mmxDarkGrey};
  padding: ${p => `0 ${p.theme.slideoutPaddingLR}px`};
  display: flex;
  align-items: center;
`;

const ValueContent = styled.div`
  color: ${p => p.theme.colors.grey85};
  padding: ${p => `0 ${p.theme.slideoutPaddingLR}px`};
  line-height: normal;
  font-size: 13pt;
`;

const Tile = ({
  loading,
  style,
  children,
  desc,
  title,
  className,
  collapsible,
  collapsed,
  onClick,
  valueTitle,
  valueContent,
}) => (
    <Wrapper className={className} style={style}>
      {!isNil(title) && (
        <Title
          onClick={() => !loading && onClick()}
          collapsible={collapsible}
          collapsed={collapsed}
        >
          <TileMeta>
            {title && <TitleText>{title}</TitleText>}
            {desc && <TitleDesc>{desc}</TitleDesc>}
          </TileMeta>

          {loading && <Loader />}
          {!loading && collapsible && (
            <TitleChevron>
              {collapsed && <IconChevRight />}
              {!collapsed && <IconChevDown />}
            </TitleChevron>
          )}
        </Title>
      )}
      {!collapsible && children}
      {!isNil(valueTitle) && (
        <>
          <ValueTitle>{valueTitle}</ValueTitle>
          <ValueContent>{valueContent}</ValueContent>
        </>
      )}
      {collapsible && (
        <Collapse isOpened={!collapsed}>
          {children}
        </Collapse>
      )}
    </Wrapper>
  );

Tile.propTypes = {
  style: PropTypes.object,
  onClick: PropTypes.func,
  collapsible: PropTypes.bool,
  collapsed: PropTypes.bool,
  title: PropTypes.string,
  desc: PropTypes.string,
  valueTitle: PropTypes.any,
  valueContent: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.string,
};

export default Tile;
