import ContainerComponent, { Container } from './Container';
import ItemContainer, { Item } from './Item';

// Importable styled-component for usage as selector
export { Container as SimpleGridContainer };
export { Item as SimpleGridItem };

// Default React components
export default {
  Container: ContainerComponent,
  Item: ItemContainer,
};
