import * as React from 'react';
import { isNil } from 'lodash';
import { Mentions } from '@marketmuse/components';
import { CellMentionsProps } from './types';

const CellMentions = ({ getValue, uiState }: CellMentionsProps) => {
  const value = getValue();
  const { displayExactDistribution } = uiState;

  if (isNil(value)) {
    return <span />;
  }

  return <Mentions value={value} banded={!displayExactDistribution} />;
};

export default CellMentions;
