import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Clickable from '../Clickable';
import { ModalTopbarWrapper } from './ModalTopbar';

export const ModalSidebarList = styled.ul`
  ${p => p.theme.mixins.listReset};
  background-color: ${p =>
    p.backgroundColor ? p.theme.colors[p.backgroundColor] : 'transparent'};
  margin-top: ${p => p.theme.spacing.small};
  ${p => p.theme.mixins.applyPadding};
  ${p => p.theme.mixins.applyMargin};
  ${p => p.theme.mixins.applyFlex};
  ${p => p.theme.mixins.applyGrid};

  &:first-child {
    margin-top: 0;
  }

  & + & {
    padding-top: ${p => p.theme.spacing.small};
    border-top: 1px solid ${p => p.theme.colors.grey15};
  }
`;

export const ModalSidebarHeading = styled.div`
  ${p => p.theme.type.heading3};
  margin-top: ${p => p.theme.spacing.medium};

  &:first-child {
    margin-top: 0;
  }
`;

// usage: within ModalSidebarList
export const ModalSidebarItem = styled.li`
  margin-top: ${p => p.theme.spacing.xSmall};
  ${p => p.theme.mixins.applyMargin};

  &:first-child {
    margin-top: 0;
  }
`;

// usage: connect an onClick event to text
export const ModalSidebarClickable = styled(Clickable).attrs(({id, title, ...p}) => {
  if (id) {
    return {
      ...p,
      title: void 0,
      id: void 0,
      'data-mms--modal-sidebar-item': id,
    };
  }
  return p;
})`
  ${p => p.theme.mixins.buttonReset};
  ${p => p.theme.type.textRegular};
  display: flex;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 2px;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: ${p => p.theme.colors[!p.active ? 'grey60' : 'blueDark1']};
  transition: color 0.3s ${p => p.theme.transitionFn};

  &:hover {
    color: ${p => p.theme.colors[!p.active ? 'grey85' : 'blueDark1']};
    ${p => p.disabled && `color: ${p.theme.colors.grey60};`}
  }
`;

ModalSidebarClickable.propTypes = {
  active: PropTypes.bool, // active page,
  id: PropTypes.string, // id for data attr
}

export const ModalSidebarListItem = ({ id, as, active, onClick, children, className, style }) => (
  <ModalSidebarItem as={as} className={className} style={style}>
    <ModalSidebarClickable active={active} id={id} onClick={onClick}>
      {children}
    </ModalSidebarClickable>
  </ModalSidebarItem>
);

const ModalSidebar = styled.div`
  display: flex;
  order: 2;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  flex: 0 0 auto;
  width: ${p => p.theme.sidebarWidthLarge}px;
  padding: ${p => p.theme.spacing.large};
  background-color: ${p => p.theme.colors.grey10};
  overflow: auto;

  ${ModalTopbarWrapper} ~ & {
    height: 100%;
    max-height: calc(100% - 60px);
  }
`;

ModalSidebar.propTypes = {
  children: PropTypes.any,
};

export default ModalSidebar;
