import { createAction } from '@reduxjs/toolkit';

import {
  SavedViewsListPayload,
  SavedViewUpdateUiConfig,
  SavedViewsDeletePayload,
  SavedViewCurrentSetPayload,
} from '../types/savedViews';

import {
  SAVED_VIEW_UPSERT,
  SAVED_VIEWS_LIST_SET,
  SAVED_VIEW_UI_CONFIG_SET,
  SAVED_VIEW_CURRENT_SET,
  SAVED_VIEW_DELETE_FROM_STATE,
} from './savedViews.actionTypes';

export const savedViewsUpdate =
  createAction<SavedViewsListPayload>(SAVED_VIEW_UPSERT);
export const savedViewsListSet =
  createAction<SavedViewsListPayload>(SAVED_VIEWS_LIST_SET);

export const savedViewUpdateUiConfig = createAction<SavedViewUpdateUiConfig>(
  SAVED_VIEW_UI_CONFIG_SET,
);

export const savedViewDeleteFromState = createAction<SavedViewsDeletePayload>(
  SAVED_VIEW_DELETE_FROM_STATE,
);

export const savedViewCurrentSet = createAction<SavedViewCurrentSetPayload>(
  SAVED_VIEW_CURRENT_SET,
);
