import get from 'lodash/get';

import * as types from '../config/types';
import initialState from '../config/initialState';

const toolsReducer = (state = initialState.tools, action) => {

  if (action.type === types.SET_TOOL_STATE) {
    const currentToolState = get(state, `data[${action.id}]`) || {};
    return {
      ...state,
      data: {
        ...state.data,
        [action.id]: {
          ...currentToolState,
          ...(action.data || {})
        }
      },
    };
  }

  return state;
};

export default toolsReducer;
