import { get } from 'lodash';
import { PAPI } from '@marketmuse/config/types';
import { validatePermission } from '@marketmuse/utilities';
import {
  ReduxListener,
  ResearchDataConnectInput,
  AppQueryNetworkStatus,
  RootState,
} from '../../../types';

import {
  researchTaskStatusCheck,
  researchTaskConnectFetch,
} from '../../actions';
import { dataSlice, networkSlice } from '../../slices';
import { getLinkingRecommendations } from '../../thunks';

const listeners = (startListening: ReduxListener) => {
  const dataActions = dataSlice.actions;
  const networkActions = networkSlice.actions;

  startListening({
    actionCreator: getLinkingRecommendations.pending,
    effect: async (action, { dispatch }) => {
      await dispatch(
        networkActions.linkingRecommendationsSet(AppQueryNetworkStatus.loading),
      );
    },
  });

  startListening({
    actionCreator: getLinkingRecommendations.rejected,
    effect: async (action, { dispatch }) => {
      const { aborted } = action.meta;
      if (!aborted) {
        await dispatch(
          networkActions.linkingRecommendationsSet(AppQueryNetworkStatus.error),
        );
      }
    },
  });

  startListening({
    actionCreator: getLinkingRecommendations.fulfilled,
    effect: async ({ payload }, { dispatch }) => {
      await Promise.all([
        dispatch(dataActions.linkingRecommendationsSet(payload.data)),
        dispatch(
          networkActions.linkingRecommendationsSet(
            AppQueryNetworkStatus.complete,
          ),
        ),
      ]);

      // check status after other steps
      await dispatch(researchTaskStatusCheck());
    },
  });

  startListening({
    actionCreator: researchTaskConnectFetch,
    effect: async (action, { dispatch, getState }) => {
      const state: RootState = getState();
      const permissions = state.permissions;

      const hasAccess = validatePermission({
        permissions,
        permission: {
          resource: PAPI.PermissionResource.RESEARCH_CONNECT,
          action: PAPI.PermissionAction.ACCESS_DATA,
        },
      });

      if (!hasAccess) {
        return;
      }

      const domains: ResearchDataConnectInput = action.payload;
      const { appQuery, params } = state.appResearch.ui;
      if (appQuery) {
        await dispatch(
          getLinkingRecommendations({
            ...domains,
            siteId: params.siteId,
            country: appQuery.country,
            language: appQuery.language,
            term: appQuery.term,
            url: get(state, 'filter.site.domain'),
          }),
        );
      }
    },
  });
};

export default listeners;
