import { Suspense } from 'react';
// import { Optimize as OptimizeContainer } from '@marketmuse/containers';
import { lazyLoad } from '@marketmuse/utilities';

import ScreenLoader from '../../../components/ScreenLoader';
import { Omnibar } from '../components/Omnibar';
import ScoresBar from '../../../components/Bars/ScoresBar';
import editorOnCopy from '../../../models/tracker/events/editor/onCopy';
import editorOnPaste from '../../../models/tracker/events/editor/onPaste';
import Toolkit from '../Toolkit/Toolkit';
import { withAppDataSerializer } from '../../../hocs/withAppDataSerializer';
import { PageStyled } from './shared/structure';

const OptimizeContainer = lazyLoad(() => {
  return import(
    /* webpackChunkName: 'OptimizeContainer' */ '@marketmuse/containers/Optimize'
  );
});

const Optimize = () => {
  return (
    <Suspense fallback={<ScreenLoader.Page />}>
      <PageStyled className="mms--app">
        <OptimizeContainer
          renderOmnibar={Omnibar}
          renderScoresBar={withAppDataSerializer(ScoresBar)}
          renderSidebar={withAppDataSerializer(Toolkit)}
          eventLayer={{
            onCopyTrack: () => editorOnCopy.record(),
            onPasteTrack: () => editorOnPaste.record(),
          }}
        />
      </PageStyled>
    </Suspense>
  );
};
export default Optimize;
