import { get, cloneDeep } from 'lodash';
import { ReportsReadDocument } from '@marketmuse/data-papi';
import makeRequest from '../../utils/makeRequest';
import * as types from '../../config/types';

const dateFilterType = {
  gt: 'GTE',
  lt: 'LTE',
  range: 'RANGE',
};

const sortDirection = {
  desc: 'DESC',
  asc: 'ASC',
};
export default () => () => next => action => {
  if (action.type === types.READ_REPORTS) {
    const filter = {};

    const {
      reportType,
      reportTypes,
      dueDate,
      dueDateCompareType,
      dueDateCompareValue,
      dueDateCompareValue2,
      orderedDateCompareType,
      orderedDateCompareValue,
      orderedDateCompareValue2,
      reportStatusesTime,
      reportTypesTime,
      ...prevFilter
    } = action.filter;

    let sort = cloneDeep(action.sort);

    if (sort.length > 0) {
      sort[0]['direction'] = sortDirection[sort[0]['direction']];
    }
    if (reportTypes) {
      filter.reportTypes = [];
      Object.keys(reportTypes).forEach(value => {
        if (reportTypes[value]) filter.reportTypes.push(value);
      });
    } else filter.reportTypes = ['CREATE', 'OPTIMIZE'];

    if (dueDateCompareType) {
      filter['dueDateType'] = dateFilterType[dueDateCompareType];
      filter['dueDateVal'] = dueDateCompareValue;
      filter['dueDateVal2'] = dueDateCompareValue2;
    }
    if (orderedDateCompareType) {
      filter['createdAtType'] = dateFilterType[orderedDateCompareType];
      filter['createdAtVal'] = orderedDateCompareValue;
      filter['createdAtVal2'] = orderedDateCompareValue2;
    }
    makeRequest({
      type: types.READ_REPORTS,
      mutation: ReportsReadDocument,
      keys: (action.keys || []).map(key => `read_reports_${key}`),
      variables: {
        filters: {
          first: action.first,
          skip: action.offset,
          sort: sort,
          reportClass: action.reportClass,
          focusTopic: action.focusTopic,
          search: action.search,
          siteId: action.siteId,
          ...filter,
          ...prevFilter,
        },
      },
      options: {
        isQuery: true,
      },
      apolloOptions: {
        fetchPolicy: 'no-cache',
      },
    }).then(res => {
      const reports = get(res, 'readReports', []);
      if (action.callback) action.callback(reports);
    });
  }

  return next(action);
};
