import { useMemo } from 'react';
import COUNTRY_CODES from '../../../config/countryCodes';

export function useActiveCountry(countriesList, activeCountry) {
  return useMemo(
    () =>
      countriesList.find(({ id }) => id === activeCountry) ||
      countriesList[COUNTRY_CODES.US],
    [activeCountry, countriesList],
  );
}
