/**
 * Things to record:
 * - expanded topic
 * - bodyOrArticle
 * - brands
 * - country
 * - people
 * - topic
 * - url
 */

import Event from '../../Event';

class OptimizeResearchVariantsExpanded extends Event {

  record({ expandedTopic, ...params }) {
    super.record('OptimizeResearchVariantsExpanded', {
      category: 'Optimize',
      mainValue: '',
      newName: 'apps--optimize--research--variants-expanded',
      expandedTopic,
      ...params,
    })
  }

}

export default new OptimizeResearchVariantsExpanded();
