export enum ConnectViews {
  internal = 'internal',
  external = 'external',
  network = 'network',
  competition = 'competitor',
}

export enum ConnectViewsData {
  internal = 'internalLinkingRecommendations',
  external = 'externalLinkingRecommendations',
  network = 'networkLinkingRecommendations',
  competitor = 'competitionLinkingRecommendations',
}

export type ConnectViewStatus = {
  loading: boolean;
  complete: boolean;
  error: boolean;
};

export interface ActionButtonProps {
  children: React.ReactNode;
  setView: (view: ConnectViews) => void;
  currentView: ConnectViews;
  targetView: ConnectViews;
  loading: boolean;
}
