import {
  DisplayTableDict,
  DisplayTableColumn,
} from '../../types/displayTables';
import common from './common';

const table = 'topics';
const topicColumns: DisplayTableDict<DisplayTableColumn> = {
  topic: {
    field: 'term',
    title: 'Topic',
    table,
  },
  actionBar: {
    field: 'actionBar',
    title: 'Actions',
    table,
  },
  rank: {
    field: 'maxRank',
    title: 'Rank',
    table,
  },
  topRankingUrl: {
    field: 'topRankingPage',
    title: 'Top Ranking Url',
    table,
  },
  difficulty: {
    field: 'mmDifficulty',
    title: 'Difficulty',
    table,
  },
  competitiveAdvantage: {
    field: 'mmCompetitiveAdvantage',
    title: 'Competitive Advantage',
    table,
  },
  difficultyPersonalized: {
    field: 'mmPersonalizedDifficulty',
    title: 'Personalized Difficulty',
    table,
  },
  value: {
    ...common['trafficValue'],
    table,
  },
  valueChange: {
    ...common['trafficValueDelta'],
    table,
  },
  traffic: {
    ...common['traffic'],
    table,
  },
  trafficChange: {
    ...common['trafficDelta'],
    table,
  },
  serpFeatures: {
    field: 'serpFeatures',
    title: 'SERP Features',
    table,
  },
  potentialTraffic: {
    ...common['potentialTraffic'],
    table,
  },
  potentialTrafficUpside: {
    ...common['potentialTrafficUpside'],
    table,
  },
  valueFromTopic: {
    ...common['versionTrafficValuePct'],
    table,
  },
  trafficFromTopic: {
    ...common['versionTrafficPct'],
    table,
  },
  potentialValue: {
    ...common['potentialTrafficValue'],
    table,
  },
  trafficChangePct: {
    ...common['trafficDeltaPct'],
    table,
  },
  seasonality: {
    field: 'monthlyTrends',
    title: 'Seasonality',
    table,
  },
  authority: {
    field: 'avgPageAuthority',
    title: 'Avg. Page Authority',
    table,
  },
  topicVolume: {
    field: 'volume',
    title: 'Search Volume',
    table,
  },
  mmPersonalizedAdvantage: {
    field: 'mmPersonalizedAdvantage',
    title: 'Topic Authority',
    table,
  },
};

export default topicColumns;
