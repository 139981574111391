import { Range, Model, Item } from '@ckeditor/ckeditor5-engine';

export function shouldProcessRangeItem({
  type,
  item,
  model,
  range,
}: {
  type: string;
  item: Item;
  model: Model;
  range: Range;
}) {
  if (type !== 'elementStart') {
    return false;
  }

  if (!model.schema.checkChild(item, '$text')) {
    return false;
  }

  if (range.root.rootName === '$graveyard') {
    return false;
  }

  return true;
}
