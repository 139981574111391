import { FC } from 'react';
import { Box, Button, Text } from '@marketmuse/components';
import { ReactComponent as TopicSerpXray } from './assets/topic-serpxray.svg';

interface CollateralSerpXrayProps {
  onUpgrade?: (event: MouseEvent) => void;
}

const CollateralSerpXray: FC<CollateralSerpXrayProps> = ({ onUpgrade }) => {
  return (
    <Box className={['flex', 'flex-row']}>
      <Box
        className={[
          'w-1/2',
          'flex',
          'flex-row',
          'items-center',
          'justify-center',
          'py-6',
        ]}
      >
        <TopicSerpXray />
      </Box>
      <Box
        className={[
          'w-1/2',
          'flex',
          'flex-col',
          'items-center',
          'justify-center',
          'text-center',
        ]}
      >
        <Text className={['prose', 'text-xl', 'font-bold', 'mb-2.5']} as="h2">
          Fill competitive blind spots.
        </Text>
        <Text className={['prose', 'text-base', 'mb-4']} as="p">
          Analyze the search results to quickly understand how they structure
          their content.
        </Text>
        <Button variant="blue400" onClick={onUpgrade}>
          Upgrade Now
        </Button>
      </Box>
    </Box>
  );
};

export default CollateralSerpXray;
