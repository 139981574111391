import { useCallback, useState, useMemo } from 'react';

import dayjs from 'dayjs';
import { toast } from '@marketmuse/components';
import { useAddFromResearchMutation } from '@marketmuse/data-papi';
import { useStoreDispatch } from '@marketmuse/data-state/hooks';
import { RESEARCH_ACTIONS } from '@marketmuse/data-state/appResearch';
import { errorsToString } from '@marketmuse/utilities';

import {
  AddToInventoryHookResult,
  AddToInventoryHandlerParams,
} from '../types';
import useToaster from './useToaster';

const genericErrorMessage =
  'Sorry, we were not able to save that to your inventory.';

const useAddToInventory = ({
  canAddToInventory,
  hasViableSiteInventory,
  siteId,
}: {
  canAddToInventory: boolean;
  hasViableSiteInventory: boolean;
  siteId: string;
}): AddToInventoryHookResult => {
  const dispatch = useStoreDispatch();
  const [addingOrigin, setAddingOrigin] = useState(null);
  const [addingItems, setAddingItems] = useState([]);
  const { addedToInventoryToast, inventoryNotReady, premiumInventoryToast } =
    useToaster();

  const [inventoryItemsAddMutation, { loading }] = useAddFromResearchMutation();

  const handler = useCallback(
    ({ items, origin }: AddToInventoryHandlerParams) => {
      if (!canAddToInventory) {
        premiumInventoryToast();
        return;
      }

      if (!hasViableSiteInventory) {
        inventoryNotReady();
        return;
      }

      setAddingOrigin(origin);
      setAddingItems(items);

      const tagTitle = dayjs().format('MMM-D-YYYY').toLowerCase();
      const inventoryItems = items.map(item => ({
        term: item.term,
      }));

      inventoryItemsAddMutation({
        variables: {
          siteId,
          items: inventoryItems,
          tagId: tagTitle,
          tagInput: {
            title: tagTitle,
            terms: { add: inventoryItems.map(item => item.term) },
          },
        },
      })
        .then(({ data, errors }) => {
          const status = data?.inventoryItemsAdd?.status;

          if ((errors && !data) || status !== 200) {
            const error = errors
              ? errorsToString(errors as unknown as Array<Error>)
              : genericErrorMessage;
            toast.error(error);
          }

          if (status === 200) {
            addedToInventoryToast();
            dispatch(
              RESEARCH_ACTIONS.researchTaskInventoryItemAdded(addingItems),
            );
          }
        })
        .catch(error => {
          const message = error.message || genericErrorMessage;
          toast.error(message);
        })
        .finally(() => {
          setAddingOrigin(void 0);
          setAddingItems([]);
        });
    },
    [
      addedToInventoryToast,
      addingItems,
      dispatch,
      canAddToInventory,
      hasViableSiteInventory,
      inventoryItemsAddMutation,
      inventoryNotReady,
      premiumInventoryToast,
      siteId,
    ],
  );

  const loadingItems = useMemo(
    () =>
      addingItems.reduce((acc, item) => ({ ...acc, [item.term]: true }), {}),
    [addingItems],
  );

  return { handler, addingOrigin, loading, loadingItems };
};

export default useAddToInventory;
