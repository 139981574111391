import get from 'lodash/get';

import { GetUserDocument as GET_USER_DATA } from '@marketmuse/data-papi';

import * as types from '../../config/types';
import { saveUserData } from '../../actions/authenticateActions';
import makeRequest from '../../utils/makeRequest';

export default () =>
  ({ dispatch, getState }) =>
  next =>
  action => {
    if (action.type === types.GET_USER_DATA) {
      const state = getState();
      makeRequest({
        type: types.GET_USER_DATA,
        mutation: GET_USER_DATA,
        options: { isQuery: true },
        apolloOptions: {
          fetchPolicy: 'no-cache',
        },
        variables: {
          email: get(state, 'user.email'),
        },
      }).then(res => {
        const user = get(res, 'user', {});

        if (action.saveUserData) {
          dispatch(saveUserData(user));
        }
        if (action.callback && typeof action.callback === 'function') {
          action.callback(user);
        }
      });
    }

    return next(action);
  };
