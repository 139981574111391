import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sanitizeTerm } from '@marketmuse/utilities';
import { AppUiStatus } from '@marketmuse/config/types/applications';

import {
  ResearchDataInput,
  ResearchDataConnectInput,
  ResearchSnapshotInput,
} from '../../types';
import {
  researchTaskSiteChange,
  researchSiteConnectDomains,
  researchTaskConnectDomains,
} from '../actions';

import validateAppParams from '../../utils/validateAppParams';
import {
  getLastConnectDomains,
  mergeConnectDomains,
  shouldRemoveConnectDomain,
} from '../utils/connectDomains';
import { initialState } from './ui.initialState';

export const uiSlice = createSlice({
  name: 'research/ui',
  initialState,
  reducers: {
    appQuerySet: (
      state,
      action: PayloadAction<ResearchSnapshotInput['appQuery']>,
    ) => {
      const { id, status, term, url, country, language } = action.payload;

      state.appQuery = {
        id,
        status,
        term: sanitizeTerm(term),
        url,
        country,
        language,
      };
    },
    statusUpdate: (state, action: PayloadAction<AppUiStatus>) => {
      state.status = action.payload;
    },
    paramsUpdate: (state, action: PayloadAction<ResearchDataInput>) => {
      state.params = action.payload;
      state.validParams = validateAppParams<ResearchDataInput>(action.payload);
    },
    paramsAndStatusUpdate: (
      state,
      action: PayloadAction<{
        params: ResearchDataInput;
        status: AppUiStatus;
      }>,
    ) => {
      const { status, params } = action.payload;
      state.status = status;
      state.params = params;
      state.validParams = validateAppParams<ResearchDataInput>(params);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(
        researchSiteConnectDomains,
        (
          state,
          action: PayloadAction<{
            next: {
              networkDomains: string[];
              competitorDomains: string[];
            };
            last: {
              networkDomains: string[];
              competitorDomains: string[];
            };
          }>,
        ) => {
          const { next, last } = action.payload;
          const {
            competitorDomains: competitorDomainsLast,
            networkDomains: networkDomainsLast,
          } = getLastConnectDomains(state.params);

          const {
            competitorDomains: competitorDomainsMerged,
            networkDomains: networkDomainsMerged,
          } = mergeConnectDomains({
            competitorDomains: next.competitorDomains || [],
            competitorDomainsLast: competitorDomainsLast || [],
            networkDomains: next.networkDomains || [],
            networkDomainsLast: networkDomainsLast || [],
          });

          const competitorDomains = competitorDomainsMerged
            .filter(
              shouldRemoveConnectDomain(
                next.competitorDomains,
                last.competitorDomains,
              ),
            )
            .filter(Boolean);

          const networkDomains = networkDomainsMerged
            .filter(
              shouldRemoveConnectDomain(
                next.networkDomains,
                last.networkDomains,
              ),
            )
            .filter(Boolean);

          state.params = {
            ...state.params,
            competitorDomains,
            networkDomains,
          };
        },
      )
      .addCase(
        researchTaskConnectDomains,
        (state, action: PayloadAction<ResearchDataConnectInput>) => {
          const {
            competitorDomains: competitorDomainsLast,
            networkDomains: networkDomainsLast,
          } = getLastConnectDomains(state.params);

          let {
            competitorDomains: competitorDomainsNext,
            networkDomains: networkDomainsNext,
          } = action.payload;

          if (!competitorDomainsNext) {
            competitorDomainsNext = competitorDomainsLast;
          }
          if (!networkDomainsNext) {
            networkDomainsNext = networkDomainsLast;
          }

          const {
            competitorDomains: competitorDomainsMerged,
            networkDomains: networkDomainsMerged,
          } = mergeConnectDomains({
            competitorDomains: competitorDomainsNext || [],
            competitorDomainsLast: competitorDomainsLast || [],
            networkDomains: networkDomainsNext || [],
            networkDomainsLast: networkDomainsLast || [],
          });

          const competitorDomains = competitorDomainsMerged
            .filter(
              shouldRemoveConnectDomain(
                competitorDomainsNext || [],
                competitorDomainsLast || [],
              ),
            )
            .filter(Boolean);

          const networkDomains = networkDomainsMerged
            .filter(
              shouldRemoveConnectDomain(
                networkDomainsNext || [],
                networkDomainsLast || [],
              ),
            )
            .filter(Boolean);

          state.params = {
            ...state.params,
            competitorDomains,
            networkDomains,
          };
        },
      )
      .addCase(
        researchTaskSiteChange,
        (state, action: PayloadAction<ResearchDataInput>) => {
          return {
            ...initialState,
            params: {
              ...action.payload,
            },
          };
        },
      );
  },
});

export const uiActions = uiSlice.actions;
