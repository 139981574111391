import { useState } from 'react';
import { trim } from 'lodash';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { useGetUserLazyQuery } from '@marketmuse/data-papi';
import { Icon, SUPPORTED_ICON } from '@marketmuse/components';

import { useSuiteNavigate } from '../../hooks/useSuiteNavigate';
import handleEventBlock from '../../utils/handleEventBlock';

import * as SL from '../SimpleList';
import SimpleTextInput from '../SimpleTextInput';
import Popper from '../Tooltip/Popper';
import Button from '../Button';
import Modal, { ModalHeader, ModalBody, ModalFooter } from '../Modal';
import Card, { CardBody } from '../../components/Card';

export const PopperStyled = styled(Popper)`
  display: block;
  width: 300px;
`;

const Text = styled.div`
  ${p => p.theme.truncate}
`;

const Label = styled.div`
  padding-top: ${props => props.paddingTop || '5px'};
  font-weight: 700;
  ${p => p.theme.truncate}
`;
const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: ${p => p.theme.spacing.small};
  background-color: ${p => p.theme.colors.grey15};
`;
const LinkWrapper = styled.a`
  text-decoration: none !important;
  color: inherit !important;
  width: 100%;
`;

const UserLookupModal = props => {
  const navigate = useSuiteNavigate();
  const [email, setEmail] = useState('');
  const closeFn = data => {
    if (typeof props.close === 'function') props.close(data);
  };

  const [getData, { loading: isGettingUserData, data }] = useGetUserLazyQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });
  const { user } = data || {};
  const onSubmit = event => {
    if (event) {
      handleEventBlock(event);
    }
    if (email) {
      getData({ variables: { email: trim(email) } });
    }
  };

  return (
    <Modal hideClose close={closeFn} style={{ width: '464px' }}>
      <ModalHeader title="Find a user" close={closeFn} />
      <ModalBody
        as={'form'}
        onSubmit={onSubmit}
        onKeyDown={event => (event?.key === 'Enter' ? onSubmit(event) : void 0)}
      >
        <SL.List
          mt={0}
          style={{
            flexFlow: 'column',
            alignItems: 'flex-start',
            justifyContent: 'stretch',
          }}
        >
          {/* email */}
          <SL.Row mt={8} width="100%">
            <SL.Item
              grow
              p={0}
              pt={8}
              style={{ flexFlow: 'column', alignItems: 'flex-start' }}
            >
              <SimpleTextInput
                border
                label="Email"
                value={email}
                error={user === null ? 'User not found.' : false}
                required
                onChange={e => setEmail(e.target.value)}
              />
            </SL.Item>
          </SL.Row>
        </SL.List>
        {user && user?.id && !isGettingUserData && (
          <Card style={{ minWidth: 300, marginTop: '15px' }}>
            <CardBody style={{ padding: '0px' }}>
              <Label paddingTop={'0px'}>First name</Label>
              <Text>{user.firstName}</Text>
              <Label>last name</Label>
              <Text>{user.lastName}</Text>
              <Label>Created at</Label>
              <Text>{dayjs(user.createdAt).format('MM/DD/YY')}</Text>
              <Label>Email</Label>
              <Text>{user.email}</Text>
              <Divider />
              <Label>Organization</Label>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <Text>{user.org.name}</Text>
                  <LinkWrapper href={`orgs/${user.org.id}`}>
                    {user.org.id}
                  </LinkWrapper>
                </div>
                <div>
                  <Button
                    tertiary
                    iconOnly
                    icon={<Icon name={SUPPORTED_ICON.new_window} size={16} />}
                    onMouseDown={event => {
                      event.stopPropagation();
                      const newWindow = event?.button === 1;
                      closeFn();
                      navigate(`admin/orgs/${user.org.id}`, {
                        newWindow: newWindow ? true : false,
                        omitOrgSlug: true,
                      });
                    }}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        )}
      </ModalBody>

      <ModalFooter hasBorderTop>
        <Button bold large onClick={closeFn} disabled={isGettingUserData}>
          Cancel
        </Button>
        <Button
          large
          primary
          submit
          loading={isGettingUserData}
          disabled={isGettingUserData}
          onClick={onSubmit}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UserLookupModal;
