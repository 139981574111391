import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Country, Language } from '@marketmuse/config/types/papi';
import {
  APPLICATIONS,
  NetworkStatus,
  NETWORK_STATUS,
} from '@marketmuse/config/types';

export interface AppQueryParams {
  term: string;
  url?: string;
  country: Country;
  language: Language;
  siteId: string;
}

export type AppUiSliceState<T> = {
  params: T;
  status: APPLICATIONS.AppUiStatus;
  validParams: boolean;
  appQuery: APPLICATIONS.AppQueryUiData | Record<string, never>;
};

// TODO: https://marketmuse.atlassian.net/browse/MMS-3728
export const AppQueryNetworkStatus = {
  error: NETWORK_STATUS.error,
  loading: NETWORK_STATUS.loading,
  queued: NETWORK_STATUS.queued,
  inactive: NETWORK_STATUS.inactive,
  complete: NETWORK_STATUS.complete,
} as const;

export enum AppQueryNetworkKeys {
  adwordsKeywords = 'adwordsKeywords',
  adwordsQuestions = 'adwordsQuestions',
  bulkAdwordsKeywords = 'bulkAdwordsKeywords',
  knowledgeGraph = 'knowledgeGraph',
  inventoryTopicsKeywords = 'inventoryTopicsKeywords',
  inventoryTopicsKG = 'inventoryTopicsKG',
  inventoryTopicsBulkKeywords = 'inventoryTopicsBulkKeywords',
  inventoryTopicsQuestions = 'inventoryTopicsQuestions',
  inventoryTopicsSerpQuestions = 'inventoryTopicsSerpQuestions',
  linkingRecommendations = 'linkingRecommendations',
  serpData = 'serpData',
  serpPages = 'serpPages',
  serpQuestions = 'serpQuestions',
  serpUserIntentData = 'serpUserIntentData',
  websiteHeatmap = 'websiteHeatmap',
  pageContents = 'pageContents',
}

export type AppQueryNetworkStatus = {
  [AppQueryNetworkKeys.bulkAdwordsKeywords]: NetworkStatus;
  [AppQueryNetworkKeys.knowledgeGraph]: NetworkStatus;
};

export type ThunkApiConfig = {
  extra: {
    apollo: ApolloClient<NormalizedCacheObject>;
  };
};
