const COUNTRY_CODES = {
  US: 'us',
  GB: 'gb',
  CA: 'ca',
  RU: 'ru',
  DE: 'de',
  FR: 'fr',
  ES: 'es',
  IT: 'it',
  BR: 'br',
  AU: 'au',
  AR: 'ar',
  BE: 'be',
  CH: 'ch',
  DK: 'dk',
  FI: 'fi',
  HK: 'hk',
  IE: 'ie',
  IL: 'il',
  MX: 'mx',
  NL: 'nl',
  NO: 'no',
  PL: 'pl',
  SE: 'se',
  SG: 'sg',
  TR: 'tr',
  JP: 'jp',
  IN: 'in',
  HU: 'hu',
  BG: 'bg',
  CN: 'cn',
  GR: 'gr',
  MY: 'my',
  NZ: 'nz',
  PH: 'ph',
  RO: 'ro',
  ZA: 'za',
  KR: 'kr',
  CO: 'co',
  PE: 'pe',
  VE: 've',
  CL: 'cl',
  GT: 'gt',
  EC: 'ec',
  BO: 'bo',
  PY: 'py',
  NI: 'ni',
  CR: 'cr',
};

export default COUNTRY_CODES;
