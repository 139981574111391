import { isNil } from 'lodash';
import { ResearchInventoryTopic } from '@marketmuse/data-state/types';

export const getUniqueInventoryTopics = (
  inventoryTopics?: Array<ResearchInventoryTopic>,
) => {
  const hash: Record<string | number, ResearchInventoryTopic> = {};

  for (let i = 0, len = inventoryTopics.length; i < len; ++i) {
    const item = inventoryTopics[i];

    if (!hash[item.term] || isNil(hash[item.term].volume)) {
      hash[item.term] = item;
    }
  }

  return Object.values(hash);
};
