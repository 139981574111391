import * as React from 'react';
import { Country, Language } from '@marketmuse/config/types/papi';
import { IResearchUiStateContext } from '../types';

const initialState = {
  displayExactDistribution: true,
  siteId: '',
  siteCountry: Country.US,
  term: '',
  url: '',
  country: Country.US,
  language: Language.EN,
  networkDomains: [],
  competitorDomains: [],
};

const ResearchUiStateContext =
  React.createContext<IResearchUiStateContext>(initialState);

export default ResearchUiStateContext;
