/* eslint-disable no-useless-escape */
// removes all characters from URL string besides domain name and tld
export const parseDomain = (url: string) =>
  url
    .replace(new RegExp(/^\s+/), '')
    .replace(new RegExp(/\s+$/), '')
    .replace(new RegExp(/\\/g), '/')
    .replace(new RegExp(/^http\:\/\/|^https\:\/\/|^ftp\:\/\//i), '')
    .replace(new RegExp(/^www\./i), '')
    .replace(new RegExp(/\/(.*)/), '')
    .toLowerCase();
