import { WordCountArgs } from '../types';
import calculate from './calculate';

const backgroundColor = (...args: WordCountArgs) => {
  const color = calculate(...args);
  if (color === 'red') return 'bg-red-400';
  if (color === 'yellow') return 'bg-yellow-400';
  return 'bg-green-400';
};

export default backgroundColor;
