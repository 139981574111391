import { forwardRef, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { Button, Icon, SUPPORTED_ICON } from '@marketmuse/components';
import { ExportTriggerProps } from '../types';

type ExtendedExportTriggerProps = ExportTriggerProps & {
  open?: boolean;
  showCaret?: boolean;
  onClick?: (event: MouseEvent) => void;
};

const ExportTrigger = forwardRef<HTMLButtonElement, ExtendedExportTriggerProps>(
  (
    {
      children = 'Export',
      disabled,
      locked,
      className,
      showCaret = true,
      loading,
      onClick,
    },
    ref,
  ) => {
    const iconName = useMemo(() => {
      if (locked) return SUPPORTED_ICON.locked;
      return SUPPORTED_ICON.export;
    }, [locked]);

    return (
      <Button
        ref={ref}
        as="span"
        size="xs"
        corners="lg"
        variant={disabled || locked ? 'gray500' : 'blue500OnBlue100'}
        className={twMerge(
          ['gap-2', 'text-sm', 'font-bold'],
          disabled && !loading && 'cursor-default',
          className,
        )}
        onClick={event => {
          if (!disabled) {
            onClick?.(event);
          }
        }}
      >
        <Icon name={iconName} className="-mt-0.5" />
        {children}
        {!locked && showCaret && (
          <Icon boxSize={16} name={SUPPORTED_ICON.caret_down} />
        )}
        {loading && (
          <Icon
            boxSize={12}
            name={SUPPORTED_ICON.spinner}
            className="ml-2 animate-spin text-blue-300"
          />
        )}
      </Button>
    );
  },
);

export default ExportTrigger;
