import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  border-radius: 4px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
`;

const ImageContainer = styled.div`
  flex-grow: 1;
  padding: 22px;
  svg {
    width: 100%;
  }
`;

const TextContainer = styled.div`
  flex-shrink: 0;
  padding: 14px;
  font-weight: 700;
  font-size: 13px;
  color: ${p => p.theme.mmxBlack};
  background-color: ${p => p.theme.mmxGreyF4};
`;

const AppZeroStateBlock = ({ svg, text, style } = {}) => (
  <Wrapper style={style}>
    <ImageContainer>{svg}</ImageContainer>
    <TextContainer>{text}</TextContainer>
  </Wrapper>
);

AppZeroStateBlock.propTypes = {
  style: PropTypes.object,
  left: PropTypes.bool,
  right: PropTypes.bool,
  text: PropTypes.string,
  svg: PropTypes.any,
};

export default AppZeroStateBlock;
