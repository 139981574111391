import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sanitizeTerm } from '@marketmuse/utilities';
import { AppUiStatus } from '@marketmuse/config/types/applications';

import validateAppParams from '../../../utils/validateAppParams';
import { AppQueryParams, OptimizeSnapshotInput } from '../../../types';
import {
  optimizeTaskFetchPage,
  optimizeTaskHydrateCheck,
  optimizeTaskSiteChange,
} from '../../actions';

import { initialState } from './ui.initialState';

export const uiSlice = createSlice({
  name: 'optimize/ui',
  initialState,
  reducers: {
    appQuerySet: (
      state,
      action: PayloadAction<OptimizeSnapshotInput['appQuery']>,
    ) => {
      const { id, status, term, url, country, language } = action.payload;

      state.appQuery = {
        id,
        status,
        term: sanitizeTerm(term),
        url,
        country,
        language,
      };
    },
    contentsSet: (state, action: PayloadAction<string>) => {
      state.contents = action.payload;
    },
    statusSet: (state, action: PayloadAction<AppUiStatus>) => {
      state.status = action.payload;
    },
    paramsSet: (state, action: PayloadAction<AppQueryParams>) => {
      state.params = action.payload;
      state.validParams = validateAppParams(action.payload);
    },
    paramsAndStatusSet: (
      state,
      action: PayloadAction<{
        params: AppQueryParams;
        status: AppUiStatus;
      }>,
    ) => {
      const { status, params } = action.payload;
      state.status = status;
      state.params = params;
      state.validParams = validateAppParams(params);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(optimizeTaskHydrateCheck, (state, action) => {
        if (
          state.status === AppUiStatus.creating ||
          state.status === AppUiStatus.loading
        ) {
          return {
            ...initialState,
            params: {
              ...initialState.params,
              ...action.payload,
            },
          };
        } else {
          return {
            ...state,
            params: {
              ...state.params,
              ...action.payload,
            },
          };
        }
      })
      .addCase(optimizeTaskSiteChange, (state, action) => {
        return {
          ...initialState,
          params: {
            ...initialState.params,
            ...action.payload,
          },
        };
      })
      .addCase(optimizeTaskFetchPage, state => {
        state.contents = null;
      });
  },
});

export const uiActions = uiSlice.actions;
