import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isNil, get } from 'lodash';

const Wrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${p => isNil(p.box) ? p.size : p.box}px;
  height: ${p => isNil(p.box) ? p.size : p.box}px;
  ${p => p.color && `
    color: ${get(p, `theme.${p.color}`) || p.color};
  `}
`;

const Icon = props => (
  <Wrapper
    className={`mm-icon mm mm-${props.name}${props.size} ${props.className}`}
    style={props.style}
    color={props.color}
    size={props.size}
    box={props.box}
  />
);

Icon.propTypes = {
  box: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]), // size of square icon is sitting in, defaults to "size"
  size: PropTypes.oneOf([12, 16, 28, "12", "16", "28", ""]).isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

Icon.defaultProps = {
  size: 16,
  className: '',
}

export default Icon;
