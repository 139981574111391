import React from 'react';
import styled from 'styled-components';
import { Icon, SUPPORTED_ICON } from '@marketmuse/components';

import Loader from '../../../components/Loader';

const IconCheckStyled = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column nowrap;

  svg {
    width: 14px;
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

const IconAppStatus = ({ status }) => {
  return (
    <>
      {status === 'loading' && <Loader />}
      {status === 'complete' && (
        <IconCheckStyled className="mm-tw">
          <Icon name={SUPPORTED_ICON.checkmark} color="text-green-400" />
        </IconCheckStyled>
      )}
    </>
  );
};

export default IconAppStatus;
