import { MiddlewareData, Placement } from '@floating-ui/react';
import { useMemo } from 'react';
import { POPPER_THEME } from '../../types';

type Side = 'top' | 'left' | 'right' | 'bottom';

export const useArrowColor = (theme: POPPER_THEME) => {
  return useMemo(() => {
    switch (theme) {
      case POPPER_THEME.dark:
        return {
          top: 'border-t-gray-800',
          bottom: 'border-b-gray-800',
          left: 'border-l-gray-800',
          right: 'border-r-gray-800',
        };
      case POPPER_THEME.light:
        return {
          top: 'border-t-white',
          bottom: 'border-b-white',
          left: 'border-l-white',
          right: 'border-r-white',
        };
      default:
        return {};
    }
  }, [theme]);
};

export const useArrow = (
  middlewareData: MiddlewareData,
  placement: Placement,
  theme: POPPER_THEME,
) => {
  const arrowColor = useArrowColor(theme);

  const arrowStyle = useMemo(() => {
    const staticSide: Side = placement.split('-')[0] as Side;
    const arrowSide = {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right',
    }[staticSide];
    const { x, y } = middlewareData.arrow || {
      x: 0,
      y: 0,
    };
    const width = {
      top: 8,
      left: 8,
      right: 8,
      bottom: 8,
    };
    width[arrowSide] = 0;
    const colorClassName = [
      'border-transparent',
      'shadow-sm',
      'z-40',
      arrowColor[staticSide],
    ];
    const borderWidth = `${width.top}px ${width.right}px ${width.bottom}px ${width.left}px`;
    const position = {
      left: x != null ? `${x}px` : '',
      top: y != null ? `${y}px` : '',
      [arrowSide]: '-7px',
      borderWidth: borderWidth,
    };
    return { colorClassName, position };
  }, [arrowColor, middlewareData.arrow, placement]);

  return arrowStyle;
};
