import * as types from '../config/types';

export const setAppsOnboarding = ({ id, ...args }) => ({
  type: types.SET_APPS_DATA_START,
  id,
  ...args,
});

export const setAppsHydrating = ({ id, params, siteId, callback }) => ({
  type: types.SET_APPS_DATA_HYDRATING,
  id,
  params,
  siteId,
  callback,
});

export const setAppsReducer = ({ payload }) => ({
  type: types.SET_APPS_REDUCER,
  payload,
});

export const getAppData = ({ app, params, callback }) => ({
  type: types.GET_APP_DATA,
  app,
  params,
  callback,
});

export const saveAppConfigState = ({ key, date, params, data }) => ({
  type: types.SAVE_APP_CONFIG_STATE,
  date,
  key,
  params,
  data,
});

export const saveAppData = (key, value) => ({
  type: types.SAVE_APP_DATA,
  key,
  value,
});

export const clearCacheItems = () => ({
  type: types.CLEAR_CACHE_ITEMS,
});

export const setToolState = (id, data) => ({
  type: types.SET_TOOL_STATE,
  id,
  data,
});

export const getExcel = ({ data, context, fn, filename, callback }) => ({
  type: types.GET_EXCEL,
  filename,
  data,
  context,
  fn,
  callback,
});

export const getAppQueries = ({ options, callback }) => ({
  type: types.GET_APP_QUERIES,
  options,
  callback,
});

export default {};
