import React from 'react';
import { PAPI } from '@marketmuse/config/types';
import hasAccess from '../utils/hasAccess';

import Research from '../containers/Tools/Research';
import Optimize from '../containers/Tools/Optimize/Optimize';
import Newsroom from '../containers/Tools/Newsroom/Newsroom';
import IconSvg from '../components/IconSvg';

export const APPLICATIONS_CONFIG_FLAT = {
  [PAPI.PermissionResource.RESEARCH_APPLICATION]: {
    id: {
      resource: PAPI.PermissionResource.RESEARCH_APPLICATION,
      action: PAPI.PermissionAction.ACCESS_DATA,
    },
    icon: <IconSvg name="research" />,
    title: 'Research Next',
    desc: `Discover important subtopics to incorporate into
    your content to improve rankings.`,
    tabClassName: '',
    color: 'red',
    component: Research,
    restricted: [],
    visible: false,
    comingSoon: false,
    dataRequirements: ['kg', 'scores'],
  },
  [PAPI.PermissionResource.OPTIMIZE]: {
    id: {
      resource: PAPI.PermissionResource.OPTIMIZE,
      action: PAPI.PermissionAction.ACCESS_DATA,
    },
    icon: <IconSvg name="optimize" />,
    title: 'Optimize',
    desc: `Create content that will drive up your rank based
    on our data-driven suggestions.`,
    tabClassName: '',
    color: 'orange',
    component: Optimize,
    restricted: [],
    visible: true,
    comingSoon: false,
    beta: true,
    dataRequirements: ['scores'],
  },
  [PAPI.PermissionResource.NEWSROOM]: {
    id: {
      resource: PAPI.PermissionResource.NEWSROOM,
      action: PAPI.PermissionAction.ACCESS_DATA,
    },
    icon: <IconSvg name="newsroom" />,
    title: 'Newsroom',
    desc: `Creating high-ranking content in the first draft`,
    tabClassName: '',
    color: 'blue',
    component: Newsroom,
    restricted: ['gold'],
    restrictedText:
      'Gain insights needed to quickly create content designed to rank in news search.',
    visible: true,
    comingSoon: false,
    dataRequirements: ['kg', 'kgNews', 'scoresNews', 'compareKgsNews'],
  },
};

export const getApplicationsConfig = () => ({
  [PAPI.PermissionResource.OPTIMIZE]: {
    id: {
      resource: PAPI.PermissionResource.OPTIMIZE,
      action: PAPI.PermissionAction.ACCESS_DATA,
    },
    iconId: 'optimize',
    iconSize: '16',
    title: 'Optimize',
    path: '/apps/optimize',
    locked: !hasAccess({
      resource: PAPI.PermissionResource.OPTIMIZE,
      action: PAPI.PermissionAction.ACCESS_DATA,
    }),
    showWhenLocked: true,
  },
  [PAPI.PermissionResource.NEWSROOM]: {
    id: {
      resource: PAPI.PermissionResource.NEWSROOM,
      action: PAPI.PermissionAction.ACCESS_DATA,
    },
    iconId: 'newsroom',
    iconSize: '16',
    title: 'Newsroom',
    path: '/apps/newsroom',
    locked: !hasAccess({
      resource: PAPI.PermissionResource.NEWSROOM,
      action: PAPI.PermissionAction.ACCESS_DATA,
    }),
    showWhenLocked: false,
  },
});
