import { useMemo } from 'react';

import { InitialTableState, ColumnFilter } from '@tanstack/react-table';
import { uniqueBy } from '@marketmuse/utilities';

const useInitialState = ({
  columns,
  initialState,
}: {
  columns: Record<string, any>[];
  initialState: InitialTableState;
}) => {
  const state = useMemo(() => {
    const initialFilters = columns
      .filter(column => column['meta']?.filter?.initialState)
      .map(column => ({
        id: column['id'],
        value: column['meta'].filter.initialState,
      }));
    const propsFilters = initialState?.columnFilters
      ? initialState.columnFilters
      : [];

    // eslint-disable-next-line
    // @ts-expect-error
    const columnFilters = uniqueBy<ColumnFilter>(
      [].concat(propsFilters, initialFilters),
      'id',
    ) as unknown as ColumnFilter[];

    return {
      ...(initialState || {}),
      columnFilters,
    };
  }, [initialState, columns]);

  return state;
};

export default useInitialState;
