import * as types from '../config/types';

export const upsertProject = ({ callback, key, ...args }) => ({
  type: types.UPSERT_PROJECT,
  callback,
  key,
  args,
});

export const upsertProjects = ({
  projects = [],
  fields = {},
  keys,
  callback,
}) => ({
  type: types.UPSERT_PROJECTS,
  fields,
  keys,
  callback,
  projects,
});

export const getProjects = ({ callback, limit, order, page, ...args }) => ({
  type: types.GET_PROJECTS,
  callback,
  limit,
  order,
  page,
  ...args,
});

export const getProject = ({ callback, id }) => ({
  type: types.GET_PROJECT,
  callback,
  id,
});

export const projectPlaceOrders = ({ items = [], callback, dryRun }) => ({
  type: types.PROJECT_PLACE_ORDERS,
  items,
  callback,
  dryRun,
});

export const removeProjectOrder = ({ callback, projectId, siteId }) => ({
  type: types.REMOVE_PROJECT_ORDER,
  callback,
  projectId,
  siteId,
});
