import { ApolloQueryResult } from '@apollo/client';
import { pick } from 'lodash';
import type {
  SeoWebsiteHeatmapQuery,
  SeoWebsiteHeatmapQueryVariables,
  WebsiteSerpItemPagesKgData,
} from '@marketmuse/config/types/papi';
import { SeoWebsiteHeatmapDocument } from '@marketmuse/data-papi';

import { ThunkApiConfig } from '../../types';
import createNonConcurrentAsyncThunk from '../../utils/createNonConcurrentAsyncThunk';

const getWebsiteHeatmap = createNonConcurrentAsyncThunk<
  WebsiteSerpItemPagesKgData[],
  SeoWebsiteHeatmapQueryVariables,
  ThunkApiConfig
>('runs/getWebsiteHeatmap', async (params, { extra, rejectWithValue }) => {
  const { data, errors }: ApolloQueryResult<SeoWebsiteHeatmapQuery> =
    await extra.apollo.query({
      query: SeoWebsiteHeatmapDocument,
      variables: pick(params, [
        'term',
        'kgTerms',
        'includePath',
        'excludePaths',
      ]),
    });

  if (errors) {
    return rejectWithValue(errors);
  }
  const seoWebsiteHeatmap = (data?.seoWebsiteHeatmap || []).slice(0, 20);

  return seoWebsiteHeatmap;
});

export default getWebsiteHeatmap;
