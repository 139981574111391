import { twMerge } from 'tailwind-merge';
import { useMemo } from 'react';
import {
  ButtonBar,
  ButtonBarProps,
  ButtonBarItem,
  Icon,
  SUPPORTED_ICON,
  Tooltip,
  POPPER_THEME,
} from '@marketmuse/components';
import { TabsProps } from './types';

const disabledButtonStyles = [
  'text-gray-500',
  'bg-transparent',
  'opacity-50',
  'cursor-default',
  'hover:bg-transparent',
  'hover:text-gray-500',
];

export function Tabs({ items, size, className }: TabsProps) {
  const buttonBarProps: ButtonBarProps = useMemo(() => {
    let activeId = undefined;

    const buttons = items.reduce(
      (
        acc,
        {
          id,
          title,
          disabled,
          active,
          Icon: RenderBefore,
          loading,
          onClick,
          tooltip,
        },
      ) => {
        if (active) activeId = id;

        const item: ButtonBarItem = {
          id,
          title,
          disabled,
          onClick,
          RenderBefore,
          className: twMerge(
            ['whitespace-nowrap'],
            disabled && disabledButtonStyles,
          ),
        };

        if (tooltip) {
          item.Wrapper = ({ children }) => (
            <Tooltip
              theme={POPPER_THEME.dark}
              showArrow={true}
              content={<div>{tooltip}</div>}
              placement="bottom"
              offsetSize={12}
              delay={{
                open: 350,
                close: 100,
              }}
            >
              <div>{children}</div>
            </Tooltip>
          );
        }

        if (loading) {
          item['RenderAfter'] = () => (
            <Icon
              className="animate-spin text-blue-300"
              name={SUPPORTED_ICON.spinner}
            />
          );
        }

        return [...acc, item];
      },
      [] as ButtonBarItem[],
    );

    return {
      dataId: 'research-tab',
      defaultProps: { size },
      items: buttons,
      activeId,
      className,
    };
  }, [className, items, size]);

  return <ButtonBar {...buttonBarProps} />;
}

export default Tabs;
