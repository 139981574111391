import { get, isEmpty } from 'lodash';

import { RecurlyUpsertBillingInfoDocument } from '@marketmuse/data-papi';

import { getRecurlyAccount } from '../../actions/recurlyActions';

import * as types from '../../config/types';
import isPaymentTestModeActive from '../../utils/isPaymentTestModeActive';
import makeRequest from '../../utils/makeRequest';
import Toast from '../../components/Toast';

export default () =>
  ({ getState, dispatch }) =>
  next =>
  action => {
    if (action.type === types.RECURLY_UPSERT_BILLING_INFO) {
      const state = getState();
      const useTestCode = isPaymentTestModeActive();
      const code = get(state, 'user.org.recurlyAccountCode');
      const codeTest = get(state, 'user.org.recurlyAccountCodeTest');
      const accountCode = useTestCode ? codeTest : code;

      if (!accountCode || isEmpty(accountCode)) {
        Toast.fire('Billing account is incomplete', 'error');
        if (typeof action.callbackFail === 'function') {
          action.callbackFail();
        }
        return next(action);
      }

      makeRequest({
        type: types.RECURLY_UPSERT_BILLING_INFO,
        mutation: RecurlyUpsertBillingInfoDocument,
        variables: {
          recurlyToken: action.recurlyToken,
          accountCode,
        },
        apolloOptions: {
          fetchPolicy: 'no-cache',
          context: {
            headers: {
              UseRecurlyTestCode: useTestCode,
            },
          },
        },
      }).then(res => {
        if (get(res, 'error')) {
          Toast.fire(res.error || 'Failed to update payment method', 'error');
          if (typeof action.callbackFail === 'function') {
            action.callbackFail();
          }
        } else {
          const billingInfo = get(res, 'recurlyUpsertBillingInfo');

          dispatch(
            getRecurlyAccount({
              accountCode: code,
              accountCodeTest: codeTest,
            }),
          );
          if (typeof action.callback === 'function') {
            action.callback(billingInfo);
          }
        }
      });
    }

    return next(action);
  };
