import { Size } from './types';

export const getWidth = (size: Size) => {
  switch (size) {
    case 18:
      return 'w-[18px]';
    case 20:
      return 'w-[20px]';
    case 22:
      return 'w-[22px]';
    case 24:
      return 'w-6';
    case 28:
      return 'w-7';
    case 38:
      return 'w-[38px]';
    default:
      return 'w-[18px]';
  }
};

export const getHeight = (size: Size) => {
  switch (size) {
    case 18:
      return 'h-[18px]';
    case 20:
      return 'h-[20px]';
    case 22:
      return 'h-[22px]';
    case 24:
      return 'h-6';
    case 28:
      return 'h-7';
    case 38:
      return 'h-[38px]';
    default:
      return 'h-[18px]';
  }
};

export const getFontSize = (size: Size) => {
  switch (size) {
    case 18:
      return 'text-[10px]';
    case 28:
    case 24:
    case 22:
    case 20:
      return 'text-[11px]';
    case 38:
      return 'text-sm';
    default:
      return 'text-[10px]';
  }
};

export const getRounded = (size: Size) => {
  switch (size) {
    case 18:
      return 'rounded-[18px]';
    case 20:
      return 'rounded-[20px]';
    case 22:
      return 'rounded-[22px]';
    case 24:
      return 'rounded-3xl';
    case 28:
      return 'rounded-[14px]';
    case 38:
      return 'rounded-[38px]';
    default:
      return 'rounded-[18px]';
  }
};
