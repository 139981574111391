import {
  combineReducers,
  createSlice,
  original,
  PayloadAction,
  isDraft,
} from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/types/types-external';
import { InventoryType } from '@marketmuse/config/types/papi';

import * as ACTION_TYPES from '../actionTypes';
import {
  SavedViewCurrentSetPayload,
  SavedViewsListPayload,
  SavedViewUpdateUiConfig,
  SavedViewsDeletePayload,
  InventoryTypeSlice,
} from '../types/savedViews';

import { initialState } from './savedViews.initialState';
import {
  SAVED_VIEW_UPSERT,
  SAVED_VIEW_UI_CONFIG_SET,
  SAVED_VIEW_DELETE_FROM_STATE,
  SAVED_VIEW_CURRENT_SET,
  SAVED_VIEWS_LIST_SET,
} from './savedViews.actionTypes';

const handleUpsert = (
  state: WritableDraft<InventoryTypeSlice>,
  { payload }: PayloadAction<SavedViewsListPayload>,
) => {
  payload.items.forEach(view => {
    const index = state.list.findIndex(({ id }) => id === view.id);
    if (index != -1) {
      state.list[index] = view;
    } else {
      state.list.push(view);
    }
    if (state.current.id === view.id) {
      state.current = view;
    }
  });
};

const handleUiConfigUpdate = (
  state: WritableDraft<InventoryTypeSlice>,
  { payload }: PayloadAction<SavedViewUpdateUiConfig>,
) => {
  const initial = state.tableUiConfig[payload.tableUiConfigType];

  const last = isDraft(initial)
    ? original(state.tableUiConfig[payload.tableUiConfigType])
    : {};
  state.tableUiConfig[payload.tableUiConfigType] = {
    ...last,
    ...payload.config,
  };
};

const handleDelete = (
  state: WritableDraft<InventoryTypeSlice>,
  { payload }: PayloadAction<SavedViewsDeletePayload>,
) => {
  if (state.current.id === payload.id) {
    state.current = {};
  }
  state.list = state.list.filter(({ id }) => id !== payload.id);
};

const createSavedViewSlice = (inventoryType: InventoryType) => {
  const initialSliceState = initialState[inventoryType];

  return createSlice({
    name: inventoryType,
    initialState: initialSliceState,
    reducers: {
      reset: () => {
        return initialSliceState;
      },
    },
    extraReducers: builder => {
      builder
        .addMatcher(action => {
          return (
            action?.payload?.inventoryType === inventoryType &&
            action.type === SAVED_VIEW_UPSERT
          );
        }, handleUpsert)
        .addMatcher(
          action =>
            action?.payload?.inventoryType === inventoryType &&
            action.type === SAVED_VIEWS_LIST_SET,
          (state, { payload }: PayloadAction<SavedViewsListPayload>) => {
            state.list = payload.items;
          },
        )
        .addMatcher(
          action =>
            action?.payload?.inventoryType === inventoryType &&
            action.type === SAVED_VIEW_UI_CONFIG_SET,
          handleUiConfigUpdate,
        )
        .addMatcher(
          action => {
            return (
              action?.payload?.savedView?.inventoryType === inventoryType &&
              action.type === SAVED_VIEW_CURRENT_SET
            );
          },
          (state, { payload }: PayloadAction<SavedViewCurrentSetPayload>) => {
            state.tableUiConfig.default.page = 1;
            state.tableUiConfig.default.query = '';
            state.current = payload.savedView;
          },
        )
        .addMatcher(action => {
          return (
            action?.payload?.inventoryType === inventoryType &&
            action.type === SAVED_VIEW_DELETE_FROM_STATE
          );
        }, handleDelete)
        .addMatcher(
          action =>
            [
              ACTION_TYPES.ACTIVE_INVENTORY_SELECT,
              ACTION_TYPES.SIGN_OUT,
            ].includes(action.type),
          () => {
            return initialSliceState;
          },
        );
    },
  });
};

const pageTopicsSlice = createSavedViewSlice(InventoryType.PAGE_TOPICS);
const pagesSlice = createSavedViewSlice(InventoryType.PAGES);
const topicsSlice = createSavedViewSlice(InventoryType.TOPICS);
const projectsSlice = createSavedViewSlice(InventoryType.PROJECTS);
const plansSlice = createSavedViewSlice(InventoryType.PLANS);
const briefsSlice = createSavedViewSlice(InventoryType.BRIEFS);
const savedWritingSlice = createSavedViewSlice(InventoryType.SAVED_WRITING);

export const savedViewsActions = {
  pageTopics: pageTopicsSlice.actions,
  pages: pagesSlice.actions,
  topics: topicsSlice.actions,
  projects: projectsSlice.actions,
  plans: plansSlice.actions,
  briefs: briefsSlice.actions,
  savedWriting: savedWritingSlice.actions,
};

export const savedViewsReducer = combineReducers({
  PAGE_TOPICS: pageTopicsSlice.reducer,
  PAGES: pagesSlice.reducer,
  TOPICS: topicsSlice.reducer,
  PROJECTS: projectsSlice.reducer,
  PLANS: plansSlice.reducer,
  BRIEFS: briefsSlice.reducer,
  SAVED_WRITING: savedWritingSlice.reducer,
});
