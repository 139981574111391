import { AddRouteParamArgs } from './types';

export const addRouteParams = ({
  omitOrgSlug,
  omitSiteId,
  orgSlug,
  pathSuffix,
  siteId,
}: AddRouteParamArgs) => {
  const pathArgs = [];
  if (!omitOrgSlug) {
    pathArgs.push(orgSlug);

    if (!omitSiteId) {
      pathArgs.push(siteId);
    }
  }

  pathArgs.push(pathSuffix);

  const joinedPath = pathArgs.join('/');

  return `a/${joinedPath}`;
};
