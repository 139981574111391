import { ContentScoreArgs } from '../types';
import calculate from './calculate';

const backgroundColor = (...args: ContentScoreArgs) => {
  const color = calculate(...args);
  if (color === 'red') return 'bg-red-400';
  if (color === 'yellow') return 'bg-yellow-400';
  if (color === 'green') return 'bg-green-400';
  if (color === 'blue') return 'bg-blue-400';
  return 'bg-gray-400';
};

export default backgroundColor;
