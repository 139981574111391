import styled from 'styled-components';

import { SelectionWrapper } from '../../../../components/Tooltip/Popper';
import SimpleTextInput from '../../../../components/SimpleTextInput/SimpleTextInput';

export const SimpleTextInputStyled = styled(SimpleTextInput)`
  height: 40px;
  width: 100%;
  outline: none !important;
  padding: 0 4px;
  border: 1px solid ${p => p.theme.colors.grey15};
  input {
    ${p => p.theme.type.textRegular};
  }
  &::after {
    box-shadow: none;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: strech;
  background-color: white;
  position: relative;
  width: 100%;
  outline: none !important;
`;

export const FilterCustomWrapper = styled.div`
  display: flex;
  align-items: baseline;
  width: 100%;
`;

export const FilterCustom = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  ${FilterWrapper} {
    &:not(:first-of-type) {
      ${SelectionWrapper}, ${SimpleTextInputStyled} {
        border-top: none;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
    }
    &:first-of-type {
      ${SelectionWrapper}, ${SimpleTextInputStyled} {
        border-radius: 0;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }
    }
    &:last-of-type {
      ${SelectionWrapper}, ${SimpleTextInputStyled} {
        border-top: none;
        border-radius: 0;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
  }

  .mmx-input-area--filter {
    margin-right: -1px;
  }
`;
