import { default as wordsIncludesExcludes } from './filters/FilterIncludesExcludes/filter';
import { default as numberInRangeOptional } from './filters/FilterNumberBetween/filter';
import { default as stringInList } from './filters/FilterList/filter';

export * from './Table';
export * from './useTableContext';
export * as TableFilters from './filters';
export * as tableColumns from './columns';
export * as tableSorting from './sorting';
export * as tableComponents from './components';

export const tableFilters = {
  wordsIncludesExcludes,
  numberInRangeOptional,
  stringInList,
};
