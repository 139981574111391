import { twMerge } from 'tailwind-merge';
import { forwardRef } from 'react';
import {
  SUPPORTED_ICON,
  Polymorphic,
  Icon,
  Tooltip,
} from '@marketmuse/components';
import { useColorClasses } from './useColorClasses';

export interface AddToInventoryCircleProps {
  className?: string | Array<string | string[]>;
  loading?: boolean;
  disabled?: boolean;
  existsInInventory?: boolean;
  onClick?: (data: string) => void;
  term: string;
}

export const AddToInventoryCircle = forwardRef<
  HTMLButtonElement,
  AddToInventoryCircleProps
>(
  (
    {
      className,
      onClick,
      loading = false,
      disabled = false,
      existsInInventory = false,
      term,
    },
    ref,
  ) => {
    const colorClasses = useColorClasses(existsInInventory, disabled, loading);

    return (
      <Tooltip
        placement="bottom-end"
        tooltipClassName={['p-3']}
        showArrow={false}
        disabled={disabled}
        content={
          existsInInventory ? (
            <>
              This topic exists in your inventory. Visit{' '}
              <strong>Reflect</strong> for more insights into its performance.
            </>
          ) : (
            <>
              This topic is not yet in your inventory. Add it now to get
              personalized insights.
            </>
          )
        }
      >
        <Polymorphic
          ref={ref}
          as="button"
          className={twMerge(
            [
              'flex',
              'flex-shrink-0',
              'justify-center',
              'items-center',
              'border',
              'rounded-full',
              'h-fit',
              'w-[14px]',
              'h-[14px]',
            ],
            colorClasses,
            className,
          )}
          onClick={() => {
            if (typeof onClick === 'function' && term && !existsInInventory) {
              onClick(term);
            }
          }}
          disabled={loading}
        >
          {!loading && (
            <Icon name={SUPPORTED_ICON.plus} boxSize={10} iconSize={12} />
          )}
        </Polymorphic>
      </Tooltip>
    );
  },
);

export default AddToInventoryCircle;
