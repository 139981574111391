import { ACTION_TYPES } from '@marketmuse/data-state';
import * as types from '../config/types';

export const setSimple = ({ key, value, persist = false }) => ({
  type: ACTION_TYPES.SET_SIMPLE,
  key,
  value,
  persist,
});

export const sidebarToggle = () => ({
  type: types.SIDEBAR_TOGGLE,
});

export const toggleDynamic = ({ id, status = null, data = null }) => {
  return {
    type: ACTION_TYPES.TOGGLE_DYNAMIC,
    payload: {
      id,
      status,
      data,
    },
  };
};

export const getSnapshotData = ({ key, callback }) => ({
  type: types.GET_SNAPSHOT_DATA,
  key,
  callback,
});

export const validateEmail = ({ email, callback }) => ({
  type: types.VALIDATE_EMAIL,
  email,
  callback,
});

export const inquirePremium = ({ planLevel, callback }) => ({
  type: types.INQUIRE_PREMIUM,
  planLevel,
  callback,
});
