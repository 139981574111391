import React from 'react';
import PropTypes from 'prop-types';

import Modal, { ModalBody } from '../../../../components/Modal';
import Portal from '../../../../components/Portal';
import Snapshots from './Snapshots';

const SnapshotsModal = props => (
  <Portal>
    <Modal close={props.close}>
      <ModalBody hasHeader style={{ marginTop: -22 }}>
        <Snapshots
          close={props.close}
          app={props.app}
          loadSnapshotData={props.loadSnapshotData}
        />
      </ModalBody>
    </Modal>
  </Portal>
);

SnapshotsModal.propTypes = {
  close: PropTypes.func,
  app: PropTypes.string,
  loadSnapshotData: PropTypes.func,
};

export default SnapshotsModal;
