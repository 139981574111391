import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useStoreSelector } from '@marketmuse/data-state/hooks';
import { statBandMentions } from '@marketmuse/utilities';

const Wrapper = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: ${p => p.theme.colors[p.color || 'grey25']};
  height: 32px;
  min-height: 32px;
  border-radius: 8px;
  margin: auto;
  width: 52px;
  font-weight: bold;
`;

type DistributionProps = {
  value: number;
  forceStyle?: 'exact' | 'band';
  className?: string;
  style?: React.CSSProperties;
};

const Distribution = ({
  value,
  className,
  style,
  forceStyle,
}: DistributionProps) => {
  const displayExactDistribution = useStoreSelector(
    state => state.user.displayExactDistribution,
  );

  const { color, band } = statBandMentions.calculate(value);

  return (
    <Wrapper
      color={color}
      className={`${className} mms--distribution`}
      style={style}
    >
      {forceStyle && forceStyle === 'exact' && value}
      {forceStyle && forceStyle === 'band' && band}
      {!forceStyle && (displayExactDistribution ? value : band)}
    </Wrapper>
  );
};

Distribution.propTypes = {
  value: PropTypes.number,
  forceStyle: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default Distribution;
