import { AppQueryNetworkStatus, ReduxListener } from '../../../types';

import { researchTaskStatusCheck } from '../../actions';
import { dataSlice, networkSlice } from '../../slices';

import { getSerpUserIntentData } from '../../thunks';

const listeners = (startListening: ReduxListener) => {
  const dataActions = dataSlice.actions;
  const networkActions = networkSlice.actions;

  startListening({
    actionCreator: getSerpUserIntentData.pending,
    effect: async (action, { dispatch }) => {
      await dispatch(
        networkActions.serpUserIntentDataSet(AppQueryNetworkStatus.loading),
      );
    },
  });

  startListening({
    actionCreator: getSerpUserIntentData.rejected,
    effect: async (action, { dispatch }) => {
      const { aborted } = action.meta;
      if (!aborted) {
        await dispatch(
          networkActions.serpUserIntentDataSet(AppQueryNetworkStatus.error),
        );

        // check status after other steps
        await dispatch(researchTaskStatusCheck());
      }
    },
  });

  startListening({
    actionCreator: getSerpUserIntentData.fulfilled,
    effect: async ({ payload }, { dispatch }) => {
      await Promise.all([
        dispatch(dataActions.serpUserIntentDataSet(payload.data)),
        dispatch(
          networkActions.serpUserIntentDataSet(AppQueryNetworkStatus.complete),
        ),
      ]);

      // check status after other steps
      await dispatch(researchTaskStatusCheck());
    },
  });
};

export default listeners;
