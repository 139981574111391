import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Distribution from '../../components/Distribution';

const DistContainer = styled.div`
  flex-shrink: 0;
  min-width: 80px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  right: 20px;
  bottom: 0;
  padding: 10px 20px;
  background-color: ${p => p.theme.mmxDark};
  font-size: 10pt;
  max-width: 500px;
  color: white;
`;

const Keyword = styled.div`
  flex-shrink: 0;
  margin-right: 20px;
`;

const Url = styled.div`
  color: ${p => p.theme.mmxBlue};
  margin-right: 20px;
  ${p => p.theme.truncate}
`;

class MatrixHoverGuide extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  render() {
    return (
      <Wrapper>
        <Url>{this.props.url}</Url>
        <Keyword>{this.props.keyword}</Keyword>
        <DistContainer>
          <Distribution value={this.props.mentions} />
        </DistContainer>
      </Wrapper>
    );
  }
}

MatrixHoverGuide.propTypes = {
  url: PropTypes.string,
  keyword: PropTypes.string,
  mentions: PropTypes.number,
};

export default MatrixHoverGuide;
