import { get, isNil } from 'lodash';

import { FormFieldUpdateDocument } from '@marketmuse/data-papi';
import makeRequest from '../../utils/makeRequest';
import Toast from '../../components/Toast';
import * as types from '../../config/types';

export default () => () => next => action => {
  if (action.type === types.UPDATE_FORM_FIELD) {
    makeRequest({
      type: types.UPDATE_FORM_FIELD,
      mutation: FormFieldUpdateDocument,
      variables: {
        formFieldId: action.id,
        formFieldInput: {
          groupId: action.groupId,
          ...action.data,
          ...action.params,
        },
      },
      apolloOptions: {
        fetchPolicy: 'no-cache',
      },
    }).then(res => {
      const data = get(res, 'formFieldUpdate') || null;

      if (typeof action.callback === 'function' && !isNil(data)) {
        action.callback(data);
      }

      if (res.error || res.errors) {
        Toast.fire('Error encountered trying to save this field', 'error');
      }
    });
  }

  return next(action);
};
