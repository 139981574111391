import { useMemo } from 'react';
import { LANGUAGES_CODES } from '../../../config/countryLanguageCombinations';

export function useActiveLanguage({ country, activeLanguage }) {
  return useMemo(() => {
    const languages = country?.languages;
    const suggestedLanguage =
      languages?.find(({ id: languageId }) => languageId === activeLanguage) ||
      languages?.[0] ||
      languages?.[LANGUAGES_CODES.EN];

    return suggestedLanguage;
  }, [country, activeLanguage]);
}
