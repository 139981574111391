import {
  InventoryVersion,
  VersionStatusEnum,
  Site,
  Permission,
  PermissionResource,
  PermissionAction,
} from '@marketmuse/config/types/papi';

import validatePermission from '../access/validatePermission';

interface VersionInterface {
  failed?: InventoryVersion['failed'];
  status?: InventoryVersion['status'];
  visible?: InventoryVersion['visible'];
  [x: string]: any;
}

export const checkSiteViableInventory = ({
  invId,
  permissions,
  versions,
}: {
  invId: Site['invId'];
  permissions: Array<Permission>;
  versions: Array<VersionInterface>;
}) => {
  const hasValidVersion = versions.find(
    version =>
      version?.failed === 0 &&
      version?.visible === 1 &&
      version?.status === VersionStatusEnum.FINISHED,
  );

  const hasInventoryAccess = validatePermission({
    permissions,
    permission: {
      resource: PermissionResource.SITE_INVENTORY,
      action: PermissionAction.ACCESS_DATA,
    },
  });

  if (invId && hasValidVersion && hasInventoryAccess) {
    return true;
  }

  return false;
};
