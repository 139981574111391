import { isEmpty, trim } from 'lodash';
import { sanitizeTerm, sanitizePageUrl } from '@marketmuse/utilities';
import { AppQueryParams } from '../types';

function sanitizeAppParams<T extends AppQueryParams>(params: T) {
  const initial = [
    'siteId',
    'connectCompetitorDomains',
    'connectNetworkDomains',
    'country',
    'language',
    'term',
    'url',
  ].reduce((acc, key) => {
    const value = params[key];
    if (isEmpty(value)) {
      return acc;
    } else {
      const valueClean = !Array.isArray(value) ? trim(value) : value.map(trim);
      return {
        ...acc,
        [key]: valueClean,
      };
    }
  }, {}) as unknown as T;

  const variables = {
    ...initial,
    term: sanitizeTerm(initial.term, params.language),
    ...(initial.url ? { url: sanitizePageUrl(initial.url) } : {}),
  };
  return variables as T;
}

export default sanitizeAppParams;
