import React from 'react';
import { FloatingUi } from '../FloatingUi';
import { POPPER_THEME, POPPER_TYPE, PopoverProps } from '../types';

type PopoverComponent = (props: PopoverProps) => React.ReactElement | null;

export const Popover: PopoverComponent = React.forwardRef<
  HTMLElement,
  PopoverProps
>(({ theme = POPPER_THEME.dark, showArrow = true, render, ...props }, ref) => {
  return (
    <FloatingUi
      {...props}
      ref={ref}
      popperType={POPPER_TYPE.popover}
      showArrow={showArrow}
      theme={theme}
      render={render}
    />
  );
});
