import { get, isNil } from 'lodash';

import { FormFieldCreateDocument } from '@marketmuse/data-papi';
import makeRequest from '../../utils/makeRequest';
import Toast from '../../components/Toast';
import * as types from '../../config/types';

export default () => () => next => action => {
  if (action.type === types.CREATE_FORM_FIELD) {
    const keys = [];
    // New new field being added
    if (action.groupId) {
      keys.push(`form_field_add_new`);
      keys.push(`form_field_add_in_${action.groupId}`);
    }

    makeRequest({
      type: types.CREATE_FORM_FIELD,
      mutation: FormFieldCreateDocument,
      variables: {
        formFieldInput: {
          groupId: action.groupId,
          ...action.data,
          ...action.params,
        },
      },
      apolloOptions: {
        fetchPolicy: 'no-cache',
      },
      keys,
    }).then(res => {
      const data = get(res, 'formFieldCreate') || null;

      if (typeof action.callback === 'function' && !isNil(data)) {
        action.callback(data);
      }

      if (res.error || res.errors) {
        Toast.fire('Error encountered trying to save this field', 'error');
      }
    });
  }

  return next(action);
};
