import { stripHttp, stripWWW, stripSlash } from '../misc';

const cleanUrl = url => stripHttp(stripWWW(stripSlash(url)));

// convert app configuration into a key for data to be cached under
export default params => {
  // if type==='roi' - TODO: key for roi
  // else:
  return `___${[
    `t-${params['topic']}`,
    `u-${cleanUrl(params['url']) || 'x'}`,
    `ba-${params['bodyOrArticle'] || 'article'}`,
    `p-${params['people'] ? 1 : 0}`,
    `b-${params['brands'] ? 1 : 0}`,
    `c-${params['country'] || 'us'}`,
    `cs-${params['competitorSites'] || 'x'}`,
    `ns-${params['networkSites'] || 'x'}`
  ].join('|')}`;
}
