import { FetchResult } from '@apollo/client';
import { omit } from 'lodash';
import type {
  AppQuery,
  AppQueryCreateOrUpdateMutation,
  AppQueryCreateOrUpdateMutationVariables,
} from '@marketmuse/config/types/papi';
import { AppQueryCreateOrUpdateDocument } from '@marketmuse/data-papi';
import { errorsToString } from '@marketmuse/utilities';

import { ThunkApiConfig, AppQueryParams } from '../../types';
import createNonConcurrentAsyncThunk from '../../utils/createNonConcurrentAsyncThunk';
import sanitizeAppParams from '../../utils/sanitizeAppParams';

const appQueryUpsert = createNonConcurrentAsyncThunk<
  {
    data: AppQuery;
    params: AppQueryParams;
  },
  AppQueryCreateOrUpdateMutationVariables,
  ThunkApiConfig
>('optimize/appQueryUpsert', async (params, { extra, rejectWithValue }) => {
  const variables = sanitizeAppParams<AppQueryParams>(omit(params, 'app'));

  const response: FetchResult<AppQueryCreateOrUpdateMutation> =
    await extra.apollo.mutate({
      mutation: AppQueryCreateOrUpdateDocument,
      variables: {
        ...variables,
        app: params.app,
      },
    });

  if (response.errors) {
    return rejectWithValue(
      errorsToString(response.errors as unknown as Array<Error>),
    );
  }

  return {
    data: response?.data?.appQueryCreateOrUpdate,
    params: variables,
  };
});

export default appQueryUpsert;
