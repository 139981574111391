import {
  useFloating,
  useTransitionStyles,
  autoUpdate,
  offset,
  flip,
  shift,
  useRole,
  useInteractions,
  useClick,
  useDismiss,
  useHover,
  arrow,
} from '@floating-ui/react';
import { useState, useMemo, useRef } from 'react';
import { FloatingUiStateProps, POPPER_TYPE } from '../../types';

export function usePopper({
  initialOpen,
  placement,
  offsetSize,
  popperType,
  middlewares,
  showArrow,
  popOnHover,
  hideOnScroll = false,
  delay,
  onOpenChange,
  duration = {
    open: 200,
    close: 200,
  },
}: FloatingUiStateProps) {
  const arrowRef = useRef(null);
  const [open, setOpen] = useState(initialOpen);
  const middleware = [
    offset(offsetSize),
    flip(),
    shift(),
    ...(middlewares ? middlewares : []),
  ];

  if (showArrow) {
    middleware.push(arrow({ element: arrowRef }));
  }

  const data = useFloating({
    placement,
    open,
    onOpenChange: (nextOpen, event, reason) => {
      setOpen(nextOpen);
      onOpenChange?.(nextOpen, event, reason);
    },
    middleware: middleware,
    whileElementsMounted: autoUpdate,
  });
  const { isMounted, styles } = useTransitionStyles(data.context, {
    duration,
  });

  const click = useClick(data.context, { enabled: !popOnHover });
  const hover = useHover(data.context, {
    enabled: popperType === POPPER_TYPE.tooltip || popOnHover,
    move: false,
    delay: delay,
  });

  const dismiss = useDismiss(data.context, {
    ancestorScroll: hideOnScroll,
  });

  const role = useRole(
    data.context,
    popperType === POPPER_TYPE.tooltip ? { role: 'tooltip' } : {},
  );
  const interactions = useInteractions([
    popperType === POPPER_TYPE.popover ? click : hover,
    dismiss,
    role,
  ]);

  return useMemo(
    () => ({
      open,
      setOpen,
      arrowRef,
      isMounted,
      styles,
      ...interactions,
      ...data,
    }),
    [open, setOpen, interactions, data, isMounted, styles],
  );
}
