export const intentTitle = {
  TRANSACTIONAL: 'Transactional',
  LOCAL: 'Location',
  INFO_KNOW: 'Know',
  INFO_KNOW_SIMPLE: 'Know Simple',
  INFO_COMPARISON: 'Comparison',
  WEBSITE_QUERY: 'Website Query',
};

export const intentTitleTruncated = {
  TRANSACTIONAL: 'Tran.',
  LOCAL: 'Map',
  INFO_KNOW: 'Know',
  INFO_KNOW_SIMPLE: 'Simple',
  INFO_COMPARISON: 'Comp.',
  WEBSITE_QUERY: 'Site',
};
