import { useCallback } from 'react';
import { useExportConnectLazyQuery } from '@marketmuse/data-papi';
import { createDownload } from '@marketmuse/utilities';
import { toast } from '@marketmuse/components';
import { ExportConnectPayload } from '../types';
import useToaster from './useToaster';

const useExportConnect = ({
  canExport,
  term,
  url,
}: {
  canExport: boolean;
  term: string;
  url?: string;
}) => {
  const { premiumFeatureToast, exportSuccessfulToast } = useToaster();
  const [exportFn, { loading }] = useExportConnectLazyQuery({
    fetchPolicy: 'no-cache',
    errorPolicy: 'none',
    notifyOnNetworkStatusChange: true,
    context: {
      omitTypename: true,
    },
    onError: () => {
      toast.error('Sorry, we were unable to export Connect.');
    },
    onCompleted: data => {
      if (data?.exportConnect?.data) {
        exportSuccessfulToast();
        createDownload(data.exportConnect.data, `connect-${term}`);
      }
    },
  });

  const handler = useCallback(
    ({ recommendations }: ExportConnectPayload) => {
      if (!canExport) {
        premiumFeatureToast();
        return;
      }
      console.log('do exprot');
      exportFn({
        variables: {
          generationContext: {
            term,
            url,
          },
          seoConnectData: {
            ...recommendations,
          },
        },
      });
    },
    [canExport, exportFn, term, url, premiumFeatureToast],
  );

  return { handler, loading };
};

export default useExportConnect;
