import { Plugin, type Editor } from '@ckeditor/ckeditor5-core';
import { editor as editorConfig } from '@marketmuse/config/configs';
import { InsertTextCommand } from './InsertTextCommand';

const {
  INSERT_TEXT: { COMMANDS, SETTINGS },
} = editorConfig;

export default class InsertText extends Plugin {
  static get pluginName() {
    return SETTINGS.NAME;
  }

  public init(): void {
    const editor = this.editor as Editor;
    editor.commands.add(COMMANDS.insertText, new InsertTextCommand(editor));
  }
}
