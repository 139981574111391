export default () => {
  try {
    if (!window.qualified) {
      return console.error('Qualified not initialized');
    }

    window.qualified('showExperience', 'experience-1671037994601');
  } catch (error) {
    console.log('OpenChat: Qualified', error);
  }
};
