import AUTH_ROLES, { AuthRoles } from '../../types/authRoles';

type UserConfig = {
  id: AuthRoles;
  title: string;
};

type UsersConfig = Record<AuthRoles, UserConfig>;

export const configs: UsersConfig = {
  [AUTH_ROLES.SUPER_ADMIN]: {
    id: AUTH_ROLES.SUPER_ADMIN,
    title: 'MM Admin',
  },
  [AUTH_ROLES.ADMIN]: {
    id: AUTH_ROLES.ADMIN,
    title: 'Admin',
  },
  [AUTH_ROLES.MM_CX_ADMIN]: {
    id: AUTH_ROLES.MM_CX_ADMIN,
    title: 'MM CX Admin',
  },
  [AUTH_ROLES.USER]: {
    id: AUTH_ROLES.USER,
    title: 'User',
  },
  [AUTH_ROLES.CLIENT]: {
    id: AUTH_ROLES.CLIENT,
    title: 'Brief Manager',
  },
  [AUTH_ROLES.GUEST]: {
    id: AUTH_ROLES.GUEST,
    title: 'Guest',
  },
  [AUTH_ROLES.AUTH0]: {
    id: AUTH_ROLES.AUTH0,
    title: 'Auth0 User',
  },
};
