import get from 'lodash/get';

export const isBriefs = () => {
  const origin = get(window, 'location.origin') || '';
  const hr = get(window, 'location.href') || '';
  const briefUri = `${origin}/brief/`;
  return !!hr.match(briefUri);
};

export const isProd = () => {
  const hr = get(window, 'location.href') || '';
  return (
    !!hr.match('https://app.marketmuse.com') ||
    !!hr.match('mm-suite.netlify.app')
  );
};

export const isStaging = () => {
  const hr = get(window, 'location.href') || '';
  return (
    !!hr.match('https://staging.marketmuse.com') ||
    !!hr.match('mm-staging.netlify.app')
  );
};

export const isDev = () => {
  const hr = get(window, 'location.href') || '';
  return !!hr.match('https://dev.marketmuse.com');
};

export const isLocal = () => {
  const hr = get(window, 'location.href') || '';
  return !!hr.match('localhost:3000') || !!hr.match('http://127.0.0.1');
};

export const isNewPricingAndPackagingSupported = () => {
  return process.env.REACT_APP_IS_NEW_PRICING_AND_PACKAGING_ENABLED === 'true';
};
