import get from 'lodash/get';

// returns function that toggles sticky value of given column
export const getToggleSticky = (sticky, setSticky) => id =>
  setSticky({ ...sticky, [id]: !sticky[id] });

// calculate the total width of the table
export const calculateTotalWidth = table =>
  Object.values(table).reduce((acc, c) => acc + c.width, 0);

// order the columns based on the `order` key, stickies first
export const getOrderColumns = sticky => table => {
  const colsSticky = Object.values(table)
    .filter(c => sticky[c.id])
    .sort((a, b) => a.order - b.order);
  const colsNonSticky = Object.values(table)
    .filter(c => !sticky[c.id])
    .sort((a, b) => a.order - b.order);

  return colsSticky.concat(colsNonSticky);
};

export const getCalculateZIndex = (sticky, table) => (id, header) => {
  const colsOrdered = getOrderColumns(sticky)(table);
  return (
    (header ? 9999 : 999) +
    (colsOrdered.length - colsOrdered.map(c => c.id).indexOf(id))
  );
};

// calculate left px value of given sticky column
export const getCalculateLeft = (sticky, table) => id => {
  // non-sticky columns doesn't have a left value
  if (!sticky[id]) return null;
  // get col to calculate
  const colCurrent = table[id];
  // return width cols that are sticky and comes before this col
  return Object.values(table)
    .filter(c => sticky[c.id])
    .filter(c => c?.order < colCurrent?.order)
    .reduce((acc, c) => acc + c.width, 0);
};

export const getIsLastSticky = (sticky, table) => id => {
  const colsOrdered = getOrderColumns(sticky)(table);
  const colsSticky = colsOrdered.filter(c => sticky[c.id]);
  return get(colsSticky, `[${colsSticky.length - 1}].id`) === id;
};
