import { isEmpty, set, get } from 'lodash';
import { createSlice, PayloadAction, original } from '@reduxjs/toolkit';

import {
  ResearchAdWordsItem,
  ResearchDataWithInventoryItems,
  ResearchKGItem,
  ResearchKnowledgeGraph,
} from '../../../types';
import {
  researchTaskInitialize,
  researchTaskQueryUpsert,
  researchTaskSiteChange,
} from '../../actions';
import { getUniqueInventoryTopics } from '../../utils/getUniqueInventoryTopics';
import { initialState } from './data.initialState';
import { updateItemsForTerms } from './utils';

export const dataSlice = createSlice({
  name: 'research/data',
  initialState,
  reducers: {
    adwordsKeywordsSet: (state, action) => {
      state.adwordsKeywords = action.payload;
    },
    adwordsQuestionsSet: (state, action) => {
      state.adwordsQuestions = action.payload;
    },
    bulkAdwordsKeywordsSet: (state, action) => {
      state.bulkAdwordsKeywords = action.payload;
    },
    knowledgeGraphSet: (
      state,
      { payload }: PayloadAction<ResearchKnowledgeGraph>,
    ) => {
      const sorted = payload?.organicSerpItemPagesKgData.sort(
        ({ position: a }, { position: b }) => {
          if (a !== 0 && b === 0) {
            return -1;
          } else if (a === 0 && b === 0) {
            return 1;
          } else {
            return a - b;
          }
        },
      );

      state.knowledgeGraph = {
        ...payload,
        organicSerpItemPagesKgData: sorted,
      };
    },
    inventoryTopicsUpdate: (state, action) => {
      state.inventoryTopics = getUniqueInventoryTopics([
        ...(state.inventoryTopics || []),
        ...action.payload,
      ]);
    },
    inventoryTopicsSet: (state, action) => {
      state.inventoryTopics = action.payload;
    },
    linkingRecommendationsSet: (state, action) => {
      state.linkingRecommendations = action.payload;
    },
    serpDataSet: (state, action) => {
      state.serpData = action.payload;
    },
    serpPagesSet: (state, action) => {
      state.serpPages = action.payload;
    },
    websiteHeatmapSet: (state, action) => {
      state.websiteHeatmap = action.payload;
    },
    serpQuestionsSet: (state, action) => {
      state.serpQuestions = action.payload;
    },
    serpUserIntentDataSet: (state, action) => {
      state.serpUserIntentData = action.payload;
    },
    checkInvTopicInSeo: (
      state,
      action: PayloadAction<{
        path?: string;
        key: ResearchDataWithInventoryItems;
        terms: Array<string>;
      }>,
    ) => {
      const { terms, key, path } = action.payload;
      const draft = state[key];
      const data = draft ? original(draft) : false;

      if (!isEmpty(terms) && data) {
        const seoItems: Array<ResearchAdWordsItem | ResearchKGItem> =
          Array.from(path ? get(data, path) : data);
        const next = updateItemsForTerms(seoItems, terms);

        if (path) {
          set(state, `${key}.${path}`, next);
        } else {
          state[key] = next;
        }
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(researchTaskSiteChange, () => {
        return initialState;
      })
      .addCase(researchTaskInitialize, () => {
        return initialState;
      })
      .addCase(researchTaskQueryUpsert, () => {
        return initialState;
      });
  },
});

export const dataActions = dataSlice.actions;
