import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Key = styled.div`
  font-size: 12px;
  color: ${p => p.theme.colors.grey60};
  margin-right: 8px;
`;

export const Value = styled.div`
  font-size: 12px;
  color: ${p => p.theme.colors.grey85};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const KeyValue = ({ children }) => (
  <Wrapper>{children}</Wrapper>
);

KeyValue.propTypes = {
  children: PropTypes.any,
};

export default KeyValue;
