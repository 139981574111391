import { SortingFn } from '@tanstack/table-core';
import { isNil, isUndefined, isNull } from 'lodash';

export const sortUndefinedLast: SortingFn<any> = (rowA, rowB, columnId) => {
  const a = rowA.getValue(columnId);
  const b = rowB.getValue(columnId);

  const aIsNil = isNil(a) || isUndefined(a) || isNull(a);
  const bIsNil = isNil(b) || isUndefined(b) || isNull(b);

  if (!aIsNil && bIsNil) {
    return -1;
  }
  if (aIsNil && !bIsNil) {
    return 1;
  }
  if (aIsNil && bIsNil) {
    return -1;
  }

  return Number((a as unknown as number) - (b as unknown as number));
};
