import React from 'react';
import isNil from 'lodash/isNil';
import {
  WrapperNew,
  TextWrapperNew,
  TextSection,
  BarText,
  Indicator,
  ProgressBarStyledNew,
} from './shared';

export const BarWordCount = React.forwardRef(
  (
    { at, over, style, wcChange, wordCountAverage, wordCountTarget, shrunk },
    ref,
  ) => {
    const value = (at / over) * 100;
    return (
      <WrapperNew ref={ref} style={style}>
        <TextWrapperNew>
          {/** current content score */}
          <TextSection>
            Word Count:
            <BarText color="black" style={{ marginLeft: 4 }}>
              {wcChange !== 0 && (
                <Indicator change={wcChange}>
                  {wcChange > 0 && '+'}
                  {wcChange}
                </Indicator>
              )}
              {isNil(at) ? '-' : at.toLocaleString()}
            </BarText>
          </TextSection>

          {/** avg */}
          <TextSection mask={'true'} shrunk={shrunk}>
            Avg:
            <BarText color="black" style={{ marginLeft: 4 }}>
              {isNil(wordCountAverage)
                ? '-'
                : wordCountAverage.toLocaleString()}
            </BarText>
          </TextSection>

          {/** target */}
          <TextSection data-mms--writing-goals="wc-target">
            Target:
            <BarText color="black" style={{ marginLeft: 4 }}>
              {isNil(wordCountTarget) ? '-' : wordCountTarget.toLocaleString()}
            </BarText>
          </TextSection>
        </TextWrapperNew>
        <ProgressBarStyledNew
          thin
          hideValue
          trackColor="mmxGreyF4"
          color="blue"
          value={value}
        />
      </WrapperNew>
    );
  },
);
