import { MentionsArgs } from '../types';
import calculate from './calculate';

const backgroundColor = (...args: MentionsArgs) => {
  const { color } = calculate(...args);
  if (color === 'yellow') return 'bg-yellow-400';
  if (color === 'green') return 'bg-green-400';
  if (color === 'blue') return 'bg-blue-400';
  return 'bg-red-400';
};

export default backgroundColor;
