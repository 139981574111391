import { pick } from 'lodash';
import { ApolloQueryResult } from '@apollo/client';
import type {
  SeoBulkAdwordsKeywordsMinimalQuery,
  AdWordsItemWithVariants,
  Country,
  Language,
} from '@marketmuse/config/types/papi';
import { SeoBulkAdwordsKeywordsMinimalDocument } from '@marketmuse/data-papi';
import { ThunkApiConfig } from '../../types';
import createNonConcurrentAsyncThunk from '../../utils/createNonConcurrentAsyncThunk';

const getBulkAdwordsKeywords = createNonConcurrentAsyncThunk<
  {
    data: AdWordsItemWithVariants[];
  },
  {
    country: Country;
    language: Language;
    terms: string[];
  },
  ThunkApiConfig
>(
  'optimize/getBulkAdwordsKeywords',
  async (params, { extra, rejectWithValue }) => {
    const response: ApolloQueryResult<SeoBulkAdwordsKeywordsMinimalQuery> =
      await extra.apollo.query({
        query: SeoBulkAdwordsKeywordsMinimalDocument,
        variables: pick(params, ['terms', 'country', 'language']),
      });

    const error = response.errors;
    if (error) {
      return rejectWithValue(error);
    }

    return {
      data: response?.data?.seoBulkAdwordsKeywords || [],
    };
  },
);

export default getBulkAdwordsKeywords;
