import { ApolloQueryResult } from '@apollo/client';
import { pick } from 'lodash';
import type { GetSeoAdwordsQuestionsQuery } from '@marketmuse/config/types/papi';
import { GetSeoAdwordsQuestionsDocument } from '@marketmuse/data-papi';
import {
  ResearchDataInput,
  ResearchThunkSeoRes,
  ThunkApiConfig,
} from '../../types';
import createNonConcurrentAsyncThunk from '../../utils/createNonConcurrentAsyncThunk';

const getAdwordsQuestions = createNonConcurrentAsyncThunk<
  ResearchThunkSeoRes,
  ResearchDataInput,
  ThunkApiConfig
>('runs/getAdwordsQuestions', async (params, { extra, rejectWithValue }) => {
  const response: ApolloQueryResult<GetSeoAdwordsQuestionsQuery> =
    await extra.apollo.query({
      query: GetSeoAdwordsQuestionsDocument,
      variables: pick(params, ['term', 'country', 'language']),
    });

  const error = response.errors;
  if (error) {
    return rejectWithValue(error);
  }

  return {
    data: response?.data?.seoAdwordsQuestions.map(item => ({
      ...item,
      existsInInventory: false,
    })),
    path: 'adwordsQuestions',
    params,
  };
});

export default getAdwordsQuestions;
