import { get, isNil } from 'lodash';

import { store } from '../../config/instances';
import appConfigKey from './appConfigKey';
import isAppDataCached from './isAppDataCached';
import checkAppRunLimit from './checkAppRunLimit';

export default (
  { configKey, params } = {},
  {
    setShowResults, // fn
    setAppData, // fn
    getAppData, // fn
    getParams, // fn
    setParams, // fn
    apps, // object - the redux state for apps
    appId, // string
    saveAppData, // fn
    dataRequirements, // [string]
    callback, // fn
    checkAppRuns,
  } = {},
) => {
  // check run limits
  const state = store.getState();
  const canRunApp = checkAppRunLimit(get(state, 'misc'));
  if (!canRunApp && checkAppRuns) return;

  // enable results section
  setShowResults(true);

  // ### STEP 1 - PARAMETERS (app configuration)
  // 1. Get it using provided config key (updates local app state)
  // 2. Use provided parameters (updates local app state)
  // 3. Get it from the local app state (default)

  // get current app configuration from the local app state
  let appParams = getParams();

  // a specific configuration key is provided
  if (!isNil(configKey)) {
    // grab cache data / params for this configuration
    const cached = apps[configKey];
    if (!isNil(cached.params)) {
      // use the provided config instead of the local app state
      appParams = cached.params;
      // set local app state to the provided configuration
      setParams(cached.params);
    }
  }

  // specific parameters were provided
  else if (!isNil(params)) {
    // use the provided parameters instead of the local app state
    appParams = params;
    // set local app state to the provided configuration
    setParams(params);
  }

  // ### STEP 2 - CONFIG KEY
  // Turn parameters into the config key, or use the provided one
  // This is the apps current configuration id
  const currentConfigKey = configKey || appConfigKey(appParams);

  // if user hits run multiple times, fetch and refresh cache
  const lastSavedConfigKey = get(apps, appId);
  const forceFetch = currentConfigKey === lastSavedConfigKey;

  // ### STEP 3 - UPDATE GLOBAL STATE
  // Save current config key to the state. This indicates that this app
  // is active (ie. displaying data). This will be used for:
  // 1. Hydrating the app: when navigated away and back, this config key
  //    will be used to grab the params and data that will populate the app
  // 2. Signaling the MMS platform that this app is in use and currently
  //    displaying data. One example that will use this is the Sidebar
  //    component, to display the checkmark next to the app
  saveAppData(appId, currentConfigKey);

  // ### STEP 4 - APP DATA
  // Use the current config key to grab the cached
  // app data from redux state
  const appDataCached = get(apps, `['${currentConfigKey}'].data`);

  // ### STEP 4 - POPULATE OR RUN
  // Check if the required data for this is present in cache
  // ie. research needs `kg` and `scores`, if any of them
  // is not present, data needs to be fetched
  const isCacheSufficient = isAppDataCached(appDataCached, dataRequirements);

  // data required for this app is present, set the app data
  // to the local state
  if (isCacheSufficient && !forceFetch) {
    setAppData(appDataCached);
    if (typeof callback === 'function') callback(appDataCached);
  }

  // data required for this app is not present, start fetching
  else {
    // if type==='roi' - TODO: request for roi
    // else:
    getAppData({
      app: appId,
      params: appParams,
      callback: appData => {
        setAppData(appData);
        setShowResults(true);
        if (typeof callback === 'function') callback(appData);
      },
    });
  }
};
