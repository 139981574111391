import { get } from 'lodash';

import { titleCase } from '@marketmuse/utilities';
import { tracker } from '../config/instances';

const userDataLayer = ({ user, site, subscriptions = [] }) => {
  return {
    id: get(user, 'id'),
    fullName: get(user, 'fullName'),
    firstName: get(user, 'firstName'),
    lastName: get(user, 'lastName'),
    email: get(user, 'email'),
    org: get(user, 'org'),
    tier: titleCase(get(user, 'org.clientTier') || 'None'),
    sfdcContactId: get(user, 'sfdcContactId'),
    site,
    subscriptions,
  };
};

export const updateTrackedUser = ({ user, site, subscriptions }) => {
  tracker.update(userDataLayer({ user, site, subscriptions }));
};

export const initTrackedUser = ({ user, site, subscriptions }) => {
  if (get(tracker, 'user.id') !== user?.id) {
    tracker.init(userDataLayer({ user, site, subscriptions }));
  } else {
    updateTrackedUser({ user, site, subscriptions });
  }
};

export const trackerReset = () => {
  tracker.clear();
};
