import isNil from 'lodash/isNil';

import usePrevious from './usePrevious';

export default (value, { prev } = {}) => {
  // if prev value not given, last value
  // will be used as previous
  const prevRecorded = usePrevious(value);
  const prevValue = isNil(prev) ? prevRecorded : prev;

  const delta = value - prevValue;

  return {
    delta,
    increased: delta > 0,
    increase: Math.abs(delta),
    decrease: Math.abs(delta),
  };
};
