import * as types from '../../config/types';
import Toast from '../../components/Toast';

export default () =>
  ({ getState, dispatch }) =>
  next =>
  action => {
    if (action.type === types.PROJECT_PLACE_ORDERS) {
      Toast.fire('This functionality is no longer possible.', 'error');
      if (typeof action.callback === 'function') action.callback({});
    }

    return next(action);
  };
