import get from 'lodash/get';

import { RecurlyAccountCreateDocument } from '@marketmuse/data-papi';
import Toast from '../../components/Toast';

import * as types from '../../config/types';
import makeRequest from '../../utils/makeRequest';

export default () =>
  ({ dispatch, getState }) =>
  next =>
  action => {
    if (action.type === types.RECURLY_CREATE_ACCOUNT) {
      makeRequest({
        type: types.RECURLY_CREATE_ACCOUNT,
        mutation: RecurlyAccountCreateDocument,
        variables: {
          email: action.params.email,
          orgId: action.params.orgId,
        },
        apolloOptions: {
          fetchPolicy: 'no-cache',
        },
      }).then(res => {
        if (get(res, 'errors') || get(res, 'error')) {
          Toast.fire(get(res, 'errors.0.message') || 'Recurly error', 'error');
          return action.callback(res);
        } else {
          const data = get(res, 'recurlyCreateAccount');

          if (typeof action.callback === 'function' && data) {
            action.callback(data);
          }
        }
      });
    }

    return next(action);
  };
