import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  PopperChevron,
  SelectionWrapper,
} from '../../../../../components/Tooltip/Popper';
import Box from '../../../../../components/Box';

import hideTooltip from '../../../../../utils/hideTooltip';
import { valuesShape } from '../filterFactoryTypes';
import handleEventBlock from '../../../../../utils/handleEventBlock';
import IconSvg from '../../../../../components/IconSvg/IconSvg';
import DataList from '../../../../../components/DataList/DataList';
import { SimpleTextInputLabel } from '../../../../../components/SimpleTextInput';

const PopperPlaceholder = styled(SimpleTextInputLabel)`
  flex-grow: 1;
  cursor: pointer;
  margin-bottom: 0;
  font-weight: 400;
`;

const FilterDataList = ({
  placeholder,
  noResultsText,
  selected,
  label,
  values,
  onChange,
  visible,
  tags,
  mb,
}) => {
  const activeItems = values.filter(v => v.id === selected || v.active) || [];
  const activeItem = activeItems[0] || {};
  const identifier = new Date().getTime();
  useEffect(() => {
    if (visible) {
      hideTooltip(`to_close_${identifier}`);
    }
  }, [visible]);

  return (
    <Box boxVariant="flex" flexDirection="column" width="100%" mb={mb}>
      {label && <SimpleTextInputLabel>{label}</SimpleTextInputLabel>}
      <DataList
        highlightSelected={true}
        filterItemsFn={(item, query) => {
          if (!query) {
            return true;
          }
          return (
            (item.name || '').toLowerCase().indexOf(query.toLowerCase()) !== -1
          );
        }}
        noResultsText={noResultsText}
        list={values}
        selection={activeItem}
        inputProps={{
          placeholder: 'Search',
          name: 'project--plan-title',
          itemsLeft: [<IconSvg name="search" size="16" />],
        }}
        handleId={`to_close_${identifier}`}
        onSelect={(value = {}, event) => {
          hideTooltip(`to_close_${identifier}`);
          handleEventBlock(event);
          onChange(value.id, event);
        }}
      >
        <SelectionWrapper border {...tags}>
          {!activeItem && (
            <PopperPlaceholder>{placeholder || 'Select'}</PopperPlaceholder>
          )}
          {!!activeItem && (
            <PopperPlaceholder>{activeItem.title}</PopperPlaceholder>
          )}
          <PopperChevron />
        </SelectionWrapper>
      </DataList>
    </Box>
  );
};

FilterDataList.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  values: PropTypes.arrayOf(valuesShape),
};

export default FilterDataList;
