/**
 * Things to record:
 * - comparing to: url
 * - comparing to: title
 * - comparing to: rank
 * - bodyOrArticle
 * - brands
 * - country
 * - people
 * - topic
 * - url
 * 
 */

import Event from '../../Event';

class OptimizeCompeteHeadToHeadExpanded extends Event {

  record({
    comparingToUrl,
    comparingToTitle,
    comparingToRank,
    ...params
  }) {
    super.record('OptimizeCompeteHeadToHeadExpanded', {
      category: 'Optimize',
      mainValue: `Comparing to ${comparingToUrl}`,
      newName: 'apps--optimize--compete--head-to-head-expanded',
      comparingToUrl,
      comparingToTitle,
      comparingToRank,
      ...params,
    })
  }

}

export default new OptimizeCompeteHeadToHeadExpanded();
