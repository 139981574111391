import {
  SliceTypeFilter,
  SliceTypeFilterSite,
  SliceTypeFilterVersions,
} from '../types';

export const versionsState: SliceTypeFilterVersions = [];

export const siteState: SliceTypeFilterSite = null;

export const initialState: SliceTypeFilter = {
  site: siteState,
  versions: versionsState,
};
