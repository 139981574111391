import { get, isEmpty } from 'lodash';
import { SavedViewCreateDocument } from '@marketmuse/data-papi';
import { savedViewsUpdate } from '@marketmuse/data-state/savedViews';

import * as types from '../../config/types';
import makeRequest from '../../utils/makeRequest';

export default () =>
  ({ dispatch, getState }) =>
  next =>
  action => {
    const state = getState();
    if (action.type === types.CREATE_SAVED_VIEW) {
      const { type, callback, ...args } = action;

      const fields = {
        filters: args.filters,
        hidden: args.hidden,
        order: args.order,
        pinned: args.pinned,
        sorting: !isEmpty(args.sorting) ? args.sorting : null,
        title: args.title,
        inventoryType: args.inventoryType,
        description: args.description,
        sharing: args.sharing,
      };

      makeRequest({
        type: types.CREATE_SAVED_VIEW,
        mutation: SavedViewCreateDocument,
        variables: {
          siteId: get(state, 'filter.site.id'),
          savedViewInput: fields,
        },
      }).then(res => {
        const data = get(res, 'savedViewCreate') || null;
        if (data) {
          dispatch(
            savedViewsUpdate({
              inventoryType: data.inventoryType,
              items: [data],
            }),
          );

          if (typeof callback === 'function') callback(data);
        }
      });
    }

    return next(action);
  };
