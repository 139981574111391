import { get, isNil } from 'lodash';

import { FormGroupCreateDocument } from '@marketmuse/data-papi';
import makeRequest from '../../utils/makeRequest';
import * as types from '../../config/types';

export default () => () => next => action => {
  if (action.type === types.CREATE_FORM_GROUP) {
    const formGroupInput = {
      formId: action.formId,
      title: action.data?.title,
      type: action.data?.type,
    };
    makeRequest({
      type: types.CREATE_FORM_GROUP,
      mutation: FormGroupCreateDocument,
      variables: {
        formGroupInput: formGroupInput,
      },
      apolloOptions: {
        fetchPolicy: 'no-cache',
      },
      keys: [`form_group_create`],
    }).then(res => {
      const data = get(res, 'formGroupCreate', {});

      if (!isNil(data)) {
        const { form, ...group } = data;

        if (typeof action.callback === 'function') {
          action.callback({
            group,
            form,
          });
        }
      }
    });
  }

  return next(action);
};
