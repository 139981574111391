import { FC } from 'react';
import { Box, Button, Text } from '@marketmuse/components';
import { ReactComponent as TopicMagnifying } from './assets/topic-magnifying.svg';

interface CollateralTableGatedProps {
  onUpgrade?: (event: MouseEvent) => void;
}

const CollateralTableGated: FC<CollateralTableGatedProps> = ({ onUpgrade }) => {
  return (
    <Box className={['flex', 'flex-row']}>
      <Box
        className={[
          'w-1/2',
          'flex',
          'flex-row',
          'items-center',
          'justify-center',
          'py-6',
        ]}
      >
        <TopicMagnifying />
      </Box>
      <Box
        className={[
          'w-1/2',
          'flex',
          'flex-col',
          'items-center',
          'justify-center',
          'text-center',
        ]}
      >
        <Text className={['prose', 'text-xl', 'font-bold', 'mb-2.5']} as="h2">
          Get more data
        </Text>
        <Text className={['prose', 'text-base', 'mb-4']} as="p">
          Don’t miss important keywords or questions. Upgrade to get access to
          this data.
        </Text>
        <Button variant="blue400" onClick={onUpgrade}>
          Upgrade Now
        </Button>
      </Box>
    </Box>
  );
};

export default CollateralTableGated;
