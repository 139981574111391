import replaceUrl from './replaceUrl';
import isNumeric from './isNumeric';

export const prepareValue = (valueArg, raw) => {
  const value =
    typeof valueArg === 'object' ? JSON.stringify(valueArg) : valueArg;
  const valueBase64 = btoa(value);
  const valueSafe = encodeURIComponent(value);
  const valueBase64Safe = encodeURIComponent(valueBase64);
  return raw ? valueSafe : valueBase64Safe;
};

export default {
  add: (key, val, { raw = false } = {}) => {
    try {
      const keySafe = encodeURIComponent(key);
      let url = new URL(window.location.href);
      url.searchParams.set(keySafe, prepareValue(val, raw));
      replaceUrl(url.href);
      url = null;
    } catch (error) {
      console.error(error);
    }
  },
  remove: key => {
    try {
      const keySafe = encodeURIComponent(key);
      let url = new URL(window.location.href);
      url.searchParams.delete(keySafe);
      replaceUrl(url.href);
      url = null;
    } catch (error) {
      console.error(error);
    }
  },
  // TODO: encoding and decoding url parameters
  // by default was a terrible idea. Use getRaw,
  // and fix it asap.
  get: (key, { raw = false } = {}) => {
    const keySafe = encodeURIComponent(key);
    let returnValue = null;
    try {
      let url = new URL(window.location.href);
      const rawValue = url.searchParams.get(keySafe);
      if (!rawValue) return null;
      const decoded = decodeURIComponent(rawValue);
      if (decoded) returnValue = raw ? decoded : atob(decoded);
      url = null;
    } catch (e) {
      return null;
    }
    if (!returnValue) return null;
    try {
      return JSON.parse(returnValue);
    } catch (e) {
      return isNumeric(returnValue) ? parseFloat(returnValue) : returnValue;
    }
  },
  getRaw: key => {
    const keySafe = encodeURIComponent(key);
    try {
      let url = new URL(window.location.href);
      return url.searchParams.get(keySafe);
    } catch (e) {
      return null;
    }
  },
};
