import * as React from 'react';
import { IResearchViewModelContext } from '../types';

import { ResearchViewModelContext } from '../context';

const useResearchViewModel = (): IResearchViewModelContext => {
  const context = React.useContext(ResearchViewModelContext);
  if (!context) {
    throw new Error(
      'This component must be used within the <ResearchProvider />.',
    );
  }
  return context;
};

export default useResearchViewModel;
