import { DocumentChangeEvent } from '@ckeditor/ckeditor5-engine';
import { Plugin } from '@ckeditor/ckeditor5-core';

import { debounce } from 'lodash';

import { WORD_COUNT as CONSTANTS } from '@marketmuse/config/configs/editor';
import {
  EVENTS,
  OnChangeWordCountEvent,
  OnCheckWordCountEvent,
} from '@marketmuse/config/types/editor/plugins/wordCount';

import { modelElementToPlainText } from '../_utils/modelElementToPlainText';

const { SETTINGS } = CONSTANTS;

export default class WordCount extends Plugin {
  public static get pluginName() {
    return SETTINGS.NAME;
  }

  constructor(editor) {
    super(editor);
  }

  public init(): void {
    this.editor.model.document.on<DocumentChangeEvent>(
      'change:data',
      debounce(() => {
        this._refreshWordCount();
      }, 250),
    );
  }

  public afterInit(): void {
    this.editor.on<OnCheckWordCountEvent>(EVENTS.onCheckWordCount, () => {
      this._refreshWordCount();
    });
  }

  private _getText(): string {
    const root = this.editor.model.document.getRoot();
    if (!root) {
      return '';
    }

    const text = modelElementToPlainText(root);
    return text;
  }

  private _refreshWordCount() {
    const text = this._getText();
    const wordsArray = text.match(/\S+/g) || [];

    const data = wordsArray.length;

    this.editor.fire<OnChangeWordCountEvent>(EVENTS.onChangeWordCount, data);
  }
}
