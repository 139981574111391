import { FC, memo } from 'react';
import { Box, Text } from '@marketmuse/components';

import { DomainsListProps } from '../../types';
import Domain from './Domain';

const DomainsList: FC<DomainsListProps> = ({ domains = [], onRemove, tab }) => (
  <Box
    className={[
      'w-full',
      'h-48',
      'mb-4',
      'rounded',
      'border-2',
      'border-gray-150',
      'overflow-hidden',
      'overflow-y-auto',
    ]}
  >
    {domains.length > 0 ? (
      <ul className="w-full">
        {domains.map((domain, index) => (
          <Domain
            domain={domain}
            key={`${tab}-${domain}-${index}`}
            onRemove={onRemove}
          />
        ))}
      </ul>
    ) : (
      <Text as="p" className="text-sm px-4 py-2 text-gray-700">
        No domains added yet!
      </Text>
    )}
  </Box>
);

export default memo(DomainsList);
