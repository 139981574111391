import { ApolloQueryResult } from '@apollo/client';
import { pick, isEmpty } from 'lodash';
import type {
  SeoKnowledgeGraphResearchQuery,
  KnowledgeGraph,
} from '@marketmuse/config/types/papi';
import { SeoKnowledgeGraphResearchDocument } from '@marketmuse/data-papi';
import {
  ResearchDataInput,
  ResearchThunkSeoRes,
  ThunkApiConfig,
} from '../../types';
import createNonConcurrentAsyncThunk from '../../utils/createNonConcurrentAsyncThunk';
import serializeKnowledgeGraphResponse from '../../utils/serializeKnowledgeGraphResponse';

const getKnowledgeGraph = createNonConcurrentAsyncThunk<
  ResearchThunkSeoRes,
  ResearchDataInput,
  ThunkApiConfig
>('runs/getKnowledgeGraph', async (params, { extra, rejectWithValue }) => {
  const response: ApolloQueryResult<SeoKnowledgeGraphResearchQuery> =
    await extra.apollo.query({
      query: SeoKnowledgeGraphResearchDocument,
      variables: {
        ...pick(params, ['term', 'url', 'country', 'language']),
        skipPageScrapping: true,
      },
    });

  const error = response.errors;
  if (error && isEmpty(response?.data?.seoKnowledgeGraph)) {
    return rejectWithValue(error);
  }

  const knowledgeGraph =
    response?.data?.seoKnowledgeGraph || ({} as KnowledgeGraph);

  const {
    organicSerpItemPagesKgData = [],
    knowledgeGraphItems = [],
    userProvidedPage = {},
    metrics,
  } = serializeKnowledgeGraphResponse(knowledgeGraph);

  const resKnowledgeGraphItems = knowledgeGraphItems.map(topic => ({
    ...topic,
    existsInInventory: false,
  }));

  const data = {
    organicSerpItemPagesKgData,
    knowledgeGraphItems: resKnowledgeGraphItems,
    userProvidedPage,
    metrics,
  };

  return {
    data,
    params,
  };
});

export default getKnowledgeGraph;
