import { FC, ReactNode } from 'react';

import { AppQueryNetworkKeys } from '@marketmuse/data-state/types';
import { useStoreSelector } from '@marketmuse/data-state/hooks';

import { ResearchDataContext } from '../context';
import { selectData, selectNetworkStatus } from './select';

const ResearchDataProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const adwordsKeywordsData = useStoreSelector(state =>
    selectData(state, AppQueryNetworkKeys.adwordsKeywords),
  );
  const adwordsKeywordsStatus = useStoreSelector(state =>
    selectNetworkStatus(state, AppQueryNetworkKeys.adwordsKeywords),
  );

  const adwordsQuestionsData = useStoreSelector(state =>
    selectData(state, AppQueryNetworkKeys.adwordsQuestions),
  );
  const adwordsQuestionsStatus = useStoreSelector(state =>
    selectNetworkStatus(state, AppQueryNetworkKeys.adwordsQuestions),
  );
  const bulkAdwordsKeywordsData = useStoreSelector(state =>
    selectData(state, AppQueryNetworkKeys.bulkAdwordsKeywords),
  );
  const bulkAdwordsKeywordsStatus = useStoreSelector(state =>
    selectNetworkStatus(state, AppQueryNetworkKeys.bulkAdwordsKeywords),
  );

  const knowledgeGraphData = useStoreSelector(state =>
    selectData(state, AppQueryNetworkKeys.knowledgeGraph),
  );
  const knowledgeGraphStatus = useStoreSelector(state =>
    selectNetworkStatus(state, AppQueryNetworkKeys.knowledgeGraph),
  );

  const linkingRecommendationsData = useStoreSelector(state =>
    selectData(state, AppQueryNetworkKeys.linkingRecommendations),
  );
  const linkingRecommendationsStatus = useStoreSelector(state =>
    selectNetworkStatus(state, AppQueryNetworkKeys.linkingRecommendations),
  );

  const serpQuestionsData = useStoreSelector(state =>
    selectData(state, AppQueryNetworkKeys.serpQuestions),
  );
  const serpQuestionsStatus = useStoreSelector(state =>
    selectNetworkStatus(state, AppQueryNetworkKeys.serpQuestions),
  );

  const serpDataData = useStoreSelector(state =>
    selectData(state, AppQueryNetworkKeys.serpData),
  );
  const serpDataStatus = useStoreSelector(state =>
    selectNetworkStatus(state, AppQueryNetworkKeys.serpData),
  );

  const serpUserIntentData = useStoreSelector(state =>
    selectData(state, AppQueryNetworkKeys.serpUserIntentData),
  );
  const serpUserIntentDataStatus = useStoreSelector(state =>
    selectNetworkStatus(state, AppQueryNetworkKeys.serpUserIntentData),
  );

  const serpPagesData = useStoreSelector(state =>
    selectData(state, AppQueryNetworkKeys.serpPages),
  );
  const serpPagesStatus = useStoreSelector(state =>
    selectNetworkStatus(state, AppQueryNetworkKeys.serpPages),
  );

  const websiteHeatmapData = useStoreSelector(state =>
    selectData(state, AppQueryNetworkKeys.websiteHeatmap),
  );
  const websiteHeatmapStatus = useStoreSelector(state =>
    selectNetworkStatus(state, AppQueryNetworkKeys.websiteHeatmap),
  );

  const inventoryTopicsData = useStoreSelector(state =>
    selectData(state, 'inventoryTopics'),
  );

  const inventoryTopicsKeywordsStatus = useStoreSelector(state =>
    selectNetworkStatus(state, AppQueryNetworkKeys.inventoryTopicsKeywords),
  );
  const inventoryTopicsKGStatus = useStoreSelector(state =>
    selectNetworkStatus(state, AppQueryNetworkKeys.inventoryTopicsKG),
  );
  const inventoryTopicsBulkKeywordsStatus = useStoreSelector(state =>
    selectNetworkStatus(state, AppQueryNetworkKeys.inventoryTopicsBulkKeywords),
  );
  const inventoryTopicsQuestionsStatus = useStoreSelector(state =>
    selectNetworkStatus(state, AppQueryNetworkKeys.inventoryTopicsQuestions),
  );
  const inventoryTopicsSerpQuestionsStatus = useStoreSelector(state =>
    selectNetworkStatus(
      state,
      AppQueryNetworkKeys.inventoryTopicsSerpQuestions,
    ),
  );

  return (
    <ResearchDataContext.Provider
      value={{
        data: {
          adwordsKeywords: adwordsKeywordsData,
          adwordsQuestions: adwordsQuestionsData,
          bulkAdwordsKeywords: bulkAdwordsKeywordsData,
          knowledgeGraph: knowledgeGraphData,
          serpQuestions: serpQuestionsData,
          serpData: serpDataData,
          serpUserIntentData: serpUserIntentData,
          serpPages: serpPagesData,
          inventoryTopics: inventoryTopicsData,
          linkingRecommendations: linkingRecommendationsData,
          websiteHeatmap: websiteHeatmapData,
        },
        network: {
          adwordsKeywords: adwordsKeywordsStatus,
          adwordsQuestions: adwordsQuestionsStatus,
          bulkAdwordsKeywords: bulkAdwordsKeywordsStatus,
          knowledgeGraph: knowledgeGraphStatus,
          serpQuestions: serpQuestionsStatus,
          serpData: serpDataStatus,
          serpUserIntentData: serpUserIntentDataStatus,
          serpPages: serpPagesStatus,
          inventoryTopicsKeywords: inventoryTopicsKeywordsStatus,
          inventoryTopicsBulkKeywords: inventoryTopicsBulkKeywordsStatus,
          inventoryTopicsKG: inventoryTopicsKGStatus,
          inventoryTopicsQuestions: inventoryTopicsQuestionsStatus,
          inventoryTopicsSerpQuestions: inventoryTopicsSerpQuestionsStatus,
          linkingRecommendations: linkingRecommendationsStatus,
          websiteHeatmap: websiteHeatmapStatus,
        },
      }}
    >
      {children}
    </ResearchDataContext.Provider>
  );
};

export default ResearchDataProvider;
