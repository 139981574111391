import get from 'lodash/get';
import { ReportSubheadingAddDocument } from '@marketmuse/data-papi';
import makeRequest from '../../utils/makeRequest';
import * as types from '../../config/types';

export default () => () => next => action => {
  if (action.type === types.ADD_SUBHEADING) {
    makeRequest({
      type: types.ADD_SUBHEADING,
      mutation: ReportSubheadingAddDocument,
      variables: {
        data: {
          briefId: action.briefId,
          subheadings: [action.subheading],
        },
      },
      apolloOptions: {
        fetchPolicy: 'no-cache',
      },
      keys: [`${types.ADD_SUBHEADING}_${action.briefId}`],
    }).then(res => {
      const brief = get(res, 'addSubheading');
      if (typeof action.callback === 'function') {
        action.callback(brief);
      }
    });
  }

  return next(action);
};
