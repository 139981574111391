import * as Sentry from '@sentry/browser';
import { ReportGenerateDocument } from '@marketmuse/data-papi';
import makeRequest from '../../utils/makeRequest';
import * as types from '../../config/types';

export default () => () => next => action => {
  if (action.type === types.GENERATE_REPORT) {
    makeRequest({
      type: types.GENERATE_REPORT,
      mutation: ReportGenerateDocument,
      variables: {
        projectId: action.projectId,
        subheadings: action.subheadings,
      },
      apolloOptions: {
        fetchPolicy: 'no-cache',
      },
    })
      .then(res => {
        const data = res?.generateReport ?? null;
        if (typeof action.callback === 'function') action.callback(data);
      })
      .catch(error => {
        Sentry.captureException(error);
        // H.consumeError(error, 'Error occurred during confirmation and processing brief');
        if (typeof action.callback === 'function') action.callback(null);
      });
  }

  return next(action);
};
