import COUNTRY_CODES from './countryCodes';

export const LANGUAGES_CODES = {
  EN: 'en',
  ES: 'es',
};

export const languages = {
  [LANGUAGES_CODES.EN]: {
    id: LANGUAGES_CODES.EN,
    title: 'English (EN)',
    name: 'English',
    code: 'EN',
  },
  [LANGUAGES_CODES.ES]: {
    id: LANGUAGES_CODES.ES,
    title: 'Espanol (ES)',
    name: 'Espanol',
    code: 'ES',
  },
};

export const allowedLanguages = Object.values(languages);

export const countryLanguageCombinations = {
  [COUNTRY_CODES.US]: [languages[LANGUAGES_CODES.EN], languages[LANGUAGES_CODES.ES]],
  [COUNTRY_CODES.CA]: [languages[LANGUAGES_CODES.EN]],
  [COUNTRY_CODES.NZ]: [languages[LANGUAGES_CODES.EN]],
  [COUNTRY_CODES.AU]: [languages[LANGUAGES_CODES.EN]],
  [COUNTRY_CODES.GB]: [languages[LANGUAGES_CODES.EN]],
  [COUNTRY_CODES.ES]: [languages[LANGUAGES_CODES.ES]],
  [COUNTRY_CODES.MX]: [languages[LANGUAGES_CODES.ES]],
  [COUNTRY_CODES.CO]: [languages[LANGUAGES_CODES.ES]],
  [COUNTRY_CODES.AR]: [languages[LANGUAGES_CODES.ES]],
  [COUNTRY_CODES.PE]: [languages[LANGUAGES_CODES.ES]],
  [COUNTRY_CODES.VE]: [languages[LANGUAGES_CODES.ES]],
  [COUNTRY_CODES.CL]: [languages[LANGUAGES_CODES.ES]],
  [COUNTRY_CODES.GT]: [languages[LANGUAGES_CODES.ES]],
  [COUNTRY_CODES.EC]: [languages[LANGUAGES_CODES.ES]],
  [COUNTRY_CODES.BO]: [languages[LANGUAGES_CODES.ES]],
  [COUNTRY_CODES.PY]: [languages[LANGUAGES_CODES.ES]],
  [COUNTRY_CODES.NI]: [languages[LANGUAGES_CODES.ES]],
  [COUNTRY_CODES.CR]: [languages[LANGUAGES_CODES.ES]],
};
