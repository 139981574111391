import * as types from '../config/types';

// CRUD ACTIONS: [method][Model]
// follows prisma resolvers
export const createSavedView = (args = {}) => ({
  type: types.CREATE_SAVED_VIEW,
  ...args,
});

export const deleteSavedView = ({ savedView }) => ({
  type: types.DELETE_SAVED_VIEW,
  payload: { savedView },
});

export const getSavedViews = ({
  filters,
  orderBy,
  search,
  limit,
  offset,
  callback,
  keys,
}) => ({
  type: types.GET_SAVED_VIEWS,
  filters,
  orderBy,
  limit,
  offset,
  search,
  keys,
  callback,
});

export const getSavedViewsEach = ({
  callback,
  each,
  filters,
  limit,
  offset,
  orderBy,
  search,
  siteId,
}) => ({
  type: types.GET_SAVED_VIEWS_EACH,
  callback,
  each,
  filters,
  limit,
  offset,
  orderBy,
  search,
  siteId,
});
