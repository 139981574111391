import { Plugin } from '@ckeditor/ckeditor5-core';
import type { Editor } from '@ckeditor/ckeditor5-core';
import {
  ButtonView,
  createDropdown,
  ListView,
  ViewCollection,
} from '@ckeditor/ckeditor5-ui';
import { isUndefined } from 'lodash';
import { EDITOR } from '@marketmuse/config/types';
import { editor as editorConfig } from '@marketmuse/config/configs';
import exportIcon from './assets/export';
import locked from './assets/locked';
import spinner from './assets/spinner';

import { TOOLBAR_TITLE, feature } from './constants';
import { MenuItem } from './types';
import { exportDoc } from './utils/exportDoc';
import ExportGoogleDoc from './ExportGoogleDoc';
export default class ExportDropdown extends Plugin {
  private _exportGoogleDoc: ExportGoogleDoc;

  private _config: EDITOR.ExportConfig;
  private _menuButtons: ViewCollection;

  static get requires() {
    return [ExportGoogleDoc];
  }

  constructor(editor) {
    super(editor);

    this._exportGoogleDoc = editor.plugins.get(ExportGoogleDoc);

    this._config = editor.config.get(editorConfig.SETTINGS.CONFIG_NAME) || {};
    this._menuButtons = new ViewCollection();
  }

  public afterInit(): void {
    this._menuButtons = this.getMenuButtons(this.editor.locale);
    this.addToolbarButton();

    this.editor.listenTo(
      this.editor,
      editorConfig.EXPORT_EVENT.updateExportToolbarView,
      (_, { key, isLoading, isEnabled }) => {
        this.updateButtonState({ key, isLoading, isEnabled });
      },
    );

    this.editor.listenTo(
      this.editor,
      editorConfig.EXPORT_EVENT.onExcelDataChange,
      (_, { isLoading }) => {
        this.updateButtonState({ key: 'excel', isEnabled: !isLoading });
      },
    );

    this.editor.listenTo(
      this.editor,
      feature.googleDoc.onClickEvent,
      (_, { hasAccess }) => {
        if (hasAccess) {
          this._exportGoogleDoc.initiateGoogleDocUpload();
        }
      },
    );

    this.editor.listenTo(
      this.editor,
      feature.word.onClickEvent,
      (_, { hasAccess }) => {
        if (hasAccess) {
          const filename = this._config.filename;
          exportDoc({
            data: this.editor.getData(),
            filename,
          });
        }
      },
    );
  }

  updateButtonState({
    key,
    isLoading,
    isEnabled,
  }: {
    key: EDITOR.ExportFeature;
    isLoading?: boolean;
    isEnabled?: boolean;
  }) {
    if (isUndefined(key)) {
      throw new Error('key must be provided');
    }
    if (isUndefined(isLoading) && isUndefined(isEnabled)) {
      throw new Error(
        'Both isLoading and isEnabled cannot be undefined. At least one of them must be provided.',
      );
    }
    // eslint-disable-next-line
    //@ts-ignore
    const button: ButtonView = this._menuButtons._items.find(
      (item: ButtonView) => (item.class as string).includes(`mm--${key}`),
    );

    if (!isUndefined(isLoading)) {
      if (isLoading) {
        button.iconView.element?.classList.add('animate-spin');
        button.icon = spinner;
        button.isEnabled = false;
      } else {
        button.iconView.element?.classList.remove('animate-spin');
        button.icon = feature[key].icon;
        button.isEnabled = true;
      }
    }

    if (!isUndefined(isEnabled)) {
      button.isEnabled = isEnabled;
    }
  }

  addToolbarButton() {
    this.editor.ui.componentFactory.add(
      editorConfig.SETTINGS.CONFIG_NAME,
      locale => {
        const dropdown = createDropdown(locale);

        dropdown.buttonView.set({
          label: TOOLBAR_TITLE,
          icon: exportIcon,
          withText: true,
          isVisible: this._config.isEnabled,
        });
        dropdown.render();

        dropdown.buttonView.element?.setAttribute(
          'data-mms--export-action',
          'export-dropdown',
        );

        const listView = new ListView(locale);
        listView.render();

        if (listView.element) {
          this._menuButtons.setParent(listView.element);
          dropdown?.panelView?.element?.appendChild(listView.element);
        }

        return dropdown;
      },
    );
  }

  getFeatureItems() {
    const menuItems: MenuItem[] = [];
    const featureKeys = Object.keys(
      this._config.feature || {},
    ) as EDITOR.ExportFeature[];

    featureKeys.forEach(key => {
      const { hasAccess, isEnabled } = this._config.feature[key];

      const item = {
        key: key,
        title: feature[key].title,
        icon: hasAccess ? feature[key].icon : locked,
        hasAccess: hasAccess,
        isEnabled: isEnabled,
        onClick: (editor: Editor) => {
          editor.fire(feature[key].onClickEvent, { hasAccess: hasAccess });
        },
        class: !hasAccess ? 'ck-disabled' : '',
        dataTag: hasAccess
          ? feature[key].dataTag
          : `locked-${feature[key].dataTag}`,
      };

      if (isEnabled) {
        menuItems.push(item);
      }
    });

    return menuItems;
  }

  getMenuButtons = locale => {
    const collection = new ViewCollection();
    const items = this.getFeatureItems();
    for (const item of items) {
      const button = new ButtonView(locale);
      button.set({
        label: item.title,
        withText: true,
        icon: item.icon,
        class: item.class.split(' ').concat(`mm--${item.key}`).join(' '),
        isEnabled: item.isEnabled,
      });
      button.render();

      button.element?.setAttribute('data-mms--export-action', item.dataTag);

      button.on('execute', () => item.onClick(this.editor));
      collection.add(button);
    }

    return collection;
  };
}
