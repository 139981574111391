import { createListenerMiddleware } from '@reduxjs/toolkit';
import setupListeners from './listeners';

const middlewareFn = () => {
  const middleware = createListenerMiddleware();
  setupListeners(middleware.startListening);

  return middleware;
};

export default middlewareFn;
