import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from '../Box';

const Wrapper = styled(Box)`
  height: 30px;
  width: ${p => p.width || '100%'};
  animation: ${p => p.theme.keyframes.loading[p.loadingTheme]} 1.2s linear
    infinite;
  background-color: ${p => p.theme.generic[p.loadingTheme].loadingBg};
`;

const LoadingBlock = ({ loadingTheme = 'light', children, ...rest }) => (
  <Wrapper loadingTheme={loadingTheme} {...rest}>
    {children || ''}
  </Wrapper>
);

LoadingBlock.propTypes = {
  loadingTheme: PropTypes.oneOf(['light', 'dark']),
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.any,
};

export default memo(LoadingBlock);
