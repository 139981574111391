import { Command } from '@ckeditor/ckeditor5-core';
import {
  Element,
  HtmlDataProcessor,
  Position,
  Range,
  ViewRange,
} from '@ckeditor/ckeditor5-engine';
import plainTextToHtml from '@ckeditor/ckeditor5-clipboard/src/utils/plaintexttohtml';

import {
  InsertTextCommandParams,
  Marker,
} from '@marketmuse/config/types/editor';

export class InsertTextCommand extends Command {
  private _createNewParagraph: boolean;
  private _insertPosition!: Position;
  private _resultRange?: Range;

  constructor(editor) {
    super(editor);

    this.affectsData = true;
    this.isEnabled = true;
    this._createNewParagraph = false;
  }

  override refresh() {
    this.isEnabled = true;
  }

  override execute({ text, options = {} }: InsertTextCommandParams) {
    const { marker, callback, insertPosition, createNewParagraph } = options;
    this._createNewParagraph = createNewParagraph || false;

    const editor = this.editor;
    const model = editor.model;

    const cursorPosition = editor.model.document.selection.getFirstPosition();

    this._insertPosition = (
      insertPosition ? insertPosition : cursorPosition
    ) as Position;

    if (!this._insertPosition) {
      return;
    }

    const docFrag = this._toModelFragment(text);

    model.enqueueChange({ isUndoable: true }, () => {
      this._resultRange = editor.model.insertContent(
        docFrag,
        this._insertPosition,
      );
    });

    if (marker) {
      this._upsertMarker({ marker });
    }

    if (callback && this._resultRange) {
      callback(
        this.editor.editing.mapper.toViewRange(this._resultRange) as ViewRange,
      );
    }
  }

  private _getParagraphModificationStatus() {
    const insertPosition = this._insertPosition;
    const createNewParagraph = this._createNewParagraph;

    if (!createNewParagraph) {
      return { start: false, end: false };
    }

    if (insertPosition?.isAtEnd && !insertPosition?.isAtStart) {
      return { start: true, end: false };
    }

    if (!insertPosition?.isAtEnd && !insertPosition?.isAtStart) {
      return { start: true, end: true };
    }

    if (insertPosition?.isAtStart) {
      return { start: false, end: true };
    }

    return { start: false, end: false };
  }

  private _getInsertedContentRange() {
    const editor = this.editor;
    const resultRange = this._resultRange;
    const createNewParagraph = this._createNewParagraph;
    const { start: paragraphAtStart } = this._getParagraphModificationStatus();

    if (!resultRange) {
      return null;
    }

    if (this._resultRange?.start.path[0] === this._resultRange?.end.path[0]) {
      return this._resultRange;
    }

    const startIndex =
      resultRange.start.path[0] +
      (createNewParagraph && paragraphAtStart ? 1 : 0);

    const startItemOffset = createNewParagraph
      ? 0
      : resultRange.start.path[1] || 0;

    const startPath = [startIndex, startItemOffset];

    const lastItemIndex = Math.max(0, resultRange.end.path[0] - 1);

    const lastItemIndexOffset =
      // eslint-disable-next-line
      // @ts-expect-error
      resultRange.end.root.getChild(lastItemIndex)?.maxOffset;

    const endPath = [lastItemIndex, lastItemIndexOffset];

    const markerStartPosition = editor.model.createPositionFromPath(
      resultRange.start.root,
      startPath,
    );
    const markerEndPosition = editor.model.createPositionFromPath(
      resultRange.end.root,
      endPath,
    );

    return editor.model.createRange(markerStartPosition, markerEndPosition);
  }

  private _addElementTag({ block, html }: { html: string; block: Element }) {
    const blockName = block.name;
    if (blockName.slice(0, -1) === 'heading') {
      const tag = `h${blockName.slice(-1)}`;
      return `<${tag}>${html}</${tag}>`;
    }

    if (blockName === 'listItem') {
      if (block.getAttribute('listType') === 'numbered') {
        return `<ol><li>${html}</li></ol>`;
      } else {
        return `<ul><li>${html}</li></ul>`;
      }
    }

    return html;
  }

  private _getBlocks() {
    const range = this.editor.model.createRange(
      this._insertPosition,
      this._insertPosition,
    );
    const selection = this.editor.model.createSelection(range);
    const selectedBlocks = selection?.getSelectedBlocks();

    return Array.from(selectedBlocks);
  }

  private _toModelFragment(text: string) {
    const { start, end } = this._getParagraphModificationStatus();

    const modifiedText = `${start ? '\n\n' : ''}${text}${end ? '\n\n' : ''}`;

    let html = plainTextToHtml(modifiedText);

    const blocks = this._getBlocks();
    if (blocks.length === 1) {
      html = this._addElementTag({ block: blocks[0], html });
    }

    const editor = this.editor;
    const htmlDP = new HtmlDataProcessor(editor.data.viewDocument);

    const viewFragment = htmlDP.toView(html);
    const modelFragment = editor.data.toModel(viewFragment);

    return modelFragment;
  }

  private _upsertMarker({ marker }: { marker: Marker }) {
    const model = this.editor.model;
    const markerRange = this._getInsertedContentRange();

    if (!markerRange) {
      return;
    }

    model.enqueueChange({ isUndoable: false }, writer => {
      if (model.markers.has(marker?.name)) {
        writer.updateMarker(marker?.name, {
          ...marker?.options,
          range: markerRange,
        });
      } else {
        writer.addMarker(marker?.name, {
          ...marker?.options,
          range: markerRange,
        });
      }

      this._resultRange = markerRange;
    });
  }
}
