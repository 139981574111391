import * as React from 'react';
import { appResearchSlices } from '@marketmuse/data-state/appResearch';
import { IResearchViewModelContext } from '../types';

const initialState = { ...appResearchSlices.initialState.views };

const ResearchViewModelContext =
  React.createContext<IResearchViewModelContext>(initialState);

export default ResearchViewModelContext;
