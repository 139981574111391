import * as Sentry from '@sentry/browser';
import { isEmpty } from 'lodash';
import { App } from '@marketmuse/config/types/papi';
import { AppUiStatus } from '@marketmuse/config/types/applications';
import * as ACTION_TYPES from '../../../actionTypes';
import {
  researchTaskQueryUpsert,
  researchTaskQueryLoad,
  researchTaskInitialize,
  researchTaskConnectDomains,
  researchTaskConnectFetch,
} from '../../actions';
import {
  RootState,
  ReduxListener,
  ResearchDataInput,
  ResearchDataConnectInput,
  ResearchAppQueryUpsertRes,
  ResearchSnapshotInput,
} from '../../../types';
import sanitizeAppParams from '../../../utils/sanitizeAppParams';
import { uiSlice } from '../../slices';
import { appQueryUpsert, appQueryUpdate } from '../../thunks';
import validateAppParams from '../../../utils/validateAppParams';

const listen = (startListening: ReduxListener) => {
  const uiActions = uiSlice.actions;
  startListening({
    actionCreator: researchTaskQueryLoad,
    effect: async (
      { payload }: { payload: ResearchSnapshotInput },
      { dispatch, cancelActiveListeners },
    ) => {
      const params = sanitizeAppParams<ResearchDataInput>(payload.params);
      const isParamsValid = validateAppParams<ResearchDataInput>(
        params,
      ) as unknown as ResearchDataInput;
      if (isParamsValid) {
        cancelActiveListeners();
        dispatch(uiActions.paramsUpdate(payload.params));
        dispatch(
          uiActions.appQuerySet({
            ...payload.appQuery,
            term: params.term,
            url: params.url,
          }),
        );
        await dispatch(researchTaskInitialize(params));
      }
    },
  });

  startListening({
    actionCreator: researchTaskQueryUpsert,
    effect: (
      { payload }: { payload: ResearchDataInput },
      { dispatch, cancelActiveListeners },
    ) => {
      const isParamsValid = validateAppParams(payload);
      if (isParamsValid) {
        dispatch(
          uiActions.paramsAndStatusUpdate({
            params: payload,
            status: AppUiStatus.creating,
          }),
        );

        cancelActiveListeners();

        dispatch(
          appQueryUpsert({
            ...payload,
            app: App.RESEARCH,
          }),
        );
      } else {
        dispatch(uiActions.paramsUpdate(payload));
        console.log('Params invalid', payload);
      }
    },
  });

  startListening({
    actionCreator: appQueryUpsert.rejected,
    effect: (action, { dispatch }) => {
      const { aborted, arg } = action.meta;

      if (!aborted) {
        dispatch(uiActions.statusUpdate(AppUiStatus.zero));

        if (!isEmpty(action.error)) {
          if (action.error.message !== 'Network request failed') {
            Sentry.captureMessage(action.error.message, {
              extra: arg,
            });
          }
        }
      }
    },
  });

  startListening({
    actionCreator: appQueryUpdate.fulfilled,
    effect: async ({ payload }, { dispatch, getState }) => {
      const siteId = getState().filter?.site?.id;
      await dispatch(
        uiActions.appQuerySet({
          id: payload.id,
          status: payload.status,
          term: payload.topic,
          url: payload.url,
          country: payload.country,
          language: payload.language,
        }),
      );
      await dispatch({
        type: ACTION_TYPES.AGGREGATE_STATS_GET,
        payload: siteId,
      });
    },
  });

  startListening({
    actionCreator: appQueryUpsert.fulfilled,
    effect: async (
      { payload }: { payload: ResearchAppQueryUpsertRes },
      { dispatch },
    ) => {
      dispatch(
        uiActions.appQuerySet({
          id: payload.data.id,
          status: payload.data.status,
          term: payload.data.topic,
          url: payload.data.url,
          country: payload.data.country,
          language: payload.data.language,
        }),
      );

      await dispatch(researchTaskInitialize(payload.params));
    },
  });

  startListening({
    actionCreator: researchTaskConnectDomains,
    effect: (
      {
        payload,
      }: {
        payload: ResearchDataConnectInput;
      },
      { dispatch, getState },
    ) => {
      const state: RootState = getState();
      const { appQuery, status } = state.appResearch.ui;
      if (appQuery?.id) {
        dispatch(
          appQueryUpdate({
            appQueryId: appQuery.id,
            ...payload,
          }),
        );
      }

      if (status === AppUiStatus.complete) {
        dispatch(researchTaskConnectFetch(payload));
      }
    },
  });
};

export default listen;
