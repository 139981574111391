import { ObjectValues } from '../../utilities';

export const AI_ASSISTANT_STEPS = {
  INACTIVE: 'INACTIVE',
  INITIAL: 'INITIAL',
  LOADING: 'LOADING',
  RESPONSE: 'RESPONSE',
} as const;

export const AI_ASSISTANT_MODES = {
  BALLOON: 'BALLOON',
  SLASH: 'SLASH',
} as const;

export const AI_ASSISTANT_EVENTS = {
  onClickMenuItem: 'ai-assistant:onClickMenuItem',
  onClickResponseMenuItem: 'ai-assistant:onClickResponseMenuItem',
  onClickSlashItem: 'ai-assistant:onClickSlashItem',
  onClickUpsell: 'ai-assistant:onClickUpsell',
  onFocusTermChange: 'ai-assistant:onFocusTermChange',
  onOpenAssistant: 'ai-assistant:onOpenAssistant',
  onPageChangeEvent: 'ai-assistant:onPageChangeEvent',
  onTopicModelChange: 'ai-assistant:onTopicModelChange',
  onClickReplaceSelection: 'ai-assistant:onClickReplaceSelection',
  onClickInsertBelow: 'ai-assistant:onClickInsertBelow',
} as const;

export const AI_ASSISTANT_RESPONSE_MODES = {
  IN_BALLOON: 'IN_BALLOON',
  IN_EDITOR: 'IN_EDITOR',
} as const;

export type AiAssistantResponseModes = ObjectValues<
  typeof AI_ASSISTANT_RESPONSE_MODES
>;
export type AiAssistantSteps = ObjectValues<typeof AI_ASSISTANT_STEPS>;
export type AiAssistantModes = ObjectValues<typeof AI_ASSISTANT_MODES>;
export type AiAssistantEvents = ObjectValues<typeof AI_ASSISTANT_EVENTS>;

export type AiAssistantConfig = {
  isGated: boolean;
};
