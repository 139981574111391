import { forwardRef, Fragment } from 'react';
import { Popover } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import ExportTrigger from './components/ExportTrigger';
import { ExportProps } from './types';
import ExportMenu from './components/ExportMenu';

export const Export = forwardRef<HTMLButtonElement, ExportProps>(
  (
    {
      dataId,
      align,
      onItemClick,
      showPopover = true,
      loading = false,
      onButtonClick,
      disabled,
      canExportSelectedItems = false,
      children,
      locked,
      className,
      onClick,
    },
    ref,
  ) => {
    return (
      <Popover className="relative w-fit">
        {({ open, close }) => (
          <Fragment>
            <Popover.Button
              data-mms--research-export={dataId}
              className="focus:outline-none"
              disabled={disabled}
              onClick={onButtonClick}
            >
              <ExportTrigger
                ref={ref}
                children={children}
                disabled={disabled}
                locked={locked}
                className={className}
                open={open}
                showCaret={showPopover}
                loading={loading}
                onClick={onClick}
              />
            </Popover.Button>
            {!loading && open && showPopover && (
              <Popover.Panel
                className={twMerge(
                  ['absolute', 'z-20', 'px-4', 'mt-1', 'right-0', 'sm:px-0'],
                  align === 'left' ? 'left-0' : 'right-0',
                )}
              >
                <ExportMenu
                  canExportSelectedItems={canExportSelectedItems}
                  dataId={dataId}
                  onItemClick={key => {
                    onItemClick?.(key);
                    close();
                  }}
                />
              </Popover.Panel>
            )}
          </Fragment>
        )}
      </Popover>
    );
  },
);

export default Export;
