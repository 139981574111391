const NS = `https://app.marketmuse.com`;
const scopes = [
  `${NS}/email`,
  `${NS}/firstName`,
  `${NS}/lastName`,
  `${NS}/domain`,
  `${NS}/website`,
  `${NS}/dailyLoginCount`,
  `${NS}/dailyAddresses`,
  'openid',
  'profile',
  'email',
];

export default {
  CDN_JS: [
    'https://cdnjs.cloudflare.com/ajax/libs/izitoast/1.1.5/js/iziToast.min.js',
  ],
  CDN_CSS: [
    'https://cdnjs.cloudflare.com/ajax/libs/izitoast/1.1.5/css/iziToast.min.css',
    'https://mms-app.s3.us-east-1.amazonaws.com/fonts/suite/style.css',
    'https://rsms.me/inter/inter.css',
    'https://js.recurly.com/v4/recurly.css',
  ],

  auth0: {
    CLAIMS_NS: NS,
    scopes,
    options: {
      audience: '',
      scope: scopes.join(' '),
    },
  },
};

export const REACT_APP = {
  S3_APP_URI: process.env.REACT_APP_S3_APP_URI,
  MM_ORG_ID: process.env.REACT_APP_MM_ORG_ID,
  PROSPERSTACK: process.env.REACT_APP_PROSPERSTACK,
  GQL_LOG: process.env.REACT_APP_GQL_LOG,
  GQL_INTERCEPT: process.env.REACT_APP_GQL_INTERCEPT,
  PAPI_GQL: process.env.REACT_APP_PAPI_GQL,
  PAPI_REST: process.env.REACT_APP_PAPI_REST,
  CLERK_KEY: process.env.REACT_APP_CLERK_PUBLISHABLE_KEY,
  SENTRY_ENVIRONMENT: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  SENTRY_SAMPLING: process.env.REACT_APP_SENTRY_SAMPLING || '0',
  SESSION_SAMPLING: process.env.REACT_APP_SENTRY_SESSION_SAMPLING || 0,
  SESSION_ERROR_SAMPLING:
    process.env.REACT_APP_SENTRY_SESSION_ERROR_SAMPLING || 0,
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
};
