import { Plugin } from '@ckeditor/ckeditor5-core';
import { editor as EDITOR_CONFIG } from '@marketmuse/config/configs';
import { EDITOR } from '@marketmuse/config/types';
import ExportDropdown from './ExportDropdown';
import ExportGoogleDoc from './ExportGoogleDoc';

class Export extends Plugin {
  static get requires() {
    return [ExportDropdown, ExportGoogleDoc];
  }

  static get pluginName() {
    return 'Export';
  }

  constructor(editor) {
    super(editor);

    const config: EDITOR.ExportConfig = {
      isEnabled: false,
      filename: 'download',
      googleClientId: '',
      feature: {
        [EDITOR_CONFIG.EXPORT_FEATURE.word]: {
          hasAccess: false,
          isEnabled: false,
        },
        [EDITOR_CONFIG.EXPORT_FEATURE.excel]: {
          hasAccess: false,
          isEnabled: false,
        },
        [EDITOR_CONFIG.EXPORT_FEATURE.googleDoc]: {
          hasAccess: false,
          isEnabled: false,
        },
      },
    };

    editor.config.define(EDITOR_CONFIG.SETTINGS.CONFIG_NAME, config);
  }
}

export default Export;
