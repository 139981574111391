import React, { useState } from 'react';
import styled from 'styled-components';

import countries, { allowedCountries } from '../../config/countries';

import hideTooltip from '../../utils/hideTooltip';

import Popper from '../../components/Tooltip/Popper';

import {
  SimpleTextInputLabel,
  SimpleTextInputRequiredStar,
} from '../../components/SimpleTextInput';

const CountryFieldToggle = styled.button`
  ${p => p.theme.mixins.buttonReset};
  color: ${p => p.theme.colors.blueDark1};
  font-size: 14px;
  border-bottom: 1px solid currentColor;

  &:hover {
    color: ${p => p.theme.colors.blueDark1};
  }
`;

const SimpleCountry = ({
  onDebounce,
  targetValue,
  showSelector: _showSelector,
  ...props
}) => {
  const [showSelector, setShowSelector] = useState(_showSelector);
  const items = Object.values(allowedCountries).map(c => ({
    id: c.id,
    title: c.title,
    onClick: () => {
      hideTooltip('signup-country-dropdown');
      onDebounce && typeof onDebounce === 'function' && onDebounce(c.id, 'country');
    },
  }));
  return (
    <>
      {!showSelector && (
        <CountryFieldToggle
          onClick={setShowSelector.bind(this, !showSelector)}
          type="button"
        >
          Ranking outside of the US?
        </CountryFieldToggle>
      )}
      {showSelector && (
        <>
          <div>
            <SimpleTextInputLabel>
              <SimpleTextInputRequiredStar>*</SimpleTextInputRequiredStar>
              <span data-mms>Country</span>
            </SimpleTextInputLabel>
          </div>
          <Popper
            required
            tippyUsage="dropdown"
            tippyTheme="light"
            handleId="signup-country-dropdown"
            items={items}
            selection={countries[targetValue] && countries[targetValue].title}
          />
        </>
      )}
    </>
  );
};

export default SimpleCountry;
