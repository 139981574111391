import React from 'react';
import { Box } from '../Box';
import { ErrorScreenProps } from './types';
import { ChunkLoadError } from './components/ChunkLoadError';
import { UnknownError } from './components/UnknownError';

const wrapperClassNames = [
  'max-w-[560px]',
  'space-y-4',
  'prose',
  'prose-h1:leading-snug',
  'prose-h1:font-bold',
  'prose-h1:text-2xl',
];

const ErrorScreen = ({ error, componentStack, openChat }: ErrorScreenProps) => {
  let Component = UnknownError;
  if (error && error?.toString()?.includes('ChunkLoadError')) {
    Component = ChunkLoadError;
  } else {
    console.error(error, componentStack);
  }

  return (
    <div className="mm-tw" style={{ width: '100%', height: '100%' }}>
      <Box
        className={[
          'w-full',
          'h-full',
          'px-10',
          'py-8',
          'flex',
          'flex-col',
          'items-center',
          'justify-center',
        ]}
      >
        <Component openChat={openChat} wrapperClassNames={wrapperClassNames} />
      </Box>
    </div>
  );
};

export default ErrorScreen;
