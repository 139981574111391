import get from 'lodash/get';

import { InventorySubdomainsDocument as InventorySubdomains } from '@marketmuse/data-papi';
import Toast from '../../components/Toast';

import makeRequest from '../../utils/makeRequest';
import * as types from '../../config/types';

export default () =>
  ({ getState }) =>
  next =>
  action => {
    const state = getState();

    if (action.type === types.GET_INVENTORY_SUBDOMAINS) {
      makeRequest({
        type: types.GET_INVENTORY_SUBDOMAINS,
        mutation: InventorySubdomains,
        options: { isQuery: true },
        variables: {
          domain: action.domain,
          siteId: get(state, 'filter.site.id'),
        },
      }).then(res => {
        if (res.error) Toast.fire(res.error || 'Unknown error', 'error');
        else {
          const subdomains = get(res, 'inventorySubdomains') || [];
          if (typeof action.callback === 'function')
            action.callback(subdomains);
        }
      });
    }

    return next(action);
  };
