import { css } from 'styled-components';
import colors from './colors';
import spacing from './spacing';

const families = {
  main: "'Inter', Roboto, Arial, san-serif",
  heading: "'Inter', Roboto, Arial, san-serif",
};

// system--type: $heading-1;
const heading1 = css`
  font-family: ${families.heading};
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: ${36.31 / 30};
  text-transform: capitalize;
  color: ${colors.grey85};
`;

// system--type: $heading-2;
const heading2 = css`
  font-family: ${families.heading};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: ${32 / 24};
  color: ${colors.grey85};
`;

// system--type: $heading-3;
const heading3 = css`
  font-family: ${families.heading};
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: ${21.78 / 18};
  color: ${colors.grey85};
`;

// system--type: $heading-4;
const heading4 = css`
  font-family: ${families.heading};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: ${21.05 / 16};
  color: ${colors.grey85};
`;

// system--type: $heading-5;
const heading5 = css`
  font-family: ${families.heading};
  font-style: normal;
  font-weight: bold;
  line-height: 1.42;
  font-size: 14px;
  line-height: ${20.33 / 14};
  letter-spacing: -0.171111px;
  color: ${colors.grey85};
`;

// system--type: $heading-6;
const heading6 = css`
  font-family: ${families.heading};
  font-weight: bold;
  font-size: 12px;
  line-height: ${14.52 / 12};
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${colors.grey50};
`;

const textLarge = css`
  font-family: ${families.main};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: ${24 / 16};
  color: ${colors.grey85};
`;

const textRegular = css`
  font-family: ${families.main};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: ${22 / 14};
  color: ${colors.grey85};
`;

const textSmall = css`
  font-family: ${families.main};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: ${20 / 12};
  color: ${colors.grey85};
`;

const richText = css`
  p,
  ul,
  ol {
    margin-top: ${spacing.regular};
    margin-bottom: 0;
    line-height: 1.6;
    color: currentColor;
  }

  ul,
  ol {
    padding-left: ${spacing.medium};
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`;

export default {
  families,
  heading1,
  heading2,
  heading3,
  heading4,
  heading5,
  heading6,
  textSmall,
  textRegular,
  textLarge,
  richText,
};
