import PropTypes from 'prop-types';
import * as hotkeyPropTypes from './useHotkeys';

export const item = {
  // initial value for data-mms tag
  id: PropTypes.string,

  // uses MenuItem by default. Is passed following as props
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),

  // what to render as text
  title: PropTypes.string,

  // show a node on the left
  itemLeft: PropTypes.node,

  // show a node on the right
  itemRight: PropTypes.node,

  // hotkey string to show - used for menu.hotkeys
  hotkey: PropTypes.string,

  // array of contextMenuItemTypes
  children: PropTypes.array,

  disabled: PropTypes.bool,
};

export const menu = {
  // unique identifier... not needed yet
  id: PropTypes.string,

  // a11y
  label: PropTypes.string,

  // array of menu items to display
  items: PropTypes.arrayOf(PropTypes.shape(item)),

  // keyboard shortcuts to be active on mount
  hotkeys: PropTypes.arrayOf(PropTypes.shape(hotkeyPropTypes.hotkey)),

  origin: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
};
