import React, { useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import Popper from '../../../../../components/Tooltip/Popper';
import Box from '../../../../../components/Box';

import hideTooltip from '../../../../../utils/hideTooltip';
import { valuesShape } from '../filterFactoryTypes';
import handleEventBlock from '../../../../../utils/handleEventBlock';
import useRectangle from '../../../../../hooks/useRectangle';

const FilterDropdown = ({
  placeholder,
  selected,
  label,
  values,
  onChange,
  visible,
  tags,
  mb,
}) => {
  const ref = useRef(null);
  const rect = useRectangle(ref);
  const width = useMemo(() => `${rect.width}`, [rect.width]);
  const activeItems = values.filter(v => v.id === selected || v.active) || [];
  const activeItem = activeItems[0] || {};
  const identifier = new Date().getTime();
  useEffect(() => {
    if (visible) {
      hideTooltip(`to_close_${identifier}`);
    }
  }, [visible]);

  return (
    <Box
      ref={ref}
      boxVariant="flex"
      flexDirection="column"
      width="100%"
      mb={mb}
    >
      <Popper
        label={label}
        tippyUsage={'dropdown'}
        style={{ width: '100%' }}
        tags={tags}
        selectionStyle={{ border: '1px solid #e9e9e9' }}
        handleId={`to_close_${identifier}`}
        placeholder={placeholder}
        listStyle={{ width: `${width || 280}px` }}
        items={values.map(({ id, title, disabled, tags: itemTags }) => ({
          id,
          title,
          tags: itemTags,
          active: id === activeItem.id,
          disabled,
          onClick: e => {
            hideTooltip(`to_close_${identifier}`);
            handleEventBlock(e);
            onChange(id, e);
          },
        }))}
        selection={
          values.map(i => i.id).includes(activeItem?.id) && activeItem?.title
        }
      />
    </Box>
  );
};

FilterDropdown.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  values: PropTypes.arrayOf(valuesShape),
};

export default FilterDropdown;
