import {
  AsyncThunk,
  AsyncThunkOptions,
  AsyncThunkPayloadCreator,
  createAsyncThunk,
  Dispatch,
  unwrapResult,
} from '@reduxjs/toolkit';

import { RootState } from '../types';

type AsyncThunkConfig = {
  state?: RootState;
  dispatch?: Dispatch;
  extra?: unknown;
  rejectValue?: unknown;
  serializedErrorType?: unknown;
  pendingMeta?: unknown;
  fulfilledMeta?: unknown;
  rejectedMeta?: unknown;
};

function createNonConcurrentAsyncThunk<
  Returned,
  ThunkArg,
  ThunkApiConfig extends AsyncThunkConfig,
>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkApiConfig>,
  options?: AsyncThunkOptions<ThunkArg, ThunkApiConfig>,
): AsyncThunk<Returned, ThunkArg, unknown> {
  const pending: {
    payloadPromise?: Promise<unknown>;
    actionAbort?: () => void;
  } = {};

  const wrappedPayloadCreator = (arg, thunkAPI) => {
    const run = () => {
      if (thunkAPI.signal.aborted) {
        return thunkAPI.rejectWithValue({
          name: 'AbortError',
          message: 'Aborted',
        });
      }
      const promise = Promise.resolve(payloadCreator(arg, thunkAPI))
        .then(result => {
          if (pending.payloadPromise === promise) {
            pending.payloadPromise = null;
          }
          return result;
        })
        .catch(error => {
          if (pending.payloadPromise === promise) {
            pending.payloadPromise = null;
          }
          return thunkAPI.rejectWithValue(error);
        });

      return (pending.payloadPromise = promise);
    };

    return pending.payloadPromise
      ? (pending.payloadPromise = pending.payloadPromise.then(run, run))
      : run();
  };

  const internalThunk = createAsyncThunk(
    `${typePrefix}-protected`,
    wrappedPayloadCreator,
  );

  return createAsyncThunk<Returned, ThunkArg>(
    typePrefix,
    async (arg, thunkAPI) => {
      if (pending.actionAbort) {
        pending.actionAbort();
      }
      const internalPromise = thunkAPI.dispatch(internalThunk(arg));
      const abort = internalPromise.abort;
      pending.actionAbort = abort;

      try {
        const result = await internalPromise;
        return unwrapResult(result);
      } finally {
        if (pending.actionAbort === abort) {
          pending.actionAbort = null;
        }
      }
    },
    options,
  );
}

export default createNonConcurrentAsyncThunk;
