import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isNil from 'lodash/isNil';

import isNumeric from '../../utils/isNumeric';

const labelFlip = 25;
const borderRadius = 50;

const Wrapper = styled.div.attrs({

})`
  width: 100%;
  height: 20px;
  display: flex;
  ${p => p.thin && `height: 4px;`}
  ${p => p.trackHeight && `height: ${p.trackHeight}px;`}
`;

const Track = styled.div.attrs({
  className: 'mms--pb-track'
})`
  flex-grow: 1;
  position: relative;
  background-color: ${p => p.trackColorValue || p.theme[p.trackColor || 'mmxGreyE9']};
  height: 100%;
  overflow: hidden;
  border-radius: ${borderRadius}px;
  ${p => p.thin && `border-radius: 3px;`}
`;

const Bar = styled.div.attrs({
  className: 'mms--pb-bar'
})`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: ${borderRadius}px;
  background-color: ${p => p.colorValue || p.theme[p.color || 'mmxBlue']};
  width: calc(${p => p.value}% + 8%);
  ${p => p.value === 0 && `width: 0px;`}
  max-width: 100%;
  height: 100%;
  transition: ${p => p.theme.transition};
`;

const BarValue = styled.div.attrs({
  className: 'mms--pb-bar-value'
})`
  margin-top: 1px;
  position: absolute;
  right: 8px;
  color: white;
  font-size: 8pt;
  font-weight: 700;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  word-break: normal;
  ${p => (p.value < labelFlip) && `
    right: -8px;
    transform: translateX(100%);
    color: ${p.theme.mmxDarkGrey};
  `}
  ${p => p.value === 0 && `
    right: -12px;
  `}
`;

const SideValue = styled.div`
  width: 32px;
  flex-shrink: 0;
  font-size: 8pt;
  color: ${p => p.theme.mmxDarkGrey};
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProgressBar = props => {
  const val = isNil(props.value) ? '-' : Math.round(props.value);
  const valFormatted = typeof props.valueFormat === 'function' ?
    props.valueFormat(val) : val;

  return (
    <Wrapper
      thin={props.thin}
      style={props.style}
      className={`mms--progressbar ${props.className}`}
    >
      <Track
        thin={props.thin}
        trackColor={props.trackColor}
        trackColorValue={props.trackColorValue}
      >
        {isNumeric(val) && (
          <Bar
            value={val}
            color={props.color}
            colorValue={props.colorValue}
          >
            {!props.thin && !props.hideValue && (
              <BarValue value={val}>
                {valFormatted}
              </BarValue>
            )}
          </Bar>
        )}
        {!isNumeric(val) && (
          <Bar
            value={0}
            color={props.color}
            colorValue={props.colorValue}
          >
            {!props.thin && !props.hideValue && (
              <BarValue value={0}>-</BarValue>
            )}
          </Bar>
        )}
      </Track>
      {props.thin && !props.hideValue && (
        <SideValue>
          {valFormatted}
        </SideValue>
      )}
    </Wrapper>
  )
};

ProgressBar.propTypes = {
  color: PropTypes.string,
  trackColor: PropTypes.string,
  colorValue: PropTypes.string, // actual value of the color
  trackColorValue: PropTypes.string, // actual value of the color
  trackHeight: PropTypes.number, // manually give track a height
  style: PropTypes.object,
  value: PropTypes.number,
  // render a thin bar with its text on the side outside, instead
  // of a fat bar with the its text inside the bar
  thin: PropTypes.bool,
  hideValue: PropTypes.bool,
  // function that returns the formatted
  // value before render
  valueFormat: PropTypes.func,
  className: PropTypes.string,
};

ProgressBar.defaultProps = {
  thin: false,
  hideValue: false,
}

export default ProgressBar;
