import { useParams } from 'react-router-dom';
import { Box, Button, Text } from '@marketmuse/components';
import openSupportChat from '../../utils/openSupportChat';
import withTailwindCompatibility from '../../hocs/withTailwindCompatibility';

function Component() {
  const params = useParams();
  console.error({ 'No Match': params });
  return (
    <Box
      className={[
        'w-full',
        'h-full',
        'px-10',
        'py-8',
        'flex',
        'flex-col',
        'items-center',
        'justify-center',
      ]}
    >
      <Box
        className={[
          'max-w-[560px]',
          'space-y-4',
          'prose',
          'prose-h1:leading-snug',
          'prose-h1:font-bold',
          'prose-h1:text-2xl',
        ]}
      >
        <h1>We couldn't find this page</h1>
        <Text as="p">
          <Button onClick={() => openSupportChat()} variant={'blue400'}>
            Let us know what happened
          </Button>
        </Text>
      </Box>
    </Box>
  );
}

export const NoMatch = withTailwindCompatibility({
  Component,
  style: {
    height: '100%',
  },
});
