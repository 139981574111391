import styled from 'styled-components';

export const DropdownContainer = styled.div`
  flex-shrink: 0;
  text-align: left;
  width: 100%;
  min-width: 220px;
`;
export const DropdownInput = styled.div``;
export const DropdownTopic = styled.div`
  color: ${p => p.theme.colors.grey85};
  font-size: 9pt;
`;
export const DropdownUrl = styled.div`
  color: ${p => p.theme.mmxDarkGrey};
  font-size: 7pt;
  margin-top: -3px;
`;
export const DropdownTag = styled.div`
  display: inline-block;
  border: 1px solid ${p => p.theme.mmxGreyE6};
  border-radius: 3px;
  padding: 0 3px;
  margin-right: 3px;
  margin-top: 5px;
  font-size: 7pt;
  text-transform: capitalize;
`;
export const InputCountry = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 12px;
  margin-right: -12px;
  margin-left: -8px;
  padding-left: 10px;
  background-color: transparent;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  &:hover {
    cursor: pointer;
  }
`;
export const InputCountryFlag = styled.div`
  font-size: 7pt;
  margin-right: 2px;
`;
export const InputCountryAbbr = styled.div`
  font-size: 7pt;
  color: ${p => p.theme.mmxDarkGrey};
  text-transform: uppercase;
`;
