import React, { cloneElement, useMemo } from 'react';
import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react';

import { twMerge } from 'tailwind-merge';

import { FloatingUiProps, POPPER_THEME, POPPER_TYPE } from '../types';
import { useArrow } from './hooks/useArrow';
import { usePopper } from './hooks/usePopper';
import { mergeRefs } from './utils/mergeRefs';

type FloatingUiComponent = (
  props: FloatingUiProps,
) => React.ReactElement | null;

export const FloatingUi: FloatingUiComponent = ({
  arrowClassName,
  children,
  delay,
  disabled = false,
  duration,
  hideOnScroll,
  initialOpen = false,
  middlewares,
  offsetSize = 5,
  onOpenChange,
  placement = 'top',
  popOnHover,
  popperType,
  ref,
  render,
  showArrow,
  theme = POPPER_THEME.light,
}) => {
  const {
    refs,
    placement: renderedPosition,
    context,
    getReferenceProps,
    open,
    setOpen,
    strategy,
    middlewareData,
    x,
    y,
    getFloatingProps,
    arrowRef,
    styles,
  } = usePopper({
    delay,
    duration,
    hideOnScroll,
    initialOpen,
    middlewares,
    offsetSize,
    onOpenChange,
    placement,
    popOnHover,
    popperType,
    showArrow,
  });

  const arrow = useArrow(middlewareData, renderedPosition, theme);

  const content = useMemo(
    () => (
      <div
        ref={refs.setFloating}
        style={{
          position: strategy,
          top: y ?? 0,
          left: x ?? 0,
          visibility: x == null ? 'hidden' : 'visible',
          ...styles,
        }}
        {...getFloatingProps()}
      >
        {render &&
          render({
            close: () => {
              setOpen(false);
            },
          })}
        {showArrow && (
          <div
            ref={arrowRef}
            className={twMerge(arrow.colorClassName, arrowClassName)}
            style={{
              content: '',
              position: 'absolute',
              borderStyle: 'solid',
              ...arrow.position,
            }}
          />
        )}
      </div>
    ),
    [
      arrow.colorClassName,
      arrow.position,
      arrowClassName,
      arrowRef,
      refs,
      getFloatingProps,
      render,
      setOpen,
      showArrow,
      strategy,
      styles,
      x,
      y,
    ],
  );

  const contentWrapper = useMemo(() => {
    if (popperType === POPPER_TYPE.popover) {
      return (
        <FloatingFocusManager context={context}>{content}</FloatingFocusManager>
      );
    }
    return content;
  }, [popperType, content, context]);

  const cloneRef = useMemo(
    () => mergeRefs([refs.setReference, ref, (children as any).ref]),
    [refs, ref, children],
  );

  return (
    <>
      {cloneElement(
        children,
        getReferenceProps({ ...children.props, ref: cloneRef }),
      )}

      {!disabled && (
        <FloatingPortal id="mms--fui-root">
          {open && contentWrapper}
        </FloatingPortal>
      )}
    </>
  );
};
