import download from 'downloadjs';
import { htmlToWord } from './htmlToWord';

export const exportDoc = async ({
  data,
  filename,
}: {
  data: string;
  filename: string;
}) => {
  const document = await htmlToWord({ data });
  const dateString = new Date().toLocaleDateString('en-US');
  const mimeType = 'application/msword';
  const documentName = `${dateString}-marketmuse-${filename}.doc`;

  download(document, documentName, mimeType);
};
