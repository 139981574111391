import React, { ComponentType } from 'react';
import { useStoreSelector } from '@marketmuse/data-state/hooks';

type Prop = {
  siteId: string;
};

const withSiteIdProp =
  <P extends object>(Component: ComponentType<P & Prop>): React.FC<P> =>
  props => {
    const siteId = useStoreSelector(state => state.filter?.site?.id);

    const modifiedProps = {
      siteId,
      ...props,
    };

    return <Component {...modifiedProps} />;
  };

export default withSiteIdProp;
