import { pick } from 'lodash';
import { PAPI } from '@marketmuse/config/types';
import { validatePermission } from '@marketmuse/utilities';
import {
  AppQueryNetworkStatus,
  AppQueryNetworkKeys,
  ReduxListener,
  ResearchKGItem,
  RootState,
} from '../../../types';

import {
  researchTaskConnectFetch,
  researchTaskStatusCheck,
  researchTaskWebsiteHeatmapData,
} from '../../actions';
import { dataSlice, networkSlice } from '../../slices';
import { getKnowledgeGraph, getBulkAdwordsKeywords } from '../../thunks';

const listeners = (startListening: ReduxListener) => {
  const dataActions = dataSlice.actions;
  const networkActions = networkSlice.actions;

  startListening({
    actionCreator: getKnowledgeGraph.pending,
    effect: async (action, { dispatch }) => {
      await dispatch(
        networkActions.knowledgeGraphSet(AppQueryNetworkStatus.loading),
      );
    },
  });

  startListening({
    actionCreator: getKnowledgeGraph.rejected,
    effect: async (action, { dispatch }) => {
      const { aborted } = action.meta;
      if (!aborted) {
        await Promise.all([
          dispatch(
            networkActions.knowledgeGraphSet(AppQueryNetworkStatus.error),
          ),
          dispatch(
            networkActions.setMany({
              keys: [
                'bulkAdwordsKeywords',
                'inventoryTopicsKG',
                'inventoryTopicsBulkKeywords',
              ] as AppQueryNetworkKeys[],
              status: AppQueryNetworkStatus.inactive,
            }),
          ),
          dispatch(
            networkActions.inventoryTopicsKGSet(AppQueryNetworkStatus.inactive),
          ),
        ]);

        // check status after other steps
        await dispatch(researchTaskStatusCheck());
      }
    },
  });

  startListening({
    actionCreator: getKnowledgeGraph.fulfilled,
    effect: async ({ payload }, { dispatch }) => {
      await Promise.all([
        dispatch(dataActions.knowledgeGraphSet(payload.data)),
        dispatch(
          networkActions.knowledgeGraphSet(AppQueryNetworkStatus.complete),
        ),
      ]);

      // check status after other steps
      await dispatch(researchTaskStatusCheck());
    },
  });

  startListening({
    actionCreator: getKnowledgeGraph.fulfilled,
    effect: async (action, { getState, dispatch }) => {
      const state: RootState = getState();
      const items = action.payload.data.knowledgeGraphItems || [];

      await dispatch(
        researchTaskConnectFetch(
          pick(state.appResearch.ui.params, [
            'networkDomains',
            'competitorDomains',
          ]),
        ),
      );

      if (Array.isArray(items) && items.length > 0) {
        const permissions = state.permissions;

        const shouldLimitDataTopicNavigator = !validatePermission({
          permissions,
          permission: {
            resource: PAPI.PermissionResource.RESEARCH_TOPIC_NAVIGATOR,
            action: PAPI.PermissionAction.ACCESS_FULL_DATA,
          },
        });

        const terms = (
          shouldLimitDataTopicNavigator ? items.slice(0, 10) : items
        ).map(({ term }) => term);

        await dispatch(
          getBulkAdwordsKeywords({
            ...pick(state.appResearch.ui.params, ['country', 'language']),
            terms,
          }),
        );
      } else {
        await dispatch(
          networkActions.setMany({
            keys: [
              'bulkAdwordsKeywords',
              'inventoryTopicsKG',
              'inventoryTopicsBulkKeywords',
            ] as AppQueryNetworkKeys[],
            status: AppQueryNetworkStatus.inactive,
          }),
        );
      }

      const { include = '', exclude = [] } = pick(
        state.appResearch.views.heatmap.filters,
        ['include', 'exclude'],
      );

      await dispatch(
        researchTaskWebsiteHeatmapData({
          include,
          exclude,
          knowledgeGraphItems: action.payload.data
            .knowledgeGraphItems as ResearchKGItem[],
        }),
      );
    },
  });
};

export default listeners;
