import { Site } from '@marketmuse/config/types/papi';

export const serializeAccessibleInventories = (sites: Array<Site>) => {
  const ordered: Array<Site> = (sites || [])
    .filter(site => site?.visible)
    .map(site => ({ ...site, title: site?.title || site?.domain }))
    .sort((a, b) => a.title.localeCompare(b.title));

  return ordered;
};
