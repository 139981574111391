import { Box, Text } from '@marketmuse/components';
import { ReactComponent as SpaceCrawler } from '@marketmuse/components/assets/space-crawler.svg';
import { ReactComponent as SpacePersonSearching } from '@marketmuse/components/assets/space-person-searching.svg';

interface NoResultsProps {
  title: string;
  body: string;
  imageType?: 'person' | 'crawler';
}

const imageMap = {
  person: {
    component: SpacePersonSearching,
    size: [245, 211],
  },
  crawler: {
    component: SpaceCrawler,
    size: [371, 199],
  },
};

const NoResults = ({ title, body, imageType = 'person' }: NoResultsProps) => {
  const Image = imageMap[imageType].component;
  const style = {
    width: imageMap[imageType].size[0],
    height: imageMap[imageType].size[1],
  };

  return (
    <Box
      className={['max-w-md', 'text-gray-700', 'text-center']}
      data-mms--research-messaging="no-results"
    >
      <Box className={['flex', 'flex-row', 'justify-center', 'items-center']}>
        <Image style={style} />
      </Box>
      <Text
        as="h1"
        className={['prose', 'font-bold', 'text-3xl', 'mt-4', 'text-gray-600']}
      >
        {title}
      </Text>
      <Text
        as="p"
        className={['prose', 'font-medium', 'text-lg', 'mt-2', 'text-gray-600']}
      >
        {body}
      </Text>
    </Box>
  );
};

export default NoResults;
