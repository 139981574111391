import { ACTION_TYPES } from '@marketmuse/data-state';
import * as types from '../config/types';

export const getInventorySubdomains = ({ domain, callback }) => ({
  type: types.GET_INVENTORY_SUBDOMAINS,
  domain,
  callback,
});

// SEO CALL
export const seoKnowledgeGraphAndVariants = ({
  term,
  url,
  skipVariants,
  fetchSerpPages,
  callback,
}) => ({
  type: types.GET_KEYWORD_DETAILS_KG,
  term,
  url,
  skipVariants,
  fetchSerpPages,
  callback,
});

// UPDATES INV-DATA
export const inventoryItemsUpdate = ({
  pages,
  topics,
  resources,
  noFeedback,
  callback,
  undoCallback,
  updateType,
}) => ({
  type: types.INVENTORY_ITEMS_UPDATE,
  pages,
  topics,
  resources,
  noFeedback,
  callback,
  undoCallback,
  updateType,
});

// CREATES NEW ORGS
export const inventoryCreateOrg = ({ fields, noErrorToast, callback }) => ({
  type: types.CREATE_INVENTORY_ORG,
  fields,
  noErrorToast,
  callback,
});

// SITE MODEL
export const inventoryCreate = ({ orgId, params, options, callback }) => ({
  type: types.INVENTORY_CREATE,
  orgId,
  params,
  options,
  callback,
});

export const inventoryUpdate = ({
  updateSiteId,
  fields = {},
  callback,
  keys = [],
  customToast = false,
}) => ({
  type: types.INVENTORY_UPDATE,
  updateSiteId,
  fields,
  callback,
  keys,
  customToast,
});

export const updateActiveInventory = (args = {}) => ({
  type: ACTION_TYPES.INVENTORY_FIELDS_UPDATE,
  payload: args,
});
