import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';

import Popper from '../Tooltip/Popper';

import COUNTRY_CODES from '../../config/countryCodes';

import hideTooltip from '../../utils/hideTooltip';
import { clearCacheItems } from '../../actions/toolsActions';
import handleEventBlock from '../../utils/handleEventBlock';
import IconSvg from '../IconSvg/IconSvg';
import DataList from '../DataList';
import Autocomplete from '../Autocomplete';
import { InputCountry, InputCountryFlag, InputCountryAbbr } from './styles';

import { useCountries } from './hooks/useCountries';
import { useLanguages } from './hooks/useLanguages';
import { useActiveLanguage } from './hooks/useActiveLanguage';
import { useActiveCountry } from './hooks/useActiveCountry';
import { useCachedItemsList } from './hooks/useCachedItemsList';

const filterItemsFn = (item, query) => {
  if (!query) {
    return true;
  }
  return (item.name || '').toLowerCase().indexOf(query.toLowerCase()) !== -1;
};

export const AppAutocomplete = ({
  style,
  activeCountry = COUNTRY_CODES.US,
  activeLanguage,
  inputProps = {},
  setLanguage,
  setCountry,
  setCountryLanguage,
  ...rest
}) => {
  const dispatch = useDispatch();
  const canSetLanguage = useMemo(
    () => [setCountryLanguage].some(fn => typeof fn === 'function'),
    [setCountryLanguage],
  );
  const countriesList = useCountries(canSetLanguage);
  const country = useActiveCountry(countriesList, activeCountry);

  const language = useActiveLanguage({
    country,
    activeLanguage,
  });
  const languagesItems = useLanguages({
    setCountryLanguage,
    country,
    language,
  });

  const cachedItemsList = useCachedItemsList({
    activeCountry,
    activeLanguage,
    canSetLanguage,
  });

  return (
    <Autocomplete
      {...rest}
      inputProps={{
        ...inputProps,
        id: 'mms__app_topic_input',
        'data-mms--app-param-term': true,
        'data-mms--page-header-input': 'term',
        itemsRight: [
          ...(canSetLanguage
            ? [
                <DataList
                  highlightSelected
                  filterItemsFn={filterItemsFn}
                  noResultsText="No countries found..."
                  list={countriesList}
                  selection={country}
                  inputProps={{
                    placeholder: 'Search',
                    name: 'app-country-selection',
                    itemsLeft: [<IconSvg name="search" size="16" />],
                  }}
                  handleId="mms--app-country-dropdown"
                  onSelect={(value = {}, event) => {
                    handleEventBlock(event);

                    setCountryLanguage({
                      country: value.id,
                      language: value.languages?.[0]?.id,
                    });
                    hideTooltip('mms--app-country-dropdown');
                  }}
                >
                  <InputCountry data-mms--app-country-trigger>
                    <InputCountryFlag>{get(country, 'flag')}</InputCountryFlag>
                    &nbsp;
                    <InputCountryAbbr>{get(country, 'id')}</InputCountryAbbr>
                  </InputCountry>
                </DataList>,
                <Popper
                  handleId="mms--app-language-dropdown"
                  selectionStyle={{ height: '100%' }}
                  onClick={e => handleEventBlock(e)}
                  style={{ height: '100%' }}
                  items={languagesItems}
                >
                  <InputCountry
                    data-mms--app-language-trigger
                    style={{ paddingLeft: 0 }}
                  >
                    <InputCountryAbbr>
                      &nbsp;({get(language, 'code')})
                    </InputCountryAbbr>
                  </InputCountry>
                </Popper>,
              ]
            : []),
          ...(!canSetLanguage
            ? [
                <Popper
                  handleId="mms--app-country-dropdown"
                  selectionStyle={{ height: '100%' }}
                  style={{ height: '100%' }}
                  items={countriesList.map(({ id, title, languages }) => ({
                    id,
                    title,
                    tags: { 'data-mms--app-country-option': id },
                    active: id === country?.id,
                    onClick: () => {
                      if (typeof setCountry === 'function') {
                        hideTooltip('mms--app-country-dropdown');
                        setCountry(id);
                      }
                    },
                  }))}
                >
                  <InputCountry data-mms--app-country-trigger>
                    <InputCountryFlag>{get(country, 'flag')}</InputCountryFlag>
                    &nbsp;
                    <InputCountryAbbr>{get(country, 'id')}</InputCountryAbbr>
                  </InputCountry>
                </Popper>,
              ]
            : []),
        ],
      }}
      style={style}
      popperProps={{
        headText: 'Recent Queries',
        headCTATitle: 'Clear all',
        headCTAClick: () => dispatch(clearCacheItems()),
        items: cachedItemsList,
        ...(rest.popperProps || {}),
      }}
    />
  );
};
