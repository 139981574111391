import { NETWORK_STATUS } from '@marketmuse/config/types';
import { ResearchNetworkStatus } from '../../types';

export const initialState: ResearchNetworkStatus = {
  adwordsKeywords: NETWORK_STATUS.inactive,
  adwordsQuestions: NETWORK_STATUS.inactive,
  bulkAdwordsKeywords: NETWORK_STATUS.inactive,
  knowledgeGraph: NETWORK_STATUS.inactive,
  inventoryTopicsKeywords: NETWORK_STATUS.inactive,
  inventoryTopicsBulkKeywords: NETWORK_STATUS.inactive,
  inventoryTopicsKG: NETWORK_STATUS.inactive,
  inventoryTopicsQuestions: NETWORK_STATUS.inactive,
  inventoryTopicsSerpQuestions: NETWORK_STATUS.inactive,
  linkingRecommendations: NETWORK_STATUS.inactive,
  serpData: NETWORK_STATUS.inactive,
  serpPages: NETWORK_STATUS.inactive,
  serpQuestions: NETWORK_STATUS.inactive,
  serpUserIntentData: NETWORK_STATUS.inactive,
  websiteHeatmap: NETWORK_STATUS.inactive,
};
