import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Tile from '../Tile';

const TileStyled = styled(Tile)`
  justify-content: flex-start;
  padding: ${p => `${p.theme.slideoutPaddingTB}px ${p.theme.slideoutPaddingLR}px`};
  padding-top: 14px;
  height: 100%;
`;

const TileLargeText = styled.div`
  font-size: 9pt;
  color: ${p => p.theme.mmxDarkGrey};
`;

const TileLargeContent = styled.div`
  font-size: 14pt;
  color: ${p => p.theme.colors.grey85};
  margin-top: 8px;
  line-height: 22px;
`;

const TileLarge = ({ title, content, className }) => (
  <TileStyled className={className}>
    <TileLargeText>{title}</TileLargeText>
    <TileLargeContent>{content}</TileLargeContent>
  </TileStyled>
);

TileLarge.propTypes = {
  title: PropTypes.any,
  content: PropTypes.any,
  className: PropTypes.string,
};

export default TileLarge;
