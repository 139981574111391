import { camelCase, get, set, isEmpty } from 'lodash';

import { GetFormDocument } from '@marketmuse/data-papi';
import makeRequest from '../../utils/makeRequest';
import * as types from '../../config/types';

const orderByVisibility = (items, orderVisible, orderHidden) => {
  // Collect sorting info for items
  const data = items.reduce(
    (acc, item) => {
      const indexVisible = orderVisible.indexOf(item.id);
      const indexHidden = orderHidden.indexOf(item.id);

      if (indexVisible !== -1) {
        acc.sortedVisible[indexVisible] = item;
      }

      if (indexHidden !== -1) {
        acc.sortedHidden[indexHidden] = item;
      }

      return acc;
    },
    {
      sortedHidden: [],
      sortedVisible: [],
    },
  );

  // Build sorted items
  // Sorted items are needed for ReportForm
  const nextOrderHidden = data.sortedHidden.filter(i => i).map(item => item.id);
  const nextOrderVisible = data.sortedVisible
    .filter(i => i)
    .map(item => item.id);

  return {
    orderHidden: nextOrderHidden,
    orderVisible: nextOrderVisible,
  };
};

const collectFieldData = ({ groupsDict, groups }) => {
  // Collect all fields
  const fieldsFlat = groups.reduce(
    (acc, group) =>
      acc.concat(
        group.orderVisible.map(fieldId =>
          group.fields.find(({ id }) => id === fieldId),
        ),
      ),
    [],
  );

  const fieldsDict = fieldsFlat.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});

  const requiredFields = fieldsFlat
    .filter(field => {
      // Simple fields can be required generically
      if (field.required) {
        return true;
      }

      // Field may have items, which may be individually/all required
      const requiredItems = get(field, 'fieldData.requiredItems') || {};
      if (!isEmpty(requiredItems)) {
        return true;
      }

      // Field is not required
      return false;
    })
    .reduce(
      (acc, field) => {
        const type = get(groupsDict, `${field.group.id}.type`, 'section');
        acc[`${type}s`].push(field);
        return acc;
      },
      { sections: [], tabSections: [] },
    );

  return {
    fieldsDict,
    requiredFields,
  };
};

const collectGroupData = ({ groups }) => {
  return groups.reduce(
    (acc, g) => {
      const group = { ...g };
      const sorting = orderByVisibility(
        g.fields,
        g.orderVisible,
        g.orderHidden,
      );

      // Store sorting information
      set(group, 'orderHidden', sorting.orderHidden);
      set(group, 'orderVisible', sorting.orderVisible);

      // Add group to dictionary
      acc.groupsDict[group.id] = group;

      // For simple front end validation, seperate by type
      acc[`${camelCase(group.type)}s`].push(group);

      return acc;
    },
    { groupsDict: {}, sections: [], tabSections: [] },
  );
};

export default () => () => next => action => {
  if (action.type === types.GET_FORM) {
    makeRequest({
      type: types.GET_FORM,
      mutation: GetFormDocument,
      variables: {
        id: action.id,
      },
      options: {
        isQuery: true,
      },
      apolloOptions: {
        fetchPolicy: 'no-cache',
      },
    }).then(res => {
      const data = get(res, 'form', {});
      const {
        groupsDict = {},
        sections = [],
        tabSections = [],
      } = collectGroupData(data);

      const orderedSections = orderByVisibility(
        sections,
        data.orderVisible,
        data.orderHidden,
      );

      const orderedTabSections = orderByVisibility(
        tabSections,
        data.orderVisible,
        data.orderHidden,
      );

      const groups = []
        .concat(orderedSections.orderVisible, orderedTabSections.orderVisible)
        .map(gid => data.groups.find(({ id }) => id === gid));

      const { requiredFields, fieldsDict } = collectFieldData({
        groupsDict,
        groups,
      });

      if (typeof action.callback === 'function') {
        const value = {
          ...data,
          groups,
          groupsDict,
          requiredFields,
          fieldsDict,
          orderedSections,
          orderedTabSections,
        };
        action.callback(value);
      }
    });
  }

  return next(action);
};
