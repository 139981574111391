import get from 'lodash/get';

import { AppQueriesDocument } from '@marketmuse/data-papi';
import * as types from '../../config/types';
import makeRequest from '../../utils/makeRequest';

export default () =>
  ({ getState }) =>
  next =>
  action => {
    if (action.type === types.GET_APP_QUERIES) {
      const state = getState();
      const siteId = get(state, 'filter.site.id');
      const { app, page, limit } = action.options;

      makeRequest({
        type: types.GET_APP_QUERIES,
        mutation: AppQueriesDocument,
        variables: {
          order: 'created_at__desc',
          page,
          limit,
          filters: [
            {
              app: app,
            },
            {
              site: [{ id: siteId }],
            },
          ],
        },
        options: {
          isQuery: true,
        },
        apolloOptions: {
          fetchPolicy: 'no-cache',
        },
      }).then(response => {
        const data = get(response, 'appQueries');

        if (action.callback && data) {
          action.callback(data);
        }
      });
    }

    return next(action);
  };
