import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { randomNumber } from '../../utils/misc';

const Label = styled.div`
  position: relative;
  height: 320px !important;
  color: ${p => p.theme.mmxDarkGrey};
  margin-bottom: 0 !important;
  user-select: auto !important;
  background-color: white;
  &:hover { cursor: pointer; }
`;

const LabelFiller = styled.div`
  background-color: #f4f4f4;
  height: 12px;
`;

const LabelText = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  transform: rotate(-90deg);
  transform-origin: top left;
  font-size: 12px;
  left: 0;
  bottom: -40px;
  width: ${p => 320 - p.theme.matrixCellSize}px;
  height: ${p => p.theme.matrixCellSize}px;
  padding: 0 12px;

  ${p => p.theme.truncate}

  a {
    color: ${p => p.theme.mmxDarkGrey} !important;
    &:hover {
      text-decoration: underline !important;
    }
  }
`;

const LabelTruncate = styled.div`
  ${p => p.theme.truncate}
`;

const LabelTop = styled.div.attrs(p => ({ 'data-mms--matrix' : 'grid-column-rank' }))`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${p => p.theme.matrixCellSize}px;
  position: relative;

  &:before {
    content: '';
    width: 100%;
    height: ${p => p.theme.matrixCellSize / 2}px;
    background-color: white;
    position: absolute;
    bottom: 0;
  }
`;

const MatrixColumnLabel = props => {
  let { label, href, attrs = {} } = props;
  try {
    label = label.replace('http://', '');
    label = label.replace('https://', '');
    label = label.replace('www.', '');
    if (label.substr(-1) === '/') {
      label = label.substr(0, label.length - 1);
    }
  } catch (e) {
    /** do nothing */
  }

  return (
    <Label className={props.className} {...attrs}>
      {props.top && <LabelTop>{props.top}</LabelTop>}
      <LabelText>
        <LabelTruncate>
          {props.label && (
            <a href={href} target="_new">
              {label}
            </a>
          )}
          {!props.label && label}
        </LabelTruncate>
        {props.loading && (
          <LabelFiller style={{ width: randomNumber(50, 180) }} />
        )}
      </LabelText>
    </Label>
  );
};

MatrixColumnLabel.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.any,
  top: PropTypes.any
};

export default MatrixColumnLabel;
