import React, { useMemo, Fragment } from 'react';
import { Navigate } from 'react-router-dom';
import { SignedOut, SignedIn } from '@clerk/clerk-react';

import { MISC_SIMPLE_KEYS } from '@marketmuse/data-state/types';
import { useStoreSelector } from '@marketmuse/data-state/hooks';

import useIdentityProvider from '../providers/IdentityProvider/useIdentityProvider';
import ScreenLoader from '../components/ScreenLoader';

type withIdpOnlyFn = (Component: React.FC) => React.FC;
const withIdpUserOnly: withIdpOnlyFn = Component => {
  const Authenticated: React.FC = (): JSX.Element | null => {
    const { user: idpUser } = useIdentityProvider();
    const id = useStoreSelector(state => state.user?.id);
    const processingSignup = useStoreSelector(
      state => state.misc?.[MISC_SIMPLE_KEYS.processingSignup],
    );

    const shouldShow = useMemo(() => {
      const idpUserOnly = id && idpUser?.id && idpUser?.id === id;
      return idpUserOnly || processingSignup;
    }, [id, idpUser, processingSignup]);

    return (
      <Fragment>
        <SignedOut>
          <ScreenLoader.Auth />
        </SignedOut>
        <SignedIn>
          {shouldShow ? <Component /> : <Navigate to={'/login'} />}
        </SignedIn>
      </Fragment>
    );
  };

  return Authenticated;
};

export default withIdpUserOnly;
