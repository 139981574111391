import get from 'lodash/get';
import { OrganizationUpdateDocument as UPDATE_ORG_DATA } from '@marketmuse/data-papi';
import Toast from '../../components/Toast';

import makeRequest from '../../utils/makeRequest';
import * as types from '../../config/types';
import { saveOrgData } from '../../actions/authenticateActions';

export default () => ({ dispatch, getState }) => next => action => {
  const state = getState();

  if (action.type === types.UPDATE_ORG_DATA) {
    const userOrgId = get(state, 'user.org.id');
    const actionOrgId = action?.orgId;
    const orgId = actionOrgId || userOrgId;
    const primaryContactId = get(action.fields, 'primaryContact');
    delete action.fields.primaryContact;
    makeRequest({
      type: types.UPDATE_ORG_DATA,
      mutation: UPDATE_ORG_DATA,
      variables: {
        organizationId: orgId,
        organizationInput: {
          ...action.fields,
          primaryContactId: primaryContactId
        },
      },
      keys: Object
        .keys(action.fields || {})
        .map(k => `${types.UPDATE_ORG_DATA}-${k}`)
        .concat((action.keys || []).map(k => `${types.UPDATE_ORG_DATA}-${k}`))
    }).then(res => {
      const orgData = get(res, 'organizationUpdate', {}) || {};
      if (orgData && orgData.id) {
        Toast.fire('Saved');
        if (action.callback) action.callback(orgData);
        if (!action.orgId || get(state, 'user.org.id') === action.orgId) {
          dispatch(saveOrgData(orgData));
        }
      } else {
        Toast.fire(get(res, 'error') || 'Failed to save organization', 'error');
        if (action.callback) action.callback(false);
      }
    });
  }

  return next(action);
};
