import PropTypes from 'prop-types';

// https://atomiks.github.io/tippyjs/v6/all-props/#popperoptions
export const popperOptions = PropTypes.shape({
  strategy: PropTypes.string,
  placement: PropTypes.string,
  modifiers: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, options: PropTypes.object })
  ),
});

export const trigger = PropTypes.oneOf([
  'mouseenter focus',
  'mouseenter click',
  'click',
  'focusin',
  'manual',
]);

export const placement = PropTypes.oneOf([
  'top',
  'top-start',
  'top-end',
  'right',
  'right-start',
  'right-end',
  'bottom',
  'bottom-start',
  'bottom-end',
  'left',
  'left-start',
  'left-end',
  'auto',
  'auto-start',
  'auto-end',
]);

export const plugin = PropTypes.shape({
  name: PropTypes.string,
  defaultValue: PropTypes.bool,
  fn: PropTypes.func,
});

// https://popper.js.org/docs/v2/modifiers/offset/#options
export const offset = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.array,
  PropTypes.string,
  PropTypes.number,
]);
