import { HttpLink } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import fetch from 'cross-fetch';
import { get, isNil } from 'lodash';

import { ApiLink } from '../types';

const customFetch = (uri: string | URL | Request, options?: RequestInit) => {
  let operationName = null;
  if (!isNil(options?.body)) {
    try {
      const body = JSON.parse(options?.body as string);
      operationName = get(body, 'operationName');
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  return fetch(`${uri}?op=${operationName || ''}`, {
    ...(options || {}),
  });
};

export const httpLink = ({ endpoint }: ApiLink) => {
  return new HttpLink({ uri: endpoint, fetch: customFetch });
};
