import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isNil, get } from 'lodash';

import IconSvg from '../IconSvg';
import Clickable from '../Clickable';
import LoadingBlock from '../LoadingBlock';
import SimpleTextInput from '../SimpleTextInput';
import Button from '../Button';
import Popper from '../Tooltip/Popper';

import isNumeric from '../../utils/isNumeric';
import format from '../../utils/format';

const height = 24;

export const FOOTER_HEIGHT = 52;

// TODO: MMS-290
export const TableFooter = styled.div`
  flex-shrink: 0;
  width: 100%;
  height: ${FOOTER_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: 999;
  position: sticky;
  bottom: 0;
  left: 0;
  border-top: 1px solid ${p => p.theme.mmxGreyE9};
`;

export const TableFooterSideItem = styled.div`
  position: absolute;
  padding: 0 12px;
  color: ${p => p.theme.colors.grey85};
  font-size: 12px;

  &:last-child {
    right: 0;
  }

  &:first-child {
    left: 0;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 32px;
  font-size: 8.5pt;
`;

const Item = styled.div`
  margin-right: 8px;
  &:last-child { margin: 0; }
`;

const Page = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${height}px;
  padding: 0 2px;
  border-radius: 3px;
  font-weight: bold;
  color: ${p => p.theme.colors.grey85};
`;

const CurrentPage = styled(Page)`
  background-color: ${p => p.theme.mmxGreyE9};
  padding: 0;
`;

const SimpleTextInputStyled = styled(SimpleTextInput).attrs({ p: 2 })`
  background-color: transparent;
  width: 50px;
  transition: ${p => p.theme.transition};
  input {
    text-align: center;
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  ${p => p.focused && `
    width: 70px;
    input {
      text-align: left;
      padding-left: 8px;
    }
  `}
`;

const ButtonStyled = styled(Button)`
  padding: 0 4px;
  font-size: 7pt;
  height: 14px;
  min-height: unset;
`;

const IconStyled = styled(IconSvg)`
  ${p => p.disabled && `color: ${p.theme.mmxGreyF4};`}
`;

const ItemsShowingWrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${height}px;
`;

export const ItemsShowing = props => {
  return (
    <ItemsShowingWrapper>
      <div style={{ marginRight: 8 }}>Showing</div>
      <Popper
        selection={props.itemsPerPage}
        selectionStyle={{ padding: 0, height: '100%', paddingLeft: 8, paddingRight: 8 }}
        items={(props.itemsPerPageOptions || []).map(ipp => ({
          title: ipp,
          onClick: () => {
            if (typeof props.itemsPerPageChanged === 'function') {
              props.itemsPerPageChanged(ipp);
            }
          }
        }))}
      />
      {!isNil(props.itemsTotal) && (
        <>
          <div style={{ marginLeft: 6 }}>of</div>
          <div style={{ marginLeft: 4 }}><b>{format(props.itemsTotal)}</b></div>
          <div style={{ marginLeft: 4 }}>found</div>
        </>
      )}
    </ItemsShowingWrapper>
  )
}

const Pagination = props => {
  const inputEl = useRef(null);

  const [tmpPage, setTmpPage] = useState(null);
  const [focused, setFocused] = useState(false);

  let totalPages = props.total;
  if (!isNil(props.totalItems) && !isNil(props.itemsPerPage)) {
    totalPages = Math.ceil(props.totalItems / props.itemsPerPage);
  }

  const hasTotal = !isNil(totalPages);
  const hasJumpToPage = (
    !isNil(props.jumpToPage) &&
    typeof props.jumpToPage === 'function'
  );

  const hasNext = hasTotal ? props.page < totalPages : !isNil(props.onNext);
  const hasPrev = hasTotal ? props.page > 1 : !isNil(props.onPrev);

  const tmpPageNumber = parseInt(tmpPage);
  const inputActive = focused && hasTotal && hasJumpToPage;
  const inputValid = (
    hasTotal &&
    hasJumpToPage &&
    isNumeric(tmpPage) &&
    tmpPageNumber !== props.page &&
    tmpPageNumber > 0 &&
    tmpPageNumber <= totalPages
  );

  const cancel = () => {
    setTmpPage(null);
    setFocused(false);
    inputEl.current.blur();
  }

  const submit = () => {
    if (inputValid && hasJumpToPage) {
      props.jumpToPage(tmpPageNumber);
      resetScroll()
      cancel();
    }
  }

  const resetScroll = () => {
    if (props.scrollParent && props.scrollParent.current) {
      const el = props.scrollParent.current;
      el.scrollTop = 0;
      el.scrollLeft = 0;
    }
  }

  return (
    <Wrapper className={props.className} style={props.style}>

      {/* prev button */}
      <Item>
        <Clickable
          disabled={!hasPrev}
          onClick={e => {
            resetScroll();
            props.onPrev(e);
          }}
        >
          <IconStyled
            disabled={!hasPrev}
            name="chevron-left"
            size="16"
          />
        </Clickable>
      </Item>

      {/* current page */}
      <Item>
        <Clickable>
          <CurrentPage>
            <form onSubmit={e => { e.preventDefault(); e.stopPropagation(); submit(); }}>
              <SimpleTextInputStyled
                type="number"
                gap="3px"
                ref={inputEl}
                value={(isNil(tmpPage) ? (get(props, 'page', 0) || 0) : tmpPage).toString()}
                onChange={e => setTmpPage(e.target.value)}
                focused={inputActive}
                onFocus={e => { setFocused(true); }}
                onBlur={() => { setTimeout(cancel, 1000); }}
                onEsc={cancel}
                itemsRight={inputActive ? [
                  <ButtonStyled
                    disabled={!inputValid}
                    onClick={submit}
                  >
                    Go
                  </ButtonStyled>
                ] : null}
              />
            </form>
          </CurrentPage>
        </Clickable>
      </Item>

      {/* total */}
      {hasTotal && (
        <>
          <Item>of</Item>
          <Item>
            <Page>
              {props.loading && <LoadingBlock width={12} height={16} />}
              {!props.loading && totalPages}
            </Page>
          </Item>
        </>
      )}

      {/* next button */}
      <Item>
        <Clickable
          disabled={!hasNext}
          onClick={e => {
            resetScroll();
            props.onNext(e);
          }}
        >
          <IconStyled
            disabled={!hasNext}
            name="chevron-right"
            size="16"
          />
        </Clickable>
      </Item>
    </Wrapper>
  )
};

Pagination.propTypes = {
  total: PropTypes.number, // total number of pages
  totalItems: PropTypes.number, // total number of items
  itemsPerPage: PropTypes.number,
  page: PropTypes.number.isRequired,
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  jumpToPage: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  // the element that which scrolltop should be set to 0
  // when pages are changed
  scrollParent: PropTypes.object,
};

export default Pagination;
