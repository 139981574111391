import styled from 'styled-components';

import Modal from '../Modal';
import Tab, { Tabs } from '../Tab';

const Slideout = styled(Modal).attrs({
  position: 'right',
  closeStyle: {
    position: 'fixed',
    right: '648px',
  },
})`
  display: flex;
  flex-flow: column;
`;

export const SlideoutHeader = styled.div`
  position: relative;
  padding: ${p => `12px ${p.theme.slideoutPaddingLR}px`};
  box-shadow: ${p => p.theme.boxShadow};
  z-index: 9;
`;

export const SlideoutHeaderRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;

  &:first-child {
    margin-top: 0;
  }
`;

export const SlideoutBody = styled.div`
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  ${p => p.padding && `padding: 20px 22px;`}
`;

export const SlideoutTabs = styled(Tabs)`
  width: 100%;
  margin: 0;
  height: auto;
`;

export const SlideoutTab = styled(Tab)`
  flex-grow: 1;
  justify-content: center;
  margin: 0;
  height: 42px;
  border-top: 1px solid ${p => p.theme.mmxGreyE6};
`;

export default Slideout;
