// This file contains the advanced settings configuration for the tools
import { PAPI } from '@marketmuse/config/types';
import CountryLanguage from '../containers/Tools/components/FilterFactory/filterItems/FilterCountryLanguage';

import countries, {
  allowedCountries,
  countriesWithLanguageSupport,
} from './countries';

const country = (state, setState) => ({
  id: 'country',
  type: 'dropdown',
  label: 'Search results origin',
  onChange: id => setState({ country: id }),
  tags: { 'data-mms--app-country-trigger': 'Country selector trigger' },
  values: allowedCountries.map(c => ({
    ...countries[c.id],
    tags: { 'data-mms--app-country-option': c.id },
    active: state.country === c.id,
  })),
});

const countryLanguage = (state, setState) => {
  return {
    id: 'countryLanguage',
    type: 'custom',
    component: (
      <CountryLanguage
        countries={countriesWithLanguageSupport}
        activeCountryCode={state.country}
        activeLanguageCode={state.language}
        handleChange={({ country, language }) => {
          setState({
            ...(country && { country }),
            ...(language && { language }),
          });
        }}
      />
    ),
  };
};

const peopleBrand = (state, setState) => ({
  id: 'peopleBrand',
  type: 'toggle',
  label: 'Include',
  onChange: id => setState({ [id]: !state[id] }),
  values: [
    { id: 'people', title: 'Include People', active: state.people },
    { id: 'brand', title: 'Include Brands', active: state.brand },
  ],
});

const bodyArticle = (state, setState) => ({
  id: 'bodyOrArticle',
  type: 'switch',
  label: 'Text to Analyze',
  onChange: id => setState({ bodyOrArticle: id }),
  values: [
    {
      id: 'article',
      title: 'Article Only',
      active: state.bodyOrArticle === 'article',
    },
    { id: 'body', title: 'Full Page', active: state.bodyOrArticle === 'body' },
  ],
});

export default {
  [PAPI.PermissionResource.NEWSROOM]: (state, setState) => ({
    sections: [
      bodyArticle(state, setState),
      peopleBrand(state, setState),
      country(state, setState),
    ],
  }),
  [PAPI.PermissionResource.OPTIMIZE]: (state, setState) => ({
    sections: [
      bodyArticle(state, setState),
      peopleBrand(state, setState),
      countryLanguage(state, setState),
    ],
  }),
};
