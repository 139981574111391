import { StatBand, MentionsArgs, MentionsCalculateReturnType } from '../types';
import backgroundColor from './backgroundColor';
import calculate from './calculate';

const statBandMentions: StatBand<MentionsArgs, MentionsCalculateReturnType> = {
  calculate,
  backgroundColor,
  textColor: (...args) => {
    console.log('Not implemented', args);
    return '';
  },
};

export default statBandMentions;
