import { useCallback, useRef } from 'react';
import { toast } from '@marketmuse/components';
import { useExportTopicNavigatorLazyQuery } from '@marketmuse/data-papi';
import { ResearchViews } from '@marketmuse/data-state/types';
import { createDownload, omitWalkDeepFromArray } from '@marketmuse/utilities';
import { ExportTopicNavigatorPayload } from '../types';
import useToaster from './useToaster';

const dataKey = {
  [ResearchViews.KEYWORDS]: 'keywordsTabData',
  [ResearchViews.QUESTIONS]: 'questionsTabData',
  [ResearchViews.REFLECT]: 'reflectTabData',
  [ResearchViews.TOPIC_MODEL]: 'topicModelTabData',
};

const useExportTopicNavigator = ({
  canExport,
  term,
  url,
}: {
  canExport: boolean;
  term: string;
  url?: string;
}) => {
  const { premiumFeatureToast, exportSuccessfulToast } = useToaster();
  const filenameRef = useRef(null);

  const [exportTopicNavigator, { loading }] = useExportTopicNavigatorLazyQuery({
    fetchPolicy: 'no-cache',
    errorPolicy: 'none',
    notifyOnNetworkStatusChange: true,
    context: {
      omitTypename: true,
    },
    onError: () => {
      toast.error('Sorry, we were unable to export Topic Navigator.');
    },
    onCompleted: res => {
      if (res?.exportTable?.data) {
        exportSuccessfulToast();
        createDownload(res.exportTable.data, filenameRef.current || term);
      }

      filenameRef.current = null;
    },
  });

  const handler = useCallback(
    ({ rows, view }: ExportTopicNavigatorPayload) => {
      if (!canExport) {
        premiumFeatureToast();
        return;
      }
      filenameRef.current = [view, term].join('-');

      const cleanedData = omitWalkDeepFromArray(rows, ['existsInInventory']);

      const data = {
        [dataKey[ResearchViews.KEYWORDS]]: undefined,
        [dataKey[ResearchViews.QUESTIONS]]: undefined,
        [dataKey[ResearchViews.REFLECT]]: undefined,
        [dataKey[ResearchViews.TOPIC_MODEL]]: undefined,
      };
      data[dataKey[view as unknown as ResearchViews]] = cleanedData;

      exportTopicNavigator({
        variables: {
          generationContext: {
            term,
            url,
          },
          ...data,
        },
      });
    },
    [canExport, exportTopicNavigator, term, url, premiumFeatureToast],
  );

  return { handler, loading };
};

export default useExportTopicNavigator;
