import { RankArgs } from '../types';
import calculate from './calculate';

const backgroundColor = (...args: RankArgs) => {
  const color = calculate(...args);
  if (color === 'gray') return 'bg-gray-150';
  if (color === 'blue') return 'bg-blue-400';
  if (color === 'green') return 'bg-green-400';
  if (color === 'red') return 'bg-red-400';
  if (color === 'yellow') return 'bg-yellow-400';
  if (color === 'orange') return 'bg-orange-400';
  return 'bg-red-400';
};

export default backgroundColor;
