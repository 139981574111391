import { AppQueryNetworkStatus, ReduxListener } from '../../../types';

import { optimizeTaskStatusCheck } from '../../actions';
import { networkSlice, dataSlice } from '../../slices';
import { getBulkAdwordsKeywords } from '../../thunks';

const listeners = (startListening: ReduxListener) => {
  const dataActions = dataSlice.actions;
  const networkActions = networkSlice.actions;

  startListening({
    actionCreator: getBulkAdwordsKeywords.pending,
    effect: async (action, { dispatch }) => {
      await dispatch(
        networkActions.bulkAdwordsKeywordsSet(AppQueryNetworkStatus.loading),
      );
    },
  });

  startListening({
    actionCreator: getBulkAdwordsKeywords.rejected,
    effect: async (action, { dispatch }) => {
      const { aborted } = action.meta;
      if (!aborted) {
        await Promise.all([
          dispatch(
            networkActions.bulkAdwordsKeywordsSet(AppQueryNetworkStatus.error),
          ),
        ]);
      }

      // check status after other steps
      await dispatch(optimizeTaskStatusCheck());
    },
  });

  startListening({
    actionCreator: getBulkAdwordsKeywords.fulfilled,
    effect: async ({ payload }, { dispatch }) => {
      await Promise.all([
        dispatch(dataActions.bulkAdwordsKeywordsSet(payload.data)),
        dispatch(
          networkActions.bulkAdwordsKeywordsSet(AppQueryNetworkStatus.complete),
        ),
      ]);

      // check status after other steps
      await dispatch(optimizeTaskStatusCheck());
    },
  });
};

export default listeners;
