import { ApolloQueryResult } from '@apollo/client';
import { pick } from 'lodash';
import type { SeoSerpPageDataQuery } from '@marketmuse/config/types/papi';
import { SeoSerpPageDataDocument } from '@marketmuse/data-papi';
import {
  ResearchDataInput,
  ResearchThunkSeoRes,
  ThunkApiConfig,
} from '../../types';
import createNonConcurrentAsyncThunk from '../../utils/createNonConcurrentAsyncThunk';
import sanitizeAppParams from '../../utils/sanitizeAppParams';

const getSerpPagesData = createNonConcurrentAsyncThunk<
  ResearchThunkSeoRes,
  ResearchDataInput,
  ThunkApiConfig
>('runs/getSerpPagesData', async (params, { extra, rejectWithValue }) => {
  const variables = sanitizeAppParams<ResearchDataInput>(params);

  const response: ApolloQueryResult<SeoSerpPageDataQuery> =
    await extra.apollo.query({
      query: SeoSerpPageDataDocument,
      variables: pick(variables, ['term', 'url', 'country', 'language']),
    });

  const error = response.errors;
  if (error) {
    return rejectWithValue(error);
  }

  return {
    data: response?.data?.seoSerpPageData,
    path: 'serpPages',
    params: variables,
  };
});

export default getSerpPagesData;
