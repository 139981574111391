import * as React from 'react';
import { IResearchActionsContext } from '../types';
import { ResearchActionsContext } from '../context';

const useResearchActions = (): IResearchActionsContext => {
  const context = React.useContext(ResearchActionsContext);
  if (!context) {
    throw new Error(
      'This component must be used within the <ResearchProvider />.',
    );
  }
  return context;
};

export default useResearchActions;
