import { isEmpty } from 'lodash';
import { FilterFn } from '@tanstack/react-table';

const stringInList: FilterFn<any> = (
  row,
  columnId: string,
  filterValues: unknown[],
) => {
  const value = row.getValue<unknown>(columnId);
  if (isEmpty(filterValues)) {
    return true;
  }
  return filterValues.some(filterValue => value === filterValue);
};

export default stringInList;
