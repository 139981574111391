import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import boxPropTypes from '../../config/propTypes/box';

const Element = styled.div`
  ${p => p.theme.mixins.applyPositioning};
  ${p => p.theme.mixins.applyBoxVariant};
  ${p => p.theme.mixins.applyPadding};
  ${p => p.theme.mixins.applyMargin};
  ${p => p.theme.mixins.applyWidths};
  ${p => p.theme.mixins.applyHeights};
  ${p => p.theme.mixins.applyColors};

  ${p =>
    ['flex', 'inlineFlex'].includes(p?.boxVariant) &&
    css`
      ${p.theme.mixins.applyFlex}
    `}
  ${p =>
    p?.boxVariant === 'grid' &&
    css`
      ${p.theme.mixins.applyGrid};
    `}
`;

const Box = forwardRef(
  ({ forwardedAs, as = 'div', boxVariant = 'block', ...props }, ref) => (
    <Element
      ref={ref}
      as={forwardedAs || as}
      boxVariant={boxVariant}
      {...props}
    />
  ),
);

Box.propTypes = boxPropTypes;

export default Box;
