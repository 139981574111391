import * as React from 'react';
import { IResearchActionsContext } from '../types';

export const actions = {
  addToInventory: {
    addingOrigin: null,
    handler: () => void 0,
    loading: false,
    loadingItems: {},
  },
  exportConnect: { handler: () => void 0, loading: false },
  exportHeatmap: { handler: () => void 0, loading: false },
  exportSerpXray: { handler: () => void 0, loading: false },
  exportTopicNavigator: { handler: () => void 0, loading: false },
  loadAppQuery: () => void 0,
  matchQueryLocaleToSite: () => void 0,
  onTriggerSiteLevelHeatmap: () => void 0,
  openCalendly: () => void 0,
  openSubscriptionFlow: () => void 0,
  performAppQuery: () => void 0,
  updateConnectDomains: () => void 0,
  updateHeatmapFilter: () => void 0,
  updateHeatmapMode: () => void 0,
  updateHeatmapSort: () => void 0,
  updateSerpXrayFilter: () => void 0,
  updateTopicNavigatorFilters: () => void 0,
  updateTopicNavigatorSort: () => void 0,
};

const ResearchActionsContext =
  React.createContext<IResearchActionsContext>(actions);

export default ResearchActionsContext;
