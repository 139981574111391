import get from 'lodash/get';

import { GetProjectsDocument } from '@marketmuse/data-papi';
import Toast from '../../components/Toast';
import * as types from '../../config/types';
import makeRequest from '../../utils/makeRequest';

export default () =>
  ({ getState }) =>
  next =>
  action => {
    const state = getState();

    if (action.type === types.GET_PROJECTS) {
      const { type, callback, limit, order, page, ...args } = action;
      makeRequest({
        type: types.GET_PROJECTS,
        mutation: GetProjectsDocument,
        options: {
          isQuery: true,
        },
        variables: {
          siteId: get(state, 'filter.site.id'),
          order,
          page: page || 0,
          limit,
          ...(args || {}),
        },
        apolloOptions: {
          fetchPolicy: 'no-cache',
        },
        keys: [].concat(action.keys || []),
      }).then(res => {
        const data = get(res, 'projects') || [];

        if (res.errors || res.error) {
          Toast.fire(res.errors || res.error || 'Unknown error', 'error');
        }

        if (typeof callback === 'function') callback(data);
        return data;
      });
    }

    return next(action);
  };
