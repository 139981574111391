import React from 'react';
import isNil from 'lodash/isNil';
import { statBandContentScore } from '@marketmuse/utilities';
import {
  WrapperNew,
  TextWrapperNew,
  TextSection,
  BarText,
  Indicator,
  ProgressBarStyledNew,
} from './shared';

export const BarContentScore = React.forwardRef(
  (
    {
      at,
      over,
      style,
      csChange,
      contentScoreAverage,
      contentScoreTarget,
      contentScoreBest,
      shrunk,
    },
    ref,
  ) => {
    const contentScoreColor = statBandContentScore.calculate(
      at,
      contentScoreAverage,
      contentScoreTarget,
      contentScoreBest,
    );

    const value = (at / over) * 100;
    return (
      <WrapperNew ref={ref} style={style}>
        <TextWrapperNew>
          {/** current content score */}
          <TextSection>
            Content Score:
            <BarText color={contentScoreColor} style={{ marginLeft: 4 }}>
              {csChange !== 0 && (
                <Indicator change={csChange}>
                  {csChange > 0 && '+'}
                  {csChange}
                </Indicator>
              )}
              {isNil(at) ? '-' : at.toLocaleString()}
            </BarText>
          </TextSection>

          {/** avg */}
          <TextSection mask={'true'} shrunk={shrunk}>
            Avg:
            <BarText color="yellow" style={{ marginLeft: 4 }}>
              {isNil(contentScoreAverage)
                ? '-'
                : contentScoreAverage.toLocaleString()}
            </BarText>
          </TextSection>

          {/** target */}
          <TextSection data-mms--writing-goals="cs-target">
            Target:
            <BarText color="green" style={{ marginLeft: 4 }}>
              {isNil(contentScoreTarget)
                ? '-'
                : contentScoreTarget.toLocaleString()}
            </BarText>
          </TextSection>
        </TextWrapperNew>
        <ProgressBarStyledNew
          thin
          hideValue
          trackColor="mmxGreyF4"
          color={contentScoreColor}
          value={value}
        />
      </WrapperNew>
    );
  },
);
