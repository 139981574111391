import { ApolloQueryResult } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';

import type { GetInventoryVersionsQuery } from '@marketmuse/config/types/papi';
import { GetInventoryVersionsDocument } from '@marketmuse/data-papi';
import { ThunkApiConfig } from '../types';

const inventoryVersions = createAsyncThunk<
  { data: GetInventoryVersionsQuery['inventoryVersions']; siteId: string },
  { siteId: string },
  ThunkApiConfig
>('inventory/versions', async (params, { extra, rejectWithValue }) => {
  if (!params.siteId) {
    return rejectWithValue('No siteId provided');
  }
  const response: ApolloQueryResult<GetInventoryVersionsQuery> =
    await extra.apollo.query({
      query: GetInventoryVersionsDocument,
      fetchPolicy: 'no-cache',
      variables: {
        siteId: params.siteId,
        startTime: '2019-03-01T00:00:00',
      },
    });

  const error = response.errors;
  if (error) {
    return rejectWithValue(error);
  }

  const inventoryVersions = response?.data?.inventoryVersions;

  return {
    data: inventoryVersions,
    siteId: params.siteId,
  };
});

export default inventoryVersions;
