import { isNil } from 'lodash';
import { FilterFn } from '@tanstack/react-table';

type FilterValue = [number, number, boolean];

const numberInRangeOptional: FilterFn<any> = (
  row,
  columnId: string,
  filterValue: FilterValue,
) => {
  const [min, max, excludeNull] = filterValue;
  const rowValue = row.getValue<number>(columnId);

  if (isNil(rowValue)) {
    return !excludeNull;
  } else {
    const result = rowValue >= min && rowValue <= max;

    return result;
  }
};

numberInRangeOptional.resolveFilterValue = (val: FilterValue) => {
  const [unsafeMin, unsafeMax, unsafeExclude] = val;

  const parsedMin =
    typeof unsafeMin !== 'number' ? parseFloat(unsafeMin as string) : unsafeMin;
  const parsedMax =
    typeof unsafeMax !== 'number' ? parseFloat(unsafeMax as string) : unsafeMax;

  let min =
    unsafeMin === null || Number.isNaN(parsedMin) ? -Infinity : parsedMin;
  let max =
    unsafeMax === null || Number.isNaN(parsedMax) ? Infinity : parsedMax;

  if (min > max) {
    const temp = min;
    min = max;
    max = temp;
  }

  return [min, max, unsafeExclude] as const;
};

numberInRangeOptional.autoRemove = () => {
  return false;
};

export default numberInRangeOptional;
