import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '@marketmuse/config/types/papi';

import { initialState } from './user.initialState';

type UserUpdate = {
  key: keyof User;
  value: User[keyof User];
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateField: (state, action: PayloadAction<UserUpdate>) => {
      const key = action.payload.key;
      const value = action.payload.value;

      // eslint-disable-next-line
      // @ts-ignore
      state[key] = value;
    },
  },
});

export const userReducer = userSlice.reducer;
export const userActions = userSlice.actions;
