import React from 'react';
import styled from 'styled-components';

import LoadingBlock from '../LoadingBlock';
import { getStyleProp, itemBPStyles, parseGridColumn } from './utils';
import propTypes from './propTypes';

const Item = styled.div`
  width: 100%;
  padding: ${p => getStyleProp(p, 'padding')};
  grid-column: ${p =>
    parseGridColumn(getStyleProp(p, 'span'), getStyleProp(p, 'gridColumn'))};
  grid-row: ${p => getStyleProp(p, 'gridRow')};
  justify-self: ${p => getStyleProp(p, 'justifySelf')};
  align-self: ${p => getStyleProp(p, 'alignSelf')};
  order: ${p => getStyleProp(p, 'order')};
  ${itemBPStyles}
`;

const ItemComponent = props => (
  <Item {...props} >
    {props.isLoading && (<LoadingBlock />)}
    {!props.isLoading && props.children}
    </Item>
);

ItemComponent.propTypes = {
  /** Control horizontal size and placement of Item */
  gridColumn: propTypes.gridColumn,

  /** Control vertical size and placement of Item */
  gridRow: propTypes.gridRow,

  /** Amount of columns item should span -- overrides gridColumn */
  span: propTypes.span,

  /** Internal padding, expects 1 to 4 values */
  padding: propTypes.padding,

  /** Aligns an item within its containing block along the Column Axis */
  alignSelf: propTypes.alignSelf,

  /** Aligns an item within its containing block along the Row Axis */
  justifySelf: propTypes.justifySelf,

  /** Reposition an element */
  order: propTypes.order,

  /**Essentially a fall back if no other props are passed */
  inherent: propTypes.itemInherent.isRequired,
};

ItemComponent.defaultProps = {
  inherent: {
    gridColumn: 'auto',
    padding: 0,
  },
};

// Export styled-component, and react component to preserve PropType peeking
export { Item };
export default ItemComponent;
