export enum SUPPORTED_ICON {
  add_row = 'add-row',
  adwords_bottom = 'adwords-bottom',
  adwords_top = 'adwords-top',
  aim = 'aim',
  archive = 'archive',
  arrow_down = 'arrow-down',
  arrow_left = 'arrow-left',
  arrow_right = 'arrow-right',
  arrow_up = 'arrow-up',
  arrow_up_bold = 'arrow-up-bold',
  authority = 'authority',
  avatar = 'avatar',
  bag = 'bag',
  badge = 'badge',
  briefs = 'briefs',
  calculator = 'calculator',
  calendar = 'calendar',
  caret_down = 'caret-down',
  caret_up = 'caret-up',
  chat = 'chat',
  checkmark = 'checkmark',
  check_circle = 'check-circle',
  check_square = 'check-square',
  chevron_left = 'chevron-left',
  chevron_right = 'chevron-right',
  circle_question = 'circle-question',
  circle_progress_25 = 'circle-progress-25',
  circle_progress_50 = 'circle-progress-50',
  circle_progress_75 = 'circle-progress-75',
  circle_progress_done = 'circle-progress-done',
  circle_progress_queue = 'circle-progress-queue',
  clear = 'clear',
  close = 'close',
  cluster = 'cluster',
  collapse = 'collapse',
  compete = 'compete',
  compass = 'compass',
  connect = 'connect',
  connectivity = 'connectivity',
  copy = 'copy',
  copy_to_clipboard = 'copy-to-clipboard',
  create_reports = 'create-reports',
  credits = 'credits',
  cursor = 'cursor',
  delete = 'delete',
  deltas = 'deltas',
  documents = 'documents',
  double_chevron = 'double-chevron',
  drag = 'drag',
  edit = 'edit',
  ellipsis = 'ellipsis',
  enter = 'enter',
  expansion = 'expansion',
  experiments = 'experiments',
  export = 'export',
  face_laugh = 'face-laugh',
  favorite_color = 'favorite-color',
  favorite_fill = 'favorite-fill',
  favorite_stroke = 'favorite-stroke',
  featured_snippet = 'featured-snippet',
  filter = 'filter',
  flag = 'flag',
  grid = 'grid',
  globe = 'globe',
  help = 'help',
  hidden = 'hidden',
  image = 'image',
  images = 'images',
  images_pack = 'images-pack',
  info = 'info',
  info_letter = 'info-letter',
  insights = 'insights',
  inventory = 'inventory',
  jobs = 'jobs',
  knowledge_card = 'knowledge-card',
  knowledge_panel = 'knowledge-panel',
  lightbulb = 'lightbulb',
  link = 'link',
  list = 'list',
  list_ascending = 'list-ascending',
  list_descending = 'list-descending',
  locked = 'locked',
  location = 'location',
  maximize = 'maximize',
  minimize = 'minimize',
  minus = 'minus',
  mm_sparkles = 'mm-sparkles',
  muses = 'muses',
  news = 'news',
  newsroom = 'newsroom',
  new_window = 'new-window',
  open_close = 'open-close',
  open_ai_logo = 'open-ai-logo',
  optimize = 'optimize',
  optimize_reports = 'optimize-reports',
  organic = 'organic',
  organization = 'organization',
  overflow_trigger = 'overflow-trigger',
  overview = 'overview',
  pages = 'pages',
  paper_airplane = 'paper-airplane',
  payment_card = 'payment-card',
  pin = 'pin',
  plans = 'plans',
  plus = 'plus',
  position_down = 'position-down',
  position_neutral = 'position-neutral',
  popular_products = 'popular-products',
  position_up = 'position-up',
  qbr = 'qbr',
  questions = 'questions',
  rank = 'rank',
  regenerate = 'regenerate',
  reports_manager = 'reports-manager',
  reports_manager_metrics = 'reports-manager-metrics',
  recipes = 'recipes',
  related_questions = 'related-questions',
  related_searches = 'related-searches',
  research = 'research',
  research_connect = 'research-connect',
  research_heatmap = 'research-heatmap',
  research_serp_xray = 'research-serp-xray',
  research_topic_navigator = 'research-topic-navigator',
  rise = 'rise',
  run = 'run',
  save = 'save',
  scales = 'scales',
  scissors = 'scissors',
  search = 'search',
  settings = 'settings',
  shopping_results = 'shopping-results',
  sign_out = 'sign-out',
  site_links = 'site-links',
  sleep = 'sleep',
  snapshots = 'snapshots',
  spelling = 'spelling',
  spinner = 'spinner',
  target = 'target',
  thin_enter = 'thin-enter',
  thumbs_up = 'thumbs-up',
  topic_reports = 'topic-reports',
  topics = 'topics',
  tutorials = 'tutorials',
  twitter = 'twitter',
  unlocked = 'unlocked',
  user = 'user',
  utility_danger = 'utility-danger',
  utility_warning = 'utility-warning',
  video = 'video',
  visible = 'visible',
  view_switcher = 'view-switcher',
  warning = 'warning',
  warning_triangle = 'warning-triangle',
  writing = 'writing',
  x = 'x',
}
