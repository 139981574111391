import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Clickable from '../../components/Clickable';

const arrowHeight = 42;

const Wrapper = styled(Clickable)`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 10px;
  background-color: ${p => p.theme.mmxGrey};
  height: ${arrowHeight}px;
  outline: none !important;

  &:focus {
    color: ${p => p.theme.colors.grey85} !important;
  }

  &:after,
  &:before {
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-top: ${arrowHeight / 2}px solid transparent;
    border-bottom: ${arrowHeight / 2}px solid transparent;
    position: absolute;
    top: 0;
  }

  &:after {
    border-left: 14px solid ${p => p.theme.mmxGrey};;
    left: 100%;
  }
  &:before {
    border-left: 14px solid white;
    left: 0;
  }

  &:hover {
    cursor: pointer;
  }
`;

const Title = styled.div`
  margin-left: 10px;
  font-size: 11pt;
`;

const Icon = styled.div`
  width: 22px;
  svg { width: 22px; }
`;

const ArrowButton = props => (
  <Wrapper color={props.color}>
    <Icon>{props.icon}</Icon>
    <Title>{props.title}</Title>
  </Wrapper>
);

ArrowButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  icon: PropTypes.any,
  color: PropTypes.string
};

export default ArrowButton;
