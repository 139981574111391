import * as React from 'react';
import { Outlet, Navigate, useSearchParams } from 'react-router-dom';

import urlParams from '../../utils/urlParams';

import { useParamsToStorage } from '../../hooks/useParamStorage';
import useInitialPath from '../../hooks/useInitialPath';

import LayoutMain from '../../containers/Layout/LayoutMain';
import ErrorBoundary from '../../components/ErrorBoundary';
import ScreenLoader from '../../components/ScreenLoader';
import { PARAMS_LIST } from '../../config/paramsList';

export const Root = () => {
  const [searchParams] = useSearchParams();
  useInitialPath();
  useParamsToStorage(PARAMS_LIST);

  if (searchParams.has('gt')) {
    const guestToken = urlParams.getRaw('gt');
    return <Navigate to={`/guest/auth/${guestToken}`} />;
  }

  return (
    <ErrorBoundary>
      <LayoutMain>
        <React.Suspense fallback={<ScreenLoader.Light />}>
          <Outlet />
        </React.Suspense>
      </LayoutMain>
    </ErrorBoundary>
  );
};
