import React, { memo, useEffect, useRef, useState } from 'react';
import { get } from 'lodash';
import styled from 'styled-components';
import useChangeIndicator from '../../hooks/useChangeIndicator';

import { BarContentScore, BarWordCount, BarLastSaved } from '.';

const Wrapper = styled.div`
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 5px;
  height: ${p => p.theme.scoresBarHeight}px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  flex: 0 0 auto;
`;

const ScoresBar = ({ appData, contentScore, wordCount, writingLastSaved }) => {
  const wrapperRef = useRef(null);
  const [shrunk, setShrunk] = useState(true);

  const contentScoreAverage = Math.round(
    get(appData, 'scores.metrics.cs_avg', 0),
  );
  const contentScoreTarget = Math.round(
    get(appData, 'scores.metrics.cs_target', 0),
  );
  const contentScoreBest = Math.round(get(appData, 'scores.metrics.cs_max', 0));
  const wordCountTarget = Math.round(
    get(appData, 'scores.metrics.wc_target', 0),
  );
  const wordCountAverage = Math.round(get(appData, 'scores.metrics.wc_avg', 0));

  const csChange = useChangeIndicator(contentScore, { duration: 2000 });
  const wcChange = useChangeIndicator(wordCount, { duration: 2000 });

  useEffect(() => {
    if (!wrapperRef.current) return;

    const observer = new ResizeObserver(() => {
      window &&
        window.requestAnimationFrame(() => {
          if (wrapperRef.current) {
            const wrapperWidth = wrapperRef.current.clientWidth;
            if (wrapperWidth < 700) setShrunk(true);
            if (wrapperWidth > 750) setShrunk(false);
          }
        });
    });

    observer.observe(wrapperRef.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Wrapper ref={wrapperRef}>
      <BarContentScore
        shrunk={shrunk}
        style={{ marginRight: 8, flexShrink: 0 }}
        at={contentScore}
        over={contentScoreTarget}
        csChange={csChange}
        contentScoreAverage={contentScoreAverage}
        contentScoreTarget={contentScoreTarget}
        contentScoreBest={contentScoreBest}
      />

      <BarWordCount
        shrunk={shrunk}
        style={{ marginRight: 8, flexShrink: 0 }}
        at={wordCount}
        over={wordCountTarget}
        wcChange={wcChange}
        wordCountAverage={wordCountAverage}
        wordCountTarget={wordCountTarget}
      />

      {writingLastSaved && <BarLastSaved lastSaved={writingLastSaved} />}
    </Wrapper>
  );
};

export default memo(ScoresBar);
