import { FC, memo, useCallback, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { Text, Box, Button, ButtonBar } from '@marketmuse/components';
import DomainAdder from './components/DomainAdder';
import DomainsList from './components/DomainsList';
import { TABS_CONTENT } from './constants';
import { DomainList, SettingsProps, TabsType } from './types';

export const Settings: FC<SettingsProps> = ({
  competitors,
  network,
  onCancel,
  onSubmit,
}) => {
  const [activeTab, setActiveTab] = useState<TabsType>('competitors');
  const [domainsList, setDomainsList] = useState<DomainList>({
    competitors: competitors || [],
    network: network || [],
  });

  const handleAddDomain = useCallback(
    (domain: string) => {
      setDomainsList(prev => {
        if (prev[activeTab].includes(domain)) {
          return prev;
        }

        return { ...prev, [activeTab]: [domain, ...prev[activeTab]] };
      });
    },
    [activeTab],
  );

  const handleRemoveDomain = useCallback(
    (domain: string) => {
      setDomainsList(prev => ({
        ...prev,
        [activeTab]: prev[activeTab].filter(item => item !== domain),
      }));
    },
    [activeTab],
  );

  const handleConfirm = useCallback(() => {
    onSubmit({
      competitors: domainsList.competitors,
      network: domainsList.network,
    });
  }, [domainsList, onSubmit]);

  const BUTTONS_LIST = [
    {
      id: 'competitors',
      title: 'Competitors',
      onClick: () => setActiveTab('competitors'),
    },
    {
      id: 'network',
      title: 'Network',
      onClick: () => setActiveTab('network'),
    },
  ];

  return (
    <>
      <ButtonBar
        items={BUTTONS_LIST}
        defaultProps={{ size: 'md' }}
        activeId={activeTab}
        className="mx-auto mb-4 bg-gray-50"
      />

      <Text as="h2" className="text-lg font-bold text-center mb-2">
        {TABS_CONTENT[activeTab].title}
      </Text>
      <Text as="p" className="text-sm text-center mb-5 text-gray-700">
        {TABS_CONTENT[activeTab].description}
      </Text>

      <DomainAdder
        placeholder={TABS_CONTENT[activeTab].placeholder}
        onAdd={handleAddDomain}
      />
      <Text
        as="p"
        className={twMerge([
          'text-xs',
          'font-bold',
          'py-2',
          'mb-4',
          'uppercase',
          'text-gray-500',
          'border-b-2',
          'border-gray-150',
        ])}
      >
        {domainsList[activeTab].length} {TABS_CONTENT[activeTab].listHeader}
      </Text>

      <DomainsList
        domains={domainsList[activeTab]}
        onRemove={handleRemoveDomain}
        tab={activeTab}
      />

      <Box className="flex justify-end gap-4">
        <Button variant="gray150" className="text-sm" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="blue400" className="text-sm" onClick={handleConfirm}>
          Save Changes
        </Button>
      </Box>
    </>
  );
};

export default memo(Settings);
