import { FetchResult } from '@apollo/client';
import { isEmpty } from 'lodash';

import type {
  SavedViewUpdateMutation,
  SavedViewUpdateMutationVariables,
} from '@marketmuse/config/types/papi';
import { SavedViewUpdateDocument } from '@marketmuse/data-papi';

import { ThunkApiConfig, SavedViewForState } from '../../types';
import { loadingsActions } from '../../loadings';
import createNonConcurrentAsyncThunk from '../../utils/createNonConcurrentAsyncThunk';

import { SAVED_VIEW_UPDATE } from '../savedViews.actionTypes';
import { savedViewsUpdate } from '../actions';

const savedViewUpdate = createNonConcurrentAsyncThunk<
  SavedViewForState,
  SavedViewUpdateMutationVariables,
  ThunkApiConfig
>(
  SAVED_VIEW_UPDATE,
  async (variables, { extra, rejectWithValue, dispatch }) => {
    const { savedViewId, savedViewInput } = variables;

    if (
      Object.keys(savedViewInput).includes('sorting') &&
      isEmpty(savedViewInput.sorting)
    ) {
      return rejectWithValue('Sorting cannot be empty');
    }

    const updates = {
      filters: savedViewInput.filters,
      hidden: savedViewInput.hidden,
      order: savedViewInput.order,
      pinned: savedViewInput.pinned,
      sorting: savedViewInput.sorting,
      title: savedViewInput.title,
      inventoryType: savedViewInput.inventoryType,
      description: savedViewInput.description,
      sharing: savedViewInput.sharing,
    };

    const thunkId = `${Date.now()}-${Math.round(Math.random() * 10000)}`;
    const keys = [`${SAVED_VIEW_UPDATE}_${savedViewId}`];
    if (!isEmpty(updates.title)) {
      keys.push(`${keys[0]}_title`);
    }

    dispatch(
      loadingsActions.start({
        id: thunkId,
        type: SAVED_VIEW_UPDATE,
        keys,
      }),
    );

    const response: FetchResult<SavedViewUpdateMutation> =
      await extra.apollo.mutate({
        mutation: SavedViewUpdateDocument,
        variables: {
          savedViewId,
          savedViewInput: updates,
        },
      });

    dispatch(
      loadingsActions.stop({
        id: thunkId,
      }),
    );

    const error = response.errors;
    if (error) {
      return rejectWithValue(error);
    }

    const savedViewUpdate = response?.data
      ?.savedViewUpdate as SavedViewForState;
    dispatch(
      savedViewsUpdate({
        inventoryType: savedViewUpdate.inventoryType,
        items: [savedViewUpdate],
      }),
    );

    return savedViewUpdate;
  },
);

export default savedViewUpdate;
