import React, { memo, Suspense, ReactNode } from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  AppQueryUiData,
  AppUiStatus,
} from '@marketmuse/config/types/applications';
import {
  QueryHeader,
  ZeroState,
  ZeroStateProp,
  navigationConfig,
} from '@marketmuse/research-ui';

import { lazyLoad } from '@marketmuse/utilities';

import ErrorBoundary from '../../../components/ErrorBoundary';

import ConnectSettingsLoader from './components/ConnectSettingsLoader';

const TopicNavigator = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'TopicNavigator' */ '@marketmuse/research-topic-navigator/topic-navigator'
    ),
);

const SerpXray = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'SerpXray' */ '@marketmuse/research-xray/SerpXray/SerpXray'
    ),
);

const SerpTypes = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'SerpXray' */ '@marketmuse/research-xray/SerpTypes/SerpTypes'
    ),
);

const Heatmap = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'Heatmap' */ '@marketmuse/research-heatmap/Heatmap'
    ),
);

const Recommendations = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'Recommendations' */ '@marketmuse/research-connect/Recommendations'
    ),
);

const RouteLoader = ({
  appStatus,
  type,
  Container,
}: {
  appStatus: AppUiStatus;
  type: ZeroStateProp['type'];
  Container: ReactNode;
}) => {
  return (
    <>
      {appStatus === AppUiStatus.zero && <ZeroState type={type} />}
      {appStatus !== AppUiStatus.zero && (
        <Suspense fallback={<div></div>}>
          <ErrorBoundary>
            <Container />
          </ErrorBoundary>
        </Suspense>
      )}
    </>
  );
};

function ResearchRoutes({
  appQuery,
  appStatus,
  loadingConnect,
  loadingHeatmap,
  loadingTopicNavigator,
  loadingXray,
}: {
  appQuery: AppQueryUiData;
  appStatus: AppUiStatus;
  loadingConnect: boolean;
  loadingHeatmap: boolean;
  loadingTopicNavigator: boolean;
  loadingXray: boolean;
}) {
  return (
    <Routes>
      <Route
        path={navigationConfig.topicNavigator.path}
        element={
          <>
            <QueryHeader
              topic={appQuery.term}
              loading={loadingTopicNavigator}
            />
            <RouteLoader
              appStatus={appStatus}
              type={'topicNavigator'}
              Container={TopicNavigator}
            />
          </>
        }
      />

      <Route
        path={navigationConfig.connect.path}
        element={
          <>
            <QueryHeader topic={appQuery.term} loading={loadingConnect}>
              <ConnectSettingsLoader />
            </QueryHeader>
            <RouteLoader
              appStatus={appStatus}
              type={'connect'}
              Container={Recommendations}
            />
          </>
        }
      />

      <Route
        path={navigationConfig.heatmap.path}
        element={
          <>
            <QueryHeader topic={appQuery.term} loading={loadingHeatmap} />
            <RouteLoader
              appStatus={appStatus}
              type={'heatmap'}
              Container={Heatmap}
            />
          </>
        }
      />

      <Route
        path={navigationConfig.serpXray.path}
        element={
          <>
            <QueryHeader topic={appQuery.term} loading={loadingXray}>
              <Suspense fallback={<div />}>
                <SerpTypes className={'pr-3'} />
              </Suspense>
            </QueryHeader>
            <RouteLoader
              appStatus={appStatus}
              type={'serpXray'}
              Container={SerpXray}
            />
          </>
        }
      />
    </Routes>
  );
}

export default memo(ResearchRoutes);
