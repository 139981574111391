// Former 'default' theme, 'dynamic' theme is now the default
// as it appears to be the direction headed in `system--master`

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import BaseWrapper from './base/BaseWrapper';
import BaseInput from './base/BaseInput';
import BaseTextarea from './base/BaseTextarea';

export const LegacyInput = styled(BaseInput)``;
export const LegacyTextarea = styled(BaseTextarea)``;

const Wrapper = styled(BaseWrapper)`
  color: ${p => p.theme.input.default.color};
  background-color: ${p => p.theme.input.default.backgroundColor};
  ${p => p.disabled && `opacity: 0.5;`}

  ${p => p.error && `
    ${LegacyInput}, ${LegacyTextarea} {
      border: 2px solid ${p.theme.mmxRed};
      border-radius: inherit;
    }
  `}
`;

const LegacyWrapper = ({ children, ...props } = {}) => (
  <Wrapper {...props}>
    {children}
  </Wrapper>
);

LegacyWrapper.propTypes = {
  children: PropTypes.any,
};

export default LegacyWrapper;
