export const EXPORT_FEATURE = {
  word: 'word',
  excel: 'excel',
  googleDoc: 'googleDoc',
} as const;

export const EXPORT_EVENT = {
  onGoogleDocExport: 'onGoogleDocExport',
  onExcelExport: 'onExcelExport',
  onWordExport: 'onWordExport',
  updateExportToolbarView: 'updateExportToolbarView',
  onExcelDataChange: 'onExcelDataChange',
} as const;

export const SETTINGS = {
  CONFIG_NAME: 'export',
};
