import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const initialDuration = 500;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const View = styled.div`
  position: absolute;
  overflow: auto;
  width: 100%;
  height: 100%;
  left: 0;
  transform: translateX(0);
  transition: transform 0ms linear;

  ${p => p.animate && `
    transition-duration: ${p.duration || initialDuration}ms;
    transition-timing-function cubic-bezier(0.770, 0.000, 0.175, 1.000);
  `}

  ${p => p.active && `transform: translateX(0);`}
  ${p => p.prev && `transform: translateX(-100%);`}
  ${p => p.next && `transform: translateX(100%);`}

  ${p => (!p.active && `position: absolute !important;`)}
  ${p => (!p.active && !p.next && !p.prev) && `
    visibility: hidden;
    width: 0;
    height: 0;
    opacity: 0;
  `}
`;

const SlideTransition = ({
  views = [],
  useAnimate,
  viewStyle,
  duration = initialDuration,
}) => {
  const [inTransition, setInTransition] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [pos, setPos] = useState({
    next: null,
    prev: null,
    active: null,
  })

  // set initial view
  useEffect(() => {
    const initialView = views.find(v => v.initial);
    setPos({ active: initialView.id })
  }, []);

  // transition function
  const move = dir => view => {

    // prepare view positions for moving
    setInTransition(true);
    setAnimate(false);
    setPos({
      next: dir === 'forward' ? view : null,
      prev: dir === 'forward' ? null : view,
      active: pos.active,
    })

    // initiate the animation
    setTimeout(() => {
      setAnimate(true);
      setPos({
        next: dir === 'forward' ? null : pos.active,
        prev: dir === 'forward' ? pos.active : null,
        active: view,
      })

      // reset to idle state after animation
      setTimeout(() => {
        setAnimate(false);
        setInTransition(false);
        setPos({
          next: null,
          prev: null,
          active: view,
        })
      }, duration);
    }, 0);
  }

  return (
    <Wrapper animate={useAnimate && animate}>
      {views.map(v => {
        const isActive = v.id === pos.active;
        const isNext = v.id === pos.next;
        const isPrev = v.id === pos.prev;

        // if view isn't slated for transition
        // and should be unmounted while off the
        // screen, return null
        if (
          !isActive &&
          !isPrev &&
          !isNext &&
          v.shouldUnmount
        ) {
          return null;
        }

        return (
          <View
            key={v.id}
            ref={v.ref}
            active={isActive}
            next={isNext}
            prev={isPrev}
            animate={useAnimate && animate}
            duration={duration}
            style={viewStyle}
          >
            {v.render({
              // transition forward to a page with id
              moveForwardTo: move('forward'),
              // transition backward to a page with id
              moveBackTo: move('back'),
              // transition duration - useful for triggering callbacks
              duration,
              // a transition is currently happening
              inTransition,
              isActive,
            })}
          </View>
        )
      }).filter(Boolean)}
    </Wrapper>
  )
};

SlideTransition.propTypes = {
  views: PropTypes.arrayOf(
    PropTypes.shape({
      // unique id of the view
      id: PropTypes.string.isRequired,
      // render function - should return the view
      render: PropTypes.func.isRequired,
      // should this component be the main screen
      // that should be set on mount
      initial: PropTypes.bool,
      // should this view be unmounted while off screen ?
      shouldUnmount: PropTypes.bool,
      // ref to the container (ie. which is also the scroll container)
      ref: PropTypes.object,
    })
  ),
};

export default SlideTransition;
