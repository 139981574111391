import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const lineWidth = 12;

const SubItem = styled.div`
  position: relative;
  color: ${p => p.theme.mmxDarkGrey};
  font-size: 9pt;
  display: inline;
  padding-left: ${lineWidth + 6}px;
  min-height: 16px;

  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 50%;
    max-height: 8px;
    bottom: 50%;
    left: 0;
    background-color: ${p => p.theme.mmxLightGrey};
  }
  &:after {
    content: '';
    position: absolute;
    height: 1px;
    width: ${lineWidth}px;
    left: 0;
    top: 50%;
    transform: translateY(-0.5px);
    background-color: ${p => p.theme.mmxLightGrey};
  }
`;

const SubItemComponent = props => (
  <SubItem className={props.className} style={props.style}>
    {props.children}
  </SubItem>
);

SubItemComponent.propTypes = {
  children: PropTypes.any,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default SubItemComponent;
