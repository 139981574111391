import { Command, type Editor } from '@ckeditor/ckeditor5-core';
import { Batch, RootElement, Item } from '@ckeditor/ckeditor5-engine';
import * as Sentry from '@sentry/browser';

import { editor as editorConfig } from '@marketmuse/config/configs';
import { MISC } from '@marketmuse/config/configs/editor/plugins/highlightTerms';

import type HighlightTermState from '../HighlightTermState';

import { getMentionsCounts } from '../utils/getMentionsCounts';
import { modelElementToPlainText } from '../../_utils/modelElementToPlainText';
import { removeUnusedAttribute } from '../utils/removeUnusedAttribute';
import { shouldProcessRangeItem } from '../utils/shouldProcessRangeItem';

const {
  HIGHLIGHT_TERM: { EVENTS },
} = editorConfig;

export default class HighlightTermsCommand extends Command {
  private _state: HighlightTermState;

  constructor(editor: Editor, state: HighlightTermState) {
    super(editor);

    // The highlightTerms command is always enabled.
    this.isEnabled = true;

    // It does not affect data so should be enabled in read-only mode.
    this.affectsData = false;
    this._state = state;
  }

  // onChange enable highlight command
  public override refresh(): void {
    this.isEnabled = true;
  }

  private _addHighlights(item: Item, batch: Batch) {
    const text = modelElementToPlainText(item);
    const foundItemsDict = this._state.ahoCorasick?.search(text) || {};
    const keysByPriority = this._state.keysByPriority;

    keysByPriority.forEach(key => {
      const { color, priority } = this._state.highlightAttributeConfig[key];
      const attributeKey = `${color}:${priority}:${key}`;

      this.editor.model.enqueueChange(batch, writer => {
        const foundItems = foundItemsDict[key] || [];
        foundItems.forEach(foundItem => {
          try {
            const range = writer.createRange(
              writer.createPositionAt(item, foundItem.start),
              writer.createPositionAt(item, foundItem.end),
            );

            writer.setAttribute(MISC.ATTRIBUTE_KEY, attributeKey, range);
          } catch (error) {
            // eslint-disable-next-line
            // @ts-ignore
            const message = `Unable to add highlight | ${error.message}`;
            Sentry.captureMessage(message);
          }
        });
      });
    });
  }

  private _removeHighlightsUnused(item: Item, batch: Batch) {
    try {
      // eslint-disable-next-line
      // @ts-ignore
      const childItems: Item[] = Array.from(item.getChildren());
      childItems.forEach(item => {
        this.editor.model.enqueueChange(batch, writer => {
          removeUnusedAttribute(writer, item, this._state.termSearchDict);
        });
      });
    } catch (error) {
      // eslint-disable-next-line
      // @ts-ignore
      const message = `Unable to remove highlight | ${error.message}`;
      Sentry.captureMessage(message);
    }
  }

  private _processHighlights(): void {
    const batch = this.editor.model.createBatch({
      isUndoable: false,
      isTyping: true,
    });

    this.editor.model.enqueueChange(batch, () => {
      const range = this.editor.model.createRangeIn(
        this.editor.model.document.getRoot() as RootElement,
      );

      const ranges = [...range];
      for (let index = 0; index < ranges.length; index++) {
        const { type, item } = ranges[index];
        const shouldExit = !shouldProcessRangeItem({
          type,
          item,
          model: this.editor.model,
          range,
        });

        if (shouldExit) {
          continue;
        }

        this._removeHighlightsUnused(item, batch);

        this._addHighlights(item, batch);
      }
    });
  }

  public override async execute(): Promise<void> {
    const { editor } = this;
    const { model } = editor;

    const mentionCounts = getMentionsCounts({
      ahoCorasick: this._state.ahoCorasick,
      keysByPriority: this._state.keysByPriority,
      model,
    });

    editor.fire(EVENTS.onHighlight, {
      matchesCount: mentionCounts,
    });

    try {
      this._processHighlights();
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  }
}
