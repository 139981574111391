import { ApolloLink } from '@apollo/client';
import { REACT_APP } from '../../config/env';

export const gqlLogger = () => {
  const isCypress = process.env.NODE_ENV === 'development' && window?.Cypress;

  if (isCypress || REACT_APP.GQL_LOG === 'true') {
    return require('@zendesk/laika').createLazyLoadableLaikaLink({
      clientName: 'ui-suite',
      startLoggingImmediately: true,
    });
  } else {
    return new ApolloLink((operation, forward) => forward(operation));
  }
};
