import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import get from 'lodash/get';

import Tooltip from '../../../components/Tooltip/Tooltip';
import Button from '../../../components/Button/Button';
import SimpleGrid from '../../../components/SimpleGrid';

const Wrapper = styled.div``;

const ToolkitTabEnrichments = ({ appData, loading, functions }) => {
  const term = appData?.params?.topic;
  const qts = appData?.qts;
  return (
    <Wrapper data-mms--brief-subheadings-sidebar-content="enrichment">
      <SimpleGrid.Container gap={'20px'} padding={'20px'}>
        <SimpleGrid.Container gap={'20px'} columns={5}>
          <SimpleGrid.Item span={5}>
            <Button
              loading={loading.updateReport}
              large
              onClick={() =>
                functions.handlePopulateBrief({ invalidation: true })
              }
            >
              Update Enrichments
            </Button>
          </SimpleGrid.Item>
          <SimpleGrid.Item>
            <Tooltip title="ranking_subheadings">
              RS:{' '}
              {get(appData, 'item.brief.ranking_subheadings')?.length
                ? 'Yes'
                : 'No'}
            </Tooltip>
          </SimpleGrid.Item>
          <SimpleGrid.Item>
            <Tooltip title="ranking_titles">
              RT:{' '}
              {get(appData, 'item.brief.rankingTitles')?.length ? 'Yes' : 'No'}
            </Tooltip>
          </SimpleGrid.Item>
          <SimpleGrid.Item>
            <Tooltip title="subheading_terms">
              ST:{' '}
              {get(appData, 'item.brief.subheadingTerms')?.length
                ? 'Yes'
                : 'No'}
            </Tooltip>
          </SimpleGrid.Item>
          <SimpleGrid.Item>
            <Tooltip title="title_terms">
              TT: {get(appData, 'item.brief.titleTerms')?.length ? 'Yes' : 'No'}
            </Tooltip>
          </SimpleGrid.Item>
          <SimpleGrid.Item>
            <Tooltip title="subtopics">
              Sub: {get(appData, 'item.brief.subtopics')?.length ? 'Yes' : 'No'}
            </Tooltip>
          </SimpleGrid.Item>
        </SimpleGrid.Container>

        <SimpleGrid.Item>
          <div>Ranking Subheadings</div>
          <div style={{ display: 'flex', marginTop: 8 }}>
            <Button
              loading={loading.qts}
              title="topRankingSubHeadings"
              large
              secondary
              onClick={() => {
                functions.refetchQtsRequest({ term });
              }}
            >
              Refetch
            </Button>
            <Button
              onClick={() => {
                functions.handleUpdateBrief({
                  ranking_subheadings: qts?.topRankingSubHeadings.map(
                    (v, i) => ({
                      value: v.term,
                      semantic_similarity: v.similarity,
                      visible: i < 10,
                    }),
                  ),
                });
              }}
              style={{ marginLeft: 16 }}
              large
            >
              Update
            </Button>
          </div>
        </SimpleGrid.Item>

        <SimpleGrid.Item>
          <div>Ranking titles</div>
          <div style={{ display: 'flex', marginTop: 8 }}>
            <Button
              loading={loading.qts}
              large
              secondary
              title="topRankingTitles"
              onClick={() => {
                functions.refetchQtsRequest({ term });
              }}
            >
              Refetch
            </Button>
            <Button
              loading={loading.updateReport}
              large
              onClick={() => {
                functions.handleUpdateBrief({
                  rankingTitles: qts?.topRankingTitles.map(value => ({
                    value,
                  })),
                });
              }}
              style={{ marginLeft: 16 }}
            >
              Update
            </Button>
          </div>
        </SimpleGrid.Item>
        <SimpleGrid.Item>
          <div>Subheading terms</div>
          <div style={{ display: 'flex', marginTop: 8 }}>
            <Button
              loading={loading.qts}
              large
              secondary
              title="suggestedSubHeadingTerms"
              onClick={() => {
                functions.refetchQtsRequest({ term });
              }}
            >
              Refetch
            </Button>
            <Button
              loading={loading.updateReport}
              large
              onClick={() => {
                functions.handleUpdateBrief({
                  subheadingTerms: qts?.suggestedSubHeadingTerms.map(
                    (v, i) => ({
                      value: v.term,
                      semantic_similarity: v.similarity,
                      visible: i < 10,
                    }),
                  ),
                });
              }}
              style={{ marginLeft: 16 }}
            >
              Update
            </Button>
          </div>
        </SimpleGrid.Item>
        <SimpleGrid.Item>
          <div>Title terms</div>
          <div style={{ display: 'flex', marginTop: 8 }}>
            <Button
              loading={loading.qts}
              large
              secondary
              title="suggestedTitleTerms"
              onClick={() => {
                functions.refetchQtsRequest({ term });
              }}
            >
              Refetch
            </Button>
            <Button
              loading={loading.updateReport}
              large
              onClick={() => {
                functions.handleUpdateBrief({
                  titleTerms: qts?.suggestedTitleTerms.map((v, i) => ({
                    value: v.term,
                    semantic_similarity: v.similarity,
                    visible: i < 10,
                  })),
                });
              }}
              style={{ marginLeft: 16 }}
            >
              Update
            </Button>
          </div>
        </SimpleGrid.Item>
      </SimpleGrid.Container>
    </Wrapper>
  );
};

ToolkitTabEnrichments.propTypes = {
  appData: PropTypes.object,
  moveForwardTo: PropTypes.func,
  moveBackTo: PropTypes.func,
};

export default ToolkitTabEnrichments;
