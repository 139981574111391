/**
 * Things to record:
 * - application
 * - topic
 * - url
 * - brands
 * - people
 * - country
 * - bodyOrArticle
 */

import Event from '../../Event';

class AppExported extends Event {

  record(application, params = {}) {
    super.record('AppExported', {
      category: 'App',
      mainValue: `${application} - ${params.topic}`,
      newName: 'apps--exported',
      application,
      ...params,
    })
  }

}

export default new AppExported();
