import { debounce } from 'lodash';
import { Plugin } from 'ckeditor5/src/core';
import * as pluginsConfig from '@marketmuse/config/configs/editor/plugins';

import InsertText from '../insertText/insertText';
import HighlightTermsCommand from './commands/highlightTermsCommand';
import ClearHighlightTermsCommand from './commands/clearHighlightTermsCommand';
import SetHighlightConfigCommand from './commands/setHighlighTermsCommand';
import HighlightState from './HighlightTermState';

const {
  INSERT_TEXT: { COMMANDS: INSERT_TEXT_COMMANDS },
  HIGHLIGHT_TERM: {
    COMMANDS,
    SETTINGS,
    MISC: { ATTRIBUTE_KEY },
  },
} = pluginsConfig;

const DEBOUNCE_DURATION = 250;

export default class HighlightTerm extends Plugin {
  static get pluginName() {
    return SETTINGS.PLUGIN_NAME;
  }

  static get requires() {
    return [InsertText];
  }

  constructor(editor) {
    super(editor);

    editor.config.define(SETTINGS.CONFIG_NAME, {
      isEnabled: true,
    });

    this._config = editor.config.get(SETTINGS.CONFIG_NAME) || {};
    this.state = new HighlightState(this.editor.model);
  }

  init() {
    this._defineSchema();
    this._defineCommands();
    this._defineConverters();
    this._activeResults = null;

    const editor = this.editor;

    const allowedKeycode = [8, 13, 32, 46];
    if (this._config.isEnabled) {
      const undoCommand = editor.commands._commands.get('undo');
      const redoCommand = editor.commands._commands.get('redo');
      const insertText = editor.commands._commands.get(
        INSERT_TEXT_COMMANDS.insertText,
      );

      if (undoCommand) {
        undoCommand.on(
          'execute',
          debounce(() => {
            this.highlightHandler();
          }, DEBOUNCE_DURATION),
        );
      }
      if (redoCommand) {
        redoCommand.on(
          'execute',
          debounce(() => {
            this.highlightHandler();
          }, DEBOUNCE_DURATION),
        );
      }

      editor.editing.view.document.on(
        'cut',
        debounce(() => {
          this.highlightHandler();
        }, DEBOUNCE_DURATION),
      );

      editor.editing.view.document.on(
        'clipboardInput',
        debounce(() => {
          this.highlightHandler();
        }, DEBOUNCE_DURATION),
      );

      // https://keyjs.dev/#keycodes-table
      editor.editing.view.document.on(
        'keyup',
        debounce((evt, data) => {
          if (
            ((data.keyCode >= 65 && data.keyCode <= 90) ||
              (data.keyCode >= 48 && data.keyCode <= 57) ||
              (data.keyCode >= 96 && data.keyCode <= 111) ||
              (data.keyCode >= 186 && data.keyCode <= 192) ||
              (data.keyCode >= 219 && data.keyCode <= 222) ||
              allowedKeycode.includes(data.keyCode)) &&
            !data.ctrlKey
          ) {
            this.highlightHandler();
          }
        }, DEBOUNCE_DURATION),
      );

      if (insertText) {
        insertText.on(
          'execute',
          debounce(() => {
            this.highlightHandler();
          }, DEBOUNCE_DURATION),
          { priority: 'low' },
        );
      }
    }
  }

  highlightHandler() {
    this.editor.execute(COMMANDS.highlightTerms);
  }

  _defineCommands() {
    this.editor.commands.add(
      COMMANDS.highlightTerms,
      new HighlightTermsCommand(this.editor, this.state),
    );

    this.editor.commands.add(
      COMMANDS.clearHighlightedTerms,
      new ClearHighlightTermsCommand(this.editor, this.state),
    );

    this.editor.commands.add(
      COMMANDS.setHighlightTerms,
      new SetHighlightConfigCommand(this.editor, this.state),
    );
  }

  _defineConverters() {
    const { editor } = this;
    editor.conversion.for('editingDowncast').attributeToElement({
      model: ATTRIBUTE_KEY,
      view: (modelAttributeValue, conversionApi) => {
        const { writer } = conversionApi;
        const [color, priority] = (modelAttributeValue || '').split(':');
        return writer.createAttributeElement(
          'span',
          { class: color },
          { priority: Number(priority) },
        );
      },
    });
  }

  _defineSchema() {
    const schema = this.editor.model.schema;

    // Extend the text node's schema to accept the highlightTerm attribute.
    schema.extend('$text', {
      allowAttributes: [ATTRIBUTE_KEY],
    });

    schema.setAttributeProperties(ATTRIBUTE_KEY, {
      isFormatting: false,
      copyOnEnter: false,
    });
  }
}
