import { useMemo } from 'react';
import { ButtonVariants, BUTTON_VARIANTS } from '../types';

export const useVariantsClasses = (variant: ButtonVariants) => {
  return useMemo(() => {
    switch (variant) {
      case BUTTON_VARIANTS.blue400:
        return [
          'text-white',
          'bg-blue-400',
          'hover:bg-blue-500',
          'disabled:bg-gray-400 disabled:hover:bg-gray-400',
          'disabled:text-white disabled:hover:text-white',
        ];
      case BUTTON_VARIANTS.blue200:
        return [
          'text-blue-500',
          'bg-blue-100',
          'border-transparent',
          'hover:bg-blue-50',
          'hover:border-blue-500',
          'disabled:bg-gray-400',
          'disabled:hover:bg-gray-400',
          'disabled:text-white',
          'disabled:hover:text-white',
        ];
      case BUTTON_VARIANTS.red100:
        return ['bg-red-100', 'text-red-500', 'hover:bg-red-200'];
      case BUTTON_VARIANTS.red400:
        return ['text-white', 'bg-red-400', 'hover:bg-red-500'];
      case BUTTON_VARIANTS.gray500:
        return [
          'bg-gray-500',
          'text-white',
          'disabled:bg-gray-500',
          'disabled:text-white',
          'hover:bg-gray-500',
          'hover:text-white',
        ];
      case BUTTON_VARIANTS.gray700:
        return [
          'bg-gray-700',
          'text-gray-500',
          'hover:bg-gray-800',
          'hover:text-gray-500',
        ];
      case BUTTON_VARIANTS.gray150:
        return [
          'bg-gray-150',
          'text-gray-700',
          'hover:bg-gray-200',
          'disabled:text-white',
          'disabled:hover:bg-gray-150',
        ];
      case BUTTON_VARIANTS.gray50:
        return [
          'bg-gray-50',
          'text-gray-500',
          'hover:bg-gray-150',
          'hover:text-gray-700',
        ];
      case BUTTON_VARIANTS.blueOnTransparent:
        return [
          'text-blue-400',
          'bg-transparent',
          'hover:text-blue-500',
          'disabled:text-gray-500',
        ];
      case BUTTON_VARIANTS.blue500OnTransparent:
        return [
          'text-blue-500',
          'bg-transparent',
          'hover:text-blue-600',
          'disabled:text-gray-500',
        ];
      case BUTTON_VARIANTS.blue500OnBlue100:
        return [
          'text-blue-500',
          'bg-blue-100',
          'hover:blue-200',
          'disabled:text-blue-500',
          'disabled:bg-blue-100',
        ];
      case BUTTON_VARIANTS.gray50OnTransparent:
        return ['text-gray-500', 'hover:text-gray-900', 'bg-transparent'];
      case BUTTON_VARIANTS.gray600OnTransparent:
        return [
          'text-gray-600',
          'bg-transparent',
          'disabled:text-gray-500',
          'hover:text-gray-800',
        ];
      case BUTTON_VARIANTS.blackOnTransparent:
        return ['text-gray-800', 'bg-transparent'];
      default:
      case BUTTON_VARIANTS.none:
        return [];
    }
  }, [variant]);
};
