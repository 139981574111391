import { FC } from 'react';
import { Button, ButtonProps } from '../../../Button';
import { Icon, SUPPORTED_ICON } from '../../../Icon';

const ToastClose: FC<Omit<ButtonProps, 'children'>> = props => {
  if (!props?.onClick) return null;

  return (
    <Button className={['px-3']} {...props}>
      <Icon name={SUPPORTED_ICON.close} boxSize={21} color="text-gray-500" />
    </Button>
  );
};

export default ToastClose;
