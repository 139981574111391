import { get, isEmpty } from 'lodash';
import dayjs from 'dayjs';
import {
  getCountryEnum,
  sanitizePageUrl,
  sanitizeTerm,
  serializeToAppData,
} from '@marketmuse/utilities';
import {
  Country,
  Language,
  KnowledgeGraph,
  AdWordsItemWithVariants,
  SeoKnowledgeGraphCompleteQueryVariables,
} from '@marketmuse/config/types/papi';
import * as types from '../../config/types';
import { KgScoresStore } from '../../config/instances';

import fetchKgAndVariants from '../misc/fetchKgAndVariants';

type CacheValue = {
  data: {
    knowledgeGraph: KnowledgeGraph;
    bulkAdwordKeywords: AdWordsItemWithVariants[];
  };
  timestamp: number;
};

const shouldUseCache = (value, date) => {
  const hasValue = !isEmpty(value?.data);
  const now = dayjs(date);
  const timestamp = value?.timestamp;
  const diff = now.diff(timestamp, 'day', true);

  return hasValue && diff <= 1;
};

const serializeResponse = ({
  knowledgeGraph,
  bulkAdwordsKeywords,
}: {
  knowledgeGraph: KnowledgeGraph;
  bulkAdwordsKeywords: AdWordsItemWithVariants[];
}) => {
  const res = {
    appData: serializeToAppData({
      ...knowledgeGraph,
      bulkAdwordsKeywords: bulkAdwordsKeywords,
    }),
    knowledgeGraph,
    bulkAdwordsKeywords,
  };

  return res;
};

async function fetchAndCache({
  date,
  key,
  fetchSerpPages,
  skipVariants,
  variables,
}: {
  date: number;
  key: string;
  skipVariants: boolean;
  fetchSerpPages: boolean;
  variables: SeoKnowledgeGraphCompleteQueryVariables;
}) {
  const { seoKnowledgeGraph, seoBulkAdwordsKeywords } =
    await fetchKgAndVariants({ variables, fetchSerpPages, skipVariants });

  KgScoresStore.setItem(key, {
    data: {
      knowledgeGraph: seoKnowledgeGraph,
      bulkAdwordsKeywords: seoBulkAdwordsKeywords,
    },
    timestamp: date,
  });

  const res = serializeResponse({
    knowledgeGraph: seoKnowledgeGraph,
    bulkAdwordsKeywords: seoBulkAdwordsKeywords,
  });
  return res;
}

const seoKnowledgeGraphAndVariants = () => {
  return ({ getState }) => {
    return next => async action => {
      const state = getState();

      if (action.type === types.GET_KEYWORD_DETAILS_KG) {
        const date = Date.now();
        const skipVariants = get(action, 'skipVariants') || false;
        const fetchSerpPages = get(action, 'fetchSerpPages') || false;
        const term = get(action, 'term');
        const url = get(action, 'url');
        const countryString = get(
          state,
          'filter.site.defaultSerpCountry',
        ) as string;

        // fetchKgAndVariants variables
        const variables = {
          country: countryString ? getCountryEnum(countryString) : Country.US,
          language: Language.EN,
          term: sanitizeTerm(term),
          ...(url ? { url: sanitizePageUrl(url) } : {}),
        };

        const key = [
          types.GET_KEYWORD_DETAILS_KG,
          variables.term,
          variables.url,
          variables.country,
          'v24.01.a',
        ].join('-');

        try {
          const value = (await KgScoresStore.getItem(key)) as CacheValue;

          if (value) {
            if (shouldUseCache(value, date)) {
              const res = serializeResponse(value.data);

              action.callback(res);
            } else {
              await KgScoresStore.removeItem(key);
            }
          } else {
            const res = await fetchAndCache({
              date,
              key,
              skipVariants,
              fetchSerpPages,
              variables,
            });

            action.callback(res);
          }
        } catch (error) {
          action.callback({});
        }
      }

      return next(action);
    };
  };
};

export default seoKnowledgeGraphAndVariants;
