import { get, isEmpty } from 'lodash';

import { RecurlyGetTransactionsDocument } from '@marketmuse/data-papi';
import { paymentActions } from '@marketmuse/data-state/payment';
import * as types from '../../config/types';

import makeRequest from '../../utils/makeRequest';
import isPaymentTestModeActive from '../../utils/isPaymentTestModeActive';

export default () =>
  ({ dispatch }) =>
  next =>
  action => {
    if (action.type === types.RECURLY_GET_TRANSACTIONS) {
      const useTestCode = isPaymentTestModeActive();
      const code = action.accountCode;
      const codeTest = action.accountCodeTest;
      const accountCode = useTestCode ? codeTest : code;

      if (!accountCode || isEmpty(accountCode)) {
        return next(action);
      }

      makeRequest({
        type: types.RECURLY_GET_TRANSACTIONS,
        mutation: RecurlyGetTransactionsDocument,
        variables: {
          accountCode,
        },
        options: {
          isQuery: true,
        },
        apolloOptions: {
          fetchPolicy: 'no-cache',
          context: {
            headers: {
              UseRecurlyTestCode: useTestCode,
            },
          },
        },
      }).then(res => {
        const transactions = get(res, 'recurlyGetTransactions');

        if (transactions) {
          dispatch(paymentActions.setTransactions(transactions));
        }

        if (typeof action.callback === 'function') {
          action.callback(transactions);
        }
      });
    }

    return next(action);
  };
