import * as types from '../../config/types';

export default () =>
  ({ getState }) =>
  next =>
  action => {
    if (action.type === types.DELETE_ORG) {
      alert(
        'This resolver is disabled at the moment - please contact the engineering team',
      );
      console.error('Disabled');
      // makeRequest({
      //   type: types.DELETE_ORG,
      //   mutation: ,
      //   variables: {
      //     id: action.id,
      //   },
      //   apolloOptions: {
      //     fetchPolicy: 'no-cache'
      //   }
      // }).then(res => {
      //   const data = get(res, 'organizationDelete', {});
      //   if (get(data, 'id')) {
      //     if (typeof action.callback === 'function') action.callback(data);
      //   } else {
      //     Toast.fire(res.error || 'There was a problem in deleting this Organization.', 'error');
      //   }
      //   Toast.fire('Organization has been successfully deleted.');
      // });
    }

    return next(action);
  };
