import React from 'react';
import cx from 'classnames';
import { Badge } from '../../Badge';
import { BadgeProps } from '../../Badge/lib/types';

import { Icon, SUPPORTED_ICON } from '../../Icon';

export interface PillProps extends Omit<BadgeProps, 'as'> {
  onRemove?: () => void;
  iconClassName?: string;
}

type PillComponent = (props: PillProps) => React.ReactElement | null;

export const Pill: PillComponent = React.memo(
  ({ onRemove, iconClassName, children, ...badgeProps }: PillProps) => {
    const iconSize =
      badgeProps.size === 'xs' || badgeProps.size === 'sm' ? 12 : 16;
    const _iconClassName =
      typeof iconClassName === 'string' ? iconClassName : cx(iconClassName);
    return (
      <Badge {...badgeProps}>
        {children}
        {onRemove && (
          <Icon
            title={`Remove ${children}`}
            as="button"
            name={SUPPORTED_ICON.close}
            onClick={onRemove}
            boxRole={'button'}
            boxSize={iconSize}
            iconSize={iconSize}
            className={cx('cursor-pointer', 'ml-3', _iconClassName)}
          />
        )}
      </Badge>
    );
  },
);
