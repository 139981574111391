import { pick } from 'lodash';
import { ApolloQueryResult } from '@apollo/client';
import type { SeoBulkAdwordsKeywordsCompleteQuery } from '@marketmuse/config/types/papi';
import { SeoBulkAdwordsKeywordsCompleteDocument } from '@marketmuse/data-papi';
import {
  ResearchDataBulkAwInput,
  ResearchThunkSeoRes,
  ThunkApiConfig,
} from '../../types';
import createNonConcurrentAsyncThunk from '../../utils/createNonConcurrentAsyncThunk';

const getBulkAdwordsKeywords = createNonConcurrentAsyncThunk<
  ResearchThunkSeoRes,
  ResearchDataBulkAwInput,
  ThunkApiConfig
>('runs/getBulkAdwordsKeywords', async (params, { extra, rejectWithValue }) => {
  const response: ApolloQueryResult<SeoBulkAdwordsKeywordsCompleteQuery> =
    await extra.apollo.query({
      query: SeoBulkAdwordsKeywordsCompleteDocument,
      variables: pick(params, ['terms', 'country', 'language']),
    });

  const error = response.errors;
  if (error) {
    return rejectWithValue(error);
  }

  const resBulkAdwordsKeywords = (
    response?.data?.seoBulkAdwordsKeywords || []
  ).map(item => ({
    ...item,
    existsInInventory: false,
  }));

  return {
    data: resBulkAdwordsKeywords,
  };
});

export default getBulkAdwordsKeywords;
