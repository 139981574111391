import { useState, useLayoutEffect } from 'react';

const getRectangle = el => {
  const rect = el?.getBoundingClientRect() || {};
  const { top, left, x, y, width, height } = rect;
  return { top, left, width, height, x, y };
};

const useRectangle = (ref = {}) => {
  const [rect, setRect] = useState(getRectangle(ref?.current));

  const handleChangePosition = () => {
    if (ref?.current) {
      setRect(getRectangle(ref.current));
    }
  };

  useLayoutEffect(() => {
    handleChangePosition();

    const resizeObserver = new ResizeObserver(function () {
      handleChangePosition();
    });

    ref?.current && resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect(ref.current);
    };
  }, [ref]);

  return rect;
};

export default useRectangle;
