import { ObjectValues } from './utilities';

const WRITER_TYPES = {
  optimize: 'optimize',
  writing: 'writing',
  optimizeBrief: 'optimizeBrief',
} as const;

export type WriterTypes = ObjectValues<typeof WRITER_TYPES>;

export default WRITER_TYPES;
