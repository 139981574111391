import styled from 'styled-components';
import Sidebar from '../Sidebar/Sidebar';

import AppModalsMiscState from './AppModalsMiscState';

const ContentWrapper = styled.div.attrs({ role: 'main' })`
  position: relative;
  flex-grow: 1;
  overflow: hidden;
  background-color: white;
`;

const LayoutProtected = ({ children }) => {
  return (
    <>
      <Sidebar />
      <AppModalsMiscState />
      <ContentWrapper>{children}</ContentWrapper>
    </>
  );
};

export default LayoutProtected;
