import { DifficultyArgs } from '../types';
import calculate from './calculate';

const backgroundColor = (...args: DifficultyArgs) => {
  const { color } = calculate(...args);
  if (color === 'red') return 'bg-red-400';
  if (color === 'gray') return 'bg-gray-400';
  if (color === 'green') return 'bg-green-400';
  if (color === 'orange') return 'bg-orange-400';
  if (color === 'yellow') return 'bg-yellow-400';
  return 'bg-green-400';
};

export default backgroundColor;
