import { PAPI } from '@marketmuse/config/types';
import { validatePermission } from '@marketmuse/utilities';
import {
  AppQueryNetworkStatus,
  ReduxListener,
  RootState,
} from '../../../types';

import { researchTaskStatusCheck } from '../../actions';
import { dataSlice, networkSlice } from '../../slices';

import {
  getSerpPagesData,
  getSerpQuestions,
  getKnowledgeGraph,
} from '../../thunks';

const listeners = (startListening: ReduxListener) => {
  const dataActions = dataSlice.actions;
  const networkActions = networkSlice.actions;

  startListening({
    actionCreator: getSerpPagesData.pending,
    effect: async (action, { dispatch }) => {
      await dispatch(
        networkActions.serpPagesSet(AppQueryNetworkStatus.loading),
      );
    },
  });

  startListening({
    actionCreator: getSerpPagesData.rejected,
    effect: async (action, { dispatch }) => {
      const { aborted } = action.meta;
      if (!aborted) {
        await dispatch(
          networkActions.serpPagesSet(AppQueryNetworkStatus.error),
        );

        // check status after other steps
        await dispatch(researchTaskStatusCheck());
      }
    },
  });

  startListening({
    actionCreator: getSerpPagesData.fulfilled,
    effect: async ({ payload }, { dispatch }) => {
      await Promise.all([
        dispatch(dataActions.serpPagesSet(payload.data)),
        dispatch(networkActions.serpPagesSet(AppQueryNetworkStatus.complete)),
      ]);

      // check status after other steps
      await dispatch(researchTaskStatusCheck());
    },
  });

  startListening({
    actionCreator: getSerpPagesData.fulfilled,
    effect: async ({ payload }, { dispatch, getState }) => {
      const state: RootState = getState();
      const permissions = state.permissions;
      const canAccessQuestions = validatePermission({
        permissions,
        permission: {
          resource: PAPI.PermissionResource.RESEARCH_QUESTIONS,
          action: PAPI.PermissionAction.ACCESS_DATA,
        },
      });
      const next = [dispatch(getKnowledgeGraph(payload.params))];
      if (canAccessQuestions) {
        next.push(dispatch(getSerpQuestions(payload.params)));
      }
      await Promise.all(next);
    },
  });
};

export default listeners;
