import { ObjectValues } from './utilities';

const NETWORK_STATUS = {
  complete: 'complete',
  error: 'error',
  inactive: 'inactive',
  loading: 'loading',
  queued: 'queued',
} as const;

export type NetworkStatus = ObjectValues<typeof NETWORK_STATUS>;

export default NETWORK_STATUS;
