import { useMemo, useRef, useCallback } from 'react';
import { merge } from 'lodash';
import { Editor as EditorBase } from '@marketmuse/editor-ui';
import { editor as EDITOR_CONFIG } from '@marketmuse/config/configs';
import { omitItems } from '@marketmuse/utilities';

import { toast } from '../Toast';

import { IEditorProps } from './types';
import { defaultPlugins } from './config/plugins';
import {
  heading,
  removePlugins,
  getToolbarItems,
  table,
  image,
  link,
  indentBlock,
} from './config/defaultConfig';

export const Editor = ({
  config = {},
  data,
  features = {},
  id,
  onBlur,
  onChange,
  onEditorReady,
  onError,
  onFocus,
}: IEditorProps) => {
  const editorRef = useRef(null);

  const exportConfig = useMemo(() => {
    const base = config.export || {};
    const defaultConfig = {
      googleClientId: process.env['REACT_APP_GOOGLE_CLIENT_ID'],
      filename: 'export',
      isEnabled: true,
      feature: {
        word: {
          hasAccess: false,
          isEnabled: true,
        },
        googleDoc: {
          hasAccess: false,
          isEnabled: true,
          onSuccess: (id: string) => {
            toast.neutral(
              <>
                <p>Your document has been uploaded to your Google Drive</p>
                <a
                  className={'px-4 py-1 bg-gray-200 rounded'}
                  target="_blank"
                  rel="noreferrer"
                  href={`https://docs.google.com/document/d/${id}`}
                >
                  Open in Google
                </a>
              </>,
            );
          },
        },
      },
    };

    return merge(defaultConfig, base);
  }, [config.export]);

  const customConfig: any = {
    extraPlugins: [].concat(...defaultPlugins, config.extraPlugins || []),
    placeholder: 'Enter content',
    indentBlock,
    removePlugins: [...removePlugins],
    toolbar: {
      items: getToolbarItems(features?.toolbar),
      shouldNotGroupWhenFull: features?.shouldNotGroupWhenFull,
    },
    heading,
    table,
    image,
    link,
    export: exportConfig,
    ...(config ? omitItems(config, ['extraPlugins', 'export']) : {}),
  };

  const onReady = useCallback(
    editor => {
      if (!editorRef.current) {
        editorRef.current = editor;
      }
      if (onEditorReady) {
        onEditorReady(editorRef.current || editor);
      }
      if (window && window.mmEditorReady) {
        window.mmEditorReady(id);
      }
    },
    [onEditorReady, id],
  );

  return (
    <EditorBase
      id={id}
      data={data}
      config={customConfig}
      onReady={onReady}
      onError={onError}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
};

export default Editor;
