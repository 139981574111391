import { hash } from '../../utils/misc';
import Event from './Event';


class ErrorEvent extends Event {

  getDefaults() {
    const defaults = super.getDefaults();
    return Object.assign(defaults, {
      mmsErrorEvent: true,
    })
  }

  record(action, data) {
    const randomNumber = Math.round(Math.random() * 100000000);
    return super.record('ErrorEvent', {
      ...data,
      errorId: hash(`${action}${randomNumber}`),
      errorTitle: action
    })
  }
}

export default ErrorEvent;
