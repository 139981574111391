import { useStoreSelector } from '@marketmuse/data-state/hooks';
import { PermissionToCheck } from '@marketmuse/config/types/permissions';
import { validatePermission } from '@marketmuse/utilities';

const useHasAccess = (permission: PermissionToCheck) => {
  const permissions = useStoreSelector(state => state.permissions);

  return validatePermission({
    permissions,
    permission,
  });
};

export default useHasAccess;
