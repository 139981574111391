import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import Tippy from '@tippyjs/react';
import { inlinePositioning } from 'tippy.js';

import * as propTypes from '../../../config/propTypes/tippy';

import defaultProps from './defaultProps';
import { manualOriginPlugin } from './plugins/manualOriginPlugin';
import { hideOnEsc } from './plugins/hideOnEsc';

const MMSTippy = forwardRef(({ plugins, content, ...rest }, ref) => {
  const [mounted, setMounted] = useState(false);
  const lazyPlugin = {
    fn: () => ({
      onMount: () => setMounted(true),
      onHidden: () => setMounted(false),
    }),
  };

  const props = {
    ...defaultProps,
    ...rest,
    ...(!isNil(rest.visible) ? { hideOnClick: void 0, trigger: void 0 } : {}),
    content: mounted ? content : '',
    plugins: [
      lazyPlugin,
      manualOriginPlugin,
      inlinePositioning,
      hideOnEsc,
      ...(plugins || []),
    ],
  };

  return <Tippy {...props} ref={ref} />;
});

// watch console.warn when modifying
// node_modules/@tippyjs/react/index.d.ts
// https://atomiks.github.io/tippyjs/v6/all-props/
MMSTippy.propTypes = {
  // https://atomiks.github.io/tippyjs/v6/all-props/#popperoptions
  popperOptions: propTypes.popperOptions,
  // https://github.com/atomiks/tippyjs-react#reference-reactrefobject--element
  reference: PropTypes.object,

  visible: PropTypes.bool,
  // allow interaction
  interactive: PropTypes.bool,
  // Determines the size of the invisible border around the tippy that will prevent it from hiding if the cursor left it.
  interactiveBorder: PropTypes.number,
  // Delay in ms once a trigger event is fired before a tippy shows or hides.
  delay: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  // Delay in ms once a trigger event is fired before a tippy shows or hides.
  duration: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  // show arrow
  arrow: PropTypes.bool,
  // prevent opening
  disabled: PropTypes.bool,
  // how to open
  trigger: propTypes.trigger,
  placement: propTypes.placement,
  hideOnClick: PropTypes.oneOf([true, false, 'toggle']),
  showOnCreate: PropTypes.bool,
  offset: propTypes.offset,
  plugins: PropTypes.arrayOf(propTypes.plugin),
};

export default MMSTippy;
