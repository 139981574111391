import { combineReducers } from '@reduxjs/toolkit';

import { dataSlice, networkSlice, uiSlice, viewsSlice } from './slices';

const appResearchReducer = combineReducers({
  data: dataSlice.reducer,
  network: networkSlice.reducer,
  ui: uiSlice.reducer,
  views: viewsSlice,
});

export default appResearchReducer;
