import React from 'react';
import * as Sentry from '@sentry/react';
import { ErrorBoundaryProps } from './types';
import ErrorScreen from './ErrorScreen';

export const ErrorBoundary = ({ openChat, children }: ErrorBoundaryProps) => (
  <Sentry.ErrorBoundary
    fallback={({ error, componentStack }) => (
      <ErrorScreen
        error={error}
        componentStack={componentStack}
        openChat={openChat}
      />
    )}
    children={children}
  />
);
