import { AdWordsItem, KnowledgeGraphItem } from '@marketmuse/config/types/papi';

type VariantsParams = Pick<KnowledgeGraphItem, 'term' | 'recommendedMentions'> &
  Pick<AdWordsItem, 'variants'>;
type Variants = Pick<KnowledgeGraphItem, 'term' | 'recommendedMentions'>[];
type AllVariants = { [key: string]: Variants };

const serializeBulkAdwordsVariants = (items: VariantsParams[]) => {
  const variants: Variants = [];
  const all_variants: AllVariants = {};

  items.forEach(item => {
    const { variants: kgVariants, recommendedMentions, term } = item;
    const parsedVariants = (kgVariants || []).map(variant => ({
      term: variant.term,
      recommended_mentions: recommendedMentions,
    }));

    variants.push(...parsedVariants);
    all_variants[term] = [...(all_variants[term] || []), ...parsedVariants];
  });
  return { variants, all_variants };
};

export default serializeBulkAdwordsVariants;
