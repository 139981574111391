/*
 * Use with any container with `position: relative`
 *
 * <div style={{ position: 'relative' }}>
 *   <BackgroundBubbles bubbles=[...] />
 * </div>
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  ${p =>
    p.zIndex &&
    `
    z-index: ${p.zIndex.value};
  `}
`;

export const Bubble = styled.div`
  position: absolute;
  border-radius: 50%;
  mix-blend-mode: multiply;
  background-color: ${p => p.theme[p.color || 'mmxGrey']};
`;

const defaultBubbles = [
  { size: 90, mmcolor: 'yellow', top: -45, left: -45 },
  { size: 150, mmcolor: 'blue', bottom: -20, right: -100 },
  { size: 220, mmcolor: 'red', bottom: -160, right: -20 },
  { size: 80, mmcolor: 'green', top: 50, right: -55 },
  { size: 280, mmcolor: 'grey', top: 10, right: -80 },
  { size: 280, mmcolor: 'grey', bottom: -80, left: -20 },
];

const pageBubbles = [
  { size: 160, mmcolor: 'yellow', top: -15, left: -85 },
  { size: 120, mmcolor: 'green', top: -85, left: 0 },
];

const readableBubbles = [
  { size: 150, mmcolor: 'blue', bottom: -20, right: -100, opacity: 0.2 },
  { size: 220, mmcolor: 'red', bottom: -160, right: -20, opacity: 0.2 },
  { size: 280, mmcolor: 'grey', top: 10, right: -80 },
  { size: 280, mmcolor: 'grey', bottom: -80, left: -20 },
];

const framingBubbles = [
  { size: 120, mmcolor: 'lightBlue2', top: -55, left: -55 },
  { size: 85, mmcolor: 'greenLight2', top: 45, left: -45 },
  { size: 90, mmcolor: 'redLight2', bottom: 60, right: -40 },
  { size: 150, mmcolor: 'yellowLight2', bottom: -50, right: -100 },
];

const BackgroundBubbles = props => {
  let bubbles = props.bubbles || defaultBubbles;
  if (props.page) bubbles = pageBubbles;
  if (props.readable) bubbles = readableBubbles;
  if (props.framingBubbles)
    bubbles =
      (props.framingBubbles &&
        Array.isArray(props.framingBubbles) &&
        props.framingBubbles) ||
      framingBubbles;

  return (
    <Wrapper zIndex={props.zIndex} style={props.style}>
      {bubbles.map((b, i) => (
        <Bubble
          key={`bubble-${i}`}
          color={b.mmcolor}
          style={{
            width: b.size,
            height: b.size,
            ...b,
          }}
        />
      ))}
    </Wrapper>
  );
};

BackgroundBubbles.propTypes = {
  page: PropTypes.bool, // page bubble configuration
  readable: PropTypes.bool, // readable configuration
  bubbles: PropTypes.arrayOf(
    PropTypes.shape({
      size: PropTypes.number,
      mmcolor: PropTypes.oneOf([
        'red',
        'green',
        'blue',
        'grey',
        'lightBlue2',
        'greenLight2',
        'redLight2',
        'yellowLight2',
      ]),
      top: PropTypes.number,
      bottom: PropTypes.number,
      right: PropTypes.number,
      left: PropTypes.number,
    }),
  ),
};

BackgroundBubbles.defaultProps = {
  default: true,
};

export default BackgroundBubbles;
