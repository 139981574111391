import * as React from 'react';
import { ButtonCorners, BUTTON_CORNERS } from '../types';

export const useCornersClasses = (corners: ButtonCorners) => {
  return React.useMemo(() => {
    switch (corners) {
      case BUTTON_CORNERS.none:
        return ['rounded-none'];
      case BUTTON_CORNERS.sm:
        return ['rounded-sm'];
      case BUTTON_CORNERS.md:
        return ['rounded-md'];
      case BUTTON_CORNERS.lg:
        return ['rounded-lg'];
      case BUTTON_CORNERS.xl:
        return ['rounded-xl'];
      case BUTTON_CORNERS.full:
        return ['rounded-full'];
      case BUTTON_CORNERS.base:
        return ['rounded'];
      default:
        return [];
    }
  }, [corners]);
};
