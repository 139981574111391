import { StatBand, WordCountArgs, Color } from '../types';
import backgroundColor from './backgroundColor';
import calculate from './calculate';

const statBandWordCount: StatBand<WordCountArgs, Color> = {
  calculate,
  backgroundColor,
  textColor: (...args) => {
    console.log('Not implemented', args);
    return '';
  },
};

export default statBandWordCount;
