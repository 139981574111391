import React from 'react';
import { format } from '@marketmuse/utilities';
import { IPaginationProps, IUsePagination } from '../types';

const sanitize = (value, fallback) =>
  Number.isSafeInteger(parseInt(value))
    ? Math.max(0, parseInt(value))
    : fallback;

function useMiddlePages({
  isReachedToFirst,
  pages,
  currentPage,
  isReachedToLast,
  middlePagesSiblingCount,
}: {
  isReachedToFirst: boolean;
  pages: number[];
  currentPage: number;
  isReachedToLast: boolean;
  middlePagesSiblingCount: number;
}) {
  return React.useMemo(() => {
    if (isReachedToFirst) {
      return pages.slice(0, currentPage + 3);
    }
    if (isReachedToLast) {
      return pages.slice(currentPage - 2, currentPage + 3);
    }
    return pages.slice(
      currentPage - middlePagesSiblingCount,
      currentPage + middlePagesSiblingCount + 1,
    );
  }, [
    middlePagesSiblingCount,
    currentPage,
    pages,
    isReachedToFirst,
    isReachedToLast,
  ]);
}

function useItemsShowing({
  page,
  limit,
  resultCount,
  maxCount,
}: {
  page: number;
  limit: number;
  resultCount: number;
  maxCount: number;
}) {
  return React.useMemo(() => {
    const offset = page * limit;
    let modifiedCount = resultCount;
    let showPlus = false;
    const remaining = modifiedCount - offset;

    if (remaining > maxCount) {
      if (remaining > maxCount && remaining % 10 === 1) {
        showPlus = true;
        modifiedCount -= 1;
      }
    }
    if (modifiedCount <= 0) {
      return '0 of 0';
    }

    const current = Math.min(offset + limit, modifiedCount);
    const outerCount = format(modifiedCount);
    const outer = `${outerCount}${showPlus ? '+' : ''}`;

    return `${offset + 1} - ${current} of ${outer}`;
  }, [page, limit, resultCount, maxCount]);
}

function useTotalPages({
  resultCount,
  limit,
}: {
  resultCount: number;
  limit: number;
}) {
  return React.useMemo(() => {
    const safeLimit = sanitize(limit, 1);
    const count = resultCount || 0;
    if (count <= 1) {
      return Math.max(count, 0);
    } else {
      return Math.max(Math.ceil((count - 1) / safeLimit), 0);
    }
  }, [resultCount, limit]);
}

const usePagination = ({
  page,
  resultCount,
  limit,
  maxCount,
}: Pick<
  IPaginationProps,
  'page' | 'resultCount' | 'limit' | 'maxCount'
>): IUsePagination => {
  const pageclean = sanitize(page, 0);
  const limitClean = sanitize(limit, 1);
  const resultCountClean = sanitize(resultCount, 0);

  const currentPage = Math.max(0, pageclean);
  const totalPages = useTotalPages({
    resultCount: resultCountClean,
    limit: limitClean,
  });
  const itemsShowing = useItemsShowing({
    page,
    limit: limitClean,
    resultCount: resultCountClean,
    maxCount,
  });

  const pages = Array(Math.min(totalPages, 10000))
    .fill(0)
    .map((_, i) => i + 1);

  const middlePagesSiblingCount = 2;
  const hasPreviousPage = currentPage + 1 > 1;
  const hasNextPage = currentPage + 1 < totalPages;

  const isReachedToFirst = currentPage <= middlePagesSiblingCount;
  const isReachedToLast = currentPage + middlePagesSiblingCount >= totalPages;

  const middlePages = useMiddlePages({
    isReachedToFirst,
    pages,
    currentPage,
    isReachedToLast,
    middlePagesSiblingCount,
  });

  const getAllPreviousPages = React.useMemo(() => {
    return pages.slice(0, middlePages[0] - 1);
  }, [pages, middlePages]);

  const previousPages = React.useMemo(() => {
    if (isReachedToFirst || getAllPreviousPages.length < 1) {
      return [];
    }
    return [Math.max(currentPage - 9, pages[0])];
  }, [pages, isReachedToFirst, currentPage, getAllPreviousPages]);

  const getAllNextPages = React.useMemo(() => {
    return pages.slice(
      middlePages[middlePages.length - 1],
      pages[pages.length],
    );
  }, [pages, middlePages]);

  const nextPages = React.useMemo(() => {
    if (isReachedToLast) {
      return [];
    }

    if (getAllNextPages.length < 1) {
      return [];
    }
    return [Math.min(currentPage + 11, pages[pages.length - 1])];
  }, [pages, isReachedToLast, currentPage, getAllNextPages]);

  const isPreviousTruncable = React.useMemo(() => {
    // Is truncable if first value of middlePage is larger than last value of previousPages
    return middlePages[0] > previousPages[previousPages.length - 1] + 1;
  }, [previousPages, middlePages]);

  const isNextTruncable = React.useMemo(() => {
    // Is truncable if last value of middlePage is larger than first value of previousPages
    return middlePages[middlePages.length - 1] + 1 < nextPages[0];
  }, [nextPages, middlePages]);

  return {
    page: currentPage,
    pages,
    hasPreviousPage,
    hasNextPage,
    previousPages,
    isPreviousTruncable,
    middlePages,
    isNextTruncable,
    nextPages,
    itemsShowing,
  };
};

export default usePagination;
