import { InlineWidget } from 'react-calendly';
import { Modal } from '../Modal';
import { CalendlyProps } from './types';

function Calendly({
  onClose,
  url,
  isVisible,
  style,
  prefill,
  utm,
}: CalendlyProps) {
  return (
    <Modal
      isVisible={isVisible}
      onClose={onClose}
      panelClassName={['max-w-[800px]', 'p-0']}
      className={'z-[200]'}
      panelWrapperClassName={['p-0']}
    >
      <InlineWidget url={url} styles={style} prefill={prefill} utm={utm} />
    </Modal>
  );
}

export default Calendly;
