import { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { MODAL_MODE } from '../types';

export const wrapperBase = [
  'fixed',
  'inset-0',
  'min-h-full',
  'h-full',
  'flex',
  'justify-center',
  'text-center',
  'items-center',
  'overflow-y-auto',
];

export const panelBase = [
  'w-full',
  'p-5',
  'text-left',
  'bg-white',
  'shadow-xl',
  'transform',
  'transition-all',
  'overflow-hidden',
];

export const panelSlideout = ['w-[640px]', 'max-w-[85%]', 'min-h-full'];

const useModalContentClasses = (mode: MODAL_MODE) => {
  return useMemo(() => {
    switch (mode) {
      case MODAL_MODE.slideoutRight:
        return {
          wrapper: twMerge(wrapperBase, ['justify-end', 'text-right']),
          panel: twMerge(panelBase, panelSlideout),
        };

      case MODAL_MODE.slideoutLeft:
        return {
          wrapper: twMerge(wrapperBase, ['justify-start', 'text-left']),
          panel: twMerge(panelBase, panelSlideout),
        };

      case MODAL_MODE.takeover:
        return {
          wrapper: wrapperBase,
          panel: twMerge(panelBase, ['h-full']),
        };

      case MODAL_MODE.complete:
        return {
          wrapper: wrapperBase,
          panel: twMerge(panelBase, ['max-w-[1260px]', 'h-full']),
        };

      default:
        return {
          wrapper: wrapperBase,
          panel: twMerge(panelBase, ['max-w-md', 'rounded-xl']),
        };
    }
  }, [mode]);
};

export default useModalContentClasses;
