import styled from 'styled-components';
import Button from './Button';

export default styled(Button).attrs({ large: true })`
  flex: 0 0 auto;
  padding-left: 0;
  padding-right: 0;
  color: ${p => p.theme.colors.black};
  background-color: ${p => p.theme.colors.white};
  border: 1px solid transparent;
  transition: color 0.3s ${p => p.theme.transitionFn};

  &:not(:first-child) {
    margin-left: ${p => p.theme.spacing.regular};
  }

  &:hover,
  &:active {
    color: ${p => p.theme.colors.blueBase};
  }
`;
