import React, { Suspense, useCallback } from 'react';

import { useMatch, generatePath } from 'react-router';
import { kebabCase } from 'lodash';

import { appResearchSlices } from '@marketmuse/data-state/appResearch';
import { ResearchDataInput } from '@marketmuse/data-state/types';
import {
  Navigation,
  navigationConfig,
  NavigationRoute,
} from '@marketmuse/research-ui';

import {
  useStoreDispatch,
  useStoreSelector,
} from '@marketmuse/data-state/hooks';
import { useSuiteNavigate } from '../../../hooks/useSuiteNavigate';

import Page from '../../../components/Page/Page';
import ScreenLoader from '../../../components/ScreenLoader';

import PageBodyStyled from './components/PageBodyStyled';
import useIsLoading from './hooks/useIsLoading';

import ResearchOmnibar from './ResearchOmnibar';
import ResearchRoutes from './ResearchRoutes';

const basePath = '/a/:slug/:siteId/apps/research';
const availableNavItems = [
  navigationConfig.topicNavigator.id,
  navigationConfig.serpXray.id,
  navigationConfig.heatmap.id,
  navigationConfig.connect.id,
];

const Research = () => {
  const dispatch = useStoreDispatch();
  const appParams: ResearchDataInput = useStoreSelector(
    state => state?.appResearch?.ui?.params,
  );
  const appQuery = useStoreSelector(state => state?.appResearch?.ui?.appQuery);
  const appStatus = useStoreSelector(state => state.appResearch.ui.status);
  const validParams = useStoreSelector(
    state => state.appResearch.ui.validParams,
  );

  const navigate = useSuiteNavigate();
  const { loadingTopicNavigator, loadingXray, loadingHeatmap, loadingConnect } =
    useIsLoading();

  const { params: routerParams } = useMatch(`${basePath}/:route/:view?`);

  const handleNavigation = useCallback(
    ({ path, id, view }: NavigationRoute) => {
      const nextLocation = generatePath(`${basePath}/${path}`, {
        route: kebabCase(id),
        view,
        siteId: routerParams.siteId,
        slug: routerParams.slug,
      });

      navigate(nextLocation, { root: true });
    },
    [navigate, routerParams],
  );

  return (
    <Suspense fallback={<ScreenLoader.Page />}>
      <Page className="mms--app">
        <ResearchOmnibar
          appParams={appParams}
          appStatus={appStatus}
          setParams={values =>
            dispatch(
              appResearchSlices.uiSlice.actions.paramsUpdate({
                ...appParams,
                ...values,
              }),
            )
          }
          validParams={validParams}
        />

        <PageBodyStyled className="mm-tw">
          <Navigation
            activeId={routerParams?.route}
            items={availableNavItems}
            loading={[
              loadingConnect && navigationConfig.connect.id,
              loadingHeatmap && navigationConfig.heatmap.id,
              loadingTopicNavigator && navigationConfig.topicNavigator.id,
              loadingXray && navigationConfig.serpXray.id,
            ].filter(Boolean)}
            onClick={handleNavigation}
          />
          <ResearchRoutes
            appQuery={appQuery || {}}
            appStatus={appStatus}
            loadingConnect={loadingConnect}
            loadingHeatmap={loadingHeatmap}
            loadingTopicNavigator={loadingTopicNavigator}
            loadingXray={loadingXray}
          />
        </PageBodyStyled>
      </Page>
    </Suspense>
  );
};

export default Research;
