import isNil from 'lodash/isNil';

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const monthNamesShort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const dateOrdinalSuffix = i => {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return 'st';
  }
  if (j === 2 && k !== 12) {
    return 'nd';
  }
  if (j === 3 && k !== 13) {
    return 'rd';
  }
  return 'th';
}

export const getMonthName = (monthNumber, options = {}) => {
  if (isNil(monthNumber)) return null;

  if (options.short) {
    return monthNamesShort[monthNumber - 1];
  }

  return monthNames[monthNumber - 1];
}

export default (date, options = {}) => {
  if (!date) return null;
  if (date === '-') return '-';
  const now = new Date();
  const d = new Date(date);

  const month = d.getMonth();
  const day = d.getDate();
  const year = d.getFullYear();
  const thisYear = now.getFullYear();

  if (typeof options.format === 'function') {
    return options.format({
      mShort: monthNamesShort[month],
      mLong: monthNames[month],
      month,
      day,
      year
    })
  }

  let readableDate = `${monthNamesShort[month]} ${day}`;
  if (options.addOrdinal) readableDate += dateOrdinalSuffix(day)
  if (year !== thisYear || options.forceIncludeYear) readableDate += `, ${year}`;

  return readableDate;
}
