import get from 'lodash/get';

import { OrganizationCreateDocument } from '@marketmuse/data-papi';
import Toast from '../../components/Toast';

import makeRequest from '../../utils/makeRequest';
import * as types from '../../config/types';

export default () =>
  ({ getState }) =>
  next =>
  action => {
    if (action.type === types.CREATE_INVENTORY_ORG) {
      makeRequest({
        type: types.CREATE_INVENTORY_ORG,
        mutation: OrganizationCreateDocument,
        variables: {
          organizationInput: action.fields,
        },
      }).then(res => {
        const data = get(res, 'organizationCreate') || {};
        if (get(data, 'id')) {
          Toast.fire('Organization created');
          if (typeof action.callback === 'function') action.callback(data);
        } else {
          !action.noErrorToast &&
            Toast.fire(
              res.error || res.data?.error || 'Cannot add organization',
              'error',
            );
          action.callback(res);
        }
      });
    }

    return next(action);
  };
