import React, { FC } from 'react';
import { IPageButtonProps } from '../types';
import { Box } from '../../Box';
import { TruncableElement } from './TruncableElement';
import { PageButtonRenderer } from './PageButtonRenderer';

export const PageButton: FC<IPageButtonProps> = ({
  previousPages,
  middlePages,
  nextPages,
  setCurrentPage,
  currentPage,
  truncableElementProps,
}) => {
  return (
    <Box className="flex gap-x-6">
      {previousPages.map(page => {
        return (
          <PageButtonRenderer
            key={page}
            page={page}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      })}
      <TruncableElement prev {...truncableElementProps} />
      {middlePages.map(page => {
        return (
          <PageButtonRenderer
            key={page}
            page={page}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      })}
      <TruncableElement {...truncableElementProps} />
      {nextPages.map(page => {
        return (
          <PageButtonRenderer
            key={page}
            page={page}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      })}
    </Box>
  );
};
