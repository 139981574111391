import { useCallback, useMemo } from 'react';
import { generatePath, useNavigate, useMatch } from 'react-router-dom';

import { Box } from '@marketmuse/components';
import { LinkingRecommendationForTerm } from '@marketmuse/config/types/papi';
import { NoResults, GatingCollateralConnect } from '@marketmuse/research-ui';
import {
  useResearchActions,
  useResearchPermissions,
} from '@marketmuse/research-data';
import { LinkList } from '../LinkList';
import { ConnectViews } from './types';
import { useRecommendation, useRecommendationCount, useStatus } from './hooks';
import ActionBar from './components/ActionBar';
import RecommendationLoadingBlock from './components/RecommendationLoading';

function Recommendations() {
  const navigate = useNavigate();
  const { shouldGateConnect } = useResearchPermissions();
  const {
    pattern: { path },
    params,
  } = useMatch('/a/:slug/:siteId/apps/research/:route/:view');
  const currentView = useMemo(
    () => params?.view,
    [params?.view],
  ) as unknown as ConnectViews;
  const status = useStatus();

  const { openSubscriptionFlow } = useResearchActions();
  const { recommendations, noResultDescription } =
    useRecommendation(currentView);
  const recommendationCount = useRecommendationCount();

  const setView = useCallback(
    view => {
      const next = generatePath(path, { ...params, view });
      navigate(next);
    },
    [path, params, navigate],
  );

  return (
    <>
      {shouldGateConnect && (
        <GatingCollateralConnect onUpgrade={openSubscriptionFlow} />
      )}
      {!shouldGateConnect && (
        <>
          <ActionBar
            recommendationCount={recommendationCount}
            loading={status.loading}
            currentView={currentView}
            setView={setView}
          />

          <div className="w-full flex flex-col flex-grow gap-2 overflow-y-auto pt-8">
            <Box className={['pl-12', 'pr-8']}>
              {status.loading && <RecommendationLoadingBlock />}
              {!status.loading &&
                Array.isArray(recommendations) &&
                recommendations.map((item: LinkingRecommendationForTerm) => {
                  return (
                    <LinkList
                      key={item.term}
                      className={'mb-5'}
                      term={item.term}
                      recommendations={item.recommendations}
                    />
                  );
                })}
              {status.complete && recommendations.length === 0 && (
                <Box className={['flex flex-row items-center justify-center']}>
                  <NoResults
                    title="No Items Found"
                    body={noResultDescription}
                  />
                </Box>
              )}
            </Box>
          </div>
        </>
      )}
    </>
  );
}

export default Recommendations;
