import React from 'react';
import PropTypes from 'prop-types';

import SidebarItem from './SidebarItem';
import SidebarItemCollapsed from './SidebarItemCollapsed';

const SidebarItemComponent = props => {
  if (props?.hidden) return null;

  return props?.collapsed ? (
    <SidebarItemCollapsed {...props} />
  ) : (
    <SidebarItem {...props} />
  );
};

SidebarItemComponent.propTypes = {
  collapsed: PropTypes.bool,
};

export default SidebarItemComponent;
