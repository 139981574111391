import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import useHoverDelay from '../../../hooks/useHoverDelay';
import hideTooltip from '../../../utils/hideTooltip';
import Menu from '../styles/Menu';

import SimpleMenuItem from './SimpleMenuItem';

const SimpleMenuList = ({ items, handleId, isParent, ...props }) => {
  const ref = useRef();
  const { engagedHover, hoverEngage, hoverDisengage } = useHoverDelay({
    delayOn: 100,
    delayOff: 250,
  });
  const titleIdMapper = useCallback(
    (title = '') =>
      title
        .toString()
        .toLowerCase()
        .replace(/\s/g, '-')
        .replace(/[^a-z-\d]/g, ''),
    [],
  );

  return (
    <Menu
      {...props}
      isParent={isParent}
      ref={ref}
      tabIndex="0"
      onClick={event => hideTooltip(handleId)}
    >
      {items?.map(({ component, ...item }, i) => {
        const titleId = titleIdMapper(item?.title);
        const key = `menu-item-${i}`;
        const id = item.id || titleId || key;

        const onClick = event => {
          hideTooltip(handleId);
          if (typeof item.onClick === 'function' && !item.disabled) {
            item.onClick(event);
          }
        };

        const props = {
          ...item,
          id,
          engagedHover: engagedHover === key,
          onMouseEnter: () => hoverEngage(key),
          onMouseLeave: hoverDisengage,
          onClick,
        };

        const Component = component ? component : SimpleMenuItem;

        return <Component key={key} {...props} />;
      })}
    </Menu>
  );
};

SimpleMenuList.propTypes = {
  // a11y
  handleId: PropTypes.string,

  // a11y
  role: PropTypes.string,

  // menu items to show
  items: PropTypes.array,
};

export default SimpleMenuList;
