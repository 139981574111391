import { forwardRef, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import {
  Button,
  ButtonProps,
  Icon,
  SUPPORTED_ICON,
} from '@marketmuse/components';

export interface FiltersTriggerProps extends Omit<ButtonProps, 'children'> {
  filtered?: boolean;
  locked?: boolean;
  dataId:
    | 'topic-navigator-keywords'
    | 'topic-navigator-topic-model'
    | 'topic-navigator-questions'
    | 'topic-navigator-reflect'
    | 'serp-xray';
}

const FiltersTrigger = forwardRef<HTMLButtonElement, FiltersTriggerProps>(
  (
    {
      children = 'Filter',
      filtered,
      locked,
      className,
      dataId,
      disabled,
      ...rest
    },
    ref,
  ) => {
    const icon = useMemo(
      () => (locked ? SUPPORTED_ICON.locked : SUPPORTED_ICON.filter),
      [locked],
    );

    return (
      <Button
        ref={ref}
        size="xs"
        corners="lg"
        data-mms--research-filters={dataId}
        variant={disabled || locked ? 'gray500' : 'blue500OnBlue100'}
        className={twMerge(
          ['gap-2', 'text-sm', 'font-bold', 'border', 'border-transparent'],
          filtered && !disabled && !locked && 'border-blue-500',
          disabled && 'cursor-default',
          className,
        )}
        {...rest}
      >
        <Icon name={icon} />
        {children}
        {!locked && <Icon name={SUPPORTED_ICON.caret_down} boxSize={12} />}
      </Button>
    );
  },
);

export default FiltersTrigger;
