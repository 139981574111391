/**
 * Things to record:
 * - application
 * - topic
 * - url
 * - brands
 * - people
 * - country
 * - bodyOrArticle
 */
import Event from '../../Event';

class AppExecuted extends Event {
  /**
   * Information to send to tracking agents
   * @param {object} executionDetails
   * @param {string} executionDetails.application
   * @param {object} executionDetails.params
   * @param {string} executionDetails.params.topic
   *
   * @param {object} additionalDetails
   * @param {boolean} additionalDetails.isTrial
   * @param {number} additionalDetails.appRunsCurrent
   * @param {number} additionalDetails.appRunsLimit
   */
  record(executionDetails, additionalDetails) {
    const { application, params } = executionDetails;
    const { fromSnapshot } = additionalDetails;

    super.record('AppExecuted', {
      category: 'App',
      mainValue: `${executionDetails.application} - ${params.topic}`,
      newName: 'apps--executed',
      fromSnapshot,
      application,
      ...params,
    });
  }
}

export default new AppExecuted();
