import { useMemo } from 'react';
import { ButtonCorners, ButtonSizes, ButtonVariants } from '../types';
import { useCornersClasses } from './useCornersClasses';
import { useSizesClasses } from './useSizesClasses';
import { useVariantsClasses } from './useVariantsClasses';

interface HookButtonClasses {
  variant: ButtonVariants;
  size: ButtonSizes;
  corners: ButtonCorners;
  typography?: string | Array<string>;
}

const useButtonClasses = ({
  variant,
  typography,
  size,
  corners,
}: HookButtonClasses) => {
  const variantClasses = useVariantsClasses(variant);
  const sizeClasses = useSizesClasses(size);
  const cornersClasses = useCornersClasses(corners);

  const typographyClasses = useMemo(() => {
    if (!typography) {
      return ['font-semibold', 'line-height-1', 'text-xs'];
    }
    return typography;
  }, [typography]);

  return useMemo(() => {
    const position = ['flex', 'inline-flex', 'items-center'];

    const animation = [
      'transition-all',
      'duration-200',
      'ease-out',
      'hover:ease-in',
    ];

    return [].concat(
      typographyClasses,
      position,
      variantClasses,
      sizeClasses,
      animation,
      cornersClasses,
    );
  }, [variantClasses, sizeClasses, cornersClasses, typographyClasses]);
};

export default useButtonClasses;
