import React, { memo, useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import IconSvg from '../../../../components/IconSvg';

import advancedSettings from '../../../../config/advancedSettings';
import handleEventBlock from '../../../../utils/handleEventBlock';
import Button from '../../../../components/Button';
import useRectangle from '../../../../hooks/useRectangle';
import { FilterCustomWrapper, FilterCustom } from './styles';
import Section from './Section';
import Filter from './Filter';

const Wrapper = styled.div`
  width: 320px;
  padding: ${p => p.theme.spacing.medium};
  height: 100%;
  max-height: ${p => p.maxHeight};
  overflow-y: auto;
`;

const FilterFactory = ({ state, setState, tool }) => {
  const ref = useRef(null);
  const rect = useRectangle(ref);

  const addCustomFilter = useCallback(
    customFilterKey => {
      const newCustomFilters = (state[customFilterKey] || []).slice();
      newCustomFilters.push({});
      setState({ [customFilterKey]: newCustomFilters });
    },
    [setState, state],
  );

  const removeCustomFilter = useCallback(
    (customFilterKey, index) => {
      const newCustomFilters = (state[customFilterKey] || []).slice();
      newCustomFilters.splice(index, 1);
      setState({ [customFilterKey]: newCustomFilters });
    },
    [setState, state],
  );

  const renderCustomFilterComponent = useCallback(
    ({ index, customFilter, customFilterKey, customFilterItem }) => {
      return (
        <FilterCustomWrapper key={`custom-item-${index}`}>
          <FilterCustom>
            {(customFilter.schema || []).map(({ id: key, ...setting }) => {
              const filterProps = {
                ...setting,
                // override onChange of filters so that they can modify
                // their object value on the `customFilters` object array
                // instead of modifying the global state directly
                onChange: id => {
                  const newCustomFilters = (
                    state[customFilterKey] || []
                  ).slice();
                  newCustomFilters[index] = { ...customFilterItem, [key]: id };
                  setState({
                    ...state,
                    [customFilterKey]: newCustomFilters,
                  });
                },
                value: customFilterItem[key],
                // override values to attach item active data
                values: (setting.values || []).map(value => ({
                  ...value,
                  active: customFilterItem[key] === value.id,
                })),
              };
              return <Filter key={key} {...filterProps} />;
            })}
          </FilterCustom>
          <Button
            key={`custom-item-child-${index}`}
            tertiary
            onClick={event => {
              handleEventBlock(event);
              removeCustomFilter(customFilterKey, index);
            }}
            style={{ padding: 0, marginLeft: '12px' }}
          >
            <IconSvg name="close" size="16" box="12" />
          </Button>
        </FilterCustomWrapper>
      );
    },
    [setState, state, removeCustomFilter],
  );

  const generateCustomFilters = useCallback(
    customFilter => {
      if (!customFilter) return [];
      let custom = [];
      const customFilterKey = customFilter.id || 'customFilters';

      // custom filters schema generated
      custom = custom.concat(
        (state[customFilterKey] || []).map((cf, i) => ({
          stretch: true,
          style: { paddingTop: 5 },
          component: renderCustomFilterComponent({
            customFilter,
            customFilterKey,
            customFilterItem: cf,
            index: i,
          }),
        })),
      );

      // add new item button
      custom.push({
        stretch: true,
        style: { paddingTop: 10 },
        component: (
          <Button
            tertiary
            border
            xLarge
            iconRight={<IconSvg name="plus" size="16" box="12" />}
            style={{ width: '100%', justifyContent: 'space-between' }}
            onClick={event => {
              handleEventBlock(event);
              addCustomFilter(customFilterKey);
            }}
          >
            Add Filter
          </Button>
        ),
      });

      return custom;
    },
    [setState, state, addCustomFilter],
  );

  const toolSettings = useMemo(
    () => advancedSettings[tool](state, setState),
    [tool, state, setState],
  );

  // predefined filters
  const commonSettings = useMemo(() => {
    return (toolSettings.sections || []).map(
      ({ id, component, ...setting }) => ({
        style: setting.outerStyle || {},
        component: component || <Filter key={id} {...setting} />,
      }),
    );
  }, [toolSettings.sections]);

  const serializedCustomFilters = useMemo(() => {
    let customFilters = toolSettings.custom || [];
    if (!Array.isArray(customFilters)) customFilters = [customFilters];
    return customFilters.map(filter => ({
      ...filter,
      items: generateCustomFilters(filter),
    }));
  }, [toolSettings.custom, generateCustomFilters]);
  const maxHeight = useMemo(
    () => `calc(100vh - ${rect?.top ?? 0}px - 20px)`,
    [rect.y],
  );
  return (
    <Wrapper ref={ref} maxHeight={maxHeight}>
      <Section
        icon="settings-outline"
        label="Settings"
        innerStyle={{ justifyContent: 'stretch' }}
        items={commonSettings}
      />
      {serializedCustomFilters.map(filter => (
        <Section
          key={filter.id}
          icon={filter.icon}
          label={filter.label}
          innerStyle={{ justifyContent: 'stretch' }}
          items={filter.items}
        />
      ))}
    </Wrapper>
  );
};

FilterFactory.propTypes = {
  label: PropTypes.string,
  tool: PropTypes.string,
  style: PropTypes.object,
  state: PropTypes.object,
  setState: PropTypes.func,
  reset: PropTypes.func,
  apply: PropTypes.func,
};

export default memo(FilterFactory);
