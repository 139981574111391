import { SliceTypeAppResearch } from '../../types/appResearch';
import { initialState as dataSliceInitialState } from './data/data.initialState';
import { initialState as networkSliceInitialState } from './network.initialState';
import { initialState as uiSliceInitialState } from './ui.initialState';
import { initialState as viewsSliceInitialState } from './views.initialState';

export { dataSlice } from './data/data.slice';
export { networkSlice } from './network.slice';
export { uiSlice } from './ui.slice';
export { viewsSlice, viewsActions } from './views.slice';

export const initialState: SliceTypeAppResearch = {
  data: dataSliceInitialState,
  network: networkSliceInitialState,
  ui: uiSliceInitialState,
  views: viewsSliceInitialState,
};
