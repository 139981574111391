// Former 'dynamic' theme

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import {
  SideItemsLeft,
  SideItemsRight,
} from '../components/SideItems';
import BaseWrapper from './base/BaseWrapper';
import BaseInput from './base/BaseInput';
import BaseTextarea from './base/BaseTextarea';


export const DefaultInput = styled(BaseInput)`
  color: currentColor;

  &::placeholder {
    color: ${p => p.theme.grey60};
  }
`;

export const DefaultTextarea = styled(BaseTextarea)`
  color: currentColor;

  &::placeholder {
    color: ${p => p.theme.grey60};
  }
`;

const Wrapper = styled(BaseWrapper)`
  position: relative;
  background-color: ${p => p.theme.colors.grey10};
  border-radius: ${p => p.theme.borderRadius};
  transition: ${p => p.theme.transition};
  color: ${p => p.theme.grey70};
  border: 2px solid transparent;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    content: '';
    border-radius: ${p => p.theme.borderRadius};
    transition: ${p => p.theme.transition};
    box-shadow: 0 0 0 2px ${p => p.theme.colors.blueLight1};
    opacity: 0;
  }

  &:focus-within {
    background-color: ${p => p.theme.colors.white};
    color: ${p => p.theme.grey85};

    &::after {
      opacity: 1;
    }
  }

  ${p => p.error && css`
    background-color: ${p.theme.colors.grey05};

    &::after {
      box-shadow: 0 0 0 2px ${p.theme.colors.redBase};
      opacity: 1;
    }

    &:focus-within {
      &::after {
        box-shadow: 0 0 0 2px ${p => p.theme.colors.blueLight1};
      }
    }
  `}

  ${p => p.disabled && css`
    color: ${p.theme.colors.grey50};
  `}

  ${DefaultTextarea},
  ${DefaultInput},
  ${SideItemsLeft},
  ${SideItemsRight} {
    z-index: 10;
  }
`;

const DefaultWrapper = ({ children, ...props } = {}) => (
  <Wrapper {...props}>{children}</Wrapper>
);

DefaultWrapper.propTypes = {
  children: PropTypes.any,
};

export default DefaultWrapper;
