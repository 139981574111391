import { isEmpty } from 'lodash';
import { RecurlySubscription } from '@marketmuse/config/types/papi';
import { recurlyPlansCodes } from '@marketmuse/config/configs';

type ResponseShape = {
  standardTrialExpiryDate?: Date;
  subscribedToStandardDate?: Date;
  cancelledStandardDate?: Date;
  subscriptionTrialExpiry?: Date;
  subscriptionPaidUpgraded?: Date;
  subscriptionPaidCancel?: Date;
};
/**
 * serializePendoSubscriptionInfo
 *
 * Take a list of recurly subscriptions, sort by date, and return date attributes
 * - if there's a trial subscription, return the trial expiry date
 * - if a subscription is canceled, return the canceled date
 * - if the subscription has canceled or downgraded to free
 *
 * Notes:
 * - Takes in 3 generations of P&P naming changes into logic
 * - Takes in 2 phases of trial plan codes
 * - Contains possible tracking flaw; a user can have multiple active/inactive subscriptions
 *   and a review of data may skew understanding of current state
 *   ie: an account with a trial expiry, a paid conversion, and a cancelation could mean
 *       - trial -> paid -> free
 *       - trial -> paid -> canceled -> paid
 *       - trial -> paid -> canceled
 * - Fields have been renamed to better match stat value until https://marketmuse.atlassian.net/browse/ENG-767
 * @param subscriptions
 * @returns
 */
const serializePendoSubscriptionInfo = (
  subscriptions: RecurlySubscription[],
): ResponseShape => {
  const returnValue: ResponseShape = {};

  if (isEmpty(subscriptions)) {
    return returnValue;
  }
  const subscriptionsSorted = subscriptions.slice().sort((a, b) => {
    const dateA = new Date(a?.createdAt?.toString()).valueOf();
    const dateB = new Date(b?.createdAt?.toString()).valueOf();
    return dateB - dateA;
  });

  const canceled = subscriptionsSorted.filter(subscription => {
    return (
      recurlyPlansCodes.paid.includes(subscription.plan.code) &&
      ['expired', 'canceled'].includes(subscription.state)
    );
  });

  const active = subscriptionsSorted.filter(subscription => {
    return (
      recurlyPlansCodes.paid.includes(subscription.plan.code) &&
      ['active'].includes(subscription.state)
    );
  });

  const trial = subscriptionsSorted.filter(subscription => {
    return recurlyPlansCodes.trial.includes(subscription.plan.code);
  });

  if (canceled.length && !active.length) {
    // TODO: https://marketmuse.atlassian.net/browse/ENG-767
    // @deprecated - Remove after removal from Pendo/Qualified
    returnValue.cancelledStandardDate = canceled[0].canceledAt;
    returnValue.subscriptionPaidCancel = canceled[0].canceledAt;
  }

  if (active.length) {
    // TODO: https://marketmuse.atlassian.net/browse/ENG-767
    // @deprecated - Remove after removal from Pendo/Qualified
    returnValue.subscribedToStandardDate = active[0].activatedAt;
    returnValue.subscriptionPaidUpgraded = active[0].activatedAt;
  }

  if (trial.length) {
    returnValue.subscriptionTrialExpiry = trial[0].currentTermEndsAt;
    // TODO: https://marketmuse.atlassian.net/browse/ENG-767
    // @deprecated - Remove after removal from Pendo/Qualified
    returnValue.standardTrialExpiryDate = trial[0].currentTermEndsAt;
  }

  return returnValue;
};

export default serializePendoSubscriptionInfo;
