import { findIndex } from 'lodash';
import { sanitizeTerm } from '@marketmuse/utilities';
import { ResearchAdWordsItem, ResearchKGItem } from '../../../types';

function updateVariantsForTerms(
  item: ResearchKGItem | ResearchAdWordsItem,
  terms: string[],
) {
  return item.variants.map(variant => {
    const existsInVariants = findIndex(
      terms,
      term => sanitizeTerm(term) === sanitizeTerm(variant.term),
    );
    return {
      ...variant,
      existsInInventory: existsInVariants !== -1 || variant.existsInInventory,
    };
  });
}

export function updateItemsForTerms(
  seoItems: Array<ResearchKGItem | ResearchAdWordsItem>,
  terms: string[],
) {
  return seoItems.map(item => {
    const existsInInventory = findIndex(
      terms,
      term => sanitizeTerm(term) === sanitizeTerm(item.term),
    );

    let variants;
    if (item.variants) {
      variants = updateVariantsForTerms(item, terms);
    }

    return {
      ...item,
      existsInInventory: existsInInventory !== -1 || item.existsInInventory,
      variants,
    };
  });
}
