import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { Box } from '../../Box';
import { useTableContext } from '../useTableContext';
import TableHeaderCell from '../components/TableHeaderCell';

export interface IPropsHeader {
  className?: string | Array<string | string[]>;
}

const TableHeader: FC<IPropsHeader> = ({ className }) => {
  const { getHeaderGroups } = useTableContext();
  const groups = getHeaderGroups() || [];

  return (
    <>
      {groups.map((group, groupIndex) => (
        <Box
          key={groupIndex}
          className={twMerge([
            'w-[max-content]',
            'min-w-full',
            'border-b',
            'border-gray-150',
            'bg-gray-50',
            'sticky',
            'group',
            'top-0',
            'z-10',
            'flex',
            'flex-row',
            className,
          ])}
        >
          {group.headers.map((cell, index) => (
            <TableHeaderCell key={index} cell={cell} />
          ))}
        </Box>
      ))}
    </>
  );
};

export default TableHeader;
