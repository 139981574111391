import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import IconSvg from '../../../components/IconSvg/IconSvg';
import RestrictedButton from '../../../components/RestrictedButton';
import Tooltip from '../../../components/Tooltip/Tooltip/Tooltip';

import * as types from '../../../config/types';

const Export = ({ tooltip, dataTags, onClick }) => {
  const loading = useSelector(state => state.loadings[types.GET_EXCEL]);

  return (
    <Tooltip title={tooltip} disabled={!tooltip}>
      <RestrictedButton
        restrictionType="export"
        onClick={onClick}
        loading={loading}
        disabled={loading}
        style={{ marginRight: 12 }}
        dataTags={dataTags}
      >
        <IconSvg
          name="sign-out"
          size="28"
          box="22"
          style={{ transform: 'rotate(-90deg)' }}
        />
      </RestrictedButton>
    </Tooltip>
  );
};

Export.propTypes = {
  onClick: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
  dataTags: PropTypes.object,
};

export default memo(Export);
