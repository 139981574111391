/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentType } from 'react';
import { serializeToAppData } from '@marketmuse/utilities';

export const withAppDataSerializer =
  (Component: ComponentType<any>) =>
  ({ data, ...rest }) => {
    const modifiedProps = {
      appData: serializeToAppData({
        bulkAdwordsKeywords: data.bulkAdwordsKeywords,
        knowledgeGraphItems: data.knowledgeGraph?.knowledgeGraphItems || [],
        metrics: data.knowledgeGraph?.metrics || {},
        organicSerpItemPagesKgData:
          data.knowledgeGraph?.organicSerpItemPagesKgData || [],
        userProvidedPage: data.knowledgeGraph?.userProvidedPage || {},
      }),
      ...rest,
    };

    return <Component {...modifiedProps} />;
  };
