export const manualOriginPlugin = {
  name: 'manualOrigin',
  defaultValue: false,
  fn(instance) {
    return {
      onCreate() {
        if (instance?.props?.manualOrigin) {
          instance.show();
        }
      },
      onClickOutside(instance, e) {
        const path = e.path || (e.composedPath && e.composedPath()) || [];
        const isPopperElementClicked = path.find(
          item => item?.id === 'mms--tippy-root',
        );
        if (!isPopperElementClicked) {
          instance.hide();
        }
      },
      onShow() {
        const manualOrigin = instance?.props?.manualOrigin;
        if (manualOrigin) {
          instance.setProps({
            getReferenceClientRect: () => ({
              width: 0,
              height: 0,
              top: manualOrigin.y,
              bottom: manualOrigin.y,
              left: manualOrigin.x,
              right: manualOrigin.x,
            }),
          });
        }
      },
    };
  },
};
