import { get, isEmpty } from 'lodash';

import { RecurlyGetAccountDocument } from '@marketmuse/data-papi';
import { paymentActions } from '@marketmuse/data-state/payment';
import * as types from '../../config/types';

import makeRequest from '../../utils/makeRequest';
import isPaymentTestModeActive from '../../utils/isPaymentTestModeActive';

export default () =>
  ({ dispatch }) =>
  next =>
  action => {
    if (action.type === types.RECURLY_GET_ACCOUNT) {
      const useTestCode = isPaymentTestModeActive();
      const code = action.accountCode;
      const codeTest = action.accountCodeTest;
      const accountCode = useTestCode ? codeTest : code;

      if (!accountCode || isEmpty(accountCode)) {
        return next(action);
      }

      makeRequest({
        type: types.RECURLY_GET_ACCOUNT,
        mutation: RecurlyGetAccountDocument,
        variables: {
          accountCode,
        },
        options: {
          isQuery: true,
        },
        apolloOptions: {
          fetchPolicy: 'no-cache',
          context: {
            headers: {
              UseRecurlyTestCode: useTestCode,
            },
          },
        },
      }).then(res => {
        const account = get(res, 'recurlyGetAccount');

        if (account) {
          dispatch(paymentActions.setAccount(account));
        }

        if (typeof action.callback === 'function') {
          action.callback(account);
        }

        return account;
      });
    }

    return next(action);
  };
