import { recurlyBillingCountries } from '@marketmuse/config/configs/recurly';
import COUNTRY_CODES from './countryCodes';
import { countryLanguageCombinations } from './countryLanguageCombinations';

const allowedCountries = [
  recurlyBillingCountries[COUNTRY_CODES.US],
  recurlyBillingCountries[COUNTRY_CODES.GB],
  recurlyBillingCountries[COUNTRY_CODES.CA],
  recurlyBillingCountries[COUNTRY_CODES.AU],
  recurlyBillingCountries[COUNTRY_CODES.NZ],
];

const countriesWithLanguageSupport = Object.keys(countryLanguageCombinations)
  .map(key => {
    const country = recurlyBillingCountries[key];
    const languages = countryLanguageCombinations[key];
    if (country && languages?.length) {
      return { ...country, languages };
    } else {
      return null;
    }
  })
  .filter(Boolean);

export { allowedCountries, countriesWithLanguageSupport };

export default recurlyBillingCountries;
