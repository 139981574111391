import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '@marketmuse/config/types/papi';
import * as ACTION_TYPES from '../actionTypes';
import { initialState } from './admin.initialState';

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    clear: () => {
      return {};
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        action =>
          action.type === ACTION_TYPES.SIGN_OUT ||
          action.type === ACTION_TYPES.ADMIN_MODE_DISABLE,
        () => {
          return {};
        },
      )
      .addMatcher(
        action => action.type === ACTION_TYPES.ADMIN_MODE_ENABLE,
        (state, action: PayloadAction<User>) => {
          return {
            ...action.payload,
          };
        },
      );
  },
});

export const adminReducer = adminSlice.reducer;
