import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isNil from 'lodash/isNil';

const Wrapper = styled.div`
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: ${p => isNil(p.maxHeight) ? '300px' : p.theme.apply(p.maxHeight)};

  ${p => p.border && `
    border-top: 1px solid var(--mms-simple-border, ${p.theme.generic.light.border});
    border-bottom: 1px solid var(--mms-simple-border, ${p.theme.generic.light.border});
  `}
`;

const SimpleListScroll = ({ children, ...rest }) => (
  <Wrapper {...rest}>
    {children}
  </Wrapper>
);

SimpleListScroll.propTypes = {
  children: PropTypes.any,
  maxHeight: PropTypes.number,
  border: PropTypes.bool, // render a border on top and bottom of the scroll area
};

export default SimpleListScroll;
