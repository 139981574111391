import { ColumnFiltersState, SortingState } from '@tanstack/react-table';

import {
  AdWordsItem,
  AppQuery,
  Country,
  KgMetrics,
  KnowledgeGraph,
  KnowledgeGraphItem,
  Language,
  OrganicSerpItemPageKgData,
  SeoConnect,
  SeoSerpPageResponse,
  SerpData,
  Topic,
  WebsiteSerpItemPagesKgData,
  SerpUserIntentDataResponse,
} from '@marketmuse/config/types/papi';
import { NetworkStatus } from '@marketmuse/config/types';
import { AppSnapshotInput } from '@marketmuse/config/types/applications';
import {
  AppQueryParams,
  AppUiSliceState,
  AppQueryNetworkKeys,
  AppQueryNetworkStatus,
} from './appQuery';

export interface ResearchDataInput extends AppQueryParams {
  networkDomains?: string[];
  competitorDomains?: string[];
}

export interface ResearchDataBulkAwInput {
  country: Country;
  language: Language;
  terms: Array<string>;
}

export type ResearchDataConnectInput = Pick<
  ResearchDataInput,
  'networkDomains' | 'competitorDomains'
>;

export type ResearchSnapshotInput = AppSnapshotInput<ResearchDataInput>;

export enum ResearchViews {
  CONNECT = 'connect',
  HEATMAP = 'heatmap',
  KEYWORDS = 'keywords',
  QUESTIONS = 'questions',
  REFLECT = 'reflect',
  TOPIC_MODEL = 'topicModel',
  XRAY = 'xray',
}

export type ResearchXrayFilterKeys =
  | 'facebook'
  | 'youtube'
  | 'wikipedia'
  | 'amazon';

export type ResearchTopicNavigatorViewUpdate = {
  view:
    | ResearchViews.REFLECT
    | ResearchViews.QUESTIONS
    | ResearchViews.KEYWORDS
    | ResearchViews.TOPIC_MODEL;
  filters?: ColumnFiltersState;
  sort?: SortingState;
};

export type ViewHeatmapFiltersState = {
  include: string;
  exclude: Array<string>;
};

export type ViewSerpXrayFiltersState = {
  predefinedFilters: Record<ResearchXrayFilterKeys, 'include' | 'exclude'>;
  exclude: Array<string>;
};

export type ViewHeatmapSliceState = {
  filters: ViewHeatmapFiltersState;
  sort: SortingState;
  mode: 'serp' | 'website';
};

export type ViewTableSliceState = {
  filters: ColumnFiltersState;
  sort: SortingState;
};

export type ViewSerpXraySliceState = {
  filters: ViewSerpXrayFiltersState;
};

export type ViewGenericSliceState = {
  filters: object;
  sort: object;
};

export type ResearchUiSliceState = AppUiSliceState<ResearchDataInput>;

export interface ResearchKGItem extends KnowledgeGraphItem {
  volume?: number;
  existsInInventory?: boolean;
}
export interface ResearchAdWordsItem extends AdWordsItem {
  existsInInventory?: boolean;
}

export interface ResearchInventoryTopic
  extends Pick<
    Topic,
    | '__typename'
    | 'term'
    | 'topRankingPage'
    | 'topRankingPageTitle'
    | 'maxRank'
    | 'mmPersonalizedDifficulty'
    | 'mmPersonalizedAdvantage'
  > {
  existsInInventory: boolean;
  volume?: ResearchAdWordsItem['volume'];
}

export type ResearchHeatmapPage =
  | OrganicSerpItemPageKgData
  | WebsiteSerpItemPagesKgData;

export type ResearchTopicNavigatorItem =
  | ResearchKGItem
  | ResearchAdWordsItem
  | ResearchInventoryTopic;

export interface ResearchKnowledgeGraph
  extends Pick<KnowledgeGraph, '__typename'> {
  knowledgeGraphItems?: ResearchKGItem[];
  metrics?: KgMetrics;
  organicSerpItemPagesKgData?: OrganicSerpItemPageKgData[];
  userProvidedPage?: OrganicSerpItemPageKgData;
}

export interface ResearchNetworkStatus extends AppQueryNetworkStatus {
  [AppQueryNetworkKeys.adwordsKeywords]: NetworkStatus;
  [AppQueryNetworkKeys.adwordsQuestions]: NetworkStatus;
  [AppQueryNetworkKeys.inventoryTopicsKeywords]: NetworkStatus;
  [AppQueryNetworkKeys.inventoryTopicsKG]: NetworkStatus;
  [AppQueryNetworkKeys.inventoryTopicsBulkKeywords]: NetworkStatus;
  [AppQueryNetworkKeys.inventoryTopicsQuestions]: NetworkStatus;
  [AppQueryNetworkKeys.inventoryTopicsSerpQuestions]: NetworkStatus;
  [AppQueryNetworkKeys.linkingRecommendations]: NetworkStatus;
  [AppQueryNetworkKeys.serpData]: NetworkStatus;
  [AppQueryNetworkKeys.serpPages]: NetworkStatus;
  [AppQueryNetworkKeys.serpQuestions]: NetworkStatus;
  [AppQueryNetworkKeys.serpUserIntentData]: NetworkStatus;
  [AppQueryNetworkKeys.websiteHeatmap]: NetworkStatus;
}

export type ResearchNetworkData = {
  adwordsKeywords?: ResearchAdWordsItem[];
  adwordsQuestions?: ResearchAdWordsItem[];
  bulkAdwordsKeywords?: AdWordsItem[];
  inventoryTopics?: ResearchInventoryTopic[];
  knowledgeGraph?: ResearchKnowledgeGraph;
  linkingRecommendations?: SeoConnect;
  serpData?: SerpData;
  serpPages?: SeoSerpPageResponse;
  serpQuestions?: ResearchAdWordsItem[];
  serpUserIntentData?: SerpUserIntentDataResponse;
  websiteHeatmap?: WebsiteSerpItemPagesKgData[];
};

export type ResearchViewModel = {
  [ResearchViews.HEATMAP]: ViewHeatmapSliceState;
  [ResearchViews.KEYWORDS]: ViewTableSliceState;
  [ResearchViews.QUESTIONS]: ViewTableSliceState;
  [ResearchViews.REFLECT]: ViewTableSliceState;
  [ResearchViews.TOPIC_MODEL]: ViewTableSliceState;
  [ResearchViews.XRAY]: ViewSerpXraySliceState;
};

export type SliceTypeAppResearch = {
  ui: ResearchUiSliceState;
  data: ResearchNetworkData;
  network: ResearchNetworkStatus;
  views: ResearchViewModel;
};

export type ResearchDataWithInventoryItems =
  | 'adwordsKeywords'
  | 'adwordsQuestions'
  | 'bulkAdwordsKeywords'
  | 'knowledgeGraph'
  | 'serpQuestions';

export type ResearchThunkSeoRes = {
  data: any;
  params?: ResearchDataInput;
};

export type ResearchAppQueryUpsertRes = {
  data: AppQuery;
  params: ResearchDataInput;
};

export type ResearchThunkInvItemsInput = {
  siteId: string;
  items: Array<ResearchAdWordsItem | ResearchKGItem>;
};

export type ResearchThunkInvItemsRes = {
  data: ResearchInventoryTopic[];
};

export interface ResearchThunkInvPageTopicInput {
  siteId: string;
  term: string;
  url: string;
}
