import React from 'react';
import PropTypes from 'prop-types';

import { Rank as RankBase } from '@marketmuse/components';

import withTailwindCompatibility from '../../hocs/withTailwindCompatibility';

const RankUI = withTailwindCompatibility({
  Component: RankBase,
  style: { width: 'initial' },
  as: 'span',
});

const Rank = ({ style, value, height = 28, className }) => {
  return (
    <RankUI style={style} size={height} value={value} className={className} />
  );
};

Rank.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
};

Rank.defaultProps = {
  style: {},
};

export default Rank;
