import React from 'react';
import styled from 'styled-components';

import { Collapse } from 'react-collapse/lib/Collapse';
import SimpleTextInput from '../../components/SimpleTextInput/SimpleTextInput';
import Icon from '../../components/Icon';


export const IconStyled = styled(Icon)`
  font-size: 16px;
  margin-right: 8px;
`;

export const Requirements = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: ${p => p.theme.spacing.regular};
  margin-bottom: ${p => p.theme.spacing.regular};
`;

export const Req = styled.li`
  font-size: 12px;
  padding: 0 0 7px;
  display: flex;
  align-items: center;
  ${p =>
    !p.valid &&
    p.showErrors &&
    `
    color: ${p.theme.colors.red};
  `}

  ${IconStyled} {
    display: inline-block;
    color: ${p => (p.valid ? p.theme.mmxBlue : p.theme.colors.grey15)};

    ${p =>
      !p.valid &&
      p.showErrors &&
      `
      color: currentColor;
    `}
  }

  em {
    font-style: normal;
    color: ${p => p.theme.colors.grey60};
  }
`;

export const SimplePassword = ({
  hideRequirementsWithoutValue,
  targetValue,
  showErrors,
  error,
  ...props
}) => {
  const specChars = /[!@#$%^&*]/;
  const lowerChar = /[a-z]/;
  const upperChar = /[A-Z]/;
  const numberChar = /[0-9]/;

  const specialCharacterValid = password => specChars.test(password);
  const lowerCharacterValid = password => lowerChar.test(password);
  const upperCharacterValid = password => upperChar.test(password);
  const numberCharacterValid = password => numberChar.test(password);
  const characterLengthValid = password => password.length >= 8;

  const requirements = [
    {
      text: (
        <>
          Special Character&nbsp;<em>(!@#$%^&*)</em>
        </>
      ),
      isValid: specialCharacterValid(targetValue),
    },
    {
      text: 'Lower case letter',
      isValid: lowerCharacterValid(targetValue),
    },
    {
      text: 'Upper case letter',
      isValid: upperCharacterValid(targetValue),
    },
    {
      text: 'Number',
      isValid: numberCharacterValid(targetValue),
    },
    {
      text: '8 character minimum',
      isValid: characterLengthValid(targetValue),
    },
  ];
  return (
    <>
      <SimpleTextInput targetValue={targetValue} {...props} />
      <Collapse isOpened={hideRequirementsWithoutValue ? !!targetValue : true}>
        <Requirements>
          {requirements.map((r, i) => (
            <Req key={i} valid={r.isValid} showErrors={showErrors}>
              <IconStyled name="checkmark" size="16" />
              {r.text}
            </Req>
          ))}
        </Requirements>
      </Collapse>
    </>
  );
};

export default SimplePassword;
