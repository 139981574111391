import typography from '../lib/typography';

export default {
  heading1: typography.heading1,
  heading2: typography.heading2,
  heading3: typography.heading3,
  heading4: typography.heading4,
  heading5: typography.heading5,
  heading6: typography.heading6,
  textSmall: typography.textSmall,
  textRegular: typography.textRegular,
  textLarge: typography.textLarge,
  richText: typography.richText,
};
