import { ContentScoreArgs } from '../types';
import calculate from './calculate';

const textColor = (...args: ContentScoreArgs) => {
  const color = calculate(...args);
  if (color === 'red') return 'text-red-400';
  if (color === 'yellow') return 'text-yellow-400';
  if (color === 'green') return 'text-green-400';
  if (color === 'blue') return 'text-blue-400';
  return 'text-gray-500';
};

export default textColor;
