export const TABS_CONTENT = {
  competitors: {
    title: 'Manage Your Competitors',
    description:
      'MarketMuse will recognize these sites as competitors and use that information to supply linking suggestions',
    placeholder: 'Enter a competitor domain here',
    listHeader: 'competitors',
  },
  network: {
    title: 'Manage Your Network',
    description:
      'MarketMuse will recognize these sites as being in your network and use that information to supply linking suggestions',
    placeholder: 'Enter a network domain here',
    listHeader: 'domains in your network',
  },
};
