import { ApolloQueryResult } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import { isEmpty } from 'lodash';

import { toast } from '@marketmuse/components';
import { Permission, PermissionsQuery } from '@marketmuse/config/types/papi';
import { PermissionsDocument } from '@marketmuse/data-papi';
import { permissionsActions } from '@marketmuse/data-state/permissions';
import { AppDispatch } from '@marketmuse/data-state/types';
import { errorsToString } from '@marketmuse/utilities';

import { apolloClient } from '../config/apollo/clients';

export async function fetchPermissions(dispatch: AppDispatch, siteId: string) {
  let permissions: Permission[] = [];
  try {
    const response: ApolloQueryResult<PermissionsQuery> =
      await apolloClient().query({
        fetchPolicy: 'no-cache',
        query: PermissionsDocument,
        variables: {
          siteId,
        },
      });

    permissions = response?.data?.permissions;
    if (response.errors && isEmpty(permissions)) {
      throw new Error(
        errorsToString(response.errors as unknown as Array<Error>),
      );
    }

    dispatch(permissionsActions.setPermissions(permissions));
  } catch (error) {
    dispatch(permissionsActions.clearPermissions());

    Sentry.captureException(error);
    toast.error('Failed to fetch permissions!');
  }

  return permissions;
}
