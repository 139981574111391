import { get, isNil, isEmpty } from 'lodash';

import isAppDataCached from './isAppDataCached';

export default ({
  apps, // object - the redux state for apps
  appId, // string
  loading, // bool
  setParams, // fn
  setAppData, // fn
  setShowResults, // fn
  dataRequirements // string arr
}) => {

  // grab configuration key from redux state
  const savedConfigKey = get(apps, appId);

  // if app is currently loading or is active,
  // enable results section
  if (loading || !isNil(savedConfigKey)) {
    setShowResults(true);
  }

  if (!isNil(savedConfigKey)) {

    // grab cached data stored under the config key
    const cached = apps[savedConfigKey] || {};

    // save parameters to local app state
    if (!isEmpty(cached.params)) setParams(cached.params);

    // save data to local app state
    if (!isEmpty(cached.data) && isAppDataCached(cached.data, dataRequirements)) {
      setShowResults(true)
      setAppData(cached.data);
    }

    if (loading) {
      setShowResults(true);
    }
  }
}
