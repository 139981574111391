import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import RequiredStar from './RequiredStar';

const LabelWrapper = styled.span`
  display: flex;
  align-items: center;
  min-width: 100%;
  margin-bottom: ${p => p.theme.spacing.xSmall};
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: ${p => p.theme.grey70};

  > span {
    color: ${p => p.theme.grey60};
    font-weight: bold;
  }
`;

const Label = props => (
  <LabelWrapper style={props.style} className={props.className}>
    {props.required && <RequiredStar>*</RequiredStar>}
    {props.children}
  </LabelWrapper>
);

Label.propTypes = {
  children: PropTypes.any,
  required: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default Label;
