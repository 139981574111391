import { useMemo } from 'react';
import {
  allowedCountries,
  countriesWithLanguageSupport,
} from '../../../config/countries';

const mapCountries = countries =>
  countries.map(country => ({
    ...country,
    tags: {
      'data-mms--app-country-option': country.id,
    },
  }));

export function useCountries(canSetLanguage) {
  return useMemo(() => {
    const countries = canSetLanguage
      ? countriesWithLanguageSupport
      : allowedCountries;

    return mapCountries(countries);
  }, [canSetLanguage]);
}
