import { Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import { lazyLoad } from '@marketmuse/utilities';
import { PAPI } from '@marketmuse/config/types';

import ScreenLoader from '../components/ScreenLoader';
import withAccess from '../hocs/withAccess';

const NewsroomComponent = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'Newsroom' */ '../containers/Tools/Newsroom/Newsroom'
    ),
);

const OptimizeComponent = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'OptimizeLoader' */ '../containers/Tools/Optimize/Optimize'
    ),
);

const ResearchComponent = lazyLoad(
  () =>
    import(/* webpackChunkName: 'Research' */ '../containers/Tools/Research'),
);

const Newsroom = withAccess(NewsroomComponent, {
  allow: [
    {
      resource: PAPI.PermissionResource.NEWSROOM,
      action: PAPI.PermissionAction.ACCESS_DATA,
    },
  ],
});

const Optimize = withAccess(OptimizeComponent, {
  allow: [
    {
      resource: PAPI.PermissionResource.OPTIMIZE,
      action: PAPI.PermissionAction.ACCESS_DATA,
    },
  ],
});

const Research = withAccess(ResearchComponent, {
  allow: [
    {
      resource: PAPI.PermissionResource.RESEARCH_APPLICATION,
      action: PAPI.PermissionAction.ACCESS_DATA,
    },
  ],
});

export const routes = [
  {
    path: 'optimize/:view?',
    element: (
      <Suspense fallback={<ScreenLoader.Light unwrapped={true} />}>
        <Optimize />
      </Suspense>
    ),
  },
  {
    path: 'newsroom/:view?',
    element: (
      <Suspense fallback={<ScreenLoader.Light unwrapped={true} />}>
        <Newsroom />
      </Suspense>
    ),
  },
  {
    path: 'research/*',
    element: (
      <Suspense fallback={<ScreenLoader.Light unwrapped={true} />}>
        <Research />
      </Suspense>
    ),
  },
  {
    path: 'research',
    element: <Navigate to={'research/topic-navigator/topic-model'} />,
  },
];
