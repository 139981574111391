import { isNil } from 'lodash';
import {
  Color,
  DifficultyCalculateFn,
  DifficultyCalculateReturnType,
} from '../types';

const calculateDifficultyRelative = (
  score: number,
  relativeScore: number,
): DifficultyCalculateReturnType => {
  let color = 'gray' as Color;
  let text = 'N/A';

  if (score === -1 || relativeScore === -1)
    return {
      band: 0,
      text,
      color,
    };

  const band = score - relativeScore;
  let bandLow = null;
  let bandHigh = null;

  if (band >= 50) {
    text = 'Much Easier';
    color = 'green';
    bandLow = relativeScore;
    bandHigh = score;
  }
  if (band >= 1 && band < 50) {
    text = 'Easier';
    color = 'green';
    bandLow = relativeScore;
    bandHigh = score;
  }
  if (band <= -1 && band >= -15) {
    text = 'Harder';
    color = 'red';
    bandLow = score;
    bandHigh = relativeScore;
  }
  if (band < -15) {
    text = 'Much Harder';
    color = 'red';
    bandLow = score;
    bandHigh = relativeScore;
  }
  if (band === 0) {
    text = 'Same';
    bandLow = score;
    bandHigh = relativeScore;
  }

  return {
    color,
    band,
    text,
    bandLow,
    bandHigh,
  };
};

const calculateDifficultyAbsolute = (
  score: number,
): { color: Color; text: string } => {
  let color = 'gray' as Color;
  let text = 'N/A';

  if (score > 0 && score <= 33) {
    text = 'Easy';
    color = 'green';
  }
  if (score <= 66 && score > 33) {
    text = 'Medium';
    color = 'orange';
  }
  if (score > 66) {
    text = 'Hard';
    color = 'red';
  }
  if (score === 0) {
    text = 'None';
  }

  return { text, color };
};

const calculate: DifficultyCalculateFn = (
  score,
  relativeScore,
): DifficultyCalculateReturnType => {
  const scoreInt = Number.isFinite(score) ? score : -1;

  if (isNil(relativeScore)) {
    return calculateDifficultyAbsolute(scoreInt);
  } else {
    const relativeScoreInt = Number.isFinite(relativeScore)
      ? relativeScore
      : -1;
    return calculateDifficultyRelative(scoreInt, relativeScoreInt);
  }
};

export default calculate;
