import { useCallback } from 'react';

import { Text, toast } from '@marketmuse/components';

const useToaster = () => {
  const addedToInventoryToast = () => {
    toast.success(
      <>
        <Text>Items have been added to your inventory.</Text>
        {/* <Button size="xs" variant="blue400" onClick={() => navigate(url)}>
          View Inventory
        </Button> */}
      </>,
    );
  };

  const inventoryNotReady = () => {
    toast.neutral(
      <Text>
        Sorry about this, but your inventory isn't yet ready for use.
      </Text>,
    );
  };

  const premiumInventoryToast = () => {
    toast.neutral(
      <Text>
        To gain access to your own inventory and personalized insights, upgrade
        your plan.
      </Text>,
    );
  };

  const premiumFeatureToast = () => {
    toast.neutral(
      <>
        <Text className="font-bold">This is a premium feature</Text>
        <Text as="p">
          Get access to more workflows, briefs, and content. Talk to a
          strategist today to unlock even more power from MarketMuse.
        </Text>
      </>,
    );
  };

  const exportSuccessfulToast = useCallback(() => {
    toast.success(
      <Text>Export successful. Your download will begin shortly.</Text>,
    );
  }, []);

  return {
    addedToInventoryToast,
    inventoryNotReady,
    exportSuccessfulToast,
    premiumInventoryToast,
    premiumFeatureToast,
  };
};

export default useToaster;
