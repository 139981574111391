import cx from 'classnames';
import * as React from 'react';
import { twMerge } from 'tailwind-merge';
import { Text } from '../Text';

interface LabelProps
  extends Omit<React.HtmlHTMLAttributes<HTMLSpanElement>, 'className'> {
  className?: string | Array<string | string[]>;
}

interface SelectElProps<V>
  extends Omit<React.HtmlHTMLAttributes<HTMLSelectElement>, 'className'> {
  className?: string | Array<string | string[]>;
  disabled?: boolean;
  value?: V;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

type OptionBase = {
  id: string;
  label: string;
  disabled?: boolean;
};

type Option = OptionBase & {
  items?: Array<OptionBase>;
};

export interface SelectProps<V> {
  name: string;
  label: string;
  labelProps?: LabelProps;
  items: Array<Option>;
  selectProps?: SelectElProps<V>;
}

const OptionElement = ({ id, label, disabled }: Option) => (
  <option value={id} disabled={disabled}>
    {label}
  </option>
);

export const Select = <V extends string>({
  name,
  label,
  selectProps,
  labelProps,
  items,
}: SelectProps<V>) => {
  const { className: _selectClassName, ...restSelectProps } = selectProps || {};
  const { className: labelClassName, ...restLabelProps } = labelProps || {};
  const selectClassName =
    typeof _selectClassName === 'string'
      ? _selectClassName
      : cx(_selectClassName);
  return (
    <label>
      <Text
        className={twMerge(
          'block',
          'text-sm',
          'font-bold',
          'text-gray-700',
          'mb-1',
          labelClassName,
        )}
        {...restLabelProps}
      >
        {label}
      </Text>

      <select
        id={name}
        name={name}
        className={twMerge(
          'shadow-sm',
          'focus:ring-blue-400',
          'focus:border-blue-400',
          'block',
          'w-full',
          'sm:text-sm',
          'border-gray-400',
          'rounded',
          selectClassName,
        )}
        {...restSelectProps}
      >
        {items.map(item => {
          if (!item.items) {
            return <OptionElement key={item.id} {...item} />;
          }
          return (
            <optgroup key={item.id} label={item.label}>
              {item.items.map(subItem => (
                <OptionElement key={subItem.id} {...subItem} />
              ))}
            </optgroup>
          );
        })}
      </select>
    </label>
  );
};
