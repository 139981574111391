// Former 'default' theme, 'dynamic' theme is now the default
// as it appears to be the direction headed in `system--master`

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Label from '../components/Label';
import BaseWrapper from './base/BaseWrapper';
import BaseInput from './base/BaseInput';
import BaseTextarea from './base/BaseTextarea';

const FloatingLabel = styled(Label)`
  top: 0;
  position: absolute;
  padding: 0 ${p => p.theme.spacing.small};
  margin: 0;
  color: ${p => p.theme.colors.grey50};
  font-size: 12px;
  font-weight: normal;
  line-height: 1.4;
  border-radius: ${p => p.theme.borderRadius};
  transform: translateY(11px);
  transition: ${p => p.theme.transition};
  pointer-events: none;
`;


const labelStyleActive = () => css`
  top: 0;
  padding: 0 4px;
  margin: 0 8px;
  font-size: 9px;
  transform: translateY(-50%);
  color: ${p => p.theme.colors.grey60};
  background-color: white;
`;

const inputStyleShared = () => css`
  ${p => p.theme.type.textRegular}
  color: ${p => p.theme.colors.grey60};

  &:not(:disabled) {
    &:active ~ ${FloatingLabel}, &:focus ~ ${FloatingLabel} {
      ${labelStyleActive}
    }
  }
`;

export const DarkInput = styled(BaseInput)`
  ${inputStyleShared}
  height: ${p => p.theme.input.dark.minHeight}px;
  min-height: inherit;
`;

export const DarkTextarea = styled(BaseTextarea)`
  ${inputStyleShared}
  min-height: 56px;
  height: inherit;
  max-width: 100%;
  resize: ${p => (p.disabled ? 'none' : 'vertical')};
`;

const Wrapper = styled(BaseWrapper)`
  color: ${p => p.theme.input.dark.color};
  background-color: ${p => p.theme.input.dark.backgroundColor};
  ${p => p.disabled && `opacity: 0.5;`}

  ${p => p.error && `
    ${DarkInput}, ${DarkTextarea} {
      border: 2px solid ${p.theme.mmxRed};
      border-radius: inherit;
    }
  `}
`;

const DarkWrapper = ({ children, ...props } = {}) => (
  <Wrapper {...props}>
    {children}
  </Wrapper>
);

DarkWrapper.propTypes = {
  children: PropTypes.any,
};

export default DarkWrapper;
