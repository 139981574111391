import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isNil from 'lodash/isNil';

import { statBandContentScore } from '@marketmuse/utilities';
const { calculate } = statBandContentScore;

const Wrapper = styled.div`
  font-size: 9pt;
  font-weight: bolder;
  color: ${p => p.theme[p.color || 'mmxDarkGrey']};
`;

const Separator = styled.span`
  font-weight: bolder;
  color: ${p => p.theme[p.color || 'mmxDarkGrey']};
`;

const ContentScore = ({
  forceFallback,
  displayTarget,
  value,
  average,
  target,
  best,
  style = {},
  className,
} = {}) => {
  const csColor = calculate(value, average, target, best);
  const csColorTarget = calculate(target, average, target, best);

  let displayValue = isNil(value) ? '-' : Math.round(value);
  if (!csColor && !forceFallback) displayValue = '-';

  let displayValueTarget = isNil(target) ? '-' : Math.round(target);
  if (!csColorTarget && !forceFallback) displayValueTarget = '-';

  return (
    <>
      <Wrapper style={style} color={csColor} className={className}>
        {displayValue}
      </Wrapper>

      {displayTarget && displayValueTarget !== '-' && displayValue !== '-' && (
        <>
          <Separator color={csColorTarget} style={{ marginLeft: 6 }}>
            {' / '}
          </Separator>

          <Wrapper
            color={csColorTarget}
            className={className}
            style={{
              ...style,
              marginLeft: 2,
              fontSize: 11,
            }}
          >
            {displayValueTarget}
          </Wrapper>
        </>
      )}
    </>
  );
};

ContentScore.propTypes = {
  value: PropTypes.number,
  average: PropTypes.number,
  target: PropTypes.number,
  best: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
  forceFallback: PropTypes.bool,
  displayTarget: PropTypes.bool,
};

ContentScore.defaultProps = {
  forceFallback: false,
};

export default ContentScore;
