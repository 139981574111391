import { useEffect, useState } from 'react';
import { captureMessage } from '@sentry/react';
import { useSession, useSessionList } from '@clerk/clerk-react';
import { TOKEN_KEYS } from '@marketmuse/config/configs';
import { toast } from '@marketmuse/components';

import useLogout from '../../hooks/auth/useLogout';

const NOT_ACTIVE =
  'Your session is no longer active. Please log in again to continue.';
const EXPIRED = 'Your session has expired. Please log in again to continue.';
const REVOKED =
  'For security purposes users may have a maximum of 2 concurrent sessions.';

const toastOptions = {
  duration: 20000,
  id: 'auth-error',
};

const useSessionData = () => {
  const [sessionId, setSessionId] = useState<string | null>(null);
  const handleLogout = useLogout();
  const { session, isLoaded, isSignedIn } = useSession();
  const { sessions } = useSessionList();

  useEffect(() => {
    if (isLoaded && isSignedIn && session && !sessionId) {
      sessionStorage.removeItem(TOKEN_KEYS.GHOST);
      sessionStorage.removeItem(TOKEN_KEYS.GUEST);
      setSessionId(session.id);
    }
  }, [isLoaded, isSignedIn, session, sessionId]);

  useEffect(() => {
    const current = sessions?.find(s => s && s.id === sessionId);
    const isExpired = current?.status === 'expired';
    const isRevoked = current?.status === 'revoked';
    const isActive = current?.status === 'active';

    if (!isLoaded || !sessions.length || isActive || !current || !sessionId) {
      return;
    }

    let message = NOT_ACTIVE;
    if (isRevoked) {
      message = REVOKED;
    } else if (isExpired) {
      message = EXPIRED;
    }

    toast.error(message, toastOptions);

    captureMessage(`Session ended - ${current.status}`, {
      level: 'log',
      fingerprint: ['session-ended-early'],
      tags: { 'session-ended': current.status },
    });

    if (!isSignedIn) {
      setSessionId(null);
      handleLogout();
    }
  }, [isSignedIn, isLoaded, sessions, sessionId, handleLogout]);

  return session?.id;
};

export default useSessionData;
