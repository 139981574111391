import { ApolloClient, from } from '@apollo/client';
import { LocalStorageWrapper, CachePersistor } from 'apollo3-cache-persist';
import {
  recurlyEnvLink,
  cleanTypenameLink,
  errorLink,
  httpLink,
  IDataClientOptions,
  retryLink,
} from '@marketmuse/data-client';
import schema from '../generated/schema.graphql';
import cache from './cache';
import defaultOptions from './defaultOptions';

const PERSIST_LAST_PURGE_KEY = 'PERSIST_LAST_PURGE_KEY';
const PERSIST_TTL = 24 * 1000 * 60 * 60; // 1 day

export const apolloCachePapi = cache;

export const apolloClientPapi = ({
  endpoint,
  links = [],
}: IDataClientOptions) => {
  const ssrMode = typeof window === 'undefined';
  const link = from(
    [retryLink, errorLink].concat(
      ...links,
      cleanTypenameLink,
      recurlyEnvLink,
      httpLink({ endpoint }),
    ),
  );

  if (!ssrMode) {
    const lastPurge = localStorage.getItem(PERSIST_LAST_PURGE_KEY);
    const persistor = new CachePersistor({
      cache,
      // Use max 4 MB for persistent cache. Leave 1 MB for other means out of 5 MB available.
      // If exceeded, persistence will pause and app will start up cold on next launch.
      maxSize: 1024 * 1024 * 4,
      key: 'papi-data',
      storage: new LocalStorageWrapper(window.localStorage),
    });

    if (!lastPurge || Number(lastPurge) < Date.now() - PERSIST_TTL) {
      localStorage.setItem(PERSIST_LAST_PURGE_KEY, String(Date.now()));
      persistor
        .purge()
        // eslint-disable-next-line no-console
        .catch(error => console.log(`Cache purge error: ${error}`));
    } else {
      persistor
        .restore()
        // eslint-disable-next-line no-console
        .catch(error => console.log(`Cache restore error: ${error}`));
    }
  }

  return new ApolloClient({
    connectToDevTools: process.env.NODE_ENV !== 'production',
    typeDefs: schema,
    ssrMode,
    link,
    cache,
    name: 'papi',
    defaultOptions: defaultOptions,
  });
};
