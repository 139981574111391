import {
  KnowledgeGraphItem,
  KgMetrics,
  OrganicSerpItemPageKgData,
  AdWordsItemWithVariants,
} from '@marketmuse/config/types/papi';
import { LegacyAppData } from '@marketmuse/config/types/compatibility';
import serializeBulkAdwordsVariants from './serializeBulkAdwordsVariants';

type Params = {
  knowledgeGraphItems: KnowledgeGraphItem[];
  metrics: KgMetrics;
  organicSerpItemPagesKgData: OrganicSerpItemPageKgData[];
  userProvidedPage: OrganicSerpItemPageKgData | undefined;
  bulkAdwordsKeywords: AdWordsItemWithVariants[];
};

const parseToPage = (pages: OrganicSerpItemPageKgData[]) => {
  return pages.reduce((acc: Record<string, any>, page) => {
    const { contentScore, mentions, position, title, url, wordCount } = page;
    acc[url] = {
      cs: contentScore,
      content_score: contentScore, // this is for backward compatibility with getExcel resolver
      mentions: (mentions || []).reduce(
        (acc, { term, count }) => ({ ...acc, [term]: count }),
        {},
      ),
      rank: position,
      title,
      url,
      wc: wordCount,
    };
    return acc;
  }, {});
};

const serializeToAppData = ({
  knowledgeGraphItems,
  metrics,
  organicSerpItemPagesKgData,
  userProvidedPage,
  bulkAdwordsKeywords,
}: Params): LegacyAppData => {
  const kgTopics: { term: string; recommended_mentions: number }[] = [];
  const kgDataWithVariants = knowledgeGraphItems.map(item => {
    kgTopics.push({
      term: item.term,
      recommended_mentions: item.recommendedMentions as unknown as number,
    });

    const { variants = [] } =
      (bulkAdwordsKeywords || []).find(({ term }) => term === item.term) || {};
    return { ...item, variants };
  });

  const { all_variants, variants } = serializeBulkAdwordsVariants(
    kgDataWithVariants || [],
  );

  const pages = userProvidedPage?.url
    ? { ...parseToPage([userProvidedPage]) }
    : {};

  const ranking_pages = parseToPage(organicSerpItemPagesKgData);

  return {
    kg: {
      content_score_max: metrics.highestContentScore,
      content_score_avg: metrics.averageContentScore,
      content_score_target: metrics.targetContentScore,
      word_count_target: metrics.targetWordCount,
    },
    scores: {
      all_variants,
      metrics: {
        cs_avg: metrics.averageContentScore,
        cs_max: metrics.highestContentScore,
        cs_target: metrics.targetContentScore,
        wc_avg: metrics.averageWordCount,
        wc_target: metrics.targetWordCount,
      },
      kg: {
        data: kgTopics,
      },
      pages,
      ranking_pages,
      variants,
    },
  };
};

export default serializeToAppData;
