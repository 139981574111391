import ErrorEvent from '../../ErrorEvent';

class ReportReadError extends ErrorEvent {
  record({ id, error }) {
    super.record('ReportReadError', {
      category: 'Error',
      mainValue: 'Report error',
      newName: 'error--reports--report-read',
      reportId: id,
      error,
    });
  }
}
export default new ReportReadError();
