import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import get from 'lodash/get';
import isNil from 'lodash/isNil';

import {
  getCalculateLeft,
  getIsLastSticky,
  getOrderColumns,
  getCalculateZIndex,
  calculateTotalWidth,
} from '../../utils/tableUtils';

import * as SL from '../SimpleList/themes/app';
import Repeat from '../Repeat';
import Sortable from '../Sortable';
import NoResults, { noResultPropTypes } from '../NoResults';


const NoResultsStyled = styled(NoResults)`
  margin: 60px 0;
  max-width: calc(100vw - 80px);
`;

// TODO: MMS-290
const FooterWrapper = styled.div`
  margin-top: -1px;
  position: sticky;
  left: 0;
  bottom: 0;
  z-index: 2000;
`;

export const SlidingTableWrapper = styled.div.attrs(p => ({
  style: {
    width: p.overlayItemsGap && `calc(100% + ${p.overlayItemsGap}px)`,
  }
}))`
  overflow: auto;
  height: 100%;
  min-width: 100%;
  width: 100%;
`

export const SlidingTableInner = styled.div.attrs(p => ({
  style: {
    width: !p.fullWidth && p.totalWidth
  }
}))`
  width: 100%;
  flex-grow: 1;
`

const SlidingTable = React.forwardRef((props, ref) => {
  const sticky = Object.values(get(props, 'table') || {})
    .filter(c => get(c, 'sticky'))
    .reduce((acc, c) => ({ ...acc, [c.id]: true }), {})

  // eslint-disable-next-line
  // const toggleSticky = getToggleSticky(sticky, setSticky, props.table);
  const calculateLeft = getCalculateLeft(sticky, props.table);
  const isLastSticky = getIsLastSticky(sticky, props.table);
  const orderColumns = getOrderColumns(sticky);
  const calculateZIndex = getCalculateZIndex(sticky, props.table);

  let totalWidth = calculateTotalWidth(props.table);
  if (!isNil(props.overlayItemsGap)) totalWidth += props.overlayItemsGap;
  const tableArr = orderColumns(props.table);

  // render columns
  const renderColumn = (i, item = {}, { colIndex, rowIndex } = {}) => {

    let ItemComponent = SL.Item;
    if (i.overrideItemComponent || i.overrideColItemComponent) {
      ItemComponent = (
        i.overrideItemComponent ||
        i.overrideColItemComponent
      );
    }

    const { style, ...colProps } = (typeof get(i, 'colProps') === 'function' ?
      i.colProps(item) :
      i.colProps
    ) || {};

    const comp = (
      <ItemComponent
        key={`${props.id}_${i.id}`}
        loading={isNil(get(i, 'loading')) ? props.loading : get(i, 'loading')}
        borderRight={isLastSticky(i.id)}
        breakWord
        sticky={i.sticky}
        style={{
          width: i.width,
          flex: i.flexGrow ? 1 : 'inherit',
          left: calculateLeft(i.id),
          zIndex: calculateZIndex(i.id),
          ...(style || {}),
        }}
        {...(get(i, 'props') || {})}
        {...(colProps || {})}
      >
        {(i.colChildren && typeof i.colChildren === 'function') ?
          i.colChildren({ item, self: i, colIndex, rowIndex }) :
          null}
      </ItemComponent>
    )

    if (typeof i.colComponent === 'function') {
      return i.colComponent({ item, self: i, key: `${props.id}_${i.id}`, component: comp, colIndex, rowIndex });
    }

    return comp;
  }

  // render headers
  const renderHeader = i => {

    let ItemComponent = SL.Item;
    if (i.overrideItemComponent || i.overrideHeaderItemComponent) {
      ItemComponent = (
        i.overrideItemComponent ||
        i.overrideHeaderItemComponent
      );
    }

    const { style, ...headerProps } = get(i, 'headerProps') || {};

    const comp = (
      <ItemComponent
        header
        id={`${props.id}_${i.id}`}
        key={`${props.id}_${i.id}`}
        sticky={i.sticky}
        borderRight={isLastSticky(i.id)}
        style={{
          width: i.width,
          flex: i.flexGrow ? 1 : 'inherit',
          left: calculateLeft(i.id),
          zIndex: calculateZIndex(i.id, true),
          ...(style || {}),
        }}
        {...(get(i, 'props') || {})}
        {...(headerProps || {})}
      >
        {/* non sortable header */}
        {typeof i.headerChildren === 'function' && isNil(i.sortField) && (
          i.headerChildren({ self: i })
        )}

        {/* sortable header */}
        {typeof i.headerChildren === 'function' && !isNil(i.sortField) && (
          <Sortable
            active={get(props, 'sort.field') === i.sortField}
            direction={get(props, 'sort.direction')}
            onChange={direction => {
              props.sortChanged({ direction, field: i.sortField });
            }}
          >
            {i.headerChildren({ self: i })}
          </Sortable>
        )}
      </ItemComponent>
    );

    if (typeof i.headerComponent === 'function') {
      return i.headerComponent({ self: i, component: comp });
    }

    return comp;
  }

  return (
    <SlidingTableWrapper
      overlayItemsGap={props.overlayItemsGap}
      className={props.className}
      ref={ref}
    >
      <SlidingTableInner fullWidth={props.fullWidth} totalWidth={totalWidth}>
        {/* header */}
        <SL.Row header sticky style={{ top: 0, zIndex: 999999 }}>
          {tableArr.map(i => renderHeader(i))}
        </SL.Row>

        {/* loading rows */}
        {props.loading && (
          <Repeat
            n={props.loadingCount || 30}
            id={`${props.id}-loader-repeat`}
            component={SL.Row}
            props={{
              children: tableArr.map((i, colIndex) => renderColumn(i, null, { colIndex})),
              ...(typeof props.rowProps === 'function' ? props.rowProps(null) : {})
            }}
          />
        )}

        {/* no data row */}
        {!props.loading && (!props.items || props.items.length === 0) && (
          <NoResultsStyled
            imgSrc={get(props, 'emptyState.imgSrc')}
            imgAlt={get(props, 'emptyState.imgAlt')}
            title={get(props, 'emptyState.title') || "Something doesn't look right here"}
            text={get(props, 'emptyState.text')}
            cta={get(props, 'emptyState.cta')}
            ctaClick={get(props, 'emptyState.ctaClick')}
          />
        )}

        {/* rows */}
        {!props.loading && props.items && props.items.length > 0 && props.items.map((item, rowIndex) => (
          <SL.Row
            key={item.id}
            onClick={(props.loading || typeof props.onRowClick !== 'function') ? null : () => {
              props.onRowClick(item)
            }}
            active={get(props.selectedItems, item.id) === true || props.forceShowOverlayItems === item.id}
            overlayItemsActive={props.forceShowOverlayItems === item.id}
            overlayItemProps={{ style: {
              right: props.overlayItemsGap || 0,
              position: props.fullWidth && 'absolute',
              top: props.fullWidth && '50%',
              transform: props.fullWidth && 'translateY(-50%)'
            } }}
            overlayItems={typeof props.overlayItems === 'function' ?
              props.overlayItems(item) : null}
            {...(typeof props.rowProps === 'function' ? props.rowProps(item) : {})}
          >
            {tableArr.map((i, colIndex) => renderColumn(i, item, { rowIndex, colIndex }))}
            {!isNil(props.overlayItemsGap) && (
              renderColumn({
                id: 'overlay',
                width: props.overlayItemsGap,
                sticky: true,
                style: {
                  right: 0
                },
              }, null, { rowIndex })
            )}
          </SL.Row>
        ))}

      </SlidingTableInner>

      {!isNil(props.footer) && (
        <FooterWrapper
          style={{
            width: `calc(100% - ${props.overlayItemsGap || 0}px)`,
          }}
        >
          {props.footer}
        </FooterWrapper>
      )}
    </SlidingTableWrapper>
  )
});

SlidingTable.propTypes = {

  // unique id of the table
  id: PropTypes.string.isRequired,

  // array of items to be rendered
  items: PropTypes.array,

  // runs when you click on a row
  onRowClick: PropTypes.func,

  // attach props to row items
  rowProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),

  // return overlay items for each row - receives item
  overlayItems: PropTypes.func,
  forceShowOverlayItems: PropTypes.bool,
  // approximate width of the overlay items
  overlayItemsGap: PropTypes.number,

  // current sorting for the table
  sort: PropTypes.shape({
    direction: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
  }),

  // sort change callback, receives new sort
  sortChanged: PropTypes.func.isRequired,

  loading: PropTypes.bool,

  // Number of rows to show as loading
  loadingCount: PropTypes.number,

  // render the table footer
  footer: PropTypes.any,

  // details for non-ideal state
  emptyState: PropTypes.shape(noResultPropTypes),

  // table configuration
  table: PropTypes.objectOf(PropTypes.shape({

    // id of the column
    id: PropTypes.string,

    // override global table loading
    loading: PropTypes.bool,

    // override SL.Item props
    // ps. colProps could be an object, or function that takes the item
    // and returns an object.
    props: PropTypes.object,
    headerProps: PropTypes.object,
    colProps: PropTypes.oneOfType([ PropTypes.object, PropTypes.func ]),

    // order of the column
    order: PropTypes.number,

    // should column be sticky
    sticky: PropTypes.bool,

    // with of the cell
    width: PropTypes.number,

    // return only the children of the header item - receives item
    headerChildren: PropTypes.func,

    // return entire header item
    // this could be used with along with `headerChildren` and other props
    // receives argument: { self, component }
    // self: configuration object
    // component: the final header component ready to render. use this
    // if you like to wrap the header around something (ie. Popper)
    headerComponent: PropTypes.func,

    // return entire col item - receives item
    colChildren: PropTypes.func,

    // return only the children of the col item - receives item
    // this could be used with along with `colChildren` and other props
    // receives argument: { self, component }
    // self: configuration object
    // component: the final col component ready to render. use this
    // if you like to wrap the header around something (ie. Popper)
    colComponent: PropTypes.func,

    // column is sortable by this field
    sortField: PropTypes.string,

    // Instead of using the default SL.Item, use a
    // custom modified version of SL.Item
    overrideItemComponent: PropTypes.node,
    overrideHeaderItemComponent: PropTypes.node,
    overrideColItemComponent: PropTypes.node,
  }).isRequired).isRequired
};

SlidingTable.defaultProps = {
  items: [],
  selectedItems: {},
}

export default SlidingTable;
