import { pick, camelCase, isEmpty, isNil, get } from 'lodash';
import download from 'downloadjs';
import { GetExcelDocument } from '@marketmuse/data-papi';

import Toast from '../../components/Toast';

import * as types from '../../config/types';
import makeRequest from '../../utils/makeRequest';

const camelCaseKeys = input =>
  Object.entries(input)
    .map(([key, value]) => ({
      key: camelCase(key),
      value,
    }))
    .filter(item => !isEmpty(item.value) || !isNil(item.value))
    .reduce((acc, current) => ({ ...acc, [current.key]: current.value }), {});

const processDataForPapi = input => {
  const keysParams = [
    'bodyOrArticle',
    'brands',
    'competitorSites',
    'country',
    'language',
    'networkSites',
    'people',
    'topic',
    'url',
  ];

  const keysData = [
    'kg',
    'kgNews',
    'lr',
    'params',
    'qts',
    'reports',
    'scores',
    'scoresNews',
  ];

  const data = camelCaseKeys(input);
  const params = camelCaseKeys(data.params);
  const processedParams = pick(params, keysParams);
  const processedData = pick({ ...data, params: processedParams }, keysData);

  return processedData;
};

export default () =>
  ({ dispatch, getState }) =>
  next =>
  action => {
    if (action.type === types.GET_EXCEL) {
      makeRequest({
        type: types.GET_EXCEL,
        mutation: GetExcelDocument,
        options: { isQuery: true },
        variables: {
          data: processDataForPapi(action.data),
          context: camelCaseKeys(action.context),
          fn: action.fn,
        },
        apolloOptions: {
          context: {
            omitTypename: true,
          },
        },
      }).then(res => {
        const xlData = get(res, 'getExcel', {});
        if (xlData && xlData.data) {
          const mimeType =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          const content = `data:${mimeType};base64,${xlData.data}`;
          const filename = `${action.filename}.xlsx` || 'results.xlsx';
          download(content, filename, mimeType);
        } else {
          Toast.fire('Failed to generate report', 'error');
        }
        if (typeof action.callback === 'function') {
          action.callback();
        }
      });
    }

    return next(action);
  };
