import * as React from 'react';
import { Checkbox } from '../../Forms';
import { useTableContext } from '../useTableContext';

const TableSelectHeader = ({ table }) => {
  const { loading } = useTableContext();
  return (
    <Checkbox
      {...{
        disabled: loading,
        checked: table.getIsAllRowsSelected(),
        indeterminate: table.getIsSomeRowsSelected(),
        onChange: table.getToggleAllRowsSelectedHandler(),
      }}
    />
  );
};

export default TableSelectHeader;
