/* eslint-enable no-unused-vars */
import { useRef, useState, useEffect } from 'react';

// we need a ref to retain timer during renders - cleans up on exit
const clearTimer = handle => {
  if (handle.current) {
    clearTimeout(handle.current);
    handle.current = null;
  }
};

// delayOff: how long to 'keep hovered on mouseOut
// delayOn: how long to 'ignore hover' onMouseEnter
const useHoverDelay = ({ delayOff = 0, delayOn = 0 }) => {
  const [engagedHover, setEngaged] = useState(null);
  const delayHover = useRef(null);
  const releaseHover = useRef(null);

  // perform an action on hover, after delayOn MS
  const hoverEngage = (id = true) => {
    clearTimer(releaseHover);
    delayHover.current = setTimeout(() => setEngaged(id), delayOn);
  };

  // perform an action on blur, after delayOn MS
  const hoverDisengage = () => {
    clearTimer(delayHover);
    releaseHover.current = setTimeout(() => setEngaged(null), delayOff);
  };

  // Clear any timers
  useEffect(() => {
    return () => {
      clearTimer(delayHover);
      clearTimer(releaseHover);
    };
  }, []);

  return {
    engagedHover,
    hoverEngage,
    hoverDisengage,
  };
};

export default useHoverDelay;
