import { useMemo } from 'react';
import { merge } from 'lodash';
import { Bold, Italic, Underline } from '@ckeditor/ckeditor5-basic-styles';
import { AutoLink, Link } from '@ckeditor/ckeditor5-link';
import { Clipboard } from '@ckeditor/ckeditor5-clipboard';
import { GeneralHtmlSupport } from '@ckeditor/ckeditor5-html-support';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { PasteFromOffice } from '@ckeditor/ckeditor5-paste-from-office';

import { EditorConfigInterface } from '@marketmuse/config/types/editor';

function useConfig(config: EditorConfigInterface) {
  return useMemo(() => {
    return merge(
      {},
      {
        licenseKey:
          process.env.REACT_APP_CKEDITOR_LICENSE_KEY ||
          process.env.STORYBOOK_CKEDITOR_LICENSE_KEY,
        plugins: [
          AutoLink,
          Bold,
          Clipboard,
          Essentials,
          GeneralHtmlSupport,
          Italic,
          Link,
          PasteFromOffice,
          Underline,
        ],
        htmlSupport: {
          allow: [
            {
              name: 'span',
              styles: true,
            },
          ],
        },
      },
      config,
    );
  }, [config]);
}

export default useConfig;
