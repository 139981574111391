import { get, omit } from 'lodash';

import COUNTRY_CODES from '../../../config/countryCodes';
import { LANGUAGES_CODES } from '../../../config/countryLanguageCombinations';

const toolsDefaultParams = ({ activeInventory = {} }) => ({
  query: '',
  url: '',
  brand: false,
  people: false,
  bodyOrArticle: 'article',
  country: get(activeInventory, 'defaultSerpCountry') || COUNTRY_CODES.US,
  language: get(activeInventory, 'defaultSERPLanguage') || LANGUAGES_CODES.EN,
  showResults: false,
  appData: null,
});

const setToolsParams = params => ({
  query: (params?.topic || '').trim(),
  url: (params?.url || '').trim(),
  brand: !!params.brands,
  people: !!params.people,
  bodyOrArticle: params.bodyOrArticle || 'article',
});

const extractToolsParams = (state, fieldsToExclude) => {
  const allowedStateFields = fieldsToExclude
    ? omit(state, fieldsToExclude)
    : state;
  return {
    topic: (allowedStateFields?.query || '').trim(),
    url: (allowedStateFields?.url || '').trim(),
    brands: !!allowedStateFields.brand,
    people: !!allowedStateFields.people,
    bodyOrArticle: allowedStateFields.bodyOrArticle,
    country: allowedStateFields.country,
    language: allowedStateFields.language,
  };
};

export { extractToolsParams, setToolsParams, toolsDefaultParams };
