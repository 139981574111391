import styled from 'styled-components';

export default styled.span`
  display: flex;
  align-items: center;
  justify-self: flex-end;
  margin-right: 0;
  padding-left: ${p => p.theme.spacing.small};
  margin-left: auto;
`;
