import get from 'lodash/get';

import { SeoPageProcessorDocument } from '@marketmuse/data-papi';
import * as types from '../../config/types';
import { stripSlash } from '../../utils/misc';
import makeRequest from '../../utils/makeRequest';

export default () => () => next => action => {
  if (action.type === types.DOWNLOAD_PAGE) {
    makeRequest({
      type: types.DOWNLOAD_PAGE,
      mutation: SeoPageProcessorDocument,
      options: { isQuery: true },
      variables: {
        url: stripSlash(action.url),
      },
    }).then(res => {
      // send response over to callback
      const { callback } = action;
      const data = get(res, 'seoPageProcessor', null);
      if (typeof callback === 'function') callback(data);
    });
  }

  return next(action);
};
