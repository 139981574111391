import { AppQueryNetworkStatus, ReduxListener } from '../../../types';

import { researchTaskStatusCheck } from '../../actions';
import { dataSlice } from '../../slices';
import { networkSlice } from '../../slices/network.slice';
import { getAdwordsKeywords } from '../../thunks';

const listeners = (startListening: ReduxListener) => {
  const dataActions = dataSlice.actions;
  const networkActions = networkSlice.actions;

  startListening({
    actionCreator: getAdwordsKeywords.pending,
    effect: async (action, { dispatch }) => {
      await dispatch(
        networkActions.adwordsKeywordsSet(AppQueryNetworkStatus.loading),
      );
    },
  });

  startListening({
    actionCreator: getAdwordsKeywords.rejected,
    effect: async (action, { dispatch }) => {
      const { aborted } = action.meta;
      if (!aborted) {
        await Promise.all([
          dispatch(
            networkActions.adwordsKeywordsSet(AppQueryNetworkStatus.error),
          ),
          dispatch(
            networkActions.inventoryTopicsKeywordsSet(
              AppQueryNetworkStatus.inactive,
            ),
          ),
        ]);

        // check status after other steps
        await dispatch(researchTaskStatusCheck());
      }
    },
  });

  startListening({
    actionCreator: getAdwordsKeywords.fulfilled,
    effect: async ({ payload }, { dispatch }) => {
      await Promise.all([
        dispatch(dataActions.adwordsKeywordsSet(payload.data)),
        dispatch(
          networkActions.adwordsKeywordsSet(AppQueryNetworkStatus.complete),
        ),
      ]);

      // check status after other steps
      await dispatch(researchTaskStatusCheck());
    },
  });
};

export default listeners;
