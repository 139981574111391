import { get, set, isNil } from 'lodash';

import { GetAppDataDocument } from '@marketmuse/data-papi';
import { saveAppConfigState } from '../../actions/toolsActions';
import { setAggregateStats } from '../../actions/siteActions.js';

import { setSimple } from '../../actions/miscActions.js';
import * as types from '../../config/types';
import COUNTRY_CODES from '../../config/countryCodes';
import { LANGUAGES_CODES } from '../../config/countryLanguageCombinations';

import appExecuted from '../../models/tracker/events/apps/AppExecuted';
import appConfigKey from '../../utils/applications/appConfigKey';
import makeRequest from '../../utils/makeRequest';
import { stripSlash } from '../../utils/misc';
import { parseDomain } from '../../utils/testUrl';

export default () =>
  ({ dispatch, getState }) =>
  next =>
  action => {
    const state = getState();

    // request
    if (action.type === types.GET_APP_DATA) {
      if (!get(action, 'params.topic')) {
        return next(action);
      }
      if (get(action, 'params.url')) {
        const url = get(action, 'params.url');
        if (action.app === 'connect') {
          set(action, 'params.url', parseDomain(url));
        } else {
          set(action, 'params.url', stripSlash(url));
        }
      }
      if (isNil(get(action, 'params.country'))) {
        const country =
          get(state, 'filter.site.defaultSerpCountry') || COUNTRY_CODES.US;
        set(action, 'params.country', country);
      }
      if (isNil(get(action, 'params.language'))) {
        const language = LANGUAGES_CODES.EN;
        set(action, 'params.language', language);
      }

      // app execution timestamp
      const timestamp = Date.now();

      // initiate entry with only params
      dispatch(
        saveAppConfigState({
          key: appConfigKey(action.params),
          date: timestamp,
          params: action.params,
          data: {},
        }),
      );

      // save last app run timestamp
      dispatch(
        setSimple({
          key: 'lastAppRun',
          value: timestamp,
        }),
      );

      makeRequest({
        type: types.GET_APP_DATA,
        mutation: GetAppDataDocument,
        options: {
          params: action.params,
          isQuery: true,
        },
        keys: [`${types.GET_APP_DATA}-${action.app}`],
        variables: {
          params: action.params,
          app: action.app,
          siteId: get(state, 'filter.site.id'),
        },
      }).then(res => {
        if (res && !res.error) {
          const data = get(res, 'getAppData', {});

          // callback
          if (action.callback) action.callback(data);

          // app runs returned by this query
          const numAppRuns = get(data, 'appRuns');

          // compare with latest app run timestamp in local state
          // ie. if some other query updates the timestamp in local
          // state, then this shouldn't be the latest query executed
          const currentState = getState();
          const latestAppRunTimestamp = get(currentState, 'misc.lastAppRun');
          const isLatestRun = latestAppRunTimestamp === timestamp;
          const hasRuns = !isNil(numAppRuns);

          // TODO: is it better to do it with a 'last updated' approach ?
          // ie. if the last query to update the UI was fired before the current one,
          // update the IU (as it's an incremental update), but if it was fired after,
          // THEN don't update the UI.

          // update app runs if this query is the latest
          if (hasRuns && isLatestRun) {
            const executionDetails = {
              application: action.app,
              params: action.params,
            };

            const additionalDetails = {
              appRunsCurrent: get(numAppRuns, 'current') || 1,
              appRunsLimit: get(numAppRuns, 'limit') || 0,
            };

            appExecuted.record(executionDetails, additionalDetails);

            dispatch(
              setAggregateStats({
                data: { appRuns: numAppRuns },
              }),
            );
          }
        }
      });
    }

    // response
    if (
      action.type === types.REQUEST_SUCCESS &&
      action.requestType === types.GET_APP_DATA
    ) {
      const res = action.res || {};
      const data = get(res, 'getAppData', {});
      if (typeof action.callback === 'function') action.callback(data);

      dispatch(
        saveAppConfigState({
          key: appConfigKey(get(action, 'options.params') || {}),
          date: Date.now(),
          params: get(action, 'options.params'),
          data,
        }),
      );
    }

    return next(action);
  };
