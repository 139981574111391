import { get, isEqual } from 'lodash';
import { ResearchDataInput } from '@marketmuse/data-state/types';
import { sanitizePageUrl, sanitizeTerm } from '@marketmuse/utilities';
import { useStoreSelector } from '@marketmuse/data-state/hooks';
import { Country, Language } from '@marketmuse/config/types/papi';

const useIsNewQuery = (params: ResearchDataInput) => {
  const appQuery = useStoreSelector(state => state?.appResearch?.ui?.appQuery);

  const newParams = {
    country: params.country,
    language: params.language,
    term: sanitizeTerm(params.term, params.language),
    url: sanitizePageUrl(params.url),
  };

  const prevParams = {
    country: get(appQuery, 'country') || Country.US,
    language: get(appQuery, 'language') || Language.EN,
    term: get(appQuery, 'term') || '',
    url: get(appQuery, 'url') || '',
  };

  return !isEqual(newParams, prevParams);
};

export default useIsNewQuery;
