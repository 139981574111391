import { Alignment } from '@ckeditor/ckeditor5-alignment';
import { Autoformat } from '@ckeditor/ckeditor5-autoformat';
import { Autosave } from '@ckeditor/ckeditor5-autosave';
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote';
import { CodeBlock } from '@ckeditor/ckeditor5-code-block';
import { FindAndReplace } from '@ckeditor/ckeditor5-find-and-replace';
import {
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
} from '@ckeditor/ckeditor5-font';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { Highlight } from '@ckeditor/ckeditor5-highlight';
import { HorizontalLine } from '@ckeditor/ckeditor5-horizontal-line';
import { HtmlComment } from '@ckeditor/ckeditor5-html-support';
import {
  AutoImage,
  Image,
  ImageCaption,
  ImageInsert,
  ImageInsertUI,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageToolbar,
} from '@ckeditor/ckeditor5-image';
import { Indent, IndentBlock } from '@ckeditor/ckeditor5-indent';
import { LinkImage } from '@ckeditor/ckeditor5-link';
import { AdjacentListsSupport, List, TodoList } from '@ckeditor/ckeditor5-list';
import { MediaEmbed } from '@ckeditor/ckeditor5-media-embed';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { RemoveFormat } from '@ckeditor/ckeditor5-remove-format';
import { SelectAll } from '@ckeditor/ckeditor5-select-all';
import { SourceEditing } from '@ckeditor/ckeditor5-source-editing';
import {
  Code,
  Strikethrough,
  Subscript,
  Superscript,
} from '@ckeditor/ckeditor5-basic-styles';
import {
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
} from '@ckeditor/ckeditor5-table';
import { TextTransformation } from '@ckeditor/ckeditor5-typing';

import { HighlightTerm } from '@marketmuse/editor-plugins/highlightTerms';
import { TrackClipboard } from '@marketmuse/editor-plugins/trackClipboard';
import { Export } from '@marketmuse/editor-plugins/export';
import { WordCount } from '@marketmuse/editor-plugins/wordCount';

export const defaultPlugins = [
  AdjacentListsSupport,
  Alignment,
  Autoformat,
  AutoImage,
  Autosave,
  BlockQuote,
  Code,
  CodeBlock,
  Export,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Heading,
  Highlight,
  HighlightTerm,
  HorizontalLine,
  HtmlComment,
  Image,
  ImageCaption,
  ImageInsert,
  ImageInsertUI,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageToolbar,
  Indent,
  IndentBlock,
  LinkImage,
  List,
  MediaEmbed,
  Paragraph,
  RemoveFormat,
  SelectAll,
  SourceEditing,
  Strikethrough,
  Subscript,
  Superscript,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextTransformation,
  TodoList,
  TrackClipboard,
  WordCount,
];
