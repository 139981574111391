import { isEqual } from 'lodash';
import { CustomFilterProps } from '@tanstack/react-table';
import cx from 'classnames';
import { InputPillList } from '../../../Forms';
import { Button } from '../../../Button';

import { Box } from '../../../Box';
import { INCLUDE_TERMS_TYPE } from './types';

const labelClassName = ['text-base', 'prose', 'font-bold'];
const description =
  'Separate multiple terms with a comma, or press enter after each.';

const paragraphStyles = [
  'block',
  'text-sm',
  'font-medium',
  'text-gray-500',
  'leading-5',
];

function FilterIncludesExcludes({ column, table }: CustomFilterProps) {
  const id = column.id;
  const filterValue = column.getFilterValue() || {};

  return (
    <Box className={['flex', 'flex-col', 'gap-4']}>
      <Box className={['flex', 'flex-col', 'gap-4']}>
        <InputPillList
          name={`table-filter--column-include-${id}`}
          pillIconClassName="text-blue-400"
          renderBefore={
            <>
              <label
                htmlFor={`table-filter--column-include-${id}`}
                className={cx('block', 'text-gray-700', labelClassName)}
              >
                Include terms
              </label>
              <Box className={['flex', 'gap-x-2']}>
                {[
                  { id: INCLUDE_TERMS_TYPE.ALL_WORDS, title: 'All words' },
                  { id: INCLUDE_TERMS_TYPE.ANY_WORDS, title: 'Any words' },
                ].map(item => {
                  return (
                    <Button
                      key={item.id}
                      children={item.title}
                      onClick={() =>
                        column.setFilterValue(() => ({
                          ...filterValue,
                          include: item.id,
                        }))
                      }
                      className={cx(
                        'border border-gray-150 rounded py-2 px-4',
                        {
                          'bg-blue-100': filterValue.include === item.id,
                          'border-blue-500': filterValue.include === item.id,
                        },
                      )}
                    />
                  );
                })}
              </Box>
            </>
          }
          items={filterValue.includes || []}
          onChange={items => {
            if (!isEqual(items, filterValue.includes)) {
              column.setFilterValue(() => ({
                ...filterValue,
                includes: items,
              }));
            }
          }}
          renderAfter={<p className={cx(paragraphStyles)}>{description}</p>}
        />
      </Box>
      <Box className={['flex', 'flex-col', 'gap-4']}>
        <InputPillList
          name={`table-filter--column-exclude-${id}`}
          label={`Excludes terms`}
          pillIconClassName="text-blue-400"
          renderBefore={
            <label
              htmlFor={`table-filter--column-exclude-${id}`}
              className={cx('block', 'text-gray-700', labelClassName)}
            >
              Excludes terms
            </label>
          }
          items={filterValue.excludes || []}
          onChange={items => {
            if (!isEqual(items, filterValue.excludes)) {
              column.setFilterValue(() => ({
                ...filterValue,
                excludes: items,
              }));
            }
          }}
          renderAfter={<p className={cx(paragraphStyles)}>{description}</p>}
        />
      </Box>
    </Box>
  );
}
export default FilterIncludesExcludes;
