import get from 'lodash/get';

import Agent from '../Agent';
import { isProd, isStaging } from '../../../utils/isEnv';

class GoogleAnalytics extends Agent {
  isReady() {
    return Array.isArray(window.dataLayer);
  }

  canInit(user) {
    return this.isReady() && super.canInit(user);
  }

  init(user) {
    if (!this.canInit(user, this)) return;
    window.dataLayer.push({ userId: user.id });
  }

  initAnonymous() {
    return;
  }

  clear() {
    return;
  }

  track(data) {
    if (!this.isReady()) {
      console.warn('GA cannot track events.');
      return;
    }

    const category = get(data, 'category');
    const action = get(data, 'action');
    const mainValue = get(data, 'mainValue');
    const newName = get(data, 'newName');

    const final = {
      ...data,
      event: newName,
      eventAction: data.eventAction || action,
      eventLabel: category,
      eventCategory: action,
      eventValue: mainValue || data.value,
      value: mainValue,
    };

    if (!isProd() && !isStaging()) {
      return;
    }

    window.dataLayer.push(final);
  }
}

export default GoogleAnalytics;
