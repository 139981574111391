import { FC, useCallback } from 'react';
import { Export } from '@marketmuse/research-ui';
import {
  IResearchDataContext,
  useResearchActions,
  useResearchData,
  useResearchPermissions,
} from '@marketmuse/research-data';

type RecommendationExportProps = {
  loading?: boolean;
};

const RecommendationExport: FC<RecommendationExportProps> = ({ loading }) => {
  const { canExportConnect } = useResearchPermissions();
  const { exportConnect } = useResearchActions();
  const {
    data: { linkingRecommendations },
  }: IResearchDataContext = useResearchData();
  const { handler: exportConnectFn, loading: exportLoading } = exportConnect;

  const handleClick = useCallback(() => {
    if (canExportConnect) {
      exportConnectFn({
        recommendations: linkingRecommendations,
      });
    }
  }, [canExportConnect, exportConnectFn, linkingRecommendations]);

  return (
    <Export
      dataId={'connect'}
      disabled={loading}
      locked={!canExportConnect}
      showPopover={false}
      loading={exportLoading}
      onButtonClick={handleClick}
    />
  );
};

export default RecommendationExport;
