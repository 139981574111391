import styled, { keyframes } from 'styled-components';

const indeterminate = keyframes`
  0% { left: -35%; right: 100%; }
  60% { left: 100%; right: -90%; }
  100% { left: 100%; right: -90%; }
`;

const indeterminateShort = keyframes`
  0% { left: -200%; right: 100%; }
  60% { left: 107%; right: -8%; }
  100% { left: 107%; right: -8%; }
`;

const bars = p => `
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  background-color: ${p.theme.mmxBlue};
  will-change: left, right;
`;

export default styled.div`
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: transparent;
  overflow: hidden;
  z-index: 99999999;
  &:before {
    ${p => bars(p)}
    animation: ${indeterminate} 3.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  }
  &:after {
    ${p => bars(p)}
    animation: ${indeterminateShort} 3.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    animation-delay: 2.15s;
  }
`;
