import React from 'react';
import { ResearchProvider } from '@marketmuse/research-data';
import Research from './Research';

const Wrapper = () => {
  return (
    <ResearchProvider>
      <Research />
    </ResearchProvider>
  );
};

export default Wrapper;
