import { ChangeEvent, useState } from 'react';
import cx from 'classnames';
import { useDebouncedCallback } from 'use-debounce';
import { twMerge } from 'tailwind-merge';

import { Box } from '../Box';
import { Input } from '../Forms';
import { Icon, SUPPORTED_ICON } from '../Icon';
import { Text } from '../Text';

type Props = {
  children?: React.ReactNode;
  heading: {
    title: string;
    icon: SUPPORTED_ICON;
  };
  input: {
    label: string;
  };
  onChangeSearchQuery: (term: string) => void;
  query?: string;
};

export function PageHeaderWrapper({ children }: { children: React.ReactNode }) {
  return (
    <Box
      className={twMerge([
        'bg-white',
        'border-b-2',
        'border-b-gray-200',
        'flex-row',
        'flex',
        'gap-12',
        'items-center',
        'justify-start',
        'm-0',
        'px-8',
        'py-4',
        'sticky',
        'text-gray-900',
        'top-0',
        'z-40',
        'w-full',
      ])}
    >
      {children}
    </Box>
  );
}

function PageHeader({
  children,
  heading,
  input,
  onChangeSearchQuery,
  query: defaultQuery = '',
}: Props) {
  const [query, setQuery] = useState<string>(defaultQuery);
  const onChangeSearchQueryDebounced = useDebouncedCallback(
    onChangeSearchQuery,
    500,
  );

  return (
    <PageHeaderWrapper>
      <Box className={['flex', 'flex-row', 'items-center', 'gap-2']}>
        <Icon name={heading.icon} iconSize={16} boxSize={20} />
        <Text className={['font-bold', 'text-lg']} as="h1">
          {heading.title}
        </Text>
      </Box>
      <Box className={'relative'}>
        <Input
          name="page-header-input"
          inputProps={{
            type: 'text',
            placeholder: input.label,
            'data-mms--page-header-input': 'term',
            defaultValue: query,
            onChange: (event: ChangeEvent<HTMLInputElement>) => {
              const value = event.currentTarget.value;
              setQuery(value);
              onChangeSearchQueryDebounced(value);
            },
            className: [
              'z-10',
              'pl-11',
              'bg-gray-100',
              'border-gray-100',
              'w-[460px]',
            ],
          }}
          label={input.label}
          labelProps={{ className: 'hidden' }}
        />
        <Icon
          name={SUPPORTED_ICON.search}
          iconSize={16}
          className={cx([
            'absolute',
            'left-4',
            'top-[50%]',
            'z-20',
            'translate-y-[-50%]',
          ])}
        />
      </Box>
      {children}
    </PageHeaderWrapper>
  );
}

export default PageHeader;
