import { Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import { lazyLoad } from '@marketmuse/utilities';

import ScreenLoader from '../components/ScreenLoader';

const DetailsScreen = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'DetailsScreen' */ '../containers/Inventory/DetailsScreen'
    ),
);
const TableViewRoutes = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'TableViewRoutes' */ '../containers/Inventory/TableView/TableViewLoader'
    ),
);

export const routes = [
  {
    path: ':routeDomain/:routeDomainValue?',
    element: (
      <Suspense fallback={<ScreenLoader.Light unwrapped={true} />}>
        <TableViewRoutes />
      </Suspense>
    ),
  },
  {
    path: ':routeDomain/:routeDomainValue?/:entityType/:entityId/:viewId?/:briefScreen?',
    element: (
      <Suspense fallback={<ScreenLoader.Light unwrapped={true} />}>
        <DetailsScreen />
      </Suspense>
    ),
  },
  {
    path: ':routeDomain/:routeDomainValue?/:entityType/:entityId',
    children: [
      {
        path: ':viewId?/:briefScreen?',
        element: (
          <Suspense fallback={<ScreenLoader.Light unwrapped={true} />}>
            <DetailsScreen />
          </Suspense>
        ),
      },
      {
        path: '*',
        element: <Navigate to="overview" />,
      },
    ],
  },
];
