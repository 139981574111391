import {
  DisplayTableDict,
  DisplayTableColumn,
} from '../../types/displayTables';
import common from './common';
import pageColumns from './pageColumns';
import topicColumns from './topicColumns';

const table = 'page_topics';
const pageTopicColumns: DisplayTableDict<DisplayTableColumn> = {
  topic: topicColumns['topic'],
  actionBar: {
    field: 'actionBar',
    title: 'Actions',
    table,
  },
  rank: {
    field: 'rank',
    title: 'Rank',
    table,
  },
  url: pageColumns['url'],
  pageTitle: pageColumns['pageTitle'],
  difficulty: topicColumns['difficulty'],
  competitiveAdvantage: topicColumns['competitiveAdvantage'],
  difficultyPersonalized: topicColumns['difficultyPersonalized'],
  value: { ...common['trafficValue'], table },
  valueChange: { ...common['trafficValueDelta'], table },
  traffic: {
    ...common['traffic'],
    table,
  },
  trafficChange: {
    ...common['trafficDelta'],
    table,
  },
  serpFeatures: topicColumns['serpFeatures'],
  potentialTraffic: {
    ...common['potentialTraffic'],
    table,
  },
  potentialTrafficUpside: {
    ...common['potentialTrafficUpside'],
    table,
  },
  internalLinks: pageColumns['internalLinks'],
  externalLinks: pageColumns['externalLinks'],
  valueFromTopic: {
    ...common['versionTrafficValuePct'],
    table,
  },
  trafficFromTopic: {
    ...common['versionTrafficPct'],
    table,
  },
  potentialValue: {
    ...common['potentialTrafficValue'],
    table,
  },
  trafficChangePct: {
    ...common['trafficDeltaPct'],
    table,
  },
  seasonality: topicColumns['seasonality'],
  authority: pageColumns['authority'],
  topicVolume: topicColumns['topicVolume'],
  mmPersonalizedAdvantage: topicColumns['mmPersonalizedAdvantage'],
  numTopics: {
    ...pageColumns['numTopics'],
    title: 'Topics on Page',
  },
};

export default pageTopicColumns;
