import { memo, useState, useEffect, useMemo, useCallback } from 'react';
import { get, pick } from 'lodash';

import { App, Country, Language } from '@marketmuse/config/types/papi';
import { AppUiStatus } from '@marketmuse/config/types/applications';
import { ResearchDataInput } from '@marketmuse/data-state/types';
import { useResearchActions } from '@marketmuse/research-data';
import { lazyLoad } from '@marketmuse/utilities';

import { Omnibar } from '../components/Omnibar';
import useIsNewQuery from './hooks/useIsNewQuery';

const SnapshotsLoader = lazyLoad(() => {
  return import(
    /* webpackChunkName: 'SnapshotsLoader' */ '@marketmuse/containers/Snapshots/SnapshotsLoader'
  );
});

function ResearchOmnibar({
  appParams,
  appStatus,
  setParams,
  validParams,
}: {
  appParams: ResearchDataInput;
  appStatus: AppUiStatus;
  setParams: (values: ResearchDataInput) => void;
  validParams: boolean;
}) {
  const [buttonInTimeout, setButtonInTimeout] = useState<boolean>(false);
  const { performAppQuery, loadAppQuery } = useResearchActions();
  const isNewQuery = useIsNewQuery(appParams);
  const onSubmit = useCallback(() => {
    performAppQuery(appParams);
    setButtonInTimeout(true);
  }, [performAppQuery, appParams]);

  const omnibarVals = useMemo(
    () => pick(appParams, ['term', 'country', 'url', 'language']),
    [appParams],
  );

  useEffect(() => {
    const timer = setTimeout(() => setButtonInTimeout(false), 2000);
    return () => clearTimeout(timer);
  }, [buttonInTimeout]);
  const isLoading = appStatus === AppUiStatus.loading;

  return (
    <Omnibar
      title="Research"
      iconName="research"
      app="research"
      style={{ borderBottomColor: '#fff' }}
      setValues={setParams}
      values={omnibarVals}
      onSubmit={onSubmit}
      loading={isLoading}
      canSubmitText="Run"
      canSubmit={validParams && !buttonInTimeout && (isNewQuery || !isLoading)}
      children={[
        {
          key: 'spacer-1',
          style: { flexGrow: 1 },
        },
        {
          key: 'snapshots',
          style: { marginRight: 40 },
          className: 'mm-tw',
          children: (
            <SnapshotsLoader
              app={App.RESEARCH}
              onAppQueryClick={({ row: appQuery }) => {
                const params = {
                  term: get(appQuery, 'topic'),
                  url: get(appQuery, 'url'),
                  siteId: get(appQuery, 'site.id'),
                  country: get(appQuery, 'country') || Country.US,
                  language: get(appQuery, 'language') || Language.EN,
                  networkDomains: get(appQuery, 'connectNetworkDomains'),
                  competitorDomains: get(appQuery, 'connectCompetitorDomains'),
                };

                loadAppQuery({
                  params,
                  appQuery: {
                    id: get(appQuery, 'id'),
                    status: get(appQuery, 'status'),
                    term: get(params, 'term'),
                    url: get(params, 'url'),
                    country: get(params, 'country'),
                    language: get(params, 'language'),
                  },
                });
              }}
            />
          ),
        },
      ]}
    />
  );
}

export default memo(ResearchOmnibar);
