import { SliceTypeAppOptimize } from '../../types';
import { initialState as dataSliceInitialState } from './data/data.initialState';
import { initialState as networkSliceInitialState } from './network/network.initialState';
import { initialState as uiSliceInitialState } from './ui/ui.initialState';

export { dataSlice } from './data/data.slice';
export { networkSlice } from './network/network.slice';
export { uiSlice } from './ui/ui.slice';

export const initialState: SliceTypeAppOptimize = {
  data: dataSliceInitialState,
  network: networkSliceInitialState,
  ui: uiSliceInitialState,
};
