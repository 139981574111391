import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import Button from '../Button';
import Loader from '../Loader';
import Icon from '../Icon';

import { toggleDynamic } from '../../actions/miscActions';

const ButtonStyled = styled(Button)`
  padding-right: 0;
  padding-left: 0;
`;

const IconStyled = styled(Icon)`
  font-size: 10pt;
  margin-right: 6px;
  margin-left: -6px;
  margin-bottom: -1px;
`;

const RestrictedButton = ({
  loading,
  children,
  restricted,
  restrictionType,
  blockOnClick,
  dataTags,
  ...props
}) => {
  let isRestricted = restricted;
  const dispatch = useDispatch();

  return (
    <ButtonStyled
      tertiary
      large
      locked={isRestricted}
      disabled={loading}
      {...props}
      {...(!isRestricted && dataTags ? dataTags : {})}
      onClick={e => {
        if (isRestricted) {
          dispatch(
            toggleDynamic({
              id: 'upgradeWarning',
              status: true,
            }),
          );
        } else {
          if (typeof props.onClick === 'function') {
            props.onClick(e);
          }
        }
      }}
    >
      {isRestricted && <IconStyled name="locked" size="16" />}
      {loading ? <Loader color="grey70" /> : children}
    </ButtonStyled>
  );
};

RestrictedButton.propTypes = {
  children: PropTypes.any,
  loading: PropTypes.bool,
  restrictionType: PropTypes.string, // preset check for restriction
  restricted: PropTypes.bool, // override restriction check
  dataTags: PropTypes.object,
};

export default RestrictedButton;
