import { SUPPORTED_ICON } from '@marketmuse/components';

export type ConfigType = 'topicNavigator' | 'heatmap' | 'serpXray' | 'connect';

export type ConfigValues = {
  title: string;
  iconName: SUPPORTED_ICON;
  description: string;
};

const config: Record<ConfigType, ConfigValues> = {
  topicNavigator: {
    title: 'Topic Navigator',
    iconName: SUPPORTED_ICON.compass,
    description:
      'Which keywords are related to my search? What topics would an expert include when talking about this topic? What questions do searchers of this topic ask?',
  },
  heatmap: {
    title: 'Heatmap',
    iconName: SUPPORTED_ICON.grid,
    description:
      'What topics are table-stakes across the SERP? What topics are my competitors missing? How do I stack up?',
  },
  serpXray: {
    title: 'SERP X-Ray',
    iconName: SUPPORTED_ICON.rank,
    description:
      'What type of content do people write when they cover this topic? What types of pages rank? What SERP Features show up and what does that mean for rankings and intent?',
  },
  connect: {
    title: 'Connect',
    iconName: SUPPORTED_ICON.connectivity,
    description:
      'Which pages inside and outside of my site should I link to in a piece of content covering this topic?',
  },
};

export default config;
