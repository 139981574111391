import get from 'lodash/get';
import * as Sentry from '@sentry/browser';
import { recurlyFieldsConfig } from '@marketmuse/config/configs/recurly';
import Toast from '../components/Toast';

class Recurly {
  init() {
    if (!window.recurly) {
      console.error('Recurly is not loaded');
      return;
    }

    window.recurly?.configure({
      publicKey: process.env['REACT_APP_RECURLY_PUBLIC_KEY'],
      fields: recurlyFieldsConfig,
    });
  }

  // tokenize the form
  token(form, callback, onError) {
    window.recurly?.token(form, (error, res) => {
      if (error) {
        Sentry.captureMessage(error.message, {
          extra: { error },
        });
        Toast.fire(
          get(error, 'message', 'Failed to process your card'),
          'error',
        );
        if (typeof onError === 'function') {
          onError(error);
        }
      } else {
        if (typeof callback === 'function') {
          callback(res);
        }
      }
    });
  }
}

export default new Recurly();
