import { isEmpty } from 'lodash';
import { twMerge } from 'tailwind-merge';
import { WistiaVideoProps } from './types';

const divClassName = [
  'bg-center',
  'bg-cover',
  'bg-no-repeat',
  'h-0',
  'overflow-hidden',
  'pb-[56.25%]',
  'relative',
  'rounded',
];

const iframeClassName = [
  'top-0',
  'bottom-0',
  'left-0',
  'right-0',
  'absolute',
  'w-full',
  'h-full',
  'rounded',
  'overflow-hidden',
];

function WistiaVideo({
  style,
  title,
  videoId,
  backgroundImage,
  className,
}: WistiaVideoProps) {
  const backgroundImageStyle = {
    backgroundImage: `url('${backgroundImage || ''}')`,
  };
  return (
    <div
      className={twMerge(divClassName, className)}
      style={{
        ...style,
        ...(!isEmpty(backgroundImage) ? backgroundImageStyle : {}),
      }}
    >
      <iframe
        title={title}
        src={`https://fast.wistia.net/embed/iframe/${videoId}`}
        frameBorder="0"
        scrolling="no"
        allowFullScreen
        className={twMerge(iframeClassName)}
      />
    </div>
  );
}

export default WistiaVideo;
