import PropTypes from 'prop-types';
import styled from 'styled-components';

import ModalBody from './ModalBody';

const ModalFooter = styled.div`
  position: relative;
  order: 5;
  padding: ${p => p.theme.spacing.large};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${p => p.theme.spacing.regular};

  ${ModalBody} ~ & {
    margin-top: -${p => p.theme.spacing.xSmall};
    padding-top: 0;
  }

  ${p => p.hasBorderTop && `
    &::before {
      position: absolute;
      top: 0;
      left: ${p.theme.spacing.large};
      right: ${p.theme.spacing.large};
      content: '';
      display: block;
      border: 1px solid ${p.theme.colors.grey05};
    }

    ${ModalBody} ~ & {
      margin-top: 0;
      padding-top: ${p.theme.spacing.large};
    }
  `}
`;

ModalFooter.propTypes = {
  hasBorderTop: PropTypes.bool,
}

export default ModalFooter;
