export enum CKE_PLUGINS {
  BOLD = 'Bold',
  ITALIC = 'Italic',
  PARAGRAPH = 'Paragraph',
  UNDERLINE = 'Underline',
  STRIKETHROUGH = 'Strikethrough',
  SUPERSCRIPT = 'Superscript',
  SUBSCRIPT = 'Subscript',
  CODE = 'Code',
  LINK = 'Link',
  AUTO_LINK = 'AutoLink',
  ALIGNMENT = 'Alignment',
  AUTOFORMAT = 'Autoformat',
  TEXT_TRANSFORMATION = 'TextTransformation',
  INDENT = 'Indent',
  INDENT_BLOCK = 'IndentBlock',
  BLOCK_QUOTE = 'BlockQuote',
  LIST = 'List',
  CODE_BLOCK = 'CodeBlock',
  TITLE = 'Title',
  FIND_AND_REPLACE = 'FindAndReplace',
  FONT_BACKGROUND_COLOR = 'FontBackgroundColor',
  FONT_COLOR = 'FontColor',
  FONT_SIZE = 'FontSize',
  FONT_FAMILY = 'FontFamily',
  HIGHLIGHT = 'Highlight',
  HORIZONTAL_LINE = 'HorizontalLine',
  PASTE_FROM_OFFICE = 'PasteFromOffice',
  HTML_COMMENT = 'HtmlComment',
  GENERAL_HTML_SUPPORT = 'GeneralHtmlSupport',
  CLIPBOARD = 'Clipboard',
  SOURCE_EDITING = 'SourceEditing',
  REMOVE_FORMAT = 'RemoveFormat',
  SELECT_ALL = 'SelectAll',
  HEADING = 'Heading',
  TODO_LIST = 'TodoList',
  WORD_COUNT = 'WordCount',

  TABLE = 'Table',
  TABLE_TOOLBAR = 'TableToolbar',
  TABLE_PROPERTIES = 'TableProperties',
  TABLE_CELL_PROPERTIES = 'TableCellProperties',
  TABLE_COLUMN_RESIZE = 'TableColumnResize',
  TABLE_CAPTION = 'TableCaption',

  IMAGE_INSERT = 'ImageInsert',
  AUTO_IMAGE = 'AutoImage',
  Image = 'Image',
  IMAGE_TOOLBAR = 'ImageToolbar',
  IMAGE_CAPTION = 'ImageCaption',
  IMAGE_STYLE = 'ImageStyle',
  IMAGE_RESIZE = 'ImageResize',
  LINK_IMAGE = 'LinkImage',
  IMAGE_UPLOAD_UI = 'ImageUploadUI',
  IMAGE_UPLOAD = 'ImageUpload',
  MEDIA_EMBED = 'MediaEmbed',

  HIGHLIGHT_TERM = 'HighlightTerm',
  EXPORT = 'Export',
}
