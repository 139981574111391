import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import get from 'lodash/get';

import IconLoader from './IconLoader';

const IconSvgWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: ${p => p.box && `${p.box}px`};
  height: ${p => p.box && `${p.box}px`};
  color: ${p => p.color && (p.theme.colors[p.color] || p.color)};

  svg {
    width: 100%;
    height: 100%;
  }
`;

const IconSvg = props => {
  const title = get(props, 'title');
  const iconName = get(props, 'name');
  const sizeParsed = parseInt(get(props, 'size'), 10);
  const iconSize = isNaN(sizeParsed) ? 16 : sizeParsed;

  return (
    <IconSvgWrapper
      className={props.className}
      role="presentation"
      aria-label={title || iconName}
      box={props.box || iconSize}
      color={props.color}
      style={props.style}
    >
      <IconLoader name={iconName} title={title} size={iconSize} />
    </IconSvgWrapper>
  );
};

IconSvg.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  box: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  // provides area friendly title
  title: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
};

IconSvg.defaultProps = {
  size: 16,
  className: '',
};

export default memo(IconSvg);
