import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Box from '../../../../../components/Box';
import { Toggle } from '../../../../../components/Radio';
import Text from '../../../../../components/Text';

import { valuesShape } from '../filterFactoryTypes';

const FilterToggle = ({ values = [], onChange }) => (
  <Box boxVariant="flex" width="100%" flexDirection="column">
    {values.map(item => (
      <Box
        boxVariant="flex"
        width="100%"
        justifyContent="space-between"
        mb="16px"
      >
        <Text textVariant="textRegular">{item.title}</Text>
        <Toggle
          key={`Pb-${item.id}`}
          onClick={() => onChange(item.id)}
          active={item.active}
        />
      </Box>
    ))}
  </Box>
);

FilterToggle.propTypes = {
  onChange: PropTypes.func,
  values: PropTypes.arrayOf(valuesShape),
};

export default memo(FilterToggle);
