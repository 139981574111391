import { editor as EDITOR } from '@marketmuse/config/configs';
import msWord from './assets/ms-word';
import excel from './assets/excel';
import googleDoc from './assets/google-doc';

export const TOOLBAR_TITLE = 'Export';

export const feature = {
  excel: {
    title: 'Excel',
    dataTag: 'excel',
    icon: excel,
    onClickEvent: EDITOR.EXPORT_EVENT.onExcelExport,
  },
  word: {
    title: 'Word',
    dataTag: 'word',
    icon: msWord,
    onClickEvent: EDITOR.EXPORT_EVENT.onWordExport,
  },
  googleDoc: {
    title: 'Google Doc',
    dataTag: 'google-doc',
    icon: googleDoc,
    onClickEvent: EDITOR.EXPORT_EVENT.onGoogleDocExport,
  },
};
