import {
  SeoConnect,
  Country,
  ExportSerpXrayQueryVariables,
  ExportHeatmapQueryVariables,
} from '@marketmuse/config/types/papi';

import {
  ResearchNetworkStatus,
  ResearchDataConnectInput,
  ResearchDataInput,
  ResearchNetworkData,
  ResearchSnapshotInput,
  ResearchTopicNavigatorViewUpdate,
  ResearchViewModel,
  ResearchViews,
  ViewHeatmapFiltersState,
  ViewHeatmapSliceState,
  ViewSerpXraySliceState,
} from '@marketmuse/data-state/types';

export interface IResearchDataProps {
  children?: React.ReactNode;
}

export interface IResearchDataContext {
  data: ResearchNetworkData;
  network: ResearchNetworkStatus;
}

export interface IResearchUiStateContext
  extends Omit<ResearchDataInput, 'term' | 'url'> {
  term?: string;
  url?: string;
  siteId: string;
  siteCountry: Country;
  country: Country;
  displayExactDistribution: boolean;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IResearchViewModelContext extends ResearchViewModel {}

export interface ExportTopicNavigatorPayload {
  view:
    | ResearchViews.REFLECT
    | ResearchViews.QUESTIONS
    | ResearchViews.KEYWORDS
    | ResearchViews.TOPIC_MODEL;
  rows: any[];
}

export interface ExportConnectPayload {
  recommendations: SeoConnect;
}

export type ExportSerpXrayPayload = Omit<
  ExportSerpXrayQueryVariables,
  'generationContext'
>;

export type ExportHeatmapPayload = Omit<
  ExportHeatmapQueryVariables,
  'generationContext'
>;

export interface IResearchPermissionsContext {
  canAccessHeatmapWebsite: boolean;
  canAccessHeatmapSerp: boolean;
  canAccessXray: boolean;
  canAddToInventory: boolean;
  canDisplaySiteContentData: boolean;
  canExportConnect: boolean;
  canExportHeatmap: boolean;
  canExportSerpXray: boolean;
  canExportTopicNavigator: boolean;
  canFilterSerpXray: boolean;
  canFilterTopicNavigator: boolean;
  canSortHeatmap: boolean;
  hasViableSiteInventory: boolean;
  shouldGateConnect: boolean;
  shouldGateKeywords: boolean;
  shouldGateQuestions: boolean;
  shouldGateReflectTable: boolean;
  shouldLimitDataTopicNavigator: boolean;
}

export enum ADD_TO_INVENTORY_ORIGIN {
  tableActionBar,
  topicCircle,
}
export interface AddToInventoryHandlerParams {
  items: Array<{ term: string; volume?: number }>;
  origin: ADD_TO_INVENTORY_ORIGIN;
}

export interface AddToInventoryHookResult {
  addingOrigin: ADD_TO_INVENTORY_ORIGIN | null;
  handler: (params: AddToInventoryHandlerParams) => void;
  loading: boolean;
  loadingItems: Record<string, boolean>;
}

export enum ADD_SAVED_WRITINGS_ORIGIN {
  tableActionBar = 'tableActionBar',
  tableRowCta = 'tableRowCta',
  modal = 'modal',
}

export interface IResearchActionsContext {
  addToInventory: AddToInventoryHookResult;
  exportTopicNavigator: {
    handler: (payload: ExportTopicNavigatorPayload) => void;
    loading: boolean;
  };
  exportConnect: {
    handler: (payload: ExportConnectPayload) => void;
    loading: boolean;
  };
  exportSerpXray: {
    handler: (payload: ExportSerpXrayPayload) => void;
    loading: boolean;
  };
  exportHeatmap: {
    handler: (payload: ExportHeatmapPayload) => void;
    loading: boolean;
  };
  loadAppQuery: (payload: ResearchSnapshotInput) => void;
  matchQueryLocaleToSite: () => void;
  onTriggerSiteLevelHeatmap: (term: string) => void;
  openCalendly: () => void;
  openSubscriptionFlow: () => void;
  performAppQuery: (params: ResearchDataInput) => void;
  updateConnectDomains: (payload: ResearchDataConnectInput) => void;
  updateHeatmapFilter: (payload: ViewHeatmapFiltersState) => void;
  updateHeatmapMode: (payload: ViewHeatmapSliceState['mode']) => void;
  updateHeatmapSort: (payload: ViewHeatmapSliceState['sort']) => void;
  updateSerpXrayFilter: (payload: ViewSerpXraySliceState) => void;
  updateTopicNavigatorFilters: (
    payload: Pick<ResearchTopicNavigatorViewUpdate, 'view' | 'filters'>,
  ) => void;
  updateTopicNavigatorSort: (
    payload: Pick<ResearchTopicNavigatorViewUpdate, 'view' | 'sort'>,
  ) => void;
}
