import cx from 'classnames';
import React, { ReactNode } from 'react';

import { Polymorphic } from '../../Polymorphic';

export interface BoxProps {
  className?: string | Array<string>;
  style?: React.CSSProperties;
  as?: React.ElementType<any>;
  children?: ReactNode;
  onClick?: (event: MouseEvent) => void;
}

export const Box = React.forwardRef<HTMLDivElement, BoxProps>(
  ({ as, className, ...props }, ref) => {
    return (
      <Polymorphic
        ref={ref}
        {...props}
        as={as || 'div'}
        className={className}
      />
    );
  },
);

export const Container = React.forwardRef<HTMLDivElement, BoxProps>(
  ({ className, ...props }, ref) => {
    return <Box {...props} className={cx('container', className)} />;
  },
);

export default Box;
