import styled from 'styled-components';

export default styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  ${p =>
    p.started &&
    `
    padding: 82px;
    overflow: hidden;
  `}
  ${p =>
    !p.started &&
    `
    padding: 42px;
    align-items: center;
    justify-content: center;
  `}
`;
