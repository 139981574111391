import { SortingFn } from '@tanstack/table-core';
import { get } from 'lodash';

export const sortListLength: SortingFn<any> = (rowA, rowB, columnId) => {
  const a = (get(rowA?.original, columnId) || [])?.length;
  const b = (get(rowB?.original, columnId) || [])?.length;

  if (a > b) {
    return 1;
  }
  return -1;
};
