export * from '../appResearch/appResearch.actionTypes';
export * from '../filter/filter.actionTypes';

// Suite | Backwards compatibility: Misc
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';
export const SIGN_OUT = 'SIGN_OUT';
export const SET_SIMPLE = 'SET_SIMPLE';
export const TOGGLE_DYNAMIC = 'TOGGLE_DYNAMIC';

// Suite | Backwards compatibility: SEO functionality
export const GET_PAGE_CACHE_FOR_KG = 'GET_PAGE_CACHE_FOR_KG';
export const GET_KG_COMPLETE = 'GET_KG_COMPLETE';
export const GET_BULK_AW_KEYWORDS = 'GET_BULK_AW_KEYWORDS';

// Suite | Backwards compatibility: App query functionality
export const APP_QUERY_CREATE_OR_UPDATE = 'APP_QUERY_CREATE_OR_UPDATE';
export const APP_QUERY_UPDATE = 'APP_QUERY_UPDATE';

// Suite | Backwards compatibility: admin mode functionality
export const ADMIN_MODE_ENABLE = 'ADMIN_MODE_ENABLE';
export const ADMIN_MODE_DISABLE = 'ADMIN_MODE_DISABLE';

// Suite | Backwards compatibility: User functionality
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const UPDATE_USER_DATA_STATE = 'UPDATE_USER_DATA_STATE';
export const ORG_DATA_SAVE = 'ORG_DATA_SAVE';
export const ORG_DATA_SITE_DELETE = 'ORG_DATA_SITE_DELETE';
