import styled, { css } from 'styled-components';

// https://www.w3.org/WAI/tutorials/menus/application-menus/
// unless specified as menubar, is menu (child)
// TODO: MMS-290
/// position: ${p => (p.isParent ? 'fixed' : 'absolute')};
const Menu = styled.div.attrs(p => ({
  tabIndex: 0,
  role: p.role ? p.role : 'menu',
  ...p,
}))`
  height: 100%;
  max-height: calc(100vh - 50px);
  overflow: auto;
  ${p => p.theme.type.textRegular}
  z-index: 10000;
  min-width: 148px;
  width: ${p => (p.isParent ? '240px' : `max-content`)};
  padding-top: ${p => p.theme.spacing.small};
  padding-bottom: ${p => p.theme.spacing.small};
  font-size: 14px;
  background-color: var(--mms-simple-background, ${p => p.theme.generic.dark.background});
  box-shadow: ${p => p.theme.boxShadowLow};
  border-radius: 4px;
  outline: none;
  ${p =>
    !p.isParent &&
    css`
      animation: ${p.theme.keyframes.slideIn} 100ms ease;
    `}
`;

export default Menu;
