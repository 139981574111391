import styled from 'styled-components';

// https://www.w3.org/WAI/tutorials/menus/application-menus/
// represents an item within a menu
// haspopup if has submenu
const MenuItem = styled.button.attrs(p => ({
  'aria-haspopup': p.items && 'true',
  role: 'menuitem',
  tabIndex: 0,
  ...p,
}))`
  ${p => p.theme.mixins.buttonReset};
  ${p => p.theme.type.textRegular};
  position: relative;
  z-index: 100;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: ${p => p.theme.spacing.xSmall} ${p => p.theme.spacing.medium};
  color: var(--mms-simple-foreground, ${p => p.theme.generic.dark.foreground});
  background-color: var(--mms-simple-background, ${p => p.theme.generic.dark.background});
  cursor: pointer;
  transition: background-color 100ms ease-in-out;

  &:hover {
    background-color: var(--mms-simple-background-hover, ${p => p.theme.generic.dark.backgroundHover});
  }

  &:disabled {
    cursor: default;

    &,
    &:focus,
    &:hover {
      background-color: var(--mms-simple-background-disabled, ${p => p.theme.generic.dark.backgroundDisabled});
    }
  }
`;

export default MenuItem;
