import Event from '../../Event';

class EditorOnPaste extends Event {
  record() {
    super.record('EditorOnPaste', {
      category: 'Editor',
      mainValue: '',
      newName: 'suite--editor--content-pasted',
    });
  }
}

export default new EditorOnPaste();
