import isNil from 'lodash/isNil';
import { rgba as rgbaPolished } from 'polished';

const rgba = (color, alpha) => rgbaPolished(color, alpha);
const apply = value => (typeof value === 'number' ? `${value}px` : value);

const defaultProcessKey = key => {
  let source = key;
  let property = key;
  // allow re-mapping of property
  // [input, output]
  if (typeof key === 'object' && Array.isArray(key)) {
    source = key[0];
    property = key[1];
  }

  return [source, property];
};
const defaultProcessValue = value => apply(value);

const reduceProps = (supported, handlers = {}) => {
  const {
    processPropKey = defaultProcessKey,
    processPropValue = defaultProcessValue,
  } = handlers;

  return props =>
    supported.reduce((acc, key) => {
      const [source, property] = processPropKey(key);
      const value = props?.[source];
      const style = !isNil(value) ? { [property]: processPropValue(value) } : {};

      return {
        ...acc,
        ...style,
      };
    }, {});
};

export default {
  rgba,
  apply,
  reduceProps,
};
