import { ReduxListener } from '../../../types';

import adwordsKeywords from './adwordsKeywords';
import adwordsQuestions from './adwordsQuestions';
import bulkAdwordsKeywords from './bulkAdwordsKeywords';
import inventoryItems from './inventoryItems';
import knowledgeGraph from './knowledgeGraph';
import linkingRecommendations from './linkingRecommendations';
import serpData from './serpData';
import serpPagesData from './serpPagesData';
import serpQuestions from './serpQuestions';
import serpUserIntentData from './serpUserIntentData';
import websiteHeatmap from './websiteHeatmap';

const setupListeners = (startListening: ReduxListener) =>
  [
    adwordsKeywords,
    adwordsQuestions,
    bulkAdwordsKeywords,
    inventoryItems,
    knowledgeGraph,
    linkingRecommendations,
    serpData,
    serpPagesData,
    serpUserIntentData,
    serpQuestions,
    websiteHeatmap,
  ].forEach(listener => listener(startListening));

export default setupListeners;
