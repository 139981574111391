export const PARAMS_LIST = [
  // hydrate signup
  // user
  'onboardingData',
  'email',
  // payment
  'coupon',
  'planName',
  // 'code' -> conflicts with auth0

  // marketing tracking
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
];
