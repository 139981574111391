import styled from 'styled-components';

import * as SL from '../SimpleList/themes/dropdown';
import DataListItem from './DataListItem';

const DataListRow = styled(SL.Row)`
  height: unset;
  min-height: 32px;
  padding-top: ${p => p.theme.spacing.xxSmall};
  padding-bottom: ${p => p.theme.spacing.xxSmall};
  text-align: left;
  ${p => p.active && `background: ${p.theme.colors.lightblue};`}
  &:hover {
    background-color: var(
      --mms-simple-background-hover,
      ${p => p.theme.generic.light.backgroundHover}
    );
  }

  ${DataListItem}:hover {
    background-color: ${p =>
      !p.hoverDisabled &&
      `var(--mms-simple-background-hover, ${p.theme.generic.light.backgroundHover})`};
  }

  ${p =>
    p.headerRow &&
    `
    height: 41px;
    min-height: 41px;
    padding-top: 0;
    padding-bottom: 0;
    background: var(--mms-simple-background, ${p.theme.generic.light.background})};
    border-bottom: 1px solid var(--mms-simple-border, ${p.theme.generic.light.border}) !important;
    border-radius: 4px 4px 0 0;
    overflow: hidden;

    ${DataListItem} {
      padding: 0;
    }
  `}
`;

export default DataListRow;
