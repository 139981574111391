/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import * as React from 'react';
import { isNil, isEmpty } from 'lodash';
import { CellContext, RowData } from '@tanstack/table-core';
import { twMerge } from 'tailwind-merge';

import { format } from '@marketmuse/utilities';

import { Box } from '../../Box';
import { Text } from '../../Text';

export interface IPropsCell {
  children?: any;
  className?: string | Array<string | string[]>;
  id: string;
  width?: number;
}

const floatFormatter = (value: number) =>
  Number.parseFloat(value.toString()).toFixed(2);

const serializeOptionalNumber = (
  value: string | number | undefined | null,
  formatter: (
    value: number,
    args?: { type?: 'default' | 'money' },
  ) => string | number,
  args?: { type?: 'default' | 'money' },
) => {
  if (value === undefined) {
    return '';
  }

  if (isNaN(value as number) || isNil(value)) {
    return 'N/A';
  }

  return formatter(value as number, args);
};

export const TableCellString = (cell: any) => {
  return <Text className={'font-semibold'}>{cell.getValue() || ''}</Text>;
};

export const TableCellNumber = (cell: any) => {
  const value = cell.getValue();

  return (
    <Text className={'font-semibold'}>
      {serializeOptionalNumber(value, format)}
    </Text>
  );
};

export const TableCellFloat = (cell: any) => {
  const value = cell.getValue();

  return (
    <Text className={'font-semibold'}>
      {serializeOptionalNumber(value, floatFormatter)}
    </Text>
  );
};

export const TableCellMoney = (cell: any) => {
  const value = cell.getValue();

  return (
    <Text className={'font-semibold'}>
      {serializeOptionalNumber(value, format, { type: 'money' })}
    </Text>
  );
};

export const TableCellDateTime = (cell: any) => {
  const value = cell.getValue();
  let children = 'N/A';

  if (!isEmpty(value.toString())) {
    children = new Intl.DateTimeFormat().format(new Date(value));
  }

  return <Text className={'font-semibold'}>{children}</Text>;
};

const TableCell = ({ children, className, id, width }: IPropsCell) => {
  return (
    <Box
      data-mms--datatable-cell={id}
      className={twMerge(
        'px-6',
        'py-3',
        'flex',
        'flex-column',
        'items-center',
        className,
      )}
      style={{ width }}
    >
      {children}
    </Box>
  );
};

export default React.memo(TableCell);
