import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isNil from 'lodash/isNil';

import Popper from '../Tooltip/Popper';

const PopperStyled = styled(Popper)``;

const callDefault = (e, fn, inputProps) => {
  if (typeof inputProps[fn] === 'function') {
    inputProps[fn](e);
  }
}

const onSubmit = (item, args) => {
  if (item) {
    if (typeof args.onSubmit === 'function') { args.onSubmit(item) }
    else { args.setValue(args.getItemValue(item)) }
  }
}

const onComplete = (item, args) => {
  if (item) {
    let val = args.getItemValue(item);
    if (typeof args.onComplete === 'function') { val = args.onComplete(item); }
    if (val) args.setValue(val);
  }
}

const keyPressed = args => {

  // the item action was taken on
  const item = isNil(args.activeIndex) ? null : args.items[args.activeIndex];

  // -- esc
  // disable the autocomplete when esc pressed
  if (args.event.keyCode === 27) {
    args.setDisabled(true);
    args.setActiveIndex(null);
  }

  // -- enter
  // on submit, disable autocomplete
  // and prevent the default action
  if (args.event.keyCode === 13) {
    args.setDisabled(true);
    onSubmit(item, args);
    // prevent event only an item is selected
    // otherwise propagate the default event
    if (item) return true;
  }

  // -- tab
  // on tab just autocomplete
  if (args.event.keyCode === 9) {
    onComplete(item, args);
    if (item) { args.event.preventDefault(); }
  }

  // -- [0-9], [a-z]
  // enable autocomplete when these are pressed
  if (args.event.keyCode >= 48 && args.event.keyCode <= 90) {
    args.setDisabled(false);
  }

  // up arrow
  if (args.event.keyCode === 38) {
    args.event.preventDefault();
    if (isNil(args.activeIndex)) {
      args.setActiveIndex(args.items.length - 1);
    } else {
      const nextIndex = args.activeIndex - 1;
      args.setActiveIndex(nextIndex >= 0 ? nextIndex : args.items.length - 1);
    }
  }

  // down arrow
  if (args.event.keyCode === 40) {
    args.event.preventDefault();
    if (isNil(args.activeIndex)) {
      args.setActiveIndex(0);
    } else {
      const nextIndex = args.activeIndex + 1;
      args.setActiveIndex(nextIndex < args.items.length ? nextIndex : 0);
    }
  }
}

const Autocomplete = props => {

  const InputComponent = props.inputComponent;
  const handleId = `${props.id || ''}-autocomplete-dropdown`;

  const [disabled, setDisabled] = useState(false);
  const [focused, setFocused] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  const inputRef = useRef(null);

  const text = props.inputProps['value'] || props.inputProps['targetValue'];
  const items = props.popperProps['items'] || [];

  const itemsProcessed = items
    .map(i => ({
      ...i,
      hideTooltip: true,
      onClick: args => {
        onSubmit(i, props);
        if (typeof i.onClick === 'function') i.onClick(args);
      }
    }))

  // filter out items that should not be rendered
  const itemsFiltered = itemsProcessed
    .filter((item, index) => props.shouldItemRender({ item, index, text }));

  // set active flags
  const itemsWithSelection = itemsFiltered.map((i, index) => {
    i.active = index === activeIndex;
    return i;
  })

  // get width of the input wrapper and set it to
  // the width of the dropdown list
  let width = props.width || -1;
  if (props.fullWidth) {
    try {
      width = inputRef.current.wrapper.current.getBoundingClientRect().width;
      if (!isNil(props.offset)) width += props.offset;
      if (!isNil(props.padding)) width -= (props.padding * 2);
    }
    catch {
      // ignore
    }
  }

  let offset = null;
  if (!isNil(props.offset)) offset = -1 * (props.offset - (props.padding || 0))

  return (
    <PopperStyled
      {...props.popperProps}
      style={props.style}
      offset={offset}
      condensed
      items={(itemsWithSelection || []).slice(props.maxItems || 0)}
      handleId={handleId}
      position="bottom-start"
      disabled={disabled || itemsWithSelection.length === 0}
      listStyle={{ width, ...(props.listStyle || {}) }}
      open={!props.isClosed && focused ? true : void 0}
    >
      <InputComponent
        {...props.inputProps}
        ref={inputRef}
        preventSubmit={!isNil(activeIndex) && !disabled}
        onClick={e => {
          // reset the disabled state once focuses out
          setDisabled(false);
          // if onClick function present, call it with event
          callDefault(e, 'onClick', props.inputProps);
        }}
        onFocus={e => {
          // set focus to true
          setFocused(true);
          // if onFocus function present, call it with event
          callDefault(e, 'onFocus', props.inputProps);
        }}
        onBlur={e => {
          setTimeout(() => {
            // reset the disabled state once focuses out
            setDisabled(false)
            // set focus to false
            setFocused(false);
          }, 300);

          // if onblur function present, call it with event
          callDefault(e, 'onBlur', props.inputProps);
        }}
        onKeyDown={e => {
          // call key pressed handler
          const prevent = keyPressed({
            inputRef,
            setDisabled,
            activeIndex,
            setActiveIndex,
            event: e,
            items: itemsFiltered,
            inputProps: props.inputProps,
            setValue: props.setValue,
            getItemValue: props.getItemValue,
            onSubmit: props.onSubmit,
            onComplete: props.onComplete,
          });

          // if event not prevented and onKeyDown function present, call it
          if (!prevent) {
            callDefault(e, 'onKeyDown', props.inputProps);
          }
          // prevent event and stop it from propagating
          if (prevent) {
            e.preventDefault();
            e.stopPropagation();
            return false;
          }
        }}
      />
    </PopperStyled>
  )
};

Autocomplete.propTypes = {
  id: PropTypes.string,
  offset: PropTypes.number, // start autocomplete from left (usually for padding)
  width: PropTypes.number,
  fullWidth: PropTypes.bool, // set width to cover the whole input
  onSubmit: PropTypes.func, // on enter or click
  onComplete: PropTypes.func, // on tab - return text to autocomplete to, false to disable
  setValue: PropTypes.func, // function to modify the text value
  isClosed: PropTypes.bool, // set to true to force close the autocomplete
  inputComponent: PropTypes.object, // input component to render
  inputProps: PropTypes.object, // props for the input component
  popperProps: PropTypes.object, // props for popper
  maxItems: PropTypes.number, // max number of items to render
  style: PropTypes.object,

  // functions related to individual item object
  getItemValue: PropTypes.func, // function to retrieve value from item obj (item) -> string
  getItemCta: PropTypes.func,
  shouldItemRender: PropTypes.func, // ({ item, index, text }) -> bool
};

Autocomplete.defaultProps = {
  popperProps: {},
  inputProps: {},
  getItemValue: item => item.text,
  shouldItemRender: ({ item = {}, index, text = '' }) => {
    // by default, render matching items
    const itemText = item.text || '';
    const itemTextLowercase = itemText.toLowerCase();
    const textLowercase = text.toLowerCase();
    return itemTextLowercase.indexOf(textLowercase) !== -1;
  },
}

export default Autocomplete;
