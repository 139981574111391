import { isNil } from 'lodash';

import * as types from '../../config/types';

export const serializeBriefFormData = input => {
  const data = { ...input };
  const formData = data?.formData;
  if (!isNil(formData) && typeof formData !== 'string') {
    data.formData = JSON.stringify(formData);
  }

  return data;
};

export default () => () => next => action => {
  if (action.type === types.UPDATE_REPORT) {
    return null;
  }

  return next(action);
};
