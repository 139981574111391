import { FC, ReactNode } from 'react';

import { RootState, ResearchViews } from '@marketmuse/data-state/types';
import { useStoreSelector } from '@marketmuse/data-state/hooks';
import { ResearchViewModelContext } from '../context';

const selectView = (currentView: ResearchViews) => (state: RootState) =>
  state?.appResearch?.views?.[currentView];

const ResearchViewModelProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const viewHeatmap = useStoreSelector(selectView(ResearchViews.HEATMAP));
  const viewKeywords = useStoreSelector(selectView(ResearchViews.KEYWORDS));
  const viewQuestions = useStoreSelector(selectView(ResearchViews.QUESTIONS));
  const viewReflect = useStoreSelector(selectView(ResearchViews.REFLECT));
  const viewTopicModel = useStoreSelector(
    selectView(ResearchViews.TOPIC_MODEL),
  );
  const viewXray = useStoreSelector(selectView(ResearchViews.XRAY));

  const context = {
    [ResearchViews.HEATMAP]: viewHeatmap,
    [ResearchViews.KEYWORDS]: viewKeywords,
    [ResearchViews.QUESTIONS]: viewQuestions,
    [ResearchViews.REFLECT]: viewReflect,
    [ResearchViews.TOPIC_MODEL]: viewTopicModel,
    [ResearchViews.XRAY]: viewXray,
  };

  return (
    <ResearchViewModelContext.Provider value={context}>
      {children}
    </ResearchViewModelContext.Provider>
  );
};

export default ResearchViewModelProvider;
