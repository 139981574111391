import styled from 'styled-components';

import inputStyles from './inputStyles';

/**
* IMPORTANT!
* These are global styles that applies to EVERY input of every theme.
*
* Please avoid making changes as much as possible.
* If you can achieve your goal by creating a new theme, please do so.
* If you HAVE TO make a chance, please check EVERY story
* on storybook to make sure everything renders correctly!
*/
export default styled.input`
  ${inputStyles};
  padding: 0 ${p => p.theme.spacing.small};
`;
