import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { camelCase, kebabCase } from 'lodash';

import IconSvg from '../../../components/IconSvg/IconSvg';

import { BRIEF_STATUS, BRIEF_STATUS_TITLES } from '../../../config/briefStatus';
import isPageActive from '../../../utils/isPageActive';
import { useSuiteNavigate } from '../../../hooks/useSuiteNavigate';

import { Separator, SidebarPaddingLR } from '../components/shared';
import SidebarStatusFilters from '../components/SidebarStatusFilters';
import SidebarItem from '../components/SidebarItem';

const BriefingSidebarScreen = ({
  activeScreen,
  canOrderItems,
  collapsed,
  historyPathname,
  labelLoading,
  screenId,
  setActiveScreen,
}) => {
  const navigate = useSuiteNavigate();
  const briefingCounts = useSelector(state => state.misc?.counts?.briefs) ?? {};
  const briefingCountsLoading = false;
  const filters = useMemo(() => {
    const lastUrlParam = window?.location?.pathname?.split('/')?.pop();
    const activeStatus = lastUrlParam === 'briefs' ? null : lastUrlParam;

    return [
      {
        order: 1,
        icon: (
          <IconSvg
            color="redBase"
            name="circle-progress-25"
            size="16"
            box="18"
          />
        ),
        activeLabelBG: 'redBase',
        label: briefingCounts?.[camelCase(BRIEF_STATUS.CART)] ?? 0,
        title: BRIEF_STATUS_TITLES[BRIEF_STATUS.CART]?.title,
        isActive: activeStatus === kebabCase(BRIEF_STATUS.CART),
        onClick: () =>
          navigate(`inventory/briefs/${kebabCase(BRIEF_STATUS.CART)}`),
      },
      {
        order: 2,
        icon: (
          <IconSvg
            color="yellowBase"
            name="circle-progress-50"
            size="16"
            box="18"
          />
        ),
        activeLabelBG: 'yellowBase',
        label: briefingCounts?.[camelCase(BRIEF_STATUS.IN_PROGRESS)] ?? 0,
        title: BRIEF_STATUS_TITLES[BRIEF_STATUS.IN_PROGRESS]?.title,
        isActive: activeStatus === kebabCase(BRIEF_STATUS.IN_PROGRESS),
        onClick: () =>
          navigate(`inventory/briefs/${kebabCase(BRIEF_STATUS.IN_PROGRESS)}`),
      },
      {
        order: 3,
        icon: (
          <IconSvg
            color="greenBase"
            name="circle-progress-75"
            size="16"
            box="18"
          />
        ),
        activeLabelBG: 'greenBase',
        label: briefingCounts?.[camelCase(BRIEF_STATUS.QA_READY)] ?? 0,
        title: BRIEF_STATUS_TITLES[BRIEF_STATUS.QA_READY]?.title,
        isActive: activeStatus === kebabCase(BRIEF_STATUS.QA_READY),
        onClick: () =>
          navigate(`inventory/briefs/${kebabCase(BRIEF_STATUS.QA_READY)}`),
      },
      {
        order: 4,
        icon: (
          <IconSvg
            color="blueBase"
            name="circle-progress-done"
            size="16"
            box="18"
          />
        ),
        activeLabelBG: 'blueDark',
        label: briefingCounts?.[camelCase(BRIEF_STATUS.DELIVERED)] ?? 0,
        title: BRIEF_STATUS_TITLES[BRIEF_STATUS.DELIVERED]?.title,
        isActive: activeStatus === kebabCase(BRIEF_STATUS.DELIVERED),
        onClick: () =>
          navigate(`inventory/briefs/${kebabCase(BRIEF_STATUS.DELIVERED)}`),
      },
    ];
  }, [historyPathname, briefingCounts]);

  useEffect(() => {
    if (!canOrderItems && activeScreen === screenId) {
      setActiveScreen('main');
    }
  }, [canOrderItems, activeScreen, screenId]);

  return (
    <>
      <SidebarItem
        header
        paddingLR
        icon={<IconSvg name="arrow-left" size="16" />}
        title="Back to Main Menu"
        collapsed={collapsed}
        onClick={() => setActiveScreen('main')}
      />
      <SidebarPaddingLR>
        <Separator />
        <SidebarItem
          header
          title="Briefs"
          collapsed={collapsed}
          color="grey05"
          icon={<IconSvg name="briefs" size="16" box={20} />}
        />
        <SidebarItem
          collapsed={collapsed}
          shouldFitContent
          title="All Briefs"
          active={isPageActive('inventory/briefs', true)}
          onClick={() => navigate('inventory/briefs')}
        />
        <SidebarStatusFilters
          filters={filters}
          collapsed={collapsed}
          labelLoading={labelLoading}
          isLoading={briefingCountsLoading}
        />
      </SidebarPaddingLR>
    </>
  );
};

BriefingSidebarScreen.propTypes = {
  collapsed: PropTypes.bool,
  labelLoading: PropTypes.bool,
  historyPathname: PropTypes.string,
};

export default BriefingSidebarScreen;
