import * as React from 'react';
import { appResearchSlices } from '@marketmuse/data-state/appResearch';
import { IResearchDataContext } from '../types';

const initialState = {
  data: appResearchSlices.initialState.data,
  network: appResearchSlices.initialState.network,
};

const ResearchDataContext =
  React.createContext<IResearchDataContext>(initialState);

export default ResearchDataContext;
