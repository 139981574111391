import {
  DisplayTableDict,
  DisplayTableColumn,
} from '../../types/displayTables';
import common from './common';
const table = 'pages';
const pageColumns: DisplayTableDict<DisplayTableColumn> = {
  numTopics: {
    field: 'numTopics',
    title: 'Related Topics',
    table,
  },
  actionBar: {
    field: 'actionBar',
    title: 'Actions',
    table,
  },
  rank: {
    field: 'maxRank',
    title: 'Rank Range',
    table,
  },
  url: {
    field: 'url',
    title: 'Url',
    table,
  },
  pageTitle: {
    field: 'title',
    title: 'Page Title',
    table,
  },
  topValuedTopic: {
    field: 'topValuedTopic',
    title: 'Top Valued Topic',
    table,
  },
  competitiveAdvantage: {
    field: 'avgMmCompetitiveAdvantage',
    title: 'Avg. Competitive Advantage',
    table,
  },
  difficultyPersonalized: {
    field: 'avgPersonalizedMmDifficulty',
    title: 'Avg. Personalized Difficulty',
    table,
  },
  value: { ...common['trafficValue'], table },
  valueChange: { ...common['trafficValueDelta'], table },
  traffic: { ...common['traffic'], table },
  trafficChange: {
    ...common['trafficDelta'],
    table,
  },
  internalLinks: {
    field: 'internalContentLinkCount',
    title: 'Internal Links',
    table,
  },
  externalLinks: {
    field: 'externalContentLinkCount',
    title: 'External Links',
    table,
  },
  valueFromTopic: {
    ...common['versionTrafficValuePct'],
    table,
  },
  trafficFromTopic: {
    ...common['versionTrafficPct'],
    table,
  },
  potentialValue: {
    ...common['potentialTrafficValue'],
    table,
  },
  trafficChangePct: {
    ...common['trafficDeltaPct'],
    table,
  },
  authority: {
    field: 'authority',
    title: 'Page Authority',
    table,
  },
  topicVolume: {
    field: 'sumVolume',
    title: 'Search Volume',
    table,
  },
  topicAuthorityColumn: {
    field: 'avgMmPersonalizedAdvantage',
    title: 'Avg. Topic Authority',
    table,
  },
  potentialTraffic: {
    ...common['potentialTraffic'],
    table,
  },
  potentialTrafficUpside: {
    ...common['potentialTrafficUpside'],
    table,
  },
};

export default pageColumns;
