import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isNil, trim } from 'lodash';
import { sanitizeOrgSlug } from '@marketmuse/utilities';

import { selectSite } from '../actions/siteActions';
import useGuestTokens from './useGuestTokens';
import { useSuiteNavigate } from './useSuiteNavigate';

const useSiteNavigate = ({
  siteId,
  slug,
  resetApps,
  skipFetchPermissions = false,
} = {}) => {
  const { hasGuestAccess } = useGuestTokens();
  const dispatch = useDispatch();
  const navigate = useSuiteNavigate();

  return useCallback(
    ({ nextSlug, nextSiteId, path }) => {
      if (hasGuestAccess) {
        return;
      }

      let newPathName = `/${slug}/${nextSiteId}`;

      if (slug !== nextSlug && nextSlug) {
        const lastPath = new RegExp('^/' + slug);
        newPathName = newPathName.replace(lastPath, `/${nextSlug}`);
      }
      if (siteId !== nextSiteId) {
        newPathName = newPathName.replace(`/${siteId}`, `/${nextSiteId}`);
      }

      if (path) {
        newPathName += `/${path}`;
      }

      const finalPath = `a/${trim(newPathName, '/')}`;

      dispatch(selectSite({ id: nextSiteId, resetApps, skipFetchPermissions }));
      navigate(finalPath, { root: true });
    },
    [navigate, dispatch, slug, siteId, hasGuestAccess],
  );
};

const useSiteChange = ({
  slug: _slug,
  siteId: _siteId,
  resetApps,
  skipFetchPermissions = false,
} = {}) => {
  const { siteId: psiteId, slug: pslug } = useParams();
  const siteId = psiteId || _siteId;
  const slug = sanitizeOrgSlug(pslug || _slug);

  const navigate = useSiteNavigate({
    slug,
    siteId,
    resetApps,
    skipFetchPermissions,
  });

  const setSite = useCallback(
    ({ siteId: nextSiteId, slug: _nextSlug, path }) => {
      if (isNil(nextSiteId)) {
        return;
      }
      const nextSlug = sanitizeOrgSlug(_nextSlug || slug);
      navigate({ nextSiteId, nextSlug, path });
    },
    [navigate, slug],
  );

  return setSite;
};

export default useSiteChange;
