import * as types from '../../config/types';
import { REACT_APP } from '../../config/env';

export default () => () => next => action => {
  if (action.type === types.GET_SNAPSHOT_DATA) {
    fetch(`${REACT_APP.S3_APP_URI}?key=${action.key}`)
      .then(raw => raw.json())
      .then(res => {
        if (typeof action.callback === 'function') {
          action.callback(res);
        }
      })
      .catch(() => {
        if (typeof action.callback === 'function') {
          action.callback(null);
        }
      });
  }

  return next(action);
};
