import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';

import { APPLICATIONS_CONFIG_FLAT } from '../../../../config/getApplicationsConfig';
import Page from '../../../../components/Page/Page';
import PageHeader from '../../../../components/Page/PageHeader';
import PageBody from '../../../../components/Page/PageBody';
import SlidingTable from '../../../../components/SlidingTable';
import Url from '../../../../components/Url';
import Dotdotdot from '../../../../components/Dotdotdot';
import IconSvg from '../../../../components/IconSvg/IconSvg';
import Pagination, { TableFooter } from '../../../../components/Pagination';
import appExecuted from '../../../../models/tracker/events/apps/AppExecuted';

import * as types from '../../../../config/types';
import * as toolsActions from '../../../../actions/toolsActions';

import getDate from '../../../../utils/getDate';

class Snapshots extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      page: 0,
      queries: null,
      thereIsMore: false,
    };

    this.limit = 20;
    this.scrollParent = React.createRef();

    this.load = this.load.bind(this);
    this.loadItemInApp = this.loadItemInApp.bind(this);
  }

  componentDidMount() {
    this.load();
  }

  load() {
    this.props.toolsActions.getAppQueries({
      options: {
        app: this.props.app,
        page: this.state.page,
      },
      callback: data => {
        this.setState({
          queries: get(data, 'items'),
          thereIsMore: get(data, 'count') > this.limit * (this.state.page + 1),
        });
      },
    });
  }

  loadItemInApp(item) {
    appExecuted.record(
      {
        application: get(item, 'jsonMetadata.app'),
        params: get(item, 'jsonMetadata.params'),
      },
      {
        fromSnapshot: true,
      },
    );

    this.props.loadSnapshotData(item.id);
    if (typeof this.props.close === 'function') {
      this.props.close();
    }
  }

  render() {
    const queries = this.state.queries || [];
    const isLoading = this.props.loadings[types.GET_APP_QUERIES];

    return (
      <Page>
        <PageHeader
          icon={<IconSvg name="pages" size="28" />}
          title="Snapshots"
          hideHelp
        />
        <PageBody>
          <SlidingTable
            id="queries_table"
            ref={this.scrollParent}
            sortChanged={() => {}}
            fullWidth
            emptyState={{ text: 'No snapshots' }}
            loading={isLoading}
            items={queries}
            onRowClick={this.loadItemInApp}
            table={{
              topic: {
                id: 'topic',
                flexGrow: 1,
                headerChildren: () => 'TOPIC',
                colChildren: ({ item }) =>
                  get(item, 'jsonMetadata.params.topic'),
              },
              url: {
                id: 'url',
                width: 180,
                headerChildren: () => 'PAGE',
                colChildren: ({ item }) => {
                  const url = get(item, 'jsonMetadata.params.url');
                  if (!url) return '-';
                  return (
                    <Dotdotdot lines={2}>
                      <Url href={url || null}>{url || '-'}</Url>
                    </Dotdotdot>
                  );
                },
              },
              date: {
                id: 'date',
                width: 120,
                headerChildren: () => 'DATE RAN',
                colChildren: ({ item }) =>
                  getDate(get(item, 'createdAt'), { forceIncludeYear: true }),
              },
              app: {
                id: 'app',
                width: 180,
                headerChildren: () => 'APPLICATION',
                colChildren: ({ item }) =>
                  get(
                    APPLICATIONS_CONFIG_FLAT,
                    `['${get(item, 'jsonMetadata.app')}'].title`,
                  ),
              },
              user: {
                id: 'user',
                width: 120,
                headerChildren: () => 'ORDERED BY',
                colChildren: ({ item }) =>
                  get(item, 'user.fullName') || get(item, 'user.email'),
              },
            }}
            footer={
              <TableFooter style={{ marginTop: -1 }}>
                <Pagination
                  page={this.state.page + 1}
                  onNext={
                    !this.state.thereIsMore
                      ? void 0
                      : () => {
                          this.setState(
                            { page: this.state.page + 1 },
                            this.load,
                          );
                        }
                  }
                  onPrev={
                    this.state.page === 0
                      ? void 0
                      : () => {
                          this.setState(
                            { page: this.state.page - 1 },
                            this.load,
                          );
                        }
                  }
                  scrollParent={this.scrollParent}
                />
              </TableFooter>
            }
          />
        </PageBody>
      </Page>
    );
  }
}

Snapshots.propTypes = {
  app: PropTypes.string,
  misc: PropTypes.object,
  loadings: PropTypes.object,
  toolsActions: PropTypes.object,
  close: PropTypes.func,
  loadSnapshotData: PropTypes.func,
};

const mapStateToProps = state => ({
  misc: state.misc,
  loadings: state.loadings,
});

const mapDispatchToProps = dispatch => ({
  toolsActions: bindActionCreators(toolsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Snapshots);
