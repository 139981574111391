import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trim } from 'lodash';

import Button from '../../../components/Button';
import IconSvg from '../../../components/IconSvg';
import Modal, {
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '../../../components/Modal';
import SimpleFields from '../../../components/SimpleFields';

import * as FIELDS from '../../../hooks/useFields/config';
import * as types from '../../../config/types';

import { toggleDynamic } from '../../../actions/miscActions';
import { inventoryCreateOrg } from '../../../actions/inventoryActions';

import useFields from '../../../hooks/useFields';

const initialState = { title: '', sfdcAccountId: '' };

const CreateOrg = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    store => store.loadings?.[types.CREATE_INVENTORY_ORG],
  );

  const [formFields, , [valueState, setValueState], [errors]] = useFields({
    fields: FIELDS.getNewOrgFields(),
    initialState,
  });

  const isDisabled = useMemo(
    () => !trim(valueState.title) || isLoading,
    [valueState, isLoading],
  );

  const handleChange = useCallback(
    (value, name) => setValueState({ name, value }),
    [setValueState],
  );

  const close = () => {
    dispatch(
      toggleDynamic({
        id: 'create-org',
      }),
    );
  };

  const createOrg = () => {
    const organizationInputs = {
      name: valueState.title,
    };
    if (valueState?.sfdcAccountId)
      organizationInputs['sfdcAccountId'] = valueState?.sfdcAccountId;
    dispatch(
      inventoryCreateOrg({
        fields: organizationInputs,
        callback: createdOrg => {
          if (createdOrg?.id) {
            close();
          }
        },
      }),
    );
  };
  const modalHeaderDescription = (
    <p>
      Enter a new Organization’s name as you would like it to appear on the
      Organization list.
    </p>
  );

  return (
    <Modal hideClose close={close} style={{ width: '512px' }}>
      <ModalHeader
        icon={<IconSvg name="edit" size={16} />}
        title="Create New Organization"
        desc={modalHeaderDescription}
      />
      <ModalBody>
        <SimpleFields
          fields={formFields}
          state={valueState}
          errors={errors}
          handleChange={handleChange}
          renderSubmit={<></>}
        />
      </ModalBody>
      <ModalFooter>
        <Button bold large onClick={close} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          type="submit"
          large
          primary
          disabled={isDisabled}
          onClick={() => createOrg()}
        >
          Create
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default memo(CreateOrg);
