// This hook takes a value, and when this value
// changes, it returns the change delta for a given
// duration (defaults to 1000ms); returns zero on idle,
// which indicates change delta of zero. If another change
// occurs before the highlight period for the current
// change ends, the counter will reset and the returned
// delta will update. This is useful for short-term DOM
// manipulation to indicate change in a state value / prop.

import { useState, useEffect, useRef } from 'react';

import useValueChange from '../utils/useValueChange';

export default (value, { prev, duration = 1000 } = {}) => {
  const [change, setChange] = useState(0);
  const indicatorEnd = useRef(null);
  const valueChange = useValueChange(value, { prev });

  useEffect(() => {
    // if delta is a number and is something other
    // than zero (ie. meaning it changed)
    if (Number.isInteger(valueChange.delta) && valueChange.delta !== 0) {
      // set change indicator to delta
      setChange(valueChange.delta);

      // if a current change period is in effect
      if (indicatorEnd.current) {
        clearTimeout(indicatorEnd.current);
      }

      // start timeout that will set the change back to zero
      indicatorEnd.current = setTimeout(() => {
        if (indicatorEnd.current) {
          setChange(0);
        }
      }, duration);
    }

    return () => clearTimeout(indicatorEnd.current);
  }, [value]);

  return change;
};
