import * as React from 'react';

import { IResearchPermissionsContext } from '../types';

const permissions = {
  canAccessHeatmapSerp: false,
  canAccessHeatmapWebsite: false,
  canAccessXray: false,
  canAddToInventory: false,
  canDisplaySiteContentData: false,
  canExportConnect: false,
  canExportHeatmap: false,
  canExportSerpXray: false,
  canExportTopicNavigator: false,
  canFilterSerpXray: false,
  canFilterTopicNavigator: false,
  canSortHeatmap: false,
  hasViableSiteInventory: false,
  shouldGateConnect: false,
  shouldGateKeywords: false,
  shouldGateQuestions: false,
  shouldGateReflectTable: false,
  shouldLimitDataTopicNavigator: false,
};

const ResearchPermissionsContext =
  React.createContext<IResearchPermissionsContext>(permissions);

export default ResearchPermissionsContext;
