import React from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import styled from 'styled-components';

import IconSvg from '../IconSvg';
import Clickable from '../Clickable';

export const ModalHeaderWrapper = styled.div`
  order: 3;
  color: ${p => p.theme.colors.grey85};
  padding: ${p => p.theme.spacing.large} ${p => p.theme.spacing.large}
    ${p => p.theme.spacing.medium} ${p => p.theme.spacing.large};

  ${p =>
    p.hasBorderBottom &&
    `
    padding-bottom: 0;
 `};
`;

const Header = styled.header`
  position: relative;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
`;

const HeaderTitle = styled.div`
  ${p => p.theme.type.heading2};
  color: ${p => p.theme.colors.black};
`;

const HeaderDesc = styled.div`
  ${p => p.theme.type.richText};
  margin-top: ${p => p.theme.spacing.small};
  ${p => p.theme.type.textRegular};

  &:first-child {
    margin-top: 0;
  }
`;

const HeaderIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  color: ${p => p.theme.colors.grey85};
`;

const HeaderCloseIcon = styled(Clickable)`
  transition: color 0.3s ${p => p.theme.transitionFn};

  &:hover {
    color: ${p => p.theme.colors.black};
  }
`;

const HeaderBorderBottom = styled.span`
  margin-top: ${p => p.theme.spacing.regular};
  margin-bottom: ${p => p.theme.spacing.medium};
  display: block;
  border-bottom: 1px solid ${p => p.theme.colors.grey15};
`;

const ModalHeader = ({
  close,
  icon,
  desc,
  title,
  contents,
  children,
  className,
  style,
  titleStyle,
  hasBorderBottom,
}) => (
  <ModalHeaderWrapper
    className={className}
    style={style}
    hasBorderBottom={hasBorderBottom}
  >
    {(!isNil(icon) || !isNil(title) || !isNil(close)) && (
      <Header>
        {!isNil(icon) && <HeaderIcon>{icon}</HeaderIcon>}
        {!isNil(title) && <HeaderTitle style={titleStyle}>{title}</HeaderTitle>}

        {!isNil(close) && (
          <>
            <div style={{ flexGrow: 1 }} />
            <HeaderCloseIcon onClick={close}>
              <IconSvg name={'close'} size={16} />
            </HeaderCloseIcon>
          </>
        )}
      </Header>
    )}

    {(!isNil(children) || !isNil(contents) || !isNil(desc)) && (
      <HeaderDesc>
        {!isNil(children) && children}
        {!isNil(contents) && contents}
        {!isNil(desc) && desc}
      </HeaderDesc>
    )}
    {hasBorderBottom && <HeaderBorderBottom role="presentation" />}
  </ModalHeaderWrapper>
);

ModalHeader.propTypes = {
  close: PropTypes.func,
  icon: PropTypes.any,
  title: PropTypes.any,
  titleStyle: PropTypes.object,
  hasBorderBottom: PropTypes.bool,

  // todo: unify to children
  contents: PropTypes.any,
  desc: PropTypes.any,
  children: PropTypes.any,

  // styled-components
  style: PropTypes.object,
  className: PropTypes.string,
};

export default ModalHeader;
