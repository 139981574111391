import { get } from 'lodash';
import { AppQueryStatus } from '@marketmuse/config/types/papi';
import { NetworkStatus } from '@marketmuse/config/types';
import { AppUiStatus } from '@marketmuse/config/types/applications';
import {
  AppQueryNetworkKeys,
  AppQueryNetworkStatus,
  ReduxListener,
} from '../../../types';

import { optimizeTaskStatusCheck } from '../../actions';
import { appQueryUpdate } from '../../thunks';
import { uiSlice } from '../../slices/';

const setup = (startListening: ReduxListener) => {
  const uiActions = uiSlice.actions;

  startListening({
    actionCreator: optimizeTaskStatusCheck,
    effect: async (action, api) => {
      const state = api.getState();
      const appOptimize = state.appOptimize;

      const isUiComplete = [
        AppQueryNetworkKeys.bulkAdwordsKeywords,
        AppQueryNetworkKeys.knowledgeGraph,
      ].every(path =>
        (
          [
            AppQueryNetworkStatus.complete,
            AppQueryNetworkStatus.error,
          ] as NetworkStatus[]
        ).includes(get(appOptimize.network, path)),
      );
      if (
        appOptimize.ui?.appQuery?.status !== AppQueryStatus.COMPLETED &&
        isUiComplete
      ) {
        api.dispatch(
          appQueryUpdate({
            appQueryId: appOptimize.ui.appQuery?.id,
            status: AppQueryStatus.COMPLETED,
          }),
        );
      }

      if (isUiComplete) {
        api.dispatch(uiActions.statusSet(AppUiStatus.complete));
      }
    },
  });
};

export default setup;
