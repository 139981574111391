import { Box } from '@marketmuse/components';
import { Tabs } from '@marketmuse/research-ui';
import { ConnectViews } from '../types';
import RecommendationExport from './RecommendationExport';

interface IChangeViewButton {
  title: string;
  targetView: ConnectViews;
  tooltip?: string;
}

const buttons: Array<IChangeViewButton> = [
  {
    title: 'Internal',
    targetView: ConnectViews.internal,
    tooltip: 'Links pointing to other pages on the same website.',
  },
  {
    title: 'External',
    targetView: ConnectViews.external,
    tooltip: 'Links pointing to other pages on a different website.',
  },
  {
    title: 'Network',
    targetView: ConnectViews.network,
    tooltip:
      'Links to other pages on sites that you control, based on your settings.',
  },
  {
    title: 'Competition',
    targetView: ConnectViews.competition,
    tooltip:
      'Links to your competitors appear here and not under the External tab, based on your settings.',
  },
];

const ActionBar = ({
  setView,
  currentView,
  loading,
  recommendationCount,
}: {
  setView: (view: ConnectViews) => void;
  currentView: ConnectViews;
  loading: boolean;
  recommendationCount: Record<ConnectViews, number>;
}) => {
  return (
    <Box
      className={[
        'flex',
        'flex-row',
        'space-x-4',
        'pb-4',
        'px-12',
        'sm:pr-4',
        'lg:pr-8',
      ]}
    >
      <Tabs
        style={{ width: 650 }}
        items={buttons.map(({ title, targetView, tooltip }) => ({
          id: `connect-${targetView}`,
          title: `${title}${
            !loading ? ` (${recommendationCount[targetView]})` : ''
          }`,
          active: currentView === targetView,
          onClick: () => setView(targetView),
          loading: loading,
          tooltip,
        }))}
      />

      <Box className={['flex-grow']} />
      <Box className={['ml-auto', 'mr-0', 'flex', 'items-center']}>
        <RecommendationExport loading={loading} />
      </Box>
    </Box>
  );
};

export default ActionBar;
