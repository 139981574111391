import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isNil from 'lodash/isNil';

import Portal from '../Portal'
import BackgroundBubbles from '../BackgroundBubbles';
import Button from '../Button';
import Clickable from '../Clickable';
import Icon from '../Icon';

const Wrapper = styled.div`
  position: fixed;
  left: 20px;
  bottom: 20px;
  width: 420px;
  min-height: 220px;
  background-color: white;
  z-index: 99999999;
  border-radius: 3px;
  box-shadow: ${p => p.theme.moonShadow};
  animation-duration: 0.2s !important;
  overflow: hidden;
  padding: 22px;
  display: flex;
  flex-flow: column;
`;

const CloseButton = styled(Clickable)`
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  font-size: 14pt;
  color: ${p => p.theme.mmxDarkGrey};
  &:hover { color: ${p => p.theme.colors.grey85}; }
`;

const Title = styled.div`
  flex-shrink: 0;
  font-weight: bold;
  font-size: 11pt;
  color: ${p => p.theme.colors.grey85};
  line-height: 22px;
`;

const Desc = styled.div`
  flex-shrink: 0;
  margin-top: 12px;
  margin-bottom: 12px;
  font-size: 11pt;
  line-height: 22px;
  color: ${p => p.theme.colors.grey85};
  max-height: 200px;
  overflow: auto;
  z-index: 9999;
`;

const Footer = styled.div``;

const ButtonStyled = styled(Button)`
  height: 38px;
`;


const MessagePopup = props => (
  <Portal>
    <Wrapper className="animated fadeInUp">
      <BackgroundBubbles readable />
      {
        !isNil(props.close) &&
        typeof props.close === 'function' && (
          <CloseButton onClick={props.close}>
            <Icon
              name="plus"
              size="16"
              style={{
                transform: 'rotate(45deg)'
              }}
            />
          </CloseButton>
        )
      }
      {!isNil(props.title) && <Title>{props.title}</Title>}
      {!isNil(props.description) && <Desc>{props.description}</Desc>}
      <div style={{ flexGrow: 1 }} />
      {
        !isNil(props.cta) &&
        !isNil(props.ctaClick) &&
        typeof props.ctaClick === 'function' && (
          <Footer>
            <ButtonStyled
              large
              primary
              onClick={props.ctaClick}
              disabled={props.ctaDisabled}
              {...(props.ctaProps || {})}
            >
              {props.cta}
            </ButtonStyled>
          </Footer>
        )}
      {props.children}
    </Wrapper>
  </Portal>
);

MessagePopup.propTypes = {
  children: PropTypes.any,
  title: PropTypes.any,
  description: PropTypes.any,
  close: PropTypes.func,
  cta: PropTypes.string,
  ctaClick: PropTypes.func,
};

export default MessagePopup;
