import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isNil, unset } from 'lodash';
import * as ACTION_TYPES from '../actionTypes';
import { SliceMisc, MISC_SIMPLE_KEYS } from '../types';

const initialState: SliceMisc = {};

export const miscSlice = createSlice({
  name: 'misc',
  initialState,
  reducers: {
    clear: () => {
      return {};
    },
    setSimple: (
      state,
      action: PayloadAction<{
        key: MISC_SIMPLE_KEYS;
        value: SliceMisc[keyof SliceMisc];
      }>,
    ) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        action => action.type === ACTION_TYPES.SET_SIMPLE && !action.persist,
        (state, action) => {
          return { ...state, [action.key]: action.value };
        },
      )
      .addMatcher(
        action => action.type === ACTION_TYPES.AGGREGATE_STATS_SET,
        (state, action) => {
          unset(action, 'data.__typename');
          unset(action, 'data.briefs.__typename');
          unset(action, 'data.appRuns.__typename');
          return {
            ...state,
            ...{
              counts: {
                ...(state?.counts || {}),
                ...(action?.data || {}),
              },
            },
          };
        },
      )
      .addMatcher(
        action => action.type === ACTION_TYPES.SIGN_OUT,
        state => {
          return {
            ...state,
            counts: void 0,
            version: void 0,
            userLoaded: void 0,
            initialPath: void 0,
            initiateLogout: void 0,
            ['x-auth-ghost']: void 0,
          };
        },
      )
      .addMatcher(
        action => action.type === ACTION_TYPES.TOGGLE_DYNAMIC,
        (state, action) => {
          const { id, status, data } = action.payload;
          return {
            ...state,
            [id]: isNil(status) ? !state[id] : status,
            [`${id}-data`]: isNil(data) ? null : data,
          };
        },
      );
  },
});

export const miscActions = miscSlice.actions;
export const miscReducer = miscSlice.reducer;
