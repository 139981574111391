import { get } from 'lodash';
import { NetworkStatus } from '@marketmuse/config/types';
import { PAPI } from '@marketmuse/config/types';
import { validatePermission } from '@marketmuse/utilities';
import { Permission } from '@marketmuse/config/types/papi';
import {
  ResearchNetworkStatus,
  AppQueryNetworkKeys,
  AppQueryNetworkStatus,
} from '../../../types';

const paths = [
  AppQueryNetworkKeys.adwordsKeywords,
  AppQueryNetworkKeys.adwordsQuestions,
  AppQueryNetworkKeys.knowledgeGraph,
  AppQueryNetworkKeys.linkingRecommendations,
  AppQueryNetworkKeys.serpData,
  AppQueryNetworkKeys.serpPages,
  AppQueryNetworkKeys.serpQuestions,
  AppQueryNetworkKeys.serpUserIntentData,
];

const checkIsUiComplete = ({
  permissions,
  network,
}: {
  permissions: Permission[];
  network: ResearchNetworkStatus;
}) => {
  const canAccessKeywords = validatePermission({
    permissions,
    permission: {
      resource: PAPI.PermissionResource.RESEARCH_KEYWORDS,
      action: PAPI.PermissionAction.ACCESS_DATA,
    },
  });
  const canAccessQuestions = validatePermission({
    permissions,
    permission: {
      resource: PAPI.PermissionResource.RESEARCH_QUESTIONS,
      action: PAPI.PermissionAction.ACCESS_DATA,
    },
  });
  const canAccessConnect = validatePermission({
    permissions,
    permission: {
      resource: PAPI.PermissionResource.RESEARCH_CONNECT,
      action: PAPI.PermissionAction.ACCESS_DATA,
    },
  });

  const networkStatus: NetworkStatus[] = paths.reduce((acc, path) => {
    const noAccess = [
      path === 'adwordsQuestions' && !canAccessQuestions,
      path === 'adwordsKeywords' && !canAccessKeywords,
      path === 'serpQuestions' && !canAccessQuestions,
      path === 'linkingRecommendations' && !canAccessConnect,
    ].some(Boolean);
    if (noAccess) {
      return acc;
    }

    return [...acc, get(network, path)];
  }, []);

  const isUiComplete = networkStatus.every(status =>
    (
      [
        AppQueryNetworkStatus.complete,
        AppQueryNetworkStatus.error,
      ] as NetworkStatus[]
    ).includes(status),
  );

  return isUiComplete;
};

export default checkIsUiComplete;
