import React from 'react';
import styled from 'styled-components';

import Button from '../Button';
import IconSvg from '../IconSvg';
import Tooltip from '../Tooltip/Tooltip';
import SimpleTextInput from '../SimpleTextInput';
import BaseInput from '../SimpleTextInput/themes/base/BaseInput';
import * as SL from '../SimpleList/themes/dropdown';
import DataListRow from './DataListRow';

export const TooltipStyled = styled(Tooltip).attrs(p => ({
  distance: p.distance || 2,
  interactive: true,
  position: p.position || 'bottom-start',
  trigger: p.trigger || 'click',
  tippyTheme: p.tippyTheme || 'light',
  tippyUsage: p.tippyUsage || 'dropdown',
  unmountHTMLWhenHide: false,
  style: {
    ...p?.style,
    display: 'null',
  },
}))`

  ${p => p.html && `> div { min-height: 100% }`}
`;

export const ButtonCancel = styled(Button).attrs({
  icon: <IconSvg name="close" size="16" box={12} />,
  iconOnly: true,
  default: true,
  displayTooltip: false,
})`
  background-color: transparent;
  margin-right: ${p => p.theme.spacing.xSmall};
  margin-left: -${p => p.theme.spacing.xxSmall};
`;

export const DropdownWrapper = styled.div`
  position: relative;
  min-width: 280px;
  border-radius: 4px;
`;

export const DropdownBody = styled(SL.List)`
  z-index: 1;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;
  border-radius: 4px;

  ${p => p.empty && `padding-top: 0`}
`;

export const DataListRowSelected = styled(DataListRow)`
  border: none;

  &:hover ${ButtonCancel} {
    color: ${p => p.theme.colors.grey85};
  }
`;
export const SimpleTextInputStyled = styled(SimpleTextInput)`
  width: 100%;
  height: 100%;
  min-height: 32px;
  border: 0;
  border-radius: 4px 4px 0 0;

  &::after {
    opacity: 0;
  }

  &:focus-within {
    border-color: transparent;
    &::after {
      opacity: 0;
    }
  }

  ${BaseInput} {
    border-radius: 4px 4px 0 0;
  }
`;

// no result display

export const NoResultRow = styled(DataListRow)`
  display: flex;
`;

export const NoResultText = styled.div`
  color: ${p => p.theme.colors.grey60};
`;

export const NoResultAction = styled.div`
  flex-shrink: 0;
  font-weight: bold;
  color: ${p => p.theme.colors.blueDark1};
`;
