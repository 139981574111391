import React, { FC } from 'react';
import cx from 'classnames';
import { IPageButtonRendererProps } from '../types';
const baseClassNames = [
  'flex',
  'items-center',
  'justify-center',
  'h-6',
  'min-w-[24px]',
  'p-1 ',
  'rounded-full ',
  'cursor-pointer ',
  'font-bold text-xs',
];

export const PageButtonRenderer: FC<IPageButtonRendererProps> = ({
  page,
  currentPage,
  setCurrentPage,
}) => {
  const inactiveClassName = 'text-gray-700';
  const activeClassName = 'bg-blue-500 text-white rounded-md';
  return (
    <span
      key={page}
      onClick={() => setCurrentPage(page)}
      className={cx(
        baseClassNames,
        currentPage + 1 === page ? activeClassName : inactiveClassName,
      )}
    >
      {page}
    </span>
  );
};
