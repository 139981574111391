import { forwardRef, memo } from 'react';
import { twMerge } from 'tailwind-merge';
import { Polymorphic } from '../Polymorphic';
import useButtonClasses from './hooks/useButtonClasses';
import {
  ButtonProps,
  BUTTON_CORNERS,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from './types';

export const Button = memo(
  forwardRef<HTMLButtonElement, ButtonProps>(
    (
      {
        as,
        className,
        corners = BUTTON_CORNERS.md,
        size = BUTTON_SIZES.md,
        variant = BUTTON_VARIANTS.blackOnTransparent,
        ...props
      },
      ref,
    ) => {
      const Element = as || 'button';

      const classes = useButtonClasses({
        variant,
        size,
        corners,
      });

      return (
        <Polymorphic
          {...props}
          ref={ref}
          as={Element}
          className={twMerge(classes, className)}
        />
      );
    },
  ),
);

export default Button;
