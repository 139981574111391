import { Box, LoadingBlock } from '@marketmuse/components';

const RecommendationLoadingBlock = ({
  recommendationCount = 2,
  listItemCount = 5,
}: {
  recommendationCount?: number;
  listItemCount?: number;
}) => {
  const list = new Array(recommendationCount).fill(1);
  return (
    <>
      {list.map((listIndex, index) => (
        <Box className={['mb-5']} key={index}>
          <LoadingBlock className="mb-2 w-32 h-6" />

          <div className="w-full ">
            {new Array(listItemCount).fill(1).map((itemIndex, index) => (
              <div key={index} className="flex justify-between bg-white ">
                <div className="w-4/6 p-4">
                  <LoadingBlock className="h-6" />
                </div>
                <div className="w-2/6 p-4">
                  <LoadingBlock className="h-6" />
                </div>
              </div>
            ))}
          </div>
        </Box>
      ))}
    </>
  );
};

export default RecommendationLoadingBlock;
