import {
  CODES_ALL,
  CODES_INVENTORY,
  CodesAll,
  CountryInfo,
} from '../../types/countries';

const config: Record<CodesAll, CountryInfo> = {
  [CODES_ALL.US]: {
    adjective: 'United States',
    fallback: CODES_INVENTORY.US,
    flag: '🇺🇸',
    id: CODES_ALL.US,
    name: 'United States',
    title: '🇺🇸  United States',
  },
  [CODES_ALL.GB]: {
    adjective: 'United Kingdom',
    fallback: CODES_INVENTORY.GB,
    flag: '🇬🇧',
    id: CODES_ALL.GB,
    name: 'United Kingdom',
    title: '🇬🇧  United Kingdom',
  },
  [CODES_ALL.CA]: {
    adjective: 'Canadian',
    fallback: CODES_INVENTORY.CA,
    flag: '🇨🇦',
    id: CODES_ALL.CA,
    name: 'Canada',
    title: '🇨🇦  Canada',
  },
  [CODES_ALL.RU]: {
    adjective: '',
    fallback: CODES_INVENTORY.US,
    flag: '🇷🇺',
    id: CODES_ALL.RU,
    name: 'Russia',
    title: '🇷🇺  Russia',
  },
  [CODES_ALL.DE]: {
    adjective: '',
    fallback: CODES_INVENTORY.US,
    flag: '🇩🇪',
    id: CODES_ALL.DE,
    name: 'Germany',
    title: '🇩🇪  Germany',
  },
  [CODES_ALL.FR]: {
    adjective: '',
    fallback: CODES_INVENTORY.US,
    flag: '🇫🇷',
    id: CODES_ALL.FR,
    name: 'France',
    title: '🇫🇷  France',
  },
  [CODES_ALL.ES]: {
    adjective: 'Spanish',
    fallback: CODES_INVENTORY.US,
    flag: '🇪🇸',
    id: CODES_ALL.ES,
    name: 'Spain',
    title: '🇪🇸  Spain',
  },
  [CODES_ALL.IT]: {
    adjective: '',
    fallback: CODES_INVENTORY.US,
    flag: '🇮🇹',
    id: CODES_ALL.IT,
    name: 'Italy',
    title: '🇮🇹  Italy',
  },
  [CODES_ALL.BR]: {
    adjective: '',
    fallback: CODES_INVENTORY.US,
    flag: '🇧🇷',
    id: CODES_ALL.BR,
    name: 'Brazil',
    title: '🇧🇷  Brazil',
  },
  [CODES_ALL.AU]: {
    adjective: 'Australian',
    fallback: CODES_INVENTORY.AU,
    flag: '🇦🇺',
    id: CODES_ALL.AU,
    name: 'Australia',
    title: '🇦🇺  Australia',
  },
  [CODES_ALL.AR]: {
    adjective: 'Argentinian',
    fallback: CODES_INVENTORY.US,
    flag: '🇦🇷',
    id: CODES_ALL.AR,
    name: 'Argentina',
    title: '🇦🇷  Argentina',
  },
  [CODES_ALL.BE]: {
    adjective: '',
    fallback: CODES_INVENTORY.US,
    flag: '🇧🇪',
    id: CODES_ALL.BE,
    name: 'Belgium',
    title: '🇧🇪  Belgium',
  },
  [CODES_ALL.CH]: {
    adjective: '',
    fallback: CODES_INVENTORY.US,
    flag: '🇨🇭',
    id: CODES_ALL.CH,
    name: 'Switzerland',
    title: '🇨🇭  Switzerland',
  },
  [CODES_ALL.DK]: {
    adjective: '',
    fallback: CODES_INVENTORY.US,
    flag: '🇩🇰',
    id: CODES_ALL.DK,
    name: 'Denmark',
    title: '🇩🇰  Denmark',
  },
  [CODES_ALL.FI]: {
    adjective: '',
    fallback: CODES_INVENTORY.US,
    flag: '🇫🇮',
    id: CODES_ALL.FI,
    name: 'Finland',
    title: '🇫🇮  Finland',
  },
  [CODES_ALL.HK]: {
    adjective: '',
    fallback: CODES_INVENTORY.US,
    flag: '🇭🇰',
    id: CODES_ALL.HK,
    name: 'Hong Kong',
    title: '🇭🇰  Hong Kong',
  },
  [CODES_ALL.IE]: {
    adjective: '',
    fallback: CODES_INVENTORY.US,
    flag: '🇮🇪',
    id: CODES_ALL.IE,
    name: 'Ireland',
    title: '🇮🇪  Ireland',
  },
  [CODES_ALL.IL]: {
    adjective: '',
    fallback: CODES_INVENTORY.US,
    flag: '🇮🇱',
    id: CODES_ALL.IL,
    name: 'Israel',
    title: '🇮🇱  Israel',
  },
  [CODES_ALL.MX]: {
    adjective: 'Mexican',
    fallback: CODES_INVENTORY.US,
    flag: '🇲🇽',
    id: CODES_ALL.MX,
    name: 'Mexico',
    title: '🇲🇽  Mexico',
  },
  [CODES_ALL.NL]: {
    adjective: '',
    fallback: CODES_INVENTORY.US,
    flag: '🇳🇱',
    id: CODES_ALL.NL,
    name: 'Netherlands',
    title: '🇳🇱  Netherlands',
  },
  [CODES_ALL.NO]: {
    adjective: '',
    fallback: CODES_INVENTORY.US,
    flag: '🇳🇴',
    id: CODES_ALL.NO,
    name: 'Norway',
    title: '🇳🇴  Norway',
  },
  [CODES_ALL.PL]: {
    adjective: '',
    fallback: CODES_INVENTORY.US,
    flag: '🇵🇱',
    id: CODES_ALL.PL,
    name: 'Poland',
    title: '🇵🇱  Poland',
  },
  [CODES_ALL.SE]: {
    adjective: '',
    fallback: CODES_INVENTORY.US,
    flag: '🇸🇪',
    id: CODES_ALL.SE,
    name: 'Sweden',
    title: '🇸🇪  Sweden',
  },
  [CODES_ALL.SG]: {
    adjective: '',
    fallback: CODES_INVENTORY.US,
    flag: '🇸🇬',
    id: CODES_ALL.SG,
    name: 'Singapore',
    title: '🇸🇬  Singapore',
  },
  [CODES_ALL.TR]: {
    adjective: '',
    fallback: CODES_INVENTORY.US,
    flag: '🇹🇷',
    id: CODES_ALL.TR,
    name: 'Turkey',
    title: '🇹🇷  Turkey',
  },
  [CODES_ALL.JP]: {
    adjective: '',
    fallback: CODES_INVENTORY.US,
    flag: '🇯🇵',
    id: CODES_ALL.JP,
    name: 'Japan',
    title: '🇯🇵  Japan',
  },
  [CODES_ALL.IN]: {
    adjective: '',
    fallback: CODES_INVENTORY.US,
    flag: '🇮🇳',
    id: CODES_ALL.IN,
    name: 'India',
    title: '🇮🇳  India',
  },
  [CODES_ALL.HU]: {
    adjective: '',
    fallback: CODES_INVENTORY.US,
    flag: '🇭🇺',
    id: CODES_ALL.HU,
    name: 'Hungary',
    title: '🇭🇺  Hungary',
  },
  [CODES_ALL.BG]: {
    adjective: '',
    fallback: CODES_INVENTORY.US,
    flag: '🇧🇬',
    id: CODES_ALL.BG,
    name: 'Bulgaria',
    title: '🇧🇬  Bulgaria',
  },
  [CODES_ALL.CN]: {
    adjective: '',
    fallback: CODES_INVENTORY.US,
    flag: '🇨🇳',
    id: CODES_ALL.CN,
    name: 'China',
    title: '🇨🇳  China',
  },
  [CODES_ALL.GR]: {
    adjective: '',
    fallback: CODES_INVENTORY.US,
    flag: '🇬🇷',
    id: CODES_ALL.GR,
    name: 'Greece',
    title: '🇬🇷  Greece',
  },
  [CODES_ALL.MY]: {
    adjective: '',
    fallback: CODES_INVENTORY.US,
    flag: '🇲🇾',
    id: CODES_ALL.MY,
    name: 'Malaysia',
    title: '🇲🇾  Malaysia',
  },
  [CODES_ALL.NZ]: {
    adjective: 'New Zealand',
    fallback: CODES_INVENTORY.NZ,
    flag: '🇳🇿',
    id: CODES_ALL.NZ,
    name: 'New Zealand',
    title: '🇳🇿  New Zealand',
  },
  [CODES_ALL.PH]: {
    adjective: '',
    fallback: CODES_INVENTORY.US,
    flag: '🇵🇭',
    id: CODES_ALL.PH,
    name: 'Philippines',
    title: '🇵🇭  Philippines',
  },
  [CODES_ALL.RO]: {
    adjective: '',
    fallback: CODES_INVENTORY.US,
    flag: '🇷🇴',
    id: CODES_ALL.RO,
    name: 'Romania',
    title: '🇷🇴  Romania',
  },
  [CODES_ALL.ZA]: {
    adjective: '',
    fallback: CODES_INVENTORY.US,
    flag: '🇿🇦',
    id: CODES_ALL.ZA,
    name: 'South Africa',
    title: '🇿🇦  South Africa',
  },
  [CODES_ALL.KR]: {
    adjective: '',
    fallback: CODES_INVENTORY.US,
    flag: '🇰🇷',
    id: CODES_ALL.KR,
    name: 'South Korea',
    title: '🇰🇷  South Korea',
  },
  [CODES_ALL.CO]: {
    adjective: 'Colombian',
    fallback: CODES_INVENTORY.US,
    flag: '🇨🇴',
    id: CODES_ALL.CO,
    name: 'Colombia',
    title: '🇨🇴  Colombia',
  },
  [CODES_ALL.PE]: {
    adjective: 'Peruvian',
    fallback: CODES_INVENTORY.US,
    flag: '🇵🇪',
    id: CODES_ALL.PE,
    name: 'Peru',
    title: '🇵🇪  Peru',
  },
  [CODES_ALL.VE]: {
    adjective: 'Venezuelan',
    fallback: CODES_INVENTORY.US,
    flag: '🇻🇪',
    id: CODES_ALL.VE,
    name: 'Venezuela',
    title: '🇻🇪  Venezuela',
  },
  [CODES_ALL.CL]: {
    adjective: 'Chilean',
    fallback: CODES_INVENTORY.US,
    flag: '🇨🇱',
    id: CODES_ALL.CL,
    name: 'Chile',
    title: '🇨🇱  Chile',
  },
  [CODES_ALL.GT]: {
    adjective: 'Guatemalan',
    fallback: CODES_INVENTORY.US,
    flag: '🇬🇹',
    id: CODES_ALL.GT,
    name: 'Guatemala',
    title: '🇬🇹  Guatemala',
  },
  [CODES_ALL.EC]: {
    adjective: 'Ecuadorian',
    fallback: CODES_INVENTORY.US,
    flag: '🇪🇨',
    id: CODES_ALL.EC,
    name: 'Ecuador',
    title: '🇪🇨  Ecuador',
  },
  [CODES_ALL.BO]: {
    adjective: 'Bolivian',
    fallback: CODES_INVENTORY.US,
    flag: '🇧🇴',
    id: CODES_ALL.BO,
    name: 'Bolivia',
    title: '🇧🇴  Bolivia',
  },
  [CODES_ALL.PY]: {
    adjective: 'Paraguayan',
    fallback: CODES_INVENTORY.US,
    flag: '🇵🇾',
    id: CODES_ALL.PY,
    name: 'Paraguay',
    title: '🇵🇾  Paraguay',
  },
  [CODES_ALL.NI]: {
    adjective: 'Nicaraguan',
    fallback: CODES_INVENTORY.US,
    flag: '🇳🇮',
    id: CODES_ALL.NI,
    name: 'Nicaragua',
    title: '🇳🇮  Nicaragua',
  },
  [CODES_ALL.CR]: {
    adjective: 'Costa Rican',
    fallback: CODES_INVENTORY.US,
    flag: '🇨🇷',
    id: CODES_ALL.CR,
    name: 'Costa Rica',
    title: '🇨🇷  Costa Rica',
  },
};
export default config;
