const breakpoints = {
  xs: {
    min: '425px',
    max: '767px',
  },
  sm: {
    min: '768px',
    max: '991px',
  },
  md: {
    min: '992px',
    max: '1199',
  },
  lg: {
    min: '1200px',
  },
};

const iterableBps = Object.keys(breakpoints).map(k => ({ id: k }));

export { iterableBps }
export default breakpoints;
