import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const clickable = css`
  min-width: 0;
  outline: none;
  cursor: ${p => (p.disabled ? 'default' : 'pointer')};
  &:disabled {
    cursor: default;
  }
`;

const Clickable = styled.div`
  ${clickable};
`;

const ClickableLink = styled.a`
  ${clickable};
  display: inline-flex;
  text-decoration: none;
  color: ${p => p.theme.colors.blueDark};
`;

const ClickableComponent = ({
  children,
  onClick,
  disabled,
  useSpan,
  target = '_blank',
  loading,
  ...rest
}) => (
  <Clickable
    as={rest.href ? ClickableLink : useSpan ? 'span' : 'div'}
    onKeyDown={e => e.key === 'Enter' && typeof onClick === 'function' && onClick(e)}
    onClick={e => {
      if (!disabled && typeof onClick === 'function') onClick(e);
    }}
    style={{ outline: 'none' }}
    disabled={disabled}
    target={rest.href ? target : null}
    {...rest}
    role={rest.href || typeof onClick === 'function' ? 'button' : null}
    tabIndex="0"
    loading={loading ? loading.toString() : undefined}
  >
    {children}
  </Clickable>
);

ClickableComponent.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  useSpan: PropTypes.bool,
};

ClickableComponent.defaultProps = {
  onClick: null,
  children: '',
};

export default ClickableComponent;
