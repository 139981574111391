import * as Sentry from '@sentry/browser';
import { onError } from '@apollo/client/link/error';

// Log any GraphQL errors or network error that occurred
export const errorLink = onError(
  ({ operation, response, graphQLErrors, networkError, forward }) => {
    const operationContext = operation.getContext();
    const operationName = operation?.operationName;
    const source = operation?.query?.loc?.source?.body;
    const variables = operation?.variables;
    const context = {
      tags: { operationName },
      extra: {
        variables,
        source,
        response,
      },
    };

    if (graphQLErrors) {
      graphQLErrors.forEach(error => {
        if (
          ['Token is expired, please sign in again.'].includes(error?.message)
        ) {
          return;
        }

        Sentry.captureMessage(`${operationName} - ${error?.message}`, context);
      });
    }

    if (networkError) {
      const networkRetryCount = operationContext.networkRetryCount
        ? operationContext.networkRetryCount + 1
        : 1;
      operation.setContext((lastContext: Record<string, any>) => ({
        ...lastContext,
        networkRetryCount,
      }));

      const captureContext = {
        ...context,
        extra: {
          ...context.extra,
          networkError,
        },
        tags: {
          ...context?.tags,
          networkRetryCount,
        },
      };
      Sentry.captureMessage(networkError?.message, captureContext);
    }

    if (networkError) {
      // Forward the error to the next link in the chain
      return forward(operation);
    } else {
      return void 0;
    }
  },
);
