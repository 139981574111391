import clone from 'lodash/clone';

import * as types from '../config/types';
import initialState from '../config/initialState';

const settingsReducer = (state = initialState.settings, action) => {
  switch (action.type) {

    case types.SAVE_MEMBER_DATA_TO_STATE:
      const team = clone(state.team);
      const teamIndex = state.team.map(u => u.id).indexOf(action.data.id);
      if (teamIndex !== -1) {
        team[teamIndex] = action.data;
        return Object.assign({}, state, { team });
      }
      return state;

    default:
      return state;
  }
};

export default settingsReducer;
