import styled from 'styled-components';

const ModalContents = styled.div`
  max-width: 100%;
  width: 720px;
  height: 100%;
  margin: auto;
  margin-top: ${p => p.theme.spacing.medium};

  &:first-child {
    margin-top: 0;
  }

  ${p => p.theme.mixins.applyMargin};
  ${p => p.theme.mixins.applyPadding};
  ${p => p.theme.mixins.applyFlex};
  ${p => p.theme.mixins.applyGrid};
`;

export default ModalContents;
