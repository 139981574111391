import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IconExternal from 'react-feather/dist/icons/external-link';

import { stripHttp, stripSlash } from '../../utils/misc';

const Wrapper = styled.div`
  width: 100%;
  font-size: 8.5pt;
  * { font-size: 8.5pt; }
  ${p => p.fontSize && `
    font-size: ${p.fontSize} !important;
    * { font-size: ${p.fontSize} !important; }
  `}
  color: ${p => p.theme.mmxDarkGrey};
  display: flex;
  align-items: center;
  ${p => p.theme.truncate};
`;

const UrlIcon = styled(IconExternal)`
  flex-shrink: 0;
  margin-right: 5px;
  color: ${p => p.theme.mmxLightGrey};
  width: 12px;
  height: 100%;
  display: flex;
  align-items: center;
`;

const NonUrl = styled.div`
  ${p => p.fontSize && `
    font-size: ${p.fontSize} !important;
    * { font-size: ${p.fontSize} !important; }
  `}
  color: ${p => p.theme.mmxDarkGrey} !important;
  display: flex;
  align-items: center;
  ${p => p.theme.truncate};
`;

const UrlLink = styled.a`
  font-size: 8pt !important;
  color: ${p => p.theme.mmxDarkGrey} !important;
  text-decoration: none !important;
  &:hover { color: ${p => p.theme.mmxBlue} !important; }
  ${p => p.theme.truncate};
`;

const ExternalLink = ({ className, fontSize, url = '' }) => {
  const urlIndex = url.match(/http|https/);
  if (urlIndex === null) return <NonUrl fontSize={fontSize}>{url}</NonUrl>;
  return (
    <Wrapper className={className} fontSize={fontSize}>
      <UrlIcon />
      <UrlLink
        title={url}
        onClick={e => e.stopPropagation()}
        to={url.substr(urlIndex.index)}
        href={url.substr(urlIndex.index)}
        target="_blank"
      >
        {stripHttp(stripSlash(url))}
      </UrlLink>
    </Wrapper>
  );
};

ExternalLink.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string,
  fontSize: PropTypes.string,
};

export default ExternalLink;
