import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import get from 'lodash/get';
import { useInventoryPageSuggestionsLazyQuery } from '@marketmuse/data-papi';

import Autocomplete from '../Autocomplete';
import Url from '../Url';
import Loader from '../Loader';

const Item = styled.div`
  width: 100%;
  padding: 6px 4px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
`;
const ItemTitle = styled.div`
  color: ${p => p.theme.colors.grey85};
  font-size: 12px;
  white-space: normal;
  text-align: left;
`;
const ItemUrl = styled(Url)`
  white-space: normal !important;
  text-align: left !important;
`;

const SimpleSiteInput = ({
  width,
  offset,
  padding,
  setValue,
  style,
  inputComponent,
  inputProps,
}) => {
  const invId = useSelector(state => state?.filter?.site?.invId);
  const siteId = useSelector(state => state?.filter?.site?.id);
  const [query, { data, loading }] = useInventoryPageSuggestionsLazyQuery();

  const onDebounce = useCallback(
    value => {
      if (!invId || loading) {
        return;
      }

      query({
        variables: {
          siteId,
          search: value,
        },
      });
    },
    [invId, siteId, loading, query],
  );

  const items = useMemo(() => {
    if (loading) {
      return [];
    }
    return (get(data, 'inventoryItems.items') || []).slice(0, 5).map(item => ({
      text: item.page.url,
      component: (
        <Item>
          <ItemTitle>{item.page.title}</ItemTitle>
          <ItemUrl>{item.page.url}</ItemUrl>
        </Item>
      ),
      style: { minWidth: 220, maxWidth: width || 390 },
      onClick: () => setValue(item.page.url, item.page),
    }));
  }, [loading, data, setValue, width]);

  return (
    <Autocomplete
      {...{
        width,
        offset,
        padding,
        setValue,
        style,
        inputComponent,
        inputProps,
      }}
      inputProps={{
        ...inputProps,
        itemsRight: [loading ? <Loader /> : null],
        onDebounceDynamic: value => onDebounce(value),
      }}
      shouldItemRender={() => true}
      popperProps={{
        maxHeight: '400px',
        flex: true,
        style: { height: '100%' },
        items: items,
      }}
    />
  );
};

export default SimpleSiteInput;
