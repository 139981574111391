import { get, isEmpty } from 'lodash';
import { ReportReadDocument } from '@marketmuse/data-papi';
import Toast from '../../components/Toast';

import reportReadError from '../../models/tracker/events/reports/ReportReadError';
import makeRequest from '../../utils/makeRequest';
import * as types from '../../config/types';

export default () => () => next => action => {
  if (action.type === types.READ_REPORT) {
    if (isEmpty(action.id)) {
      return;
    }
    makeRequest({
      type: types.READ_REPORT,
      mutation: ReportReadDocument,
      variables: {
        reportId: action.id,
      },
      options: {
        isQuery: true,
      },
      apolloOptions: {
        fetchPolicy: 'no-cache',
      },
      keys: Object.keys(action.options || {}).map(key => `read_report_${key}`),
    }).then(res => {
      const resError = get(res, 'error') || get(res, 'readReport.errorMessage');
      const report = get(res, 'readReport');

      if (!res || resError) {
        reportReadError.record({ id: action.id, error: resError });

        return Toast.fire(
          "Sorry, we had a small error, and couldn't load that report.",
          'error',
        );
      } else if (action.callback && typeof action.callback === 'function') {
        return action.callback(report);
      }
    });
  }

  return next(action);
};
