import { useMemo } from 'react';
import { camelCase } from 'lodash';
import { twMerge } from 'tailwind-merge';
import { Box, Button, Text, Icon } from '@marketmuse/components';
import { NavigationItem, NavigationProps } from './types';
import config from './config';

const wrapperStyles = {
  base: ['bg-white', 'w-full', 'rounded-none'],
  itemCount: {
    [4 as number]: ['border-solid', 'border-b-4', 'border-grey-200'],
    [5 as number]: ['xl:border-solid', 'xl:border-b-4', 'xl:border-grey-200'],
  },
};

const innerStyles = {
  base: [
    'flex',
    'flex-wrap',
    'flex-row',
    'items-center',
    'justify-center',
    'mx-auto',
  ],
  itemCount: {
    [4 as number]: ['max-w-[1000px]', '-mb-1'],
    [5 as number]: ['max-w-[1200px]', 'xl:-mb-1'],
  },
};

const baseButtonStyles = {
  base: [
    'bg-white',
    'relative',
    'flex-grow',
    'flex-row',
    'flex',
    'items-center',
    'justify-center',
    'min-w-fit',
    'py-3',
    'px-2',
    'lg:py-6',
    'lg:px-4',
    'w-fit',
    'border-solid',
    'border-b-4',
    'rounded-none',
    'basis-1/2',
  ],
  itemCount: {
    [4 as number]: ['lg:basis-1/4'],
    [5 as number]: [
      'max-w-[300px]',
      'xl:border-gray-200',
      'md:basis-1/3',
      'xl:basis-1/5',
    ],
  },
};

const Navigation = ({
  items,
  loading = [],
  activeId,
  onClick,
}: NavigationProps) => {
  const buttons = useMemo(
    () =>
      items.map(id => ({
        ...config[id],
        loading: loading.includes(id),
      })),
    [items, loading],
  );

  const itemCount = Number(buttons.length);

  return (
    <Box
      className={twMerge(
        ...wrapperStyles.base,
        ...wrapperStyles.itemCount[itemCount],
      )}
    >
      <Box
        className={twMerge(
          ...innerStyles.base,
          ...innerStyles.itemCount[itemCount],
        )}
      >
        {buttons.map((item: NavigationItem) => {
          const id = item.id;
          const isActive = id === camelCase(activeId);
          return (
            <Button
              key={id}
              className={twMerge([
                ...baseButtonStyles.base,
                ...baseButtonStyles.itemCount[itemCount],
                isActive ? item.activeClassName : '',
                item.loading && !isActive ? 'opacity-70 hover:opacity-100' : '',
              ])}
              onClick={() =>
                onClick({
                  id,
                  path: item.path,
                  view: item.initialView,
                })
              }
              data-mms--research-navigation={id}
            >
              <Box className={['flex']}>
                <Icon name={item.iconName} boxSize={28} />
              </Box>
              <Text className={['font-bold', 'prose', 'text-sm', 'ml-2']}>
                {item.title}
              </Text>
            </Button>
          );
        })}
      </Box>
    </Box>
  );
};

export default Navigation;
