import * as React from 'react';
import cx from 'classnames';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Button } from '../../Button';

interface IPropsTableFiltersTrigger {
  id?: string;
  type?: string;
  'aria-expanded'?: boolean;
  'aria-controls'?: string;
  onKeyDown?: (event: KeyboardEvent) => void;
  onClick?: (event: MouseEvent) => void;
}
const TableFiltersTrigger = React.forwardRef<
  HTMLButtonElement,
  IPropsTableFiltersTrigger
>((props, ref) => {
  return (
    <Button
      {...props}
      className={[
        'flex',
        'inline-flex',
        'flex-row',
        'items-center',
        'px-4',
        'py-2',
        'border',
        'shadow-sm',
        'text-xs',
        'font-medium',
        'rounded',
      ]}
      ref={ref}
    >
      <span>Filters</span>
      <ChevronDownIcon
        aria-hidden="true"
        className={cx([
          'ml-2',
          'h-3',
          'w-3',
          props['aria-expanded'] && 'rotate-180',
        ])}
      />
    </Button>
  );
});

export default React.memo(TableFiltersTrigger);
