import { createListenerMiddleware } from '@reduxjs/toolkit';
import { VersionStatusEnum } from '@marketmuse/config/types/papi';
import inventoryVersions from '../asyncThunks/inventoryVersions';
import { ReduxListener } from '../types';
import { inventoryVersionsCheck, inventoryVersionsRecheck } from './actions';
import {
  AGGREGATE_STATS_GET,
  INVENTORY_VERSIONS_SAVE,
} from './filter.actionTypes';

const setup = (startListening: ReduxListener) => {
  startListening({
    actionCreator: inventoryVersionsCheck,
    effect: async (action, { dispatch, cancelActiveListeners }) => {
      cancelActiveListeners();
      dispatch(inventoryVersions({ siteId: action.payload.siteId }));
    },
  });
  startListening({
    actionCreator: inventoryVersions.rejected,
    effect: async (_, { dispatch }) => {
      dispatch({
        type: INVENTORY_VERSIONS_SAVE,
        payload: [],
      });
    },
  });

  // If inventory has no finished versions, wait for 30 seconds and check again
  startListening({
    actionCreator: inventoryVersionsRecheck,
    effect: async (_, { getState, dispatch, fork }) => {
      const siteId = getState()?.filter?.site?.id;
      const task = fork(async api => {
        await api.delay(30_000);
        return true;
      });
      const result = await task.result;

      if (result.status) {
        dispatch(inventoryVersionsCheck({ siteId }));
      }
    },
  });

  startListening({
    actionCreator: inventoryVersions.fulfilled,
    effect: async ({ payload }, { getState, dispatch, fork }) => {
      const state = getState();

      if (
        !payload.data ||
        !Array.isArray(payload.data) ||
        !state?.filter?.site?.invId
      ) {
        dispatch({
          type: INVENTORY_VERSIONS_SAVE,
          payload: [],
        });
        return;
      }

      const versions = payload.data.filter(({ visible, failed }) =>
        [visible === 1, failed === 0].every(Boolean),
      );
      const hasFinishedVersions = versions.some(
        ({ status }) => status === VersionStatusEnum.FINISHED,
      );
      const hadFinishedVersions = state.filter?.versions?.some(
        ({ status }) => status === VersionStatusEnum.FINISHED,
      );

      // If previously had no versions, then there are no entity counts
      // if now has versions, fetch entity counts
      if (hasFinishedVersions && !hadFinishedVersions) {
        dispatch({
          type: AGGREGATE_STATS_GET,
          payload: payload.siteId,
        });
      }

      dispatch({
        type: INVENTORY_VERSIONS_SAVE,
        payload: versions,
      });

      // Don't block previous actions
      if (!hasFinishedVersions) {
        const task = fork(async api => {
          await api.delay(30_000);
          return true;
        });
        const result = await task.result;

        if (result.status) {
          dispatch(inventoryVersionsCheck({ siteId: payload.siteId }));
        }
      }
    },
  });
};

const middlewareFn = () => {
  const middleware = createListenerMiddleware();
  setup(middleware.startListening);

  return middleware;
};

export default middlewareFn;
