import { regexCleanup } from './regexCleanup';

export const createRegexFromTerms = (
  terms: string[] = [],
  options: { substr?: boolean } = {},
): RegExp | null => {
  if (Array.isArray(terms) && terms.length === 0) {
    return null;
  }

  const termsProcessed = terms
    .slice()
    // add hyphenless version of hypened terms
    .reduce((acc: string[], c) => {
      const concat = [c];
      if (c.indexOf('-') !== -1) concat.push(c.replace(/-/g, ' '));
      return acc.concat(concat);
    }, [])
    .sort((a, b) => b.length - a.length)
    .map(regexCleanup)
    .join('|');

  if (options.substr) {
    return new RegExp(`(${termsProcessed})`, 'gi');
  }

  return new RegExp(`\\b(${termsProcessed})\\b`, 'gi');
};
