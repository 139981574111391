import { FC, useMemo } from 'react';
import cx from 'classnames';
import { Box } from '../../../Box';
import { ToastComponentProps } from '../types';
import ToastClose from './ToastClose';
import ToastIcon from './ToastIcon';
import { getToastColor, getToastIcon } from './utils';

const wrapperClassNames = [
  'bg-white',
  'border-l-[6px]',
  'flex',
  'gap-3',
  'items-center',
  'max-w-[467px]',
  'px-5',
  'py-4',
  'rounded-lg',
  'shadow-medium',
  'text-base',
  'pointer-events-auto',
];

const innerClassNames = [
  'flex-col',
  'flex',
  'gap-2',
  'items-start',
  'justify-start',
  'prose-sm',
  'prose-p:m-0',
  'text-sm',
];

const ToastComponent: FC<ToastComponentProps> = ({
  type = 'success',
  icon,
  children,
  onClose,
}) => {
  const iconName = useMemo(
    () => (icon ? icon : getToastIcon(type)),
    [icon, type],
  );
  const color = useMemo(() => getToastColor(type), [type]);

  return (
    <div className={cx(...wrapperClassNames, `border-${color}`)}>
      <ToastIcon {...{ iconName, color }} />
      <Box className={innerClassNames}>{children}</Box>
      <ToastClose onClick={onClose} />
    </div>
  );
};

export default ToastComponent;
