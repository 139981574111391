import ScreenLoader from '../../components/ScreenLoader';

import ExpiredTrialModal from '../Payments/SubscriptionStatus/ExpiredTrialModal';
import ExpiredNonTrialModal from '../Payments/SubscriptionStatus/ExpiredNonTrialModal';
import Dunning from '../Payments/SubscriptionStatus/Dunning';

import AppModalsMiscState from './AppModalsMiscState';

const LayoutGated = ({
  mode,
}: {
  mode: 'DUNNING' | 'EXPIRED_TRIAL' | 'EXPIRED';
}) => {
  return (
    <>
      <AppModalsMiscState />
      <ScreenLoader.Auth>
        {mode === 'EXPIRED' && <ExpiredNonTrialModal />}
        {mode === 'EXPIRED_TRIAL' && <ExpiredTrialModal />}
        {mode === 'DUNNING' && <Dunning />}
      </ScreenLoader.Auth>
    </>
  );
};

export default LayoutGated;
