import { kebabCase } from 'lodash';
import { stripUrl } from './normalize';

const isEncoded = (str: string): boolean => {
  return typeof str == 'string' && decodeURIComponent(str) !== str;
};

export const sanitizeOrgSlug = (slug: string): string => {
  if (isEncoded(slug)) {
    return slug;
  } else {
    const lower = kebabCase(stripUrl(slug || '').toLowerCase());

    return encodeURIComponent(lower);
  }
};
