import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isNil from 'lodash/isNil';

import LoadingBlock from '../LoadingBlock';
import { isClickable } from './utils';

const Wrapper = styled.div`
  /* sizes, paddings and margins */
  ${p => p.grow && `flex-grow: 1;`}
  ${p => !p.grow && `flex-shrink: 0;`}
  ${p => !isNil(p.width) && `width: ${p.theme.apply(p.width)};`}

  display: flex;
  align-items: center;
  padding: 0 ${p => p.theme.spacing.regular};
  outline: none !important;
  ${p => p.theme.type.textSmall};
  font-size: 12px;
  line-height: 16px;
  color: var(--mms-simple-foreground, ${p => p.theme.generic.light.foreground});
  ${p => p.grey && `color: ${p.theme.mmxDarkGrey};`}
  ${p => p.theme.mixins.applyMargin};
  ${p => p.theme.mixins.applyPadding};

  /* header */
  ${div =>
    div.header &&
    `
    margin-bottom: 5px;
    background-color: transparent;
    font-size: 11px !important;
  `};

  /* alignment */
  ${p =>
    p.alignLeft &&
    `
    justify-content: flex-start;
    .mmx--main-wrapper {
      justify-content: flex-start;
    }
  `}
  ${p =>
    p.alignRight &&
    `
    justify-content: flex-end;
    .mmx--main-wrapper {
      justify-content: flex-end;
    }
  `}
  ${p =>
    p.alignCenter &&
    `
    justify-content: center;
    .mmx--main-wrapper {
      justify-content: center;
    }
  `}
  ${p =>
    p.alignTop &&
    `
      align-items: flex-start;
      .mmx--main-wrapper {
        align-items: flex-start;
      }
    `}
  ${p =>
    p.alignBottom &&
    `
      align-items: flex-end;
      .mmx--main-wrapper {
        align-items: flex-end;
      }
    `}
  ${p =>
    p.alignMiddle &&
    `
      align-items: center;
      .mmx--main-wrapper {
        align-items: center;
      }
    `}

  ${p =>
    p.sticky &&
    `
      position: sticky;
      z-index: 9999;
  `}

  ${p =>
    p.breakWord &&
    `
    overflow-wrap: break-word;
    white-space: normal;
    word-break: break-word;
  `}

  ${p =>
    p.editable &&
    `
    position: relative;
    &:hover {
      ${
        p.onClick &&
        `
        cursor: pointer;
        color: var(--mms-simple-foreground, ${p.theme.generic.light.foreground});
        * { color: var(--mms-simple-foreground, ${p.theme.generic.light.foreground}) }
      `
      }
    }
  `}

  /* children */
  ${p => p.truncate && !p.breakWord && p.theme.truncate}

  ${p =>
    p.blur &&
    `
    filter: blur(4px);
    pointer-events: none;
  `}
  ${p => p.theme.mixins.applyFlex};
`;

const MainWrapper = styled.div`
  flex-grow: 1;
  display: flex;
`;

const SideItems = styled.div`
  flex-shrink: 0;
  display: flex;
  & > * {
    margin-left: 4px;
  }
`;

const SimpleListItem = ({ children, loading, ...props }) => {
  const isItemClickable = isClickable(props);
  return (
    <Wrapper
      {...props}
      onClick={
        !isItemClickable
          ? null
          : e => {
              e.preventDefault();
              e.stopPropagation();
              props.onClick(e);
            }
      }
    >
      {props.hide && null}
      {!props.hide && loading && <LoadingBlock />}
      {/* if not header, just render children */}
      {!props.hide && !loading && !props.header && children}
      {/* if header, wrap children in a div and render side items if any */}
      {!props.hide && !loading && props.header && (
        <MainWrapper className="mmx--main-wrapper header">{children}</MainWrapper>
      )}
      {!props.hide && !loading && props.header && !isNil(props.sideItems) && (
        <SideItems style={props.sideItemsStyle}>{props.sideItems}</SideItems>
      )}
    </Wrapper>
  );
};

SimpleListItem.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.any,
  header: PropTypes.bool,
  grow: PropTypes.bool,
  truncate: PropTypes.bool,
  alignLeft: PropTypes.bool, // horizontal alignment
  alignRight: PropTypes.bool, // horizontal alignment
  alignCenter: PropTypes.bool, // horizontal alignment
  alignTop: PropTypes.bool, // vertical alignment
  alignBottom: PropTypes.bool, // vertical alignment
  alignMiddle: PropTypes.bool, // vertical alignment
  grey: PropTypes.bool, // make the color grey
  editable: PropTypes.bool,
  sideItems: PropTypes.any,
  blur: PropTypes.bool, // blur out the cell
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  p: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  m: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ml: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

SimpleListItem.defaultProps = {
  header: false,
  grow: false,
  truncate: true,
  alignLeft: true,
  alignRight: false,
  alignCenter: false,
  role: 'button',
  tabIndex: 0,
};

export default memo(SimpleListItem);
