import { useState, useEffect, forwardRef, KeyboardEvent } from 'react';
import cx from 'classnames';

import { RECURLY } from '@marketmuse/config/types';
import {
  recurlyFieldsConfig,
  recurlyBillingCountries,
} from '@marketmuse/config/configs/recurly';

import { Text, Box, Input } from '@marketmuse/components';

type Props = {
  firstName: string;
  lastName: string;
};

const countries: RECURLY.BillingCountries = recurlyBillingCountries;

const labelClassNames = [
  'block',
  'text-sm',
  'font-bold',
  'text-gray-700',
  'block',
  'mb-1',
];

const selectClassNames = cx([
  'shadow-sm',
  'focus:ring-blue-400',
  'focus:border-blue-400',
  'block',
  'w-full',
  'sm:text-sm',
  'border-gray-400',
  'rounded',
]);

const BillingFields = forwardRef<HTMLFormElement, Props>((props, ref) => {
  const [firstName, setFirstName] = useState(props.firstName || '');
  const [lastName, setLastName] = useState(props.lastName || '');
  const [isoCountry, setISOCountry] = useState('us');

  // recurly injects frames on `recurly.configure`, so it needs
  // to be reconfigured every time the form is mounted
  useEffect(() => {
    window.recurly?.configure({
      publicKey:
        process.env.REACT_APP_RECURLY_PUBLIC_KEY ||
        process.env.STORYBOOK_RECURLY_PUBLIC_KEY,
      fields: recurlyFieldsConfig,
    });
  }, []);

  return (
    <form ref={ref} className={cx(['flex', 'flex-col', 'gap-4'])}>
      <Box>
        <Input
          label="First Name"
          name="first_name"
          labelProps={{ className: labelClassNames }}
          inputProps={{
            'data-recurly': 'first_name',
            value: firstName,
            onChange: (event: KeyboardEvent<HTMLInputElement>) =>
              setFirstName(event.currentTarget.value),
          }}
        />
      </Box>
      <Box>
        <Input
          label="Last Name"
          name="last_name"
          labelProps={{ className: labelClassNames }}
          inputProps={{
            'data-recurly': 'last_name',
            value: lastName,
            onChange: (event: KeyboardEvent<HTMLInputElement>) =>
              setLastName(event.currentTarget.value),
          }}
        />
      </Box>

      <Box>
        <label>
          <Text className={labelClassNames}>
            Card Number (no dashes or spaces)
          </Text>

          <div
            data-recurly="card"
            className={cx([
              '*:text-sm',
              '*:!border-gray-400',
              '*:!mt-0',
              '*:!h-[38px]',
              '*:!rounded',
              '*:!shadow-sm',
            ])}
          />
        </label>
      </Box>

      <Box>
        <label>
          <Text className={labelClassNames}>Country</Text>
          <select
            className={selectClassNames}
            onChange={event => {
              const [option] = Array.from(event.target.options)
                .filter(option => option.selected)
                .map(option => option.value);
              setISOCountry(option);
            }}
            value={isoCountry}
            data-recurly="country"
          >
            {Object.values(countries).map(country => (
              <option key={country.id} value={country.id}>
                {country.title}
              </option>
            ))}
          </select>
        </label>
      </Box>
    </form>
  );
});

export default BillingFields;
