import styled from 'styled-components';

export const NewsroomOutputSection = styled.div`
  margin-bottom: 50px;
`;

export const NewsroomOutputSectionHeader = styled.div`
  display: flex;
  align-items: center;
  padding: ${p => `0 ${p.theme.pagePaddingLR}px`};
`;

export const NewsroomOutputSectionHeaderTitle = styled.div`
  font-family: ${p => p.theme.type.families.heading};
  font-weight: bolder;
  color: ${p => p.theme.colors.grey85};
  font-size: 14pt;
  margin-right: 30px;
`;

export const NewsroomOutputSectionHeaderItem = styled.div`
  margin-right: 20px;
`;

export const NewsroomOutputSectionContents = styled.div`
  margin-top: 12px;
`;

export const NewsroomOutputMatrixLabel = styled.div`
  display: flex;
  align-items: center;
`;
