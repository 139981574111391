import get from 'lodash/get';

import { GuestAccessCreateManyDocument } from '@marketmuse/data-papi';
import makeRequest from '../../utils/makeRequest';
import * as types from '../../config/types';

export default () =>
  ({ getState }) =>
  next =>
  action => {
    if (action.type === types.GUEST_ACCESS_TOKEN_GET_MANY) {
      const state = getState();
      const siteId = get(state, 'filter.site.id');
      makeRequest({
        type: types.GUEST_ACCESS_TOKEN_GET_MANY,
        mutation: GuestAccessCreateManyDocument,
        variables: {
          siteId,
          purpose: action.purpose,
          resources: action.resources,
        },
      }).then(res => {
        const data = get(res, 'guestAccessCreateMany') || [];
        if (typeof action.callback === 'function') action.callback(data);
        return data;
      });
    }

    return next(action);
  };
