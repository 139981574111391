import get from 'lodash/get';

import { FormCreateDocument } from '@marketmuse/data-papi';
import makeRequest from '../../utils/makeRequest';
import * as types from '../../config/types';

export default () =>
  ({ getState }) =>
  next =>
  action => {
    if (action.type === types.CREATE_FORM) {
      makeRequest({
        type: types.CREATE_FORM,
        mutation: FormCreateDocument,
        variables: {
          orgId: action.data?.orgId,
          formInput: {
            title: action.data?.title,
          },
        },
        apolloOptions: {
          fetchPolicy: 'no-cache',
        },
      }).then(res => {
        const data = get(res, 'formCreate', {});
        if (typeof action.callback === 'function') action.callback(data);
      });
    }

    return next(action);
  };
