import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  Account as RecurlyAccount,
  RecurlySubscription,
  Transaction as RecurlyTransaction,
} from '@marketmuse/config/types/papi';
import * as ACTION_TYPES from '../actionTypes';
import { SlicePayment } from '../types';

export const initialState: SlicePayment = {
  account: {},
  subscription: null,
  subscriptions: [],
  transactions: [],
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setAccount: (state, action: PayloadAction<RecurlyAccount>) => {
      state.account = action.payload;
    },

    setSubscription: (state, action) => {
      state.subscription = action.payload;
    },

    setSubscriptions: (state, action: PayloadAction<RecurlySubscription[]>) => {
      state.subscriptions = action.payload;
    },
    setTransactions: (state, action: PayloadAction<RecurlyTransaction[]>) => {
      state.transactions = action.payload;
    },
    removeAddon: (state, action: PayloadAction<{ id: string }>) => {
      state.subscription.addons = state.subscription.addons.filter(
        addon => addon.id !== action.payload.id,
      );
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      action => action.type === ACTION_TYPES.SIGN_OUT,
      () => initialState,
    );
  },
});

export const paymentActions = paymentSlice.actions;
export const paymentReducer = paymentSlice.reducer;
