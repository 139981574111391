import { persistStore } from 'reduxjs-toolkit-persist';
import localForage from 'localforage';

import { createBrowserHistory as createHistory, type History } from 'history';

import Tracker from '../models/tracker/Tracker';
import enhanceHistory from '../utils/enhanceHistory';
import configureStore from './configureStore';

export const tracker = new Tracker();
export const store = configureStore();
export const persistor = persistStore(store);
export const history: History = enhanceHistory(createHistory());

export const KgScoresStore = localForage.createInstance({
  name: 'kg-scores',
  driver: [localForage.INDEXEDDB, localForage.LOCALSTORAGE],
});

export const QtsStore = localForage.createInstance({
  name: 'qts-title-cluster',
  driver: [localForage.INDEXEDDB, localForage.LOCALSTORAGE],
});
