import styled from 'styled-components';
import isNil from 'lodash/isNil';

const SideItems = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const SideItem = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SideItemsLeft = styled(SideItems)``;
export const SideItemLeft = styled(SideItem)`
  margin-right: ${p => (isNil(p.gap) ? '8px' : p.theme.apply(p.gap))};
  &:first-child {
    padding-left: ${p => (isNil(p.gap) ? p.theme.spacing.small : p.theme.apply(p.gap))};
  }
  &:last-child {
    margin-right: 0;
  }
`;

export const SideItemsRight = styled(SideItems)``;
export const SideItemRight = styled(SideItem)`
  margin-left: ${p => (isNil(p.gap) ? '8px' : p.theme.apply(p.gap))};
  &:last-child {
    padding-right: ${p => (isNil(p.gap) ? p.theme.spacing.small : p.theme.apply(p.gap))};
  }
  &:first-child {
    margin-left: 0;
  }
`;
