import { setAppsReducer } from '../../actions/toolsActions';
import { setSimple } from '../../actions/miscActions.js';
import * as types from '../../config/types';
import appConfigKey from '../../utils/applications/appConfigKey';

export default () => ({ dispatch, getState }) => next => action => {
  const state = getState();
  const siteId = state?.filter?.site?.id;

  // request
  if (action.type === types.SET_APPS_DATA_START) {
    const { id, params } = action;
    const key = appConfigKey(params);

    try {
      Promise.all([
        // keep as promise chain in case additional data is needed
        fetch(`https://mmx-onboarding.s3.amazonaws.com/${id}.json`).then(response => response.json()),
      ]).then(results => {
        const { download_page, ...data } = results.reduce(
          (acc, curr) => ({ ...acc, ...(curr || {}) }),
          {}
        );

        const payload = {
          questions: key,
          optimize: key,
          compete: key,
          research: key,
          connect: key,

          [key]: {
            date: Date.now(),
            params,
            data,
          },
        };

        if (download_page?.data) {
          const key = `optimize-app-${siteId}_html`;
          dispatch(setSimple({ key, value: download_page?.data?.html, persist: true }));
        }

        dispatch(setAppsReducer({ payload }));

        if (action.callback) action.callback(payload);
      });
    } catch (error) {
      console.error(error);
    }
  }

  return next(action);
};
