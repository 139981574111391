import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

import { ReactComponent as SpacePerson } from '../../assets/space-person-inflight-red.svg';
import Button from '../Button/Button';

const gradient = keyframes`
  0% { background-position: 0 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0 50%; }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(-45deg, #0881e2, #A908E2, #359ff4);
  background-size: 400% 400%;
  color: white;
  animation: ${gradient} 40s linear infinite;
`;

const MessagePageContents = styled.div`
  display: flex;
  width: 70%;
`;

const MessagePageSpacePerson = styled.div`
  flex-shrink: 0;

  svg { width: 240px; }
`;

const MessagePageText = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin-left: 30px;
`;

const MessagePageTextTitle = styled.div`
  font-weight: bolder;
  font-size: 30pt;
  font-family: ${p => p.theme.type.families.heading};
  line-height: normal;
`;

const MessagePageTextDesc = styled.div`
  line-height: normal;
  font-size: 12pt;
`;

const MessagePageTextCta = styled.div`
  margin-top: 20px;
  .button { padding: 6px 18px !important; }
`;


const Message = props => (
  <Wrapper>
    <MessagePageContents>
      <MessagePageSpacePerson>
        <SpacePerson />
      </MessagePageSpacePerson>
      {(props.title || props.message) && (
        <MessagePageText>
          {!!props.title && (
            <MessagePageTextTitle>
              {props.title}
            </MessagePageTextTitle>
          )}
          {!!props.message && (
            <MessagePageTextDesc>
              {props.message || 'Sorry, something went wrong!'}
            </MessagePageTextDesc>
          )}
          <MessagePageTextCta>
            {props.ctas.map(cta => (
              <Button large rounded="50px" onClick={cta.onClick} href={cta.href}>
                {cta.title}
              </Button>
            ))}
          </MessagePageTextCta>
        </MessagePageText>
      )}
    </MessagePageContents>
  </Wrapper>
);

Message.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  ctas: PropTypes.array,
};

Message.defaultProps = {
  ctas: []
}

export default Message;
