import get from 'lodash/get';

/**
 * for uri "/some/path/test/1"
 *
 * "/other" -> false
 * "/test" -> true
 * "/test/1" -> true
 * "/1" -> true
 *
 * with { exact: true }
 *
 * "/other" -> false
 * "/test" -> false
 * "/test/1" -> true
 * "/1" -> true
 */

export default (_path = '', exact = false) => {
  const path = _path[0] === '/' ? _path : `/${_path}`;
  const pathname = get(window, 'location.pathname', '');
  const pathSafe = `${path}/`;
  const pathnameSafe = `${pathname.split('?')[0]}/`;
  const pathMatch = new RegExp(
    `${pathSafe.replace(new RegExp('/', 'gm'), '\\/')}$`,
  );

  return Boolean(
    exact
      ? pathnameSafe.match(pathMatch)
      : pathnameSafe.indexOf(pathSafe) !== -1,
  );
};
