import { useCallback } from 'react';
import type { EventInfo } from '@ckeditor/ckeditor5-utils';
import type { Editor as EditorType } from '@ckeditor/ckeditor5-core';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';

import { EditorProps } from '../types';
import useConfig from './useConfig';

// eslint-disable-next-line
function handleCallback<T extends any[], CB>(args: T, callback: CB) {
  if (callback && typeof callback === 'function') {
    callback(...args);
  }
}

const Editor = ({
  config = {},
  data = '',
  id,
  onBlur,
  onChange,
  onError,
  onFocus,
  onReady,
}: EditorProps) => {
  const handleOnReady = useCallback(
    editor => {
      if (editor) {
        handleCallback<[EditorType], typeof onReady>([editor], onReady);
      }
    },
    [onReady],
  );

  const handleOnError = useCallback(
    (error, context) => {
      handleCallback([error, context], onError);
    },
    [onError],
  );

  const handleOnChange = useCallback(
    (event: EventInfo, editor: EditorType) => {
      handleCallback<[EditorType, EventInfo], typeof onChange>(
        [editor, event],
        onChange,
      );
    },
    [onChange],
  );

  const handleOnBlur = useCallback(
    (event: EventInfo, editor: EditorType) => {
      handleCallback<[EditorType, EventInfo], typeof onBlur>(
        [editor, event],
        onBlur,
      );
    },
    [onBlur],
  );

  const handleOnFocus = useCallback(
    (event: EventInfo, editor: EditorType) => {
      handleCallback<[EditorType, EventInfo], typeof onFocus>(
        [editor, event],
        onFocus,
      );
    },
    [onFocus],
  );

  const ckeConfig = useConfig(config);

  return (
    <CKEditor
      config={ckeConfig}
      data={data}
      editor={ClassicEditor}
      id={id}
      onBlur={handleOnBlur}
      onChange={handleOnChange}
      onError={handleOnError}
      onFocus={handleOnFocus}
      onReady={handleOnReady}
    />
  );
};

export default Editor;
