import React from 'react';
import { twMerge } from 'tailwind-merge';

export interface RadioProps extends React.HTMLProps<HTMLInputElement> {
  className?: string;
}

type RadioComponent = (props: RadioProps) => React.ReactElement | null;

export const Radio: RadioComponent = ({
  className = '',
  ...rest
}: RadioProps) => {
  return (
    <input
      type="radio"
      className={twMerge([
        rest.disabled ? 'cursor-auto' : 'cursor-pointer',
        'border-0',
        'rounded-full',
        'focus:outline-none',
        'focus:shadow-none',
        'focus:ring-0',
        'focus:ring-offset-0',
        'bg-gray-150',
        'text-blue-400',
        className,
      ])}
      {...rest}
    />
  );
};
