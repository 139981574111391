import * as types from '../../config/types';

import {
  getRecurlyAccount,
  getRecurlySubscriptions,
  getRecurlyTransactions,
} from '../../actions/recurlyActions';

export default () =>
  ({ dispatch, getState }) =>
  next =>
  action => {
    if (action.type === types.RECURLY_HYDRATE_STATE) {
      const accountCode = action.accountCode;
      const accountCodeTest = action.accountCodeTest;

      Promise.allSettled([
        new Promise(resolve =>
          dispatch(
            getRecurlyAccount({
              accountCode,
              accountCodeTest,
              callback: account => resolve(account),
            }),
          ),
        ),

        new Promise(resolve =>
          dispatch(
            getRecurlySubscriptions({
              accountCode,
              accountCodeTest,
              callback: subscriptions => resolve(subscriptions),
            }),
          ),
        ),

        new Promise(resolve =>
          dispatch(
            getRecurlyTransactions({
              accountCode,
              accountCodeTest,
              callback: transactions => resolve(transactions),
            }),
          ),
        ),
      ]).then(res => {
        if (typeof action.callback === 'function') {
          action.callback({
            account: res[0].value,
            subscriptions: res[1].value,
            transactions: res[2].value,
          });
        }
      });
    }

    return next(action);
  };
