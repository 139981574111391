import Event from '../../Event';

class EditorOnCopy extends Event {
  record() {
    super.record('EditorOnCopy', {
      category: 'Editor',
      mainValue: '',
      newName: 'suite--editor--content-copied',
    });
  }
}

export default new EditorOnCopy();
