import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Box from '../../../../../components/Box';

import { SimpleTextInputStyled } from '../styles';

const FilterText = ({ placeholder, value, onChange }) => (
  <Box boxVariant="flex" alignItems="center" width="100%">
    <SimpleTextInputStyled
      type="text"
      inputTheme="light"
      placeholder={placeholder}
      value={value}
      onChange={e => onChange(e.target.value)}
      data-lpignore="true"
    />
  </Box>
);

FilterText.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
};

export default memo(FilterText);
