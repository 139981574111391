import React from 'react';
import { ReactComponent as SpaceCrawler } from '../../assets/space-crawler.svg';
import { Box } from '../../Box';
import { Button } from '../../Button';
import { Text } from '../../Text';
import { ErrorScreenUIProps } from '../types';

export const UnknownError = ({
  openChat,
  wrapperClassNames,
}: ErrorScreenUIProps) => {
  return (
    <Box className={wrapperClassNames}>
      <Box className={'max-w-[200px]'}>
        <SpaceCrawler className={'w-full'} />
      </Box>
      <Text as="h1">We weren't expecting that</Text>
      <Text as="p">Sorry, we didn't mean for that to happen.</Text>
      {openChat && (
        <Text as="p">
          <Button onClick={() => openChat()} variant={'blue400'}>
            Let us know what happened
          </Button>
        </Text>
      )}
    </Box>
  );
};
