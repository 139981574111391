import { useCallback } from 'react';

import getHandleHotkeys from './getHandleHotkeys';
/**
 * usage:
 * import 'useHandlers' from 'useHandlers'
 * memoedHandlers = useHandlers([]);
 * ..
 * onKeyDown={event => memoedHandlers({ event })}
 *
 * @param {Array.<{key: String, when: Function, command: Function}>} hotkeys
 */
export default hotkeys => {
  return useCallback(getHandleHotkeys(hotkeys), [hotkeys]);
};
