export function isAlphaNumeric(text: string) {
  const textSafe = text.toLowerCase();
  const textLength = textSafe.length;

  for (let index = 0; index < textLength; index++) {
    const code = textSafe.charCodeAt(index);
    const isNumeric = code > 47 && code < 58; // numeric (0-9)
    const isLowerAlpha = code > 96 && code < 123; // lower alpha (a-z)

    if (!isNumeric && !isLowerAlpha) {
      return false;
    }
  }

  return true;
}
