import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import Backdrop from './components/Backdrop';
import CloseButton from './components/CloseButton';
import ModalContent from './components/ModalContent';
import { ModalPropsInterface, MODAL_MODE } from './types';

const Modal: ModalPropsInterface = ({
  children,
  className,
  closeBtnClassName,
  disableClose,
  isVisible,
  mode,
  onAfterLeave,
  onClose,
  onOutsideClick,
  panelClassName,
  panelWrapperClassName,
  unmount,
}) => {
  const slideout = useMemo(
    () =>
      [MODAL_MODE.slideoutRight, MODAL_MODE.slideoutLeft].includes(
        MODAL_MODE[mode],
      ),
    [mode],
  );

  return (
    <Transition afterLeave={onAfterLeave} appear as={Fragment} show={isVisible}>
      <Dialog
        open={isVisible}
        as="div"
        className={twMerge(['relative z-10', className])}
        onClose={() => void 0}
        unmount={unmount}
      >
        <Backdrop />
        {slideout && (
          <CloseButton
            {...{ mode, onClick: onClose, className: closeBtnClassName }}
          />
        )}
        <ModalContent
          {...{ mode, panelClassName, panelWrapperClassName }}
          onClose={onOutsideClick ? onOutsideClick : onClose}
        >
          {!slideout && (
            <CloseButton
              {...{
                onClick: onClose,
                className: closeBtnClassName,
                disableClose: disableClose,
              }}
            />
          )}
          {children}
        </ModalContent>
      </Dialog>
    </Transition>
  );
};

export default Modal;

Modal.Title = Dialog.Title;
Modal.Description = Dialog.Description;
