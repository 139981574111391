import { darken } from 'polished';

import {
  colors,
  utils,
  mixins,
  spacing,
  type,
  keyframes,
  breakpoints,
} from './lib';

/* eslint-disable */
export default {
  utils,
  mixins,
  colors,
  spacing,
  type,
  breakpoints,
  keyframes,
  // ---------------------- colors ----------------------
  mmxBlue: colors.blueBase,
  blue: colors.blueBase,
  mmxColorAlpha: (color, a) => utils.rgba(color, a),
  mmxDarkBlue: colors.blueDark1,
  darkBlue: colors.blueDark1,
  mmxLightBlue: colors.blueLight,
  lightBlue: colors.blueLight,
  lightBlue2: colors.blueLight2,
  greenLight2: colors.greenLight2,
  redLight2: colors.redLight2,
  yellowLight2: colors.yellowLight2,
  mmxGrey: colors.grey05,
  grey: colors.grey05,
  mmxBlack: colors.grey85,
  black: colors.grey85,
  mmxRed: '#ed6a5a',
  red: '#ed6a5a',
  mmxRedAlpha: a => `rgba(237, 106, 90, ${a})`,
  mmxDarkRed: '#ce5142',
  darkRed: '#ce5142',
  mmxPurple: '#8a4f7d',
  purple: '#8a4f7d',
  mmxDarkPurple: '#6E4D67',
  darkPurple: '#6E4D67',
  mmxDarkerPurple: '#51354b',
  darkerPurple: '#51354b',
  mmxGreen: '#38da8b',
  green: '#38da8b',
  mmxGreenAlpha: a => `rgba(56, 218, 139, ${a})`,
  mmxDarkGreen: '#1dad67',
  darkGreen: '#1dad67',
  mmxOrange: colors.orangeBase,
  orange: colors.orangeBase,
  mmxYellow: '#fec92e',
  yellow: '#fec92e',
  mmxYellowAlpha: a => `rgba(254, 201, 46, ${a})`,
  mmxGrey43: colors.grey70,
  mmxGreyD5: '#d5d5d5',
  mmxGreyCF: colors.grey25,
  mmxGreyE9: colors.grey15,
  mmxGreyFA: colors.grey05,
  mmxGreyE6: colors.grey15,
  mmxGrey84: '#7f8184',
  mmxGre45: '#3F4145',
  mmxLightGrey: colors.grey25,
  mmxDarkGrey: colors.grey60,
  mmxGreyF4: '#f4f4f4',
  mmxWhiteOp: 'rgba(255, 255, 255, .7)',
  mmxGreyOp12: 'rgba(196, 196, 196, 0.12)',
  mmxDarkBlack: '#18191b',
  mmxDarkOp95: 'rgba(52, 58, 64, 0.95)',
  mmxDark: '#31353a',
  mmxPrimary: '#3ca1f1',
  mmxPrimaryInvert: 'white',
  diffIn: 'rgb(212, 244, 228)',
  diffInDark: 'rgb(182, 238, 211)',
  diffInDarker: '#38DA8B',
  diffOut: 'rgb(238, 214, 211)',
  diffOutDark: 'rgb(244, 198, 193)',
  diffOutDarker: '#ED6A5A',
  white: '#ffffff',
  trialGap: 20,
  trialGapSide: 82,

  // ---------------------- misc ----------------------
  boxShadow: '0 0 15px rgba(0, 0, 0, 0.05), 0 0 15px rgba(0, 0, 0, 0.1)',
  boxShadowExtreme:
    '0 2px 20px rgba(0, 0, 0, 0.1), 0 2px 20px rgba(0, 0, 0, 0.15)',
  boxShadowLow: '0px 2px 12px rgba(0, 0, 0, 0.15)',
  boxShadowHigh: '0px 4px 30px rgba(0, 0, 0, 0.1)',
  boxShadowLight: '0px 2px 12px rgba(0, 0, 0, 0.05)',
  moonShadow: '0 1px 10px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.12)',
  moonShadowGrey: '0 1px 10px #e9e9e9, 0 1px 2px #8f8b8f',
  labelBlackShadow: '0px 0px 4px 1px #000000',
  transitionDur: '0.3s',
  transitionFn: 'cubic-bezier(0.25, 0.8, 0.25, 1)',
  transition: 'all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)',
  borderRadius: '4px',

  // ---------------------- sidebar ----------------------
  sidebarWidthLarge: 260,
  sidebarWidthSmall: 94,
  sidebarPadding: 12,

  get sidebarColor() {
    return this.colors.black;
  },
  get sidebarColorInverse() {
    return this.colors.grey85;
  },
  get sidebarColorSecondary() {
    return this.mmxBlue;
  },
  get sidebarColorHover() {
    return '#000';
  },
  get sidebarColorAdmin() {
    return this.colors.blueDark;
  },
  get sidebarColorAdminInverse() {
    return this.mmxBlue;
  },
  get sidebarColorAdminSecondary() {
    return this.mmxYellow;
  },
  get sidebarColorAdminHover() {
    return 'rgb(20, 108, 218)';
  },

  // ---------------------- global ----------------------
  pageMaxWidth: 1260,
  pageHeaderHeight: 64,
  pagePaddingLR: 22,
  pagePaddingTB: 22,
  modalPaddingLR: 22,
  modalPaddingTB: 22,
  slideoutPaddingLR: 22,
  slideoutPaddingTB: 22,
  editorToolbarHeight: 40,
  editorTopbarHeight: 48,
  editorPadding: 52,

  modalFullPaddingLR: 22,
  modalFullPaddingTB: 22,

  listContentPadding: 15,
  matrixCellSize: 38,

  briefBuilderDescWidth: 25,
  briefBuilderDescBreakpoint: 1000,

  moduleHeaderHeight: 22,

  filtersWidth: 250,

  scoresBarHeight: 50,

  // Form fields
  input: {
    minHeight: 40,
    default: {
      color: colors.grey60,
      backgroundColor: colors.grey15,
    },
    light: {
      minHeight: 38,
      color: colors.grey85,
      backgroundColor: colors.white,
      disabled: {
        color: colors.grey60,
        backgroundColor: colors.grey15,
      },
    },
    dark: {
      minHeight: 40,
      color: colors.grey60,
      backgroundColor: colors.black,
    },
  },
  // popper, tippy, simple list item
  generic: {
    dark: {
      foreground: colors.white,
      background: colors.grey85,
      border: colors.grey70,
      backgroundActive: colors.blueDark,
      backgroundHover: colors.blue,
      backgroundDisabled: colors.grey85,
      loadingBg: colors.grey85,
    },
    light: {
      foreground: colors.grey85,
      background: colors.white,
      border: colors.grey15,
      backgroundHover: colors.grey10,
      backgroundActive: colors.blueLight2,
      backgroundDisabled: colors.grey05,
      loadingBg: colors.grey10,
    },
    blue: {
      foreground: colors.white,
      background: colors.blue,
      border: colors.blueDark1,
      backgroundHover: colors.blueDark,
      backgroundActive: colors.blueDark1,
      backgroundDisabled: colors.blueLight,
      loadingBg: colors.blueDark,
    },
  },

  // ---------------------- mixins ----------------------

  ripple: (hoverBgColor, duration = '0.8s') => `
    background-position: center;
    transition: background ${duration};
    &:hover {
      cursor: pointer;
      background: ${hoverBgColor} radial-gradient(circle, transparent 1%, ${hoverBgColor} 1%) center/15000%;
    }
    &:active {
      background-color: ${darken(0.2, hoverBgColor)};
      background-size: 100%;
      transition: background 0s;
    }
  `,
  truncate: `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,

  // helper functions
  apply: utils.apply,
  get placeholder() {
    return css => `
      ::placeholder {${css(this)}},
      :-moz-placeholder: {${css(this)}},
      :-ms-input-placeholder: {${css(this)}},
      ::-moz-placeholder: {${css(this)}},
      ::-webkit-input-placeholder: {${css(this)}},
    `;
  },
  lessThan: (w, css) => `@media (max-width: ${w}px) {${css}}`,
  moreThan: (w, css) => `@media (min-width: ${w}px) {${css}}`,
};
/* eslint-enable */
