import { useCallback } from 'react';
import { toast } from '@marketmuse/components';
import { useExportSerpXrayLazyQuery } from '@marketmuse/data-papi';
import { createDownload } from '@marketmuse/utilities';
import { ExportSerpXrayPayload } from '../types';
import useToaster from './useToaster';

const useExportSerpXray = ({
  canExport,
  term,
  url,
}: {
  canExport: boolean;
  term: string;
  url?: string;
}) => {
  const { premiumFeatureToast, exportSuccessfulToast } = useToaster();
  const [exportSerpXray, { loading }] = useExportSerpXrayLazyQuery({
    fetchPolicy: 'no-cache',
    errorPolicy: 'none',
    notifyOnNetworkStatusChange: true,
    context: {
      omitTypename: true,
    },
    onError: () => {
      toast.error('Sorry, we were unable to export SERP X-Ray.');
    },
    onCompleted: data => {
      if (data?.exportSerpXray?.data) {
        exportSuccessfulToast();
        createDownload(data.exportSerpXray.data, `serp-xray-${term}`);
      }
    },
  });
  const handler = useCallback(
    (payload: ExportSerpXrayPayload) => {
      if (!canExport) {
        premiumFeatureToast();
        return;
      }
      exportSerpXray({
        variables: {
          generationContext: {
            term,
            url,
          },
          ...payload,
        },
      });
    },
    [canExport, exportSerpXray, term, url, premiumFeatureToast],
  );

  return { handler, loading };
};

export default useExportSerpXray;
