import { isNil } from 'lodash';

import * as Rules from '../../utils/inputValidation';

export const getFieldsValidation = (fields = []) => {
  let fieldValidations = {};
  fields.forEach(item => {
    let compareValidationField = null;
    const comparisonField =
      item.compareValidationField &&
      fields.find(({ field }) => field === item.compareValidationField);
    if (comparisonField) {
      compareValidationField = {
        field: comparisonField.field,
        name: comparisonField.name,
      };
    }
    fieldValidations[item.field] = Rules.validate(
      item.field,
      item.name,
      compareValidationField,
      ...(item.rules?.map(rule => Rules[rule]).filter(Boolean) ?? [])
    );
  });
  return fieldValidations;
};

export const isDisabled = (formFields, valueState, errors, propsErrors) => {
  const requiredFields = formFields
    .filter(({ rules }) => rules.includes('required'))
    .map(({ field }) => field);
  const emptyField = requiredFields.find(field => Rules.isEmptyStr(valueState[field]));
  return (
    emptyField ||
    !Rules.isEmptyObj(errors) ||
    !isNil(propsErrors.email) ||
    !isNil(propsErrors.domain)
  );
};
