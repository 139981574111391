import * as React from 'react';

import { Checkbox } from '../../Forms';

const TableSelectCell = ({ row }) => {
  return (
    <Checkbox
      {...{
        checked: row.getIsSelected(),
        indeterminate: row.getIsSomeSelected(),
        onChange: row.getToggleSelectedHandler(),
      }}
    />
  );
};

export default TableSelectCell;
