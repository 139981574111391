import * as React from 'react';
import { IResearchUiStateContext } from '../types';

import { ResearchUiStateContext } from '../context';

const useResearchUiState = (): IResearchUiStateContext => {
  const context = React.useContext(ResearchUiStateContext);
  if (!context) {
    throw new Error(
      'This component must be used within the <ResearchProvider />.',
    );
  }
  return context;
};

export default useResearchUiState;
