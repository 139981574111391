import { Command } from 'ckeditor5/src/core';
import { uniq } from 'lodash';
import { editor as editorConfig } from '@marketmuse/config/configs';
import { AhoCorasick } from '../ahocorasick';

const {
  HIGHLIGHT_TERM: { COMMANDS },
} = editorConfig;

export default class SetHighlightConfigCommand extends Command {
  constructor(editor, state) {
    super(editor);

    this.isEnabled = true;
    this.affectsData = false;
    this._state = state;
  }

  execute(searchItemsDict) {
    const highlightAttributeConfig = {};
    const searchItemsFlattened = [];
    const termSearchDict = {};

    Object.entries(searchItemsDict).forEach(([key, value]) => {
      const { color, priority, data } = value;

      termSearchDict[key] = {};
      highlightAttributeConfig[key] = {
        color: color,
        priority: priority,
      };

      const uniqTerms = uniq(data);
      uniqTerms.forEach(term => {
        const termLower = term.toLowerCase();

        searchItemsFlattened.push({
          key,
          priority,
          term: termLower,
        });

        termSearchDict[key][termLower] = true;
      });
    });

    this._state.ahoCorasick = new AhoCorasick(searchItemsFlattened);
    this._state.highlightAttributeConfig = highlightAttributeConfig;
    this._state.setTermSearchDict(termSearchDict);

    this.editor.execute(COMMANDS.highlightTerms);
  }
}
