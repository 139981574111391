import { createGlobalStyle, css } from 'styled-components';
import transparentize from 'polished/lib/color/transparentize';

const tippyStyle = name => css`
  --mms-simple-foreground: ${p => p.theme.generic[name].foreground};
  --mms-simple-background: ${p => p.theme.generic[name].background};
  --mms-simple-background-hover: ${p => p.theme.generic[name].backgroundHover};
  --mms-simple-background-active: ${p =>
    p.theme.generic[name].backgroundActive};
  --mms-simple-background-disabled: ${p =>
    p.theme.generic[name].backgroundDisabled};
  --mms-simple-border: ${p => p.theme.generic[name].border};
  box-shadow: ${p => p.theme.boxShadowLow};
  color: ${p => p.theme.generic[name].foreground};
  background-color: ${p => p.theme.generic[name].background};

  .mms--tippy-tooltip {
    padding: 4px 8px;
    font-size: 12px;
  }

  .mms--tippy-popper,
  .mms--tippy-dropdown {
    padding: 0;
    font-size: 14px;
  }

  &[data-placement^='top'] > .tippy-arrow:before {
    border-top-color: ${p => p.theme.generic[name].background};
  }
  &[data-placement^='bottom'] > .tippy-arrow:before {
    border-bottom-color: ${p => p.theme.generic[name].background};
  }
  &[data-placement^='left'] > .tippy-arrow:before {
    border-left-color: ${p => p.theme.generic[name].background};
  }
  &[data-placement^='right'] > .tippy-arrow:before {
    border-right-color: ${p => p.theme.generic[name].background};
  }
  > .tippy-backdrop {
    background-color: ${p => p.theme.generic[name].background};
  }
  > .tippy-svg-arrow {
    fill: ${p => p.theme.generic[name].background};
  }
`;

export default createGlobalStyle`
  * { box-sizing: border-box; }

  html,
  body {
    margin: 0 !important;
    padding: 0 !important;
    font-family: ${p => p.theme.type.families.main};
    color: ${p => p.theme.generic.light.foreground};
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    min-width: 300px;
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
    background-color: ${p => p.theme.generic.light.background};
    line-height: 1.2em;
    font-size: 16px;
    overflow: hidden;
    height: 100%;
  }

  #app {
    font-family: ${p => p.theme.type.families.main};
    color: ${p => p.theme.generic.light.foreground};
    height: 100%;
    width: 100%;
  }

  #root,
  #mms--fui-root,
  #headlessui-portal-root,
  #mms--tippy-root,
  #mms--portal-root {
    font-size: 14px;
    position: relative;

  }

  #mms--toast-root,
  #mms--fui-root,
  #mms--tippy-root,
  #mms--portal-root ,
  #headlessui-portal-root {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 200;
  }

  #root {
    z-index: 100;
    height: 100%;
  }

  #mms--portal-root {
    z-index: 200;
  }

  #headlessui-portal-root {
    z-index: 300;
  }

  #mms--tippy-root {
    z-index: 400;
  }

  #mms--fui-root {
    z-index: 500;
  }

  #mms--toast-root {
    z-index: 600;
  }

  .ck-body-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 200;
    height: inherit;
    width: inherit;
  }

  button,
  input,
  textarea {
    font-family: ${p => p.theme.type.families.main};
  }

  @page {
    size: letter;
  }
  /* Declare keyframes classes */
  /* BEM naming */
  .mms-keyframes {
    &__flashOp {
      animation-name: ${p => p.theme.keyframes.flashOp};
    }
    &__slideIn {
      animation-name: ${p => p.theme.keyframes.slideIn};
    }
    &__blockDeselection {
      animation-name: ${p => p.theme.keyframes.blockDeselection};
    }
    &__blockSelection {
      animation-name: ${p => p.theme.keyframes.blockSelection};
    }
    &__loading {
      &-light {
        animation-name: ${p => p.theme.keyframes.loading.light};
      }
      &-dark {
        animation-name: ${p => p.theme.keyframes.loading.dark};
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: ${p => p.theme.type.families.heading};
    font-weight: 700;
  }

  body .tippy-box {
    border-radius: 4px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .tippy-content {
      padding: 0;
      border-radius: 4px
    }

    &[data-state="hidden"] {
      ${p => p.theme.mixins.showSROnly};
      top: -10000px;
      left: -10000px;
    }

    &[data-theme~=light] {
      ${() => tippyStyle('light')};
    }

    &[data-theme~=dark] {
      ${() => tippyStyle('dark')};
    }
    &[data-theme~=blue] {
      ${() => tippyStyle('blue')};
    }
  }

  .iziToast-wrapper .iziToast {
    border: none !important;
    box-shadow: ${p => p.theme.boxShadowLow};

    &::after {
      display: none;
    }

    &.iziToast--default .iziToast-body::before {
      background-color: ${p => p.theme.colors.blue};
    }

    &.iziToast--warning .iziToast-body::before {
      background-color: ${p => p.theme.colors.orange};
    }

    &.iziToast--error .iziToast-body::before {
      background-color: ${p => p.theme.colors.red};
    }

    &.iziToast--success .iziToast-body::before {
      background-color: ${p => p.theme.colors.green};
    }

    .iziToast-body {
      display: flex;
      align-items: center;
      flex-flow: row nowrap;
      min-height: 38px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 0;
      color: ${p => p.theme.colors.grey85};

      p {
        float: none;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 32px;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: currentColor;
      }

      &::before {
        position: absolute;
        content: '';
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: ${p => p.theme.colors.grey35};
      }
    }
  }
  // CALENDAR SETTINGS
  .CalendarDay {
    color: ${p => p.theme.colors.grey85};
  }

  .CalendarMonth_table {
    margin-top: 12px;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background-color: ${p => p.theme.mmxBlue} !important;
    border-color: ${p => p.theme.mmxDarkBlue} !important;
  }

  .CalendarDay__selected_span,
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover,
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:active,
  .CalendarDay__hovered_span:hover {
      color: ${p => p.theme.colors.grey85} !important;
      background-color: ${p => transparentize(0.9, p.theme.mmxBlue)} !important;
      border-color: ${p => transparentize(0.8, p.theme.mmxBlue)} !important;
  }

  .DayPickerKeyboardShortcuts_show__bottomRight {
    display: none;
  }

  // Pendo help
  [class*="SidebarItem"] ._pendo-badge-image {
    opacity: 0;
    transition: opacity 150ms ease-in-out;
    transition-delay: 300ms;
  }

  [class*="SidebarItem"]:hover ._pendo-badge-image {
    opacity: 1;
  }

  .mms-briefSubheadingSelectionAnimation {
    animation: ${p => p.theme.keyframes.blockSelection} 0.4s ease-out forwards;
  }
  .mms-briefSubheadingDeselectionAnimation {
    animation: ${p =>
      p.theme.keyframes.blockDeselection} 0.4s ease-out forwards;
  }

  body > #ifr_* {
    position: absolute;
  }

  :root {
    --mm--editor-page-header-header: ${p => p.theme.pageHeaderHeight + 1}px;
    --mm--editor-scores-bar-height: ${p => p.theme.scoresBarHeight}px;
    --mm--editor-toolbar-height: 41px;
    --mm--cke-content-height: calc(
    100vh -
      (
        var(--mm--editor-page-header-header) + var(--mm--editor-toolbar-height) +
          var(--mm--editor-scores-bar-height)
      )
  )
  }

`;
