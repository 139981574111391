import React from 'react';

import SimpleGrid from '../SimpleGrid';
import SimpleTextInput from '../SimpleTextInput';

const SimpleField = ({
  field,
  state,
  errors,
  index,
  handleChange,
  ...restProps
}) => {
  const { Component, ...formField } = field;

  const extraProps = {
    type: formField.type,
    // autoFocus: index === 0,
    required: formField?.rules?.includes('required'),
    name: formField.field,
    error: errors && errors[formField.field],
    label: formField.label,
    targetValue: state?.[formField.field],
    targetValueWatch: true,
    onDebounce: handleChange,
    onDebounceDynamic: handleChange,
    isOpened: formField.relatedField && state?.[formField.relatedField],
    ...(formField?.componentProps ? formField?.componentProps : {}),
  };

  return (
    <SimpleGrid.Item span={formField.span} order={formField.order}>
      {Component && <Component {...extraProps} />}
      {!Component && <SimpleTextInput {...extraProps} />}
    </SimpleGrid.Item>
  );
};

export default SimpleField;
