import styled from 'styled-components';
import SimpleCountry from '../../components/SimpleFields/SimpleCountry';

import Box from '../../components/Box';
import Distribution from '../../components/Distribution/Distribution';
import SimpleCheckbox from '../../components/SimpleFields/SimpleCheckbox';
import SimplePassword from '../../components/SimpleFields/SimplePassword';

const Note = styled.div`
  font-size: 12px;
  color: ${p => p.theme.colors.grey60};
`;

const DistributionStyled = styled(Distribution)`
  width: 60px;
  min-height: 40px;
  margin: 3px 0 0 0;
`;

export const emailField = ({
  withBusinessEmailValidation = true,
  readOnlyEmail,
} = {}) => ({
  field: 'email',
  name: 'Email address',
  label: 'Email',
  rules: [
    'required',
    'isEmail',
    ...(withBusinessEmailValidation ? ['isWorkEmail'] : []),
  ],
  type: 'email',
  order: 3,
  span: 2,
  componentProps: {
    disabled: readOnlyEmail,
  },
});

export const phoneField = {
  field: 'phone',
  name: 'Phone',
  label: 'Phone Number',
  rules: ['isPhone'],
  type: 'tel',
  order: 5,
  span: 2,
};

export const companyField = {
  field: 'company',
  name: 'Company',
  label: 'Company Name',
  rules: ['required', 'isName'],
  type: 'text',
  order: 4,
  span: 2,
};

export const firstName = {
  field: 'firstName',
  name: 'Your first name',
  label: 'First Name',
  rules: ['required', 'isName'],
  type: 'text',
  order: 1,
  span: 1,
};

export const lastName = {
  field: 'lastName',
  name: 'Your last name',
  label: 'Last Name',
  rules: ['required', 'isName'],
  type: 'text',
  order: 2,
  span: 1,
};

export const domainField = {
  field: 'domain',
  name: 'A valid website address',
  label: "Your Website's Domain",
  rules: ['required', 'isWebsite'],
  type: 'text',
  order: 6,
  span: 2,
};

export const country = {
  field: 'country',
  name: 'Target country for search results',
  label: 'Search Results Country',
  rules: ['required'],
  type: 'dropdown',
  order: 10,
  span: 2,
  Component: SimpleCountry,
  componentProps: {
    showSelector: false,
  },
};

export const addUserFields = ({ disabled }) => [
  {
    ...emailField({ withBusinessEmailValidation: false }),
    label: 'Email',
    order: 1,
    debounceInterval: 500,
    componentProps: {
      disabled,
    },
  },
  {
    ...firstName,
    rules: ['limitSpecialChar', 'required'],
    order: 2,
    span: 2,
    componentProps: {
      disabled,
    },
  },
  {
    ...lastName,
    rules: ['limitSpecialChar', 'required'],
    order: 3,
    span: 2,
    componentProps: {
      disabled,
    },
  },
];

// signupFields
export const getSignupFields = ({ firstName: firstNameArgs } = {}) => [
  {
    ...firstName,
    ...firstNameArgs,
  },
  lastName,
  domainField,
  {
    ...companyField,
    label: 'Company Name',
    componentProps: {
      maxLength: 50,
    },
  },
  country,
];
export const getNewOrgFields = () => [
  {
    field: 'title',
    name: 'Organization Name',
    label: 'Organization Name',
    rules: ['required'],
    type: 'text',
    order: 1,
    span: 2,
  },
  {
    field: 'sfdcAccountId',
    name: 'SF Account id',
    label: 'SF Account id',
    rules: [],
    type: 'text',
    order: 3,
    span: 2,
  },
];

export const preferenceFields = ({ disabled }) => [
  {
    field: 'displayExactDistribution',
    name: 'displayExactDistribution',
    label: 'Display exact mention counts in applications',
    type: 'checkbox',
    rules: [],
    order: 14,
    span: 2,
    componentProps: {
      disabled,
      note: 'Selecting this option will show the exact count of mentions rather than banded values.',
      rightRail: (
        <>
          <Box width="80px">
            <Box boxVariant="flex" flexFlow="column" alignItems="start">
              <Note>Banded</Note>
              <DistributionStyled value={6} forceStyle="band" />
            </Box>
          </Box>
          <Box width="80px">
            <Box boxVariant="flex" flexFlow="column" alignItems="start">
              <Note>Exact</Note>
              <DistributionStyled value={6} forceStyle="exact" />
            </Box>
          </Box>
        </>
      ),
    },
    Component: SimpleCheckbox,
  },
];

export const accountFields = ({
  profileDelimiter,
  preferenceDelimiter,
  disabled,
  isEditMode,
  isAdminEditable,
  showErrors,
}) => [
  {
    field: 'profileDelimiter',
    name: 'profileDelimiter',
    label: 'Profile',
    Component: profileDelimiter,
    order: 1,
    span: 2,
  },
  {
    ...firstName,
    rules: ['limitSpecialChar', 'required'],
    order: 2,
    componentProps: {
      disabled,
    },
  },
  {
    ...lastName,
    rules: ['limitSpecialChar', 'required'],
    order: 3,
    componentProps: {
      disabled,
    },
  },
  {
    ...emailField({ withBusinessEmailValidation: false }),
    label: 'Email',
    order: 5,
    componentProps: {
      note: 'Note: Email will also be used as the username for logging in to MarketMuse.',
      debounceInterval: 1000,

      disabled: disabled || isEditMode,
    },
  },
  {
    ...phoneField,
    label: 'Phone Number',
    order: 6,
    componentProps: {
      disabled,
    },
  },
  {
    field: 'preferenceDelimiter',
    name: 'preferenceDelimiter',
    label: 'Preferences',
    Component: preferenceDelimiter,
    order: 10,
    span: 2,
  },
  ...preferenceFields({
    disabled,
    isEditMode,
    isAdminEditable,
    showErrors,
  }),
];

export const passwordFields = ({ showErrors, noAutoComplete }) => [
  {
    field: 'newPassword',
    name: 'Password',
    rules: ['required', 'isStrongPassword'],
    type: 'text',
    label: 'Password',
    order: 7,
    span: 2,
    Component: SimplePassword,
    componentProps: {
      noAutoComplete,
      autofocus: true,
      debounceInterval: 100,
      showErrors,
      type: 'password',
    },
  },
  {
    field: 'newPasswordConfirmation',
    name: 'Confirmation Password',
    rules: ['required', 'isMatch'],
    type: 'text',
    label: 'Confirmation Password',
    relatedField: 'newPassword',
    compareValidationField: 'newPassword',
    order: 8,
    span: 2,
    componentProps: {
      noAutoComplete,
      debounceInterval: 100,
      type: 'password',
    },
  },
];
