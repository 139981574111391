import { ApolloQueryResult } from '@apollo/client';
import { pick } from 'lodash';
import type { GetSeoAdwordsKeywordsQuery } from '@marketmuse/config/types/papi';
import { GetSeoAdwordsKeywordsDocument } from '@marketmuse/data-papi';
import {
  ResearchDataInput,
  ResearchThunkSeoRes,
  ThunkApiConfig,
} from '../../types';
import createNonConcurrentAsyncThunk from '../../utils/createNonConcurrentAsyncThunk';

const getAdwordsKeywords = createNonConcurrentAsyncThunk<
  ResearchThunkSeoRes,
  ResearchDataInput,
  ThunkApiConfig
>('runs/getAdwordsKeywords', async (params, { extra, rejectWithValue }) => {
  const response: ApolloQueryResult<GetSeoAdwordsKeywordsQuery> =
    await extra.apollo.query({
      query: GetSeoAdwordsKeywordsDocument,
      variables: pick(params, ['term', 'country', 'language']),
    });

  const error = response.errors;
  if (error) {
    return rejectWithValue(error);
  }

  return {
    data: response?.data?.seoAdwordsKeywords.map(item => ({
      ...item,
      existsInInventory: false,
    })),
    path: 'adwordsKeywords',
    params,
  };
});

export default getAdwordsKeywords;
