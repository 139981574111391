import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import isNil from 'lodash/isNil';
import IconSvg from '../IconSvg';
import Clickable from '../Clickable';
import Button from '../Button';

const TOPBAR_HEIGHT = 60;

export const ModalTopbarWrapper = styled.div`
  z-index: 1000;
  position: sticky;
  top: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: ${TOPBAR_HEIGHT}px;
  width: 100%;
  padding: ${p => p.theme.spacing.small} ${p => p.theme.spacing.large};
  color: ${p => p.theme.colors.white};
  background-color: ${p => p.theme.colors.black};

  ul {
    ${p => p.theme.mixins.listReset};
    display: flex;
    flex-flow: row nowrap;
  }

  li {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    margin-left: ${p => p.theme.spacing.medium};
    color: ${p => p.theme.colors.white};

    &:first-child {
      margin-left: 0;
    }
  }
`;

export const ModalTopbarText = styled.span`
  ${p => p.theme.type.textLarge};
  color: ${p => p.theme.colors.white};
  font-weight: 700;
  margin-left: ${p => p.theme.spacing.small};

  &:first-child {
    margin-left: 0;
  }
`;

export const ModalTopbarLink = styled(Clickable)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: start;
  align-items: center;
  color: currentColor;
`;

const SideItems = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  gap: 12px;
`;

const ModalTopbar = ({ children, close, itemsRight, ...props }) => (
  <ModalTopbarWrapper {...props}>
    {children}

    {/* gap */}
    <div style={{ flexGrow: 1 }} />

    {/* close button */}
    {!isNil(close) && (
      <Button onClick={close} title="Close" variant={'grey70'} iconOnly>
        <IconSvg name="close" size="16" />
      </Button>
    )}

    {!isNil(itemsRight) && itemsRight.length > 0 && (
      <SideItems>
        {itemsRight.map((item, index) => (
          <span key={index.toString()}>{item}</span>
        ))}
      </SideItems>
    )}
  </ModalTopbarWrapper>
);

ModalTopbar.propTypes = {
  itemsRight: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  close: PropTypes.func,
  children: PropTypes.any,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default ModalTopbar;
