import get from 'lodash/get';
import { SeoSerpQuestionsOldDocument } from '@marketmuse/data-papi';
import { getCountryEnum } from '@marketmuse/utilities';
import makeRequest from '../../utils/makeRequest';
import * as types from '../../config/types';

export default () =>
  ({ getState }) =>
  next =>
  action => {
    const state = getState();
    if (action.type === types.GET_QUESTIONS_DATA && !state.guestAccess?.token) {
      const siteCountry = get(state, 'filter.site.defaultSerpCountry') || 'us';
      const country = getCountryEnum(siteCountry);
      makeRequest({
        type: types.GET_QUESTIONS_DATA,
        mutation: SeoSerpQuestionsOldDocument,
        options: { isQuery: true },
        variables: {
          term: action.term,
          country: country,
          language: 'EN',
        },
        keys: [types.GET_QUESTIONS_DATA, `loading-brief-${action.id}`],
      }).then(res => {
        const data = get(res, 'seoSerpQuestionsOld', {});
        if (action.callback) action.callback(data);
      });
    }

    return next(action);
  };
