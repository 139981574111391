import * as Sentry from '@sentry/browser';

import { Writer, Item } from '@ckeditor/ckeditor5-engine';
import { MISC } from '@marketmuse/config/configs/editor/plugins/highlightTerms';
import { isNodeWholeWord } from './isNodeWholeWord';

export const removeUnusedAttribute = (
  writer: Writer,
  node: Item,
  termSearchDict:
    | Record<string, number>
    | Record<string, Record<string, boolean>>,
) => {
  try {
    if (!node || !node.hasAttribute(MISC.ATTRIBUTE_KEY)) {
      return;
    }

    // eslint-disable-next-line
    // @ts-ignore
    const term = node.data?.toLowerCase();
    const attributeValue = node.getAttribute(MISC.ATTRIBUTE_KEY) as string;
    const [, , key] = (attributeValue || '').split(':');
    const isWholeWord = isNodeWholeWord(node);

    if (termSearchDict[key][term] || isWholeWord) {
      return;
    }

    writer.removeAttribute(MISC.ATTRIBUTE_KEY, node);
  } catch (error) {
    // eslint-disable-next-line
    // @ts-ignore
    const message = `Unable to remove highlight | ${error.message}`;
    Sentry.captureMessage(message);
  }
};
