import { keyframes } from 'styled-components';

import colors from './colors';
import spacing from './spacing';

const flashOp = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

const slideIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(0);
  }
`;

const blockDeselection = keyframes`
  0% { border: ${spacing.xxSmall} solid ${colors.blueLight}; }
  100% { border: ${spacing.xxSmall} solid ${colors.grey10}; }
`;

const blockSelection = keyframes`
  0% { border: ${spacing.xxSmall} solid ${colors.grey10}; }
  100% { border: ${spacing.xxSmall} solid ${colors.blueLight}; }
`;

const lightLoadingAnimation = keyframes`
  0% { background-color: ${colors.grey10}; }
  50% { background-color: ${colors.grey15}; }
  100% { background-color: ${colors.grey10}; }
`;

const darkLoadingAnimation = keyframes`
  0% { background-color: ${colors.grey70}; }
  50% { background-color: ${colors.grey85}; }
  100% { background-color: ${colors.grey70}; }
`;

export default {
  flashOp,
  slideIn,
  blockDeselection,
  blockSelection,
  loading: {
    light: lightLoadingAnimation,
    dark: darkLoadingAnimation,
  },
};
