import { FilterFn } from '@tanstack/react-table';
import { INCLUDE_TERMS_TYPE } from './types';

const includesWord = (word: string, str: string): boolean => {
  const regex = new RegExp(`\\b${word}\\b`, 'i');
  return regex.test(str);
};

const wordsIncludesExcludes: FilterFn<any> = (
  row,
  columnId: string,
  filterValue: {
    include: INCLUDE_TERMS_TYPE;
    includes?: string[];
    excludes?: string[];
  },
) => {
  const include = filterValue.include;
  const includes = filterValue?.includes || [];
  const excludes = filterValue?.excludes || [];
  const value: string = (
    (row.getValue(columnId) as string) || ''
  ).toLowerCase();

  if ((includes.length <= 0 && excludes.length <= 0) || !include) {
    return true;
  }

  let includesMatched = true;
  const excludeMatched = !excludes.some(x =>
    includesWord(x.toLowerCase(), value),
  );
  if (includes.length > 0) {
    if (include === INCLUDE_TERMS_TYPE.ALL_WORDS) {
      includesMatched = includes.every(x =>
        includesWord(x.toLowerCase(), value),
      );
    } else {
      includesMatched = includes.some(x =>
        includesWord(x.toLowerCase(), value),
      );
    }
  }

  return includesMatched && excludeMatched;
};

export default wordsIncludesExcludes;
