import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// 34 - 2 - 16
const ToggleWrapper = styled.label`
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  max-width: max-content;
  cursor: ${p => !p.isDisabled && !p.isLoading && 'pointer'};
  opacity: ${p => (!p.isDisabled && !p.isLoading ? 1 : 0.6)};
`;

const ToggleEl = styled.input.attrs({
  type: 'checkbox',
  value: 1,
})`
  ${p => p.theme.mixins.showSROnly}
`;

const ToggleHandle = styled.span`
  position: relative;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-start;
  height: 16px;
  width: 34px;
  padding: 2px;
  background-color: ${p => p.theme.colors.grey15};;
  border-radius: 4px;
  cursor: ${p => !p.isDisabled && !p.isLoading && 'pointer'};

  &::before {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    background-color: white;
    content: '';
    transition: transform 0.15s ease;
  }

  ${ToggleEl}:checked + & {
    background-color: ${p => p.theme.colors.blue};

    &::before {
      transform: translateX(18px);
    }
  }
`;

const ToggleLabel = styled.span`
  ${p => p.theme.type.textRegular}
  display: flex;
  align-items: center;
  margin-left: ${p => p.theme.spacing.small};
  font-size: 11px;
`;

const Toggle = props => (
  <ToggleWrapper
    isDisabled={props.disabled}
    isLoading={props.loading}
    className={props.className}
    onClick={e => e.stopPropagation()}
  >
    <ToggleEl
      aria-label={props.ariaLabel}
      disabled={props.disabled}
      checked={props.active}
      onChange={e => {
        e.stopPropagation();
        if (props.onClick && !props.disabled) {
          props.onClick();
        }
      }}
    />

    <ToggleHandle isDisabled={props.disabled} isLoading={props.loading} />

    {props.label && <ToggleLabel>{props.label}</ToggleLabel>}
  </ToggleWrapper>
);

Toggle.propTypes = {
  propagateClick: PropTypes.bool,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  label: PropTypes.any,
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default Toggle;
