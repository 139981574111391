import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { randomNumber } from '../../utils/misc';


const randomGreyColor = () => {
  const arr = ['#fafafa', '#e9e9e9', '#cfcfcf'];
  return arr[Math.floor(Math.random() * arr.length)];
};


const Cell = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${p => p.theme.matrixCellSize}px;
  height: ${p => p.theme.matrixCellSize}px;
  border-top: 1px solid white;
  border-bottom: 1px solid white;

  text-align: center;
  user-select: none !important;
  outline: none !important;
  font-size: 13px;
  font-weight: lighter;
  color: white;

  ${p => p.color && `
    background-color: ${p.theme[p.color]};
  `}
`;

const CellText = styled.div`
  ${p => p.theme.truncate}
  ${p => p.loading && `
    width: 100%;
    height: 100%;
    animation-duration: 1.3s;
  `}
`;


const MatrixCell = ({ attrs = {}, ...props }) => (
  <Cell
    className={props.className}
    style={{ backgroundColor: props.loading ? randomGreyColor() : null }}
    color={props.color}
    onMouseEnter={() => {
      if (props.onMouseEnter) {
        props.onMouseEnter();
      }
    }}
    onMouseLeave={() => {
      if (props.onMouseLeave) {
        props.onMouseLeave();
      }
    }}
    {...attrs}
  >
    <CellText
      loading={props.loading}
      style={{
        animationName: props.loading ? 'mmxCellLoading' : '',
        animationDelay: `${randomNumber(1, 200) / 200}s`,
        animationIterationCount: 'infinite'
      }}
    >
      {props.label}
    </CellText>
  </Cell>
);

MatrixCell.propTypes = {
  loading: PropTypes.bool,
  label: PropTypes.any,
  color: PropTypes.string,
  className: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func
};

export default MatrixCell;
