import { isEmpty, trim } from 'lodash';

export const validateFocusTerm = (focusTerm: string): boolean => {
  focusTerm = trim(focusTerm);

  if (isEmpty(focusTerm)) {
    return false;
  }

  if (focusTerm.length > 500) {
    return false;
  }

  return true;
};
