import React from 'react';
import { CustomFilterProps } from '@tanstack/react-table';
import InputBetween from './InputBetween';

export function FilterInteger({ column, table }: CustomFilterProps) {
  const setFilterValue = column.setFilterValue;
  const filterValue = column.getFilterValue();
  const meta = column.columnDef?.meta;

  return (
    <InputBetween
      labelForExclude={meta?.filter?.labelForExclude}
      filterFn={column.columnDef.filterFn}
      filterValue={filterValue as number[]}
      id={column.id}
      setFilterValue={setFilterValue}
      title={meta?.filter?.title}
    />
  );
}
