import React, { ReactNode } from 'react';
import cx from 'classnames';

import { Polymorphic } from '../Polymorphic';

export interface TextAnchorProps {
  className?: string | Array<string | string[]>;
  as?: React.ElementType<any>;
  children: ReactNode;
  href: HTMLAnchorElement['href'];
  target?: HTMLAnchorElement['target'];
}

export const TextAnchor = React.forwardRef<HTMLAnchorElement, TextAnchorProps>(
  ({ as, className, ...props }, ref) => {
    const element = as || 'a';
    return (
      <Polymorphic
        target="_blank"
        rel="noreferrer"
        {...props}
        as={element}
        className={cx([
          'transition',
          'duration-300',
          'text-blue-400',
          'hover:text-blue-600',
          className,
        ])}
        ref={ref}
      />
    );
  },
);

export default TextAnchor;
