import { orderBy } from 'lodash';
import { useMemo } from 'react';
import { SeasonalityItem } from './types';

export type UseHistoryHook = (data: SeasonalityItem[]) => SeasonalityItem[];

export const useHistory: UseHistoryHook = data => {
  return useMemo(() => {
    const history = data
      .filter(value => value.yearMonth)
      .map(({ yearMonth, volume }) => {
        const [month, year] = yearMonth.split('-');
        return { volume, year, month, yearMonth };
      });

    const ordered = orderBy(history, ['year', 'month'], ['asc', 'asc']);
    return ordered.map(({ yearMonth, volume }) => ({
      yearMonth,
      volume: volume || 0,
    }));
  }, [data]);
};
