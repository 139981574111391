import { useMemo } from 'react';
import { useStoreSelector } from '@marketmuse/data-state/hooks';
import { isLoading } from '@marketmuse/research-data';

const useIsLoading = () => {
  const networkStatus = useStoreSelector(state => state?.appResearch?.network);

  const loadingTopicNavigator = useMemo(() => {
    return [
      networkStatus?.knowledgeGraph,
      networkStatus?.bulkAdwordsKeywords,
      networkStatus?.adwordsKeywords,
      networkStatus?.serpQuestions,
      networkStatus?.adwordsQuestions,
    ].some(status => isLoading(status));
  }, [
    networkStatus?.knowledgeGraph,
    networkStatus?.bulkAdwordsKeywords,
    networkStatus?.adwordsKeywords,
    networkStatus?.serpQuestions,
    networkStatus?.adwordsQuestions,
  ]);

  const loadingXray = useMemo(() => {
    return [
      networkStatus?.knowledgeGraph,
      networkStatus?.serpData,
      networkStatus?.serpPages,
    ].some(status => isLoading(status));
  }, [
    networkStatus?.knowledgeGraph,
    networkStatus?.serpData,
    networkStatus?.serpPages,
  ]);

  const loadingHeatmap = useMemo(() => {
    return [
      networkStatus?.knowledgeGraph,
      networkStatus?.serpData,
      networkStatus?.serpPages,
    ].some(status => isLoading(status));
  }, [
    networkStatus?.knowledgeGraph,
    networkStatus?.serpData,
    networkStatus?.serpPages,
  ]);

  const loadingConnect = useMemo(
    () => isLoading(networkStatus?.linkingRecommendations),
    [networkStatus?.linkingRecommendations],
  );

  return {
    loadingConnect,
    loadingHeatmap,
    loadingTopicNavigator,
    loadingXray,
  };
};

export default useIsLoading;
