import { isEmpty, get } from 'lodash';

import { GetOrgsDocument } from '@marketmuse/data-papi';
import makeRequest from '../../utils/makeRequest';
import * as types from '../../config/types';

export default () => () => next => action => {
  if (action.type === types.ADMIN_GET_ORGS) {
    const { limit, order, page, query } = action;

    const searchString = (query || '').trim();
    const filters = isEmpty(searchString)
      ? void 0
      : [
          {
            OR: [
              { nameContains: searchString },
              { id: searchString },
              {
                sites: [
                  {
                    OR: [
                      { domainContains: searchString },
                      { titleContains: searchString },
                      { id: searchString },
                    ],
                  },
                ],
              },
            ],
          },
        ];

    makeRequest({
      type: types.ADMIN_GET_ORGS,
      mutation: GetOrgsDocument,
      keys: (action.keys || []).map(key => `get_orgs_${key}`),
      variables: {
        order: order || 'created_at__desc',
        page,
        limit,
        filters,
      },
      options: {
        isQuery: true,
      },
      apolloOptions: {
        fetchPolicy: 'no-cache',
      },
    }).then(res => {
      const data = {
        items: get(res, 'organizations.items') || [],
        count: get(res, 'organizations.count') || 0,
      };
      if (action.callback) action.callback(data);
    });
  }

  return next(action);
};
