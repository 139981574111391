import { FC, ReactNode } from 'react';

import type { Country } from '@marketmuse/config/types/papi';
import { useStoreSelector } from '@marketmuse/data-state/hooks';
import { appResearchSlices } from '@marketmuse/data-state/appResearch';
import { ResearchDataInput } from '@marketmuse/data-state/types';
import { omitItems, getCountryEnum } from '@marketmuse/utilities';
import { ResearchUiStateContext } from '../context';
import { IResearchUiStateContext } from '../types';

const ResearchUiStateProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const siteId = useStoreSelector(state => state?.filter?.site?.id);
  const siteCountry = useStoreSelector(
    state => state?.filter?.site?.defaultSerpCountry,
  );
  const displayExactDistribution = useStoreSelector(
    state => state?.user?.displayExactDistribution,
  );

  const params: ResearchDataInput = useStoreSelector(
    state =>
      state?.appResearch?.ui?.params ||
      appResearchSlices.initialState.ui.params,
  );
  const appQuery = useStoreSelector(state => state?.appResearch?.ui?.appQuery);

  const context: IResearchUiStateContext = {
    ...omitItems(params, ['term', 'url', 'country', 'language']),
    siteId,
    siteCountry: getCountryEnum(siteCountry) as Country,
    displayExactDistribution,
    term: appQuery?.term,
    url: appQuery?.url,
    country: appQuery?.country,
    language: appQuery?.language,
  };

  return (
    <ResearchUiStateContext.Provider value={context}>
      {children}
    </ResearchUiStateContext.Provider>
  );
};

export default ResearchUiStateProvider;
