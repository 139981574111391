import {
  ApolloLink,
  DefaultContext,
  NextLink,
  Operation,
} from '@apollo/client';

export const recurlyEnvLink = new ApolloLink(
  (operation: Operation, forward: NextLink) => {
    const isTestMode = process.env.REACT_APP_RECURLY_MODE !== 'production';

    if (isTestMode) {
      operation.setContext((context: DefaultContext) => {
        return {
          ...context,
          headers: {
            ...context.headers,
            UseRecurlyTestCode: true,
          },
        };
      });
    }

    return forward(operation);
  },
);
