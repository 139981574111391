import { Box } from '../Box';

interface ISeasonalityTooltipProps {
  active?: boolean;
  payload?: any;
}

const SeasonalityTooltip = ({ active, payload }: ISeasonalityTooltipProps) => {
  if (active && payload && payload.length) {
    const { yearMonth, volume } = payload[0]?.payload || {};
    return (
      <Box
        className={[
          'bg-white',
          'mt-4',
          'px-2',
          'py-1',
          'rounded',
          'text-xs',
          'shadow-md',
        ]}
      >
        {yearMonth}: {volume}
      </Box>
    );
  }

  return null;
};

export default SeasonalityTooltip;
