import get from 'lodash/get';

import { iterableBps } from '../../styles/lib/breakpoints';
import mixins from '../../styles/lib/mixins';

/*
  Prop getter
  - get a default from a prop, cascading up the dependency tree
  - if prop[sProp].initial is set, use first
  - if prop[sProp] is set, use that -- TODO: do object detection
  - if no sProp defined, use 'inherent' fallback
  - if inherent is nullified, use a preferred browser fallback -- TODO: Set for all sProp
 */
const getStyleProp = (p, sProp) => {
  const styleProp = get(p, sProp);
  const initial = get(p, `['${sProp}'].initial`);
  const inherent = get(p, `inherent['${sProp}']`);

  if (initial) {
    return initial;
  }

  if (styleProp && typeof styleProp !== 'object') {
    return styleProp;
  }

  return inherent;
};

const hasStyleAtBp = (bp, props, sProps) =>
  sProps.filter(sProp => props[sProp] && props[sProp][bp.id]).length;

const parseGridColumn = (span, gridColumn) => {
  const spanValue = `span ${span}`;
  return span ? spanValue : gridColumn;
};

const parseGridTemplateColumns = (columns, gridTemplateColumns) => {
  const columnsValue = `repeat(${columns}, 1fr)`;
  return columns ? columnsValue : gridTemplateColumns;
};

// Breakpoints, and styles to match each
const itemStyleProps = [
  'gridColumn',
  'gridRow',
  'padding',
  'span',
  'alignSelf',
  'justifySelf',
];

const itemBPStyles = p => {
  return iterableBps.map(bp => {
    if (!hasStyleAtBp(bp, p, itemStyleProps)) {
      return '';
    }

    const span = get(p, `span[${bp.id}]`);
    const gridRow = get(p, `gridRow[${bp.id}]`);
    const gridColumn = get(p, `gridColumn[${bp.id}]`);
    const alignSelf = get(p, `alignSelf[${bp.id}]`);
    const order = get(p, `order[${bp.id}]`);
    const justifySelf = get(p, `justifySelf[${bp.id}]`);
    const padding = get(p, `padding[${bp.id}]`);

    const bpData = {
      gridColumn: parseGridColumn(span, gridColumn),
      gridRow,
      alignSelf,
      justifySelf,
      padding,
      order,
    };

    return mixins.minMediaQuery[bp.id](bpData);
  });
};

const containerStyleProps = [
  'gap',
  'gridTemplateColumns',
  'gridTemplateRows',
  'gridAutoFlow',
  'gridAutoRows',
  'gridAutoColumns',
  'alignContent',
  'alignItems',
  'justifyContent',
  'justifyItems',
  'columns',
];

const containerBPStyles = p => {
  return iterableBps.map(bp => {
    if (!hasStyleAtBp(bp, p, containerStyleProps)) {
      return '';
    }

    const columns = get(p, `columns[${bp.id}]`);
    const gridGap = get(p, `gap[${bp.id}]`);
    const gridTemplateColumns = get(p, `gridTemplateColumns[${bp.id}]`);
    const gridTemplateRows = get(p, `gridTemplateRows[${bp.id}]`);
    const gridAutoFlow = get(p, `gridAutoFlow[${bp.id}]`);
    const gridAutoRows = get(p, `gridAutoRows[${bp.id}]`);
    const gridAutoColumns = get(p, `gridAutoColumns[${bp.id}]`);

    const alignContent = get(p, `alignContent[${bp.id}]`);
    const alignItems = get(p, `alignItems[${bp.id}]`);
    const justifyContent = get(p, `justifyContent[${bp.id}]`);
    const justifyItems = get(p, `justifyItems[${bp.id}]`);


    const maxWidth = get(p, `maxWidth[${bp.id}]`);
    const padding = get(p, `padding[${bp.id}]`);

    const bpData = {
      gridGap,
      gridTemplateRows,
      gridTemplateColumns: parseGridTemplateColumns(columns, gridTemplateColumns),
      gridAutoFlow,
      gridAutoRows,
      gridAutoColumns,
      alignContent,
      alignItems,
      justifyContent,
      justifyItems,
      maxWidth,
      padding,
    };

    return mixins.minMediaQuery[bp.id](bpData);
  });
};

export {
  containerBPStyles,
  itemBPStyles,
  getStyleProp,
  parseGridTemplateColumns,
  parseGridColumn,
};
