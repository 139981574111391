import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import get from 'lodash/get';

import Modal from '../../../../../components/Modal';
import TileHeader from '../../../../../components/TileHeader';
import DistributionTile from '../../../../../components/DistributionTile';
import * as SL from '../../../../../components/SimpleList/themes/app';

const Slideout = styled(Modal).attrs({
  position: 'right',
  closeStyle: {
    position: 'fixed',
    right: '528px',
  },
})`
  width: 520px;
  display: flex;
  flex-flow: column;
`;

const SlideoutBody = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
`;

const NewsTopicVariants = props => {
  const selectedTopic = props.selectedTopic;

  const appData = props.appData || {};
  const allVariants = get(appData, 'scoresNews.all_variants') || {};
  const pages = get(appData, 'scoresNews.pages') || {};
  const you = Object.values(pages)[0] || null;
  const yourDistributions = get(you, 'mentions') || {};
  const termVariants = allVariants[selectedTopic.term] || [];

  const tiles = [];

  if (you) {
    tiles.push(
      <DistributionTile
        value={yourDistributions[selectedTopic] || 0}
        valueTitle="Your Distribution"
      />,
    );
  }

  tiles.push(
    <DistributionTile
      value={selectedTopic.recommended_mentions}
      valueTitle="Suggested Distribution"
    />,
  );

  return (
    <Slideout
      id="newsroom-topic-variants-slideout"
      style={{ width: 520 }}
      close={props.close}
    >
      <TileHeader header="Topic" title={selectedTopic.term} tiles={tiles} />
      <SlideoutBody>
        <>
          <SL.Row header sticky style={{ top: 0, zIndex: 999999 }}>
            <SL.Item grow header pl={22}>
              Variants
            </SL.Item>
          </SL.Row>
          {termVariants.length === 0 && (
            <SL.Row>
              <SL.Item grow truncate pl={22}>
                No data
              </SL.Item>
            </SL.Row>
          )}
          {termVariants.map((v, i) => (
            <SL.Row key={`newsroom-term-variant-${v.term}-${i}`}>
              <SL.Item grow truncate pl={22}>
                {v.term}
              </SL.Item>
            </SL.Row>
          ))}
        </>
      </SlideoutBody>
    </Slideout>
  );
};

NewsTopicVariants.propTypes = {
  close: PropTypes.func,
  selectedTopic: PropTypes.object,
  appData: PropTypes.object,
};

export default NewsTopicVariants;
