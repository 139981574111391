import { checkSiteViableInventory } from '@marketmuse/utilities';
import { PAPI } from '@marketmuse/config/types';
import { validatePermission } from '@marketmuse/utilities';
import { AppUiStatus } from '@marketmuse/config/types/applications';
import {
  AppQueryNetworkKeys,
  AppQueryNetworkStatus,
  ReduxListener,
  ResearchDataInput,
  RootState,
} from '../../../types';
import { researchTaskInitialize, researchTaskSiteChange } from '../../actions';

import { networkSlice, uiSlice } from '../../slices';
import {
  getAdwordsKeywords,
  getAdwordsQuestions,
  getSerpData,
} from '../../thunks';
import validateAppParams from '../../../utils/validateAppParams';

const serializeListenerPermissions = (permissions: Array<PAPI.Permission>) => {
  const canAccessConnect = validatePermission({
    permissions,
    permission: {
      resource: PAPI.PermissionResource.RESEARCH_CONNECT,
      action: PAPI.PermissionAction.ACCESS_DATA,
    },
  });
  const canAccessKeywords = validatePermission({
    permissions,
    permission: {
      resource: PAPI.PermissionResource.RESEARCH_KEYWORDS,
      action: PAPI.PermissionAction.ACCESS_DATA,
    },
  });
  const canAccessQuestions = validatePermission({
    permissions,
    permission: {
      resource: PAPI.PermissionResource.RESEARCH_QUESTIONS,
      action: PAPI.PermissionAction.ACCESS_DATA,
    },
  });
  const canAccessReflect = validatePermission({
    permissions,
    permission: {
      resource: PAPI.PermissionResource.RESEARCH_REFLECT,
      action: PAPI.PermissionAction.ACCESS_DATA,
    },
  });
  const canAccessXray = validatePermission({
    permissions,
    permission: {
      resource: PAPI.PermissionResource.RESEARCH_SERP_X_RAY,
      action: PAPI.PermissionAction.ACCESS_DATA,
    },
  });
  const canAccessWebsiteHeatmap = validatePermission({
    permissions,
    permission: {
      resource: PAPI.PermissionResource.RESEARCH_HEATMAP_WEBSITE,
      action: PAPI.PermissionAction.ACCESS_DATA,
    },
  });

  return {
    canAccessConnect,
    canAccessKeywords,
    canAccessQuestions,
    canAccessReflect,
    canAccessXray,
    canAccessWebsiteHeatmap,
  };
};

const setup = (startListening: ReduxListener) => {
  const networkActions = networkSlice.actions;
  const uiActions = uiSlice.actions;

  startListening({
    actionCreator: researchTaskSiteChange,
    effect: async (action, { cancelActiveListeners }) => {
      cancelActiveListeners();
    },
  });

  startListening({
    actionCreator: researchTaskInitialize,
    effect: async (action, { dispatch, getState }) => {
      const payload: ResearchDataInput = action.payload;
      const state: RootState = getState();
      const site = state?.filter?.site;
      const versions = state?.filter?.versions;
      const permissions = state.permissions;
      const isParamsValid = validateAppParams<ResearchDataInput>(payload);
      const isSiteInventoryViable = checkSiteViableInventory({
        invId: site.invId,
        versions,
        permissions,
      });
      const appStatus = state.appResearch.ui.status;

      const queueKeys = [
        AppQueryNetworkKeys.knowledgeGraph,
        AppQueryNetworkKeys.bulkAdwordsKeywords,
        AppQueryNetworkKeys.serpPages,
        AppQueryNetworkKeys.websiteHeatmap,
      ];
      const completeKeys = [];

      const {
        canAccessConnect,
        canAccessKeywords,
        canAccessQuestions,
        canAccessReflect,
        canAccessXray,
        canAccessWebsiteHeatmap,
      } = serializeListenerPermissions(permissions);

      [
        {
          canAccess: canAccessQuestions,
          key: AppQueryNetworkKeys.serpQuestions,
        },
        {
          canAccess: canAccessConnect,
          key: AppQueryNetworkKeys.linkingRecommendations,
        },
        {
          canAccess: canAccessXray,
          key: AppQueryNetworkKeys.serpUserIntentData,
        },
        {
          canAccess: canAccessWebsiteHeatmap,
          key: AppQueryNetworkKeys.websiteHeatmap,
        },

        ...[
          AppQueryNetworkKeys.inventoryTopicsKeywords,
          AppQueryNetworkKeys.inventoryTopicsKG,
          AppQueryNetworkKeys.inventoryTopicsBulkKeywords,
          AppQueryNetworkKeys.inventoryTopicsQuestions,
          AppQueryNetworkKeys.inventoryTopicsSerpQuestions,
        ].map(key => ({
          canAccess: isSiteInventoryViable && canAccessReflect,
          key,
        })),
      ].forEach(({ canAccess, key }) => {
        if (canAccess) {
          queueKeys.push(key);
        } else {
          completeKeys.push(key);
        }
      });

      if (isParamsValid) {
        dispatch(uiActions.statusUpdate(AppUiStatus.loading));
        dispatch(
          networkActions.setMany({
            keys: queueKeys,
            status: AppQueryNetworkStatus.queued,
          }),
        );

        const requests = [dispatch(getSerpData(payload))];

        if (canAccessQuestions) {
          requests.unshift(dispatch(getAdwordsQuestions(payload)));
        } else {
          completeKeys.push(AppQueryNetworkKeys.adwordsQuestions);
        }

        if (canAccessKeywords) {
          requests.unshift(dispatch(getAdwordsKeywords(payload)));
        } else {
          completeKeys.push(AppQueryNetworkKeys.adwordsKeywords);
        }

        dispatch(
          networkActions.setMany({
            keys: completeKeys,
            status: AppQueryNetworkStatus.complete,
          }),
        );

        await Promise.all(requests);
      } else {
        console.log('app run prevented', {
          params: payload,
          isParamsValid,
          isSiteInventoryViable,
          appStatus,
        });
      }
    },
  });
};

export default setup;
