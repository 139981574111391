import { omit } from 'lodash';

import {
  AdWordsItemWithVariants,
  KnowledgeGraph,
  SeoKnowledgeGraphCompleteQueryVariables,
} from '@marketmuse/config/types/papi';
import {
  SeoSerpPageDataDocument,
  SeoKnowledgeGraphCompleteDocument,
  SeoBulkAdwordsKeywordsMinimalDocument,
} from '@marketmuse/data-papi';
import { ACTION_TYPES } from '@marketmuse/data-state';
import makeRequest from '../../utils/makeRequest';

type ReturnType = {
  seoKnowledgeGraph: KnowledgeGraph;
  seoBulkAdwordsKeywords: AdWordsItemWithVariants[];
};

const fetchKgAndVariants = async ({
  fetchSerpPages,
  variables,
  skipVariants,
}: {
  fetchSerpPages: boolean;
  variables: SeoKnowledgeGraphCompleteQueryVariables;
  skipVariants: boolean;
}): Promise<ReturnType> => {
  try {
    if (fetchSerpPages) {
      const pageResponse = await makeRequest({
        type: ACTION_TYPES.GET_PAGE_CACHE_FOR_KG,
        mutation: SeoSerpPageDataDocument,
        options: { isQuery: true },
        variables,
      });

      if (
        !pageResponse.seoSerpPageData ||
        pageResponse.errors ||
        pageResponse.error
      ) {
        throw new Error(pageResponse.errors || pageResponse.error);
      }
    }

    // Request for seoKnowledgeGraph
    const kgResponse = await makeRequest({
      type: ACTION_TYPES.GET_KG_COMPLETE,
      mutation: SeoKnowledgeGraphCompleteDocument,
      options: { isQuery: true },
      variables: {
        ...variables,
        skipPageScrapping: !fetchSerpPages,
      },
    });

    if (
      !kgResponse.seoKnowledgeGraph ||
      kgResponse.errors ||
      kgResponse.error
    ) {
      throw new Error(
        kgResponse.errors || kgResponse.error || 'Error fetching KG Response',
      );
    }

    if (skipVariants) {
      return {
        seoKnowledgeGraph: kgResponse.seoKnowledgeGraph,
        seoBulkAdwordsKeywords: [],
      };
    }

    const terms = kgResponse.seoKnowledgeGraph.knowledgeGraphItems.reduce(
      (acc, item) => [...acc, item.term],
      [],
    );

    if (terms?.length < 1) {
      throw new Error('No terms found');
    }

    // Request for bulk adwords keywords
    const bulkAwResponse = await makeRequest({
      type: ACTION_TYPES.GET_BULK_AW_KEYWORDS,
      mutation: SeoBulkAdwordsKeywordsMinimalDocument,
      options: { isQuery: true },
      variables: {
        ...omit(variables, ['term']),
        terms,
      },
    });

    if (
      !bulkAwResponse.seoBulkAdwordsKeywords ||
      bulkAwResponse.errors ||
      bulkAwResponse.error
    ) {
      throw new Error(bulkAwResponse.errors || bulkAwResponse.error);
    }

    return {
      seoKnowledgeGraph: kgResponse.seoKnowledgeGraph,
      seoBulkAdwordsKeywords: bulkAwResponse.seoBulkAdwordsKeywords,
    };
  } catch (error) {
    throw new Error(error);
  }
};

export default fetchKgAndVariants;
