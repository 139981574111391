import { ApolloQueryResult } from '@apollo/client';
import { pick, isEmpty } from 'lodash';

import type {
  SeoSerpPageDataQuery,
  SeoKnowledgeGraphCompleteQuery,
  KnowledgeGraph,
} from '@marketmuse/config/types/papi';
import {
  SeoSerpPageDataDocument,
  SeoKnowledgeGraphCompleteDocument,
} from '@marketmuse/data-papi';
import { errorsToString } from '@marketmuse/utilities';

import { AppQueryParams, ThunkApiConfig } from '../../types';
import serializeKnowledgeGraphResponse from '../../utils/serializeKnowledgeGraphResponse';
import createNonConcurrentAsyncThunk from '../../utils/createNonConcurrentAsyncThunk';

const getKnowledgeGraph = createNonConcurrentAsyncThunk<
  { data: KnowledgeGraph },
  AppQueryParams,
  ThunkApiConfig
>('optimize/getKnowledgeGraph', async (params, { extra, rejectWithValue }) => {
  const variablesCommon = pick(params, ['term', 'url', 'country', 'language']);
  const variablesKG = {
    ...variablesCommon,
    skipPageScrapping: true,
  };

  const responseSerpPages: ApolloQueryResult<SeoSerpPageDataQuery> =
    await extra.apollo.query({
      query: SeoSerpPageDataDocument,
      variables: variablesCommon,
    });

  const errorSerpPages = responseSerpPages.errors;
  if (errorSerpPages || isEmpty(responseSerpPages?.data?.seoSerpPageData)) {
    return rejectWithValue(errorSerpPages);
  }

  const response: ApolloQueryResult<SeoKnowledgeGraphCompleteQuery> =
    await extra.apollo.query({
      query: SeoKnowledgeGraphCompleteDocument,
      variables: variablesKG,
    });

  const error = response.errors;
  const completeError = error && isEmpty(response?.data?.seoKnowledgeGraph);
  const noTopicModel = isEmpty(
    response?.data?.seoKnowledgeGraph.knowledgeGraphItems,
  );

  if (completeError || noTopicModel) {
    extra.apollo.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'seoKnowledgeGraph',
      args: variablesKG,
    });
    extra.apollo.cache.gc();

    const message = noTopicModel
      ? 'No topic model'
      : errorsToString(response.errors as unknown as Array<Error>);

    return rejectWithValue(message);
  }

  const knowledgeGraph =
    response?.data?.seoKnowledgeGraph || ({} as KnowledgeGraph);

  const data = serializeKnowledgeGraphResponse(knowledgeGraph);

  return {
    data,
  };
});

export default getKnowledgeGraph;
