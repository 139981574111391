import { ReduxListener } from '../../../types';

import { networkSlice } from '../../slices/network.slice';
import {
  getInvItemsAdwordsKeywords,
  getInvItemsAdwordsQuestions,
  getInvItemsBulkAdwordsKeywords,
  getInvItemsKnowledgeGraph,
  getInvItemsSerpQuestions,
} from '../../thunks';
import { createInventoryItemsListeners } from '../../utils/createInventoryItemsListeners';

const listeners = (startListening: ReduxListener) => {
  const networkActions = networkSlice.actions;
  [
    {
      thunk: getInvItemsAdwordsKeywords,
      networkSet: networkActions.inventoryTopicsKeywordsSet,
    },
    {
      thunk: getInvItemsAdwordsQuestions,
      networkSet: networkActions.inventoryTopicsQuestionsSet,
    },
    {
      thunk: getInvItemsKnowledgeGraph,
      networkSet: networkActions.inventoryTopicsKGSet,
    },
    {
      thunk: getInvItemsBulkAdwordsKeywords,
      networkSet: networkActions.inventoryTopicsBulkKeywordsSet,
    },
    {
      thunk: getInvItemsSerpQuestions,
      networkSet: networkActions.inventoryTopicsSerpQuestionsSet,
    },
  ].forEach(({ thunk, networkSet }) => {
    createInventoryItemsListeners({
      startListening,
      thunk,
      networkSet,
    });
  });
};

export default listeners;
