import { Plugin } from 'ckeditor5/src/core';

const TRACK_CLIPBOARD = 'trackClipboard';

class TrackClipboard extends Plugin {
  static get pluginName() {
    return 'TrackClipboard';
  }

  constructor(editor) {
    super(editor);

    editor.config.define(TRACK_CLIPBOARD, {
      onCopy: () => void 0,
      onPaste: () => void 0,
    });

    this._config = editor.config.get(TRACK_CLIPBOARD) || {};
  }

  init() {
    const editor = this.editor;
    const view = editor.editing.view;

    view.document.on('clipboardOutput', this._config.onCopy);
    view.document.on('clipboardInput', this._config.onPaste);
  }
}

export default TrackClipboard;
