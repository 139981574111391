import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import IconSvg from '../../../components/IconSvg/IconSvg';
import Tooltip from '../../../components/Tooltip/Tooltip/Tooltip';
import Popper from '../../../components/Tooltip/Popper/Popper';
import Button from '../../../components/Button';

import FilterFactory from './FilterFactory';

const ButtonStyled = styled(Button)`
  padding-right: 0;
  padding-left: 0;
`;

const AdvancedSettings = ({ tool, tooltip, setState, state }) => (
  <Tooltip title={tooltip} disabled={!tooltip}>
    <Popper
      label="Advanced Settings"
      tippyUsage="popper"
      position="bottom-end"
      handleId={'page-header-advanced-settings'}
      hideOnClick={'toggle'}
      html={<FilterFactory tool={tool} state={state} setState={setState} />}
    >
      <ButtonStyled large tertiary onClick={() => {}}>
        <IconSvg name="settings-outline" size={28} box={20} />
      </ButtonStyled>
    </Popper>
  </Tooltip>
);

AdvancedSettings.propTypes = {
  setState: PropTypes.func.isRequired,
  state: PropTypes.shape({}),
  tooltip: PropTypes.string,
  tool: PropTypes.string,
};

export default memo(AdvancedSettings);
