import { SiteAggregateStatsDocument } from '@marketmuse/data-papi';
import { ACTION_TYPES } from '@marketmuse/data-state';
import { setAggregateStats } from '../../actions/siteActions';
import makeRequest from '../../utils/makeRequest';

export default () =>
  ({ dispatch, getState }) =>
  next =>
  action => {
    if (action.type === ACTION_TYPES.AGGREGATE_STATS_GET) {
      makeRequest({
        type: ACTION_TYPES.AGGREGATE_STATS_GET,
        mutation: SiteAggregateStatsDocument,
        variables: {
          siteId: action.payload,
        },
        options: {
          isQuery: true,
        },
        apolloOptions: {
          fetchPolicy: 'no-cache',
        },
      }).then(res => {
        const data = res?.siteAggregateStats ?? {};
        dispatch(setAggregateStats({ data }));
      });
    }

    return next(action);
  };
