import { request } from '../actions/requestActions';
import { store } from '../config/instances';
import { apolloClient } from '../config/apollo/clients';

export default ({
  type,
  mutation,
  variables,
  keys = [],
  options = {},
  apolloOptions = {},
}) => {
  const client = apolloClient();

  return new Promise(resolve => {
    store.dispatch(
      request(
        type,
        options.isQuery ? client.query : client.mutate,
        {
          [options.isQuery ? 'query' : 'mutation']: mutation,
          variables,
          ...apolloOptions,
        },
        res => resolve(res),
        { keys: [type, ...keys], ...options },
      ),
    );
  });
};
