import { AsyncThunk } from '@reduxjs/toolkit';
import { NetworkStatus } from '@marketmuse/config/types';
import {
  ResearchThunkInvItemsInput,
  ResearchThunkInvItemsRes,
  ThunkApiConfig,
  AppQueryNetworkStatus,
  ReduxListener,
} from '../../types';
import { researchTaskStatusCheck } from '../actions';
import { dataSlice } from '../slices';

export function createInventoryItemsListeners({
  startListening,
  thunk,
  networkSet,
}: {
  startListening: ReduxListener;
  thunk: AsyncThunk<
    ResearchThunkInvItemsRes,
    ResearchThunkInvItemsInput,
    ThunkApiConfig
  >;
  networkSet: (status: NetworkStatus) => any;
}) {
  const dataActions = dataSlice.actions;

  startListening({
    actionCreator: thunk.pending,
    effect: async (action, { dispatch }) => {
      await dispatch(networkSet(AppQueryNetworkStatus.loading));
    },
  });

  startListening({
    actionCreator: thunk.rejected,
    effect: async (action, { dispatch }) => {
      const { aborted } = action.meta;
      if (!aborted) {
        await dispatch(networkSet(AppQueryNetworkStatus.error));
        await dispatch(researchTaskStatusCheck());
      }
    },
  });

  startListening({
    actionCreator: thunk.fulfilled,
    effect: async ({ payload }, { dispatch }) => {
      await Promise.all([
        dispatch(dataActions.inventoryTopicsUpdate(payload.data)),
        dispatch(networkSet(AppQueryNetworkStatus.complete)),
      ]);

      await dispatch(researchTaskStatusCheck());
    },
  });
}
