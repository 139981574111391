import get from 'lodash/get';

import { InquirePremiumDocument as INQUIRE_PREMIUM } from '@marketmuse/data-papi';
import makeRequest from '../../utils/makeRequest';
import * as types from '../../config/types';

export default () => () => next => action => {
  if (action.type === types.INQUIRE_PREMIUM) {
    makeRequest({
      type: types.INQUIRE_PREMIUM,
      mutation: INQUIRE_PREMIUM,
      variables: {
        planLevel: action.planLevel,
      },
    }).then(res => {
      const data = get(res, 'inquirePremium', {});
      if (data.status === 1 && typeof action.callback === 'function') {
        action.callback();
      }
    });
  }

  return next(action);
};
