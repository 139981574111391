import { FC, HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import { Box, BoxProps, Icon } from '@marketmuse/components';
import config, { ConfigType } from './config';

type HTMLDivProps = Omit<HTMLAttributes<HTMLDivElement>, keyof BoxProps>;
export interface ZeroStateProp extends BoxProps, HTMLDivProps {
  type: ConfigType;
}

export const ZeroState: FC<ZeroStateProp> = ({ type, className, ...rest }) => {
  const { iconName, title, description } = config[type];

  return (
    <Box
      {...rest}
      className={twMerge(
        [
          'flex',
          'flex-col',
          'items-center',
          'text-center',
          'py-14',
          'mx-auto',
          'text-gray-700',
          'overflow-hidden',
        ],
        className,
      )}
    >
      <Icon name={iconName} boxSize={36} />
      <h2 className="text-2xl font-bold mt-2">{title}</h2>
      <p className="max-w-[540px] text-base mt-3">{description}</p>
    </Box>
  );
};

export default ZeroState;
