import get from 'lodash/get';
import { ReportDeleteDocument } from '@marketmuse/data-papi';
import Toast from '../../components/Toast';

import makeRequest from '../../utils/makeRequest';
import * as types from '../../config/types';

export default () => () => next => action => {
  if (action.type === types.DELETE_REPORT) {
    makeRequest({
      type: types.DELETE_REPORT,
      mutation: ReportDeleteDocument,
      variables: {
        reportId: action.reportId,
      },
      keys: [`${types.DELETE_REPORT}-${action.reportId}`],
    }).then(res => {
      const deletedReport = get(res, 'deleteReport');
      if (deletedReport) {
        Toast.fire('Report successfully deleted');
      } else {
        Toast.fire(
          res.error || deletedReport.error || 'Unknown error',
          'error',
        );
      }
      if (action.callback) action.callback(deletedReport);
    });
  }

  return next(action);
};
