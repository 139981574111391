import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AdWordsItemWithVariants,
  KnowledgeGraph,
} from '@marketmuse/config/types/papi';

import {
  optimizeTaskQueryLoad,
  optimizeTaskQueryUpsert,
  optimizeTaskSiteChange,
} from '../../actions';
import { initialState } from './data.initialState';

export const dataSlice = createSlice({
  name: 'optimize/data',
  initialState,
  reducers: {
    bulkAdwordsKeywordsSet: (
      state,
      action: PayloadAction<AdWordsItemWithVariants[]>,
    ) => {
      state.bulkAdwordsKeywords = action.payload;
    },
    knowledgeGraphSet: (state, { payload }: PayloadAction<KnowledgeGraph>) => {
      state.knowledgeGraph = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(optimizeTaskSiteChange, () => {
        return initialState;
      })
      .addCase(optimizeTaskQueryLoad, () => {
        return initialState;
      })
      .addCase(optimizeTaskQueryUpsert, () => {
        return initialState;
      });
  },
});

export const dataActions = dataSlice.actions;
