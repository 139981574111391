import * as Sentry from '@sentry/browser';

export const lazyLoadReplay = () => {
  import('@sentry/react').then(({ replayIntegration }) => {
    const client = Sentry.getClient();
    if (!client) {
      return;
    }

    if (!client.getIntegrationByName('Replay')) {
      client.addIntegration(
        replayIntegration({
          maskAllText: false,
          blockAllMedia: true,
          unmask: [
            '[data-mms--page-header-input=search]',
            '[data-mms--page-header-input=term]',
            '[data-mms--page-header-input=url]',
          ],
        }),
      );
    }
  });
};
