export const SETTINGS = {
  CONFIG_NAME: 'aiWritingAssistance',
  NAME: 'AiWritingAssistance',
  NAME_UTILS: 'AiWritingAssistanceUtils',
  NAME_API_CLIENT: 'AiWritingAssistanceApiClient',
};

export const COMMANDS = {
  onClickDiscard: 'onClickDiscard',
  onClickInsertBelow: 'onClickInsertBelow',
  onClickReplaceSelection: 'onClickReplaceSelection',
  onExit: 'onExit',
  onShowBalloonTrigger: 'OnShowBalloonTrigger',
};

export const MISC = {
  MARKER: `${SETTINGS.NAME}:selection`,
  CSS_PROP: '--mm--cke-ai-padding-bottom',
};
