import { hideAll } from 'tippy.js';

export default id => {
  if (id) {
    const el = document.getElementById(id);
    if (el) el.click();
  } else {
    hideAll();
  }
};
