import { css } from 'styled-components';

/**
 * IMPORTANT!
 * These are global styles that applies to EVERY input and textarea of every theme.
 *
 * Please avoid making changes as much as possible.
 * If you can achieve your goal by creating a new theme, please do so.
 * If you HAVE TO make a chance, please check EVERY story
 * on storybook to make sure everything renders correctly!
 */
export default p => css`
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  min-width: 0;
  height: 100%;
  min-height: 0;
  font-size: 14px;
  line-height: 22px;
  background-color: transparent;
  border-radius: 4px;
  border: none;
  outline: none;
  ${p.theme.mixins.applyPadding(p)};
`;
