import { PAPI } from '@marketmuse/config/types';
import { validatePermission } from '@marketmuse/utilities';

import { AppQueryNetworkStatus, ReduxListener } from '../../../types';

import { researchTaskStatusCheck } from '../../actions';
import { dataSlice, networkSlice } from '../../slices';

import {
  getSerpData,
  getSerpPagesData,
  getSerpUserIntentData,
} from '../../thunks';

const listeners = (startListening: ReduxListener) => {
  const dataActions = dataSlice.actions;
  const networkActions = networkSlice.actions;

  startListening({
    actionCreator: getSerpData.pending,
    effect: async (action, { dispatch }) => {
      await dispatch(networkActions.serpDataSet(AppQueryNetworkStatus.loading));
    },
  });

  startListening({
    actionCreator: getSerpData.rejected,
    effect: async (action, { dispatch }) => {
      const { aborted } = action.meta;
      if (!aborted) {
        await dispatch(networkActions.serpDataSet(AppQueryNetworkStatus.error));

        // check status after other steps
        await dispatch(researchTaskStatusCheck());
      }
    },
  });

  startListening({
    actionCreator: getSerpData.fulfilled,
    effect: async ({ payload }, { dispatch }) => {
      await Promise.all([
        dispatch(dataActions.serpDataSet(payload.data)),
        dispatch(networkActions.serpDataSet(AppQueryNetworkStatus.complete)),
      ]);

      // check status after other steps
      await dispatch(researchTaskStatusCheck());
    },
  });

  startListening({
    actionCreator: getSerpData.fulfilled,
    effect: async ({ payload }, { dispatch, getState }) => {
      const canAccessXray = validatePermission({
        permissions: getState().permissions,
        permission: {
          resource: PAPI.PermissionResource.RESEARCH_SERP_X_RAY,
          action: PAPI.PermissionAction.ACCESS_DATA,
        },
      });
      const promises = [dispatch(getSerpPagesData(payload.params))];
      if (canAccessXray) {
        promises.push(dispatch(getSerpUserIntentData(payload.params)));
      }
      await Promise.all(promises);
    },
  });
};

export default listeners;
