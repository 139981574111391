import { createAction } from '@reduxjs/toolkit';

import {
  INVENTORY_VERSIONS_CHECK,
  INVENTORY_VERSIONS_RECHECK,
} from './filter.actionTypes';

export const inventoryVersionsCheck = createAction<{ siteId: string }>(
  INVENTORY_VERSIONS_CHECK,
);

export const inventoryVersionsRecheck = createAction(
  INVENTORY_VERSIONS_RECHECK,
);
