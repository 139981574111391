import { FC, Fragment, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { Transition } from '@headlessui/react';
import { Button } from '../../../Button';
import { Icon, SUPPORTED_ICON } from '../../../Icon';
import { MODAL_MODE } from '../types';
import useModalContentTransition from '../hooks/useModalContentTransition';

const closeClassNameBase = [
  'absolute',
  'top-0',
  'right-0',
  'text-gray-500',
  'hover:text-gray-500',
  'focus-visible:outline-none',
  'px-2.5',
];

type CloseButtonProps = {
  onClick: () => void;
  mode?: keyof typeof MODAL_MODE;
  className?: string | Array<string>;
  disableClose?: boolean;
};

const CloseButton: FC<CloseButtonProps> = ({
  onClick,
  mode,
  className,
  disableClose,
}) => {
  const transitionProps = useModalContentTransition(MODAL_MODE[mode]);

  const closeClassName = useMemo(() => {
    const slideout = [
      MODAL_MODE.slideoutRight,
      MODAL_MODE.slideoutLeft,
    ].includes(MODAL_MODE[mode]);

    const slideoutRight = MODAL_MODE.slideoutRight === MODAL_MODE[mode];

    if (slideout) {
      return twMerge(
        closeClassNameBase,
        ['top-2', 'bg-white', 'rounded-full', 'p-2', 'shadow', 'z-10'],
        slideoutRight ? ['right-[648px]'] : ['left-[648px]', 'right-auto'],
      );
    } else {
      return closeClassNameBase;
    }
  }, [mode]);

  return (
    <Transition.Child as={Fragment} {...transitionProps}>
      <Button
        disabled={disableClose}
        className={twMerge(closeClassName, className)}
        aria-label="Close"
        onClick={() => onClick()}
      >
        <Icon
          name={SUPPORTED_ICON.close}
          color={disableClose ? 'text-gray-200' : 'text-gray-600'}
        />
      </Button>
    </Transition.Child>
  );
};

export default CloseButton;
