import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isNil from 'lodash/isNil';

const overlayBackground = ({hoverAccent, clickable, theme, overlayItemsBackgroundTransparent}) => {
  if (hoverAccent || clickable) {
    return theme.mmxGreyFA
  }

  if (overlayItemsBackgroundTransparent) {
    return 'rgba(255, 255, 255, 0)'
  }

  return theme.white
}

// TODO: MMS-290
const RowOverlayWrapper = styled.div`
  display: inline-flex;
  visibility: ${p => (p.overlayItemsActive ? 'visibile' : 'hidden')};
  ${p =>
    p.overlayItemsAbsolute
      ? `
      top: 0;
      position: absolute;
      height: 100%;`
      : `
      position: sticky;`}
  right: 0;
  background-color: ${p => overlayBackground(p)};
  align-items: center;
  padding: 0 8px;
  z-index: 2000;

  ${p => !isNil(p.p) && `padding-left: ${p.theme.apply(p.p)};`}
  ${p => !isNil(p.p) && `padding-right: ${p.theme.apply(p.p)};`}
  ${p => !isNil(p.pl) && `padding-left: ${p.theme.apply(p.pl)};`}
  ${p => !isNil(p.pl) && `padding-right: ${p.theme.apply(p.pl)};`}

  ${p => p.overlayItemsActive && `
    .mms--sl-row & {
      visibility: visible
    }
  `}

  .mms--sl-row:hover & {
    visibility: visible
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 22px;
    height: 100%;
    transform: translateX(-100%);
    background: linear-gradient(
      to right,
      ${p => p.theme.utils.rgba(overlayBackground(p), 0) } 0%,
      ${p => p.theme.utils.rgba(overlayBackground(p), 1) } 100%
    );

    ${p => p.overlayItemsHideGradient && `display: none;`}
  }
`;

const RowOverlay = props => <RowOverlayWrapper {...props} />

RowOverlay.propTypes = {
  p: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  overlayItemsActive: PropTypes.bool, // force items to keep open disregarding hover
  overlayItemsAbsolute: PropTypes.bool, // overlay items renders over the row
  overlayItemsHideGradient: PropTypes.bool, // hide overlay transition gradient
  overlayItemsBackgroundTransparent: PropTypes.bool, // make overlay bacground transparent
  hoverAccent: PropTypes.bool,
  clickable: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.object,
};

RowOverlay.displayName = 'RowOverlay';

export default RowOverlay;
