import styled from 'styled-components';

import { isClickable } from '../utils';
import * as SL from '../';

const List = styled(SL.List)``;
const Item = styled(SL.Item)``;

const Row = styled(SL.Row).attrs(p => ({
  clickable: isClickable(p),
  ...p,
}))`
  ${p => !p.border && `border: 0 !important;`}
  min-height: 32px;
`;
const Separator = styled(SL.Separator)``;
const Scroll = styled(SL.Scroll)``;

export { List, Item, Row, Separator, Scroll };
