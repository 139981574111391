import React, { useCallback } from 'react';
import { twMerge } from 'tailwind-merge';

import { FloatingUi } from '../FloatingUi';
import { POPPER_THEME, POPPER_TYPE, TooltipProps } from '../types';

type TooltipComponent = (props: TooltipProps) => React.ReactElement | null;

export const Tooltip: TooltipComponent = React.forwardRef<
  HTMLElement,
  TooltipProps
>(
  (
    {
      content,
      theme = POPPER_THEME.light,
      showArrow = true,
      children,
      initialOpen,
      tooltipClassName,
      ...props
    },
    ref,
  ) => {
    const render = useCallback(
      () => (
        <div
          className={twMerge(
            [
              'p-2',
              'text-xs',
              'max-w-xs',
              'rounded-lg',
              'shadow-sm',
              theme === POPPER_THEME.light && [
                'bg-white',
                'border',
                'border-gray-200',
                'text-gray-800',
              ],
              theme === POPPER_THEME.dark && ['bg-gray-800', 'text-white'],
            ],
            tooltipClassName,
          )}
        >
          {content}
        </div>
      ),
      [content, theme, tooltipClassName],
    );

    return (
      <FloatingUi
        {...props}
        initialOpen={initialOpen}
        popperType={POPPER_TYPE.tooltip}
        showArrow={showArrow}
        theme={theme}
        render={render}
        ref={ref}
      >
        {children}
      </FloatingUi>
    );
  },
);
