import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { Box } from '../Box';
import ButtonBarItem from './components/ButtonBarItem';
import { ButtonBarProps } from './types';

const ButtonBar = forwardRef<HTMLDivElement, ButtonBarProps>(
  ({ items, activeId, dataId, defaultProps, className, onClick }, ref) => {
    return (
      <Box
        ref={ref}
        className={twMerge(
          ['w-fit', 'grid', 'p-1', 'bg-white', 'rounded-lg'],
          className,
        )}
        style={{ gridTemplateColumns: `repeat(${items.length}, 1fr)` }}
        onClick={onClick}
      >
        {items.map(item => (
          <ButtonBarItem
            key={item.id}
            {...{ item, activeId, dataId, defaultProps }}
          />
        ))}
      </Box>
    );
  },
);

export default ButtonBar;
