/* eslint-disable no-useless-escape */
// removes all characters from URL string besides domain name and tld
export const parseDomain = url =>
  url
    .replace(new RegExp(/^\s+/), '')
    .replace(new RegExp(/\s+$/), '')
    .replace(new RegExp(/\\/g), '/')
    .replace(new RegExp(/^http\:\/\/|^https\:\/\/|^ftp\:\/\//i), '')
    .replace(new RegExp(/^www\./i), '')
    .replace(new RegExp(/\/(.*)/), '')
    .toLowerCase();

/* eslint-enable no-useless-escape */

// tests whether a URL subdomain
export const checkSubdomain = url => {
  url = parseDomain(url);
  if (url.match(new RegExp(/\.[a-z]{2,3}\.[a-z]{2}$/i))) {
    url = url.replace(new RegExp(/\.[a-z]{2,3}\.[a-z]{2}$/i), '');
  } else if (url.match(new RegExp(/\.[a-z]{2,4}$/i))) {
    url = url.replace(new RegExp(/\.[a-z]{2,4}$/i), '');
  }
  return url.match(new RegExp(/\./g)) ? true : false;
};

/**
 * Checks if a supplied string is matches against a site.domain
 * @param siteUrl {String}
 * @param searchString {String}
 * @returns {boolean}
 */
export const subdomainBelongsToRootDomain = (siteUrl, searchString) => {
  const isSubdomain = checkSubdomain(searchString);
  const srcDomain = parseDomain(siteUrl);
  const domainString = parseDomain(searchString);

  const stringsMatch = srcDomain === domainString;
  const containsSiteUrl = isSubdomain && domainString.includes(srcDomain);

  return stringsMatch || containsSiteUrl;
};
