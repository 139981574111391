import IconSvg from '../../../components/IconSvg';
import { useSuiteNavigate } from '../../../hooks/useSuiteNavigate';
import isPageActive from '../../../utils/isPageActive';

import SidebarItem from './SidebarItem';
import { Separator } from './shared';

const START_PAGE_URL = '/start-page';

function QuickStartLinks({ collapsed }) {
  const navigate = useSuiteNavigate();

  return (
    <>
      <SidebarItem subheader title="Home" collapsed={collapsed} />
      <SidebarItem
        icon={<IconSvg name="mm-sparkles" size="16" />}
        collapsed={collapsed}
        active={isPageActive(START_PAGE_URL)}
        title="Start Page"
        dataTrack="sidebar--start"
        onClick={() => {
          navigate(START_PAGE_URL);
        }}
      />
      <Separator />
    </>
  );
}

export default QuickStartLinks;
