import { toast as reactHotToast } from 'react-hot-toast';
import ToastComponent from './components/ToastComponent';
import { ToastInterface, ToastProps } from './types';

export const dispatchToast: ToastProps = (content, options) => {
  const { type = 'success', icon, closable, ...rest } = options || {};

  return reactHotToast.custom(
    t => (
      <ToastComponent
        type={type}
        icon={icon}
        children={content}
        onClose={closable ? () => reactHotToast.remove(t.id) : void 0}
      />
    ),
    rest,
  );
};

const makeOptions = (options = {}, type) => {
  return {
    duration: 5000,
    closable: true,
    ...options,
    type,
  };
};

const toast: ToastInterface = {
  success: (content, options) => {
    return dispatchToast(content, makeOptions(options, 'success'));
  },
  error: (content, options) => {
    return dispatchToast(content, makeOptions(options, 'error'));
  },
  warning: (content, options) => {
    return dispatchToast(content, makeOptions(options, 'warning'));
  },
  info: (content, options) => {
    return dispatchToast(content, makeOptions(options, 'info'));
  },
  neutral: (content, options) => {
    return dispatchToast(content, makeOptions(options, 'neutral'));
  },
  dismiss: reactHotToast.dismiss,
};

export default toast;
