import { Command } from 'ckeditor5/src/core';
export default class ClearHighlightTermsCommand extends Command {
  constructor(editor, state) {
    super(editor);

    this.isEnabled = true;
    this.affectsData = false;
    this._state = state;
  }

  execute() {
    const { editor } = this;
    const { model } = editor;

    this._state.clearAll(model);
  }
}
