import React from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';

const Repeat = props => {
  const emptyArr = [...Array(props.n || 10).keys()];

  if (!isNil(props.renderComponent)) {
    return emptyArr.map((_, i) => props.renderComponent(i))
  }

  const Component = props.component;
  return (
    emptyArr.map((_, i) => (
      <Component key={`${props.id}-${i}`} {...props.props} />
    ))
  )
};

Repeat.propTypes = {
  n: PropTypes.number,
  id: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  props: PropTypes.object,
  renderComponent: PropTypes.func,
};

Repeat.defaultProps = {
  props: {}
}

export default Repeat;
