import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { lowerCase } from 'lodash';

import Clickable from '../Clickable';

const Wrapper = styled(Clickable)`
  position: relative;
  padding: 1px 5px;
  margin: -1px -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none !important;

  &:hover,
  &:focus {
    cursor: pointer;
  }
`;

const SortableDir = styled.div`
  margin-left: 6px;
  width: 12px;
`;

const SortableDirUp = styled.div`
  width: 5px;
  height: 5px;
  position: relative;
  span {
    position: absolute;
    top: -1px;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 5px solid ${p => p.theme.colors.grey25};
  }
`;

const SortableDirDown = styled.div`
  width: 5px;
  height: 5px;
  position: relative;
  span {
    position: absolute;
    top: 1px;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 5px solid ${p => p.theme.colors.grey25};
  }
`;

const sortChange = ({ active, direction, onChange }) => {
  if (!active) onChange('desc');
  else onChange(lowerCase(direction) === 'desc' ? 'asc' : 'desc');
};

const Sortable = props => (
  <Wrapper onClick={() => sortChange(props)} data-mms--table-header-sort>
    {props.children || props.columnName}
    <SortableDir>
      <SortableDirUp>
        {(!props.active || lowerCase(props?.direction || '') === 'asc') && (
          <span />
        )}
      </SortableDirUp>
      <SortableDirDown>
        {(!props.active || lowerCase(props?.direction || '') === 'desc') && (
          <span />
        )}
      </SortableDirDown>
    </SortableDir>
  </Wrapper>
);

Sortable.propTypes = {
  children: PropTypes.any,
  active: PropTypes.bool,
  direction: PropTypes.oneOf(['asc', 'desc', 'ASC', 'DESC', undefined]),
  columnName: PropTypes.string,
  onChange: PropTypes.func,
};

export default Sortable;
