import { trim } from 'lodash';

import { stripSlash } from './normalize';

export const sanitizePageUrl = (term: string) => {
  let sanitized = trim(term);
  sanitized = stripSlash(sanitized);

  return sanitized;
};
