import { get } from 'lodash';
import {
  AppQueryNetworkKeys,
  ResearchNetworkData,
} from '@marketmuse/data-state/types';

export const selectData = (state, key: keyof ResearchNetworkData) => {
  const path = `appResearch.data.${key}`;
  const data = get(state, path);

  return data;
};

export const selectNetworkStatus = (state, key: AppQueryNetworkKeys) => {
  const path = `appResearch.network.${key}`;
  const data = get(state, path);

  return data;
};
