import { Model, RootElement } from '@ckeditor/ckeditor5-engine';

import { modelElementToPlainText } from '../../_utils/modelElementToPlainText';
import { AhoCorasick } from '../ahocorasick';
import { Result } from '../types';

import { shouldProcessRangeItem } from './shouldProcessRangeItem';

type Mentions = Record<string, number>;
type MentionsByKey = Record<string, Mentions>;
function serializeDictListToCount(
  matches: Record<string, string[]>,
): MentionsByKey {
  const results = {};

  Object.entries(matches).forEach(([key, terms = []]) => {
    const counter = {};

    terms.forEach(label => {
      const term = label.toLowerCase();
      if (counter[term] !== undefined) {
        counter[term] += 1;
      } else {
        counter[term] = 1;
      }
    });

    results[key] = counter;
  });

  return results;
}

export function getMentionsCounts({
  keysByPriority,
  model,
  ahoCorasick,
}: {
  keysByPriority: string[];
  model: Model;
  ahoCorasick: AhoCorasick | null;
}) {
  const matches = keysByPriority.reduce((acc, key) => {
    return {
      ...acc,
      [key]: [],
    };
  }, {});

  const range = model.createRangeIn(model.document.getRoot() as RootElement);
  const ranges = [...range];

  for (let index = 0; index < ranges.length; index++) {
    const { type, item } = ranges[index];

    if (!shouldProcessRangeItem({ type, item, model, range })) {
      continue;
    }

    const text = modelElementToPlainText(item);
    const foundItemsDict = ahoCorasick?.search(text) || {};

    Object.values(foundItemsDict).forEach((foundItems: Result[]) => {
      foundItems.forEach(item => {
        matches[item.key].push(item.term);
      });
    });
  }

  const mentionsCounts = serializeDictListToCount(matches);

  return mentionsCounts;
}
