// https://dev.to/aidurber/in-search-of-a-faster-uniqueby-3je0
export const uniqueBy = <T extends Record<string, string | number>>(
  items: T[],
  key: string,
): T[] => {
  const record: T[] = [];
  const hash: Record<string | number, boolean> = {};

  for (let i = 0, len = items.length; i < len; ++i) {
    const item = items[i];
    const value = item[key];

    if (!hash[value]) {
      hash[value] = true;
      record.push(item);
    }
  }

  return record;
};
