import { FC } from 'react';

import { Box } from '../../Box';
import { Pagination } from '../../Pagination';
import { useTableContext } from '../useTableContext';

const classNames = ['shadow', 'flex', 'flex-row'];

const TablePagination: FC<{
  rowsControl?: Array<number>;
}> = ({ rowsControl = [10, 25, 50, 100, 250] }) => {
  const {
    setPageIndex,
    nextPage,
    previousPage,
    setPageSize,
    getState,
    getPrePaginationRowModel,
    loading,
  } = useTableContext();
  const state = getState();
  const pageIndex = state.pagination.pageIndex;
  const pageSize = state.pagination.pageSize;
  const resultCount = (
    getPrePaginationRowModel()?.rows || []
  ).length.toString();

  return (
    <Box className={classNames}>
      <Pagination
        onChangeLimit={setPageSize}
        resultCount={resultCount}
        onChangePage={page => setPageIndex(page - 1)}
        onNext={nextPage}
        onPrevious={previousPage}
        limit={pageSize}
        page={pageIndex + 1}
        rowsControl={rowsControl}
        loading={loading}
        wrapperAttrs={{ 'data-mms--table-pagination': '' }}
      />
    </Box>
  );
};

export default TablePagination;
