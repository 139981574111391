import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import noResultsImage from '../../assets/noresults.svg';
import Button from '../Button';

const Wrapper = styled.div`
  ${p => p.theme.type.richText};
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

const ImgWrapper = styled.div`
  order: ${p => p.order};
  width: 200px;
  margin-bottom: ${p => p.theme.spacing.medium};

  img {
    width: 100%;
    display: block;
  }
`;

const Title = styled.div`
  ${p => p.theme.type.heading2};
  font-size: 24px;
  line-height: 29px;
  margin-bottom: ${p => p.theme.spacing.medium};
  order: 1;
`;

const Subtitle = styled.div`
  ${p => p.theme.type.heading4};
  margin-bottom: ${p => p.theme.spacing.xSmall};
  color: ${p => p.theme.colors.grey85};
  font-size: 16px;
  line-height: 1.3156;
  order: 3;
`;

const Text = styled.div`
  ${p => p.theme.type.textLarge};
  font-size: 16px;
  line-height: 24px;

  &:not(:last-child) {
    margin-bottom: ${p => p.theme.spacing.regular};
  }
  order: 5;
`;

const Cta = styled(Button)`
  order: 7;
  ${p => p.isDefault && `margin-top: ${p.theme.spacing.small};`}
  ${p =>
    !p.isDefault &&
    `
    * {
        color: ${p.theme.mmxBlue} !important;
        font-size: 10pt;
      }`}
`;

const NoResults = ({ style, className, ...props }) => (
  <Wrapper style={style} className={className}>
    <ImgWrapper order={props.imgOrder} {...(props.imgWrapperProps || {})}>
      <img
        src={props.imgSrc || noResultsImage}
        alt={props.imgAlt || 'No Results'}
        {...(props.imgProps || {})}
      />
    </ImgWrapper>

    {props.title && <Title>{props.title}</Title>}
    {props.subtitle && <Subtitle>{props.subtitle}</Subtitle>}
    {props.text && <Text>{props.text}</Text>}
    {props.cta && props.ctaClick && (
      <Cta {...props.buttonProps} onClick={props.ctaClick}>
        {props.cta}
      </Cta>
    )}
  </Wrapper>
);

NoResults.defaultProps = {
  buttonProps: { bold: true },
  imgOrder: 0,
};

export const noResultPropTypes = {
  imgSrc: PropTypes.string,
  imgAlt: PropTypes.string,
  imgProps: PropTypes.object,
  style: PropTypes.object,
  className: PropTypes.string,
  title: PropTypes.any,
  text: PropTypes.any,
  cta: PropTypes.any,
  ctaClick: PropTypes.func,
  buttonProps: PropTypes.shape(),
  subtitle: PropTypes.string,
  imgOrder: PropTypes.number,
};

NoResults.propTypes = noResultPropTypes;

export default NoResults;
