import React from 'react';
import { ReactComponent as SpaceCrawler } from '../../assets/space-crawler.svg';
import { Box } from '../../Box';
import { Text } from '../../Text';
import { ErrorScreenUIProps } from '../types';

export const ChunkLoadError = ({ wrapperClassNames }: ErrorScreenUIProps) => {
  return (
    <Box className={wrapperClassNames}>
      <Box className={'max-w-[360px]'}>
        <SpaceCrawler className={'w-full'} />
      </Box>
      <Text as="h1">
        Please refresh the page for the new MarketMuse experience
      </Text>
      <Text as="p">
        At MarketMuse we're continuously working to improve your experience, and
        often that means changing code. Your browser keeps track of all our code
        bits, but doesn't always catch on when there has been a change.
      </Text>
      <Text as="p">
        You're seeing this error because our code change has caused your browser
        to look for something which is no longer exactly like it expects.
      </Text>
    </Box>
  );
};
