import PropTypes from 'prop-types';

export default {
  textarea: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  p: PropTypes.any,
  pt: PropTypes.any,
  pb: PropTypes.any,
  pl: PropTypes.any,
  pr: PropTypes.any,
  gap: PropTypes.any, // gap between items
  itemsLeft: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  itemsRight: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  onEsc: PropTypes.func, // esc key pressed
  onBlur: PropTypes.func,
  onEnter: PropTypes.func,
  // An issue was fixed in the commit below, that triggers the debounce function when
  // the identity of onDebounce fn changes, but in some cases this causes the debounced
  // function to trigger rapidly. So `onDebounceDynamic` was created for functions with
  // expected identity change to trigger the debounced function.
  // https://github.com/marketmuse/mmx/commit/6267361a0c1926d323dce931ab73f626e2223d2c
  onDebounce: PropTypes.func,
  onDebounceDynamic: PropTypes.func,
  debounceInterval: PropTypes.number,
  type: PropTypes.string,
  step: PropTypes.string,
  lang: PropTypes.string,
  // String, ReactNode, RenderProp - Display an error message
  error: PropTypes.any,
  // Automatically set value of input based on a prop
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // if you want SimpleTextInput to manage it's
  // own state, use this instead of Value
  targetValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // focus on this text input on mount
  autoFocus: PropTypes.bool,
  // a prop passed to this component which should trigger
  // focus on the input every time it changes
  autoFocusWatch: PropTypes.any,
  // render a label atop component
  label: PropTypes.string,
  // theme of the input
  inputTheme: PropTypes.oneOf(['default', 'dark', 'light', 'legacy']),
  // disable autocomplete
  noAutoComplete: PropTypes.bool,
};
