import React from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import countries from '../../../config/countries';
import { languages } from '../../../config/countryLanguageCombinations';
import {
  DropdownContainer,
  DropdownInput,
  DropdownTopic,
  DropdownUrl,
  DropdownTag,
} from '../styles';

export function useCachedItemsList({
  activeCountry,
  activeLanguage,
  canSetLanguage,
}) {
  const apps = useSelector(state => state?.apps);
  const cachedItemsList = Object.entries(apps || {})
    .filter(([key, value]) => key.indexOf('___') === 0)
    .filter(([key, value]) => {
      const appDataParams = get(value, 'params') || {};
      const langMatch = get(appDataParams, `language`) === activeLanguage;
      const countryMatch = get(appDataParams, `country`) === activeCountry;

      const res = langMatch && countryMatch;

      return res;
    })
    .map(([appKey, appData]) => {
      const appDataParams = get(appData, 'params') || {};
      return {
        id: appKey,
        text: `${appDataParams.topic} ${appDataParams.url}`,
        data: appDataParams,
        listItemProps: { pt: 8, pb: 8 },
        component: (
          <DropdownContainer>
            <DropdownInput>
              {appDataParams.topic && (
                <DropdownTopic>{appDataParams.topic}</DropdownTopic>
              )}
              {appDataParams.url && (
                <DropdownUrl>{appDataParams.url}</DropdownUrl>
              )}
            </DropdownInput>
            {appDataParams.people && <DropdownTag>People</DropdownTag>}
            {appDataParams.brands && <DropdownTag>Brands</DropdownTag>}
            <DropdownTag>{appDataParams.bodyOrArticle}</DropdownTag>
            <DropdownTag>
              {get(countries, `['${appDataParams.country}'].flag`)}&nbsp;
              {appDataParams.language && canSetLanguage && (
                <>
                  &nbsp;(
                  {get(languages, `['${appDataParams.language}'].code`)})
                </>
              )}
            </DropdownTag>
          </DropdownContainer>
        ),
      };
    });

  return cachedItemsList;
}
