import React, { Suspense } from 'react';
import { Provider } from 'react-redux';

import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import { ThemeProvider } from 'styled-components';

import { store, persistor } from '../config/instances';
import { ClerkProvider } from '../providers/ClerkProvider';
import IdentityProvider from '../providers/IdentityProvider';
import ApolloProvider from '../providers/ApolloProvider';
import TrackingProvider from '../providers/TrackingProvider';
import ScreenLoader from '../components/ScreenLoader';

import GlobalStyle from '../styles/main';
import theme from '../styles/variables';

if (window.Cypress) {
  window.store = store;
}

const withProviders =
  <P extends object>(App: React.ComponentType<P>): React.FC<P> =>
  () =>
    (
      <ClerkProvider>
        <TrackingProvider>
          <PersistGate persistor={persistor}>
            <ApolloProvider>
              <Provider store={store}>
                <IdentityProvider>
                  <ThemeProvider theme={theme}>
                    <>
                      <GlobalStyle />
                      <Suspense
                        fallback={<ScreenLoader.Light colorLoader="white" />}
                      >
                        <App />
                      </Suspense>
                    </>
                  </ThemeProvider>
                </IdentityProvider>
              </Provider>
            </ApolloProvider>
          </PersistGate>
        </TrackingProvider>
      </ClerkProvider>
    );

export default withProviders;
