import React, { memo, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import IconSvg from '../../../components/IconSvg';

import isPageActive from '../../../utils/isPageActive';
import * as miscActions from '../../../actions/miscActions';
import { useSuiteNavigate } from '../../../hooks/useSuiteNavigate';

import { Separator, SidebarPaddingLR } from '../components/shared';
import SidebarItem from '../components/SidebarItem';

const AdminSidebarScreen = props => {
  const navigate = useSuiteNavigate();
  const { isAdmin, activeScreen, collapsed, setActiveScreen, screenId } = props;
  useEffect(() => {
    if (!isAdmin && activeScreen === screenId) {
      setActiveScreen('main');
    }
  }, [isAdmin, activeScreen]);

  return (
    <>
      <SidebarItem
        header
        paddingLR
        icon={<IconSvg name="arrow-left" size="16" />}
        title="Back to Main Menu"
        collapsed={collapsed}
        onClick={() => setActiveScreen('main')}
      />
      <SidebarPaddingLR>
        {/* --------------------- ADMIN --------------------- */}
        <Separator />

        <SidebarItem header title="Admin" collapsed={collapsed} />

        {/* orgs */}
        <SidebarItem
          collapsed={collapsed}
          icon={<IconSvg name="organization" size="16" />}
          title="Organizations"
          dataTrack="sidebar--organizations"
          onClick={() => navigate('/admin/orgs', { omitOrgSlug: true })}
          active={isPageActive('/admin/orgs')}
          badgeAdd={[
            'Create Organization',
            () => {
              props.miscActions.toggleDynamic({ id: 'create-org' });
            },
          ]}
        />

        {/* reports manager */}
        <SidebarItem
          collapsed={collapsed}
          icon={<IconSvg name="reports-manager" size="16" />}
          title="Reports Manager"
          dataTrack="sidebar--reports-manager"
          onClick={() =>
            navigate('/admin/reports-manager', { omitOrgSlug: true })
          }
          active={isPageActive('/admin/reports-manager', { omitOrgSlug: true })}
        />

        {/* User Lookup */}
        <SidebarItem
          collapsed={collapsed}
          icon={<IconSvg name="qbr" size="16" />}
          title="User Lookup"
          dataTrack="sidebar--user-lookup"
          onClick={() => {
            props.miscActions.toggleDynamic({ id: 'userLookup' });
          }}
        />

        {/* form builder */}
        <SidebarItem
          collapsed={collapsed}
          icon={<IconSvg name="create-reports" size="16" />}
          title="Form Builder"
          dataTrack="sidebar--form-builder"
          onClick={() => navigate('/admin/form-builder', { omitOrgSlug: true })}
          active={isPageActive('/admin/form-builder')}
        />
      </SidebarPaddingLR>
    </>
  );
};

AdminSidebarScreen.propTypes = {
  miscActions: PropTypes.object,
  collapsed: PropTypes.bool,
};

const mapDispatchToProps = dispatch => ({
  miscActions: bindActionCreators(miscActions, dispatch),
});

export default memo(connect(null, mapDispatchToProps)(AdminSidebarScreen));
