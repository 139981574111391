import cx from 'classnames';
import { FC } from 'react';
import { Icon, SUPPORTED_ICON, Text, Button } from '@marketmuse/components';
import { ExportMenuProps } from '../types';

const iconClassNames = [
  'transition-all',
  'duration-200',
  'text-gray-600',
  'group-hover:text-blue-400',
];
const liClassNames = [
  'flex',
  'items-center',
  'px-0',
  'hover:text-blue-400',
  'group',
];

const buttonClassNames = [
  'flex',
  'items-center',
  'gap-2',
  'px-3',
  'py-1.5',
  'text-sm',
  'font-medium',
  'group-hover:text-blue-400',
  'disabled:text-gray-500',
  'group-hover:disabled:text-gray-500',
  'w-full',
];

const ExportMenu: FC<ExportMenuProps> = ({
  canExportSelectedItems,
  dataId,
  onItemClick,
}) => {
  return (
    <ul
      className={cx([
        'min-w-[200px]',
        'w-max',
        'mt-1',
        'p-1',
        'bg-white',
        'rounded',
        'shadow-lg',
      ])}
      data-mms--research-export-menu={dataId}
    >
      <li className={cx(liClassNames)}>
        <Button
          disabled={!canExportSelectedItems}
          onClick={() =>
            onItemClick ? onItemClick('export-selected') : void 0
          }
          variant={'blackOnTransparent'}
          className={cx(buttonClassNames)}
          data-mms--research-export-items={'selected'}
        >
          <Icon
            name={SUPPORTED_ICON.check_square}
            boxSize={18}
            className={cx([
              iconClassNames,
              !canExportSelectedItems && 'group-hover:text-gray-600',
            ])}
          />
          <Text>Export Selected</Text>
        </Button>
      </li>
      <li className={cx(liClassNames)}>
        <Button
          onClick={() =>
            onItemClick ? onItemClick('export-current-view') : void 0
          }
          variant={'blackOnTransparent'}
          className={cx(buttonClassNames)}
          data-mms--research-export-items={'current-view'}
        >
          <Icon
            boxSize={18}
            name={SUPPORTED_ICON.view_switcher}
            className={cx(iconClassNames)}
          />
          <Text>Export Current View</Text>
        </Button>
      </li>
    </ul>
  );
};

export default ExportMenu;
