import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ButtonIcon as ButtonIconBase, ButtonWrapper } from '../../Button';

const Button = styled(ButtonWrapper)`
  border-radius: 50%;
  background-color: transparent;
  color: ${p => p.theme.colors.grey50};
  padding: 0;
  min-height: 32px;
  min-width: 32px;

  &:not(:last-child) {
    margin-right: 8px;
  }

  &:hover {
    color: ${p => p.theme.mmxDarkGrey};
    background-color: ${p => p.theme.mmxGreyE9};
  }

  .mmx--button-icon svg {
    width: 100%;
  }
`;

const ButtonIcon = styled(ButtonIconBase)`
  font-size: 16px;
`;

const RowOverlayButton = ({
  ariaLabel,
  buttonType,
  className,
  icon,
  onClick,
  tooltip,
  tooltipPosition,
  disabled,
}) => {
  return (
    <Button
      type={buttonType}
      className={className}
      onClick={onClick}
      aria-label={ariaLabel || tooltip}
      disabled={disabled}
      tooltip={tooltip}
    >
      <ButtonIcon>{icon}</ButtonIcon>
    </Button>
  );
};

RowOverlayButton.propTypes = {
  ariaLabel: PropTypes.string,
  buttonType: PropTypes.oneOf(['submit', 'button']),
  icon: PropTypes.element.isRequired,
  onClick: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
};

RowOverlayButton.defaultProps = {
  type: 'button',
  tooltipPosition: 'top',
};

RowOverlayButton.displayName = 'RowOverlayButton';

export default RowOverlayButton;
