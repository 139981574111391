import React from 'react';
import { useStoreSelector } from '@marketmuse/data-state/hooks';
import { validatePermission } from '@marketmuse/utilities';
import { PermissionToCheck } from '@marketmuse/config/types/permissions';

import PowerUps from '../containers/EmptyStates/PowerUps';

const withAccess = (
  WrappedComponent: React.ComponentType<unknown>,
  {
    allow = [],
    prevent = [],
  }: {
    allow?: PermissionToCheck[];
    prevent?: PermissionToCheck[];
  } = {},
) => {
  return props => {
    const permissions = useStoreSelector(state => state.permissions);

    const admin = useStoreSelector(state => state.admin);
    const common = { admin, permissions };

    const allowed = allow.length
      ? allow.every(permission => validatePermission({ ...common, permission }))
      : true;
    const prevented = prevent.length
      ? prevent.some(permission =>
          validatePermission({ ...common, permission }),
        )
      : false;

    if (!allowed || prevented) {
      // HINT: This controls display when ACL forbids access
      return <PowerUps />;
    }

    return <WrappedComponent {...props} />;
  };
};

export default withAccess;
