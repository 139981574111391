import { pick, get } from 'lodash';

import * as types from '../config/types';
import initialState from '../config/initialState';

const appsReducer = (state = initialState.apps, action) => {
  if (action.type === types.SET_APPS_REDUCER) {
    return {
      ...(action?.payload || {}),
    };
  }

  if (action.type === types.SAVE_APP_CONFIG_STATE) {
    return {
      ...state,
      [action.key]: {
        date: action.date,
        params: action.params,
        data: {
          ...get(state, `['${action.key}'].data`),
          ...action.data,
        },
      },
    };
  }

  if (action.type === types.SAVE_APP_DATA) {
    return { ...state, [action.key]: action.value };
  }

  if (action.type === types.CLEAR_CACHE_ITEMS) {
    return pick({ ...state }, ['newsroom']);
  }

  return state;
};

export default appsReducer;
