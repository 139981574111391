import styled from 'styled-components';

import * as SL from '../../../../components/SimpleList/themes/app';

export const SLRow = styled(SL.Row)`
  display: flex;

  &:hover {
    box-shadow: none !important;
  }
  &:last-of-type {
    border-bottom: none;
  }

  ${p =>
    !p.header &&
    p.onClick &&
    !p.disabled &&
    p.theme.ripple(p.theme.colors.grey10)}
`;

export const SLItem = styled(SL.Item)`
  background-color: transparent;
  min-height: unset;
  text-align: ${p => (p.alignCenter ? 'center' : 'left')};

  &:first-of-type {
    padding-left: 12px;
  }
`;
