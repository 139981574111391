import ScreenLoaderDark from './ScreenLoaderDark';
import ScreenLoaderLight from './ScreenLoaderLight';
import ScreenLoaderMessage from './ScreenLoaderMessage';
import ScreenLoaderPage from './ScreenLoaderPage';
import ScreenLoaderAuthentication from './ScreenLoaderAuthentication';

export default {
  Dark: ScreenLoaderDark,
  Light: ScreenLoaderLight,
  Message: ScreenLoaderMessage,
  Page: ScreenLoaderPage,
  Auth: ScreenLoaderAuthentication,
};
