import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { Button } from '../../Button';
import { RenderButtonProps } from '../types';

const RenderButton = forwardRef<HTMLButtonElement, RenderButtonProps>(
  ({ dataId, item, onClick, defaultProps }, ref) => {
    const { RenderBefore, RenderAfter } = item;

    return (
      <Button
        ref={ref}
        className={twMerge(
          ['gap-2', 'justify-center', 'text-sm', 'w-full'],
          item.className,
        )}
        {...(dataId ? { [`data-mms--${dataId}`]: item.id } : {})}
        {...{ ...defaultProps, onClick }}
      >
        {RenderBefore && <RenderBefore />}
        {item.title}
        {RenderAfter && <RenderAfter />}
      </Button>
    );
  },
);

export default RenderButton;
