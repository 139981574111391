import React from 'react';
import styled from 'styled-components';
import { Toaster, Portal } from '@marketmuse/components';

import isPageActive from '../../utils/isPageActive';

const backgroundByPath = () => {
  const isWhite = isPageActive('/apps/optimize');
  const isBlack =
    isPageActive('/login') ||
    isPageActive('/account-setup') ||
    isPageActive('/logout');

  if (isBlack) {
    return `background-color: black;`;
  }
  if (isWhite) {
    return `background-color: white;`;
  }
};

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  ${backgroundByPath}
`;

const LayoutMain = ({ children }) => {
  return (
    <Wrapper>
      {children}
      <Portal root="mms--toast-root">
        <Toaster
          position="bottom-right"
          containerStyle={{
            bottom: 100,
          }}
        />
      </Portal>
    </Wrapper>
  );
};

export default LayoutMain;
