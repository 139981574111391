import { intentTitle } from './titles';

const PAGE_INTENT = {
  KNOW: 'informational',
  COMP: 'comparison',
  TRANS: 'transactional',
  LOCAL: 'local',
};

export const pageIntents = {
  [PAGE_INTENT.KNOW]: { id: PAGE_INTENT.KNOW, title: intentTitle.INFO_KNOW },
  [PAGE_INTENT.COMP]: {
    id: PAGE_INTENT.COMP,
    title: intentTitle.INFO_COMPARISON,
  },
  [PAGE_INTENT.TRANS]: {
    id: PAGE_INTENT.TRANS,
    title: intentTitle.TRANSACTIONAL,
  },
  [PAGE_INTENT.LOCAL]: { id: PAGE_INTENT.LOCAL, title: intentTitle.LOCAL },
};
