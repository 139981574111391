import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import localForage from 'localforage';
import { useAuth } from '@clerk/clerk-react';

import { TOKEN_KEYS } from '@marketmuse/config/configs';
import { MISC_SIMPLE_KEYS } from '@marketmuse/data-state/types';
import { useStoreDispatch } from '@marketmuse/data-state/hooks';
import { miscActions } from '@marketmuse/data-state/misc';

import { signOut } from '../../actions/authenticateActions';
import { trackerReset } from '../../utils/tracker';

const useLogout = () => {
  const navigate = useNavigate();
  const dispatch = useStoreDispatch();
  const { signOut: signOutClerk } = useAuth();

  const handleLogout = useCallback(
    async (redirectOverride?: string) => {
      trackerReset();
      navigate('/logout');

      dispatch(
        miscActions.setSimple({
          key: MISC_SIMPLE_KEYS.initiateLogout,
          value: new Date().getTime(),
        }),
      );

      sessionStorage.removeItem(TOKEN_KEYS.GUEST);
      sessionStorage.removeItem(TOKEN_KEYS.GHOST);

      localForage.removeItem('persist:admin');
      localForage.removeItem('persist:appOptimize');

      document.cookie = `googleAccessToken=; max-age=0; path=/;`;

      await signOutClerk();
      dispatch(signOut());

      if (redirectOverride) {
        navigate(redirectOverride);
      }
    },
    [dispatch, signOutClerk, navigate],
  );

  return handleLogout;
};

export default useLogout;
