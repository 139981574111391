import isNil from 'lodash/isNil';

import { store } from '../config/instances';
import { setSimple } from '../actions/miscActions';
import isAdminMode from './isAdminMode';

const KEY = 'developerMode';

const isDeveloperMode = () => {
  const state = store.getState();
  const { admin, user, persist } = state;

  return isAdminMode(admin, user) && persist[KEY];
};

export const toggleDeveloperMode = force => {
  const currentMode = isDeveloperMode();

  store.dispatch(
    setSimple({
      key: KEY,
      value: isNil(force) ? !currentMode : force,
      persist: true,
    })
  );
};

export default isDeveloperMode;
