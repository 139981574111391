import { StatBand, RankArgs, Color } from '../types';
import backgroundColor from './backgroundColor';
import textColor from './textColor';
import calculate from './calculate';

const statBandRank: StatBand<RankArgs, Color> = {
  calculate,
  backgroundColor,
  textColor,
};

export default statBandRank;
