import { Color, StatBand, ContentScoreArgs } from '../types';
import backgroundColor from './backgroundColor';
import calculate from './calculate';
import textColor from './textColor';

const statBandContentScore: StatBand<ContentScoreArgs, Color> = {
  calculate,
  textColor,
  backgroundColor,
};

export default statBandContentScore;
