import * as React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useStoreSelector } from '@marketmuse/data-state/hooks';

export const Privileged = () => {
  const admin = useStoreSelector(state => state.admin);

  if (!admin) {
    return <Navigate to="/a/" />;
  }

  return <Outlet />;
};
