import { ApolloQueryResult } from '@apollo/client';
import { isEmpty, pick } from 'lodash';
import { SeoSerpUserIntentDataQuery } from '@marketmuse/config/types/papi';
import { SeoSerpUserIntentDataDocument } from '@marketmuse/data-papi';
import {
  ResearchDataInput,
  ResearchThunkSeoRes,
  ThunkApiConfig,
} from '../../types';
import createNonConcurrentAsyncThunk from '../../utils/createNonConcurrentAsyncThunk';
import sanitizeAppParams from '../../utils/sanitizeAppParams';

const getSerpUserIntentData = createNonConcurrentAsyncThunk<
  ResearchThunkSeoRes,
  ResearchDataInput,
  ThunkApiConfig
>('runs/getSerpUserIntentData', async (params, { extra, rejectWithValue }) => {
  const variables = sanitizeAppParams<ResearchDataInput>(params);
  const response: ApolloQueryResult<SeoSerpUserIntentDataQuery> =
    await extra.apollo.query({
      query: SeoSerpUserIntentDataDocument,
      variables: pick(variables, ['term', 'url', 'country', 'language']),
    });

  const error = response.errors;
  if (error) {
    return rejectWithValue(error);
  }

  const {
    organicSerpItemIntents = [],
    intentData = {},
    userProvidedPage = {},
  } = response?.data?.seoSerpUserIntentData || {};

  const data = {
    organicSerpItemIntents: organicSerpItemIntents.slice(0, 20),
    intentData,
    userProvidedPage: !isEmpty(userProvidedPage) ? userProvidedPage : void 0,
  };

  return {
    data,
    params,
  };
});

export default getSerpUserIntentData;
