import { Country, Language } from '@marketmuse/config/types/papi';
import { AppUiStatus } from '@marketmuse/config/types/applications';
import { ResearchUiSliceState } from '../../types';

export const initialState: ResearchUiSliceState = {
  appQuery: {},
  params: {
    term: '',
    url: '',
    country: Country.US,
    language: Language.EN,
    siteId: '',
    networkDomains: [],
    competitorDomains: [],
  },
  status: AppUiStatus.zero,
  validParams: false,
};
