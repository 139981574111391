import get from 'lodash/get';
import * as types from '../../config/types';

export default () =>
  ({ getState }) =>
  next =>
  action => {
    const state = getState();

    if (action.type === types.GET_USERS_ADMIN) {
      const users = get(state, 'user.org') || [];

      if (action.callback) action.callback(users);
    }

    return next(action);
  };
