import React from 'react';
import { CustomFilterProps } from '@tanstack/react-table';

import { Input } from '../../Forms';
import { Box } from '../../Box';

// Define a default UI for filtering
export function FilterString({ column, table }: CustomFilterProps) {
  const id = column.id;
  const filterValue = column.getFilterValue();
  const title = column.columnDef?.meta?.filter?.title;
  return (
    <Box className={['flex', 'flex-col', 'gap-2']}>
      <Input
        name={`table-filter--column-${id}`}
        label={title || `Filter ${id as string}`}
        labelProps={{
          className: ['text-base', 'font-bold', 'text-gray-700'],
        }}
        inputProps={{
          value: filterValue || '',
          type: 'text',
          onChange: event => {
            column.setFilterValue(event.target.value || undefined);
          },
        }}
      />
    </Box>
  );
}
