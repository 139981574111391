import { Navigate } from 'react-router-dom';
import { Suspense } from 'react';

import { lazyLoad } from '@marketmuse/utilities';
import ScreenLoader from '../components/ScreenLoader';

const SettingsAccount = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'SettingsAccount' */ '../containers/Settings/SettingsAccount'
    ),
);

const SettingsCompany = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'SettingsCompany' */ '../containers/Settings/SettingsCompany'
    ),
);

const SettingsSubscriptionManagement = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'SettingsSubscriptionManagement' */ '../containers/Settings/SettingsSubscriptionManagement'
    ),
);

const SettingsTeam = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'SettingsTeam' */ '../containers/Settings/SettingsTeam'
    ),
);

const SettingsInventories = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'SettingsInventories' */ '../containers/Settings/SettingsInventories'
    ),
);

const SettingsIntegrations = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'SettingsIntegrations' */ '../containers/Settings/SettingsIntegrations'
    ),
);

export const routes = [
  {
    path: '*',
    element: <Navigate to="/account" />,
  },
  {
    path: 'account',
    element: (
      <Suspense fallback={<ScreenLoader.Light unwrapped={true} />}>
        <SettingsAccount />
      </Suspense>
    ),
  },
  {
    path: 'company',
    element: (
      <Suspense fallback={<ScreenLoader.Light unwrapped={true} />}>
        <SettingsCompany />
      </Suspense>
    ),
  },
  {
    path: 'subscription',
    element: (
      <Suspense fallback={<ScreenLoader.Light unwrapped={true} />}>
        <SettingsSubscriptionManagement />
      </Suspense>
    ),
  },
  {
    path: 'inventories',
    element: (
      <Suspense fallback={<ScreenLoader.Light unwrapped={true} />}>
        <SettingsInventories />
      </Suspense>
    ),
  },
  {
    path: 'integrations',
    element: (
      <Suspense fallback={<ScreenLoader.Light unwrapped={true} />}>
        <SettingsIntegrations />
      </Suspense>
    ),
  },
  {
    path: 'team',
    element: (
      <Suspense fallback={<ScreenLoader.Light unwrapped={true} />}>
        <SettingsTeam />
      </Suspense>
    ),
  },
];
