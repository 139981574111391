export function getCookie(key: string): string {
  const name = key + '=';
  const cookieDecoded = decodeURIComponent(document.cookie);
  const cookies = cookieDecoded.split('; ');
  let value = '';
  cookies.forEach(val => {
    if (val.indexOf(name) === 0) value = val.substring(name.length);
  });
  return value;
}
