import get from 'lodash/get';

import { SavedViewDeleteDocument } from '@marketmuse/data-papi';
import { savedViewDeleteFromState } from '@marketmuse/data-state/savedViews';
import Toast from '../../components/Toast';

import { createSavedView } from '../../actions/savedViewActions';
import * as types from '../../config/types';
import makeRequest from '../../utils/makeRequest';

export default () =>
  ({ dispatch, getState }) =>
  next =>
  action => {
    if (action.type === types.DELETE_SAVED_VIEW) {
      makeRequest({
        type: types.DELETE_SAVED_VIEW,
        mutation: SavedViewDeleteDocument,
        variables: {
          savedViewId: action.payload.savedView.id,
        },
        keys: [`${types.DELETE_SAVED_VIEW}_${action.payload.savedView.id}`],
      }).then(res => {
        const data = get(res, 'savedViewDelete');
        if (data) {
          Toast.fire('Saved view deleted', null, {
            buttons: [
              [
                '<button>Undo</button>',
                function (instance, toast) {
                  dispatch(
                    createSavedView({
                      ...action.payload.savedView,
                      callback: () => {
                        Toast.fire(`Undo complete `, 'success');
                      },
                    }),
                  );
                  Toast.fire('Reverting...', 'info');
                },
                true,
              ],
            ],
          });

          dispatch(
            savedViewDeleteFromState({
              id: action.payload.savedView.id,
              inventoryType: action.payload.savedView.inventoryType,
            }),
          );
        } else {
          Toast.fire('Sorry, we were unable to delete that', 'error');
        }
      });
    }

    return next(action);
  };
