import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Icon from '../IconSvg';

const IconStyled = styled(Icon).attrs(() => ({
  color: 'white',
  size: 12,
}))`
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
  z-index: 100;
  opacity: 0;
`;

const RadioWrapper = styled.label`
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: ${p => p.customStyles?.radioWrapper?.alignItems ?? 'center'};
  max-width: max-content;
  cursor: ${p => !p.isDisabled && 'pointer'};
`;

const RadioEl = styled.input`
  ${p => p.theme.mixins.showSROnly}
`;

const checkedStyled = css`
  background-color: ${p => p.isDisabled ? p.theme.colors.grey15 : p.theme.colors.blue};
  border: 0;
  ${IconStyled} {
    opacity: 1;
  }
`

const RadioHandle = styled.span`
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  width: ${p => p.customStyles?.radioHandle?.width ?? 16}px;
  height: ${p => p.customStyles?.radioHandle?.height ?? 16}px;
  border-radius: 3px;
  ${p => p.bordered
    ? `
      background-color: transparent;
      border: 1px solid ${p.theme.colors.grey60};
    `
    : `
      border: 0;
      background-color: ${p.theme.colors.grey15};
    `
}
  ${p => p.circle && `border-radius: 50%;`}
  ${p => p.halfSelected && checkedStyled};
  ${RadioEl}:checked + & { ${checkedStyled} };
`;

const RadioClickArea = styled.span`
  position: absolute;
  top: -${p => p.expandClickSize}px;
  right: -${p => p.expandClickSize}px;
  bottom: -${p => p.expandClickSize}px;
  left: -${p => p.expandClickSize}px;
  z-index: 200;
  display: block;
  cursor: inherit;
`;

export const RadioLabel = styled.span`
  ${p => p.theme.type.textRegular};
  margin-left: ${p => p.customStyles?.labelStyle?.marginLeft ?? p.theme.spacing.small};
  font-size: ${p => p.customStyles?.labelStyle?.fontSize ?? 11}px;
  ${p => p.customStyles?.labelStyle?.lineHeight
    ? `line-height: ${p.customStyles.labelStyle.lineHeight}px;`
    : ''
  }
`;

const Radio = props => (
  <RadioWrapper
    isDisabled={props.disabled}
    className={props.className}
    style={props.style}
    customStyles={props.customStyles}
    onClick={e => e.stopPropagation()}
  >
    <RadioEl
      aria-label={props.ariaLabel}
      disabled={props.disabled}
      name={props.name}
      type={props.type}
      checked={props.selected}
      onChange={e => {
        e.stopPropagation();
        if (props.onClick && !props.disabled) {
          props.onClick(e);
        }
      }}
    />

    <RadioHandle
      circle={props.circle}
      isDisabled={props.disabled}
      selected={props.selected}
      halfSelected={props.halfSelected}
      customStyles={props.customStyles}
      bordered={props.withoutIcon}
    >
      <RadioClickArea expandClickSize={props.disableClickableArea ? 0 : props.expandClickSize} />
      {!props.withoutIcon && (
        <IconStyled name={props.halfSelected ? 'minus' : 'checkmark'} />
      )}
    </RadioHandle>

    {props.label && <RadioLabel customStyles={props.customStyles}>{props.label}</RadioLabel>}
  </RadioWrapper>
);

Radio.defaultProps = {
  expandClickSize: 12,
  type: 'checkbox',
  withoutIcon: false,
  customStyles: {}
};

Radio.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,

  // Prevent interaction
  disabled: PropTypes.bool,

  // Give a visual label -- use ariaLabel if no label desired
  label: PropTypes.node,

  // styles for visual label
  customStyles: PropTypes.object,

  // Extend browser functionality for desired UI
  type: PropTypes.oneOf(['radio', 'checkbox']),

  // input[type=radio] must share the same name to allow for 1 to be selected at a time
  name: PropTypes.string,

  // Specify a Label for screen readers
  ariaLabel: PropTypes.string,

  // Round borders
  circle: PropTypes.bool,

  // displaying icon inside the RadioHandle
  withoutIcon: PropTypes.bool,

  // Event handler when toggled
  onClick: PropTypes.func,

  // Show as selected
  selected: PropTypes.bool,

  // Show as partially selected
  halfSelected: PropTypes.bool,

  // Restrict clickable area to only <RadioHandle />
  disableClickableArea: PropTypes.bool,
};

export default Radio;
