import React from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import styled, { css } from 'styled-components';
import IconSvg from '../IconSvg';

const ICON_NAMES = {
  increase: 'position-up',
  decrease: 'position-down',
  neutral: 'position-neutral',
  range: 'range'
};
const DIRECTIONS = Object.keys(ICON_NAMES);

const pillTheme = {
  increase: {
    wrapper: p => css`
      background-color: ${p.theme.colors.greenLight2};
      color: ${p.theme.colors.greenDark1};
    `,
    valueEmphasis: p => css`
      background-color: ${p.theme.colors.green};
      color: ${p.theme.colors.white};
    `,
  },
  decrease: {
    wrapper: p => css`
      background-color: ${p.theme.colors.redLight2};
      color: ${p.theme.colors.redDark1};
    `,
    valueEmphasis: p => css`
      background-color: ${p.theme.colors.red};
      color: ${p.theme.colors.white};
    `,
  },
  neutral: {
    wrapper: p => css`
      background-color: ${p.theme.colors.grey10};
      color: ${p.theme.colors.grey60};
    `,
    valueEmphasis: p => css`
      background-color: ${p.theme.colors.grey60};
      color: ${p.theme.colors.white};
    `,
  },
  range: {
    wrapper: p => css`
      background-color: ${p.theme.colors.grey10};
      color: ${p.theme.colors.grey60};
    `,
    valueEmphasis: p => css`
      background-color: ${p.theme.colors.grey60};
      color: ${p.theme.colors.white};
    `,
  },
};

const Wrapper = styled.div`
  ${p => pillTheme[p.direction].wrapper}
  display: inline-flex;
  height: fit-content;
  align-items: center;
  border-radius: 24px;
  ${p =>
    p.padContents &&
    `
    padding-right: ${p.theme.spacing.xSmall};
    padding-left: ${p.theme.spacing.xSmall};
  `}
`;

const IndicatorIcon = styled(IconSvg)`
  margin-left: -2px;

  &:first-child {
    margin-left: 0;
  }
`;

const Value = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  min-width: 24px;
  text-align: center;
  border-radius: 24px;
  padding: 0 4px;
  white-space: nowrap;
  & + ${IndicatorIcon} {
    margin-left: -2px;
  }

  ${IndicatorIcon} + & {
    margin-left: ${p => p.theme.spacing.xxSmall};
  }

  ${p => p.emphasis && pillTheme[p.direction].valueEmphasis}
`;

const ChangePill = ({ className, style, dir, value, changedValue }) => {
  return (
    <Wrapper
      className={className}
      style={style}
      direction={dir}
      padContents={isNil(changedValue)}
    >
      {isNil(changedValue) && dir !== ICON_NAMES.range && <IndicatorIcon size={12} name={ICON_NAMES[dir]} />}
      <Value>{value || '-'}</Value>
      {!isNil(changedValue) && (
        <>
        {
          dir === ICON_NAMES.range
            ? <strong>&nbsp;-&nbsp;&nbsp;</strong>
            : <IndicatorIcon size={12} name={ICON_NAMES[dir]} />
        }
          <Value direction={dir} emphasis>
            {changedValue || '-'}
          </Value>
        </>
      )}
    </Wrapper>
  );
};

ChangePill.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  dir: PropTypes.oneOf(DIRECTIONS).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  changedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ChangePill;
