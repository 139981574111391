import { FetchResult } from '@apollo/client';
import type {
  AppQuery,
  AppQueryUpdateMutation,
  AppQueryUpdateMutationVariables,
} from '@marketmuse/config/types/papi';
import { AppQueryUpdateDocument } from '@marketmuse/data-papi';

import { ThunkApiConfig } from '../../types';
import createNonConcurrentAsyncThunk from '../../utils/createNonConcurrentAsyncThunk';

const appQueryUpdate = createNonConcurrentAsyncThunk<
  AppQuery,
  AppQueryUpdateMutationVariables,
  ThunkApiConfig
>('runs/appQueryUpdate', async (variables, { extra, rejectWithValue }) => {
  const response: FetchResult<AppQueryUpdateMutation> =
    await extra.apollo.mutate({
      mutation: AppQueryUpdateDocument,
      variables,
    });

  if (response.errors) {
    return rejectWithValue(response.errors);
  }

  return response?.data?.appQueryUpdate;
});

export default appQueryUpdate;
