import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import get from 'lodash/get';

import * as SL from '../../../components/SimpleList/themes/app';
import Distribution from '../../../components/Distribution';
import ContentScore from '../../../components/ContentScore';
import SubItem from '../../../components/SubItem';
import Title from '../../../components/Title';
import ItemsVertical, { Item } from '../../../components/ItemsVertical';
import Slideout, {
  SlideoutHeader,
  SlideoutHeaderRow,
  SlideoutBody,
} from '../../../components/Slideout';
import KeyValue, { Key, Value } from '../../../components/KeyValue';
import Meta from '../../../components/Meta';
import Rank from '../../../components/Rank';

const ContentScoreStyled = styled(ContentScore)`
  font-size: 9pt;
`;

const DistributionStyled = styled(Distribution)`
  height: 32px;
  min-height: 32px;
  border-radius: 8px;
  margin: auto;
  width: 52px;
  font-weight: bold;
`;

const ItemsVerticalStyled = styled(ItemsVertical)`
  padding-top: 2px;
`;

const SlideoutHeaderStyled = styled(SlideoutHeader)`
  border-bottom: 1px solid ${p => p.theme.mmxGreyE9};
`;

const SlideoutBodyStyled = styled(SlideoutBody)`
  background-color: ${p => p.theme.mmxGrey};
`;

const RankWrapper = styled.div`
  height: 100%;
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

class HeadToHead extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  render() {
    const { close } = this.props;
    const appData = this.props.data;
    const focusTopic = get(appData, 'params.topic');
    const theirMentions = get(this.props, 'comparingTo.mentions') || {};
    const yourMentions = get(this.props, 'you.mentions');
    const comparingTo = get(this.props, 'comparingTo') || {};
    const contentScoreAverage = get(appData, 'scores.metrics.cs_avg');
    const contentScoreMax = get(appData, 'scores.metrics.cs_max');
    const contentScoreTarget = get(appData, 'scores.metrics.cs_target');

    return (
      <Slideout id="head-to-head-slideout" close={close}>
        <SlideoutHeaderStyled>
          <SlideoutHeaderRow>
            <Title>Head To Head</Title>
          </SlideoutHeaderRow>
          <SlideoutHeaderRow>
            <RankWrapper>
              <Rank value={comparingTo.rank} />
            </RankWrapper>
            <Meta title={comparingTo.title} url={comparingTo.url} />
          </SlideoutHeaderRow>
          <SlideoutHeaderRow>
            <ItemsVerticalStyled>
              <Item>
                <KeyValue>
                  <Key>Content Score</Key>
                  <Value>
                    <ContentScoreStyled
                      value={comparingTo.content_score}
                      average={contentScoreAverage}
                      target={contentScoreTarget}
                      best={contentScoreMax}
                    />
                  </Value>
                </KeyValue>
              </Item>
              <Item>
                <KeyValue>
                  <Key>Word Count</Key>
                  <Value>{comparingTo.wc}</Value>
                </KeyValue>
              </Item>
            </ItemsVerticalStyled>
          </SlideoutHeaderRow>
        </SlideoutHeaderStyled>
        <SlideoutBodyStyled>
          {/* focus topic */}
          {!!theirMentions[focusTopic] && (
            <div>
              <SL.Row header sticky style={{ top: 0, zIndex: 999999 }}>
                <SL.Item header grow pl={22}>
                  Focus Topic
                </SL.Item>
                {!!yourMentions && (
                  <SL.Item header width={60} p={0} pr={1}>
                    Yours
                  </SL.Item>
                )}
                <SL.Item header width={60} p={0}>
                  Theirs
                </SL.Item>
              </SL.Row>
              <SL.Row>
                <SL.Item grow pl={22}>
                  {focusTopic}
                </SL.Item>
                {!!yourMentions && (
                  <SL.Item width={60} p={0} pr={1}>
                    <DistributionStyled value={yourMentions[focusTopic] || 0} />
                  </SL.Item>
                )}
                <SL.Item width={60} p={0}>
                  <DistributionStyled value={theirMentions[focusTopic]} />
                </SL.Item>
              </SL.Row>
              <div
                style={{
                  height: 1,
                  width: '100%',
                  backgroundColor: get(this.props, 'theme.mmxGreyE9'),
                }}
              />
            </div>
          )}

          {/* related topics */}
          <div>
            <SL.Row header sticky style={{ top: 0, zIndex: 9999999 }}>
              <SL.Item header grow pl={22} style={{ display: 'flex' }}>
                <SubItem />
                Related Topics
              </SL.Item>
              {!!yourMentions && (
                <SL.Item header width={90} p={0} pr={1}>
                  Yours
                </SL.Item>
              )}
              <SL.Item header width={90} p={0}>
                Theirs
              </SL.Item>
            </SL.Row>
            {Object.keys(theirMentions)
              .filter(term => term !== focusTopic)
              .map(term => (
                <SL.Row key={`h2h-mentions-${term}`}>
                  <SL.Item grow pl={22}>
                    {term}
                  </SL.Item>
                  {!!yourMentions && (
                    <SL.Item width={100} p={0} pr={1}>
                      <DistributionStyled value={yourMentions[term] || 0} />
                    </SL.Item>
                  )}
                  <SL.Item width={100} p={0}>
                    <DistributionStyled value={theirMentions[term]} />
                  </SL.Item>
                </SL.Row>
              ))}
          </div>
        </SlideoutBodyStyled>
      </Slideout>
    );
  }
}

HeadToHead.propTypes = {
  close: PropTypes.func,
  data: PropTypes.object,
  you: PropTypes.object,
  comparingTo: PropTypes.object,
};

export default withTheme(HeadToHead);
