import { Item } from '@ckeditor/ckeditor5-engine';
import { isAlphaNumeric } from './isAlphaNumeric';

export function isNodeWholeWord(node: Item) {
  /* eslint-disable */
  // @ts-ignore
  const previousSibling = node.previousSibling;
  // @ts-ignore
  const nextSibling = node.nextSibling;
  /* eslint-enable */

  const previousNodeData = previousSibling?.data;
  const nextNodeData = nextSibling?.data;

  // If we have no previous or next sibling data, we are not a whole word
  if (!previousNodeData || !nextNodeData) {
    return false;
  }

  const isWholeWord =
    (!previousSibling || !isAlphaNumeric(previousNodeData)) &&
    (!nextSibling || !isAlphaNumeric(nextNodeData));

  return isWholeWord;
}
