import React from 'react';
import { render } from 'react-dom';
import { RouterProvider } from 'react-router-dom';

import 'symbol-observable';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-day-picker/lib/style.css';
import 'react-quill/dist/quill.snow.css';
import 'tippy.js/dist/tippy.css';
import '@marketmuse/styling/generated/compatibility.css';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import './assets/favicon.ico';
import './styles/animate.css';

import * as serviceWorker from './serviceWorker';
import { initExternalLibs } from './utils/misc';
import { tracker } from './config/instances';
import recurly from './models/Recurly';
import initSentry from './models/Sentry';
import { routes } from './routes';

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

initExternalLibs();
recurly.init();

initSentry();

window.tracker = tracker;

render(
  <RouterProvider
    router={routes}
    fallbackElement={<div className="mm-tw">Loading</div>}
  />,
  document.getElementById('root'),
);

serviceWorker.unregister();
