import get from 'lodash/get';
import { InventoryCreateDocument } from '@marketmuse/data-papi';

import Toast from '../../components/Toast';

import * as types from '../../config/types';
import makeRequest from '../../utils/makeRequest';

export default () =>
  ({ getState }) =>
  next =>
  action => {
    if (action.type === types.INVENTORY_CREATE) {
      makeRequest({
        type: types.INVENTORY_CREATE,
        mutation: InventoryCreateDocument,
        variables: {
          orgId: action.orgId,
          siteInput: action.params,
          options: action.options,
        },
      }).then(res => {
        if (res.error || res.errors) {
          Toast.fire(res.error || 'Unknown error', 'error');
          if (typeof action.callback === 'function') action.callback(res);
        } else {
          Toast.fire('Inventory created');
          const data = get(res, 'siteCreate') || {};
          if (typeof action.callback === 'function') action.callback(data);
        }
      });
    }

    return next(action);
  };
