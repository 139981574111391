import { css } from 'styled-components';

export default {
  inlineBlock: css`
    display: inline-block;
  `,
  inlineFlex: css`
    display: inline-flex;
  `,
  block: css`
    display: block;
  `,
  flex: css`
    display: flex;
  `,
  grid: css`
    display: grid;
  `,
};
