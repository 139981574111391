import * as React from 'react';
import { RowData } from '@tanstack/table-core';
import { Table } from '@tanstack/react-table';

export interface ITableContext<T> extends Table<T> {
  loading?: boolean;
}

export const TableContext = React.createContext(undefined);

export function useTableContext<T extends RowData>(): ITableContext<T> {
  const context = React.useContext(TableContext);
  if (!context) {
    throw new Error(
      'This component must be used within a <Table /> component.',
    );
  }
  return context;
}
