import React from 'react';
import styled from 'styled-components';
import isNil from 'lodash/isNil';

import { statBandContentScore } from '@marketmuse/utilities';

import ProgressBar from '../../../../components/ProgressBar';
import Tooltip from '../../../../components/Tooltip/Tooltip/Tooltip';

const ProgressBarStyled = styled(ProgressBar)`
  height: 8px;
  border-radius: 4px;
`;

const BarText = styled.div`
  position: relative;
  color: ${p => (p.color ? p.theme[p.color] : p.theme.colors.grey50)};
  letter-spacing: 1px;
  font-style: normal;
  font-weight: 900;
  font-size: 10px;
  text-transform: uppercase;
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  .mms--progressbar {
    min-width: 60px;
  }

  &:hover {
    cursor: pointer;
  }
  &:not(:hover) {
    ${BarText} {
      color: ${p => p.theme.colors.grey50} !important;
    }
    .mms--pb-bar {
      background-color: ${p => p.theme.mmxGreyE9} !important;
    }
  }
`;

const Bar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  box-shadow: none;
  border-radius: 4px;
  transition: ${p => p.theme.transition};
`;

const BarScores = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const ChangeIndicator = styled.div.attrs({
  className: 'animated fadeInUp',
})`
  width: 100%;
  text-align: center;
  font-size: 10px;
  height: 12px;
  position: absolute;
  top: -12px;
  left: 0;
  color: ${p => p.theme[p.change > 0 ? 'mmxBlue' : 'mmxRed']};
`;

const TooltipStyled = styled(Tooltip).attrs({
  delay: [500, 0],
  position: 'bottom',
})`
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
`;

export const BarContentScore = ({
  at,
  over,
  style,
  csChange,
  contentScoreAverage,
  contentScoreTarget,
  contentScoreBest,
}) => {
  const contentScoreColor = statBandContentScore.calculate(
    at,
    contentScoreAverage,
    contentScoreTarget,
    contentScoreBest,
  );

  const value = (at / over) * 100;
  return (
    <Wrapper style={style}>
      <TooltipStyled title="Current Content Score / Target Content Score">
        <BarText style={{ marginRight: 8 }}>CS</BarText>
        <Bar>
          <ProgressBarStyled
            thin
            hideValue
            trackColor="mmxGreyF4"
            color={contentScoreColor}
            value={value}
          />

          <BarScores>
            <BarText color={contentScoreColor} style={{ marginLeft: 8 }}>
              {csChange !== 0 && (
                <ChangeIndicator change={csChange}>
                  {csChange > 0 && '+'}
                  {csChange}
                </ChangeIndicator>
              )}
              {isNil(at) ? '-' : at}
            </BarText>
            <BarText style={{ marginLeft: 4, marginRight: 4 }}>/</BarText>
            <BarText color="green">{over}</BarText>
          </BarScores>
        </Bar>
      </TooltipStyled>
    </Wrapper>
  );
};

export const BarWordCount = ({ at, over, style, wcChange }) => {
  const value = (at / over) * 100;
  return (
    <Wrapper style={style}>
      <TooltipStyled title="Current Word Count / Target Word Count">
        <BarText style={{ marginRight: 8 }}>WC</BarText>
        <Bar>
          <ProgressBarStyled
            thin
            hideValue
            trackColor="mmxGreyF4"
            color="mmxBlue"
            value={value}
          />
          <BarScores>
            <BarText style={{ marginLeft: 8 }}>
              {wcChange !== 0 && (
                <ChangeIndicator change={wcChange}>
                  {wcChange > 0 && '+'}
                  {wcChange}
                </ChangeIndicator>
              )}
              {isNil(at) ? '-' : at}
            </BarText>
            <BarText style={{ marginLeft: 4, marginRight: 4 }}>/</BarText>
            <BarText>{over}</BarText>
          </BarScores>
        </Bar>
      </TooltipStyled>
    </Wrapper>
  );
};
