import {
  CellContext,
  BuiltInFilterFn,
  BuiltInSortingFn,
  FilterFn,
  FilterFnOption,
  FilterFns,
  RowData,
  SortingFn,
  SortingFns,
} from '@tanstack/table-core';
import {
  TableCellDateTime,
  TableCellFloat,
  TableCellMoney,
  TableCellNumber,
  TableCellString,
} from './components/TableCell';

import { sortListLength } from './sorting';

type ColumnConfig<TData extends RowData> = {
  size: number;
  minSize: number;
  maxSize: number;
  filterFn?:
    | BuiltInFilterFn
    | FilterFns
    | FilterFn<TData>
    | FilterFnOption<TData>;
  cell?: (cell: CellContext<TData, unknown>) => JSX.Element;
  sortingFn?: BuiltInSortingFn | SortingFns | SortingFn<unknown>;
};

export const configList: ColumnConfig<RowData> = {
  size: 120,
  minSize: 60,
  maxSize: 200,
  sortingFn: sortListLength,
};

export const configString: ColumnConfig<RowData> = {
  size: 120,
  minSize: 60,
  maxSize: 200,
  filterFn: 'includesString',
  cell: TableCellString,
  sortingFn: 'text',
};

export const configNumber: ColumnConfig<RowData> = {
  size: 120,
  minSize: 60,
  maxSize: 200,
  filterFn: 'inNumberRange',
  cell: TableCellNumber,
  sortingFn: 'basic',
};

export const configFloat: ColumnConfig<RowData> = {
  size: 120,
  minSize: 60,
  maxSize: 200,
  filterFn: 'inNumberRange',
  cell: TableCellFloat,
  sortingFn: 'alphanumeric',
};

export const configMoney: ColumnConfig<RowData> = {
  size: 120,
  minSize: 60,
  maxSize: 200,
  filterFn: 'inNumberRange',
  sortingFn: 'alphanumeric',
  cell: TableCellMoney,
};

export const configDateTime: ColumnConfig<RowData> = {
  size: 120,
  minSize: 60,
  maxSize: 200,
  filterFn: 'equals',
  sortingFn: 'datetime',
  cell: TableCellDateTime,
};
