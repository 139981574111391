import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Clickable from '../Clickable';
import IconSvg from '../IconSvg';
import ModalSidebar from './ModalSidebar';
import { ModalTopbarWrapper } from './ModalTopbar';

const parseBackdropBg = modalTheme => {
  switch (modalTheme) {
    case 'transparentBackground':
      return 'transparent';
    case 'whiteBackground':
      return 'rgba(255, 255, 255, 0.5)';
    default:
      return 'rgba(0, 0, 0, 0.5)';
  }
};

const parseModalPosition = modalPosition => {
  switch (modalPosition) {
    case 'takeover':
      return css`
        top: 0;
        left: 0;
        width: 100vw;
        max-width: unset;
        min-height: 100vh;
        border-radius: 0;
      `;
    case 'complete':
      return css`
        height: 100%;
        width: 100%;
        margin: 0 auto;
        max-width: 1260px;
      `;

    case 'right':
      return css`
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: auto;
        height: 100%;
        width: 640px;
        max-width: 85%;
        margin: 0;
        border-radius: 0;
      `;
    case 'left':
      return css`
        position: absolute;
        top: 0;
        right: auto;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 640px;
        max-width: 85%;
        margin: 0;
        border-radius: 0;
      `;
    default:
      return '';
  }
};

// TODO: MMS-290: Global Z-Index provider
const Backdrop = styled(Clickable).attrs({
  role: null,
})`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9000;
  background-color: ${p => parseBackdropBg(p?.modalTheme)};
  overflow-y: auto;
  overflow-x: visible;
  display: flex;
  align-items: center;
  padding: ${p => p.theme.spacing.large};

  ${p =>
    p.position === 'takeover' &&
    `
      padding: 0;
      align-items: stretch;
  `}

  ${p =>
    ['right', 'left'].includes(p.position) &&
    `
      padding:0;
  `}

  &:hover {
    cursor: inherit;
  }
`;

const ModalWrapper = styled.div`
  position: relative;
  overflow: auto;
  display: flex;
  flex-flow: row wrap;
  margin-right: auto;
  margin-left: auto;
  min-width: 400px;
  max-width: 100%;
  max-height: 100%;
  background-color: white;
  z-index: 1000;
  box-shadow: ${p => p.theme.boxShadowLow};
  border-radius: 4px;
  ${p => parseModalPosition(p?.position)}
  ${p => p.theme.mixins.applyPadding};
  ${p => p.theme.mixins.applyFlex};
  ${p => p.theme.mixins.applyGrid};
`;

const ModalInner = styled.div`
  position: relative;
  order: 3;
  height: 100%;
  width: 100%;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  border-radius: 4px;
  overflow: auto;

  ${ModalSidebar} + & {
    max-width: calc(100% - ${p => p.theme.sidebarWidthLarge}px);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  ${ModalTopbarWrapper} ~ & {
    height: 100%;
    max-height: calc(100% - 60px);
  }
`;

const CloseIcon = styled(Clickable)`
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${p => p.theme.colors.grey50};
  z-index: 10000;

  svg {
    width: 16px;
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

const Modal = ({
  modalTheme,
  position,

  id,
  children,
  topbar,
  sidebar,

  backdropAttrs,
  backdropStyle,

  close,
  closeAttrs,
  closeStyle,
  hideClose,

  className,
  style,
}) => {
  return (
    <Backdrop
      position={position}
      modalTheme={modalTheme}
      style={backdropStyle}
      className="mms--backdrop"
      {...{
        id: id ? `mms--backdrop-${id}` : void 0,
        onClick: close,
        style: backdropStyle ? backdropStyle : void 0,
        ...(backdropAttrs ? backdropAttrs : {}),
      }}
    >
      <ModalWrapper
        id={id}
        position={position}
        onClick={event => event.stopPropagation()}
        className={className}
        style={style}
      >
        {!hideClose && (
          <CloseIcon
            onClick={close}
            {...{
              style: closeStyle ? closeStyle : void 0,
              ...(closeAttrs ? closeAttrs : {}),
            }}
          >
            <IconSvg name={'close'} size={16} />
          </CloseIcon>
        )}
        {topbar ? topbar : void 0}
        {sidebar ? sidebar : void 0}
        <ModalInner>{children}</ModalInner>
      </ModalWrapper>
    </Backdrop>
  );
};

Modal.propTypes = {
  id: PropTypes.string,
  position: PropTypes.oneOf(['complete', 'takeover', 'right', 'left']),
  hideClose: PropTypes.bool,
  close: PropTypes.func,

  // modal main entries
  // - provide node (string, html, jsx), render func, or render-like obj
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.object,
  ]),
  topbar: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.object,
  ]),
  sidebar: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.object,
  ]),

  // styled-components
  className: PropTypes.string,
  style: PropTypes.object,
  backdropStyle: PropTypes.object,
};

export default Modal;
