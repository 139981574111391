import {
  ViewTableSliceState,
  ViewGenericSliceState,
  ViewHeatmapSliceState,
  ViewSerpXraySliceState,
} from '../../types';

export const genericState: ViewGenericSliceState = {
  filters: {},
  sort: {},
};

export const tableState: ViewTableSliceState = {
  filters: [],
  sort: [],
};

export const heatmapState: ViewHeatmapSliceState = {
  filters: {
    include: '',
    exclude: [],
  },
  sort: [
    {
      id: 'relevanceScore',
      desc: true,
    },
  ],
  mode: 'serp',
};

export const serpXrayState: ViewSerpXraySliceState = {
  filters: {
    predefinedFilters: {
      amazon: 'include',
      facebook: 'include',
      wikipedia: 'include',
      youtube: 'include',
    },
    exclude: [],
  },
};

export const initialState = {
  heatmap: heatmapState,
  keywords: tableState,
  questions: tableState,
  reflect: tableState,
  topicModel: tableState,
  xray: serpXrayState,
};
