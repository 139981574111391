import { NetworkStatus, NETWORK_STATUS } from '@marketmuse/config/types';

const isLoading = (status: NetworkStatus) =>
  (
    [
      NETWORK_STATUS.loading,
      NETWORK_STATUS.queued,
      NETWORK_STATUS.inactive,
    ] as NetworkStatus[]
  ).includes(status);

export default isLoading;
