import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SimpleListRow from './SimpleListRow';

const Wrapper = styled.div.attrs(p => ({
  width: p?.width && p.width !== '-1' ? p.width : void 0,
}))`
  ${p => p.theme.mixins.applyPadding};
  ${p => p.theme.mixins.applyMargin};
  display: flex;
  flex-flow: column;
  position: relative;

  ${p => p.overflow && `overflow: visible !important;`}
`;

const Headers = styled.div`
  position: absolute;
`;

const SimpleList = ({ children, headers, ...rest }) => (
  <Wrapper {...rest}>
    {headers && headers.length > 0 && <Headers>{headers}</Headers>}
    {!rest.loading && children}
    {rest.loading && (
      <>
        <SimpleListRow loading />
        <SimpleListRow loading />
        <SimpleListRow loading />
        <SimpleListRow loading />
        <SimpleListRow loading />
        <SimpleListRow loading />
        <SimpleListRow loading />
        <SimpleListRow loading />
        <SimpleListRow loading />
      </>
    )}
  </Wrapper>
);

SimpleList.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  loading: PropTypes.bool,

  // true for visible
  overflow: PropTypes.bool,

  headers: PropTypes.array,
  p: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  m: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ml: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

SimpleList.defaultProps = {
  headers: []
};

export default SimpleList;
