export const flexProps = [
  'flex',
  'flexBasis',
  'flexDirection',
  'flexFlow',
  'flexGrow',
  'flexShrink',
  'flexWrap',
  'order',
  'justifyContent',
  'alignItems',
  'alignSelf',
  'alignContent',
  'gap',
];

export const gridProps = [
  'grid',
  'gridArea',
  'gridAutoColumns',
  'gridAutoFlow',
  'gridAutoRows',
  'gridColumn',
  'gridColumnEnd',
  'gridColumnStart',
  'gridRow',
  'gridRowEnd',
  'gridRowStart',
  'gridTemplate',
  'gridTemplateAreas',
  'gridTemplateColumns',
  'gridTemplateRows',
  'gap',
  'columnGap',
  'rowGap',
];

export const positioningProps = ['position', 'top', 'right', 'bottom', 'left'];

export const paddingProps = [
  ['p', 'padding'],
  ['pt', 'paddingTop'],
  ['pr', 'paddingRight'],
  ['pb', 'paddingBottom'],
  ['pl', 'paddingLeft'],
];

export const marginProps = [
  ['m', 'margin'],
  ['mt', 'marginTop'],
  ['mr', 'marginRight'],
  ['mb', 'marginBottom'],
  ['ml', 'marginLeft'],
];

export const widthProps = ['width', 'minWidth', 'maxWidth'];

export const heightProps = ['height', 'minHeight', 'maxHeight'];

// intentionally minimal, need more complicated? use styled()
export const textStyleProps = [
  'fontWeight',
  'fontStyle',
  'fontSize',
  'lineHeight',
  'textAlign',
  'letterSpacing',
  'wordWrap',
  'listStyle',
];

export const colorProps = ['color', 'backgroundColor'];
