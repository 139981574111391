import isMac from '../../utils/isMac';

const normalizeHotKey = hotkey => {
  if (typeof hotkey === 'string') {
    const mod = isMac() ? '⌘' : 'ctrl';
    return hotkey.replace(/mod/, mod);
  }

  return hotkey;
};

export default normalizeHotKey;
