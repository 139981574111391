import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NetworkStatus } from '@marketmuse/config/types';
import { AppQueryNetworkKeys } from '../../types';
import { researchTaskSiteChange } from '../actions';
import { initialState } from './network.initialState';

export const networkSlice = createSlice({
  name: 'research/network',
  initialState,
  reducers: {
    adwordsKeywordsSet: (state, action: PayloadAction<NetworkStatus>) => {
      state.adwordsKeywords = action.payload;
    },
    adwordsQuestionsSet: (state, action: PayloadAction<NetworkStatus>) => {
      state.adwordsQuestions = action.payload;
    },
    bulkAdwordsKeywordsSet: (state, action: PayloadAction<NetworkStatus>) => {
      state.bulkAdwordsKeywords = action.payload;
    },
    knowledgeGraphSet: (state, action: PayloadAction<NetworkStatus>) => {
      state.knowledgeGraph = action.payload;
    },

    inventoryTopicsKeywordsSet: (
      state,
      action: PayloadAction<NetworkStatus>,
    ) => {
      state.inventoryTopicsKeywords = action.payload;
    },
    inventoryTopicsKGSet: (state, action: PayloadAction<NetworkStatus>) => {
      state.inventoryTopicsKG = action.payload;
    },
    inventoryTopicsBulkKeywordsSet: (
      state,
      action: PayloadAction<NetworkStatus>,
    ) => {
      state.inventoryTopicsBulkKeywords = action.payload;
    },
    inventoryTopicsQuestionsSet: (
      state,
      action: PayloadAction<NetworkStatus>,
    ) => {
      state.inventoryTopicsQuestions = action.payload;
    },
    inventoryTopicsSerpQuestionsSet: (
      state,
      action: PayloadAction<NetworkStatus>,
    ) => {
      state.inventoryTopicsSerpQuestions = action.payload;
    },
    linkingRecommendationsSet: (
      state,
      action: PayloadAction<NetworkStatus>,
    ) => {
      state.linkingRecommendations = action.payload;
    },
    serpDataSet: (state, action: PayloadAction<NetworkStatus>) => {
      state.serpData = action.payload;
    },
    serpPagesSet: (state, action: PayloadAction<NetworkStatus>) => {
      state.serpPages = action.payload;
    },
    serpQuestionsSet: (state, action: PayloadAction<NetworkStatus>) => {
      state.serpQuestions = action.payload;
    },
    serpUserIntentDataSet: (state, action: PayloadAction<NetworkStatus>) => {
      state.serpUserIntentData = action.payload;
    },
    websiteHeatmapSet: (state, action: PayloadAction<NetworkStatus>) => {
      state.websiteHeatmap = action.payload;
    },
    setMany: (
      state,
      action: PayloadAction<{
        keys: Array<AppQueryNetworkKeys>;
        status: NetworkStatus;
      }>,
    ) => {
      const { keys, status } = action.payload;
      keys.forEach(key => (state[key] = status));
    },
  },
  extraReducers(builder) {
    builder.addCase(researchTaskSiteChange, () => {
      return initialState;
    });
  },
});

export const networkActions = networkSlice.actions;
