import {
  DisplayTableDict,
  CommonDisplayColumn,
} from '../../types/displayTables';

const common: DisplayTableDict<CommonDisplayColumn> = {
  authority: {
    field: 'authority',
    title: 'Page Authority',
  },
  trafficValue: {
    field: 'trafficValue',
    title: 'Value/mo',
  },
  trafficValueDelta: {
    field: 'trafficValueDelta',
    title: 'Value Δ',
  },
  traffic: {
    title: 'Organic Traffic Est.',
    field: 'traffic',
  },
  trafficDelta: {
    field: 'trafficDelta',
    title: 'Organic Traffic Δ',
  },
  potentialTraffic: {
    field: 'potentialTraffic',
    title: 'Potential Traffic',
  },
  potentialTrafficUpside: {
    field: 'potentialTrafficUpside',
    title: 'Potential Traffic Gain',
  },
  versionTrafficValuePct: {
    title: 'Share of Site Value',
    field: 'versionTrafficValuePct',
  },
  versionTrafficPct: {
    title: 'Share of Site Traffic',
    field: 'versionTrafficPct',
  },
  potentialTrafficValue: {
    title: 'Potential Value',
    field: 'potentialTrafficValue',
  },
  trafficDeltaPct: {
    field: 'trafficDeltaPct',
    title: 'Traffic Change',
  },
};

export default common;
