import { parse as parseURL, format } from 'url';
import { trim } from 'lodash';

const urlPattern = new RegExp(
  '^(https?:\\/\\/)?' + // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
    '(\\:\\d+)?' + // validate port
    '((\\/[-a-z\\d%_.~+]+)|(\\/$)|(\\/\\?[;&a-z\\d%_.~+=-]*)|(\\/\\#[-a-z\\d_]*))*' + // validate path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
    '(\\#[-a-z\\d_]*)?$', // validate fragment locator
  'i',
);

export const isPageUrl = (url: string): { valid: boolean; reason: string } => {
  const urlClean = url.trim();
  let urlObj = parseURL(urlClean);

  if (urlObj.protocol === null) {
    urlObj = parseURL(`https://${urlClean}`);
  }

  if (urlObj.pathname !== '/') {
    urlObj.pathname = encodeURIComponent(trim(urlObj.pathname || '', '/'));
  }

  const formatted = format(urlObj);
  const isValidFormat = Boolean(urlPattern.test(formatted));
  const fileName = (formatted || '')?.split('\\')?.pop()?.split('/')?.pop();

  if (urlObj.search) {
    return {
      valid: false,
      reason: `URL may not contain query parameters, found ${urlObj.search}.`,
    };
  }

  if (urlObj.hash) {
    return {
      valid: false,
      reason: `URL may not contain anchor, or hash sign, found ${urlObj.hash}.`,
    };
  }

  if (!urlObj?.protocol?.includes('http')) {
    return {
      valid: false,
      reason: `Protocol is invalid. Please use http or https.`,
    };
  }

  if (fileName?.includes('.')) {
    const extension = fileName.split('.')[1];
    const validExtension = ['aspx', 'cfm', 'htm', 'html', 'php'].includes(
      extension,
    );

    if (!validExtension) {
      return {
        valid: false,
        reason: `Unsupported file extension.`,
      };
    }
  }

  return {
    valid: isValidFormat,
    reason: isValidFormat ? 'Valid webpage' : 'Invalid webpage',
  };
};
