import React from 'react';
import PropTypes from 'prop-types';
import Dotdotdot from 'react-dotdotdot';

const DotdotdotComponent = ({ children, ...props }) => (
  <Dotdotdot
    clamp={props.lines}
    className={props.className}
    style={props.style}
  >
    {children}
  </Dotdotdot>
);

DotdotdotComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  lines: PropTypes.number,
};

DotdotdotComponent.defaultProps = {
  lines: 3,
}

export default DotdotdotComponent;
