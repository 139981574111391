import { AppUiStatus } from '@marketmuse/config/types/applications';
import {
  AppQueryNetworkStatus,
  ReduxListener,
  AppQueryParams,
  RootState,
} from '../../../types';
import validateAppParams from '../../../utils/validateAppParams';
import {
  optimizeTaskInitialize,
  optimizeTaskHydrateCheck,
  optimizeTaskSiteChange,
} from '../../actions';
import { networkSlice, uiSlice } from '../../slices';
import { getKnowledgeGraph } from '../../thunks';

const setup = (startListening: ReduxListener) => {
  const networkActions = networkSlice.actions;
  const uiActions = uiSlice.actions;

  startListening({
    actionCreator: optimizeTaskSiteChange,
    effect: async (action, { cancelActiveListeners }) => {
      cancelActiveListeners();
    },
  });

  startListening({
    actionCreator: optimizeTaskHydrateCheck,
    effect: async (action, { cancelActiveListeners }) => {
      cancelActiveListeners();
    },
  });

  startListening({
    actionCreator: optimizeTaskInitialize,
    effect: async (action, { dispatch, getState }) => {
      const payload: AppQueryParams = action.payload;
      const state: RootState = getState();

      const isParamsValid = validateAppParams(payload);

      if (isParamsValid) {
        dispatch(uiActions.statusSet(AppUiStatus.loading));

        const requests = [
          dispatch(
            networkActions.bulkAdwordsKeywordsSet(AppQueryNetworkStatus.queued),
          ),
          dispatch(getKnowledgeGraph(payload)),
        ];

        await Promise.all(requests);
      } else {
        console.log('app run prevented', {
          params: payload,
          isParamsValid,
          appStatus: state.appResearch.ui.status,
        });
      }
    },
  });
};

export default setup;
