import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Tooltip from '../../components/Tooltip/Tooltip';
import Loader from '../../components/Loader/Loader';

const ButtonWrapper = styled.button`
  min-width: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  &:hover {
    cursor: ${p => (p.disabled ? 'default' : 'pointer')};
  }
`;

ButtonWrapper.displayName = 'ButtonWrapper';

// buttons

const ButtonTypeText = styled(ButtonWrapper)`
  display: inline;
  padding: 8px 0;
  background-color: transparent;
  color: ${p => p.theme.colors.blueBase};
  font-size: 14px;

  &:hover,
  &:focus {
    ${p =>
      !p.disabled &&
      `
      background-color: transparent;
    `}
  }
`;

const variantStyles = {
  blackOnTransparent: {
    initial: p => css`
      border: 1px solid rgba(255, 255, 255, 0);
      background-color: rgba(255, 255, 255, 0);
      color: ${p.theme.colors.grey85};
    `,
    active: p => css`
      color: ${p.theme.colors.black};
    `,
  },
  grey70: {
    initial: p => css`
      border: 1px solid ${p.theme.colors.grey70};
      background-color: ${p.theme.colors.grey70};
      color: ${p.theme.colors.grey50};
    `,
    active: p => css`
      background-color: ${p.theme.colors.grey85};
      color: ${p.theme.colors.grey60};
    `,
  },
  redLight2: {
    initial: p => css`
      border: 1px solid ${p.theme.colors.redLight2};
      background-color: ${p.theme.colors.redLight2};
      color: ${p.theme.colors.redDark1};
    `,
    active: p => css`
      background-color: ${p.theme.colors.redLight2};
    `,
  },
  grey05: {
    initial: p => css`
      background-color: ${p.theme.colors.grey05};
      color: ${p.theme.colors.grey60};
    `,
    active: p => css`
      background-color: ${p.theme.colors.grey15};
      color: ${p.theme.colors.grey70};
    `,
  },
  grey50: {
    initial: p => css`
      background-color: ${p.theme.colors.grey50};
      color: ${p.theme.colors.grey05};
    `,
    active: p => css`
      background-color: ${p.theme.colors.grey60};
      color: ${p.theme.colors.grey05};
    `,
  },
  grey50OnWhite: {
    initial: p => css`
      background-color: ${p.theme.colors.white};
      color: ${p.theme.colors.grey50};
    `,
  },
};

const ButtonTypeDefault = styled(ButtonWrapper)`
  border-radius: 4px;
  background-color: ${p => p.theme.colors[p.disabled ? 'grey25' : 'blue']};
  color: ${p => (p.disabled ? 'rgba(255, 255, 255, 0.9)' : 'white')};
  padding: 0 8px;
  min-height: 20px;

  ${p =>
    p.variant &&
    css`
      ${variantStyles[p.variant]?.initial};
      transition: background-color 200ms ${p.theme.transitionFn},
        border-color 200ms ${p.theme.transitionFn},
        color 200ms ${p.theme.transitionFn};

      &:focus,
      &:hover,
      &:active {
        ${variantStyles[p.variant]?.active}
      }

      &:disabled {
        &,
        &:focus,
        &:hover {
          cursor: default;
        }
      }
    `};

  ${p =>
    p.small &&
    `
    min-height: 30px;
    padding: 2px ${p.theme.spacing.regular};
    font-size: 12px;
    line-height: 22px;
    font-weight: 700;
  `}

  ${p =>
    p.medium &&
    `
    padding: 8px 18px;
  `}

  ${p =>
    p.large &&
    `
    padding: ${p.theme.spacing.xSmall} ${p.theme.spacing.regular};
    font-size: 12px;
    font-weight: 700;
  `}

  ${p =>
    p.xLarge &&
    css`
      border-radius: ${p.theme.borderRadius};
      padding: ${p.theme.spacing.xSmall} ${p.theme.spacing.medium};
      ${p.loading && `padding: 12px ${p.theme.spacing.medium};`}
      font-size: 14px;
      line-height: 22px;
      font-weight: 700;
    `}

  ${p =>
    p.xxLarge &&
    `
    border-radius: ${p.theme.borderRadius};
    padding: ${p.theme.spacing.medium} !important;
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
  `}

  ${p =>
    p.radius &&
    `
    border-radius: ${p.radius};
  `}

  ${p =>
    (p.default || p.light) &&
    `
    font-weight: bold;
    background-color: white;
    border: 1px solid ${p.theme.colors.grey50};
    color: ${p.theme.colors.grey50};
    &:hover {
      ${!p.disabled && `border-color: ${p.theme.colors.grey60};`}
      ${p.iconOnly && `color: ${p.theme.colors.grey85};`}
    }
  `}

  ${p =>
    p.text &&
    `
    font-weight: bold;
    background-color: transparent;
    color: ${p.theme.colors.blue};
    border: none;
  `}

  ${p =>
    p.iconOnly &&
    `
    height: 28px;
    width: 28px;
    padding: 0;
    border: 0;
    border-radius: 100%;
  `}

  ${p =>
    p.primary &&
    css`
      font-weight: bold;
      color: ${p.theme.colors[p.border ? 'blueDark1' : 'white']};
      background-color: ${p.theme.colors[p.border ? 'white' : 'blue']};
      border: 1px solid ${p.theme.colors.blue};
      transition: color 0.3s ${p.theme.transitionFn},
        border-color 0.3s ${p.theme.transitionFn},
        background-color 0.3s ${p.theme.transitionFn};

      &:focus,
      &:hover {
        background-color: ${!p.border && p.theme.colors.blueDark1};
      }

      &:disabled {
        &,
        &:focus,
        &:hover {
          color: ${p.border && p.theme.colors.grey25};
          border-color: ${p.theme.colors.grey25};
          background-color: ${!p.border && p.theme.colors.grey25};
          cursor: default;
        }
      }
    `}

  ${p =>
    p.primarySecondary &&
    css`
      font-weight: bold;
      color: ${p.theme.colors.white};
      background-color: ${p.theme.colors.blueDark1};
      border-color: transparent;

      &:focus,
      &:hover {
        background-color: ${p.theme.colors.blueDark1};
      }
      &:disabled {
        &,
        &:focus,
        &:hover {
          background-color: ${!p.border && p.theme.colors.grey25};
          cursor: default;
        }
      }
    `}

  ${p =>
    p.secondary &&
    `
    font-weight: bold;
    background-color: ${p.theme.colors.blueLight2};
    border: 2px solid ${p.theme.colors.blueLight2};
    color: ${p.theme.colors.blueDark1};
    transition: border .3s ease;

    &:hover,
    &:focus {
      border-color: ${p.theme.colors.blueDark1};

      &:disabled {
        border-color: ${p.theme.colors.blueLight2};
       }
    }
  `}

  ${p =>
    p.tertiary &&
    css`
      font-weight: bold;
      color: ${p.theme.colors.grey50};
      background-color: ${p.theme.colors.white};
      border: 1px solid
        ${!p.border ? p.theme.colors.white : p.theme.colors.grey50};
      transition: color 0.3s ${p.theme.transitionFn},
        border-color 0.3s ${p.theme.transitionFn},
        background-color 0.3s ${p.theme.transitionFn};

      &:hover,
      &:focus {
        color: ${p.theme.colors.grey60};
      }

      &:disabled {
        &,
        &:hover,
        &:focus {
          color: ${p.theme.colors.grey25};
          border-color: ${p.border && p.theme.colors.grey25};
          background-color: ${p.theme.colors.white};
          cursor: default;
        }
      }
    `}

  ${p =>
    p.bold &&
    `
    padding: 0;
    background-color: transparent;

    * {
      color: ${p.theme.colors.grey50} !important;
      font-size: ${p.fontSize || '13px'} !important;
      font-weight: 700 !important;
      font-family: ${p.theme.type.families.main} !important;
    }
  `}


  ${p =>
    p.success &&
    `
    background-color: ${p.theme.mmxGreen};
    border: 1px solid ${p.theme.mmxGreen};
    color: white;
    ${
      p.border &&
      `
      background-color: transparent;
      color: ${p.theme.mmxGreen};
    `
    }
    &:hover {
      ${
        !p.disabled &&
        `
        ${!p.border && `background-color: ${p.theme.mmxGreen};`}
        border: 1px solid ${p.theme.mmxDarkGreen};
      `
      }
    }
  `}

  ${p =>
    (p.error || p.danger) &&
    `
    background-color: ${p.border ? 'transparent' : p.theme.mmxRed};
    border: 1px solid ${p.theme.mmxRed};
    color: ${p.border ? p.theme.mmxRed : 'white'};

    &:hover,
    &:focus {
      background-color: ${!p.disabled && !p.border && p.theme.mmxRed};
      border-color: ${!p.border && p.theme.mmxDarkRed};
    }
  `}

  ${p =>
    p.warning &&
    `
    background-color: ${p.theme.mmxOrange};
    border: 1px solid ${p.theme.mmxOrange};
    color: white;
    ${
      p.border &&
      `
      background-color: transparent;
      color: ${p.theme.mmxOrange};
    `
    }
    &:hover {
      ${
        !p.disabled &&
        `
        ${!p.border && `background-color: ${p.theme.mmxOrange};`}
        border: 1px solid ${p.theme.mmxDarkRed};
      `
      }
    }
  `}

  ${p =>
    p.inline &&
    `
    display: inline-flex;
    padding: 0;
  `}

  ${p =>
    p.disabled &&
    !p.primary &&
    !p.primarySecondary &&
    `
    opacity: 0.6;
    &:hover {
      cursor: default !important;
    }
  `}


  ${p => p.bgWhite && `background-color: white;`}
  ${p => p.colorBlack && `color: ${p.theme.colors.grey85};`}
  ${p => p.colorBlue && `color: ${p.theme.colors.blue};`}
  ${p => p.colorGrey && `color: ${p.theme.colors.grey50};`}
  ${p => p.fontSize && `font-size: ${p.fontSize};`}
  ${p => p.lineHeight && `line-height: ${p.lineHeight};`}
  ${p => p.underline && `text-decoration: underline;`}
`;

const BtnChildren = styled.span`
  display: flex;
  align-items: center;

  ${p =>
    p.underlined &&
    `
    border-bottom: 1px solid ${p.theme.colors.blue};
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  `}
`;

const ButtonIcon = styled.span.attrs({ className: 'mmx--button-icon' })`
  display: flex;
  align-items: center;

  ${p =>
    !p.iconOnly &&
    p.left &&
    `
    margin-right: 8px;
    font-size: 16px;
  `}

  ${p =>
    !p.iconOnly &&
    p.right &&
    `
    margin-left: 8px;
    font-size: 16px;
  `}
`;

// loaders

const LoaderTypeText = styled(Loader)``;
const LoaderTypeDefault = styled(Loader)`
  color: currentColor;
  min-height: 16px;
`;

const Button = ({
  type,
  className,
  icon,
  iconLeft,
  iconRight,
  children,
  loading,
  tooltip,
  tooltipPosition,
  submit,
  href,
  target,
  displayTooltip,
  underlined,
  ...rest
}) => {
  const ref = useRef();
  let ButtonComponent = ButtonTypeDefault;
  let LoaderComponent = LoaderTypeDefault;

  if (type === 'text') {
    ButtonComponent = ButtonTypeText;
    LoaderComponent = LoaderTypeText;
  }

  return (
    <>
      <ButtonComponent
        loading={loading ? loading.toString() : void 0}
        type={submit ? 'submit' : 'button'}
        className={className}
        iconOnly={!children}
        as={href ? 'a' : void 0}
        href={href}
        target={href && target ? target : void 0}
        ref={ref}
        {...rest}
      >
        {(!!icon || !!iconLeft) && (
          <ButtonIcon left iconOnly={!children}>
            {iconLeft || icon}
          </ButtonIcon>
        )}
        {loading && <LoaderComponent bgWhite={rest?.bgWhite} />}
        {!loading && (
          <BtnChildren underlined={underlined}>{children}</BtnChildren>
        )}
        {!!iconRight && (
          <ButtonIcon right iconOnly={!children}>
            {iconRight}
          </ButtonIcon>
        )}
      </ButtonComponent>
      <Tooltip
        html={tooltip}
        disabled={!tooltip}
        position={tooltipPosition}
        reference={ref}
      />
    </>
  );
};

Button.propTypes = {
  displayTooltip: PropTypes.bool,
  target: PropTypes.string,
  href: PropTypes.string,
  fontSize: PropTypes.string,
  border: PropTypes.bool,
  radius: PropTypes.string,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  warning: PropTypes.bool,
  danger: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  light: PropTypes.bool,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.any,
  children: PropTypes.any,
  loading: PropTypes.bool,
  submit: PropTypes.bool,
  large: PropTypes.bool,
  xLarge: PropTypes.bool,
  underlined: PropTypes.bool,
  inline: PropTypes.bool,
};

Button.defaultProps = {
  type: 'default',
  displayTooltip: true,
  xLarge: false,
  underlined: false,
  inline: false,
};

export { ButtonWrapper, ButtonIcon };
export default Button;
