import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import hideTooltip from '../../utils/hideTooltip';
import {
  TooltipStyled,
  ButtonCancel,
  DropdownBody,
  DropdownWrapper,
  SimpleTextInputStyled,
  DataListRowSelected,
  NoResultText,
} from './shared';

import DataListRow from './DataListRow';
import DataListItem from './DataListItem';

const DATALIST_SCREENS = {
  datalist: 'datalist',
  action: 'action',
};

const DataList = ({
  wrapper,
  onWrapperClick,
  children,
  list = [],
  handleId,
  selection,
  onSelect,
  onDeselect,
  onDebounce,
  className,
  dropdownStyles,
  inputProps,
  popperProps,
  action,
  noResultsText = 'No results',
  tippyTheme = 'light',
  tippyUsage,
  filterItemsFn,
  highlightSelected,
}) => {
  const [query, setQuery] = useState('');
  const [autoFocus, setAutoFocus] = useState(false);
  const [screen, setScreen] = useState(DATALIST_SCREENS.datalist);

  const useScreen = useCallback(
    next => {
      setScreen(next);

      if (
        action?.onScreenChange &&
        typeof action?.onScreenChange === 'function'
      ) {
        action && action.onScreenChange(next);
      }
    },
    [screen, action?.onScreenChange],
  );

  const filteredList =
    filterItemsFn && typeof filterItemsFn === 'function'
      ? list.filter(item => filterItemsFn(item, query))
      : list
          .filter(item => item?.id !== selection?.id)
          .filter(item => {
            if (!query) {
              return true;
            }
            return (
              (item.title || '').toLowerCase().indexOf(query.toLowerCase()) !==
              -1
            );
          });
  const childStyles = Object.assign({}, { width: '185px' }, dropdownStyles);

  const noResultsComponent = (
    <DataListRow>
      <DataListItem>
        <NoResultText>{noResultsText}</NoResultText>
      </DataListItem>
    </DataListRow>
  );

  const renderDataListRow = ({ item, selectedId }) => (
    <DataListRow
      key={item.id}
      clickable
      {...item.tags}
      {...(selectedId && { active: item.id === selectedId })}
      disabled={item.disabled}
      onClick={event => {
        hideTooltip(handleId);
        onSelect(item, event);
      }}
    >
      <DataListItem>{item.title}</DataListItem>
    </DataListRow>
  );

  const plugins = [
    {
      fn: () => ({
        onShow(instance) {
          setAutoFocus(true);
        },
        onHide: () => {
          useScreen(DATALIST_SCREENS.datalist);
          setAutoFocus(false);
          setQuery('');
        },
      }),
    },
  ];

  const content = (
    <DropdownWrapper style={childStyles} onClick={onWrapperClick}>
      {screen === DATALIST_SCREENS.datalist && (
        <>
          {list && Array.isArray(list) && (
            <DataListRow headerRow>
              <DataListItem>
                <SimpleTextInputStyled
                  autoFocus
                  autoFocusWatch={autoFocus}
                  value={query}
                  onChange={e => setQuery(e.target.value)}
                  onDebounce={onDebounce}
                  {...inputProps}
                />
              </DataListItem>
            </DataListRow>
          )}
          <DropdownBody empty={filteredList.length === 0}>
            {selection?.id && typeof onDeselect === 'function' && (
              <DataListRowSelected
                clickable={onDeselect}
                {...selection.tags}
                onClick={event => {
                  hideTooltip(handleId);
                  onDeselect(event);
                }}
              >
                <DataListItem>
                  <ButtonCancel />
                  <span>{selection.title}</span>
                </DataListItem>
              </DataListRowSelected>
            )}
            {filteredList.length === 0 && noResultsComponent}
            {filteredList.map(
              item =>
                item?.render?.({ item, query }) ||
                renderDataListRow({
                  item,
                  selectedId:
                    highlightSelected &&
                    typeof onDeselect !== 'function' &&
                    selection?.id,
                }),
            )}
          </DropdownBody>
        </>
      )}
      {screen === DATALIST_SCREENS.datalist && action?.cta && (
        <DataListRow
          hoverDisabled
          clickable
          style={{ paddingTop: 0, paddingBottom: 0 }}
          onClick={event => {
            if (action.onClick && typeof action.onClick === 'function') {
              action.onClick(event);
            }

            if (action.screen) {
              event.nativeEvent.stopImmediatePropagation();
              event.preventDefault();
              event.stopPropagation();
              useScreen(DATALIST_SCREENS.action);
            }
          }}
        >
          <DataListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
            {action?.cta}
          </DataListItem>
        </DataListRow>
      )}
      {screen === DATALIST_SCREENS.action &&
        action?.screen &&
        action?.screen?.({ useScreen, query })}
    </DropdownWrapper>
  );

  const wrapped = (
    <TooltipStyled
      title={null}
      id={handleId}
      useContext={false}
      className={className}
      tippyTheme={tippyTheme}
      tippyUsage={tippyUsage}
      childAs="div"
      popperProps={popperProps}
      plugins={plugins}
      html={content}
    >
      {children}
    </TooltipStyled>
  );

  return wrapper ? wrapper(content) : wrapped;
};

DataList.propTypes = {
  className: PropTypes.string,

  // pass props to child components
  inputProps: PropTypes.object,
  popperProps: PropTypes.object,

  // control styling of DropdownWrapper
  dropdownStyles: PropTypes.object,

  // contents
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      title: PropTypes.string,
    }),
  ),

  // pass a renderProp
  wrapper: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),

  // when clicked wrapper
  onWrapperClick: PropTypes.func,

  // object in list that is the selected item
  selection: PropTypes.object,

  // filterItemsFn: function to validate item uniq
  filterItemsFn: PropTypes.func,

  // when an item is selected
  onSelect: PropTypes.func,

  // remove selected item
  onDeselect: PropTypes.func,

  // callback with value, name of input
  onDebounce: PropTypes.func,

  // enables 'click to close' functionality
  handleId: PropTypes.string,

  // add a tertiary action
  action: PropTypes.shape({
    cta: PropTypes.any,
    onClick: PropTypes.func,
    screen: PropTypes.any,
    // how to handle action screen changes
    onScreenChange: PropTypes.func,
  }),

  // what text to display if no results
  noResultsText: PropTypes.string,
  tippyTheme: PropTypes.oneOf(['light', 'dark']),
  highlightSelected: PropTypes.bool,
};

DataList.defaultProps = {
  list: [],
  className: '',
  onSelect: () => {},
  selection: {},
  popperProps: {},
};

export default memo(DataList);
