import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Col = styled.div`
  position: relative;
  border-left: 2px solid white;
`;

const MatrixColumn = ({ attrs = {}, ...props }) => (
  <Col
    className={props.className}
    onMouseEnter={() => {
      if (props.onMouseEnter) {
        props.onMouseEnter();
      }
    }}
    onMouseLeave={() => {
      if (props.onMouseLeave) {
        props.onMouseLeave();
      }
    }}
    {...attrs}
  >
    {props.children}
  </Col>
);

MatrixColumn.propTypes = {
  hover: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func
};

export default MatrixColumn;
