import * as React from 'react';
import { IResearchPermissionsContext } from '../types';

import { ResearchPermissionsContext } from '../context';

const useResearchPermissions = (): IResearchPermissionsContext => {
  const context = React.useContext(ResearchPermissionsContext);
  if (!context) {
    throw new Error(
      'This component must be used within the <ResearchPermissionsProvider />.',
    );
  }
  return context;
};

export default useResearchPermissions;
