import { isEmpty, get } from 'lodash';

import { GetSavedViewsEachDocument as gql } from '@marketmuse/data-papi';
import * as types from '../../config/types';
import makeRequest from '../../utils/makeRequest';
import { offsetToPage } from '../../utils/misc';

export default () => () => next => action => {
  if (action.type === types.GET_SAVED_VIEWS_EACH) {
    const { siteId, filters, orderBy, limit, offset, each, search } = action;

    const page = offsetToPage(offset, limit);

    const filtersNext = [
      {
        AND: [
          ...(!isEmpty(search)
            ? {
                OR: [
                  {
                    descriptionContains: search,
                  },
                  {
                    titleContains: search,
                  },
                ],
              }
            : []),
          ...(!isEmpty(filters) ? filters : []),
        ].filter(Boolean),
      },
    ];

    makeRequest({
      type: types.GET_SAVED_VIEWS_EACH,
      mutation: gql,
      options: { isQuery: true },
      variables: {
        order: orderBy,
        page: page || 0,
        limit,
        siteId: siteId,
        filters: !isEmpty(filtersNext[0].AND) ? filtersNext : void 0,
        each,
      },
      apolloOptions: {
        fetchPolicy: 'no-cache',
      },
    }).then(res => {
      const data = get(res, 'savedViewsEach');
      if (typeof action.callback === 'function' && data) action.callback(data);
    });
  }

  return next(action);
};
