import React from 'react';
import { twMerge } from 'tailwind-merge';

export interface CheckboxProps extends React.HTMLProps<HTMLInputElement> {
  className?: string;
  indeterminate?: boolean;
}

type CheckboxComponent = (props: CheckboxProps) => React.ReactElement | null;

export const Checkbox: CheckboxComponent = ({
  indeterminate,
  className = '',
  ...rest
}: CheckboxProps) => {
  const ref = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={twMerge([
        rest.disabled ? 'cursor-auto' : 'cursor-pointer',
        'border-0',
        'rounded',
        'focus:outline-none focus:shadow-none focus:ring-0 focus:ring-offset-0',
        rest.checked || indeterminate ? 'text-blue-400' : 'bg-gray-150',
        className,
      ])}
      {...rest}
    />
  );
};
