import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IconArrowDownRight from 'react-feather/dist/icons/arrow-down-right';

import { randomNumber } from '../../utils/misc';

const Label = styled.div`
  flex-shrink: 0;
  position: relative;
  display: flex;
  align-items: center;
  line-height: ${p => p.theme.matrixCellSize}px;
  height: ${p => p.theme.matrixCellSize}px;
  background-color: white;
  padding: 0 12px;
  padding-left: 0;
  width: 280px;
  border-top: 1px solid ${p => p.theme.mmxGreyE9};
`;

const LabelFiller = styled.div`
  height: 12px;
  background-color: #f4f4f4;
  width: 100%;
`;

const LabelText = styled.div`
  font-size: 13px;
  color: ${p => p.theme.colors.grey85};
  ${p => p.theme.truncate}
`;

const LabelHeader = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 280px;
  height: ${p => p.theme.matrixCellSize}px;
  top: ${p => -1 * p.theme.matrixCellSize}px;
  left: 0;
  color: ${p => p.theme.colors.grey50};
  font-weight: bold;
  font-size: 8.5pt;
  text-transform: uppercase;
  letter-spacing: 1.2px;
`;

const LabelHeaderText = styled.div`
`;

const LabelHeaderPointer = styled.div`
  margin-right: 20px;
`;

const LabelHeaderArrow = styled.div`
  display: flex;
  position: absolute;
  right: -1px;
  bottom: 5px;
  height: 12px;
  svg { height: 12px; }
`;

const MatrixLabel = ({ attrs = {}, ...props }) => (
  <Label className={props.className} {...attrs}>
    <LabelText>{props.label}</LabelText>
    {props.loading && (
      <LabelFiller style={{ width: `${randomNumber(30, 100)}%` }} />
    )}
    {props.labelHeaderText && (
      <LabelHeader>
        <LabelHeaderText>{props.labelHeaderText}</LabelHeaderText>
        <LabelHeaderPointer>{props.labelHeaderPointerText}</LabelHeaderPointer>
        <LabelHeaderArrow><IconArrowDownRight /></LabelHeaderArrow>
      </LabelHeader>
    )}
  </Label>
);

MatrixLabel.propTypes = {
  loading: PropTypes.bool,
  label: PropTypes.any,
  className: PropTypes.string,
  labelHeaderText: PropTypes.string,
  labelHeaderPointerText: PropTypes.string
};

export default MatrixLabel;
