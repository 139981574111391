import { FC, ReactNode } from 'react';
import cx from 'classnames';
import { toString, isEmpty } from 'lodash';
import { Box, Text } from '@marketmuse/components';

interface QueryHeaderProps {
  children?: ReactNode;
  topic: string;
  loading: boolean;
}

const QueryHeader: FC<QueryHeaderProps> = ({ topic, children, loading }) => {
  return (
    <Box
      className={cx([
        'flex',
        'flex-row',
        'justify-between',
        'items-center',
        'px-12',
      ])}
    >
      <Box>
        <Text
          as={'h1'}
          className={cx([
            'text-3xl',
            'flex',
            'flex-row',
            'justify-between',
            'font-bold',
            'py-7',
            loading ? 'text-gray-600' : 'text-black',
          ])}
          {...(!isEmpty(topic)
            ? { 'data-mms--research-query-header': 'search query' }
            : {})}
        >
          {topic ? toString(topic).toLowerCase() : <>&nbsp;</>}
        </Text>
      </Box>
      {children ? <Box>{children}</Box> : null}
    </Box>
  );
};

export default QueryHeader;
