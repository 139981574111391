import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import get from 'lodash/get';
import isNil from 'lodash/isNil';

import Tab, { Tabs } from '../../../../../components/Tab';

import * as SL from '../../../../../components/SimpleList/themes/app';
import Repeat from '../../../../../components/Repeat';

import MentionMatrix from '../../../../../components/Matrix';
import HeadToHead from '../../../components/HeadToHead';
import Rank from '../../../../../components/Rank';
import Meta from '../../../../../components/Meta';
import ContentScore from '../../../../../components/ContentScore';

import {
  NewsroomOutputSection,
  NewsroomOutputSectionHeader,
  NewsroomOutputSectionContents,
  NewsroomOutputSectionHeaderTitle,
  NewsroomOutputSectionHeaderItem,
  NewsroomOutputMatrixLabel,
} from '../../sharedStyles';

const MatrixWrapper = styled.div`
  margin-top: 360px;
  padding-left: ${p => p.theme.pagePaddingLR}px;
`;

const TabsStyled = styled(Tabs)`
  margin-right: 20px;
`;

const TabStyled = styled(Tab)`
  margin: 0;
  padding: 0 12px;
  border-bottom: 2px solid ${p => p.theme[p.active ? 'mmxBlue' : 'mmxGrey']};
`;

class Top20 extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      view: 'list',
      h2hSite: null,
    };
  }

  render() {
    const appData = this.props.appData || {};
    const you = Object.values(get(appData, 'scoresNews.pages') || {})[0];
    const contentScoreAverage = get(appData, 'scoresNews.metrics.cs_avg');
    const contentScoreMax = get(appData, 'scoresNews.metrics.cs_max');
    const contentScoreTarget = get(appData, 'scoresNews.metrics.cs_target');
    const rankingPages = Object.values(
      get(appData, 'scoresNews.ranking_pages') || {},
    ).sort((a, b) => a.rank - b.rank);

    const rankingUrlsSerp = Object.values(
      get(appData, 'scores.ranking_pages') || {},
    );
    const rankingUrlsSerpRankDict = rankingUrlsSerp.map(rp => ({
      [rp.url]: rp.rank,
    }));

    return (
      <NewsroomOutputSection>
        <NewsroomOutputSectionHeader>
          <NewsroomOutputSectionHeaderTitle>
            News Top 20
          </NewsroomOutputSectionHeaderTitle>
          <NewsroomOutputSectionHeaderItem style={{ marginRight: '-5px' }}>
            <TabsStyled>
              <TabStyled
                active={this.state.view === 'list'}
                onClick={() => this.setState({ view: 'list' })}
              >
                Rank List
              </TabStyled>
              <TabStyled
                active={this.state.view === 'grid'}
                onClick={() => this.setState({ view: 'grid' })}
              >
                Topical Heatmap
              </TabStyled>
            </TabsStyled>
          </NewsroomOutputSectionHeaderItem>
        </NewsroomOutputSectionHeader>
        <NewsroomOutputSectionContents>
          {this.state.view === 'grid' && (
            <MatrixWrapper>
              <MentionMatrix
                compareAction={h2hSite => this.setState({ h2hSite })}
                loading={this.props.loading}
                topLabel={
                  <NewsroomOutputMatrixLabel>
                    Page Rank / <br /> Serp Rank
                  </NewsroomOutputMatrixLabel>
                }
                data={{
                  ...appData,
                  rankingUrlsSerpRankDict,
                  scores: get(appData, 'scoresNews'),
                }}
              />
            </MatrixWrapper>
          )}
          {this.state.view === 'list' && (
            <>
              {/*// TODO: MMS-290*/}
              <SL.Row header sticky style={{ top: 0 }}>
                <SL.Item header width={75} alignCenter pl={22}>
                  Rank
                </SL.Item>
                <SL.Item header grow>
                  Top Ranking Pages
                </SL.Item>
                <SL.Item header width={110} alignCenter>
                  SERP Rank
                </SL.Item>
                <SL.Item header width={130} alignCenter>
                  <span className="mm mm-stars" style={{ marginRight: 5 }} />{' '}
                  News Score
                </SL.Item>
                <SL.Item header width={130} alignCenter>
                  Word Count
                </SL.Item>
              </SL.Row>
              {this.props.loading && (
                <Repeat
                  id="newsroom-top-20"
                  component={SL.Row}
                  props={{ loading: true }}
                />
              )}
              {!this.props.loading && rankingPages.length === 0 && (
                <SL.Row>
                  <SL.Item grow pl={22}>
                    No data
                  </SL.Item>
                </SL.Row>
              )}
              {!this.props.loading &&
                rankingPages.map((rp, i) => (
                  <SL.Row
                    key={`newsroom-rps-${rp.term}-${i}`}
                    onClick={() => this.setState({ h2hSite: rp })}
                  >
                    <SL.Item width={75} alignCenter pl={22}>
                      <Rank value={rp.rank} />
                    </SL.Item>
                    <SL.Item grow pt={8} pb={8}>
                      <Meta title={rp.title} url={rp.url} />
                    </SL.Item>
                    <SL.Item width={110} alignCenter>
                      {!isNil(rankingUrlsSerpRankDict[rp.url]) && (
                        <Rank value={rankingUrlsSerpRankDict[rp.url]} />
                      )}
                    </SL.Item>
                    <SL.Item width={130} alignCenter>
                      <ContentScore
                        value={rp.cs}
                        average={contentScoreAverage}
                        target={contentScoreTarget}
                        best={contentScoreMax}
                      />
                    </SL.Item>
                    <SL.Item width={130} alignCenter>
                      {rp.wc}
                    </SL.Item>
                  </SL.Row>
                ))}
            </>
          )}
        </NewsroomOutputSectionContents>
        {this.state.h2hSite && (
          <HeadToHead
            you={you}
            data={{
              ...appData,
              scores: get(appData, 'scoresNews'),
            }}
            comparingTo={this.state.h2hSite}
            close={() => this.setState({ h2hSite: null })}
          />
        )}
      </NewsroomOutputSection>
    );
  }
}

Top20.propTypes = {
  url: PropTypes.string,
  query: PropTypes.string,
  loading: PropTypes.bool,
  appData: PropTypes.object,
  snapshot: PropTypes.bool,
};

export default Top20;
