import { ACTION_TYPES } from '@marketmuse/data-state';
import * as types from '../config/types';
import initialState from '../config/initialState';

const guestAccessReducer = (state = initialState.guestAccess, action) => {
  switch (action.type) {
    case types.SAVE_GUEST_ACCESS_DATA:
      return { ...action.guestAccess };

    case ACTION_TYPES.SIGN_OUT:
      return initialState.guestAccess;

    default:
      return state;
  }
};

export default guestAccessReducer;
